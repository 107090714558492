import * as React from 'react'
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actionCreators } from '../../../store/analiserisco/HistoricoPagamentos';

import Moment from 'react-moment';
import { FormattedNumber } from 'react-intl';
import { Row, Modal } from 'react-bootstrap';
import Section from '../../commons/Section';
import SectionHeader from '../../commons/SectionHeader';
import InputSelect from '../../commons/InputSelect';
import AlertDismissable from '../../commons/AlertDismissable';
import DateTimePicker from '../../commons/DateTimePicker';
import BtnPesquisar from '../../commons/BtnPesquisar';
import ListPager from '../../commons/ListPager';
import BtnSalvar from '../../commons/BtnSalvar';
import BtnCancelar from '../../commons/BtnCancelar';

class HistoricoPagamentosForm extends React.Component {
    constructor() {
        super();
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {
        this.props.requestListPagamentos();
    }

    handleSubmit(e) {
        e.preventDefault();
        this.props.Filters.PageNumber = 1;
        this.props.requestListPagamentos();
    }

    render() {
        var objProps = this.props;
        return (
            <Row>
                <div className="col-md-12">
                    <Section>
                        <AlertDismissable
                            title="Ops algo deu errado!"
                            text={this.props.ErrorText}
                            show={this.props.ErrorText != '' && this.props.ErrorText != undefined ? true : false}
                            bsStyle="danger"
                        />
                        <SectionHeader title="Histórico de Solicitação de Crédito" />
                        <div className="tile-body">
                            <form onSubmit={this.handleSubmit}>
                                <Row>
                                    <div className="form-group col-md-4">
                                        <label>Data inicial</label>
                                        <DateTimePicker
                                            onChange={(value) => {
                                                this.props.Filters.DataDe = value;
                                                this.props.fnUpdateState(this.props);
                                            }}
                                            value={this.props.Filters.DataDe}
                                        />
                                    </div>
                                    <div className="form-group col-md-4">
                                        <label>Data final</label>
                                        <DateTimePicker
                                            onChange={(value) => {
                                                this.props.Filters.DataAte = value;
                                                this.props.fnUpdateState(this.props);
                                            }}
                                            value={this.props.Filters.DataAte}
                                        />
                                    </div>
                                    <div className="form-group col-md-4">
                                        <label>Status</label>
                                        <InputSelect
                                            class="form-control mt-4"
                                            data={[{ value: '', text: 'Selecione' }, { value: '1', text: 'Em liberação' }, { value: '2', text: 'Liberado' }, { value: '3', text: 'Cancelado' }]}
                                            onChange={(name, value) => { this.props.Filters.IdStatus = value; this.props.fnUpdateState(this.props); }}
                                            selectedValue={(this.props.Filters.IdStatus === null) ? null : this.props.Filters.IdStatus.toString()}
                                        />
                                    </div>
                                    <div className="form-group text-right">
                                        <div className='col-md-12'>
                                            <BtnPesquisar
                                                type="submit"
                                                isLoading={this.props.IsLoading}
                                                text=" Pesquisar"
                                                textLoading=" Aguarde..."
                                            />
                                        </div>
                                    </div>
                                </Row>
                            </form>
                            <br />
                            <div className="table-responsive">
                                <div className="dataTables_wrapper form-inline dt-bootstrap no-footer">
                                    <div className="dataTables_length" id="basic-usage_length">
                                        <InputSelect
                                            class="form-control input-sm"
                                            data={[{ value: '10', text: '10' }, { value: '25', text: '25' }, { value: '50', text: '50' }]}
                                            onChange={(name, value) => { this.props.Filters.PageSize = value; this.props.Filters.PageNumber = 1; this.props.requestListPagamentos(); }}
                                            selectedValue={(this.props.Filters.PageSize === null) ? null : this.props.Filters.PageSize.toString()}
                                        /> registros por página
              </div>
                                    <Row>
                                        <div className="col-sm-12">
                                            <table className="table table-custom dataTable no-footer">
                                                <thead>
                                                    <tr role="row">
                                                        <th>Responsável Solic.</th>
                                                        <th>Data Solic.</th>
                                                        <th>Valor R$</th>
                                                        <th>Status</th>
                                                        <th>Liberado Por</th>
                                                        <th>Data Liberação</th>
                                                        <th>Ações</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        (this.props.IsLoading)
                                                            ? <tr key={0} className="odd">
                                                                <td colSpan={8} className="dataTables_empty">
                                                                    <div className="progress progress-striped active">
                                                                        <div className="progress-bar progress-bar-blue" role="progressbar" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100" style={{ width: "100%" }}>
                                                                            <span className="sr-only">100% Complete</span>
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                            : (this.props.Results.length === 0)
                                                                ? <tr key={0} className="odd">
                                                                    <td colSpan={8} className="dataTables_empty">
                                                                        <p className="text-warning">Não foram encontrados registros para a busca...</p>
                                                                    </td>
                                                                </tr>
                                                                : this.props.Results.map(function (rowData, index) {
                                                                    return (
                                                                        <tr key={index}>
                                                                            <td>{rowData.NomeResponsavelSolicitacao}</td>
                                                                            <td><Moment format="DD/MM/YYYY HH:mm">{rowData.DataHoraSolicitacao}</Moment></td>
                                                                            <td><FormattedNumber value={rowData.Valor} style="decimal" minimumFractionDigits={2} maximumFractionDigits={2} /></td>
                                                                            <td><span className={rowData.DescricaoStatus === "Em liberação" ? "label label-warning" : rowData.DescricaoStatus === "Cancelado" ? "label label-danger" : "label label-success"}>{rowData.DescricaoStatus}</span></td>
                                                                            <td>{rowData.NomeResponsavelLiberacao}</td>
                                                                            <td>
                                                                                {(rowData.DataHoraLiberacao === null)
                                                                                    ? ""
                                                                                    : <Moment format="DD/MM/YYYY HH:mm">{rowData.DataHoraLiberacao}</Moment>
                                                                                }
                                                                            </td>
                                                                            <td>
                                                                                {
                                                                                    (rowData.DescricaoStatus === "Em liberação")
                                                                                        ? <a
                                                                                            role="button"
                                                                                            tabIndex={0}
                                                                                            className="delete text-danger text-uppercase text-strong text-sm mr-10"
                                                                                            onClick={() => {
                                                                                                objProps.ModalCancelar.IdCancelar = rowData.ID;
                                                                                                objProps.fnUpdateState(objProps);
                                                                                                objProps.fnOpenModal();
                                                                                            }}>Cancelar</a>
                                                                                        : null
                                                                                }
                                                                            </td>
                                                                        </tr>
                                                                    )
                                                                })}
                                                </tbody>
                                            </table>
                                        </div>
                                    </Row>
                                    <Row>
                                        <div className="col-sm-5">
                                            <div className="dataTables_info"> Mostrando&nbsp; {
                                                (this.props.Filters.PageSize > this.props.Filters.TotalNumberOfRecords)
                                                    ? this.props.Filters.TotalNumberOfRecords : this.props.Filters.PageSize
                                            } de {this.props.Filters.TotalNumberOfRecords}
                                            </div>
                                        </div>
                                        <div className="col-sm-7">
                                            <div className="dataTables_paginate paging_simple_numbers">
                                                <ListPager
                                                    CurrentPage={this.props.Filters.PageNumber}
                                                    Size={this.props.Filters.TotalNumberOfPages}
                                                    OnPageChanged={(page) => {
                                                        this.props.Filters.PageNumber = page;
                                                        this.props.requestListPagamentos();
                                                    }} />
                                            </div>
                                        </div>
                                    </Row>
                                </div>
                            </div>
                        </div>
                    </Section>
                </div>
                <Modal show={this.props.ModalCancelar.ShowModalCancelar} onHide={() => { this.props.fnCloseModal(); }}>
                    <Modal.Header closeButton>
                        <h3 className="modal-title custom-font">Cancelamento Solicitação de Crédito</h3>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="row">
                            <div className="form-group col-md-12">
                                <p>Tem certeza que deseja cancelar o crédito solicitado?</p>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <BtnSalvar
                            type="button"
                            text=" Confirmar Cancelamento"
                            textLoading=" Aguarde..."
                            isLoading={this.props.IsLoading}
                            onClick={() => { this.props.fnConfirmarCancelamento(); }}
                        />
                        <BtnCancelar
                            type="button"
                            text=" Cancelar"
                            textLoading=" Aguarde..."
                            isLoading={this.props.IsLoading}
                            onClick={() => { this.props.fnCloseModal(); }}
                        />
                    </Modal.Footer>
                </Modal>
            </Row>
        );
    }
}

export default connect(
    state => state.historicopagamentos,
    dispatch => bindActionCreators(actionCreators, dispatch),
    null,
    { pure: false }
)(HistoricoPagamentosForm);