import { fetch, addTask } from 'domain-task';
import * as crmwebmotors_service from '../../services/crm/crmwebmotors_service';

const UPDATE_STATE_CRM_CONSULTA_ACTION = 'UPDATE_STATE_CRM_WEBMOTORS_ACTION';

const REQUEST_CRM_LISTA_LEADS_ACTION = 'REQUEST_CRM_LISTA_LEADS_ACTION';
const RESPONSE_CRM_LISTA_LEADS_ACTION = 'RESPONSE_CRM_LISTA_LEADS_ACTION';
const ERROR_CRM_LISTA_LEADS_ACTION = 'ERROR_CRM_LISTA_LEADS_ACTION';

const POST_CRM_ACEITAR_LEAD_ACTION = 'POST_CRM_ACEITAR_LEAD_ACTION';
const RESPONSE_CRM_ACEITAR_LEAD_ACTION = 'RESPONSE_CRM_ACEITAR_LEAD_ACTION';
const ERROR_CRM_ACEITAR_LEAD_ACTION = 'ERROR_CRM_ACEITAR_LEAD_ACTION';

const POST_CRM_RECUSAR_LEAD_ACTION = 'POST_CRM_RECUSAR_LEAD_ACTION';
const RESPONSE_CRM_RECUSAR_LEAD_ACTION = 'RESPONSE_CRM_RECUSAR_LEAD_ACTION';
const ERROR_CRM_RECUSAR_LEAD_ACTION = 'ERROR_CRM_RECUSAR_LEAD_ACTION';


const initialState = {
    consultaLeads: {
        errorText: '',
        requested: false,
        isLoading: false,
        pageNumber: 0,
        pageSize: 0,
        totalNumberOfPages: 0,
        totalNumberOfRecords: 0,
        status: 1,
        dataDe: null,
        dataAte: null,
        results: []
    },
    modalDetalhes: {
        errorText: '',
        successText: '',
        isLoading: false,
        showModal: false,
        id: 0,
        idStatus: 0,
        lead: {
            id: 0,
            status: '',
            midia: '',
            negociacao: '',
            marca: '',
            modelo: '',
            versao: '',
            anoModelo: 0,
            anoFabricacao: 0,
            placa: '',
            valor: 0,
            nome: '',
            email: '',
            telefone: '',
            mensagem: '',
        }
    }
};

export const actionCreators = {
    fnUpdateState: (pSate) => async (dispatch) => {
        dispatch({
            type: 'UPDATE_STATE_CRM_WEBMOTORS_ACTION',
            consultaLeads: {
                errorText: pSate.consultaLeads.errorText,
                requested: pSate.consultaLeads.requested,
                isLoading: pSate.consultaLeads.isLoading,
                pageNumber: pSate.consultaLeads.pageNumber,
                pageSize: pSate.consultaLeads.pageSize,
                totalNumberOfPages: pSate.consultaLeads.totalNumberOfPages,
                totalNumberOfRecords: pSate.consultaLeads.totalNumberOfRecords,
                status: pSate.consultaLeads.status,
                dataDe: pSate.consultaLeads.dataDe,
                dataAte: pSate.consultaLeads.dataAte,
                results: pSate.consultaLeads.results
            },
            modalDetalhes: {
                errorText: pSate.modalDetalhes.errorText,
                successText: pSate.modalDetalhes.successText,
                isLoading: pSate.modalDetalhes.isLoading,
                showModal: pSate.modalDetalhes.showModal,
                id: pSate.modalDetalhes.id,
                idStatus: pSate.modalDetalhes.idStatus,
                lead: {
                    id: pSate.modalDetalhes.lead.id,
                    status: pSate.modalDetalhes.lead.status,
                    midia: pSate.modalDetalhes.lead.midia,
                    negociacao: pSate.modalDetalhes.lead.negociacao,
                    marca: pSate.modalDetalhes.lead.marca,
                    modelo: pSate.modalDetalhes.lead.modelo,
                    versao: pSate.modalDetalhes.lead.versao,
                    anoModelo: pSate.modalDetalhes.lead.anoModelo,
                    anoFabricacao: pSate.modalDetalhes.lead.anoFabricacao,
                    placa: pSate.modalDetalhes.lead.placa,
                    valor: pSate.modalDetalhes.lead.valor,
                    nome: pSate.modalDetalhes.lead.nome,
                    email: pSate.modalDetalhes.lead.email,
                    telefone: pSate.modalDetalhes.lead.telefone,
                    mensagem: pSate.modalDetalhes.lead.mensagem,
                }
            }
        });
    },
    fnListaLeads: () => async (dispatch, getState) => {
        dispatch({ type: 'REQUEST_CRM_LISTA_LEADS_ACTION' });

        var pDTO = {
            pageNumber: getState().crm_webmotors.consultaLeads.pageNumber,
            pageSize: getState().crm_webmotors.consultaLeads.pageSize,
            status: getState().crm_webmotors.consultaLeads.status,
            dataDe: getState().crm_webmotors.consultaLeads.dataDe,
            dataAte: getState().crm_webmotors.consultaLeads.dataAte,
        };

        await fetch(crmwebmotors_service.services.listaLeads(getState().auth.authorizationToken, pDTO))
            .then(response => {
                if (response.status === 200 || response.status === 0) {
                    return response;
                }
                else {
                    return response.json().then(function (object) {
                        return Promise.reject(new Error(object));
                    })
                }
            })
            .then(data => {
                data.json().then(function (object) {
                    var dtoListResult = [];
                    object.forEach(function (entry) {
                        dtoListResult.push({
                            id: entry.id,
                            status: entry.status,
                            datahoraCadastro: entry.datahoraCadastro,
                            jsonLead: entry.jsonLead,
                            datahoraAceiteRecusa: entry.datahoraAceiteRecusa,
                            idUsuarioAceiteRecusa: entry.idUsuarioAceiteRecusa,
                            jsonLeadAceiteRecusa: entry.jsonLeadAceiteRecusa,
                            jsonProcessamento: entry.jsonProcessamento,
                            totalNumberOfPages: entry.totalNumberOfPages,
                            totalNumberOfRecords: entry.totalNumberOfRecords
                        });
                    });

                    dispatch({
                        type: 'RESPONSE_CRM_LISTA_LEADS_ACTION',
                        results: dtoListResult,
                        totalNumberOfPages: (dtoListResult.length > 0 ? dtoListResult[0].totalNumberOfPages : 0),
                        totalNumberOfRecords: (dtoListResult.length > 0 ? dtoListResult[0].totalNumberOfRecords : 0)
                    });
                });
            }).catch(function (err) {
                dispatch({ type: 'ERROR_CRM_LISTA_LEADS_ACTION', errorText: err.toString() });
            });
    },
    fnRecusarLead: () => async (dispatch, getState) => {
        dispatch({ type: 'POST_CRM_RECUSAR_LEAD_ACTION' });

        await fetch(crmwebmotors_service.services.recusarLead(getState().auth.authorizationToken, getState().crm_webmotors.modalDetalhes.id))
            .then(response => {
                if (response.status === 200 || response.status === 0) {
                    return response;
                }
                else {
                    return response.json().then(function (object) {
                        return Promise.reject(new Error(object));
                    })
                }
            })
            .then(data => {
                data.json().then(function (object) {
                    dispatch({ type: 'RESPONSE_CRM_RECUSAR_LEAD_ACTION' });
                });
            }).catch(function (err) {
                dispatch({ type: 'ERROR_CRM_RECUSAR_LEAD_ACTION', errorText: err.toString() });
            });
    },
    fnAceitarLead: () => async (dispatch, getState) => {
        dispatch({ type: 'POST_CRM_ACEITAR_LEAD_ACTION' });

        await fetch(crmwebmotors_service.services.aceitarLead(getState().auth.authorizationToken, getState().crm_webmotors.modalDetalhes.id))
            .then(response => {
                if (response.status === 200 || response.status === 0) {
                    return response;
                }
                else {
                    return response.json().then(function (object) {
                        return Promise.reject(new Error(object));
                    })
                }
            })
            .then(data => {
                data.json().then(function (object) {
                    dispatch({ type: 'RESPONSE_CRM_ACEITAR_LEAD_ACTION' });
                });
            }).catch(function (err) {
                dispatch({ type: 'ERROR_CRM_ACEITAR_LEAD_ACTION', errorText: err.toString() });
            });
    }
};

export const reducer = (state, action) => {
    state = state || initialState;

    if (action.type === UPDATE_STATE_CRM_CONSULTA_ACTION) {
        return {
            ...state,
            consultaLeads: action.consultaLeads
        };
    }

    if (action.type === REQUEST_CRM_LISTA_LEADS_ACTION) {
        return {
            ...state,
            consultaLeads: {
                ...state.consultaLeads,
                errorText: '',
                requested: true,
                isLoading: true,
                results: []
            }
        };
    }
    if (action.type === RESPONSE_CRM_LISTA_LEADS_ACTION) {
        return {
            ...state,
            consultaLeads: {
                ...state.consultaLeads,
                isLoading: false,
                results: action.results,
                totalNumberOfPages: action.totalNumberOfPages,
                totalNumberOfRecords: action.totalNumberOfRecords
            }
        };
    }
    if (action.type === ERROR_CRM_LISTA_LEADS_ACTION) {
        return {
            ...state,
            consultaLeads: {
                ...state.consultaLeads,
                isLoading: false,
                errorText: action.errorText
            }
        };
    }

    if (action.type === POST_CRM_ACEITAR_LEAD_ACTION) {
        return {
            ...state,
            modalDetalhes: {
                ...state.modalDetalhes,
                errorText: "",
                successText: "",
                isLoading: true
            }
        };
    }
    if (action.type === RESPONSE_CRM_ACEITAR_LEAD_ACTION) {
        var listLeads = state.consultaLeads.results;
        listLeads.map(function (lead, index) {
            if (lead.id === state.modalDetalhes.id) {
                listLeads[index].status = 2;
            }
        });
            
        return {
            ...state,
            consultaLeads: {
                ...state.consultaLeads,
                results: listLeads
            },
            modalDetalhes: {
                ...state.modalDetalhes,
                errorText: "",
                successText: "",
                isLoading: false,
                showModal: false
            }
        };
    }
    if (action.type === ERROR_CRM_ACEITAR_LEAD_ACTION) {
        return {
            ...state,
            modalDetalhes: {
                ...state.modalDetalhes,
                errorText: action.errorText,
                isLoading: false
            }
        };
    }

    if (action.type === POST_CRM_RECUSAR_LEAD_ACTION) {
        return {
            ...state,
            modalDetalhes: {
                ...state.modalDetalhes,
                errorText: "",
                successText: "",
                isLoading: true
            }
        };
    }
    if (action.type === RESPONSE_CRM_RECUSAR_LEAD_ACTION) {
        var listLeads = state.consultaLeads.results;
        listLeads.map(function (lead, index) {
            if (lead.id === state.modalDetalhes.id) {
                listLeads[index].status = 3;
            }
        });

        return {
            ...state,
            consultaLeads: {
                ...state.consultaLeads,
                results: listLeads
            },
            modalDetalhes: {
                ...state.modalDetalhes,
                errorText: "",
                successText: "",
                isLoading: false,
                showModal: false
            }
        };
    }
    if (action.type === ERROR_CRM_RECUSAR_LEAD_ACTION) {
        return {
            ...state,
            modalDetalhes: {
                ...state.modalDetalhes,
                errorText: action.errorText,
                isLoading: false
            }
        };
    }

    return state;
};