import * as React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actionCreators } from '../../../store/crm/CrmConsulta';

import { Row, Panel, Modal } from 'react-bootstrap';
import AlertError from '../../commons/AlertError';
import AlertSuccess from '../../commons/AlertSuccess';
import ProgerssBar from '../../commons/ProgressBar';
import InputSelect from '../../commons/InputSelect';
import BtnSalvar from '../../commons/BtnSalvar';
import BtnCancelar from '../../commons/BtnCancelar';
import DateTimePicker from '../../commons/DateTimePicker';
import TextAreaValidated from '../../commons/TextAreaValidated';

import InputText from '../../commons/InputText';
import Section from '../../commons/Section';




class ModalFinalizar extends React.Component {
    constructor(props) {
        super(props);
    }

    async componentDidMount() {

        //load drop motivo de acordo com a intenção
        if (this.props.modalFinalizar.ehVendido) {
            this.props.fnListaMotivoVendido();
        } else {
            this.props.fnListaMotivoPerdido();
        }
    }

    handleConfirmar() {

        if (this.props.modalFinalizar.ehVendido) {
            this.props.fnVendidoOportunidade();
        } else {
            this.props.fnPerdidoOportunidade();
        }
    }

    handleOnHide() {
        this.props.modalFinalizar.showModal = false;
        this.props.fnUpdateState(this.props);
    }

    render() {
        const { ehVendido, encerramento } = this.props.modalFinalizar;

        var dropdownMotivo = [];
        dropdownMotivo.push({ value: null, text: '' });
        encerramento.dropdownMotivo.results.forEach(function (entry) {
            dropdownMotivo.push({ value: entry.id.toString(), text: entry.nome });
        });



        return (<Modal
            show={this.props.modalFinalizar.showModal}
            onHide={this.handleOnHide.bind(this)}
            bsSize="large"
            >
            <Modal.Header closeButton bsStyle="primary">
                <h3 className="modal-title custom-font">Oportunidade : {ehVendido
                    ? <span className="badge badge-success">Vendido</span>
                    : <span className="badge badge-danger">Perdido</span>
                    }</h3>
            </Modal.Header>
            <Modal.Body>
                <AlertSuccess
                    successText={this.props.modalFinalizar.successText}
                    alertVisible={this.props.modalFinalizar.successText != '' && this.props.modalFinalizar.successText != undefined ? true : false}
                    onDismiss={(alertVisible, successText) => {
                        this.props.modalFinalizar.successText = successText;
                        this.props.fnUpdateState(this.props);
                    }}
                />
                <AlertError
                    errorText={this.props.modalFinalizar.errorText}
                    alertVisible={this.props.modalFinalizar.errorText != '' && this.props.modalFinalizar.errorText != undefined ? true : false}
                    onDismiss={(alertVisible, errorText) => {
                        this.props.modalFinalizar.errorText = errorText;
                        this.props.fnUpdateState(this.props);
                    }}
                />
                {
                    this.props.modalFinalizar.isLoading
                        ? <ProgerssBar />
                        : <div>
                            <Row>
                                <InputSelect
                                    required
                                    name="ddlMotivo"
                                    className="col-md-6"
                                    data={
                                        encerramento.dropdownMotivo.isLoading
                                            ? [{ value: null, text: 'Carregando...' }]
                                            : encerramento.dropdownMotivo.errorText != ''
                                                ? [{ value: null, text: encerramento.dropdownMotivo.errorText }]
                                                : dropdownMotivo
                                    }
                                    onChange={(name, value) => {
                                        encerramento.idMotivo = value;
                                        this.props.fnUpdateState(this.props);
                                    }}
                                    title="Motivo:"
                                    disabled={
                                        encerramento.dropdownMotivo.isLoading ? true : false
                                    }
                                    selectedValue={
                                        encerramento.idMotivo == null
                                            ? null
                                            : encerramento.idMotivo.toString()
                                    }
                                />
                                <div className="form-group col-md-4">
                                    <label>Data de Encerramento</label>
                                    <DateTimePicker
                                        onChange={(value) => {
                                            encerramento.dataEncerramento = value;
                                            this.props.fnUpdateState(this.props);
                                        }}
                                        required
                                        value={encerramento.dataEncerramento}
                                    />
                                </div>

                                <InputText
                                    name="txtComentario"
                                    placeholder=""
                                    className="col-md-12"
                                    onChange={(name, value) => {
                                        encerramento.comentarios = value;
                                        this.props.fnUpdateState(this.props);
                                    }}
                                    value={encerramento.comentarios}
                                    title="Comentários:"
                                />


                                <div className="col-md-12 text-right pull-right">
                                    <BtnCancelar
                                        isLoading={this.props.modalFinalizar.isLoading}
                                        text={this.props.modalFinalizar.isLoading ? " Aguarde..." : " Cancelar"}
                                        disabled={this.props.modalFinalizar.isLoading}
                                        onClick={() => {
                                            this.props.modalFinalizar.showModal = false;
                                            this.props.fnUpdateState(this.props);
                                        }}
                                    />

                                    <BtnSalvar
                                        isLoading={this.props.modalFinalizar.isLoading}
                                        text={this.props.modalFinalizar.isLoading ? " Aguarde..." : " Confirmar"}
                                        disabled={this.props.modalFinalizar.isLoading}
                                        onClick={this.handleConfirmar.bind(this)}
                                    />
                                </div>
                            </Row>
                        </div>
                }
            </Modal.Body>
        </Modal>);
    }
}


export default connect(
    state => state.crm_consulta,
    dispatch => bindActionCreators(actionCreators, dispatch),
    null, {}
)(ModalFinalizar);