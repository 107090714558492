import { fetch } from 'domain-task';
import * as daypagconsulta_service from '../../../services/documentos/daypag/daypagconsulta_service';

const UPDATE_STATE_DAYPAG_CONSULTA_ACTION = 'UPDATE_STATE_DAYPAG_CONSULTA_ACTION';
const REQUEST_TIPO_SERVICO_ACTION = 'REQUEST_TIPO_SERVICO_ACTION';
const RESPONSE_TIPO_SERVICO_ACTION = 'RESPONSE_TIPO_SERVICO_ACTION';
const ERROR_TIPO_SERVICO_ACTION = 'ERROR_TIPO_SERVICO_ACTION';
const REQUEST_TIPO_SUB_SERVICO_ACTION = 'REQUEST_TIPO_SUB_SERVICO_ACTION';
const RESPONSE_TIPO_SUB_SERVICO_ACTION = 'RESPONSE_TIPO_SUB_SERVICO_ACTION';
const ERROR_TIPO_SUB_SERVICO_ACTION = 'ERROR_TIPO_SUB_SERVICO_ACTION';
const POST_CONSULTA_DETALHADA_ACTION = 'POST_CONSULTA_DETALHADA_ACTION';
const RESPONSE_CONSULTA_DETALHADA_ACTION = 'RESPONSE_CONSULTA_DETALHADA_ACTION';
const ERROR_CONSULTA_DETALHADA_ACTION = 'ERROR_CONSULTA_DETALHADA_ACTION';
const POST_EFETUAR_PAGAMENTO_ACTION = 'POST_EFETUAR_PAGAMENTO_ACTION';
const RESPONSE_EFETUAR_PAGAMENTO_ACTION = 'RESPONSE_EFETUAR_PAGAMENTO_ACTION';
const ERROR_EFETUAR_PAGAMENTO_ACTION = 'ERROR_EFETUAR_PAGAMENTO_ACTION';
const REQUEST_OBTER_SALDO_ACTION = 'REQUEST_OBTER_SALDO_ACTION';
const RESPONSE_OBTER_SALDO_ACTION = 'RESPONSE_OBTER_SALDO_ACTION';
const ERROR_OBTER_SALDO_ACTION = 'ERROR_OBTER_SALDO_ACTION';

const initialState = {
    consultaSaldo: {
        errorText: '',
        isLoading: false,
        saldo: 0
    },
    consultaDebitos: {
        errorText: '',
        isLoading: false,
        renavam: '',
        tipoServico: '',
        tipoSubServico: '',
        dropdownMunicipio: {
            errorText: '',
            isLoading: false,
            results: []
        },
        dropdownTipoServico: {
            errorText: '',
            isLoading: false,
            results: []
        },
        dropdownTipoSubServico: {
            errorText: '',
            isLoading: false,
            results: []
        },
        result: []
    },
    pagamento: {
        isLoading: false,
        errorText: ''
    }
};

export const actionCreators = {
    fnUpdateState: (pDaypagConsultaState) => async (dispatch) => {
        dispatch({
            type: 'UPDATE_STATE_DAYPAG_CONSULTA_ACTION',
            consultaSaldo: {
                errorText: pDaypagConsultaState.consultaSaldo.errorText,
                isLoading: pDaypagConsultaState.consultaSaldo.isLoading,
                saldo: pDaypagConsultaState.consultaSaldo.saldo
            },
            consultaDebitos: {
                errorText: pDaypagConsultaState.consultaDebitos.errorText,
                isLoading: pDaypagConsultaState.consultaDebitos.isLoading,
                renavam: pDaypagConsultaState.consultaDebitos.renavam,
                tipoServico: pDaypagConsultaState.consultaDebitos.tipoServico,
                tipoSubServico: pDaypagConsultaState.consultaDebitos.tipoSubServico,
                dropdownMunicipio: {
                    errorText: pDaypagConsultaState.consultaDebitos.dropdownMunicipio.errorText,
                    isLoading: pDaypagConsultaState.consultaDebitos.dropdownMunicipio.isLoading,
                    results: pDaypagConsultaState.consultaDebitos.dropdownMunicipio.results
                },
                dropdownTipoServico: {
                    errorText: pDaypagConsultaState.consultaDebitos.dropdownTipoServico.errorText,
                    isLoading: pDaypagConsultaState.consultaDebitos.dropdownTipoServico.isLoading,
                    results: pDaypagConsultaState.consultaDebitos.dropdownTipoServico.results
                },
                dropdownTipoSubServico: {
                    errorText: pDaypagConsultaState.consultaDebitos.dropdownTipoSubServico.errorText,
                    isLoading: pDaypagConsultaState.consultaDebitos.dropdownTipoSubServico.isLoading,
                    results: pDaypagConsultaState.consultaDebitos.dropdownTipoSubServico.results
                },
                result: pDaypagConsultaState.consultaDebitos.result
            },
            pagamento: {
                isLoading: pDaypagConsultaState.pagamento.isLoading,
                errorText: pDaypagConsultaState.pagamento.errorText
            }
        });
    },
    fnListaTipoServico: () => async (dispatch, getState) => {
        dispatch({ type: 'REQUEST_TIPO_SERVICO_ACTION' });

        await fetch(daypagconsulta_service.services.listaTipoServico(getState().auth.authorizationToken))
            .then(response => {
                if (response.status === 200 || response.status === 0) {
                    return response;
                }
                else {
                    return response.json().then(function (object) {
                        return Promise.reject(new Error(object));
                    })
                }
            })
            .then(data => {
                data.json().then(function (object) {
                    dispatch({ type: 'RESPONSE_TIPO_SERVICO_ACTION', results: object });
                });
            }).catch(function (err) {
                dispatch({ type: 'ERROR_TIPO_SERVICO_ACTION', errorText: err.toString() });
            });
    },
    fnListaTipoSubServico: (idTipoServico) => async (dispatch, getState) => {
        dispatch({ type: 'REQUEST_TIPO_SUB_SERVICO_ACTION' });

        await fetch(daypagconsulta_service.services.listaTipoSubServico(idTipoServico, getState().auth.authorizationToken))
            .then(response => {
                if (response.status === 200 || response.status === 0) {
                    return response;
                }
                else {
                    return response.json().then(function (object) {
                        return Promise.reject(new Error(object));
                    })
                }
            })
            .then(data => {
                data.json().then(function (object) {
                    dispatch({ type: 'RESPONSE_TIPO_SUB_SERVICO_ACTION', results: object });
                });
            }).catch(function (err) {
                dispatch({ type: 'ERROR_TIPO_SUB_SERVICO_ACTION', errorText: err.toString() });
            });
    },
    fnConsultaDetalhada: () => async (dispatch, getState) => {
        var dto = {
            listaParametroDebitoVeiculo: [
                {
                    renavam: getState().daypag_consulta.consultaDebitos.renavam,
                    documento: "",
                    codServico: getState().daypag_consulta.consultaDebitos.tipoServico,
                    codSubServico: getState().daypag_consulta.consultaDebitos.tipoSubServico,
                    tipoConsulta: "D"
                }
            ]
        };

        dispatch({ type: 'POST_CONSULTA_DETALHADA_ACTION' });

        await fetch(daypagconsulta_service.services.consultaDetalhada(dto, getState().auth.authorizationToken))
            .then(response => {
                if (response.status === 200 || response.status === 0) {
                    return response;
                }
                else {
                    return response.json().then(function (object) {
                        return Promise.reject(new Error(object));
                    })
                }
            })
            .then(data => {
                data.json().then(function (object) {
                    console.log(object);
                    dispatch({ type: 'RESPONSE_CONSULTA_DETALHADA_ACTION', result: object.ListaDebitos });
                });
            }).catch(function (err) {
                dispatch({ type: 'ERROR_CONSULTA_DETALHADA_ACTION', errorText: err.toString() });
            });
    },
    fnPagamento: () => async (dispatch, getState) => {
        var dto = {};

        if (getState().daypag_consulta.consultaDebitos.tipoServico == "001"
            || getState().daypag_consulta.consultaDebitos.tipoServico == "002"
            || getState().daypag_consulta.consultaDebitos.tipoServico == "003"
            || getState().daypag_consulta.consultaDebitos.tipoServico == "004"
            || getState().daypag_consulta.consultaDebitos.tipoServico == "005"
            || getState().daypag_consulta.consultaDebitos.tipoServico == "007") {

            dto["listaParametroPagamentoVeiculo"] = [];
            dto["listaParametroPagamentoVeiculo"].push(
                {
                    codServico: getState().daypag_consulta.consultaDebitos.tipoServico,
                    valortotal: getState().daypag_consulta.consultaDebitos.result[0].ValorTotal,
                    renavam: getState().daypag_consulta.consultaDebitos.renavam,
                    localEntrega: "D"
                }
            );
        }
        else if (getState().daypag_consulta.consultaDebitos.tipoServico == "006") {
            dto["listaParametroPagamentoVeiculo"] = [];
            dto["listaParametroPagamentoVeiculo"].push(
                {
                    codServico: getState().daypag_consulta.consultaDebitos.tipoServico,
                    valortotal: getState().daypag_consulta.consultaDebitos.result[0].ValorTotal,
                    renavam: getState().daypag_consulta.consultaDebitos.renavam,
                    placa: getState().daypag_consulta.consultaDebitos.result[0].Placa,
                    documento: getState().daypag_consulta.consultaDebitos.result[0].Documento,
                    nome: getState().daypag_consulta.consultaDebitos.result[0].Nome,
                    codFederal: "7107"
                }
            );
        }
        else if (getState().daypag_consulta.consultaDebitos.tipoServico == "008") {
            dto["listaParametroPagamentoVeiculo"] = [];
            dto["listaParametroPagamentoVeiculo"].push(
                {
                    codServico: getState().daypag_consulta.consultaDebitos.tipoServico,
                    codSubServico: getState().daypag_consulta.consultaDebitos.tipoSubServico,
                    valortotal: getState().daypag_consulta.consultaDebitos.result[0].ValorTotal,
                    renavam: getState().daypag_consulta.consultaDebitos.renavam
                }
            );
        }

        dispatch({ type: 'POST_EFETUAR_PAGAMENTO_ACTION' });

        await fetch(daypagconsulta_service.services.efetuarPagamento(dto, getState().auth.authorizationToken))
            .then(response => {
                if (response.status === 200 || response.status === 0) {
                    return response;
                }
                else {
                    return response.json().then(function (object) {
                        return Promise.reject(new Error(object));
                    })
                }
            })
            .then(data => {
                data.json().then(function (object) {
                    dispatch({ type: 'RESPONSE_EFETUAR_PAGAMENTO_ACTION' });
                });
            }).catch(function (err) {
                dispatch({ type: 'ERROR_EFETUAR_PAGAMENTO_ACTION', errorText: err.toString() });
            });
    },
    fnObterSaldo: () => async (dispatch, getState) => {
        dispatch({ type: 'REQUEST_OBTER_SALDO_ACTION' });

        await fetch(daypagconsulta_service.services.obterSaldo(getState().auth.authorizationToken))
            .then(response => {
                if (response.status === 200 || response.status === 0) {
                    return response;
                }
                else {
                    return response.json().then(function (object) {
                        return Promise.reject(new Error(object));
                    })
                }
            })
            .then(data => {
                data.json().then(function (object) {
                    dispatch({ type: 'RESPONSE_OBTER_SALDO_ACTION', saldo: object.SaldoDisponivel.Valor });
                });
            }).catch(function (err) {
                dispatch({ type: 'ERROR_OBTER_SALDO_ACTION', errorText: err.toString() });
            });
    }
};

export const reducer = (state, action) => {
    state = state || initialState;



    if (action.type === UPDATE_STATE_DAYPAG_CONSULTA_ACTION) {
        return {
            ...state,
            consultaSaldo: action.consultaSaldo,
            consultaDebitos: action.consultaDebitos,
            pagamento: action.pagamento
        };
    }



    if (action.type === REQUEST_TIPO_SERVICO_ACTION) {
        return {
            ...state,
            consultaDebitos: {
                ...state.consultaDebitos,
                dropdownTipoServico: {
                    errorText: '',
                    isLoading: true,
                    results: []
                }
            }
        };
    }
    if (action.type === RESPONSE_TIPO_SERVICO_ACTION) {
        return {
            ...state,
            consultaDebitos: {
                ...state.consultaDebitos,
                dropdownTipoServico: {
                    ...state.consultaDebitos.dropdownTipoServico,
                    isLoading: false,
                    results: action.results
                }
            }
        };
    }
    if (action.type === ERROR_TIPO_SERVICO_ACTION) {
        return {
            ...state,
            consultaDebitos: {
                ...state.consultaDebitos,
                dropdownTipoServico: {
                    ...state.consultaDebitos.dropdownTipoServico,
                    isLoading: false,
                    errorText: action.errorText
                }
            }
        };
    }



    if (action.type === REQUEST_TIPO_SUB_SERVICO_ACTION) {
        return {
            ...state,
            consultaDebitos: {
                ...state.consultaDebitos,
                dropdownTipoSubServico: {
                    errorText: '',
                    isLoading: true,
                    results: []
                }
            }
        };
    }
    if (action.type === RESPONSE_TIPO_SUB_SERVICO_ACTION) {
        return {
            ...state,
            consultaDebitos: {
                ...state.consultaDebitos,
                dropdownTipoSubServico: {
                    ...state.consultaDebitos.dropdownTipoSubServico,
                    isLoading: false,
                    results: action.results
                }
            }
        };
    }
    if (action.type === ERROR_TIPO_SUB_SERVICO_ACTION) {
        return {
            ...state,
            consultaDebitos: {
                ...state.consultaDebitos,
                dropdownTipoSubServico: {
                    ...state.consultaDebitos.dropdownTipoSubServico,
                    isLoading: false,
                    errorText: action.errorText
                }
            }

        };
    }



    if (action.type === POST_CONSULTA_DETALHADA_ACTION) {
        return {
            ...state,
            consultaDebitos: {
                ...state.consultaDebitos,
                errorText: '',
                isLoading: true,
                result: []
            }
        };
    }
    if (action.type === RESPONSE_CONSULTA_DETALHADA_ACTION) {
        return {
            ...state,
            consultaDebitos: {
                ...state.consultaDebitos,
                isLoading: false,
                result: action.result
            }
        };
    }
    if (action.type === ERROR_CONSULTA_DETALHADA_ACTION) {
        return {
            ...state,
            consultaDebitos: {
                ...state.consultaDebitos,
                isLoading: false,
                errorText: action.errorText
            }
        };
    }



    if (action.type === POST_EFETUAR_PAGAMENTO_ACTION) {
        return {
            ...state,
            pagamento: {
                ...state.pagamento,
                errorText: '',
                isLoading: true
            }
        };
    }
    if (action.type === RESPONSE_EFETUAR_PAGAMENTO_ACTION) {
        return {
            ...state,
            pagamento: {
                ...state.pagamento,
                isLoading: false
            }
        };
    }
    if (action.type === ERROR_EFETUAR_PAGAMENTO_ACTION) {
        return {
            ...state,
            pagamento: {
                ...state.pagamento,
                isLoading: false,
                errorText: action.errorText
            }
        };
    }



    if (action.type === REQUEST_OBTER_SALDO_ACTION) {
        return {
            ...state,
            consultaSaldo: {
                errorText: '',
                isLoading: true,
                saldo: 0
            }
        };
    }
    if (action.type === RESPONSE_OBTER_SALDO_ACTION) {
        return {
            ...state,
            consultaSaldo: {
                isLoading: false,
                saldo: action.saldo
            }
        };
    }
    if (action.type === ERROR_OBTER_SALDO_ACTION) {
        return {
            ...state,
            consultaSaldo: {
                isLoading: false,
                errorText: action.errorText
            }
        };
    }


    return state;
};

