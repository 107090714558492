import { fetch, addTask } from 'domain-task';
import * as crmconsulta_service from '../../services/crm/crmconsulta_service';


const UPDATE_STATE_CRM_CONSULTA_ACTION = 'UPDATE_STATE_CRM_CONSULTA_ACTION';

const REQUEST_CRM_STATUS_ACTION = 'REQUEST_CRM_STATUS_ACTION';
const RESPONSE_CRM_STATUS_ACTION = 'RESPONSE_CRM_STATUS_ACTION';
const ERROR_CRM_STATUS_ACTION = 'ERROR_CRM_STATUS_ACTION';

const REQUEST_CRM_ETAPA_ACTION = 'REQUEST_CRM_ETAPA_ACTION';
const RESPONSE_CRM_ETAPA_ACTION = 'RESPONSE_CRM_ETAPA_ACTION';
const ERROR_CRM_ETAPA_ACTION = 'ERROR_CRM_ETAPA_ACTION';

const REQUEST_CRM_RESPONSAVEL_ACTION = 'REQUEST_CRM_RESPONSAVEL_ACTION';
const RESPONSE_CRM_RESPONSAVEL_ACTION = 'RESPONSE_CRM_RESPONSAVEL_ACTION';
const ERROR_CRM_RESPONSAVEL_ACTION = 'ERROR_CRM_RESPONSAVEL_ACTION';

const REQUEST_CRM_LISTA_OPORTUNIDADES_ACTION = 'REQUEST_CRM_LISTA_OPORTUNIDADES_ACTION';
const RESPONSE_CRM_LISTA_OPORTUNIDADES_ACTION = 'RESPONSE_CRM_LISTA_OPORTUNIDADES_ACTION';
const ERROR_CRM_LISTA_OPORTUNIDADES_ACTION = 'ERROR_CRM_LISTA_OPORTUNIDADES_ACTION';

const REQUEST_CRM_LISTA_OPORTUNIDADES_EXPORT_ACTION = 'REQUEST_CRM_LISTA_OPORTUNIDADES_EXPORT_ACTION';
const RESPONSE_CRM_LISTA_OPORTUNIDADES_EXPORT_ACTION = 'RESPONSE_CRM_LISTA_OPORTUNIDADES_EXPORT_ACTION';
const ERROR_CRM_LISTA_OPORTUNIDADES_EXPORT_ACTION = 'ERROR_CRM_LISTA_OPORTUNIDADES_EXPORT_ACTION';

const REQUEST_CRM_OBTER_OPORTUNIDADE_ACTION = 'REQUEST_CRM_OBTER_OPORTUNIDADE_ACTION';
const RESPONSE_CRM_OBTER_OPORTUNIDADE_ACTION = 'RESPONSE_CRM_OBTER_OPORTUNIDADE_ACTION';
const ERROR_CRM_OBTER_OPORTUNIDADE_ACTION = 'ERROR_CRM_OBTER_OPORTUNIDADE_ACTION';

const REQUEST_CRM_LISTA_ATIVIDADES_OPORTUNIDADE_ACTION = 'REQUEST_CRM_LISTA_ATIVIDADES_OPORTUNIDADE_ACTION';
const RESPONSE_CRM_LISTA_ATIVIDADES_OPORTUNIDADE_ACTION = 'RESPONSE_CRM_LISTA_ATIVIDADES_OPORTUNIDADE_ACTION';
const ERROR_CRM_LISTA_ATIVIDADES_OPORTUNIDADE_ACTION = 'ERROR_CRM_LISTA_ATIVIDADES_OPORTUNIDADE_ACTION';

const REQUEST_CRM_TIMELINE_OPORTUNIDADE_ACTION = 'REQUEST_CRM_TIMELINE_OPORTUNIDADE_ACTION';
const RESPONSE_CRM_TIMELINE_OPORTUNIDADE_ACTION = 'RESPONSE_CRM_TIMELINE_OPORTUNIDADE_ACTION';
const ERROR_CRM_TIMELINE_OPORTUNIDADE_ACTION = 'ERROR_CRM_TIMELINE_OPORTUNIDADE_ACTION';

const REQUEST_CRM_LISTA_USUARIOS_ACTION = 'REQUEST_CRM_LISTA_USUARIOS_ACTION';
const RESPONSE_CRM_LISTA_USUARIOS_ACTION = 'RESPONSE_CRM_LISTA_USUARIOS_ACTION';
const ERROR_CRM_LISTA_USUARIOS_ACTION = 'ERROR_CRM_LISTA_USUARIOS_ACTION';

const PUT_CRM_ATUALIZAR_USUARIO_ACTION = 'PUT_CRM_ATUALIZAR_USUARIO_ACTION';
const SUCCESS_CRM_ATUALIZAR_USUARIO_ACTION = 'SUCCESS_CRM_ATUALIZAR_USUARIO_ACTION';
const ERROR_CRM_ATUALIZAR_USUARIO_ACTION = 'ERROR_CRM_ATUALIZAR_USUARIO_ACTION';

const REQUEST_CRM_LISTA_CANAIS_ATIVIDADE_ACTION = 'REQUEST_CRM_LISTA_CANAIS_ATIVIDADE_ACTION';
const RESPONSE_CRM_LISTA_CANAIS_ATIVIDADE_ACTION = 'RESPONSE_CRM_LISTA_CANAIS_ATIVIDADE_ACTION';
const ERROR_CRM_LISTA_CANAIS_ATIVIDADE_ACTION = 'ERROR_CRM_LISTA_CANAIS_ATIVIDADE_ACTION';

const POST_CRM_ATIVIDADE_ACTION = 'POST_CRM_ATIVIDADE_ACTION';
const SUCCESS_CRM_ATIVIDADE_ACTION = 'SUCCESS_CRM_ATIVIDADE_ACTION';
const ERROR_CRM_ATIVIDADE_ACTION = 'ERROR_CRM_ATIVIDADE_ACTION';

const PUT_CRM_REABRIR_OPORTUNIDADE_ACTION = 'PUT_CRM_REABRIR_OPORTUNIDADE_ACTION';
const SUCCESS_CRM_REABRIR_OPORTUNIDADE_ACTION = 'SUCCESS_CRM_REABRIR_OPORTUNIDADE_ACTION';
const ERROR_CRM_REABRIR_OPORTUNIDADE_ACTION = 'ERROR_CRM_REABRIR_OPORTUNIDADE_ACTION';

const REQUEST_CRM_LISTA_CANAIS_LEAD_ACTION = 'REQUEST_CRM_LISTA_CANAIS_LEAD_ACTION';
const RESPONSE_CRM_LISTA_CANAIS_LEAD_ACTION = 'RESPONSE_CRM_LISTA_CANAIS_LEAD_ACTION';
const ERROR_CRM_LISTA_CANAIS_LEAD_ACTION = 'ERROR_CRM_LISTA_CANAIS_LEAD_ACTION';

const REQUEST_CRM_LISTA_MOTIVO_PERDIDO_ACTION = 'REQUEST_CRM_LISTA_MOTIVO_PERDIDO_ACTION';
const RESPONSE_CRM_LISTA_MOTIVO_PERDIDO_ACTION = 'RESPONSE_CRM_LISTA_MOTIVO_PERDIDO_ACTION';
const ERROR_CRM_LISTA_MOTIVO_PERDIDO_ACTION = 'ERROR_CRM_LISTA_MOTIVO_PERDIDO_ACTION';

const REQUEST_CRM_LISTA_MOTIVO_VENDIDO_ACTION = 'REQUEST_CRM_LISTA_MOTIVO_VENDIDO_ACTION';
const RESPONSE_CRM_LISTA_MOTIVO_VENDIDO_ACTION = 'RESPONSE_CRM_LISTA_MOTIVO_VENDIDO_ACTION';
const ERROR_CRM_LISTA_MOTIVO_VENDIDO_ACTION = 'ERROR_CRM_LISTA_MOTIVO_VENDIDO_ACTION';


const PUT_CRM_VENDIDO_OPORTUNDADE_ACTION = 'PUT_CRM_VENDIDO_OPORTUNDADE_ACTION';
const SUCCESS_CRM_VENDIDO_OPORTUNIDADE_ACTION = 'SUCCESS_CRM_VENDIDO_OPORTUNIDADE_ACTION';
const ERROR_CRM_VENDIDO_OPORTUNIDADE_ACTION = 'ERROR_CRM_VENDIDO_OPORTUNIDADE_ACTION';


const PUT_CRM_PERDIDO_OPORTUNDADE_ACTION = 'PUT_CRM_PERDIDO_OPORTUNDADE_ACTION';
const SUCCESS_CRM_PERDIDO_OPORTUNIDADE_ACTION = 'SUCCESS_CRM_PERDIDO_OPORTUNIDADE_ACTION';
const ERROR_CRM_PERDIDO_OPORTUNIDADE_ACTION = 'ERROR_CRM_PERDIDO_OPORTUNIDADE_ACTION';

const POST_CRM_NOVA_OPORTUNIDADE_ACTION = 'POST_CRM_NOVA_OPORTUNIDADE_ACTION';
const SUCCESS_CRM_NOVA_OPORTUNIDADE_ACTION = 'SUCCESS_CRM_NOVA_OPORTUNIDADE_ACTION';
const ERROR_CRM_NOVA_OPORTUNIDADE_ACTION = 'ERROR_CRM_NOVA_OPORTUNIDADE_ACTION';

const REQUEST_CRM_DETALHES_LISTA_TEMPERATURA_ACTION = 'REQUEST_CRM_DETALHES_LISTA_TEMPERATURA_ACTION';
const RESPONSE_CRM_DETALHES_LISTA_TEMPERATURA_ACTION = 'RESPONSE_CRM_DETALHES_LISTA_TEMPERATURA_ACTION';
const ERROR_CRM_DETALHES_LISTA_TEMPERATURA_ACTION = 'ERROR_CRM_DETALHES_LISTA_TEMPERATURA_ACTION';


const REQUEST_CRM_CONSULTA_LISTA_TEMPERATURA_ACTION = 'REQUEST_CRM_CONSULTA_LISTA_TEMPERATURA_ACTION';
const RESPONSE_CRM_CONSULTA_LISTA_TEMPERATURA_ACTION = 'RESPONSE_CRM_CONSULTA_LISTA_TEMPERATURA_ACTION';
const ERROR_CRM_CONSULTA_LISTA_TEMPERATURA_ACTION = 'ERROR_CRM_CONSULTA_LISTA_TEMPERATURA_ACTION';

const REQUEST_CRM_NOVOLEAD_LISTA_TEMPERATURA_ACTION = 'REQUEST_CRM_NOVOLEAD_LISTA_TEMPERATURA_ACTION';
const RESPONSE_CRM_NOVOLEAD_LISTA_TEMPERATURA_ACTION = 'RESPONSE_CRM_NOVOLEAD_LISTA_TEMPERATURA_ACTION';
const ERROR_CRM_NOVOLEAD_LISTA_TEMPERATURA_ACTION = 'ERROR_CRM_NOVOLEAD_LISTA_TEMPERATURA_ACTION';

const PUT_CRM_ATUALIZAR_TEMPERATURA_ACTION = 'PUT_CRM_ATUALIZAR_TEMPERATURA_ACTION';
const SUCCESS_CRM_ATUALIZAR_TEMPERATURA_ACTION = 'SUCCESS_CRM_ATUALIZAR_TEMPERATURA_ACTION';
const ERROR_CRM_ATUALIZAR_TEMPERATURA_ACTION = 'ERROR_CRM_ATUALIZAR_TEMPERATURA_ACTION';

const initialState = {
    consultaOportunidades: {
        errorText: '',
        requested: false,
        isLoading: false,
        pageNumber: 0,
        pageSize: 0,
        totalNumberOfPages: 0,
        totalNumberOfRecords: 0,
        idOportunidadeStatus: null,
        idEtapaFunilVendas: null,
        dataDe: null,
        dataAte: null,
        dataDeOriginal: null,
        dataAteOriginal:null,
        naoAtribuido: false,
        idCanalLead: null,
        idResponsavel: null,
        idTemperatura: null,
        dataEncerramentoDe: null,
        dataEncerramentoAte: null,
        dataEncerramentoDeOriginal: null,
        dataEncerramentoAteOriginal: null,
        dropdownStatus: {
            isLoading: false,
            errorText: '',
            results: []
        },
        dropdownEtapa: {
            isLoading: false,
            errorText: '',
            results: []
        },
        dropdownCanalOportunidade: {
            isLoading: false,
            errorText: '',
            results: []
        },
        dropdownResponsavel: {
            isLoading: false,
            errorText: '',
            results: []
        },
        dropdownTemperatura: {
            isLoading: false,
            errorText: '',
            results: []
        },
        results: [],
    },
    consultaOportunidadesExport: {
        errorText: '',
        requested: false,
        isLoading: false,
        results: [],
    },
    modalDetalhes: {
        errorText: '',
        successText: '',
        isLoading: false,
        showModal: false,
        idOportunidade: 0,
        oportunidade: {
            dataCadastro: '',
            emailCliente: '',
            empresaCliente: '',
            idTipoPessoa: '',
            linkAnuncio: '',
            nomeCanalLead: '',
            nomeCliente: '',
            nomeEtapaFunilVendas: '',
            nomeOportunidadeStatus: '',
            oferta: '',
            potencial: 0,
            telefoneCliente: '',
            datahoraEncerrado: null,
            idOportunidadeStatus:0
        },
        atividades: {
            isLoading: false,
            errorText: '',
            results: []
        }
    },
    detalhes: {
        errorText: '',
        successText: '',
        isLoading: false,
        showModal: false,
        idOportunidade: 0,       
        oportunidade: {
            dataCadastro: '',
            emailCliente: '',
            empresaCliente: '',
            idTipoPessoa: '',
            linkAnuncio: '',
            nomeCanalLead: '',
            nomeCliente: '',
            nomeEtapaFunilVendas: '',
            nomeOportunidadeStatus: '',
            oferta: '',
            potencial: 0,
            telefoneCliente: '',
            nomeUsuario: '',
            idTemperatura: null,
            datahoraEncerrado: null,
            idOportunidadeStatus: 0
        },
        atividades: {
            isLoading: false,
            errorText: '',
            results: []
        },
        timeline: {
            isLoading: false,
            errorText: '',
            results: []
        },
        atividade: {
            isLoading: false,
            errorText: '',
            showDateTime: true,
            idCanalAtividade: 0,
            data: '',
            hora: '',
            minuto: '',
            anotacoes: '',
            dropdownAtividades: {
                isLoading: false,
                errorText: '',
                results: []
            }
        },
        dropdownTemperatura: {
            isLoading: false,
            errorText: '',
            results: []
        }
    },
    modalUsuarios: {
        errorText: '',
        successText: '',
        isLoading: false,
        showModal: false,
        idOportunidade: 0,
        responsavelAtual: '',
        idNovoResponsavel: 0,
        dropdownUsuarios: {
            isLoading: false,
            errorText: '',
            results: []
        }
    },
    modalReabrir: {
        errorText: '',
        successText: '',
        isLoading: false,
        showModal: false,
        idOportunidade: 0,
    },
    modalNovoLead: {
        errorText: '',
        successText: '',
        isLoading: false,
        showModal: false,
        idEtapaFunilVendas : null,
        nomeCliente: '',
        idTipoPessoa:null,
        emailCliente: '',
        telefoneCliente: '',
        idCanal: null,
        anuncio: '',
        oferta: '',
        idTemperatura: null,
        dropdownCanalOportunidade: {
            isLoading: false,
            errorText: '',
            results: []
        },
        dropdownEtapaFunilVendas: {
            isLoading: false,
            errorText: '',
            results: []
        },
         dropdownTemperaturas: {
            isLoading: false,
            errorText: '',
            results: []
        }
    },
    modalFinalizar: {
        errorText: '',
        successText: '',
        isLoading: false,
        showModal: false,
        idOportunidade: 0,
        ehVendido: false,
        encerramento: {
            idMotivo: null,
            dataEncerramento: null,
            comentarios: '',
            dropdownMotivo: {
                isLoading: false,
                errorText: '',
                results: []
            },
        }
    }
};

export const actionCreators = {
    fnUpdateState: (pCrmConsultaState) => async (dispatch) => {
        dispatch({
            type: 'UPDATE_STATE_CRM_CONSULTA_ACTION',
            consultaOportunidades: {
                errorText: pCrmConsultaState.consultaOportunidades.errorText,
                requested: pCrmConsultaState.consultaOportunidades.requested,
                isLoading: pCrmConsultaState.consultaOportunidades.isLoading,
                pageNumber: pCrmConsultaState.consultaOportunidades.pageNumber,
                pageSize: pCrmConsultaState.consultaOportunidades.pageSize,
                totalNumberOfPages: pCrmConsultaState.consultaOportunidades.totalNumberOfPages,
                totalNumberOfRecords: pCrmConsultaState.consultaOportunidades.totalNumberOfRecords,
                idOportunidadeStatus: pCrmConsultaState.consultaOportunidades.idOportunidadeStatus,
                idEtapaFunilVendas: pCrmConsultaState.consultaOportunidades.idEtapaFunilVendas,
                dataDe: pCrmConsultaState.consultaOportunidades.dataDe,
                dataAte: pCrmConsultaState.consultaOportunidades.dataAte,
                dataDeOriginal: pCrmConsultaState.consultaOportunidades.dataDeOriginal,
                dataAteOriginal: pCrmConsultaState.consultaOportunidades.dataAteOriginal,
                dataEncerramentoDe: pCrmConsultaState.consultaOportunidades.dataEncerramentoDe,
                dataEncerramentoAte: pCrmConsultaState.consultaOportunidades.dataEncerramentoAte,
                dataEncerramentoDeOriginal: pCrmConsultaState.consultaOportunidades.dataEncerramentoDeOriginal,
                dataEncerramentoAteOriginal: pCrmConsultaState.consultaOportunidades.dataEncerramentoAteOriginal,
                naoAtribuido: pCrmConsultaState.consultaOportunidades.naoAtribuido,
                idCanalLead: pCrmConsultaState.consultaOportunidades.idCanalLead,
                idResponsavel: pCrmConsultaState.consultaOportunidades.idResponsavel,
                idTemperatura: pCrmConsultaState.consultaOportunidades.idTemperatura,
                dropdownStatus: {
                    isLoading: pCrmConsultaState.consultaOportunidades.dropdownStatus.isLoading,
                    errorText: pCrmConsultaState.consultaOportunidades.dropdownStatus.errorText,
                    results: pCrmConsultaState.consultaOportunidades.dropdownStatus.results
                },
                dropdownEtapa: {
                    isLoading: pCrmConsultaState.consultaOportunidades.dropdownEtapa.isLoading,
                    errorText: pCrmConsultaState.consultaOportunidades.dropdownEtapa.errorText,
                    results: pCrmConsultaState.consultaOportunidades.dropdownEtapa.results
                },
                dropdownCanalOportunidade: {
                    isLoading: pCrmConsultaState.consultaOportunidades.dropdownCanalOportunidade.isLoading,
                    errorText: pCrmConsultaState.consultaOportunidades.dropdownCanalOportunidade.errorText,
                    results: pCrmConsultaState.consultaOportunidades.dropdownCanalOportunidade.results
                },
                dropdownResponsavel: {
                    isLoading: pCrmConsultaState.consultaOportunidades.dropdownResponsavel.isLoading,
                    errorText: pCrmConsultaState.consultaOportunidades.dropdownResponsavel.errorText,
                    results: pCrmConsultaState.consultaOportunidades.dropdownResponsavel.results
                },
                dropdownTemperatura: {
                    isLoading: pCrmConsultaState.consultaOportunidades.dropdownTemperatura.isLoading,
                    errorText: pCrmConsultaState.consultaOportunidades.dropdownTemperatura.errorText,
                    results: pCrmConsultaState.consultaOportunidades.dropdownTemperatura.results
                },
                results: pCrmConsultaState.consultaOportunidades.results
            },
            consultaOportunidadesExport: {
                errorText: pCrmConsultaState.consultaOportunidadesExport.errorText,
                requested: pCrmConsultaState.consultaOportunidadesExport.requested,
                isLoading: pCrmConsultaState.consultaOportunidadesExport.isLoading,
                results: pCrmConsultaState.consultaOportunidadesExport.results
            },
            modalDetalhes: {
                errorText: pCrmConsultaState.modalDetalhes.errorText,
                successText: pCrmConsultaState.modalDetalhes.successText,
                isLoading: pCrmConsultaState.modalDetalhes.isLoading,
                showModal: pCrmConsultaState.modalDetalhes.showModal,                
                oportunidade: {
                    dataCadastro: pCrmConsultaState.modalDetalhes.oportunidade.dataCadastro,
                    emailCliente: pCrmConsultaState.modalDetalhes.oportunidade.emailCliente,
                    empresaCliente: pCrmConsultaState.modalDetalhes.oportunidade.empresaCliente,
                    idTipoPessoa: pCrmConsultaState.modalDetalhes.oportunidade.idTipoPessoa,
                    linkAnuncio: pCrmConsultaState.modalDetalhes.oportunidade.linkAnuncio,
                    nomeCanalLead: pCrmConsultaState.modalDetalhes.oportunidade.nomeCanalLead,
                    nomeCliente: pCrmConsultaState.modalDetalhes.oportunidade.nomeCliente,
                    nomeEtapaFunilVendas: pCrmConsultaState.modalDetalhes.oportunidade.nomeEtapaFunilVendas,
                    nomeOportunidadeStatus: pCrmConsultaState.modalDetalhes.oportunidade.nomeOportunidadeStatus,
                    oferta: pCrmConsultaState.modalDetalhes.oportunidade.oferta,
                    potencial: pCrmConsultaState.modalDetalhes.oportunidade.potencial,
                    telefoneCliente: pCrmConsultaState.modalDetalhes.oportunidade.telefoneCliente,
                    idOportunidade: pCrmConsultaState.modalDetalhes.oportunidade.idOportunidade,
                    datahoraEncerrado: pCrmConsultaState.modalDetalhes.oportunidade.datahoraEncerrado,
                    idOportunidadeStatus: pCrmConsultaState.modalDetalhes.oportunidade.idOportunidadeStatus
                },
                atividades: {
                    isLoading: pCrmConsultaState.modalDetalhes.atividades.isLoading,
                    errorText: pCrmConsultaState.modalDetalhes.atividades.errorText,
                    results: pCrmConsultaState.modalDetalhes.atividades.results
                }
            },
            detalhes: {
                errorText: pCrmConsultaState.detalhes.errorText,
                successText: pCrmConsultaState.detalhes.successText,
                isLoading: pCrmConsultaState.detalhes.isLoading,
                showModal: pCrmConsultaState.detalhes.showModal,
                idOportunidade: pCrmConsultaState.detalhes.idOportunidade,
                idTemperatura: pCrmConsultaState.detalhes.idTemperatura,
                oportunidade: {
                    dataCadastro: pCrmConsultaState.detalhes.oportunidade.dataCadastro,
                    emailCliente: pCrmConsultaState.detalhes.oportunidade.emailCliente,
                    empresaCliente: pCrmConsultaState.detalhes.oportunidade.empresaCliente,
                    idTipoPessoa: pCrmConsultaState.detalhes.oportunidade.idTipoPessoa,
                    linkAnuncio: pCrmConsultaState.detalhes.oportunidade.linkAnuncio,
                    nomeCanalLead: pCrmConsultaState.detalhes.oportunidade.nomeCanalLead,
                    nomeCliente: pCrmConsultaState.detalhes.oportunidade.nomeCliente,
                    nomeEtapaFunilVendas: pCrmConsultaState.detalhes.oportunidade.nomeEtapaFunilVendas,
                    nomeOportunidadeStatus: pCrmConsultaState.detalhes.oportunidade.nomeOportunidadeStatus,
                    oferta: pCrmConsultaState.detalhes.oportunidade.oferta,
                    potencial: pCrmConsultaState.detalhes.oportunidade.potencial,
                    telefoneCliente: pCrmConsultaState.detalhes.oportunidade.telefoneCliente,
                    nomeUsuario: pCrmConsultaState.detalhes.oportunidade.nomeUsuario,
                    datahoraEncerrado: pCrmConsultaState.detalhes.oportunidade.datahoraEncerrado,
                    idOportunidadeStatus: pCrmConsultaState.detalhes.oportunidade.idOportunidadeStatus
                },
                atividades: {
                    isLoading: pCrmConsultaState.detalhes.atividades.isLoading,
                    errorText: pCrmConsultaState.detalhes.atividades.errorText,
                    results: pCrmConsultaState.detalhes.atividades.results
                },
                timeline: {
                    isLoading: pCrmConsultaState.detalhes.timeline.isLoading,
                    errorText: pCrmConsultaState.detalhes.timeline.errorText,
                    results: pCrmConsultaState.detalhes.timeline.results
                },
                atividade: {
                    isLoading: pCrmConsultaState.detalhes.atividade.isLoading,
                    errorText: pCrmConsultaState.detalhes.atividade.errorText,
                    showDateTime: pCrmConsultaState.detalhes.atividade.showDateTime,
                    idCanalAtividade: pCrmConsultaState.detalhes.atividade.idCanalAtividade,
                    data: pCrmConsultaState.detalhes.atividade.data,
                    hora: pCrmConsultaState.detalhes.atividade.hora,
                    minuto: pCrmConsultaState.detalhes.atividade.minuto,
                    anotacoes: pCrmConsultaState.detalhes.atividade.anotacoes,
                    dropdownAtividades: {
                        isLoading: pCrmConsultaState.detalhes.atividade.dropdownAtividades.isLoading,
                        errorText: pCrmConsultaState.detalhes.atividade.dropdownAtividades.errorText,
                        results: pCrmConsultaState.detalhes.atividade.dropdownAtividades.results
                    }
                },
                dropdownTemperatura: {
                    isLoading: pCrmConsultaState.detalhes.dropdownTemperatura.isLoading,
                    errorText: pCrmConsultaState.detalhes.dropdownTemperatura.errorText,
                    results: pCrmConsultaState.detalhes.dropdownTemperatura.results
                }
            },
            modalUsuarios: {
                errorText: pCrmConsultaState.modalUsuarios.errorText,
                successText: pCrmConsultaState.modalUsuarios.successText,
                isLoading: pCrmConsultaState.modalUsuarios.isLoading,
                showModal: pCrmConsultaState.modalUsuarios.showModal,
                idOportunidade: pCrmConsultaState.modalUsuarios.idOportunidade,
                responsavelAtual: pCrmConsultaState.modalUsuarios.responsavelAtual,
                idNovoResponsavel: pCrmConsultaState.modalUsuarios.idNovoResponsavel,
                dropdownUsuarios: {
                    isLoading: pCrmConsultaState.modalUsuarios.dropdownUsuarios.isLoading,
                    errorText: pCrmConsultaState.modalUsuarios.dropdownUsuarios.errorText,
                    results: pCrmConsultaState.modalUsuarios.dropdownUsuarios.results
                }
            },
            modalReabrir: {
                errorText: pCrmConsultaState.modalReabrir.errorText,
                successText: pCrmConsultaState.modalReabrir.successText,
                isLoading: pCrmConsultaState.modalReabrir.isLoading,
                showModal: pCrmConsultaState.modalReabrir.showModal,
                idOportunidade: pCrmConsultaState.modalReabrir.idOportunidade,
            },
            modalNovoLead: {
                errorText: pCrmConsultaState.modalNovoLead.errorText,
                successText: pCrmConsultaState.modalNovoLead.successText,
                isLoading: pCrmConsultaState.modalNovoLead.isLoading,
                showModal: pCrmConsultaState.modalNovoLead.showModal,
                idEtapaFunilVendas: pCrmConsultaState.modalNovoLead.idEtapaFunilVendas,
                nomeCliente: pCrmConsultaState.modalNovoLead.nomeCliente,
                idTipoPessoa: pCrmConsultaState.modalNovoLead.idTipoPessoa,
                emailCliente: pCrmConsultaState.modalNovoLead.emailCliente,
                telefoneCliente: pCrmConsultaState.modalNovoLead.telefoneCliente,
                idCanal: pCrmConsultaState.modalNovoLead.idCanal,
                anuncio: pCrmConsultaState.modalNovoLead.anuncio,
                oferta: pCrmConsultaState.modalNovoLead.oferta,
                idTemperatura: pCrmConsultaState.modalNovoLead.idTemperatura,
                dropdownCanalOportunidade: {
                    isLoading: pCrmConsultaState.modalNovoLead.dropdownCanalOportunidade.isLoading,
                    errorText: pCrmConsultaState.modalNovoLead.dropdownCanalOportunidade.errorText,
                    results: pCrmConsultaState.modalNovoLead.dropdownCanalOportunidade.results
                },
                dropdownEtapaFunilVendas: {
                    isLoading: pCrmConsultaState.modalNovoLead.dropdownEtapaFunilVendas.isLoading,
                    errorText: pCrmConsultaState.modalNovoLead.dropdownEtapaFunilVendas.errorText,
                    results: pCrmConsultaState.modalNovoLead.dropdownEtapaFunilVendas.results
                },
                dropdownTemperaturas: {
                    isLoading: pCrmConsultaState.modalNovoLead.dropdownTemperaturas.isLoading,
                    errorText: pCrmConsultaState.modalNovoLead.dropdownTemperaturas.errorText,
                    results: pCrmConsultaState.modalNovoLead.dropdownTemperaturas.results
                }
            },
            modalFinalizar: {
                errorText: pCrmConsultaState.modalFinalizar.errorText,
                successText: pCrmConsultaState.modalFinalizar.successText,
                isLoading: pCrmConsultaState.modalFinalizar.isLoading,
                showModal: pCrmConsultaState.modalFinalizar.showModal,
                idOportunidade: pCrmConsultaState.modalFinalizar.idOportunidade,
                ehVendido: pCrmConsultaState.modalFinalizar.ehVendido,
                encerramento: {
                    idMotivo: pCrmConsultaState.modalFinalizar.encerramento.idMotivo,
                    dataEncerramento: pCrmConsultaState.modalFinalizar.encerramento.dataEncerramento,
                    comentario: pCrmConsultaState.modalFinalizar.encerramento.comentario,
                    dropdownMotivo: {
                        isLoading: pCrmConsultaState.modalFinalizar.encerramento.dropdownMotivo.isLoading,
                        errorText: pCrmConsultaState.modalFinalizar.encerramento.dropdownMotivo.errorText,
                        results: pCrmConsultaState.modalFinalizar.encerramento.dropdownMotivo.results
                    }
                }
            }
        });
    },
    fnListaOportunidades: () => async (dispatch, getState) => {
        dispatch({ type: 'REQUEST_CRM_LISTA_OPORTUNIDADES_ACTION' });

        var pDTO = {
            pageNumber: getState().crm_consulta.consultaOportunidades.pageNumber,
            pageSize: getState().crm_consulta.consultaOportunidades.pageSize,
            idOportunidadeStatus: getState().crm_consulta.consultaOportunidades.idOportunidadeStatus,
            idEtapaFunilVendas: getState().crm_consulta.consultaOportunidades.idEtapaFunilVendas,
            dataDe: getState().crm_consulta.consultaOportunidades.dataDe,
            dataAte: getState().crm_consulta.consultaOportunidades.dataAte,
            naoAtribuido: getState().crm_consulta.consultaOportunidades.naoAtribuido,
            idCanalLead: getState().crm_consulta.consultaOportunidades.idCanalLead,
            idResponsavel: getState().crm_consulta.consultaOportunidades.idResponsavel,
            idTemperatura: getState().crm_consulta.consultaOportunidades.idTemperatura,
            dataEncerramentoDe: getState().crm_consulta.consultaOportunidades.dataEncerramentoDe,
            dataEncerramentoAte: getState().crm_consulta.consultaOportunidades.dataEncerramentoAte,

        };

        await fetch(crmconsulta_service.services.listaOportunidades(getState().auth.authorizationToken, pDTO))
            .then(response => {
                if (response.status === 200 || response.status === 0) {
                    return response;
                }
                else {
                    return response.json().then(function (object) {
                        return Promise.reject(new Error(object));
                    })
                }
            })
            .then(data => {
                data.json().then(function (object) {
                    var dtoListResult = [];
                    object.forEach(function (entry) {
                        dtoListResult.push({
                            id: entry.id,
                            idFunilVendas: entry.idFunilVendas,
                            idCanalLead: entry.idCanalLead,
                            nomeCanalLead: entry.nomeCanalLead,
                            potencial: entry.potencial,
                            idTipoPessoa: entry.idTipoPessoa,
                            idUsuario: entry.idUsuario,
                            empresaCliente: entry.empresaCliente,
                            nomeCliente: entry.nomeCliente,
                            nomeOportunidadeStatus: entry.nomeOportunidadeStatus,
                            nomeEtapaFunilVendas: entry.nomeEtapaFunilVendas,
                            nomeUsuario: entry.nomeUsuario,
                            emailCliente: entry.emailCliente,
                            telefoneCliente: entry.telefoneCliente,
                            dataCadastro: entry.dataCadastro,
                            idOportunidadeStatus: entry.idOportunidadeStatus,
                            totalNumberOfPages: entry.totalNumberOfPages,
                            totalNumberOfRecords: entry.totalNumberOfRecords
                        });
                    });

                    dispatch({
                        type: 'RESPONSE_CRM_LISTA_OPORTUNIDADES_ACTION',
                        results: dtoListResult,
                        totalNumberOfPages: (dtoListResult.length > 0 ? dtoListResult[0].totalNumberOfPages : 0),
                        totalNumberOfRecords: (dtoListResult.length > 0 ? dtoListResult[0].totalNumberOfRecords : 0)
                    });
                });
            }).catch(function (err) {
                dispatch({ type: 'ERROR_CRM_LISTA_OPORTUNIDADES_ACTION', errorText: err.toString() });
            });
    },
    fnListaOportunidadesExport: () => async (dispatch, getState) => {
        dispatch({ type: 'REQUEST_CRM_LISTA_OPORTUNIDADES_EXPORT_ACTION' });

        var pDTO = {
            idOportunidadeStatus: getState().crm_consulta.consultaOportunidades.idOportunidadeStatus,
            idEtapaFunilVendas: getState().crm_consulta.consultaOportunidades.idEtapaFunilVendas,
            dataDe: getState().crm_consulta.consultaOportunidades.dataDe,
            dataAte: getState().crm_consulta.consultaOportunidades.dataAte,
            naoAtribuido: getState().crm_consulta.consultaOportunidades.naoAtribuido,
            idCanalLead: getState().crm_consulta.consultaOportunidades.idCanalLead,
            idResponsavel: getState().crm_consulta.consultaOportunidades.idResponsavel,
            idTemperatura: getState().crm_consulta.consultaOportunidades.idTemperatura,
            dataEncerramentoDe: getState().crm_consulta.consultaOportunidades.dataEncerramentoDe,
            dataEncerramentoAte: getState().crm_consulta.consultaOportunidades.dataEncerramentoAte,
        };

        try {
            const response = await fetch(crmconsulta_service.services.listaOportunidadesExport(getState().auth.authorizationToken, pDTO));
            if (!response.ok) {
                throw new Error(response.statusText);
            }
            else {
                const json = await response.json();
                var dtoListResult = [];
                json.forEach(function (entry) {
                    dtoListResult.push({
                        id: entry.id,
                        nome: entry.nomeCliente,
                        email: entry.emailCliente,
                        telefone: entry.telefoneCliente,
                        status: entry.nomeOportunidadeStatus,
                        canal: entry.nomeCanalLead,
                        etapa: entry.nomeEtapaFunilVendas,
                        data: entry.dataCadastro,
                    });
                });

                dispatch({
                    type: 'RESPONSE_CRM_LISTA_OPORTUNIDADES_EXPORT_ACTION',
                    results: dtoListResult,
                });
            }
        } catch (err) {
            dispatch({ type: 'ERROR_CRM_LISTA_OPORTUNIDADES_EXPORT_ACTION', errorText: err.toString() });
        }

        //await fetch(crmconsulta_service.services.listaOportunidadesExport(getState().auth.authorizationToken, pDTO))
        //    .then(response => {
        //        if (response.status === 200 || response.status === 0) {
        //            return response;
        //        }
        //        else {
        //            return response.json().then(function (object) {
        //                return Promise.reject(new Error(object));
        //            })
        //        }
        //    })
        //    .then(data => {
        //        data.json().then(function (object) {
        //            var dtoListResult = [];
        //            object.forEach(function (entry) {
        //                dtoListResult.push({
        //                    id: entry.id,
        //                    nome: entry.nomeCliente,
        //                    email: entry.emailCliente,
        //                    telefone: entry.telefoneCliente,
        //                    status: entry.nomeOportunidadeStatus,
        //                    canal: entry.nomeCanalLead,
        //                    etapa: entry.nomeEtapaFunilVendas,
        //                    data: entry.dataCadastro,
        //                });
        //            });

        //            console.log(dtoListResult);

        //            dispatch({
        //                type: 'RESPONSE_CRM_LISTA_OPORTUNIDADES_EXPORT_ACTION',
        //                results: dtoListResult,
        //            });
        //        });
        //}).catch(function (err) {
        //    dispatch({ type: 'ERROR_CRM_LISTA_OPORTUNIDADES_EXPORT_ACTION', errorText: err.toString() });
        //});
    },
    fnListaStatus: () => async (dispatch, getState) => {
        dispatch({ type: 'REQUEST_CRM_STATUS_ACTION' });

        await fetch(crmconsulta_service.services.listaStatus(getState().auth.authorizationToken))
            .then(response => {
                if (response.status === 200 || response.status === 0) {
                    return response;
                }
                else {
                    return response.json().then(function (object) {
                        return Promise.reject(new Error(object));
                    })
                }
            })
            .then(data => {
                data.json().then(function (object) {
                    dispatch({ type: 'RESPONSE_CRM_STATUS_ACTION', results: object });
                });
            }).catch(function (err) {
                dispatch({ type: 'ERROR_CRM_STATUS_ACTION', errorText: err.toString() });
            });
    },
    fnListaEtapaFunil: () => async (dispatch, getState) => {
        dispatch({ type: 'REQUEST_CRM_ETAPA_ACTION' });

        await fetch(crmconsulta_service.services.listaEtapasFunilVendas(getState().auth.authorizationToken))
            .then(response => {
                if (response.status === 200 || response.status === 0) {
                    return response;
                }
                else {
                    return response.json().then(function (object) {
                        return Promise.reject(new Error(object));
                    })
                }
            })
            .then(data => {
                data.json().then(function (object) {
                    dispatch({ type: 'RESPONSE_CRM_ETAPA_ACTION', results: object });
                });
            }).catch(function (err) {
                dispatch({ type: 'ERROR_CRM_ETAPA_ACTION', errorText: err.toString() });
            });
    },
    fnListaCanaisOportunidade: () => async (dispatch, getState) => {
        dispatch({ type: 'REQUEST_CRM_LISTA_CANAIS_LEAD_ACTION' });

        await fetch(crmconsulta_service.services.listaCanaisLead(getState().auth.authorizationToken))
            .then(response => {
                if (response.status === 200 || response.status === 0) {
                    return response;
                }
                else {
                    return response.json().then(function (object) {
                        return Promise.reject(new Error(object));
                    })
                }
            })
            .then(data => {
                data.json().then(function (object) {
                    var dtoListResult = [];
                    object.forEach(function (entry) {
                        dtoListResult.push({
                            id: entry.id
                            , nome: entry.nome
                        });
                    });

                    dispatch({ type: 'RESPONSE_CRM_LISTA_CANAIS_LEAD_ACTION', results: dtoListResult });
                });
            }).catch(function (err) {
                dispatch({ type: 'ERROR_CRM_LISTA_CANAIS_LEAD_ACTION', errorText: err.toString() });
            });
    },
    fnListaResponsavel: () => async (dispatch, getState) => {
        dispatch({ type: 'REQUEST_CRM_RESPONSAVEL_ACTION' });

        await fetch(crmconsulta_service.services.listaResponsavel(getState().auth.authorizationToken))
            .then(response => {
                if (response.status === 200 || response.status === 0) {
                    return response;
                }
                else {
                    return response.json().then(function (object) {
                        return Promise.reject(new Error(object));
                    })
                }
            })
            .then(data => {
                data.json().then(function (object) {
                    dispatch({ type: 'RESPONSE_CRM_RESPONSAVEL_ACTION', results: object });
                });
            }).catch(function (err) {
                dispatch({ type: 'ERROR_CRM_RESPONSAVEL_ACTION', errorText: err.toString() });
            });
    },
    fnObterOportunidade: () => async (dispatch, getState) => {
        dispatch({ type: 'REQUEST_CRM_OBTER_OPORTUNIDADE_ACTION' });

        await fetch(crmconsulta_service.services.obterOportunidade(
            getState().auth.authorizationToken,
            getState().crm_consulta.detalhes.idOportunidade
        ))
            .then(response => {
                if (response.status === 200 || response.status === 0) {
                    return response;
                }
                else {
                    return response.json().then(function (object) {
                        return Promise.reject(new Error(object));
                    })
                }
            })
            .then(data => {
                data.json().then(function (object) {

                    var dtoResult = {
                        dataCadastro: object.dataCadastro,
                        emailCliente: object.emailCliente,
                        empresaCliente: object.empresaCliente,
                        idTipoPessoa: object.idTipoPessoa,
                        linkAnuncio: object.linkAnuncio,
                        nomeCanalLead: object.nomeCanalLead,
                        nomeCliente: object.nomeCliente,
                        nomeEtapaFunilVendas: object.nomeEtapaFunilVendas,
                        nomeOportunidadeStatus: object.nomeOportunidadeStatus,
                        oferta: object.oferta,
                        potencial: object.potencial,
                        telefoneCliente: object.telefoneCliente,
                        id: object.id,
                        idOportunidadeStatus: object.idOportunidadeStatus,
                        nomeUsuario: object.nomeUsuario,
                        idTemperatura: object.temperatura,
                        datahoraEncerrado: object.datahoraEncerrado                        

                    };

                    dispatch({ type: 'RESPONSE_CRM_OBTER_OPORTUNIDADE_ACTION', result: dtoResult });
                });
            }).catch(function (err) {
                dispatch({ type: 'ERROR_CRM_OBTER_OPORTUNIDADE_ACTION', errorText: err.toString() });
            });
    },
    fnAtualizaResponsavelOportunidade: () => async (dispatch, getState) => {
        dispatch({ type: 'PUT_CRM_ATUALIZAR_USUARIO_ACTION' });

        var pDTO = {
            idOportunidade: getState().crm_consulta.modalUsuarios.idOportunidade,
            idUsuario: getState().crm_consulta.modalUsuarios.idNovoResponsavel
        };

        await fetch(crmconsulta_service.services.atualizaResponsavelOportunidade(getState().auth.authorizationToken, pDTO))
            .then(response => {
                if (response.status === 200 || response.status === 0) {
                    return response;
                }
                else {
                    return response.json().then(function (object) {
                        return Promise.reject(new Error(object));
                    })
                }
            })
            .then(data => {
                dispatch(actionCreators.fnObterOportunidade());
                dispatch(actionCreators.fnListaTimeLineOportunidade());
                dispatch({ type: 'SUCCESS_CRM_ATUALIZAR_USUARIO_ACTION' });

                //var dtoListResult = [];
                //getState().crm_consulta.consultaOportunidades.results.forEach(function (entry) {
                //    var idUsuarioNew = entry.idUsuario;
                //    if (entry.id === idOportunidade) {
                //        idUsuarioNew = idUsuario;
                //    }

                //    dtoListResult.push({
                //        id: entry.id,
                //        idFunilVendas: entry.idFunilVendas,
                //        idCanalLead: entry.idCanalLead,
                //        nomeCanalLead: entry.nomeCanalLead,
                //        potencial: entry.potencial,
                //        idTipoPessoa: entry.idTipoPessoa,
                //        idUsuario: idUsuarioNew,
                //        empresaCliente: entry.empresaCliente,
                //        nomeCliente: entry.nomeCliente,
                //        nomeOportunidadeStatus: entry.nomeOportunidadeStatus,
                //        nomeEtapaFunilVendas: entry.nomeEtapaFunilVendas,
                //        emailCliente: entry.emailCliente,
                //        telefoneCliente: entry.telefoneCliente,
                //        dataCadastro: entry.dataCadastro,
                //        idOportunidadeStatus: entry.idOportunidadeStatus,
                //        totalNumberOfPages: entry.totalNumberOfPages,
                //        totalNumberOfRecords: entry.totalNumberOfRecords
                //    });

                //    dispatch({
                //        type: 'RESPONSE_CRM_LISTA_OPORTUNIDADES_ACTION',
                //        results: dtoListResult,
                //        totalNumberOfPages: (dtoListResult.length > 0 ? dtoListResult[0].totalNumberOfPages : 0),
                //        totalNumberOfRecords: (dtoListResult.length > 0 ? dtoListResult[0].totalNumberOfRecords : 0)
                //    });
                //});
            }).catch(function (err) {
                dispatch({ type: 'ERROR_CRM_ATUALIZAR_USUARIO_ACTION', errorText: err.toString() });
            });
    },
    fnAtualizaTemperaturaOportunidade: () => async (dispatch, getState) => {
        dispatch({ type: 'PUT_CRM_ATUALIZAR_TEMPERATURA_ACTION' });

        var pDTO = {
            idOportunidade: getState().crm_consulta.detalhes.idOportunidade,
            idTemperatura: getState().crm_consulta.detalhes.oportunidade.idTemperatura
        };

        await fetch(crmconsulta_service.services.atualizaTemperaturaOportunidade(getState().auth.authorizationToken, pDTO))
            .then(response => {
                if (response.status === 200 || response.status === 0) {
                    return response;
                }
                else {
                    return response.json().then(function (object) {
                        return Promise.reject(new Error(object));
                    });
                }
            })
            .then(data => {              
                dispatch({ type: 'SUCCESS_CRM_ATUALIZAR_TEMPERATURA_ACTION', successText: 'Temperatura atualizada com sucesso!' });
      
            }).catch(function (err) {
                dispatch({ type: 'ERROR_CRM_ATUALIZAR_TEMPERATURA_ACTION', errorText: err.toString() });
            });
    },
    fnListaAtividadesOpoetunidade: () => async (dispatch, getState) => {
        dispatch({ type: 'REQUEST_CRM_LISTA_ATIVIDADES_OPORTUNIDADE_ACTION' });

        await fetch(crmconsulta_service.services.listaAtividadesOportunidade(
            getState().auth.authorizationToken,
            getState().crm_consulta.detalhes.idOportunidade))
            .then(response => {
                if (response.status === 200 || response.status === 0) {
                    return response;
                }
                else {
                    return response.json().then(function (object) {
                        return Promise.reject(new Error(object));
                    })
                }
            })
            .then(data => {
                data.json().then(function (object) {
                    var dtoListResult = [];
                    object.forEach(function (entry) {
                        dtoListResult.push({
                            anotacoes: entry.anotacoes
                            , data: entry.data
                            , duracao: entry.duracao
                            , nomeCanalAtividade: entry.nomeCanalAtividade
                            , obsercacoes: entry.obsercacoes
                            , status: entry.status
                        });
                    });

                    dispatch({ type: 'RESPONSE_CRM_LISTA_ATIVIDADES_OPORTUNIDADE_ACTION', results: dtoListResult });
                });
            }).catch(function (err) {
                dispatch({ type: 'ERROR_CRM_LISTA_ATIVIDADES_OPORTUNIDADE_ACTION', errorText: err.toString() });
            });
    },
    fnListaTimeLineOportunidade: () => async (dispatch, getState) => {
        dispatch({ type: 'REQUEST_CRM_TIMELINE_OPORTUNIDADE_ACTION' });

        await fetch(crmconsulta_service.services.listaTimeLineOportunidade(
            getState().auth.authorizationToken,
            getState().crm_consulta.detalhes.idOportunidade))
            .then(response => {
                if (response.status === 200 || response.status === 0) {
                    return response;
                }
                else {
                    return response.json().then(function (object) {
                        return Promise.reject(new Error(object));
                    })
                }
            })
            .then(data => {
                data.json().then(function (object) {
                    var dtoListResult = [];
                    object.forEach(function (entry) {
                        dtoListResult.push({
                            id: entry.id
                            , datahora: entry.datahora
                            , idOportunidadeTipoEvento: entry.idOportunidadeTipoEvento
                            , texto: entry.texto
                            , usuario: entry.usuario
                            , origem: entry.origem
                            , nomeEvento: entry.nomeEvento
                            , datahoraEncerramento: entry.datahoraEncerramento
                        });
                    });

                    dispatch({ type: 'RESPONSE_CRM_TIMELINE_OPORTUNIDADE_ACTION', results: dtoListResult });
                });
            }).catch(function (err) {
                dispatch({ type: 'ERROR_CRM_TIMELINE_OPORTUNIDADE_ACTION', errorText: err.toString() });
            });
    },
    fnListaUsuarios: () => async (dispatch, getState) => {
        dispatch({ type: 'REQUEST_CRM_LISTA_USUARIOS_ACTION' });

        await fetch(crmconsulta_service.services.listaResponsavel(getState().auth.authorizationToken))
            .then(response => {
                if (response.status === 200 || response.status === 0) {
                    return response;
                }
                else {
                    return response.json().then(function (object) {
                        return Promise.reject(new Error(object));
                    })
                }
            })
            .then(data => {
                data.json().then(function (object) {
                    var dtoListResult = [];
                    object.forEach(function (entry) {
                        dtoListResult.push({
                            id: entry.id
                            , nome: entry.nome
                            , recebeLeadWeb: entry.recebeLeadWeb
                        });
                    });
                    dispatch({ type: 'RESPONSE_CRM_LISTA_USUARIOS_ACTION', results: object });
                });
            }).catch(function (err) {
                dispatch({ type: 'ERROR_CRM_LISTA_USUARIOS_ACTION', errorText: err.toString() });
            });
    },
    fnListaCanaisAtividade: () => async (dispatch, getState) => {
        dispatch({ type: 'REQUEST_CRM_LISTA_CANAIS_ATIVIDADE_ACTION' });

        await fetch(crmconsulta_service.services.listaCanaisAtividade(getState().auth.authorizationToken))
            .then(response => {
                if (response.status === 200 || response.status === 0) {
                    return response;
                }
                else {
                    return response.json().then(function (object) {
                        return Promise.reject(new Error(object));
                    })
                }
            })
            .then(data => {
                data.json().then(function (object) {
                    var dtoListResult = [];
                    object.forEach(function (entry) {
                        dtoListResult.push({
                            id: entry.id
                            , nome: entry.nome
                            , idTipoCanalAtividade: entry.idTipoCanalAtividade
                        });
                    });

                    dispatch({ type: 'RESPONSE_CRM_LISTA_CANAIS_ATIVIDADE_ACTION', results: dtoListResult });
                });
            }).catch(function (err) {
                dispatch({ type: 'ERROR_CRM_LISTA_CANAIS_ATIVIDADE_ACTION', errorText: err.toString() });
            });
    },
    fnInserirAtividade: () => async (dispatch, getState) => {
        dispatch({ type: 'POST_CRM_ATIVIDADE_ACTION' });

        var data = getState().crm_consulta.detalhes.atividade.data;
        if (getState().crm_consulta.detalhes.atividade.hora.length == 1)
            data = data + 'T0' + getState().crm_consulta.detalhes.atividade.hora;
        else
            data = data + 'T' + getState().crm_consulta.detalhes.atividade.hora;

        if (getState().crm_consulta.detalhes.atividade.minuto.length == 1)
            data = data + ':0' + getState().crm_consulta.detalhes.atividade.minuto;
        else
            data = data + ':' + getState().crm_consulta.detalhes.atividade.minuto;

        data = data + ':00.000Z';


        var pDTO = {
            idOportunidade: getState().crm_consulta.detalhes.idOportunidade,
            idCanalAtividade: getState().crm_consulta.detalhes.atividade.idCanalAtividade,
            data: data,
            duracao: 0,
            anotacoes: getState().crm_consulta.detalhes.atividade.anotacoes
        };

        await fetch(crmconsulta_service.services.inserirAtividade(getState().auth.authorizationToken, pDTO))
            .then(response => {
                if (response.status === 200 || response.status === 0) {
                    return response;
                }
                else {
                    return response.json().then(function (object) {
                        return Promise.reject(new Error(object));
                    })
                }
            })
            .then(data => {
                dispatch(actionCreators.fnListaTimeLineOportunidade());
                dispatch({ type: 'SUCCESS_CRM_ATIVIDADE_ACTION' });
            }).catch(function (err) {
                dispatch({ type: 'ERROR_CRM_ATIVIDADE_ACTION', errorText: err.toString() });
            });
    },
    fnReabrirOportunidade: () => async (dispatch, getState) => {
        dispatch({ type: 'PUT_CRM_REABRIR_OPORTUNIDADE_ACTION' });

        await fetch(crmconsulta_service.services.reabrirOportunidade(
            getState().auth.authorizationToken
            , getState().crm_consulta.modalReabrir.idOportunidade
        ))
            .then(response => {
                if (response.status === 200 || response.status === 0) {
                    return response;
                }
                else {
                    return response.json().then(function (object) {
                        return Promise.reject(new Error(object));
                    })
                }
            })
            .then(data => {
                dispatch(actionCreators.fnObterOportunidade());
                dispatch({ type: 'SUCCESS_CRM_REABRIR_OPORTUNIDADE_ACTION' });
            }).catch(function (err) {
                dispatch({ type: 'ERROR_CRM_REABRIR_OPORTUNIDADE_ACTION', errorText: err.toString() });
            });
    },
    fnListaMotivoPerdido: () => async (dispatch, getState) => {
        dispatch({ type: 'REQUEST_CRM_LISTA_MOTIVO_PERDIDO_ACTION' });

        await fetch(crmconsulta_service.services.listaMotivosPerdido(getState().auth.authorizationToken))
            .then(response => {
                if (response.status === 200 || response.status === 0) {
                    return response;
                }
                else {
                    return response.json().then(function (object) {
                        return Promise.reject(new Error(object));
                    })
                }
            })
            .then(data => {
                data.json().then(function (object) {
                    var dtoListResult = [];
                    object.forEach(function (entry) {
                        dtoListResult.push({
                            id: entry.id
                            , nome: entry.nome
                        });
                    });

                    dispatch({ type: 'RESPONSE_CRM_LISTA_MOTIVO_PERDIDO_ACTION', results: dtoListResult });
                });
            }).catch(function (err) {
                dispatch({ type: 'ERROR_CRM_LISTA_MOTIVO_PERDIDO_ACTION', errorText: err.toString() });
            });
    },
    fnListaMotivoVendido: () => async (dispatch, getState) => {
        dispatch({ type: 'REQUEST_CRM_LISTA_MOTIVO_VENDIDO_ACTION' });

        await fetch(crmconsulta_service.services.listaMotivosVendido(getState().auth.authorizationToken))
            .then(response => {
                if (response.status === 200 || response.status === 0) {
                    return response;
                }
                else {
                    return response.json().then(function (object) {
                        return Promise.reject(new Error(object));
                    })
                }
            })
            .then(data => {
                data.json().then(function (object) {
                    var dtoListResult = [];
                    object.forEach(function (entry) {
                        dtoListResult.push({
                            id: entry.id
                            , nome: entry.nome
                        });
                    });

                    dispatch({ type: 'RESPONSE_CRM_LISTA_MOTIVO_VENDIDO_ACTION', results: dtoListResult });
                });
            }).catch(function (err) {
                dispatch({ type: 'ERROR_CRM_LISTA_MOTIVO_VENDIDO_ACTION', errorText: err.toString() });
            });
    },
    fnVendidoOportunidade: () => async (dispatch, getState) => {
        dispatch({ type: 'PUT_CRM_VENDIDO_OPORTUNDADE_ACTION' });
        var pDTO = {
            id: getState().crm_consulta.modalFinalizar.idOportunidade,
            idMotivo: getState().crm_consulta.modalFinalizar.encerramento.idMotivo,
            observacoes: getState().crm_consulta.modalFinalizar.encerramento.comentarios,
            dhEncerramento: getState().crm_consulta.modalFinalizar.encerramento.dataEncerramento
        };

        await fetch(crmconsulta_service.services.vendidoOportunidade(getState().auth.authorizationToken, pDTO))
            .then(response => {
                if (response.status === 200 || response.status === 0) {
                    return response;
                }
                else {
                    return response.json().then(function (object) {
                        return Promise.reject(new Error(object));
                    })
                }
            })
            .then(data => {
                dispatch(actionCreators.fnObterOportunidade());
                dispatch({ type: 'SUCCESS_CRM_VENDIDO_OPORTUNIDADE_ACTION' });
               
            }).catch(function (err) {
                dispatch({ type: 'ERROR_CRM_VENDIDO_OPORTUNIDADE_ACTION', errorText: err.toString() });
            });
    },
    fnPerdidoOportunidade: () => async (dispatch, getState) => {
        dispatch({ type: 'PUT_CRM_PERDIDO_OPORTUNDADE_ACTION' });

        var pDTO = {
            id: getState().crm_consulta.modalFinalizar.idOportunidade,
            idMotivo: getState().crm_consulta.modalFinalizar.encerramento.idMotivo,
            observacoes: getState().crm_consulta.modalFinalizar.encerramento.comentarios,
            dhEncerramento: getState().crm_consulta.modalFinalizar.encerramento.dataEncerramento
        };

        await fetch(crmconsulta_service.services.perdidoOportunidade(getState().auth.authorizationToken, pDTO))
            .then(response => {
                if (response.status === 200 || response.status === 0) {
                    return response;
                }
                else {
                    return response.json().then(function (object) {
                        return Promise.reject(new Error(object));
                    })
                }
            })
            .then(data => {
                dispatch(actionCreators.fnObterOportunidade());
                dispatch({ type: 'SUCCESS_CRM_PERDIDO_OPORTUNIDADE_ACTION' });

            }).catch(function (err) {
                dispatch({ type: 'ERROR_CRM_PERDIDO_OPORTUNIDADE_ACTION', errorText: err.toString() });
            });
    },
    fnNovaOportunidade: () => async (dispatch, getState) => {
        dispatch({ type: POST_CRM_NOVA_OPORTUNIDADE_ACTION });

        const objState = getState().crm_consulta.modalNovoLead;

        console.log(getState());

        var pDTO = {
            idEtapaFunilVendas: objState.idEtapaFunilVendas,
            idResponsavel: objState.idResponsavel,
            dataRegistro: objState.dataRegistro,
            nomeCliente: objState.nomeCliente,
            idTipoPessoa: objState.idTipoPessoa,
            emailCliente: objState.emailCliente,
            telefoneCliente: objState.telefoneCliente,
            idCanalLead: objState.idCanal,
            linkAnuncio: objState.anuncio,
            oferta: objState.oferta,
            idTemperatura: objState.idTemperatura
        };

        await fetch(crmconsulta_service.services.novaOportunidade(getState().auth.authorizationToken, pDTO))
            .then(response => {
                if (response.status === 200 || response.status === 0) {
                    return response;
                }
                else {
                    return response.json().then(function (object) {
                        return Promise.reject(new Error(object));
                    });
                }
            })
            .then(data => {
                dispatch(actionCreators.fnListaTimeLineOportunidade());
                dispatch({ type: SUCCESS_CRM_NOVA_OPORTUNIDADE_ACTION });
            }).catch(function (err) {
                dispatch({ type: ERROR_CRM_NOVA_OPORTUNIDADE_ACTION, errorText: err.toString() });
            });
    },
    fnListaTemperaturaDetalhes: () => async (dispatch, getState) => {
        dispatch({ type: 'REQUEST_CRM_DETALHES_LISTA_TEMPERATURA_ACTION' });

        await fetch(crmconsulta_service.services.listaTemperatura(getState().auth.authorizationToken))
            .then(response => {
                if (response.status === 200 || response.status === 0) {
                    return response;
                }
                else {
                    return response.json().then(function (object) {
                        return Promise.reject(new Error(object));
                    })
                }
            })
            .then(data => {
                data.json().then(function (object) {
                    var dtoListResult = [];
                    object.forEach(function (entry) {
                        dtoListResult.push({
                            id: entry.id
                            , nome: entry.nome                           
                        });
                    });

                    dispatch({ type: 'RESPONSE_CRM_DETALHES_LISTA_TEMPERATURA_ACTION', results: dtoListResult });
                });
            }).catch(function (err) {
                dispatch({ type: 'ERROR_CRM_DETALHES_LISTA_TEMPERATURA_ACTION', errorText: err.toString() });
            });
    },
    fnListaTemperaturaConsulta: () => async (dispatch, getState) => {
        dispatch({ type: 'REQUEST_CRM_CONSULTA_LISTA_TEMPERATURA_ACTION' });

        await fetch(crmconsulta_service.services.listaTemperatura(getState().auth.authorizationToken))
            .then(response => {
                if (response.status === 200 || response.status === 0) {
                    return response;
                }
                else {
                    return response.json().then(function (object) {
                        return Promise.reject(new Error(object));
                    })
                }
            })
            .then(data => {
                data.json().then(function (object) {
                    var dtoListResult = [];
                    object.forEach(function (entry) {
                        dtoListResult.push({
                            id: entry.id
                            , nome: entry.nome
                        });
                    });

                    dispatch({ type: 'RESPONSE_CRM_CONSULTA_LISTA_TEMPERATURA_ACTION', results: dtoListResult });
                });
            }).catch(function (err) {
                dispatch({ type: 'ERROR_CRM_CONSULTA_LISTA_TEMPERATURA_ACTION', errorText: err.toString() });
            });
    },
    fnListaTemperaturaNovoLead: () => async (dispatch, getState) => {
        dispatch({ type: 'REQUEST_CRM_NOVOLEAD_LISTA_TEMPERATURA_ACTION' });

        await fetch(crmconsulta_service.services.listaTemperatura(getState().auth.authorizationToken))
            .then(response => {
                if (response.status === 200 || response.status === 0) {
                    return response;
                }
                else {
                    return response.json().then(function (object) {
                        return Promise.reject(new Error(object));
                    })
                }
            })
            .then(data => {
                data.json().then(function (object) {
                    var dtoListResult = [];
                    object.forEach(function (entry) {
                        dtoListResult.push({
                            id: entry.id
                            , nome: entry.nome
                        });
                    });

                    dispatch({ type: 'RESPONSE_CRM_NOVOLEAD_LISTA_TEMPERATURA_ACTION', results: dtoListResult });
                });
            }).catch(function (err) {
                dispatch({ type: 'ERROR_CRM_NOVOLEAD_LISTA_TEMPERATURA_ACTION', errorText: err.toString() });
            });
    }

};

export const reducer = (state, action) => {
    state = state || initialState;


    if (action.type === REQUEST_CRM_LISTA_MOTIVO_PERDIDO_ACTION) {
        return {
            ...state,
            modalFinalizar: {
                ...state.modalFinalizar,
                encerramento: {
                    ...state.modalFinalizar.encerramento,
                    dropdownMotivo: {
                        ...state.modalFinalizar.encerramento.dropdownMotivo,
                        isLoading: true,
                        errorText: '',
                        results: []
                    }
                }
            }
        };
    }
    if (action.type === RESPONSE_CRM_LISTA_MOTIVO_PERDIDO_ACTION) {
        return {
            ...state,
            modalFinalizar: {
                ...state.modalFinalizar,
                encerramento: {
                    ...state.modalFinalizar.encerramento,
                    dropdownMotivo: {
                        ...state.modalFinalizar.encerramento.dropdownMotivo,
                        isLoading: false,
                        results: action.results
                    }
                }
            }
        };
    }
    if (action.type === ERROR_CRM_LISTA_MOTIVO_PERDIDO_ACTION) {
        return {
            ...state,
            modalFinalizar: {
                ...state.modalFinalizar,
                encerramento: {
                    ...state.modalFinalizar.encerramento,
                    dropdownMotivo: {
                        ...state.modalFinalizar.encerramento.dropdownMotivo,
                        isLoading: false,
                        errorText: action.errorText
                    }
                }
            }
        };
    }

    if (action.type === REQUEST_CRM_LISTA_MOTIVO_VENDIDO_ACTION) {
        return {
            ...state,
            modalFinalizar: {
                ...state.modalFinalizar,
                encerramento: {
                    ...state.modalFinalizar.encerramento,
                    dropdownMotivo: {
                        ...state.modalFinalizar.encerramento.dropdownMotivo,
                        isLoading: true,
                        errorText: '',
                        results: []
                    }
                }
            }
        };
    }
    if (action.type === RESPONSE_CRM_LISTA_MOTIVO_VENDIDO_ACTION) {
        return {
            ...state,
            modalFinalizar: {
                ...state.modalFinalizar,
                encerramento: {
                    ...state.modalFinalizar.encerramento,
                    dropdownMotivo: {
                        ...state.modalFinalizar.encerramento.dropdownMotivo,
                        isLoading: false,
                        results: action.results
                    }
                }
            }
        };
    }
    if (action.type === ERROR_CRM_LISTA_MOTIVO_VENDIDO_ACTION) {
        return {
            ...state,
            modalFinalizar: {
                ...state.modalFinalizar,
                encerramento: {
                    ...state.modalFinalizar.encerramento,
                    dropdownMotivo: {
                        ...state.modalFinalizar.encerramento.dropdownMotivo,
                        isLoading: false,
                        errorText: action.errorText
                    }
                }
            }
        };
    }

    if (action.type === UPDATE_STATE_CRM_CONSULTA_ACTION) {
        return {
            ...state,
            consultaOportunidades: action.consultaOportunidades
        };
    }


    if (action.type === REQUEST_CRM_OBTER_OPORTUNIDADE_ACTION) {
        return {
            ...state,
            detalhes: {
                ...state.detalhes,
                errorText: '',
                successText: '',
                isLoading: true
            }
        };
    }
    if (action.type === RESPONSE_CRM_OBTER_OPORTUNIDADE_ACTION) {
        return {
            ...state,
            detalhes: {
                ...state.detalhes,
                isLoading: false,
                oportunidade: action.result
            }
        };
    }
    if (action.type === ERROR_CRM_OBTER_OPORTUNIDADE_ACTION) {
        return {
            ...state,
            detalhes: {
                ...state.detalhes,
                isLoading: false,
                errorText: action.errorText
            }
        };
    }


    if (action.type === REQUEST_CRM_LISTA_OPORTUNIDADES_ACTION) {
        return {
            ...state,
            consultaOportunidades: {
                ...state.consultaOportunidades,
                errorText: '',
                requested: true,
                isLoading: true,
                results: []
            }
        };
    }
    if (action.type === RESPONSE_CRM_LISTA_OPORTUNIDADES_ACTION) {
        return {
            ...state,
            consultaOportunidades: {
                ...state.consultaOportunidades,
                isLoading: false,
                results: action.results,
                totalNumberOfPages: action.totalNumberOfPages,
                totalNumberOfRecords: action.totalNumberOfRecords
            }
        };
    }
    if (action.type === ERROR_CRM_LISTA_OPORTUNIDADES_ACTION) {
        return {
            ...state,
            consultaOportunidades: {
                ...state.consultaOportunidades,
                isLoading: false,
                errorText: action.errorText
            }
        };
    }


    if (action.type === REQUEST_CRM_LISTA_OPORTUNIDADES_EXPORT_ACTION) {
        return {
            ...state,
            consultaOportunidadesExport: {
                ...state.consultaOportunidadesExport,
                errorText: '',
                requested: true,
                isLoading: true,
                results: []
            }
        };
    }
    if (action.type === RESPONSE_CRM_LISTA_OPORTUNIDADES_EXPORT_ACTION) {
        return {
            ...state,
            consultaOportunidadesExport: {
                ...state.consultaOportunidadesExport,
                isLoading: false,
                results: action.results,
            }
        };
    }
    if (action.type === ERROR_CRM_LISTA_OPORTUNIDADES_EXPORT_ACTION) {
        return {
            ...state,
            consultaOportunidadesExport: {
                ...state.consultaOportunidadesExport,
                isLoading: false,
                errorText: action.errorText
            }
        };
    }


    if (action.type === REQUEST_CRM_STATUS_ACTION) {
        return {
            ...state,
            consultaOportunidades: {
                ...state.consultaOportunidades,
                dropdownStatus: {
                    isLoading: true,
                    errorText: '',
                    results: []
                }
            }
        };
    }
    if (action.type === RESPONSE_CRM_STATUS_ACTION) {
        return {
            ...state,
            consultaOportunidades: {
                ...state.consultaOportunidades,
                dropdownStatus: {
                    ...state.consultaOportunidades.dropdownStatus,
                    isLoading: false,
                    results: action.results
                }
            }
        };
    }
    if (action.type === ERROR_CRM_STATUS_ACTION) {
        return {
            ...state,
            consultaOportunidades: {
                ...state.consultaOportunidades,
                dropdownStatus: {
                    ...state.consultaOportunidades.dropdownStatus,
                    isLoading: false,
                    errorText: action.errorText
                }
            }
        };
    }


    if (action.type === REQUEST_CRM_ETAPA_ACTION) {
        return {
            ...state,
            consultaOportunidades: {
                ...state.consultaOportunidades,
                dropdownEtapa: {
                    isLoading: true,
                    errorText: '',
                    results: []
                }
            },
            modalNovoLead: {
                ...state.modalNovoLead,
                dropdownEtapaFunilVendas: {
                    ...state.modalNovoLead.dropdownEtapaFunilVendas,
                    isLoading: true,
                    errorText: '',
                    results: []
                }
            }
        };
    }
    if (action.type === RESPONSE_CRM_ETAPA_ACTION) {
        return {
            ...state,
            consultaOportunidades: {
                ...state.consultaOportunidades,
                dropdownEtapa: {
                    ...state.consultaOportunidades.dropdownEtapa,
                    isLoading: false,
                    results: action.results
                }
            },
            modalNovoLead: {
                ...state.modalNovoLead,
                dropdownEtapaFunilVendas: {
                    ...state.modalNovoLead.dropdownEtapaFunilVendas,
                    isLoading: false,
                    results: action.results
                }
            }
        };
    }
    if (action.type === ERROR_CRM_ETAPA_ACTION) {
        return {
            ...state,
            consultaOportunidades: {
                ...state.consultaOportunidades,
                dropdownEtapa: {
                    ...state.consultaOportunidades.dropdownEtapa,
                    isLoading: false,
                    errorText: action.errorText
                }
            },
            modalNovoLead: {
                ...state.modalNovoLead,
                dropdownEtapaFunilVendas: {
                    ...state.modalNovoLead.dropdownEtapaFunilVendas,
                    isLoading: true,
                    errorText: '',
                    results: []
                }
            }

        };
    }


    if (action.type === REQUEST_CRM_RESPONSAVEL_ACTION) {
        return {
            ...state,
            consultaOportunidades: {
                ...state.consultaOportunidades,
                dropdownResponsavel: {
                    isLoading: true,
                    errorText: '',
                    results: []
                }
            }
        };
    }
    if (action.type === RESPONSE_CRM_RESPONSAVEL_ACTION) {
        return {
            ...state,
            consultaOportunidades: {
                ...state.consultaOportunidades,
                dropdownResponsavel: {
                    ...state.consultaOportunidades.dropdownResponsavel,
                    isLoading: false,
                    results: action.results
                }
            }
        };
    }
    if (action.type === ERROR_CRM_RESPONSAVEL_ACTION) {
        return {
            ...state,
            consultaOportunidades: {
                ...state.consultaOportunidades,
                dropdownResponsavel: {
                    ...state.consultaOportunidades.dropdownResponsavel,
                    isLoading: false,
                    errorText: action.errorText
                }
            }
        };
    }


    if (action.type === REQUEST_CRM_LISTA_ATIVIDADES_OPORTUNIDADE_ACTION) {
        return {
            ...state,
            detalhes: {
                ...state.detalhes,
                atividades: {
                    ...state.detalhes.atividades,
                    isLoading: true,
                    errorText: '',
                    results: []
                }
            }
        };
    }
    if (action.type === RESPONSE_CRM_LISTA_ATIVIDADES_OPORTUNIDADE_ACTION) {
        return {
            ...state,
            detalhes: {
                ...state.detalhes,
                atividades: {
                    ...state.detalhes.atividades,
                    isLoading: false,
                    results: action.results
                }
            }
        };
    }
    if (action.type === ERROR_CRM_LISTA_ATIVIDADES_OPORTUNIDADE_ACTION) {
        return {
            ...state,
            detalhes: {
                ...state.detalhes,
                atividades: {
                    ...state.detalhes.atividades,
                    isLoading: false,
                    errorText: action.errorText
                }
            }
        };
    }


    if (action.type === REQUEST_CRM_TIMELINE_OPORTUNIDADE_ACTION) {
        return {
            ...state,
            detalhes: {
                ...state.detalhes,
                timeline: {
                    ...state.detalhes.timeline,
                    isLoading: true,
                    errorText: '',
                    results: []
                }
            }
        };
    }
    if (action.type === RESPONSE_CRM_TIMELINE_OPORTUNIDADE_ACTION) {
        return {
            ...state,
            detalhes: {
                ...state.detalhes,
                timeline: {
                    ...state.detalhes.timeline,
                    isLoading: false,
                    results: action.results
                }
            }
        };
    }
    if (action.type === ERROR_CRM_TIMELINE_OPORTUNIDADE_ACTION) {
        return {
            ...state,
            detalhes: {
                ...state.detalhes,
                timeline: {
                    ...state.detalhes.timeline,
                    isLoading: false,
                    errorText: action.errorText
                }
            }
        };
    }


    if (action.type === REQUEST_CRM_LISTA_USUARIOS_ACTION) {
        return {
            ...state,
            modalUsuarios: {
                ...state.modalUsuarios,
                dropdownUsuarios: {
                    ...state.modalUsuarios.dropdownUsuarios,
                    isLoading: true,
                    errorText: '',
                    results: []
                }
            }
        };
    }
    if (action.type === RESPONSE_CRM_LISTA_USUARIOS_ACTION) {
        return {
            ...state,
            modalUsuarios: {
                ...state.modalUsuarios,
                dropdownUsuarios: {
                    ...state.modalUsuarios.dropdownUsuarios,
                    isLoading: false,
                    results: action.results
                }
            }
        };
    }
    if (action.type === ERROR_CRM_LISTA_USUARIOS_ACTION) {
        return {
            ...state,
            modalUsuarios: {
                ...state.modalUsuarios,
                dropdownUsuarios: {
                    ...state.modalUsuarios.dropdownUsuarios,
                    isLoading: false,
                    errorText: action.errorText
                }
            }
        };
    }


    if (action.type === PUT_CRM_ATUALIZAR_USUARIO_ACTION) {
        return {
            ...state,
            modalUsuarios: {
                ...state.modalUsuarios,
                isLoading: true
            }
        };
    }
    if (action.type === SUCCESS_CRM_ATUALIZAR_USUARIO_ACTION) {
        return {
            ...state,
            modalUsuarios: {
                ...state.modalUsuarios,
                isLoading: false,
                idOportunidade: 0,
                idNovoResponsavel: 0,
                showModal: false
            }
        };
    }
    if (action.type === ERROR_CRM_ATUALIZAR_USUARIO_ACTION) {
        return {
            ...state,
            modalUsuarios: {
                ...state.modalUsuarios,
                modalUsuarios: {
                    isLoading: false,
                    errorText: action.errorText,
                }
            }
        };
    }


    if (action.type === REQUEST_CRM_LISTA_CANAIS_ATIVIDADE_ACTION) {
        return {
            ...state,
            detalhes: {
                ...state.detalhes,
                atividade: {
                    ...state.detalhes.atividade,
                    dropdownAtividades: {
                        ...state.detalhes.atividade.dropdownAtividades,
                        isLoading: true,
                        errorText: '',
                        results: []
                    }
                }
            }
        };
    }
    if (action.type === RESPONSE_CRM_LISTA_CANAIS_ATIVIDADE_ACTION) {
        return {
            ...state,
            detalhes: {
                ...state.detalhes,
                atividade: {
                    ...state.detalhes.atividade,
                    dropdownAtividades: {
                        ...state.detalhes.atividade.dropdownAtividades,
                        isLoading: false,
                        results: action.results
                    }
                }
            }
        };
    }
    if (action.type === ERROR_CRM_LISTA_CANAIS_ATIVIDADE_ACTION) {
        return {
            ...state,
            detalhes: {
                ...state.detalhes,
                atividade: {
                    ...state.detalhes.atividade,
                    dropdownAtividades: {
                        ...state.detalhes.atividade.dropdownAtividades,
                        isLoading: false,
                        errorText: action.errorText
                    }
                }
            }
        };
    }


    if (action.type === POST_CRM_ATIVIDADE_ACTION) {
        return {
            ...state,
            detalhes: {
                ...state.detalhes,
                atividade: {
                    ...state.detalhes.atividade,
                    isLoading: true,
                    errorText: ''
                }
            }
        };
    }
    if (action.type === SUCCESS_CRM_ATIVIDADE_ACTION) {
        return {
            ...state,
            detalhes: {
                ...state.detalhes,
                atividade: {
                    ...state.detalhes.atividade,
                    isLoading: false,
                    showDateTime: true,
                    idCanalAtividade: 0,
                    data: '',
                    hora: '',
                    minuto: '',
                    anotacoes: ''
                }
            }
        };
    }
    if (action.type === ERROR_CRM_ATIVIDADE_ACTION) {
        return {
            ...state,
            detalhes: {
                ...state.detalhes,
                atividade: {
                    ...state.detalhes.atividade,
                    isLoading: false,
                    errorText: ''
                }
            }
        };
    }


    if (action.type === PUT_CRM_REABRIR_OPORTUNIDADE_ACTION) {
        return {
            ...state,
            modalReabrir: {
                ...state.modalReabrir,
                isLoading: true
            }
        };
    }
    if (action.type === SUCCESS_CRM_REABRIR_OPORTUNIDADE_ACTION) {
        return {
            ...state,
            modalReabrir: {
                ...state.modalReabrir,
                isLoading: false,
                idOportunidade: 0,
                showModal: false
            }
        };
    }
    if (action.type === ERROR_CRM_REABRIR_OPORTUNIDADE_ACTION) {
        return {
            ...state,
            modalReabrir: {
                ...state.modalReabrir,
                isLoading: false,
                errorText: action.errorText,
            }
        };
    }


    if (action.type === REQUEST_CRM_LISTA_CANAIS_LEAD_ACTION) {
        return {
            ...state,
            consultaOportunidades: {
                ...state.consultaOportunidades,
                dropdownCanalOportunidade: {
                    ...state.consultaOportunidades.dropdownCanalOportunidade,
                    isLoading: true,
                    errorText: '',
                    results: []
                }
            },
            modalNovoLead: {
                ...state.modalNovoLead,
                dropdownCanalOportunidade: {
                    ...state.consultaOportunidades.dropdownCanalOportunidade,
                    isLoading: true,
                    errorText: '',
                    results: []
                }
            }
        };
    }
    if (action.type === RESPONSE_CRM_LISTA_CANAIS_LEAD_ACTION) {
        return {
            ...state,
            consultaOportunidades: {
                ...state.consultaOportunidades,
                dropdownCanalOportunidade: {
                    ...state.consultaOportunidades.dropdownCanalOportunidade,
                    isLoading: false,
                    results: action.results
                }
            },
            modalNovoLead: {
                ...state.modalNovoLead,
                dropdownCanalOportunidade: {
                    ...state.consultaOportunidades.dropdownCanalOportunidade,
                    isLoading: false,
                    results: action.results
                }
            }
        };
    }
    if (action.type === ERROR_CRM_LISTA_CANAIS_LEAD_ACTION) {
        return {
            ...state,
            consultaOportunidades: {
                ...state.consultaOportunidades,
                dropdownCanalOportunidade: {
                    ...state.consultaOportunidades.dropdownCanalOportunidade,
                    isLoading: false,
                    errorText: action.errorText
                }
            },
            modalNovoLead: {
                ...state.modalNovoLead,
                dropdownCanalOportunidade: {
                    ...state.consultaOportunidades.dropdownCanalOportunidade,
                    isLoading: false,
                    errorText: action.errorText
                }
            }
        };
    }


    if (action.type === PUT_CRM_PERDIDO_OPORTUNDADE_ACTION
        || action.type === PUT_CRM_VENDIDO_OPORTUNDADE_ACTION) {
        return {
            ...state,
            modalFinalizar: {
                ...state.modalFinalizar,
                isLoading: true
            }
        };
    }
    if (action.type === SUCCESS_CRM_PERDIDO_OPORTUNIDADE_ACTION
        || action.type === SUCCESS_CRM_VENDIDO_OPORTUNIDADE_ACTION) {
        return {
            ...state,
            modalFinalizar: {
                ...state.modalFinalizar,
                isLoading: false,
                idOportunidade: 0,
                showModal: false
            }
        };
    }
    if (action.type === ERROR_CRM_PERDIDO_OPORTUNIDADE_ACTION
        || action.type === ERROR_CRM_VENDIDO_OPORTUNIDADE_ACTION) {
        return {
            ...state,
            modalFinalizar: {
                ...state.modalFinalizar,
                isLoading: false,
                showModal: true,
                errorText: action.errorText
            }
        };
    }

    if (action.type === POST_CRM_NOVA_OPORTUNIDADE_ACTION) {
        return {
            ...state,
            modalNovoLead: {
                ...state.modalNovoLead,
                isLoading: true
            }
        };
    }
    if (action.type === SUCCESS_CRM_NOVA_OPORTUNIDADE_ACTION) {
        return {
            ...state,
            modalNovoLead: {
                ...state.modalNovoLead,
                isLoading: false,
                showModal: false,
                idEtapaFunilVendas: null,
                dataRegistro: null,
                nomeCliente: '',
                idTipoPessoa: null,
                emailCliente: '',
                telefoneCliente: '',
                idCanal: null,
                anuncio: '',
                oferta: '',
            }
        };
    }
    if (action.type === ERROR_CRM_NOVA_OPORTUNIDADE_ACTION) {
        return {
            ...state,
            modalNovoLead: {
                ...state.modalNovoLead,
                isLoading: false,
                showModal: true,
                errorText: action.errorText
            }
        };
    }
    if (action.type === REQUEST_CRM_DETALHES_LISTA_TEMPERATURA_ACTION) {
        return {
            ...state,
            detalhes: {
                ...state.detalhes,            
                dropdownTemperatura: {
                    ...state.detalhes.dropdownTemperatura,
                        isLoading: true,
                        errorText: '',
                        results: []
                    }
                
            }
        };
    }
    if (action.type === RESPONSE_CRM_DETALHES_LISTA_TEMPERATURA_ACTION) {
        return {
            ...state,
            detalhes: {
                ...state.detalhes,               
                dropdownTemperatura: {
                    ...state.detalhes.dropdownTemperatura,
                        isLoading: false,
                        results: action.results
                    }                
            }
        };
    }
    if (action.type === ERROR_CRM_DETALHES_LISTA_TEMPERATURA_ACTION) {
        return {
            ...state,
            detalhes: {
                ...state.detalhes,             
                dropdownTemperatura: {
                        ...state.detalhes.dropdownTemperatura,
                        isLoading: false,
                        errorText: action.errorText
                    }                
            }
        };
    }
    if (action.type === REQUEST_CRM_CONSULTA_LISTA_TEMPERATURA_ACTION) {
        return {
            ...state,
            consultaOportunidades: {
                ...state.consultaOportunidades,
                dropdownTemperatura: {
                    ...state.consultaOportunidades.dropdownTemperatura,
                    isLoading: true,
                    errorText: '',
                    results: []
                }

            }
        };
    }
    if (action.type === RESPONSE_CRM_CONSULTA_LISTA_TEMPERATURA_ACTION) {
        return {
            ...state,
            consultaOportunidades: {
                ...state.consultaOportunidades,
                dropdownTemperatura: {
                    ...state.consultaOportunidades.dropdownTemperatura,
                    isLoading: false,
                    results: action.results
                }
            }
        };
    }
    if (action.type === ERROR_CRM_CONSULTA_LISTA_TEMPERATURA_ACTION) {
        return {
            ...state,
            consultaOportunidades: {
                ...state.consultaOportunidades,
                dropdownTemperatura: {
                    ...state.consultaOportunidades.dropdownTemperatura,
                    isLoading: false,
                    errorText: action.errorText
                }
            }
        };
    }
    if (action.type === REQUEST_CRM_NOVOLEAD_LISTA_TEMPERATURA_ACTION) {
        return {
            ...state,
            modalNovoLead: {
                ...state.modalNovoLead,
                dropdownTemperaturas: {
                    ...state.modalNovoLead.dropdownTemperaturas,
                    isLoading: true,
                    errorText: '',
                    results: []
                }

            }
        };
    }
    if (action.type === RESPONSE_CRM_NOVOLEAD_LISTA_TEMPERATURA_ACTION) {
        return {
            ...state,
            modalNovoLead: {
                ...state.modalNovoLead,
                dropdownTemperaturas: {
                    ...state.modalNovoLead.dropdownTemperaturas,
                    isLoading: false,
                    results: action.results
                }
            }
        };
    }
    if (action.type === ERROR_CRM_NOVOLEAD_LISTA_TEMPERATURA_ACTION) {
        return {
            ...state,
            modalNovoLead: {
                ...state.modalNovoLead,
                dropdownTemperaturas: {
                    ...state.modalNovoLead.dropdownTemperaturas,
                    isLoading: false,
                    errorText: action.errorText
                }
            }
        };
    }

    if (action.type === PUT_CRM_ATUALIZAR_USUARIO_ACTION) {
        return {
            ...state,
            detalhes: {
                ...state.detalhes,
                isLoading: true
            }
        };
    }

    if (action.type === SUCCESS_CRM_ATUALIZAR_TEMPERATURA_ACTION) {
        return {
            ...state,
            detalhes: {
                ...state.detalhes,
                isLoading: false,
                successText: action.successText
            }
        };
    }
    if (action.type === ERROR_CRM_ATUALIZAR_TEMPERATURA_ACTION) {
        return {
            ...state,
            detalhes: {
                ...state.detalhes,               
                    isLoading: false,
                    errorText: action.errorText                
            }
        };
    }

    return state;
};