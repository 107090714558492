import * as React from 'react'
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actionCreators } from '../../../store/analiserisco/SolicitarPagamentos';

import CurrencyInput from 'react-currency-input';
import { FormattedNumber } from 'react-intl';
import { Row, Modal } from 'react-bootstrap';
import AlertDismissable from '../../commons/AlertDismissable';
import Section from '../../commons/Section';
import BtnSalvar from '../../commons/BtnSalvar'; 
import BtnCancelar from '../../commons/BtnCancelar';

class SolicitarPagamentosForm extends React.Component {
    constructor() {
        super();
    }

    componentDidMount() {
        this.props.fnBuscaSaldo();
    }

    render() {
        return <Row>
            <div className="col-md-12">
                <Section>
                    <AlertDismissable
                        title="Ops algo deu errado!"
                        text={this.props.ErrorText}
                        show={this.props.ErrorText != '' && this.props.ErrorText != undefined ? true : false}
                        bsStyle="danger"
                    />
                    <div className="pagecontent">
                        <div className="add-nav">
                            <div className="nav-heading">
                                <h3>Saldo atual: <strong className="text-blue">{((!this.props.IsLoading) ? <FormattedNumber value={this.props.Saldo} style="currency" currency="BRL" currencyDisplay="symbol" /> : '---')}</strong></h3>
                                <span className="controls pull-right">
                                    <a className="btn btn-ef btn-ef-1 btn-ef-1-success btn-ef-1a btn-rounded-20" title="Comprar crédito" onClick={this.props.fnOpenModal}><i className="fa fa-tags"></i> Comprar créditos</a>
                                </span>
                            </div>
                        </div>
                    </div>
                </Section>
            </div>
            <Modal show={this.props.ShowModal} onHide={this.props.fnCloseModal} bsSize="small">
                <Modal.Header closeButton>
                    <h3 className="modal-title custom-font">Compre créditos</h3>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <div className="form-group col-md-12">
                            <div className="form-group">
                                <label>Valor: </label>
                                <CurrencyInput
                                    decimalSeparator="."
                                    thousandSeparator=""
                                    className="form-control"
                                    id="txtValorComprarCredito"
                                    onChange={(x) => { this.props.fnAtualizaValorStore(parseFloat(x)); }}
                                    inputType="text"
                                    value={this.props.ValorCredito}
                                />
                            </div>
                        </div>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <BtnSalvar
                        type="button"
                        text=" Solicitar Crédito"
                        textLoading=" Aguarde..."
                        isLoading={this.props.IsLoading}
                        onClick={() => { this.props.fnSolicitarCredito(); }}
                    />
                    <BtnCancelar
                        type="button"
                        text=" Cancelar"
                        textLoading=" Aguarde..."
                        isLoading={this.props.IsLoading}
                        onClick={() => { this.props.fnCloseModal(); }}
                    />
                </Modal.Footer>
            </Modal>
        </Row>;
    }
}

export default connect(
    state => state.solicitarpagamentos,
    dispatch => bindActionCreators(actionCreators, dispatch),
    null,
    { pure: false }
)(SolicitarPagamentosForm);