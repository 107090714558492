import * as React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actionCreators } from '../../../../../store/marketing/publicador/MarketingPublicador';

import Formsy from 'formsy-react';
import { Row, Modal } from 'react-bootstrap';
import ProgerssBar from '../../../../commons/ProgressBar';
import AlertError from '../../../../commons/AlertError';
import AlertSuccess from '../../../../commons/AlertSuccess';
import AlertInfo from '../../../../commons/AlertInfo';
import TextAreaValidated from '../../../../commons/TextAreaValidated';
import InputSelectValidated from '../../../../commons/InputSelectValidated';
import CurrencyInput from 'react-currency-input';
import BtnSalvar from '../../../../commons/BtnSalvar';
import BtnCancelar from '../../../../commons/BtnCancelar';
import InputTextValidated from '../../../../commons/InputTextValidated';



class ModalMercadoLivreMoto extends React.Component {
    constructor() {
        super();
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    async componentDidMount() {
        this.props.PublicarVeiculo.dadosPortal.versoes.results = [];
        this.props.PublicarVeiculo.dadosPortal.planos.results = [];
        this.props.PublicarVeiculo.errorText = "";
        this.props.PublicarVeiculo.successText = "";
        this.props.PublicarVeiculo.anuncio.anuncioEmDestaque = false;
        this.props.PublicarVeiculo.anuncio.idAnosModelo = "";
        this.props.PublicarVeiculo.anuncio.idAnuncio = "";
        this.props.PublicarVeiculo.anuncio.idAnuncioCliente = "";
        //this.props.PublicarVeiculo.anuncio.idAnuncioBNDV = 0;
        this.props.PublicarVeiculo.anuncio.idCategoria = 2;
        this.props.PublicarVeiculo.anuncio.idCidade = "";
        this.props.PublicarVeiculo.anuncio.idPublicador = 0;
        this.props.PublicarVeiculo.anuncio.idTipoPlano = "";
        this.props.PublicarVeiculo.anuncio.idTipoAdicional = "";
        this.props.PublicarVeiculo.anuncio.idTipoVeiculo = "";
        this.props.PublicarVeiculo.anuncio.idVeiculo = 0;
        this.props.PublicarVeiculo.anuncio.idVersao = "";      
        this.props.PublicarVeiculo.anuncio.preco = 0;
        this.props.PublicarVeiculo.anuncio.precoDe = 0;
        this.props.PublicarVeiculo.anuncio.texto = "";
        this.props.PublicarVeiculo.anuncio.titulo = "";       
        this.props.PublicarVeiculo.anuncio.idFreio = "";
        this.props.PublicarVeiculo.anuncio.idPartida = "";
        this.props.PublicarVeiculo.anuncio.cilindradas = 0;
        this.props.PublicarVeiculo.anuncio.idMotor = "";
        this.props.PublicarVeiculo.anuncio.marcaMotor = "";
        this.props.PublicarVeiculo.anuncio.video = "";


        await this.props.fnUpdateState(this.props);

        if (this.props.ModalMercadoLivreMoto.showModal) {
            if (this.props.PublicarVeiculo.anuncio.idAnuncioBNDV != null && this.props.PublicarVeiculo.anuncio.idAnuncioBNDV != 0) {
                await this.props.fnBuscarVeiculo();
                await this.props.fnBuscarAnuncioMoto();

                this.props.PublicarVeiculo.anuncio.idVersao = this.props.PublicarVeiculo.veiculoBNDV.descVersao;
                this.props.fnUpdateState(this.props);

                this.props.fnListaPlanos();
                this.props.fnListaModelos();
                this.props.fnListaFreio();
                this.props.fnListaCategorias();
                this.props.fnListaPartida();
                this.props.fnListaMotor();
            }
            else {
                await this.props.fnBuscarVeiculo();

                this.props.PublicarVeiculo.anuncio.idVersao = this.props.PublicarVeiculo.veiculoBNDV.descVersao;
                this.props.fnUpdateState(this.props);

                this.props.fnListaPlanos();
                this.props.fnListaModelos();
                this.props.fnListaFreio();
                this.props.fnListaCategorias();
                this.props.fnListaPartida();
                this.props.fnListaMotor();
            }
        }
    }

    handleSubmit(e) {
        if (this.props.PublicarVeiculo.anuncio.idAnuncioBNDV == 0)
            this.props.fnPublicarMoto();
        else
            this.props.fnAlterarAnuncioMoto();
    }

    handleOnHide() {
        this.props.ModalMercadoLivreMoto.showModal = false;
        this.props.fnUpdateState(this.props);
    }

    render() {
        var objProps = this.props;

        var dropdownModelos = [];
        dropdownModelos.push({ value: '', text: 'Selecione o Modelo / Versão' });
        objProps.PublicarVeiculo.dadosPortal.modelos.results.forEach(function (entry) {
            dropdownModelos.push({ value: entry.Nome, text: entry.Nome });
        });

        var dropdownPlanos = [];
        dropdownPlanos.push({ value: '', text: 'Selecione o Tipo de Plano' });
        objProps.PublicarVeiculo.dadosPortal.planos.results.forEach(function (entry) {
            dropdownPlanos.push({ value: entry.id, text: entry.Nome + " - Disponíveis: " + (entry.qtdeContratada - entry.qtdeUtilizada).toString() });
        });

        var dropdownCategorias = [];
        dropdownCategorias.push({ value: '', text: 'Selecione a Categoria' });
        objProps.PublicarVeiculo.dadosPortal.categorias.results.forEach(function (entry) {
            dropdownCategorias.push({ value: entry.Nome, text: entry.Nome });
        });


        var dropdownFreio = [];
        dropdownFreio.push({ value: '', text: 'Selecione o Tipo de Freio' });
        objProps.PublicarVeiculo.dadosPortal.freio.results.forEach(function (entry) {
            dropdownFreio.push({ value: entry.Nome, text: entry.Nome });
        });

        var dropdownPartida = [];
        dropdownPartida.push({ value: '', text: 'Selecione o Tipo de Partida' });
        objProps.PublicarVeiculo.dadosPortal.partida.results.forEach(function (entry) {
            dropdownPartida.push({ value: entry.Nome, text: entry.Nome });
        });

        var dropdownMotor = [];
        dropdownMotor.push({ value: '', text: 'Selecione o Tipo de Motor' });
        objProps.PublicarVeiculo.dadosPortal.motor.results.forEach(function (entry) {
            dropdownMotor.push({ value: entry.Nome, text: entry.Nome });
        });

        return <Modal
            show={this.props.ModalMercadoLivreMoto.showModal}
            onHide={this.handleOnHide.bind(this)}
            bsSize="large"
        >
            <Formsy onValidSubmit={this.handleSubmit}>
                <Modal.Header closeButton>
                    <h4 className="modal-title custom-font">Publicar Moto Mercado Livre</h4>
                </Modal.Header>
                <Modal.Body>
                    <AlertSuccess
                        successText={this.props.PublicarVeiculo.successText}
                        alertVisible={this.props.PublicarVeiculo.successText != '' && this.props.PublicarVeiculo.successText != undefined ? true : false}
                        onDismiss={(alertVisible, successText) => {
                            this.props.PublicarVeiculo.successText = successText;
                            this.props.fnUpdateState(this.props);
                        }}
                    />
                    <AlertError
                        errorText={this.props.PublicarVeiculo.errorText}
                        alertVisible={this.props.PublicarVeiculo.errorText != '' && this.props.PublicarVeiculo.errorText != undefined ? true : false}
                        onDismiss={(alertVisible, errorText) => {
                            this.props.PublicarVeiculo.errorText = errorText;
                            this.props.fnUpdateState(this.props);
                        }}
                    />
                    {this.props.PublicarVeiculo.anuncio.idAnuncioBNDV != 0
                        ? <AlertInfo
                            textHeader="Dica de uso Mercado Livre"
                            text={<p>O portal Mercado Livre não permite alteração nos campos Título, Tipo de Plano e Descrição, caso seja necessário alteração destes campos você precisará remover o anúncio e publicar novamente.</p>}
                            alertVisible={true}
                            onDismiss={() => { }}
                        />
                        : null}
                    <form
                        id="frmModalMercadoLivreMoto"
                        name="frmModalMercadoLivreMoto"
                        role="form"
                        onSubmit={this.handleSubmit}
                        data-parsley-validate>
                        {
                            this.props.ModalMercadoLivreMoto.isLoading
                                ? <ProgerssBar />
                                : <Row>
                                    <div className="form-group col-md-5">
                                        <div className="form-group col-md-12 legend">
                                            <h4><strong>Dados Veículo</strong></h4>
                                        </div>
                                        <dl className="dl-horizontal">
                                            <dt>Placa:</dt>
                                            <dd>{this.props.PublicarVeiculo.veiculoBNDV.placa}</dd>
                                            <dt>Chassi:</dt>
                                            <dd>{this.props.PublicarVeiculo.veiculoBNDV.chassi}</dd>
                                            <dt>Marca:</dt>
                                            <dd>{this.props.PublicarVeiculo.veiculoBNDV.descMarca}</dd>
                                            <dt>Modelo:</dt>
                                            <dd>{this.props.PublicarVeiculo.veiculoBNDV.descModelo}</dd>
                                            <dt>Versão:</dt>
                                            <dd>{this.props.PublicarVeiculo.veiculoBNDV.descVersao}</dd>
                                            <dt>Câmbio:</dt>
                                            <dd>{this.props.PublicarVeiculo.veiculoBNDV.descCambio}</dd>
                                            <dt>Combustível:</dt>
                                            <dd>{this.props.PublicarVeiculo.veiculoBNDV.descCombustivel}</dd>                                            
                                            <dt>Cor:</dt>
                                            <dd>{this.props.PublicarVeiculo.veiculoBNDV.cor}</dd>
                                            <dt>Ano Modelo:</dt>
                                            <dd>{this.props.PublicarVeiculo.veiculoBNDV.anoModelo}</dd>
                                            <dt>Ano Fabricação:</dt>
                                            <dd>{this.props.PublicarVeiculo.veiculoBNDV.anoFabricacao}</dd>
                                            <dt>KM:</dt>
                                            <dd>{this.props.PublicarVeiculo.veiculoBNDV.km}</dd>
                                        </dl>
                                        <div className="form-group col-md-12 legend">
                                            <h4><strong>Opcionais</strong></h4>
                                        </div>
                                        <dl className="form-group col-md-12">
                                            <p>{this.props.PublicarVeiculo.veiculoBNDV.opcionais}</p>
                                        </dl>
                                    </div>
                                    <div className="form-group col-md-7">
                                        <div className="tile-body">
                                            <Row>
                                                <InputTextValidated
                                                    name="txtTituloAnuncio"
                                                    type="text"
                                                    className="col-md-12"
                                                    placeholder="Informe o título do anúncio"
                                                    onChange={(name, value) => {
                                                        this.props.PublicarVeiculo.anuncio.titulo = value;
                                                        this.props.fnUpdateState(this.props);
                                                    }}
                                                    value={this.props.PublicarVeiculo.anuncio.titulo}
                                                    title="Título do anúncio:"
                                                    required
                                                    disabled={this.props.PublicarVeiculo.anuncio.idAnuncioBNDV != 0 ? true : false}
                                                    maxLength="60"
                                                />
                                                <InputSelectValidated
                                                    name="ddlVersao"
                                                    className="col-md-12"
                                                    data={(this.props.PublicarVeiculo.dadosPortal.modelos.isLoading
                                                        ? [{ value: '', text: 'Aguarde carregando...' }]
                                                        : dropdownModelos)}
                                                    onChange={
                                                        (name, value) => {
                                                            this.props.PublicarVeiculo.anuncio.idAnosModelo = value;
                                                            this.props.fnUpdateState(this.props);
                                                        }
                                                    }
                                                    selectedValue={
                                                        (this.props.PublicarVeiculo.anuncio.idAnosModelo == null)
                                                            ? null
                                                            : this.props.PublicarVeiculo.anuncio.idAnosModelo.toString()
                                                    }
                                                    value={
                                                        (this.props.PublicarVeiculo.anuncio.idAnosModelo == null)
                                                            ? null
                                                            : this.props.PublicarVeiculo.anuncio.idAnosModelo.toString()
                                                    }
                                                    title="Modelo & Versão:"
                                                    disabled={this.props.PublicarVeiculo.dadosPortal.modelos.isLoading ? true : false}
                                                    required
                                                />
                                                <InputSelectValidated
                                                    name="ddlTipoPlano"
                                                    className="col-md-6"
                                                    data={(this.props.PublicarVeiculo.dadosPortal.planos.isLoading
                                                        ? [{ value: '', text: 'Aguarde carregando...' }]
                                                        : dropdownPlanos)}
                                                    onChange={
                                                        (name, value) => {
                                                            this.props.PublicarVeiculo.anuncio.idTipoPlano = value;
                                                            this.props.fnUpdateState(this.props);
                                                        }
                                                    }
                                                    selectedValue={
                                                        (this.props.PublicarVeiculo.anuncio.idTipoPlano == "")
                                                            ? ""
                                                            : this.props.PublicarVeiculo.anuncio.idTipoPlano.toString()
                                                    }
                                                    disabled={
                                                        (this.props.PublicarVeiculo.dadosPortal.planos.isLoading
                                                            ? true
                                                            : this.props.PublicarVeiculo.anuncio.idAnuncioBNDV != 0 ? true : false)
                                                    }
                                                    title="Tipo de plano:"
                                                    required
                                                    value={
                                                        (this.props.PublicarVeiculo.anuncio.idTipoPlano == "")
                                                            ? ""
                                                            : this.props.PublicarVeiculo.anuncio.idTipoPlano.toString()
                                                    }
                                                />
                                                <div className="form-group col-md-6">
                                                    <label>Valor para anúncio</label>
                                                    <CurrencyInput
                                                        decimalSeparator="."
                                                        thousandSeparator=""
                                                        className="form-control"
                                                        id="txtValorAnuncio"
                                                        onChange={(x) => {
                                                            this.props.PublicarVeiculo.anuncio.preco = parseFloat(x);
                                                            this.props.fnUpdateState(this.props);
                                                        }}
                                                        inputType="text"
                                                        value={this.props.PublicarVeiculo.anuncio.preco}
                                                    />
                                                </div>
                                                <InputSelectValidated
                                                    name="ddlCategoria"
                                                    className="col-md-6"
                                                    data={(this.props.PublicarVeiculo.dadosPortal.categorias.isLoading
                                                        ? [{ value: '', text: 'Aguarde carregando...' }]
                                                        : dropdownCategorias)}
                                                    onChange={
                                                        (name, value) => {
                                                            this.props.PublicarVeiculo.anuncio.idTipoVeiculo = value;
                                                            this.props.fnUpdateState(this.props);
                                                        }
                                                    }
                                                    selectedValue={
                                                        (this.props.PublicarVeiculo.anuncio.idTipoVeiculo == "")
                                                            ? ""
                                                            : this.props.PublicarVeiculo.anuncio.idTipoVeiculo.toString()
                                                    }
                                                    value={
                                                        (this.props.PublicarVeiculo.anuncio.idTipoVeiculo == "")
                                                            ? ""
                                                            : this.props.PublicarVeiculo.anuncio.idTipoVeiculo.toString()
                                                    }
                                                    title="Categoria:"
                                                    disabled={this.props.PublicarVeiculo.dadosPortal.categorias.isLoading ? true : false}
                                                    required
                                                />
                                                <InputSelectValidated
                                                    name="ddlFreio"
                                                    className="col-md-6"
                                                    data={(this.props.PublicarVeiculo.dadosPortal.freio.isLoading
                                                        ? [{ value: '', text: 'Aguarde carregando...' }]
                                                        : dropdownFreio)}
                                                    onChange={
                                                        (name, value) => {
                                                            this.props.PublicarVeiculo.anuncio.idFreio = value;
                                                            this.props.fnUpdateState(this.props);
                                                        }
                                                    }
                                                    selectedValue={
                                                        (this.props.PublicarVeiculo.anuncio.idFreio == null)
                                                            ? null
                                                            : this.props.PublicarVeiculo.anuncio.idFreio.toString()
                                                    }
                                                    value={
                                                        (this.props.PublicarVeiculo.anuncio.idFreio == null)
                                                            ? null
                                                            : this.props.PublicarVeiculo.anuncio.idFreio.toString()
                                                    }
                                                    title="Freio:"
                                                    disabled={this.props.PublicarVeiculo.dadosPortal.freio.isLoading ? true : false}
                                                    required
                                                />

                                                <InputSelectValidated
                                                    name="ddlPartida"
                                                    className="col-md-6"
                                                    data={(this.props.PublicarVeiculo.dadosPortal.partida.isLoading
                                                        ? [{ value: '', text: 'Aguarde carregando...' }]
                                                        : dropdownPartida)}
                                                    onChange={
                                                        (name, value) => {
                                                            this.props.PublicarVeiculo.anuncio.idPartida = value;
                                                            this.props.fnUpdateState(this.props);
                                                        }
                                                    }
                                                    selectedValue={
                                                        (this.props.PublicarVeiculo.anuncio.idPartida == null)
                                                            ? null
                                                            : this.props.PublicarVeiculo.anuncio.idPartida.toString()
                                                    }
                                                    value={
                                                        (this.props.PublicarVeiculo.anuncio.idPartida == null)
                                                            ? null
                                                            : this.props.PublicarVeiculo.anuncio.idPartida.toString()
                                                    }
                                                    title="Partida:"
                                                    disabled={this.props.PublicarVeiculo.dadosPortal.partida.isLoading ? true : false}
                                                    required
                                                />
                                                <div className="form-group col-md-6">
                                                    <label>Cilindradas:</label>
                                                  <CurrencyInput
                                                        decimalSeparator=""
                                                        thousandSeparator=""  
                                                        precision = "0"
                                                        className="form-control"
                                                    onChange={(value) => {
                                                        this.props.PublicarVeiculo.anuncio.cilindradas = parseInt(value);
                                                        this.props.fnUpdateState(this.props);
                                                    }}
                                                    inputType="text"
                                                    value={this.props.PublicarVeiculo.anuncio.cilindradas}
                                                    required
                                                />
                                                </div>

                                                <InputSelectValidated
                                                    name="ddlMotor"
                                                    className="col-md-6"
                                                    data={(this.props.PublicarVeiculo.dadosPortal.motor.isLoading
                                                        ? [{ value: '', text: 'Aguarde carregando...' }]
                                                        : dropdownMotor)}
                                                    onChange={
                                                        (name, value) => {
                                                            this.props.PublicarVeiculo.anuncio.idMotor = value;
                                                            this.props.fnUpdateState(this.props);
                                                        }
                                                    }
                                                    selectedValue={
                                                        (this.props.PublicarVeiculo.anuncio.idMotor == null)
                                                            ? null
                                                            : this.props.PublicarVeiculo.anuncio.idMotor.toString()
                                                    }
                                                    value={
                                                        (this.props.PublicarVeiculo.anuncio.idMotor == null)
                                                            ? null
                                                            : this.props.PublicarVeiculo.anuncio.idMotor.toString()
                                                    }
                                                    title="Motor:"
                                                    disabled={this.props.PublicarVeiculo.dadosPortal.motor.isLoading ? true : false}
                                                    required
                                                />
                                                <InputTextValidated
                                                    name="txtMarcaMotor"
                                                    type="text"
                                                    className="col-md-6"
                                                    placeholder="Informe a marca do Motor"
                                                    onChange={(name, value) => {
                                                        this.props.PublicarVeiculo.anuncio.marcaMotor = value;
                                                        this.props.fnUpdateState(this.props);
                                                    }}
                                                    value={this.props.PublicarVeiculo.anuncio.marcaMotor}
                                                    title="Marca do Motor:"
                                                    required                                                   
                                                    maxLength="100"
                                                />
                                                <TextAreaValidated
                                                    name="txtDescricao"
                                                    className="col-md-12"
                                                    placeholder="Informe a descrição do anúncio"
                                                    rows={6}
                                                    onChange={(name, value) => {
                                                        this.props.PublicarVeiculo.anuncio.texto = value;
                                                        this.props.fnUpdateState(this.props);
                                                    }}
                                                    value={this.props.PublicarVeiculo.anuncio.texto}
                                                    title="Descrição do anúncio:"
                                                    required
                                                    disabled={this.props.PublicarVeiculo.anuncio.idAnuncioBNDV != 0 ? true : false}
                                                />
                                                <InputTextValidated
                                                    name="txtVideoAnuncio"
                                                    type="url"
                                                    className="col-md-12"
                                                    placeholder="Informe o link do Youtube"
                                                    onChange={(name, value) => {
                                                        this.props.PublicarVeiculo.anuncio.video = value;
                                                        this.props.fnUpdateState(this.props);
                                                    }}
                                                    value={this.props.PublicarVeiculo.anuncio.video}
                                                    title="Link do Youtube (Vídeo do Veículo):"
                                                />
                                            </Row>
                                        </div>
                                    </div>
                                </Row>
                        }
                    </form>
                </Modal.Body>
                <Modal.Footer>
                    {this.props.PublicarVeiculo.anuncio.idAnuncioBNDV != null
                        && this.props.PublicarVeiculo.anuncio.idAnuncioBNDV != 0
                        ? <BtnCancelar
                            type="button"
                            text=" Remover anúncio"
                            textLoading=" Aguarde..."
                            isLoading={this.props.PublicarVeiculo.isLoading}
                            onClick={() => { this.props.fnRemoverMoto(); }}
                        />
                        : null}
                    <BtnSalvar
                        type="submit"
                        text={this.props.PublicarVeiculo.anuncio.idAnuncioBNDV != 0 ? " Alterar Anúncio" : "Publicar Veículo"}
                        textLoading=" Aguarde..."
                        isLoading={this.props.PublicarVeiculo.isLoading}
                    />
                </Modal.Footer>
            </Formsy>
        </Modal>;
    }
}

export default connect(
    state => state.marketing_publicador,
    dispatch => bindActionCreators(actionCreators, dispatch),
    null, {}
)(ModalMercadoLivreMoto);