import * as React from 'react';

import HeaderPage from '../../components/commons/HeaderPage';
import ListaPublicadorForm from '../../components/marketing/publicador/meusanuncios/ListaPublicadorForm';

export default class Publicador_Container extends React.Component {
    render() {
        return <div className='page'>
            <HeaderPage
                path=' Marketing'
                name='Publicador'
                subName='Meus Anúncios' />
            <ListaPublicadorForm />
        </div>;
    }
}