import * as React from 'react';
import { fetch, addTask } from 'domain-task';
import { Chart } from 'react-google-charts';
import * as dashboard_service from '../../../services/dashboard/dashboard_service';
import Section from '../../commons/Section';
import SectionHeader from '../../commons/SectionHeader';

export default class MediaDiasEstoqueDezModelosMaisComprados extends React.Component {
    constructor() {
        super();
        this.state = { data: [] };
    }

    componentDidMount() {
        this.loadData(this.props.authorizationToken, this.props.empresaSelected);
    }

    componentWillReceiveProps(nextProps) {
        this.loadData(nextProps.authorizationToken, nextProps.empresaSelected);
    }

    loadData(authorizationToken, empresaSelected) {
        let fetchTask = fetch(dashboard_service.services.Administrador.MediaDiasEstoqueDezModelosMaisComprados(authorizationToken, empresaSelected))
            .then(response => {
                if (response.status === 200 || response.status === 0) {
                    return response.json();
                }
                else {
                    return response.json().then(function (object) {
                        return Promise.reject(new Error(object));
                    })
                }
            })
            .then(data => {
                var arrData = [];
                arrData.push(["Veiculo", "DiasEstoque"]);

                data.forEach(function (entry) {
                    arrData.push([entry.Veiculo, entry.DiasEstoque]);
                });

                this.setState({ data: arrData });
            }).catch(function (err) {
                console.log(err);
            });

        addTask(fetchTask);
    }

    render() {
        return (
            <Section>
                <SectionHeader title="Dez Modelos Mais Comprados (Média Tempo Estoque)" />
                <div className="tile-body">
                    <Chart
                        chartType="PieChart"
                        data={this.state.data}
                        options={
                            {
                                "legend": {
                                    "position": "right"
                                }
                                , "chartArea": {
                                    "left": 20,
                                    "top": 20,
                                    "width": "100%",
                                    "height": "100%"
                                }
                            }
                        }
                        width="100%"
                        legend_toggle
                    />
                </div>
            </Section>
        );
    }
}