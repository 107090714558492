import * as React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actionCreators } from '../../../store/edoc/EdocConsulta';

import Moment from 'react-moment';
import { Modal, Row } from 'react-bootstrap';
import AlertError from '../../commons/AlertError';
import AlertSuccess from '../../commons/AlertSuccess';
import ProgerssBar from '../../commons/ProgressBar';
import BtnSalvar from '../../commons/BtnSalvar';
import BtnCancelar from '../../commons/BtnCancelar';

class ModalImagens extends React.Component {
    constructor(props) {
        super(props);
        this.closeModalConfirm = this.closeModalConfirm.bind(this);
        this.verificaPermissao = this.verificaPermissao.bind(this); 
    }

    componentDidMount() {
        this.props.listaDocumentosVeiculo(this.props.edoc_consulta.modalImagens.idVeiculo);
    }

    handleOnHide() {
        this.props.edoc_consulta.modalImagens.showModal = false;
        this.props.fnUpdateState(this.props.edoc_consulta);
    }

    closeModalConfirm() {
        this.props.edoc_consulta.modalImagens.showModalRemover = false;
        this.props.fnUpdateState(this.props.edoc_consulta);
    }
    verificaPermissao(modulo) {
        var boolReturn = false;
        JSON.parse(this.props.navmenu.modulos).forEach(function (entry) {
            if (entry.toString() === modulo.toString()) {
                boolReturn = true;
            }
        });

        return boolReturn;
    }

    render() {
        var objProps = this.props;

        const renderDocEntrada = (rowData, index) => {

            return (<div>
                <div className="col-md-11">
                    <a href={"https://" + rowData.urlArquivo}
                        target="_blank"
                        style={{ cursor: 'pointer' }}>
                        <i className="fa fa-eye"></i>
                    </a>
                                                            &nbsp;
                                                            &nbsp;
                                                            <a href={"mailto:?subject=" + rowData.nomeTipoDocumento + "&body=https://" + rowData.urlArquivo}
                        style={{ cursor: 'pointer' }}>
                        <i className="fa fa-envelope"></i>
                    </a>
                                                            &nbsp;
                                                            &nbsp;
                                                            <a href={"https://api.whatsapp.com/send?text=https://" + rowData.urlArquivo}
                        target="_blank"
                        style={{ cursor: 'pointer' }}>
                        <i className="fa fa-whatsapp"></i>
                    </a>
                                                            &nbsp;
                                                            &nbsp;
                                                            |
                                                            &nbsp;
                                                            &nbsp;
                                                            {rowData.nomeTipoDocumento}
                </div>
                <div className="col-md-1">                      
                    { this.verificaPermissao(170) ?
                        <a
                            role="button"
                            tabIndex={0}
                            className="delete text-danger"
                            onClick={() => {
                                objProps.edoc_consulta.modalImagens.idModalRemover = rowData.id;
                                objProps.edoc_consulta.modalImagens.arquivoModalRemover = rowData.nomeTipoDocumento;
                                objProps.edoc_consulta.modalImagens.showModalRemover = true;
                                objProps.fnUpdateState(objProps.edoc_consulta);
                            }}><i className="fa fa-trash"></i>
                        </a>
                        : null
                    }
                </div>
            </div>)        
        }

        const renderDocSaida = (rowData, index) => {

            return (<div>
                <div className="col-md-11">
                    <a href={"https://" + rowData.urlArquivo}
                        target="_blank"
                        style={{ cursor: 'pointer' }}>
                        <i className="fa fa-eye"></i>
                    </a>
                                                            &nbsp;
                                                            &nbsp;
                                                            <a href={"mailto:?subject=" + rowData.nomeTipoDocumento + "&body=https://" + rowData.urlArquivo}
                        style={{ cursor: 'pointer' }}>
                        <i className="fa fa-envelope"></i>
                    </a>
                                                            &nbsp;
                                                            &nbsp;
                                                            <a href={"https://api.whatsapp.com/send?text=https://" + rowData.urlArquivo}
                        target="_blank"
                        style={{ cursor: 'pointer' }}>
                        <i className="fa fa-whatsapp"></i>
                    </a>
                                                            &nbsp;
                                                            &nbsp;
                                                            |
                                                            &nbsp;
                                                            &nbsp;
                                                            {rowData.nomeTipoDocumento}
                </div>
                <div className="col-md-1">
                    {this.verificaPermissao(170) ?
                        <a
                            role="button"
                            tabIndex={0}
                            className="delete text-danger"
                            onClick={() => {
                                objProps.edoc_consulta.modalImagens.idModalRemover = rowData.id;
                                objProps.edoc_consulta.modalImagens.arquivoModalRemover = rowData.nomeTipoDocumento;
                                objProps.edoc_consulta.modalImagens.showModalRemover = true;
                                objProps.fnUpdateState(objProps.edoc_consulta);
                            }}><i className="fa fa-trash"></i>
                        </a>
                        : null }
                </div>
            </div>)
        }


        return <Modal
            show={this.props.edoc_consulta.modalImagens.showModal}
            onHide={this.handleOnHide.bind(this)}
            bsSize="large"
        >
            <form
                id="frm"
                name="frm"
                role="form"
                onSubmit={this.handleSubmit}
                data-parsley-validate>
                <Modal.Header closeButton>
                    <h4 className="modal-title custom-font">Documentos Digitalizados</h4>
                </Modal.Header>
                <Modal.Body>
                    <AlertSuccess
                        successText={this.props.edoc_consulta.modalImagens.successText}
                        alertVisible={this.props.edoc_consulta.modalImagens.successText != '' && this.props.edoc_consulta.modalImagens.successText != undefined ? true : false}
                        onDismiss={(alertVisible, successText) => {
                            this.props.edoc_consulta.modalImagens.successText = successText;
                            this.props.fnUpdateState(this.props.edoc_consulta);
                        }}
                    />
                    <AlertError
                        errorText={this.props.edoc_consulta.modalImagens.errorText}
                        alertVisible={this.props.edoc_consulta.modalImagens.errorText != '' && this.props.edoc_consulta.modalImagens.errorText != undefined ? true : false}
                        onDismiss={(alertVisible, errorText) => {
                            this.props.edoc_consulta.modalImagens.errorText = errorText;
                            this.props.fnUpdateState(this.props.edoc_consulta);
                        }}
                    />
                    {
                        this.props.edoc_consulta.modalImagens.isLoading
                            ? <ProgerssBar />
                            : <div>
                                <Row>
                                    <div className="col-md-2 text-right">Placa: </div>
                                    <div className="col-md-4">{this.props.edoc_consulta.modalImagens.placa}</div>
                                    <div className="col-md-2 text-right">Chassi: </div>
                                    <div className="col-md-4">{this.props.edoc_consulta.modalImagens.chassi}</div>
                                </Row>
                                <Row>
                                    <div className="col-md-2 text-right">Ano Fab/Mod: </div>
                                    <div className="col-md-4">{this.props.edoc_consulta.modalImagens.anoFabricacao.toString() + "/" + this.props.edoc_consulta.modalImagens.anoModelo.toString()}</div>
                                    <div className="col-md-2 text-right">Data Cadastro: </div>
                                    <div className="col-md-4"><Moment format="DD/MM/YYYY">{this.props.edoc_consulta.modalImagens.dataCadastro}</Moment></div>
                                </Row>
                                <Row>
                                    <div className="col-md-2 text-right">Marca: </div>
                                    <div className="col-md-4">{this.props.edoc_consulta.modalImagens.nomeMarca}</div>
                                    <div className="col-md-2 text-right">Modelo: </div>
                                    <div className="col-md-4">{this.props.edoc_consulta.modalImagens.nomeVersao}</div>
                                </Row>
                                <Row>
                                    <div className="col-md-2 text-right">Num. Envelope: </div>
                                    <div className="col-md-4">{this.props.edoc_consulta.modalImagens.numEnvelope}</div>
                                    <div className="col-md-2 text-right">Status: </div>
                                    <div className="col-md-4">
                                        <span className={this.props.edoc_consulta.modalImagens.status == "Vendido" ? "label label-success" : this.props.edoc_consulta.modalImagens.status == "Cancelado" ? "label label-danger" : "label label-primary"}>{this.props.edoc_consulta.modalImagens.status}</span>
                                    </div>
                                </Row>
                                <br />
                                <Row>
                                    <div className="col-md-6">
                                        <div className="col-md-12 text-center bg-primary text-white">
                                            <h5><strong>DOCUMENTOS DE ENTRADA</strong></h5>
                                        </div>
                                        {
                                            (this.props.edoc_consulta.modalImagens.resultsEntrada.length == 0)
                                                ? <p className="text-warning">Nenhum documento de entrada</p>
                                                : this.props.edoc_consulta.modalImagens.resultsEntrada.map(renderDocEntrada)
                                        }
                                    </div>
                                    <div className="col-md-6">
                                        <div className="col-md-12 text-center bg-primary text-white">
                                            <h5><strong>DOCUMENTOS DE SAÍDA</strong></h5>
                                        </div>
                                        {
                                            (this.props.edoc_consulta.modalImagens.resultsSaida.length == 0)
                                                ? <p className="text-warning">Nenhum documento de saída</p>
                                                : this.props.edoc_consulta.modalImagens.resultsSaida.map(renderDocSaida)
                                        }
                                    </div>
                                </Row>
                            </div>
                    }
                </Modal.Body>
            </form>
            <Modal show={this.props.edoc_consulta.modalImagens.showModalRemover} onHide={this.closeModalConfirm} bsSize="small">
                <Modal.Header closeButton>
                    <h3 className="modal-title custom-font">Remoção de Arquivo</h3>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="form-group col-md-12">
                            <p>Tem certeza que deseja remover o arquivo <strong>{this.props.edoc_consulta.modalImagens.arquivoModalRemover}</strong>?<br />Essa alteração não poderá ser desfeita.</p>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <BtnSalvar
                        type="button"
                        text=" Remover"
                        textLoading=" Aguarde..."
                        isLoading={this.props.edoc_consulta.modalImagens.IsLoading}
                        onClick={() => { this.props.removerDocumento(this.props.edoc_consulta.modalImagens.idModalRemover); }}
                    />
                    <BtnCancelar
                        type="button"
                        text=" Cancelar"
                        textLoading=" Aguarde..."
                        isLoading={this.props.edoc_consulta.modalImagens.IsLoading}
                        onClick={this.closeModalConfirm}
                    />
                </Modal.Footer>
            </Modal>
        </Modal>;
    }
}

function mapStateToProps(state) {
    return {
        ...state,
        navmenu: state.navmenu,
        edoc_consulta: state.edoc_consulta
    }
}

export default connect(
    mapStateToProps,
    dispatch => bindActionCreators(actionCreators, dispatch),
    null, {}
)(ModalImagens);