import * as funcs from '../../utils/funcs';

//const _URL = 'http://localhost:61655/api/v1/crm/';
const _URL = 'https://apicrm.bndv.com.br/api/v1/crm/';

export const services = {
    listaUsuariosNaoAdicionados: (authorizationToken) => {
        return new Request(_URL + 'listaUsuariosNaoAdicionados',
            {
                method: 'GET',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
                    'Authorization': 'Bearer ' + authorizationToken
                }
            });
    },
    listaUsuariosAdicionados: (authorizationToken) => {
        return new Request(_URL + 'listaUsuariosAdicionados',
            {
                method: 'GET',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
                    'Authorization': 'Bearer ' + authorizationToken
                }
            });
    },
    adicionarUsuarioDistribuicao: (pDTO, authorizationToken) => {
        var query = funcs.encodeURI(pDTO);
        return new Request(_URL + 'inserirTipoDistribuicaoUsuario',
            {
                method: 'POST',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
                    'Authorization': 'Bearer ' + authorizationToken
                },
                body: query
            });
    },
    removerUsuarioDistribuicao: (pDTO, authorizationToken) => {
        var query = funcs.encodeURI(pDTO);
        return new Request(_URL + 'removerTipoDistribuicaoUsuario',
            {
                method: 'DELETE',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
                    'Authorization': 'Bearer ' + authorizationToken
                },
                body: query
            });
    },
    obterTipoDistribuicao: (authorizationToken) => {
        return new Request(_URL + 'obterTipoDistribuicao',
            {
                method: 'GET',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
                    'Authorization': 'Bearer ' + authorizationToken
                },
            });
    },
    atualizarTipoDistribuicao: (pDTO, authorizationToken) => {
        var query = funcs.encodeURI(pDTO);
        return new Request(_URL + 'atualizarTipoDistribuicao',
            {
                method: 'PUT',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
                    'Authorization': 'Bearer ' + authorizationToken
                },
                body: query
            });
    },
    atualizarRecebeLeadWebUsuario: (idUsuario, recebeLeadWeb , authorizationToken) => {
        return new Request(_URL + 'atualizarRecebeLeadWebUsuario?idUsuario=' + idUsuario.toString() + "&recebeLeadWeb=" + recebeLeadWeb.toString(),
            {
                method: 'PUT',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
                    'Authorization': 'Bearer ' + authorizationToken
                }
            });
    },
    listaPortaisAtivos: (authorizationToken) => {
        return new Request(_URL + 'listaEmpresaCanalLead',
            {
                method: 'GET',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
                    'Authorization': 'Bearer ' + authorizationToken
                }
            });
    },
    obterEmpresaCanalLead: (id, authorizationToken) => {
        return new Request(_URL + 'obterEmpresaCanalLead?id=' + id.toString(),
            {
                method: 'GET',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
                    'Authorization': 'Bearer ' + authorizationToken
                }
            });
    },
    inserirEmpresaCanalLead: (pDTO, authorizationToken) => {
        var query = funcs.encodeURI(pDTO);
        return new Request(_URL + 'inserirEmpresaCanalLead',
            {
                method: 'POST',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
                    'Authorization': 'Bearer ' + authorizationToken
                },
                body: query
            });
    },
    atualizaEmpresaCanalLead: (pDTO, authorizationToken) => {
        var query = funcs.encodeURI(pDTO);
        return new Request(_URL + 'atualizaEmpresaCanalLead',
            {
                method: 'PUT',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
                    'Authorization': 'Bearer ' + authorizationToken
                },
                body: query
            });
    },
    removerEmpresaCanalLead: (id, authorizationToken) => {
        return new Request(_URL + 'removerEmpresaCanalLead?idCanalLead=' + id.toString(),
            {
                method: 'DELETE',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
                    'Authorization': 'Bearer ' + authorizationToken
                }
            });
    },
    buscaEmpresa: (authorizationToken) => {
        return new Request(_URL + 'empresa',
            {
                method: 'GET',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
                    'Authorization': 'Bearer ' + authorizationToken
                }
            });
    },
    inserirEmpresa: (authorizationToken) => {
        return new Request(_URL + 'empresa',
            {
                method: 'POST',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
                    'Authorization': 'Bearer ' + authorizationToken
                }
            });
    }
}