import { fetch, addTask } from 'domain-task';
import * as edocconfiguracao_service from '../../services/edoc/edocconfiguracao_service';

const UPDATE_STATE_EDOC_CONFIGURACAO_ACTION = 'UPDATE_STATE_EDOC_CONFIGURACAO_ACTION';
const REQUEST_EDOC_TIPOS_DOCUMENTO_ACTION = 'REQUEST_EDOC_TIPOS_DOCUMENTO_ACTION';
const RESPONSE_EDOC_TIPOS_DOCUMENTO_ACTION = 'RESPONSE_EDOC_TIPOS_DOCUMENTO_ACTION';
const ERROR_EDOC_TIPOS_DOCUMENTO_ACTION = 'ERROR_EDOC_TIPOS_DOCUMENTO_ACTION';
const POST_EDOC_TIPOS_DOCUMENTO_ACTION = 'POST_EDOC_TIPOS_DOCUMENTO_ACTION';
const DELETE_EDOC_TIPOS_DOCUMENTO_ACTION = 'DELETE_EDOC_TIPOS_DOCUMENTO_ACTION';

const initialState = {
    tipoDocumentoConfig: {
        errorText: '',
        isLoading: false,
        tipoDocumento: '',
        results: [],
        showModalConfirmaRemocao: false,
        idTipoDocumentoRemocao: 0
    }
};

export const actionCreators = {
    fnUpdateState: (pEdocConfiguracaoState) => async (dispatch, getState) => {
        var objState = {
            errorText: pEdocConfiguracaoState.tipoDocumentoConfig.errorText,
            isLoading: pEdocConfiguracaoState.tipoDocumentoConfig.isLoading,
            tipoDocumento: pEdocConfiguracaoState.tipoDocumentoConfig.tipoDocumento,
            results: pEdocConfiguracaoState.tipoDocumentoConfig.results,
            showModalConfirmaRemocao: pEdocConfiguracaoState.tipoDocumentoConfig.showModalConfirmaRemocao,
            idTipoDocumentoRemocao: pEdocConfiguracaoState.tipoDocumentoConfig.idTipoDocumentoRemocao
        };

        dispatch({
            type: 'UPDATE_STATE_EDOC_CONFIGURACAO_ACTION',
            tipoDocumentoConfig: objState
        });
    },
    fnListaTiposDocumento: () => async (dispatch, getState) => {
        dispatch({
            type: 'REQUEST_EDOC_TIPOS_DOCUMENTO_ACTION',
            tipoDocumentoConfig: {
                errorText: '',
                isLoading: true,
                tipoDocumento: getState().edoc_configuracao.tipoDocumentoConfig.tipoDocumento,
                results: [],
                showModalConfirmaRemocao: getState().edoc_configuracao.tipoDocumentoConfig.showModalConfirmaRemocao,
                idTipoDocumentoRemocao: getState().edoc_configuracao.tipoDocumentoConfig.idTipoDocumentoRemocao
            }
        });

        await fetch(edocconfiguracao_service.services.listaTiposDocumento(getState().auth.authorizationToken))
            .then(response => {
                if (response.status === 200 || response.status === 0) {
                    return response;
                }
                else {
                    return response.json().then(function (object) {
                        return Promise.reject(new Error(object));
                    })
                }
            })
            .then(data => {
                data.json().then(function (object) {
                    dispatch({
                        type: 'RESPONSE_EDOC_TIPOS_DOCUMENTO_ACTION',
                        tipoDocumentoConfig: {
                            errorText: '',
                            isLoading: false,
                            tipoDocumento: getState().edoc_configuracao.tipoDocumentoConfig.tipoDocumento,
                            results: object,
                            showModalConfirmaRemocao: getState().edoc_configuracao.tipoDocumentoConfig.showModalConfirmaRemocao,
                            idTipoDocumentoRemocao: getState().edoc_configuracao.tipoDocumentoConfig.idTipoDocumentoRemocao
                        }
                    });
                });
            }).catch(function (err) {
                dispatch({
                    type: 'ERROR_EDOC_TIPOS_DOCUMENTO_ACTION',
                    tipoDocumentoConfig: {
                        errorText: err.toString(),
                        isLoading: false,
                        tipoDocumento: getState().edoc_configuracao.tipoDocumentoConfig.tipoDocumento,
                        results: [],
                        showModalConfirmaRemocao: getState().edoc_configuracao.tipoDocumentoConfig.showModalConfirmaRemocao,
                        idTipoDocumentoRemocao: getState().edoc_configuracao.tipoDocumentoConfig.idTipoDocumentoRemocao
                    }
                });
            });
    },
    fnAdicionarTipoDocumento: () => async (dispatch, getState) => {
        dispatch({
            type: 'POST_EDOC_TIPOS_DOCUMENTO_ACTION',
            tipoDocumentoConfig: {
                errorText: '',
                isLoading: true,
                tipoDocumento: getState().edoc_configuracao.tipoDocumentoConfig.tipoDocumento,
                results: getState().edoc_configuracao.tipoDocumentoConfig.results,
                showModalConfirmaRemocao: getState().edoc_configuracao.tipoDocumentoConfig.showModalConfirmaRemocao,
                idTipoDocumentoRemocao: getState().edoc_configuracao.tipoDocumentoConfig.idTipoDocumentoRemocao
            }
        });

        var pDTO = {
            nome: getState().edoc_configuracao.tipoDocumentoConfig.tipoDocumento
        };

        await fetch(edocconfiguracao_service.services.adicionarTipoDocumento(getState().auth.authorizationToken, pDTO))
            .then(response => {
                if (response.status === 200 || response.status === 0) {
                    return response;
                }
                else {
                    return response.json().then(function (object) {
                        return Promise.reject(new Error(object));
                    })
                }
            })
            .then(data => {
                dispatch({
                    type: 'RESPONSE_EDOC_TIPOS_DOCUMENTO_ACTION',
                    tipoDocumentoConfig: {
                        errorText: '',
                        isLoading: false,
                        tipoDocumento: '',
                        results: getState().edoc_configuracao.tipoDocumentoConfig.results,
                        showModalConfirmaRemocao: getState().edoc_configuracao.tipoDocumentoConfig.showModalConfirmaRemocao,
                        idTipoDocumentoRemocao: getState().edoc_configuracao.tipoDocumentoConfig.idTipoDocumentoRemocao
                    }
                });

                // Vamos listar novamente os tipos de documentos
                dispatch(actionCreators.fnListaTiposDocumento());
            }).catch(function (err) {
                dispatch({
                    type: 'ERROR_EDOC_TIPOS_DOCUMENTO_ACTION',
                    tipoDocumentoConfig: {
                        errorText: err.toString(),
                        isLoading: false,
                        tipoDocumento: getState().edoc_configuracao.tipoDocumentoConfig.tipoDocumento,
                        results: getState().edoc_configuracao.tipoDocumentoConfig.results,
                        showModalConfirmaRemocao: getState().edoc_configuracao.tipoDocumentoConfig.showModalConfirmaRemocao,
                        idTipoDocumentoRemocao: getState().edoc_configuracao.tipoDocumentoConfig.idTipoDocumentoRemocao
                    }
                });
            });
    },
    fnRemoverTipoDocumento: (pId) => async (dispatch, getState) => {
        dispatch({
            type: 'DELETE_EDOC_TIPOS_DOCUMENTO_ACTION',
            tipoDocumentoConfig: {
                errorText: '',
                isLoading: true,
                tipoDocumento: getState().edoc_configuracao.tipoDocumentoConfig.tipoDocumento,
                results: getState().edoc_configuracao.tipoDocumentoConfig.results,
                showModalConfirmaRemocao: getState().edoc_configuracao.tipoDocumentoConfig.showModalConfirmaRemocao,
                idTipoDocumentoRemocao: getState().edoc_configuracao.tipoDocumentoConfig.idTipoDocumentoRemocao
            }
        });

        await fetch(edocconfiguracao_service.services.removerTipoDocumento(getState().auth.authorizationToken, pId))
            .then(response => {
                if (response.status === 200 || response.status === 0) {
                    return response;
                }
                else {
                    return response.json().then(function (object) {
                        return Promise.reject(new Error(object));
                    })
                }
            })
            .then(data => {
                dispatch({
                    type: 'RESPONSE_EDOC_TIPOS_DOCUMENTO_ACTION',
                    tipoDocumentoConfig: {
                        errorText: '',
                        isLoading: false,
                        tipoDocumento: getState().edoc_configuracao.tipoDocumentoConfig.tipoDocumento,
                        results: getState().edoc_configuracao.tipoDocumentoConfig.results,
                        showModalConfirmaRemocao: false,
                        idTipoDocumentoRemocao: 0
                    }
                });

                // Vamos listar novamente os tipos de documentos
                dispatch(actionCreators.fnListaTiposDocumento());
            }).catch(function (err) {
                dispatch({
                    type: 'ERROR_EDOC_TIPOS_DOCUMENTO_ACTION',
                    tipoDocumentoConfig: {
                        errorText: err.toString(),
                        isLoading: false,
                        tipoDocumento: getState().edoc_configuracao.tipoDocumentoConfig.tipoDocumento,
                        results: getState().edoc_configuracao.tipoDocumentoConfig.results,
                        showModalConfirmaRemocao: false,
                        idTipoDocumentoRemocao: 0
                    }
                });
            });
    }
};

export const reducer = (state, action) => {
    state = state || initialState;

    if (action.type === UPDATE_STATE_EDOC_CONFIGURACAO_ACTION) {
        return {
            ...state,
            tipoDocumentoConfig: action.tipoDocumentoConfig
        };
    }

    if (action.type === REQUEST_EDOC_TIPOS_DOCUMENTO_ACTION) {
        return {
            ...state,
            tipoDocumentoConfig: action.tipoDocumentoConfig
        };
    }

    if (action.type === RESPONSE_EDOC_TIPOS_DOCUMENTO_ACTION) {
        return {
            ...state,
            tipoDocumentoConfig: action.tipoDocumentoConfig
        };
    }

    if (action.type === ERROR_EDOC_TIPOS_DOCUMENTO_ACTION) {
        return {
            ...state,
            tipoDocumentoConfig: action.tipoDocumentoConfig
        };
    }

    if (action.type === POST_EDOC_TIPOS_DOCUMENTO_ACTION) {
        return {
            ...state,
            tipoDocumentoConfig: action.tipoDocumentoConfig
        };
    }

    if (action.type === DELETE_EDOC_TIPOS_DOCUMENTO_ACTION) {
        return {
            ...state,
            tipoDocumentoConfig: action.tipoDocumentoConfig
        };
    }

    return state;
};

