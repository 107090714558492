import * as React from 'react'
import { fetch, addTask } from 'domain-task';
import * as publicador_service from '../../../../services/marketing/publicador/publicador_service';

import { Row, Modal } from 'react-bootstrap';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import AlertDismissable from '../../../commons/AlertDismissable';

export default class FotosVeiculoModal extends React.Component {
    constructor() {
        super();
        this.state = {
            isLoading: false,
            fotos: [],
            error: '',
            showModal: false
        };
    }

    componentDidMount() {
        var state = this;

        let fetchTask = fetch(publicador_service.services.obterFotosVeiculo(this.props.idVeiculo, this.props.token))
            .then(response => {
                if (response.status === 200 || response.status === 0) {
                    return response;
                }
                else {
                    return response.json().then(function (object) {
                        return Promise.reject(new Error(object));
                    })
                }
            })
            .then(data => {
                data.json().then(function (object) {
                    var strArrayFotos = [];
                    object.forEach(function (entry) {
                        strArrayFotos.push(entry.imagem);
                    });

                    state.setState({
                        isLoading: false,
                        fotos: strArrayFotos,
                        error: '',
                        showModal: state.state.showModal
                    });
                });
            }).catch(function (err) {
                console.log(err.toString());
                state.setState({
                    isLoading: false,
                    fotos: state.state.fotos,
                    error: err.toString(),
                    showModal: state.state.showModal
                });
            });

        addTask(fetchTask);
        state.setState({
            isLoading: true,
            fotos: [],
            error: '',
            showModal: this.props.showModal
        });
    }

    handleOnHide() {
        this.setState({
            isLoading: false,
            fotos: [],
            error: '',
            showModal: false
        });

        this.props.onHide(false);
    }

    render() {
        return <Modal
            show={this.state.showModal}
            onHide={this.handleOnHide.bind(this)}>
            <Modal.Header closeButton>
                <h4 className="modal-title custom-font">Fotos do Veículo</h4>
            </Modal.Header>
            <Modal.Body>
                <AlertDismissable
                    title="Ops algo deu errado!"
                    text={this.state.error}
                    show={this.state.error != '' && this.state.error != undefined ? true : false}
                    bsStyle="danger"
                />
                {
                    this.state.isLoading
                        ? <div className="progress progress-striped active">
                            <div className="progress-bar progress-bar-blue" role="progressbar" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100" style={{ width: "100%" }}>
                                <span className="sr-only">100% Complete</span>
                            </div>
                        </div>
                        : <Row>
                            {
                                this.state.fotos.length > 0
                                    ? <div className="form-group col-md-12">
                                        <Carousel>
                                            {
                                                this.state.fotos.map(function (rowData, index) {
                                                    return (
                                                        <div>
                                                            <img src={rowData} height="370" />
                                                        </div>
                                                    )
                                                })
                                            }
                                        </Carousel>
                                    </div>
                                    : null
                            }
                        </Row>
                }
            </Modal.Body>
        </Modal>;
    }
}