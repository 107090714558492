import { fetch, addTask } from 'domain-task';
import * as analiserisco_service from '../../services/analiserisco/analiserisco_service';
import * as SolicitarPagamentosStore from './SolicitarPagamentos';

const REQUEST_CONSULTAS_HISTORICO_ACTION = 'REQUEST_CONSULTAS_HISTORICO_ACTION';
const RECEIVE_CONSULTAS_HISTORICO_ACTION = 'RECEIVE_CONSULTAS_HISTORICO_ACTION';
const FAILURE_CONSULTAS_HISTORICO_ACTION = 'FAILURE_CONSULTAS_HISTORICO_ACTION';
const REQUEST_RESULTADO_PESQUISA_COMPLETA_ACTION = 'REQUEST_RESULTADO_PESQUISA_COMPLETA_ACTION';
const RECEIVE_RESULTADO_PESQUISA_COMPLETA_ACTION = 'RECEIVE_RESULTADO_PESQUISA_COMPLETA_ACTION';
const FAILURE_RESULTADO_PESQUISA_COMPLETA_ACTION = 'FAILURE_RESULTADO_PESQUISA_COMPLETA_ACTION';
const POST_SOLICITAR_PESQUISA_ACTION = 'POST_SOLICITAR_PESQUISA_ACTION';
const SUCCESS_SOLICITAR_PESQUISA_ACTION = 'SUCCESS_SOLICITAR_PESQUISA_ACTION';
const FAILURE_SOLICITAR_PESQUISA_ACTION = 'FAILURE_SOLICITAR_PESQUISA_ACTION';
const UPDATE_STATE_CONSULTA_VEICULAR_ACTION = 'UPDATE_STATE_CONSULTA_VEICULAR_ACTION';
const CLOSE_MODAL_RESULTADO_PESQUISA_ACTION = 'CLOSE_MODAL_RESULTADO_PESQUISA_ACTION';

const initialState = {
    Filters: {
        PageNumber: 1,
        PageSize: 10,
        OrderBy: '',
        Ascending: true,
        TotalNumberOfPages: 0,
        TotalNumberOfRecords: 0,
        DataDe: '',
        DataAte: '',
        Chassi: '',
        Placa: ''
    }
    , Results: []
    , ModalResultadoPesquisa: {
        id: 0
        , showModal: false
        , data: {
            Chassi: ''
            , DataHoraSolicitacao: ''
            , ID: 0
            , JsonRetorno: ''
            , NomeResponsavelSolicitacao: ''
            , Placa: ''
            , UF: ''
            , Valor: 0
        }
    }
    , SolicitarPesquisa: {
        ID: 0
        , Placa: ''
        , Chassi: ''
        , UF: ''
        , IsLoading: false
        , showModalSolicitacao: false
    }
    , IsLoading: false
    , ErrorText: ''
};

export const actionCreators = {
    fnListaPesquisasHistorico: () => async (dispatch, getState) => {
        let fetchTask = fetch(analiserisco_service.services.ConsultasVeiculares.ListaConsultasVeicularesHistorico(getState().bndvcompleta.Filters, getState().auth.authorizationToken))
            .then(response => {
                if (response.status === 200 || response.status === 0) {
                    return response.json();
                }
                else {
                    return response.json().then(function (object) {
                        return Promise.reject(new Error(object));
                    })
                }
            })
            .then(data => {
                var _Filters = getState().bndvcompleta.Filters;
                _Filters.Ascending = getState().bndvcompleta.Filters.Ascending;
                _Filters.DataAte = getState().bndvcompleta.Filters.DataAte;
                _Filters.DataDe = getState().bndvcompleta.Filters.DataDe;
                _Filters.Chassi = getState().bndvcompleta.Filters.Chassi;
                _Filters.Placa = getState().bndvcompleta.Filters.Placa;
                _Filters.OrderBy = getState().bndvcompleta.Filters.OrderBy;
                _Filters.PageNumber = getState().bndvcompleta.Filters.PageNumber;
                _Filters.PageSize = getState().bndvcompleta.Filters.PageSize;
                _Filters.TotalNumberOfPages = data.TotalNumberOfPages;
                _Filters.TotalNumberOfRecords = data.TotalNumberOfRecords;

                dispatch({ type: 'RECEIVE_CONSULTAS_HISTORICO_ACTION', Results: data.Results });
            }).catch(function (err) {
                dispatch({ type: 'FAILURE_CONSULTAS_HISTORICO_ACTION', ErrorText: err.message });
            });

        addTask(fetchTask);
        dispatch({ type: 'REQUEST_CONSULTAS_HISTORICO_ACTION', Filters: getState().bndvcompleta.Filters });

    }
    , fnUpdateState: (pConsultaVeicularState) => async (dispatch, getState) => {
        dispatch({
            type: 'UPDATE_STATE_CONSULTA_VEICULAR_ACTION',
            Filters: pConsultaVeicularState.Filters,
            Results: pConsultaVeicularState.Results,
            ModalResultadoPesquisa: pConsultaVeicularState.ModalResultadoPesquisa,
            SolicitarPesquisa: pConsultaVeicularState.SolicitarPesquisa,
            IsLoading: pConsultaVeicularState.IsLoading,
            ErrorText: pConsultaVeicularState.ErrorText
        });
    }
    , fnMostraPesquisa: (pId) => async (dispatch, getState) => {

        getState().bndvcompleta.ModalResultadoPesquisa.id = pId;
        getState().bndvcompleta.ModalResultadoPesquisa.showModal = true;

        let fetchTask = fetch(analiserisco_service.services.ConsultasVeiculares.BuscaResultadoPesquisaCompleta(getState().bndvcompleta.ModalResultadoPesquisa.id, getState().auth.authorizationToken))
            .then(response => {
                if (response.status === 200 || response.status === 0) {
                    return response.json();
                }
                else {
                    return response.json().then(function (object) {
                        return Promise.reject(new Error(object));
                    })
                }
            })
            .then(data => {
                getState().bndvcompleta.ModalResultadoPesquisa.data = data;
                dispatch({ type: 'RECEIVE_RESULTADO_PESQUISA_COMPLETA_ACTION', ModalResultadoPesquisa: getState().bndvcompleta.ModalResultadoPesquisa });
            }).catch(function (err) {
                dispatch({ type: 'FAILURE_RESULTADO_PESQUISA_COMPLETA_ACTION', ErrorText: err.message });
            });

        addTask(fetchTask);
        dispatch({ type: 'REQUEST_RESULTADO_PESQUISA_COMPLETA_ACTION', ModalResultadoPesquisa: getState().bndvcompleta.ModalResultadoPesquisa });

    }
    , fnCloseModalResultadoPesquisa: () =>  async(dispatch, getState) => {

        getState().bndvcompleta.ModalResultadoPesquisa.id = 0;
        getState().bndvcompleta.ModalResultadoPesquisa.data = { Chassi: '', DataHoraSolicitacao: '', ID: 0, JsonRetorno: '', NomeResponsavelSolicitacao: '', Placa: '', UF: '', Valor: 0 };
        getState().bndvcompleta.ModalResultadoPesquisa.showModal = false;

        dispatch({ type: 'CLOSE_MODAL_RESULTADO_PESQUISA_ACTION', ModalResultadoPesquisa: getState().bndvcompleta.ModalResultadoPesquisa });
    }
    , fnSolicitarPesquisa: () => async (dispatch, getState) => {

        getState().bndvcompleta.SolicitarPesquisa.IsLoading = true;

        let fetchTask = fetch(analiserisco_service.services.ConsultasVeiculares.SolicitaPesquisaCompleta(
            getState().bndvcompleta.SolicitarPesquisa.Placa
            , getState().bndvcompleta.SolicitarPesquisa.Chassi
            , getState().bndvcompleta.SolicitarPesquisa.UF
            , getState().auth.authorizationToken)
        ).then(response => {
            if (response.status === 200 || response.status === 0) {
                return response.json();
            }
            else {
                return response.json().then(function (object) {
                    return Promise.reject(new Error(object));
                })
            }
        }).then(data => {
            //getState().bndvcompleta.SolicitarPesquisa.ID = data.ID;
            getState().bndvcompleta.SolicitarPesquisa.Placa = '';
            getState().bndvcompleta.SolicitarPesquisa.Chassi = '';
            getState().bndvcompleta.SolicitarPesquisa.UF = '';
            getState().bndvcompleta.SolicitarPesquisa.IsLoading = false;
            getState().bndvcompleta.SolicitarPesquisa.showModalSolicitacao = false;

            dispatch({ type: 'SUCCESS_SOLICITAR_PESQUISA_ACTION', SolicitarPesquisa: getState().bndvcompleta.SolicitarPesquisa });

            // Mostra a pesquisa
            dispatch(actionCreators.fnMostraPesquisa(data.ID));
            // Atualiza o valor de crédito do cliente
            dispatch(SolicitarPagamentosStore.actionCreators.fnBuscaSaldo());
        }).catch(function (err) {
            getState().bndvcompleta.SolicitarPesquisa.IsLoading = false;

            dispatch({ type: 'FAILURE_SOLICITAR_PESQUISA_ACTION', SolicitarPesquisa: getState().bndvcompleta.SolicitarPesquisa, ErrorText: err.message });
        });

        addTask(fetchTask);
        dispatch({ type: 'POST_SOLICITAR_PESQUISA_ACTION', SolicitarPesquisa: getState().bndvcompleta.SolicitarPesquisa });

    }
};

export const reducer = (state, action) => {
    state = state || initialState;

    if (action.type === REQUEST_CONSULTAS_HISTORICO_ACTION) {
        return {
            ...state,
            Filters: action.Filters,
            IsLoading: true
        };
    }

    if (action.type === RECEIVE_CONSULTAS_HISTORICO_ACTION) {
        return {
            ...state,
            Results: action.Results,
            IsLoading: false
        };
    }

    if (action.type === FAILURE_CONSULTAS_HISTORICO_ACTION) {
        return {
            ...state,
            IsLoading: false,
            ErrorText: action.ErrorText
        };
    }

    if (action.type === REQUEST_RESULTADO_PESQUISA_COMPLETA_ACTION) {
        return {
            ...state,
            ModalResultadoPesquisa: action.ModalResultadoPesquisa,
            IsLoading: true
        };
    }

    if (action.type === RECEIVE_RESULTADO_PESQUISA_COMPLETA_ACTION) {
        return {
            ...state,
            ModalResultadoPesquisa: action.ModalResultadoPesquisa,
            IsLoading: false
        };
    }

    if (action.type === FAILURE_RESULTADO_PESQUISA_COMPLETA_ACTION) {
        return {
            ...state,
            ErrorText: action.ErrorText,
            IsLoading: false
        }
    }

    if (action.type === FAILURE_CONSULTAS_HISTORICO_ACTION) {
        return {
            ...state,
            IsLoading: false,
            ErrorText: action.ErrorText
        };
    }

    if (action.type === UPDATE_STATE_CONSULTA_VEICULAR_ACTION) {
        return {
            ...state,
            Filters: action.Filters,
            Results: action.Results,
            ModalResultadoPesquisa: action.ModalResultadoPesquisa,
            SolicitarPesquisa: action.SolicitarPesquisa,
            IsLoading: action.IsLoading,
            ErrorText: action.ErrorText
        };
    }

    if (action.type === CLOSE_MODAL_RESULTADO_PESQUISA_ACTION) {
        return {
            ...state,
            ModalResultadoPesquisa: action.ModalResultadoPesquisa
        };
    }

    if (action.type === POST_SOLICITAR_PESQUISA_ACTION) {
        return {
            ...state,
            SolicitarPesquisa: action.SolicitarPesquisa
        };
    }

    if (action.type === SUCCESS_SOLICITAR_PESQUISA_ACTION) {
        return {
            ...state,
            SolicitarPesquisa: action.SolicitarPesquisa
        };
    }

    if (action.type === FAILURE_SOLICITAR_PESQUISA_ACTION) {
        return {
            ...state,
            SolicitarPesquisa: action.SolicitarPesquisa,
            ErrorText: action.ErrorText
        };
    }

    return state;
};

