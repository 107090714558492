import * as React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actionCreators } from '../../../store/crm/CrmConsulta';

import { Modal, Row } from 'react-bootstrap';
import AlertError from '../../commons/AlertError';
import AlertSuccess from '../../commons/AlertSuccess';
import ProgerssBar from '../../commons/ProgressBar';
import InputSelect from '../../commons/InputSelect';
import BtnSalvar from '../../commons/BtnSalvar';

class ModalUsuarios extends React.Component {
    constructor(props) {
        super(props);
    }

    async componentDidMount() {
        await this.props.fnListaUsuarios();
    }

    handleOnHide() {
        this.props.modalUsuarios.showModal = false;
        this.props.fnUpdateState(this.props);
    }

    async fnAtualizarResponsavel() {
        await this.props.fnAtualizaResponsavelOportunidade();
    }

    render() {
        var objProps = this.props;

        var dropdownUsuarios = [];
        dropdownUsuarios.push({ value: null, text: '' });
        objProps.modalUsuarios.dropdownUsuarios.results.forEach(function (entry) {
            dropdownUsuarios.push({ value: entry.id.toString(), text: entry.nome });
        });

        return <Modal
            show={this.props.modalUsuarios.showModal}
            onHide={this.handleOnHide.bind(this)}
            bsSize="small"
        >
            <form
                id="frm"
                name="frm"
                role="form"
                onSubmit={this.handleSubmit}
                data-parsley-validate>
                <Modal.Header closeButton>
                    <h4 className="modal-title custom-font">Encaminhar Oportunidade</h4>
                </Modal.Header>
                <Modal.Body>
                    <AlertSuccess
                        successText={this.props.modalUsuarios.successText}
                        alertVisible={this.props.modalUsuarios.successText != '' && this.props.modalUsuarios.successText != undefined ? true : false}
                        onDismiss={(alertVisible, successText) => {
                            this.props.modalUsuarios.successText = successText;
                            this.props.fnUpdateState(this.props);
                        }}
                    />
                    <AlertError
                        errorText={this.props.modalUsuarios.errorText}
                        alertVisible={this.props.modalUsuarios.errorText != '' && this.props.modalUsuarios.errorText != undefined ? true : false}
                        onDismiss={(alertVisible, errorText) => {
                            this.props.modalUsuarios.errorText = errorText;
                            this.props.fnUpdateState(this.props);
                        }}
                    />
                    {
                        this.props.modalUsuarios.isLoading
                            ? <ProgerssBar />
                            : <div>
                                <Row>
                                    <InputSelect
                                        name={"ddlUsuario"}
                                        className="col-md-12"
                                        data={
                                            objProps.modalUsuarios.dropdownUsuarios.isLoading
                                                ? [{ value: null, text: 'Carregando...' }]
                                                : objProps.modalUsuarios.dropdownUsuarios.errorText != ''
                                                    ? [{ value: null, text: objProps.modalUsuarios.dropdownUsuarios.errorText }]
                                                    : dropdownUsuarios
                                        }
                                        onChange={(name, value) => {
                                            objProps.modalUsuarios.idNovoResponsavel = value;
                                            objProps.fnUpdateState(objProps);
                                        }}
                                        selectedValue={objProps.modalUsuarios.idNovoResponsavel}
                                        title=""
                                        disabled={
                                            objProps.modalUsuarios.dropdownUsuarios.isLoading
                                                ? true
                                                : false
                                        }
                                    />
                                    <div className="col-md-12 text-right">
                                        <BtnSalvar
                                            isLoading={this.props.modalUsuarios.isLoading}
                                            text={this.props.modalUsuarios.isLoading ? " Aguarde..." : " Encaminhar"}
                                            disabled={this.props.modalUsuarios.isLoading}
                                            onClick={() => { this.fnAtualizarResponsavel(); }}
                                        />
                                    </div>
                                </Row>
                            </div>
                    }
                </Modal.Body>
            </form>
        </Modal>;
    }
}

export default connect(
    state => state.crm_consulta,
    dispatch => bindActionCreators(actionCreators, dispatch),
    null, {}
)(ModalUsuarios);