import * as funcs from '../../../utils/funcs';

//const _URL = 'http://localhost:51880/api/v1/';
//const _URL = 'https://apidaypag-homologa.bndv.com.br/api/v1/';
const _URL = 'https://apidaypag.bndv.com.br/api/v1/'

export const services = {
    listaHistoricoPagamentos: (authorizationToken) => {
        return new Request(_URL + 'listaHistoricoPagamentos?dataDe=2018-01-01&dataAte=2019-12-31',
            {
                method: 'GET',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
                    'Authorization': 'Bearer ' + authorizationToken
                }
            });
    },
    obterRecibo: (pDTO, authorizationToken) => {
        var query = funcs.json_to_uri(pDTO);

        return new Request(_URL + 'obterRecibo',
            {
                method: 'POST',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
                    'Authorization': 'Bearer ' + authorizationToken
                },
                body: query
            });
    }
};