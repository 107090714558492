import * as React from 'react';

import HeaderPage from '../../components/commons/HeaderPage';
import SolicitarPagamentosForm from '../../components/analiserisco/pagamentos/SolicitarPagamentosForm';
import BNDVCompletaTab from '../../components/analiserisco/bndvcompleta/BNDVCompletaTab';

export default class BndvCompleta extends React.Component {
    render() {
        return <div className='page'>
            <HeaderPage path=' Análise de Risco' name='Pesquisas Veiculares' subName='BNDV Completa' />
            <SolicitarPagamentosForm />
            <BNDVCompletaTab />
        </div>;
    }
}