import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actionCreators } from '../../store/template/navmenu';
import { persistStore } from 'redux-persist';
import * as localForage from 'localforage';
import configureStore from '../../store/configureStore';

let store = configureStore();

class NavMenu extends Component {
    componentDidMount() {
        var urlArray = window.location.href.replace('http://', '').replace('https://', '').split('/');
        if (urlArray.length === 2) {
            this.props.fnChangeMenu(urlArray[1], urlArray[1]);
        }
        if (urlArray.length === 3) {
            this.props.fnChangeMenu(urlArray[1], urlArray[1] + '/' + urlArray[2]);
        }
        if (urlArray.length === 4) {
            this.props.fnChangeMenu(urlArray[1], urlArray[1] + '/' + urlArray[2] + '/' + urlArray[3]);
        }
    }

    menuChanged(menu, e) {
        this.props.fnChangeMenu(menu, '');
    }

    subMenuChanged(link, e) {
        this.props.fnChangeMenu(this.props.navmenu.menuClass, link);
    }

    verificaPermissao(modulo) {
        var boolReturn = false;
        JSON.parse(this.props.navmenu.modulos).forEach(function (entry) {
            if (entry.toString() === modulo.toString()) {
                boolReturn = true;
            }
        });

        return boolReturn;
    }

    render() {
        return (
            <div id="controls">
                <aside id="sidebar">
                    <div id="sidebar-wrap">
                        <div className="panel-group slim-scroll" role="tablist">
                            <div className="panel panel-default">
                                <div id="sidebarNav" className="panel-collapse collapse in" role="tabpanel">
                                    <div className="panel-body">
                                        <ul id="navigation">
                                            <li className={(this.props.navmenu.menuClass === '' ? 'active' : '')}>
                                                <Link to={'/'} onClick={this.menuChanged.bind(this, '')}>
                                                    <i className="fa fa-home"></i> <span>Home - BNDV 2.0</span>
                                                </Link>
                                            </li>
                                            {//Verifica se o usuário possui permissão para acessar o dashboard
                                                this.verificaPermissao(157)
                                                    ? <li className={(this.props.navmenu.menuClass === 'dashboard' ? 'active' : '')}>
                                                        <Link to={'/dashboard'} onClick={this.menuChanged.bind(this, 'dashboard')}>
                                                            <i className="fa fa-dashboard"></i> <span>Dashboard</span>
                                                        </Link>
                                                    </li>
                                                    : null
                                            }
                                            {
                                                //Cadastros Gerais
                                                this.verificaPermissao(1000000)
                                                    ? <li className={(this.props.navmenu.menuClass === 'cadastrosgerais' ? 'active open dropdown' : 'dropdown')}>
                                                        <a role="button" tabIndex={0} onClick={this.menuChanged.bind(this, 'cadastrosgerais')}><i className="fa fa-edit" aria-hidden="true"></i> <span>Cadastros Gerais</span></a>
                                                        <ul style={
                                                            (
                                                                this.props.navmenu.subMenuClass === 'cadastrosgerais/usuarios'
                                                                    ? { display: 'block' }
                                                                    : { display: 'none' }
                                                            )
                                                        }>
                                                            {
                                                                // Usuários
                                                                this.verificaPermissao(1)
                                                                    ?
                                                                    <li className={(this.props.navmenu.subMenuClass === 'cadastrosgerais/usuarios' ? 'active' : '')} >
                                                                        <Link to={'/cadastrosgerais/usuarios'} onClick={this.subMenuChanged.bind(this, 'cadastrosgerais/usuarios')}><i className="fa fa-caret-right"></i> Usuários</Link>
                                                                    </li>
                                                                    : null
                                                            }
                                                        </ul>
                                                    </li>
                                                    : null
                                            }
                                            {
                                                // Veículos 
                                                this.verificaPermissao(1000000)
                                                    ? <li className={(this.props.navmenu.menuClass === 'veiculos' ? 'active open dropdown' : 'dropdown')}>
                                                        <a role="button" tabIndex={0} onClick={this.menuChanged.bind(this, 'veiculos')}><i className="fa fa-car" aria-hidden="true"></i> <span>Veículos</span></a>
                                                        <ul style={
                                                            (
                                                                this.props.navmenu.subMenuClass === 'veiculos/estoque'
                                                                    ? { display: 'block' }
                                                                    : { display: 'none' }
                                                            )
                                                        }>
                                                            {
                                                                // Estoque
                                                                this.verificaPermissao(129)
                                                                    ?
                                                                    <li className={(this.props.navmenu.subMenuClass === 'veiculos/estoque' ? 'active' : '')} >
                                                                        <Link to={'/veiculos/estoque'} onClick={this.subMenuChanged.bind(this, 'veiculos/estoque')}><i className="fa fa-caret-right"></i> Estoque</Link>
                                                                    </li>
                                                                    : null
                                                            }
                                                        </ul>
                                                    </li>
                                                    : null
                                            }
                                            {
                                                // Análise de Risco
                                                (this.verificaPermissao(158) || this.verificaPermissao(159)) && 1 === 2
                                                    ? <li className={(this.props.navmenu.menuClass === 'analiserisco' ? 'active open dropdown' : 'dropdown')}>
                                                        <a role="button" tabIndex={0} onClick={this.menuChanged.bind(this, 'analiserisco')}><i className="fa fa-eye" aria-hidden="true"></i> <span>Análise de Risco</span></a>
                                                        <ul style={
                                                            (
                                                                this.props.navmenu.subMenuClass === 'analiserisco/pagamentos'
                                                                    || this.props.navmenu.subMenuClass === 'analiserisco/bndvcompleta'
                                                                    || this.props.navmenu.subMenuClass === 'analiserisco/bndvcompletarf'
                                                                    ? { display: 'block' }
                                                                    : { display: 'none' }
                                                            )
                                                        }>
                                                            {
                                                                this.verificaPermissao(158)
                                                                    ? <li className={(this.props.navmenu.subMenuClass === 'analiserisco/pagamentos' ? 'active' : '')} >
                                                                        <Link to={'/analiserisco/pagamentos'} onClick={this.subMenuChanged.bind(this, 'analiserisco/pagamentos')}><i className="fa fa-caret-right"></i> Pagamentos</Link>
                                                                    </li>
                                                                    : null
                                                            }
                                                            {
                                                                this.verificaPermissao(159)
                                                                    ? <li className={
                                                                        (
                                                                            this.props.navmenu.subMenuClass === 'analiserisco/bndvcompleta'
                                                                                || this.props.navmenu.subMenuClass === 'analiserisco/bndvcompletarf'
                                                                                ? 'dropdown submenu open'
                                                                                : 'dropdown submenu'
                                                                        )
                                                                    }>
                                                                        <a role="button" tabIndex={0}><i className="fa fa-caret-right"></i> Pesquisas Veiculares</a>
                                                                        <ul style={
                                                                            (
                                                                                this.props.navmenu.subMenuClass === 'analiserisco/bndvcompleta'
                                                                                    || this.props.navmenu.subMenuClass === 'analiserisco/bndvcompletarf'
                                                                                    ? { display: 'block' }
                                                                                    : { display: 'none' }
                                                                            )
                                                                        }>
                                                                            <li className={(this.props.navmenu.subMenuClass === 'analiserisco/bndvcompleta' ? 'active' : '')}>
                                                                                <Link to={'/analiserisco/bndvcompleta'} onClick={this.subMenuChanged.bind(this, 'analiserisco/bndvcompleta')}><i className="fa fa-caret-right"></i> BNDV Completa</Link>
                                                                            </li>
                                                                            <li className={(this.props.navmenu.subMenuClass === 'analiserisco/bndvcompletarf' ? 'active' : '')}>
                                                                                <Link to={'/analiserisco/bndvcompletarf'} onClick={this.subMenuChanged.bind(this, 'analiserisco/bndvcompletarf')}><i className="fa fa-caret-right"></i> BNDV Completa + RF</Link>
                                                                            </li>
                                                                        </ul>
                                                                    </li>
                                                                    : null
                                                            }
                                                        </ul>
                                                    </li>
                                                    : null
                                            }
                                            {
                                                // Marketing
                                                this.verificaPermissao(149) || this.verificaPermissao(150)
                                                    ? <li className={(this.props.navmenu.menuClass === 'marketing' ? 'active open dropdown' : 'dropdown')}>
                                                        <a role="button" tabIndex={0} onClick={this.menuChanged.bind(this, 'marketing')}><i className="fa fa-bullhorn" aria-hidden="true"></i> <span>Marketing</span></a>
                                                        <ul style={
                                                            (
                                                                this.props.navmenu.subMenuClass === 'marketing/publicador/meusanuncios'
                                                                    || this.props.navmenu.subMenuClass === 'marketing/publicador/configuracao'
                                                                    ? { display: 'block' }
                                                                    : { display: 'none' }
                                                            )
                                                        }>
                                                            <li className={
                                                                (
                                                                    this.props.navmenu.subMenuClass === 'marketing/publicador/meusanuncios'
                                                                        || this.props.navmenu.subMenuClass === 'marketing/publicador/configuracao'
                                                                        ? 'dropdown submenu open'
                                                                        : 'dropdown submenu'
                                                                )
                                                            }>
                                                                <a role="button" tabIndex={0}><i className="fa fa-caret-right"></i> Publicador</a>
                                                                <ul style={
                                                                    (
                                                                        this.props.navmenu.subMenuClass === 'marketing/publicador/meusanuncios'
                                                                            || this.props.navmenu.subMenuClass === 'marketing/publicador/configuracao'
                                                                            ? { display: 'block' }
                                                                            : { display: 'none' }
                                                                    )
                                                                }>
                                                                    {
                                                                        this.verificaPermissao(149)
                                                                            ? <li className={(this.props.navmenu.subMenuClass === 'marketing/publicador/meusanuncios' ? 'active' : '')} >
                                                                                <Link to={'/marketing/publicador/meusanuncios'} onClick={this.subMenuChanged.bind(this, 'marketing/publicador/meusanuncios')}><i className="fa fa-caret-right"></i> Meus Anúncios</Link>
                                                                            </li>
                                                                            : null
                                                                    }
                                                                    {
                                                                        this.verificaPermissao(150)
                                                                            ? <li className={(this.props.navmenu.subMenuClass === 'marketing/publicador/configuracao' ? 'active' : '')} >
                                                                                <Link to={'/marketing/publicador/configuracao'} onClick={this.subMenuChanged.bind(this, 'marketing/publicador/configuracao')}><i className="fa fa-caret-right"></i> Configurações</Link>
                                                                            </li>
                                                                            : null
                                                                    }
                                                                </ul>
                                                            </li>
                                                        </ul>
                                                    </li>
                                                    : null
                                            }
                                            {
                                                // Nfe
                                                this.verificaPermissao(1000000)
                                                    ? <li className={(this.props.navmenu.menuClass === 'nfe' ? 'active open dropdown' : 'dropdown')}>
                                                        <a role="button" tabIndex={0} onClick={this.menuChanged.bind(this, 'nfe')}><i className="fa fa-file" aria-hidden="true"></i> <span>NF-e</span></a>
                                                        <ul style={
                                                            (
                                                                this.props.navmenu.subMenuClass === 'nfe/entradas'
                                                                    || this.props.navmenu.subMenuClass === 'nfe/saidas'
                                                                    || this.props.navmenu.subMenuClass === 'nfe/consultas'
                                                                    ? { display: 'block' }
                                                                    : { display: 'none' }
                                                            )
                                                        }>

                                                            <li className={(this.props.navmenu.subMenuClass === 'nfe/entradas' ? 'active' : '')} >
                                                                <Link to={'/nfe/entradas'} onClick={this.subMenuChanged.bind(this, 'nfe/entradas')}><i className="fa fa-caret-right"></i> Entradas</Link>
                                                            </li>
                                                            <li className={(this.props.navmenu.subMenuClass === 'nfe/saidas' ? 'active' : '')} >
                                                                <Link to={'/nfe/saidas'} onClick={this.subMenuChanged.bind(this, 'nfe/saidas')}><i className="fa fa-caret-right"></i> Saídas</Link>
                                                            </li>
                                                            <li className={(this.props.navmenu.subMenuClass === 'nfe/consultas' ? 'active' : '')} >
                                                                <Link to={'/nfe/consultas'} onClick={this.subMenuChanged.bind(this, 'nfe/consultas')}><i className="fa fa-caret-right"></i> Consultas</Link>
                                                            </li>
                                                        </ul>
                                                    </li>
                                                    : null}
                                            {
                                                // GED - (Edoc Novo)
                                                this.verificaPermissao(147) || this.verificaPermissao(148)
                                                    ? <li className={(this.props.navmenu.menuClass === 'edoc' ? 'active open dropdown' : 'dropdown')}>
                                                        <a role="button" tabIndex={0} onClick={this.menuChanged.bind(this, 'edoc')}><i className="fa fa-envelope" aria-hidden="true"></i> <span>E-doc</span></a>
                                                        <ul style={
                                                            (
                                                                this.props.navmenu.subMenuClass === 'edoc/consulta'
                                                                    || this.props.navmenu.subMenuClass === 'edoc/configuracao'
                                                                    ? { display: 'block' }
                                                                    : { display: 'none' }
                                                            )
                                                        }>
                                                            {this.verificaPermissao(147)
                                                                ? <li className={(this.props.navmenu.subMenuClass === 'edoc/consulta' ? 'active' : '')} >
                                                                    <Link to={'/edoc/consulta'} onClick={this.subMenuChanged.bind(this, 'edoc/consulta')}><i className="fa fa-caret-right"></i> Consulta</Link>
                                                                </li>
                                                                : null}
                                                            {this.verificaPermissao(148)
                                                                ? <li className={(this.props.navmenu.subMenuClass === 'edoc/configuracao' ? 'active' : '')} >
                                                                    <Link to={'/edoc/Configuracao'} onClick={this.subMenuChanged.bind(this, 'edoc/configuracao')}><i className="fa fa-caret-right"></i> Configuração</Link>
                                                                </li>
                                                                : null}
                                                        </ul>
                                                    </li>
                                                    : null
                                            }
                                            {
                                                // Documentação (Despachante/Pagameto de Débitos)
                                                this.verificaPermissao(1000000)
                                                    //(1 === 1)
                                                    ? <li className={(this.props.navmenu.menuClass === 'documentos' ? 'active open dropdown' : 'dropdown')}>
                                                        <a role="button" tabIndex={0} onClick={this.menuChanged.bind(this, 'documentos')}><i className="fa fa-exchange" aria-hidden="true"></i> <span>Documentação Veículos</span></a>
                                                        <ul style={
                                                            (
                                                                this.props.navmenu.subMenuClass === 'documentos/daypag/consulta'
                                                                    || this.props.navmenu.subMenuClass === 'documentos/daypag/historicopagamentos'
                                                                    ? { display: 'block' }
                                                                    : { display: 'none' }
                                                            )
                                                        }>
                                                            <li className={
                                                                (
                                                                    this.props.navmenu.subMenuClass === 'documentos/daypag/consulta'
                                                                        || this.props.navmenu.subMenuClass === 'documentos/daypag/historicopagamentos'
                                                                        ? 'dropdown submenu open'
                                                                        : 'dropdown submenu'
                                                                )
                                                            }>
                                                                <a role="button" tabIndex={0}><i className="fa fa-caret-right"></i> Daypag - Banco Daycoval</a>
                                                                <ul style={
                                                                    (
                                                                        this.props.navmenu.subMenuClass === 'documentos/daypag/consulta'
                                                                            || this.props.navmenu.subMenuClass === 'documentos/daypag/historicopagamentos'
                                                                            ? { display: 'block' }
                                                                            : { display: 'none' }
                                                                    )
                                                                }>
                                                                    <li className={(this.props.navmenu.subMenuClass === 'documentos/daypag/consulta' ? 'active' : '')} >
                                                                        <Link to={'/documentos/daypag/consulta'} onClick={this.subMenuChanged.bind(this, 'documentos/daypag/consulta')}><i className="fa fa-caret-right"></i> Consulta/Pagamentos</Link>
                                                                    </li>
                                                                    <li className={(this.props.navmenu.subMenuClass === 'documentos/daypag/historicopagamentos' ? 'active' : '')} >
                                                                        <Link to={'/documentos/daypag/historicopagamentos'} onClick={this.subMenuChanged.bind(this, 'documentos/daypag/historicopagamentos')}><i className="fa fa-caret-right"></i> Histórico de Pagamentos</Link>
                                                                    </li>
                                                                </ul>
                                                            </li>
                                                        </ul>
                                                    </li>
                                                    : null
                                            }
                                            {
                                                // CRM
                                                this.verificaPermissao(144) || this.verificaPermissao(145) || this.verificaPermissao(146)
                                                    ? <li className={(this.props.navmenu.menuClass === 'crm' ? 'active open dropdown' : 'dropdown')}>
                                                        <a role="button" tabIndex={0} onClick={this.menuChanged.bind(this, 'crm')}><i className="fa fa-calendar" aria-hidden="true"></i> <span>CRM</span></a>
                                                        <ul style={
                                                            (
                                                                this.props.navmenu.subMenuClass === 'crm/consulta'
                                                                    || this.props.navmenu.subMenuClass === 'crm/configuracao'
                                                                    || this.props.navmenu.subMenuClass === 'crm/webmotors'
                                                                    ? { display: 'block' }
                                                                    : { display: 'none' }
                                                            )
                                                        }>
                                                            {this.verificaPermissao(144)
                                                                ? <li className={(this.props.navmenu.subMenuClass === 'crm/consulta' ? 'active' : '')} >
                                                                    <Link to={'/crm/consulta'} onClick={this.subMenuChanged.bind(this, 'crm/consulta')}><i className="fa fa-caret-right"></i> Consulta</Link>
                                                                </li>
                                                                : null}
                                                            {this.verificaPermissao(144)
                                                                ? <li className={(this.props.navmenu.subMenuClass === 'crm/pre_aprovado_mercado_livre' ? 'active' : '')} >
                                                                    <Link to={'/crm/pre_aprovado_mercado_livre'} onClick={this.subMenuChanged.bind(this, 'crm/pre_aprovado_mercado_livre')}><i className="fa fa-caret-right"></i> Pré Aprovado Mercado Livre</Link>
                                                                </li>
                                                                : null}
                                                            {this.verificaPermissao(145)
                                                                ? <li className={(this.props.navmenu.subMenuClass === 'crm/configuracao' ? 'active' : '')} >
                                                                    <Link to={'/crm/configuracao'} onClick={this.subMenuChanged.bind(this, 'crm/configuracao')}><i className="fa fa-caret-right"></i> Configuração</Link>
                                                                </li>
                                                                : null}
                                                            {this.verificaPermissao(146)
                                                                ? <li className={(this.props.navmenu.subMenuClass === 'crm/webmotors' ? 'active' : '')} >
                                                                    <Link to={'/crm/webmotors'} onClick={this.subMenuChanged.bind(this, 'crm/webmotors')}><i className="fa fa-caret-right"></i> Leads WebMotors</Link>
                                                                </li>
                                                                : null}
                                                        </ul>
                                                    </li>
                                                    : null
                                            }
                                            <li>
                                                <a onClick={() => {
                                                    persistStore(store, { storage: localForage, keyPrefix: 'bndvStorage' }).purge(); document.location.href = 'http://sistema.bndv.com.br/Veiculos/Estoque_Home.aspx?TypeAba=DP';
                                                }}>
                                                    <i className="fa fa-caret-left"></i> <span>Voltar para BNDV 1.0</span>
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </aside>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        auth: state.auth,
        navmenu: state.navmenu
    }
}

export default connect(
    mapStateToProps,
    dispatch => bindActionCreators(actionCreators, dispatch),
    null,
    { pure: false }
)(NavMenu);