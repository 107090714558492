import * as React from 'react';
import HeaderPage from '../../components/commons/HeaderPage';
import SolicitarPagamentosForm from '../../components/analiserisco/pagamentos/SolicitarPagamentosForm';
import HistoricoPagamentosForm from '../../components/analiserisco/pagamentos/HistoricoPagamentosForm';

export default class Pagamentos extends React.Component {
    render() {
        return <div className='page'>
            <HeaderPage path=' Análise de Risco' name='Pagamentos' />
            <SolicitarPagamentosForm />
            <HistoricoPagamentosForm />
        </div>;
    }
}