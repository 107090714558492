import * as React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Row } from 'react-bootstrap';
import { actionCreators } from '../../../store/dashboard/DashboardAdministrador';
import VendidosXMetaSemestre from './VendidosXMetaSemestre';
import LucratividadeXMetaSemestre from './LucratividadeXMetaSemestre';
import FinanciamentosXMetaSemestre from './FinanciamentosXMetaSemestre';
import FinanciamentosPorFinanceiraMesAtual from './FinanciamentosPorFinanceiraMesAtual';
import VendidosPorVendedorMesAtual from './VendidosPorVendedorMesAtual';
import LucratividadePorVendedorMesAtual from './LucratividadePorVendedorMesAtual';
import DezVeiculosMaisVendidosTrimestre from './DezVeiculosMaisVendidosTrimestre';
//import DezModelosMaiorTempoEstoqueMedia from './DezModelosMaiorTempoEstoqueMedia';

class DashboardAdministradorForm extends React.Component {
    render() {
        return (
            this.props.dashboardadministrador.empresaSelected > 0
                ? <Row>
                    <div className="col-md-6">
                        <VendidosXMetaSemestre
                            authorizationToken={this.props.auth.authorizationToken}
                            empresaSelected={this.props.dashboardadministrador.empresaSelected}
                        />
                    </div>
                    <div className="col-md-6">
                        <LucratividadeXMetaSemestre
                            authorizationToken={this.props.auth.authorizationToken}
                            empresaSelected={this.props.dashboardadministrador.empresaSelected}
                        />
                    </div>
                    <div className="col-md-6">
                        <FinanciamentosXMetaSemestre
                            authorizationToken={this.props.auth.authorizationToken}
                            empresaSelected={this.props.dashboardadministrador.empresaSelected}
                        />
                    </div>
                    <div className="col-md-6">
                        <FinanciamentosPorFinanceiraMesAtual
                            authorizationToken={this.props.auth.authorizationToken}
                            empresaSelected={this.props.dashboardadministrador.empresaSelected}
                        />
                    </div>
                    <div className="col-md-6">
                        <VendidosPorVendedorMesAtual
                            authorizationToken={this.props.auth.authorizationToken}
                            empresaSelected={this.props.dashboardadministrador.empresaSelected}
                        />
                    </div>
                    <div className="col-md-6">
                        <LucratividadePorVendedorMesAtual
                            authorizationToken={this.props.auth.authorizationToken}
                            empresaSelected={this.props.dashboardadministrador.empresaSelected}
                        />
                    </div>
                    <div className="col-md-6">
                        <DezVeiculosMaisVendidosTrimestre
                            authorizationToken={this.props.auth.authorizationToken}
                            empresaSelected={this.props.dashboardadministrador.empresaSelected}
                        />
                    </div>
                </Row>
                : null
        );
    }
}

function mapStateToProps(state) {
    return {
        auth: state.auth,
        dashboardadministrador: state.dashboardadministrador
    }
}

export default connect(
    mapStateToProps,
    dispatch => bindActionCreators(actionCreators, dispatch),
    null,
    { pure: false }
)(DashboardAdministradorForm);