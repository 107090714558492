const URLAuth = 'https://apisistema.bndv.com.br/Authentication/';
//const URLAuth = 'http://localhost:60821/';

export const services = {
    Auth: {
        RequestLogin: (username, password, cnpjEmpresa) => {
            password = password.replace(' ', '+');
            return new Request(URLAuth + 'Token',
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
                    },
                    body: 'grant_type=password&username=' + username + '&password=' + password + '&cnpjEmpresa=' + cnpjEmpresa
                }
            );

        }
    }
}