import * as React from 'react';
import { connect } from 'react-redux';
import HeaderPage from '../../components/commons/HeaderPage';
import DashboardAdministradorTab from '../../components/dashboard/administrador/DashboardAdministradorTab';

class Dashboard extends React.Component {
    render() {
        return <div className='page'>
            <HeaderPage path=' Dashboard' name='Administrador' />
            <DashboardAdministradorTab />
        </div>;
    }
}

function mapStateToProps(state) {
    return { auth: state.login }
}


export default connect(mapStateToProps, null, null, { pure: false })(Dashboard);