import * as React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actionCreators } from '../../store/crm/CrmConsulta';
import HeaderPage from '../../components/commons/HeaderPage';
import DetalhesFrom from '../../components/crm/consulta/DetalhesFrom';

class Crm_Detalhes extends React.Component {
    constructor(props) {
        super(props);
    }

    async componentWillMount() {
        this.props.detalhes.idOportunidade = 0;
        await this.props.fnUpdateState(this.props);
    }

    async componentDidMount() {
        this.props.detalhes.idOportunidade = this.props.match.params.id;
        await this.props.fnUpdateState(this.props);
    }

    render() {
        return <div className='page'>
            <HeaderPage
                path=' CRM'
                name='Detalhes da Oportunidade' />
            {this.props.detalhes.idOportunidade == 0 ? null : <DetalhesFrom />}
        </div>;
    }
}

export default connect(
    state => state.crm_consulta,
    dispatch => bindActionCreators(actionCreators, dispatch),
    null, {}
)(Crm_Detalhes);