import * as React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Row } from 'react-bootstrap';
import { actionCreators } from '../../../store/dashboard/DashboardAdministrador';
import QtdeVeiculosCompradosSemestre from './QtdeVeiculosCompradosSemestre';
import QtdeVeiculosCompradosPorCompradorTrimestre from './QtdeVeiculosCompradosPorCompradorTrimestre';
import DezModelosMaisCompradosTrimestre from './DezModelosMaisCompradosTrimestre';
import MediaDiasEstoqueDezModelosMaisComprados from './MediaDiasEstoqueDezModelosMaisComprados';

class DashboardAdministradorComprasForm extends React.Component {
    render() {
        return (
            this.props.dashboardadministrador.empresaSelected > 0
                ?
                <Row>
                    <div className="col-md-6">
                        <QtdeVeiculosCompradosSemestre
                            authorizationToken={this.props.auth.authorizationToken}
                            empresaSelected={this.props.dashboardadministrador.empresaSelected}
                        />
                    </div>
                    <div className="col-md-6">
                        <QtdeVeiculosCompradosPorCompradorTrimestre
                            authorizationToken={this.props.auth.authorizationToken}
                            empresaSelected={this.props.dashboardadministrador.empresaSelected}
                        />
                    </div>
                    <div className="col-md-6">
                        <DezModelosMaisCompradosTrimestre
                            authorizationToken={this.props.auth.authorizationToken}
                            empresaSelected={this.props.dashboardadministrador.empresaSelected}
                        />
                    </div>
                    <div className="col-md-6">
                        <MediaDiasEstoqueDezModelosMaisComprados
                            authorizationToken={this.props.auth.authorizationToken}
                            empresaSelected={this.props.dashboardadministrador.empresaSelected}
                        />
                    </div>
                </Row>
                : null
        );
    }
}

function mapStateToProps(state) {
    return {
        auth: state.auth,
        dashboardadministrador: state.dashboardadministrador
    }
}

export default connect(
    mapStateToProps,
    dispatch => bindActionCreators(actionCreators, dispatch),
    null,
    { pure: false }
)(DashboardAdministradorComprasForm);