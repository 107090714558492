import { fetch, addTask } from 'domain-task';
import * as publicador_service from '../../../services/marketing/publicador/publicador_service';

const UPDATE_STATE_MARKETING_PUBLICADOR_ACTION = 'UPDATE_STATE_MARKETING_PUBLICADOR_ACTION';

const REQUEST_LISTA_VEICULOS_ACTION = 'REQUEST_LISTA_VEICULOS_ACTION';
const RESPONSE_LISTA_VEICULOS_ACTION = 'RESPONSE_LISTA_VEICULOS_ACTION';
const FAILURE_LISTA_VEICULOS_ACTION = 'FAILURE_LISTA_VEICULOS_ACTION';

const REQUEST_PORTAIS_VEICULO_ACTION = 'REQUEST_PORTAIS_VEICULO_ACTION';
const RESPONSE_PORTAIS_VEICULO_ACTION = 'RESPONSE_PORTAIS_VEICULO_ACTION';
const FAILURE_PORTAIS_VEICULO_ACTION = 'FAILURE_PORTAIS_VEICULO_ACTION';

const REQUEST_PORTAIS_ATIVOS_ACTION = 'REQUEST_PORTAIS_ATIVOS_ACTION';
const RESPONSE_PORTAIS_ATIVOS_ACTION = 'RESPONSE_PORTAIS_ATIVOS_ACTION';
const FAILURE_PORTAIS_ATIVOS_ACTION = 'FAILURE_PORTAIS_ATIVOS_ACTION';

const REQUEST_PATIOS_ACTION = 'REQUEST_PATIOS_ACTION';
const RESPONSE_PATIOS_ACTION = 'RESPONSE_PATIOS_ACTION';
const FAILURE_PATIOS_ACTION = 'FAILURE_PATIOS_ACTION';

const REQUEST_BUSCAR_VEICULO = 'REQUEST_BUSCAR_VEICULO';
const RESPONSE_BUSCAR_VEICULO = 'RESPONSE_BUSCAR_VEICULO';
const FAILURE_BUSCAR_VEICULO = 'FAILURE_BUSCAR_VEICULO';

const REQUEST_BUSCAR_ANUNCIO = 'REQUEST_BUSCAR_ANUNCIO';
const RESPONSE_BUSCAR_ANUNCIO = 'RESPONSE_BUSCAR_ANUNCIO';
const FAILURE_BUSCAR_ANUNCIO = 'FAILURE_BUSCAR_ANUNCIO';

const REQUEST_BUSCAR_ANUNCIOMOTO = 'REQUEST_BUSCAR_ANUNCIOMOTO';
const RESPONSE_BUSCAR_ANUNCIOMOTO = 'RESPONSE_BUSCAR_ANUNCIOMOTO';
const FAILURE_BUSCAR_ANUNCIOMOTO = 'FAILURE_BUSCAR_ANUNCIOMOTO';

const REQUEST_ANOSMODELO_PORTAL_ACTION = 'REQUEST_ANOSMODELO_PORTAL_ACTION';
const RESPONSE_ANOSMODELO_PORTAL_ACTION = 'RESPONSE_ANOSMODELO_PORTAL_ACTION';
const FAILURE_ANOSMODELO_PORTAL_ACTION = 'FAILURE_ANOSMODELO_PORTAL_ACTION';

const REQUEST_VERSOES_PORTAL_ACTION = 'REQUEST_VERSOES_PORTAL_ACTION';
const RESPONSE_VERSOES_PORTAL_ACTION = 'RESPONSE_VERSOES_PORTAL_ACTION';
const FAILURE_VERSOES_PORTAL_ACTION = 'FAILURE_VERSOES_PORTAL_ACTION';

const REQUEST_MODELOS_PORTAL_ACTION = 'REQUEST_MODELOS_PORTAL_ACTION';
const RESPONSE_MODELOS_PORTAL_ACTION = 'RESPONSE_MODELOS_PORTAL_ACTION';
const FAILURE_MODELOS_PORTAL_ACTION = 'FAILURE_MODELOS_PORTAL_ACTION';

const REQUEST_CATEGORIAS_PORTAL_ACTION = 'REQUEST_CATEGORIAS_PORTAL_ACTION';
const RESPONSE_CATEGORIAS_PORTAL_ACTION = 'RESPONSE_CATEGORIAS_PORTAL_ACTION';
const FAILURE_CATEGORIAS_PORTAL_ACTION = 'FAILURE_CATEGORIAS_PORTAL_ACTION';

const REQUEST_PLANOS_PORTAL_ACTION = 'REQUEST_PLANOS_PORTAL_ACTION';
const RESPONSE_PLANOS_PORTAL_ACTION = 'RESPONSE_PLANOS_PORTAL_ACTION';
const FAILURE_PLANOS_PORTAL_ACTION = 'FAILURE_PLANOS_PORTAL_ACTION';

const REQUEST_ADICIONAIS_PORTAL_ACTION = 'REQUEST_ADICIONAIS_PORTAL_ACTION';
const RESPONSE_ADICIONAIS_PORTAL_ACTION = 'RESPONSE_ADICIONAIS_PORTAL_ACTION';
const FAILURE_ADICIONAIS_PORTAL_ACTION = 'FAILURE_ADICIONAIS_PORTAL_ACTION';

const POST_PUBLICACAO_ACTION = 'POST_PUBLICACAO_ACTION';
const PUT_PUBLICACAO_ACTION = 'PUT_PUBLICACAO_ACTION';
const DELETE_PUBLICACAO_ACTION = 'DELETE_PUBLICACAO_ACTION';
const SUCCESS_DELETE_PUBLICACAO_ACTION = 'SUCCESS_DELETE_PUBLICACAO_ACTION';
const SUCCESS_PUT_PUBLICACAO_ACTION = 'SUCCESS_PUT_PUBLICACAO_ACTION';
const SUCCESS_POST_PUBLICACAO_ACTION = 'SUCCESS_POST_PUBLICACAO_ACTION';
const FAILURE_PUBLICACAO_ACTION = 'FAILURE_PUBLICACAO_ACTION';

const POST_PUBLICACAOMOTO_ACTION = 'POST_PUBLICACAOMOTO_ACTION';
const SUCCESS_POST_PUBLICACAOMOTO_ACTION = 'SUCCESS_POST_PUBLICACAOMOTO_ACTION';
const FAILURE_PUBLICACAOMOTO_ACTION = 'FAILURE_PUBLICACAOMOTO_ACTION';
const PUT_PUBLICACAOMOTO_ACTION = 'PUT_PUBLICACAOMOTO_ACTION';
const SUCCESS_PUT_PUBLICACAOMOTO_ACTION = 'SUCCESS_PUT_PUBLICACAOMOTO_ACTION';
const DELETE_PUBLICACAOMOTO_ACTION = 'DELETE_PUBLICACAOMOTO_ACTION';
const SUCCESS_DELETE_PUBLICACAOMOTO_ACTION = 'SUCCESS_DELETE_PUBLICACAOMOTO_ACTION';

const REQUEST_REFRIGERACAO_PORTAL_ACTION = 'REQUEST_REFRIGERACAO_PORTAL_ACTION';
const RESPONSE_REFRIGERACAO_PORTAL_ACTION = 'RESPONSE_REFRIGERACAO_PORTAL_ACTION';
const FAILURE_REFRIGERACAO_PORTAL_ACTION = 'FAILURE_REFRIGERACAO_PORTAL_ACTION';

const REQUEST_MOTOR_PORTAL_ACTION = 'REQUEST_MOTOR_PORTAL_ACTION';
const RESPONSE_MOTOR_PORTAL_ACTION = 'RESPONSE_MOTOR_PORTAL_ACTION';
const FAILURE_MOTOR_PORTAL_ACTION = 'FAILURE_MOTOR_PORTAL_ACTION';

const REQUEST_PARTIDA_PORTAL_ACTION = 'REQUEST_PARTIDA_PORTAL_ACTION';
const RESPONSE_PARTIDA_PORTAL_ACTION = 'RESPONSE_PARTIDA_PORTAL_ACTION';
const FAILURE_PARTIDA_PORTAL_ACTION = 'FAILURE_PARTIDA_PORTAL_ACTION';

const REQUEST_ALIMENTACAO_PORTAL_ACTION = 'REQUEST_ALIMENTACAO_PORTAL_ACTION';
const RESPONSE_ALIMENTACAO_PORTAL_ACTION = 'RESPONSE_ALIMENTACAO_PORTAL_ACTION';
const FAILURE_ALIMENTACAO_PORTAL_ACTION = 'FAILURE_ALIMENTACAO_PORTAL_ACTION';

const REQUEST_MARCHA_PORTAL_ACTION = 'REQUEST_MARCHA_PORTAL_ACTION';
const RESPONSE_MARCHA_PORTAL_ACTION = 'RESPONSE_MARCHA_PORTAL_ACTION';
const FAILURE_MARCHA_PORTAL_ACTION = 'FAILURE_MARCHA_PORTAL_ACTION';

const REQUEST_FREIO_PORTAL_ACTION = 'REQUEST_FREIO_PORTAL_ACTION';
const RESPONSE_FREIO_PORTAL_ACTION = 'RESPONSE_FREIO_PORTAL_ACTION';
const FAILURE_FREIO_PORTAL_ACTION = 'FAILURE_FREIO_PORTAL_ACTION';

const REQUEST_BLINDAGEM_PORTAL_ACTION = 'REQUEST_BLINDAGEM_PORTAL_ACTION';
const RESPONSE_BLINDAGEM_PORTAL_ACTION = 'RESPONSE_BLINDAGEM_PORTAL_ACTION';
const FAILURE_BLINDAGEM_PORTAL_ACTION = 'FAILURE_BLINDAGEM_PORTAL_ACTION';

const REQUEST_CILINDRADAS_PORTAL_ACTION = 'REQUEST_CILINDRADAS_PORTAL_ACTION';
const RESPONSE_CILINDRADAS_PORTAL_ACTION = 'RESPONSE_CILINDRADAS_PORTAL_ACTION';
const FAILURE_CILINDRADAS_PORTAL_ACTION = 'FAILURE_CILINDRADAS_PORTAL_ACTION';

const REQUEST_RECOMENDACOES_PORTAL_ACTION = 'REQUEST_RECOMENDACOES_PORTAL_ACTION';
const RESPONSE_RECOMENDACOES_PORTAL_ACTION = 'RESPONSE_RECOMENDACOES_PORTAL_ACTION';
const FAILURE_RECOMENDACOES_PORTAL_ACTION = 'FAILURE_RECOMENDACOES_PORTAL_ACTION';


const VISUALIZAR_RECOMENDACAO_ACTION = 'VISUALIZAR_RECOMENDACAO_ACTION';
const SUCCESS_VISUALIZAR_RECOMENDACAO_ACTION = 'SUCCESS_VISUALIZAR_RECOMENDACAO_ACTION';
const FAILURE_VISUALIZAR_RECOMENDACAO_ACTION = 'FAILURE_VISUALIZAR_RECOMENDACAO_ACTION';

const REQUEST_EXTENSAO_PORTAL_ACTION = 'REQUEST_EXTENSAO_PORTAL_ACTION';
const RESPONSE_EXTENSAO_PORTAL_ACTION = 'RESPONSE_EXTENSAO_PORTAL_ACTION';
const FAILURE_EXTENSAO_PORTAL_ACTION = 'FAILURE_EXTENSAO_PORTAL_ACTION';

const initialState = {
    ModalWaitLoading: {
        show: false
    },
    ListaVeiculos: {
        errorText: '',
        isLoading: false,
        token: '',
        requested: false,
        filters: {
            Ascending: true,
            OrderBy: '1',
            PageNumber: 1,
            PageSize: 10,
            TotalNumberOfPages: 0,
            TotalNumberOfRecords: 0,
            FiltroGeral: "",
            AnoFabricacao: null,
            AnoModelo: null,
            idPatio: null,
            idPortal: null,
            Fotos: 0,
            Todos: false,
            NaoPublicados: false,
            Blindados: false,
            idCategoria: 0,
        },
        results: [],
        dropdownPatios: {
            errorText: '',
            isLoading: false,
            results: []
        },
        dropdownPortaisAtivos: {
            errorText: '',
            isLoading: false,
            results: []
        }
    },
    PublicarVeiculo: {
        anuncio: {
            anuncioEmDestaque: false,
            idAnosModelo: "",
            idAnuncio: "",
            idAnuncioCliente: "",
            idAnuncioBNDV: 0,
            idCategoria: "",
            idCidade: "",
            idPublicador: 0,
            idTipoPlano: "",
            idTipoAdicional: "",
            idTipoVeiculo: "",
            idVeiculo: 0,
            idVersao: "",
            preco: 0,
            precoDe: 0,
            texto: "",
            titulo: "",
            video: "",
            cilindradas: 0,
            idCilindradas: "",            
            idRefrigeracao: "",
            idAlimentacao: "",
            idMotor: "",
            idPartida: "",
            idFreio: "",
            idTipoAnuncio: 0,
            idMarcha: "",
            idBlindagem: "",
            marcaMotor: "", 
            qualidadeAnuncioStatus: 0,
            anuncioExtensaoJs: null,

        },
        veiculoBNDV: {
            anoFabricacao: 0,
            anoModelo: 0,
            chassi: '',
            descCambio: '',
            descCombustivel: '',
            descMarca: '',
            descModelo: '',
            descPortas: '',
            descVersao: '',
            cor: '',
            id: 0,
            observacoes: '',
            placa: '',
            TotalNumberOfPages: 0,
            TotalNumberOfRecords: 0,
            valorWeb: 0,
            veiculo: '',
            opcionais: '',
            vendido: false,
            km: 0,
            portais: [],
            idCategoria: 0
        },
        dadosPortal: {
            anosmodelo: { errorText: '', Id: 0, isLoading: false, Nome: '', results: [] },
            versoes: { errorText: '', Id: 0, isLoading: false, Nome: '', results: [] },
            planos: { errorText: '', Id: 0, isLoading: false, Nome: '', results: [] },
            adicionais: { errorText: '', Id: 0, isLoading: false, Nome: '', results: [] },
            categorias: { errorText: '', Id: 0, isLoading: false, Nome: '', results: [] },
            modelos: { errorText: '', Id: 0, isLoading: false, Nome: '', results: [] },
            refrigeracao: { errorText: '', Id: 0, isLoading: false, Nome: '', results: [] },
            alimentacao: { errorText: '', Id: 0, isLoading: false, Nome: '', results: [] },
            motor: { errorText: '', Id: 0, isLoading: false, Nome: '', results: [] },
            partida: { errorText: '', Id: 0, isLoading: false, Nome: '', results: [] },
            marcha: { errorText: '', Id: 0, isLoading: false, Nome: '', results: [] },
            freio: { errorText: '', Id: 0, isLoading: false, Nome: '', results: [] },
            blindagem: { errorText: '', Id: 0, isLoading: false, Nome: '', results: [] },
            cilindradas: { errorText: '', Id: 0, isLoading: false, Nome: '', results: [] },
            recomendacoes: { errorText: '', Id: 0, isLoading: false, Nome: '', results: [] }
        },
        errorText: '',
        successText: '',
        isLoading: false,
        IdAnunciante: 0,
        IdPublicador: 0,
        idVeiculo: 0,
        
    },
    ModalOLX: {
        errorText: '',
        isLoading: false,
        showModal: false
    },
    ModalOLXMoto: {
        errorText: '',
        isLoading: false,
        showModal: false
    },
    ModalAutoLine: {
        errorText: '',
        isLoading: false,
        showModal: false
    },
    ModalAutoLineMoto: {
        errorText: '',
        isLoading: false,
        showModal: false
    },
    ModalMeuCarroNovo: {
        errorText: '',
        isLoading: false,
        showModal: false
    },
    ModalICarros: {
        errorText: '',
        isLoading: false,
        showModal: false
    },
    ModalICarrosMoto: {
        errorText: '',
        isLoading: false,
        showModal: false
    },
    ModalWebMotors: {
        errorText: '',
        isLoading: false,
        showModal: false
    },
    ModalWebMotorsMoto: {
        errorText: '',
        isLoading: false,
        showModal: false
    },
    ModalMercadoLivre: {
        errorText: '',
        isLoading: false,
        showModal: false
    },
    ModalMercadoLivreMoto: {
        errorText: '',
        isLoading: false,
        showModal: false
    },
    ModalCarroSaoJose: {
        errorText: '',
        isLoading: false,
        showModal: false
    },
    ModalCarroSaoJoseMoto: {
        errorText: '',
        isLoading: false,
        showModal: false
    },
    ModalTemUsados: {
        errorText: '',
        isLoading: false,
        showModal: false
    },
    ModalNewMotors: {
        errorText: '',
        isLoading: false,
        showModal: false
    },
    ModalCompreCar: {
        errorText: '',
        isLoading: false,
        showModal: false
    },
    ModalCarroBrasil: {
        errorText: '',
        isLoading: false,
        showModal: false
    },
    ModalCarroBrasilMoto: {
        errorText: '',
        isLoading: false,
        showModal: false
    },
    ModalDefaultFeed: {
        errorText: '',
        showModal: false,
        titulo: '',
        texto: ''
    },
    ModalWebSiteBNDV: {
        errorText: '',
        showModal: false
    },
    ModalWebSiteBNDVMoto: {
        errorText: '',
        showModal: false
    },
    ModalFacebook: {
        errorText: '',
        isLoading: false,
        showModal: false
    },
    ModalFacebookMoto: {
        errorText: '',
        isLoading: false,
        showModal: false
    },
    ModalMobiAuto: {
        errorText: '',
        isLoading: false,
        showModal: false
    },
    ModalCompreBlindados: {
        errorText: '',
        isLoading: false,
        showModal: false
    },
     ModalUsadosBR: {
        errorText: '',
        isLoading: false,
        showModal: false
    },
    ModalUsadosBRMoto: {
        errorText: '',
        isLoading: false,
        showModal: false
    },
    ModalRecomendacaoMercadoLivre: {
        errorText: '',
        isLoading: false,
        showModal: false
    }
};

export const actionCreators = {
    fnUpdateState: (pMarketingPublicadorState) => async (dispatch, getState) => {
        dispatch({
            type: 'UPDATE_STATE_MARKETING_PUBLICADOR_ACTION',
            ModalWaitLoading: {
                show: pMarketingPublicadorState.ModalWaitLoading.show
            },
            ListaVeiculos: {
                errorText: pMarketingPublicadorState.ListaVeiculos.errorText,
                isLoading: pMarketingPublicadorState.ListaVeiculos.isLoading,
                token: pMarketingPublicadorState.ListaVeiculos.token,
                requested: pMarketingPublicadorState.ListaVeiculos.requested,
                filters: {
                    Ascending: pMarketingPublicadorState.ListaVeiculos.filters.Ascending,
                    OrderBy: pMarketingPublicadorState.ListaVeiculos.filters.OrderBy,
                    PageNumber: pMarketingPublicadorState.ListaVeiculos.filters.PageNumber,
                    PageSize: pMarketingPublicadorState.ListaVeiculos.filters.PageSize,
                    TotalNumberOfPages: pMarketingPublicadorState.ListaVeiculos.filters.TotalNumberOfPages,
                    TotalNumberOfRecords: pMarketingPublicadorState.ListaVeiculos.filters.TotalNumberOfRecords,
                    FiltroGeral: pMarketingPublicadorState.ListaVeiculos.filters.FiltroGeral,
                    AnoFabricacao: pMarketingPublicadorState.ListaVeiculos.filters.AnoFabricacao,
                    AnoModelo: pMarketingPublicadorState.ListaVeiculos.filters.AnoModelo,
                    idPatio: pMarketingPublicadorState.ListaVeiculos.filters.idPatio,
                    idPortal: pMarketingPublicadorState.ListaVeiculos.filters.idPortal,
                    Fotos: pMarketingPublicadorState.ListaVeiculos.filters.Fotos,
                    Todos: pMarketingPublicadorState.ListaVeiculos.filters.Todos,
                    NaoPublicados: pMarketingPublicadorState.ListaVeiculos.filters.NaoPublicados,
                    Blindados: pMarketingPublicadorState.ListaVeiculos.filters.Blindados,
                    idCategoria: pMarketingPublicadorState.ListaVeiculos.filters.idCategoria,
                },
                results: pMarketingPublicadorState.ListaVeiculos.results,
                dropdownPatios: {
                    errorText: pMarketingPublicadorState.ListaVeiculos.dropdownPatios.errorText,
                    isLoading: pMarketingPublicadorState.ListaVeiculos.dropdownPatios.isLoading,
                    results: pMarketingPublicadorState.ListaVeiculos.dropdownPatios.results
                },
                dropdownPortaisAtivos: {
                    errorText: pMarketingPublicadorState.ListaVeiculos.dropdownPortaisAtivos.errorText,
                    isLoading: pMarketingPublicadorState.ListaVeiculos.dropdownPortaisAtivos.isLoading,
                    results: pMarketingPublicadorState.ListaVeiculos.dropdownPortaisAtivos.results
                }
            },
            PublicarVeiculo: {
                anuncio: {
                    anuncioEmDestaque: pMarketingPublicadorState.PublicarVeiculo.anuncio.anuncioEmDestaque,
                    idAnosModelo: pMarketingPublicadorState.PublicarVeiculo.anuncio.idAnosModelo,
                    idAnuncio: pMarketingPublicadorState.PublicarVeiculo.anuncio.idAnuncio,
                    idAnuncioCliente: pMarketingPublicadorState.PublicarVeiculo.anuncio.idAnuncioCliente,
                    idAnuncioBNDV: pMarketingPublicadorState.PublicarVeiculo.anuncio.idAnuncioBNDV,
                    idCategoria: pMarketingPublicadorState.PublicarVeiculo.anuncio.idCategoria,
                    idCidade: pMarketingPublicadorState.PublicarVeiculo.anuncio.idCidade,
                    idPublicador: pMarketingPublicadorState.PublicarVeiculo.anuncio.idPublicador,
                    idTipoPlano: pMarketingPublicadorState.PublicarVeiculo.anuncio.idTipoPlano,
                    idTipoAdicional: pMarketingPublicadorState.PublicarVeiculo.anuncio.idTipoAdicional,
                    idTipoVeiculo: pMarketingPublicadorState.PublicarVeiculo.anuncio.idTipoVeiculo,
                    idVeiculo: pMarketingPublicadorState.PublicarVeiculo.anuncio.idVeiculo,
                    idVersao: pMarketingPublicadorState.PublicarVeiculo.anuncio.idVersao,
                    preco: pMarketingPublicadorState.PublicarVeiculo.anuncio.preco,
                    precoDe: pMarketingPublicadorState.PublicarVeiculo.anuncio.precoDe,
                    texto: pMarketingPublicadorState.PublicarVeiculo.anuncio.texto,
                    titulo: pMarketingPublicadorState.PublicarVeiculo.anuncio.titulo,
                    video: pMarketingPublicadorState.PublicarVeiculo.anuncio.video,
                    cilindradas: pMarketingPublicadorState.PublicarVeiculo.anuncio.cilindradas,
                    idCilindradas: pMarketingPublicadorState.PublicarVeiculo.anuncio.idCilindradas,                  
                    idRefrigeracao: pMarketingPublicadorState.PublicarVeiculo.anuncio.idRefrigeracao,
                    idAlimentacao: pMarketingPublicadorState.PublicarVeiculo.anuncio.idAlimentacao,
                    idMotor: pMarketingPublicadorState.PublicarVeiculo.anuncio.idMotor,
                    idPartida: pMarketingPublicadorState.PublicarVeiculo.anuncio.idPartida,
                    idFreio: pMarketingPublicadorState.PublicarVeiculo.anuncio.idFreio,
                    idTipoAnuncio: pMarketingPublicadorState.PublicarVeiculo.anuncio.idTipoAnuncio,
                    idMarcha: pMarketingPublicadorState.PublicarVeiculo.anuncio.idMarcha,
                    idBlindagem: pMarketingPublicadorState.PublicarVeiculo.anuncio.idBlindagem,
                    marcaMotor: pMarketingPublicadorState.PublicarVeiculo.anuncio.marcaMotor,   
                    qualidadeAnuncioStatus: pMarketingPublicadorState.PublicarVeiculo.anuncio.qualidadeAnuncioStatus,
                    anuncioExtensaoJs: pMarketingPublicadorState.PublicarVeiculo.anuncio.anuncioExtensaoJs,
                },
                veiculoBNDV: {
                    anoFabricacao: pMarketingPublicadorState.PublicarVeiculo.veiculoBNDV.anoFabricacao,
                    anoModelo: pMarketingPublicadorState.PublicarVeiculo.veiculoBNDV.anoModelo,
                    chassi: pMarketingPublicadorState.PublicarVeiculo.veiculoBNDV.chassi,
                    descCambio: pMarketingPublicadorState.PublicarVeiculo.veiculoBNDV.descCambio,
                    descCombustivel: pMarketingPublicadorState.PublicarVeiculo.veiculoBNDV.descCombustivel,
                    descMarca: pMarketingPublicadorState.PublicarVeiculo.veiculoBNDV.descMarca,
                    descModelo: pMarketingPublicadorState.PublicarVeiculo.veiculoBNDV.descModelo,
                    descPortas: pMarketingPublicadorState.PublicarVeiculo.veiculoBNDV.descPortas,
                    descVersao: pMarketingPublicadorState.PublicarVeiculo.veiculoBNDV.descVersao,
                    cor: pMarketingPublicadorState.PublicarVeiculo.veiculoBNDV.cor,
                    id: pMarketingPublicadorState.PublicarVeiculo.veiculoBNDV.id,
                    observacoes: pMarketingPublicadorState.PublicarVeiculo.veiculoBNDV.observacoes,
                    placa: pMarketingPublicadorState.PublicarVeiculo.veiculoBNDV.placa,
                    TotalNumberOfPages: pMarketingPublicadorState.PublicarVeiculo.veiculoBNDV.TotalNumberOfPages,
                    TotalNumberOfRecords: pMarketingPublicadorState.PublicarVeiculo.veiculoBNDV.TotalNumberOfRecords,
                    valorWeb: pMarketingPublicadorState.PublicarVeiculo.veiculoBNDV.valorWeb,
                    veiculo: pMarketingPublicadorState.PublicarVeiculo.veiculoBNDV.veiculo,
                    opcionais: pMarketingPublicadorState.PublicarVeiculo.veiculoBNDV.opcionais,
                    vendido: pMarketingPublicadorState.PublicarVeiculo.veiculoBNDV.vendido,
                    km: pMarketingPublicadorState.PublicarVeiculo.veiculoBNDV.km,
                    portais: pMarketingPublicadorState.PublicarVeiculo.veiculoBNDV.portais
                },
                dadosPortal: {
                    anosmodelo: {
                        errorText: pMarketingPublicadorState.PublicarVeiculo.dadosPortal.anosmodelo.errorText,
                        Id: pMarketingPublicadorState.PublicarVeiculo.dadosPortal.anosmodelo.Id,
                        isLoading: pMarketingPublicadorState.PublicarVeiculo.dadosPortal.anosmodelo.isLoading,
                        Nome: pMarketingPublicadorState.PublicarVeiculo.dadosPortal.anosmodelo.Nome,
                        results: pMarketingPublicadorState.PublicarVeiculo.dadosPortal.anosmodelo.results
                    },
                    modelos: {
                        errorText: pMarketingPublicadorState.PublicarVeiculo.dadosPortal.modelos.errorText,
                        Id: pMarketingPublicadorState.PublicarVeiculo.dadosPortal.modelos.Id,
                        isLoading: pMarketingPublicadorState.PublicarVeiculo.dadosPortal.modelos.isLoading,
                        Nome: pMarketingPublicadorState.PublicarVeiculo.dadosPortal.modelos.Nome,
                        results: pMarketingPublicadorState.PublicarVeiculo.dadosPortal.modelos.results
                    },
                    versoes: {
                        errorText: pMarketingPublicadorState.PublicarVeiculo.dadosPortal.versoes.errorText,
                        Id: pMarketingPublicadorState.PublicarVeiculo.dadosPortal.versoes.Id,
                        isLoading: pMarketingPublicadorState.PublicarVeiculo.dadosPortal.versoes.isLoading,
                        Nome: pMarketingPublicadorState.PublicarVeiculo.dadosPortal.versoes.Nome,
                        results: pMarketingPublicadorState.PublicarVeiculo.dadosPortal.versoes.results
                    },
                    planos: {
                        errorText: pMarketingPublicadorState.PublicarVeiculo.dadosPortal.planos.errorText,
                        Id: pMarketingPublicadorState.PublicarVeiculo.dadosPortal.planos.Id,
                        isLoading: pMarketingPublicadorState.PublicarVeiculo.dadosPortal.planos.isLoading,
                        Nome: pMarketingPublicadorState.PublicarVeiculo.dadosPortal.planos.Nome,
                        results: pMarketingPublicadorState.PublicarVeiculo.dadosPortal.planos.results
                    },
                    adicionais: {
                        errorText: pMarketingPublicadorState.PublicarVeiculo.dadosPortal.adicionais.errorText,
                        Id: pMarketingPublicadorState.PublicarVeiculo.dadosPortal.adicionais.Id,
                        isLoading: pMarketingPublicadorState.PublicarVeiculo.dadosPortal.adicionais.isLoading,
                        Nome: pMarketingPublicadorState.PublicarVeiculo.dadosPortal.adicionais.Nome,
                        results: pMarketingPublicadorState.PublicarVeiculo.dadosPortal.adicionais.results
                    },
                    categorias: {
                        errorText: pMarketingPublicadorState.PublicarVeiculo.dadosPortal.categorias.errorText,
                        Id: pMarketingPublicadorState.PublicarVeiculo.dadosPortal.categorias.Id,
                        isLoading: pMarketingPublicadorState.PublicarVeiculo.dadosPortal.categorias.isLoading,
                        Nome: pMarketingPublicadorState.PublicarVeiculo.dadosPortal.categorias.Nome,
                        results: pMarketingPublicadorState.PublicarVeiculo.dadosPortal.categorias.results
                    },
                    refrigeracao: {
                        errorText: pMarketingPublicadorState.PublicarVeiculo.dadosPortal.refrigeracao.errorText,
                        Id: pMarketingPublicadorState.PublicarVeiculo.dadosPortal.refrigeracao.Id,
                        isLoading: pMarketingPublicadorState.PublicarVeiculo.dadosPortal.refrigeracao.isLoading,
                        Nome: pMarketingPublicadorState.PublicarVeiculo.dadosPortal.refrigeracao.Nome,
                        results: pMarketingPublicadorState.PublicarVeiculo.dadosPortal.refrigeracao.results
                    },
                    alimentacao: {
                        errorText: pMarketingPublicadorState.PublicarVeiculo.dadosPortal.alimentacao.errorText,
                        Id: pMarketingPublicadorState.PublicarVeiculo.dadosPortal.alimentacao.Id,
                        isLoading: pMarketingPublicadorState.PublicarVeiculo.dadosPortal.alimentacao.isLoading,
                        Nome: pMarketingPublicadorState.PublicarVeiculo.dadosPortal.alimentacao.Nome,
                        results: pMarketingPublicadorState.PublicarVeiculo.dadosPortal.alimentacao.results
                    },
                    motor: {
                        errorText: pMarketingPublicadorState.PublicarVeiculo.dadosPortal.motor.errorText,
                        Id: pMarketingPublicadorState.PublicarVeiculo.dadosPortal.motor.Id,
                        isLoading: pMarketingPublicadorState.PublicarVeiculo.dadosPortal.motor.isLoading,
                        Nome: pMarketingPublicadorState.PublicarVeiculo.dadosPortal.motor.Nome,
                        results: pMarketingPublicadorState.PublicarVeiculo.dadosPortal.motor.results
                    },
                    partida: {
                        errorText: pMarketingPublicadorState.PublicarVeiculo.dadosPortal.partida.errorText,
                        Id: pMarketingPublicadorState.PublicarVeiculo.dadosPortal.partida.Id,
                        isLoading: pMarketingPublicadorState.PublicarVeiculo.dadosPortal.partida.isLoading,
                        Nome: pMarketingPublicadorState.PublicarVeiculo.dadosPortal.partida.Nome,
                        results: pMarketingPublicadorState.PublicarVeiculo.dadosPortal.partida.results
                    },
                    marcha: {
                        errorText: pMarketingPublicadorState.PublicarVeiculo.dadosPortal.marcha.errorText,
                        Id: pMarketingPublicadorState.PublicarVeiculo.dadosPortal.marcha.Id,
                        isLoading: pMarketingPublicadorState.PublicarVeiculo.dadosPortal.marcha.isLoading,
                        Nome: pMarketingPublicadorState.PublicarVeiculo.dadosPortal.marcha.Nome,
                        results: pMarketingPublicadorState.PublicarVeiculo.dadosPortal.marcha.results
                    },
                    freio: {
                        errorText: pMarketingPublicadorState.PublicarVeiculo.dadosPortal.freio.errorText,
                        Id: pMarketingPublicadorState.PublicarVeiculo.dadosPortal.freio.Id,
                        isLoading: pMarketingPublicadorState.PublicarVeiculo.dadosPortal.freio.isLoading,
                        Nome: pMarketingPublicadorState.PublicarVeiculo.dadosPortal.freio.Nome,
                        results: pMarketingPublicadorState.PublicarVeiculo.dadosPortal.freio.results
                    },
                    blindagem: {
                        errorText: pMarketingPublicadorState.PublicarVeiculo.dadosPortal.blindagem.errorText,
                        Id: pMarketingPublicadorState.PublicarVeiculo.dadosPortal.blindagem.Id,
                        isLoading: pMarketingPublicadorState.PublicarVeiculo.dadosPortal.blindagem.isLoading,
                        Nome: pMarketingPublicadorState.PublicarVeiculo.dadosPortal.blindagem.Nome,
                        results: pMarketingPublicadorState.PublicarVeiculo.dadosPortal.blindagem.results
                    },
                    cilindradas: {
                        errorText: pMarketingPublicadorState.PublicarVeiculo.dadosPortal.cilindradas.errorText,
                        Id: pMarketingPublicadorState.PublicarVeiculo.dadosPortal.cilindradas.Id,
                        isLoading: pMarketingPublicadorState.PublicarVeiculo.dadosPortal.cilindradas.isLoading,
                        Nome: pMarketingPublicadorState.PublicarVeiculo.dadosPortal.cilindradas.Nome,
                        results: pMarketingPublicadorState.PublicarVeiculo.dadosPortal.cilindradas.results
                    },
                    recomendacoes: {
                        errorText: pMarketingPublicadorState.PublicarVeiculo.dadosPortal.recomendacoes.errorText,
                        Id: pMarketingPublicadorState.PublicarVeiculo.dadosPortal.recomendacoes.Id,
                        isLoading: pMarketingPublicadorState.PublicarVeiculo.dadosPortal.recomendacoes.isLoading,
                        Nome: pMarketingPublicadorState.PublicarVeiculo.dadosPortal.recomendacoes.Nome,
                        results: pMarketingPublicadorState.PublicarVeiculo.dadosPortal.recomendacoes.results
                    },
                },
                errorText: pMarketingPublicadorState.PublicarVeiculo.errorText,
                successText: pMarketingPublicadorState.PublicarVeiculo.successText,
                isLoading: pMarketingPublicadorState.PublicarVeiculo.isLoading,
                IdAnunciante: pMarketingPublicadorState.PublicarVeiculo.IdAnunciante,
                IdPublicador: pMarketingPublicadorState.PublicarVeiculo.IdPublicador,
                idVeiculo: pMarketingPublicadorState.PublicarVeiculo.idVeiculo
            },
            ModalOLX: {
                errorText: pMarketingPublicadorState.ModalOLX.errorText,
                isLoading: pMarketingPublicadorState.ModalOLX.isLoading,
                showModal: pMarketingPublicadorState.ModalOLX.showModal
            },
            ModalOLXMoto: {
                errorText: pMarketingPublicadorState.ModalOLXMoto.errorText,
                isLoading: pMarketingPublicadorState.ModalOLXMoto.isLoading,
                showModal: pMarketingPublicadorState.ModalOLXMoto.showModal
            },
            ModalAutoLine: {
                errorText: pMarketingPublicadorState.ModalAutoLine.Error,
                isLoading: pMarketingPublicadorState.ModalAutoLine.isLoading,
                showModal: pMarketingPublicadorState.ModalAutoLine.showModal
            },
            ModalAutoLineMoto: {
                errorText: pMarketingPublicadorState.ModalAutoLineMoto.Error,
                isLoading: pMarketingPublicadorState.ModalAutoLineMoto.isLoading,
                showModal: pMarketingPublicadorState.ModalAutoLineMoto.showModal
            },
            ModalMeuCarroNovo: {
                errorText: pMarketingPublicadorState.ModalMeuCarroNovo.errorText,
                isLoading: pMarketingPublicadorState.ModalMeuCarroNovo.isLoading,
                showModal: pMarketingPublicadorState.ModalMeuCarroNovo.showModal
            },
            ModalICarros: {
                errorText: pMarketingPublicadorState.ModalICarros.errorText,
                isLoading: pMarketingPublicadorState.ModalICarros.isLoading,
                showModal: pMarketingPublicadorState.ModalICarros.showModal
            },
            ModalICarrosMoto: {
                errorText: pMarketingPublicadorState.ModalICarrosMoto.errorText,
                isLoading: pMarketingPublicadorState.ModalICarrosMoto.isLoading,
                showModal: pMarketingPublicadorState.ModalICarrosMoto.showModal
            },
            ModalWebMotors: {
                errorText: pMarketingPublicadorState.ModalWebMotors.errorText,
                isLoading: pMarketingPublicadorState.ModalWebMotors.isLoading,
                showModal: pMarketingPublicadorState.ModalWebMotors.showModal
            },
            ModalWebMotorsMoto: {
                errorText: pMarketingPublicadorState.ModalWebMotorsMoto.errorText,
                isLoading: pMarketingPublicadorState.ModalWebMotorsMoto.isLoading,
                showModal: pMarketingPublicadorState.ModalWebMotorsMoto.showModal
            },
            ModalMercadoLivre: {
                errorText: pMarketingPublicadorState.ModalMercadoLivre.errorText,
                isLoading: pMarketingPublicadorState.ModalMercadoLivre.isLoading,
                showModal: pMarketingPublicadorState.ModalMercadoLivre.showModal
            },
            ModalMercadoLivreMoto: {
                errorText: pMarketingPublicadorState.ModalMercadoLivreMoto.errorText,
                isLoading: pMarketingPublicadorState.ModalMercadoLivreMoto.isLoading,
                showModal: pMarketingPublicadorState.ModalMercadoLivreMoto.showModal
            },
            ModalCarroSaoJose: {
                errorText: pMarketingPublicadorState.ModalCarroSaoJose.errorText,
                isLoading: pMarketingPublicadorState.ModalCarroSaoJose.isLoading,
                showModal: pMarketingPublicadorState.ModalCarroSaoJose.showModal
            },
            ModalCarroSaoJoseMoto: {
                errorText: pMarketingPublicadorState.ModalCarroSaoJoseMoto.errorText,
                isLoading: pMarketingPublicadorState.ModalCarroSaoJoseMoto.isLoading,
                showModal: pMarketingPublicadorState.ModalCarroSaoJoseMoto.showModal
            },
            ModalTemUsados: {
                errorText: pMarketingPublicadorState.ModalTemUsados.errorText,
                isLoading: pMarketingPublicadorState.ModalTemUsados.isLoading,
                showModal: pMarketingPublicadorState.ModalTemUsados.showModal
            },
            ModalNewMotors: {
                errorText: pMarketingPublicadorState.ModalNewMotors.errorText,
                isLoading: pMarketingPublicadorState.ModalNewMotors.isLoading,
                showModal: pMarketingPublicadorState.ModalNewMotors.showModal
            },
            ModalCompreCar: {
                errorText: pMarketingPublicadorState.ModalCompreCar.errorText,
                isLoading: pMarketingPublicadorState.ModalCompreCar.isLoading,
                showModal: pMarketingPublicadorState.ModalCompreCar.showModal
            },
            ModalCarroBrasil: {
                errorText: pMarketingPublicadorState.ModalCarroBrasil.errorText,
                isLoading: pMarketingPublicadorState.ModalCarroBrasil.isLoading,
                showModal: pMarketingPublicadorState.ModalCarroBrasil.showModal
            },
            ModalCarroBrasilMoto: {
                errorText: pMarketingPublicadorState.ModalCarroBrasilMoto.errorText,
                isLoading: pMarketingPublicadorState.ModalCarroBrasilMoto.isLoading,
                showModal: pMarketingPublicadorState.ModalCarroBrasilMoto.showModal
            },
            ModalDefaultFeed: {
                errorText: pMarketingPublicadorState.ModalDefaultFeed.errorText,
                showModal: pMarketingPublicadorState.ModalDefaultFeed.showModal,
                titulo: pMarketingPublicadorState.ModalDefaultFeed.titulo,
                texto: pMarketingPublicadorState.ModalDefaultFeed.texto
            },
            ModalWebSiteBNDV: {
                errorText: pMarketingPublicadorState.ModalWebSiteBNDV.errorText,
                showModal: pMarketingPublicadorState.ModalWebSiteBNDV.showModal
            },
            ModalWebSiteBNDVMoto: {
                errorText: pMarketingPublicadorState.ModalWebSiteBNDVMoto.errorText,
                showModal: pMarketingPublicadorState.ModalWebSiteBNDVMoto.showModal
            },
            ModalFacebook: {
                errorText: pMarketingPublicadorState.ModalFacebook.errorText,
                isLoading: pMarketingPublicadorState.ModalFacebook.isLoading,
                showModal: pMarketingPublicadorState.ModalFacebook.showModal
            },
            ModalFacebookMoto: {
                errorText: pMarketingPublicadorState.ModalFacebookMoto.errorText,
                isLoading: pMarketingPublicadorState.ModalFacebookMoto.isLoading,
                showModal: pMarketingPublicadorState.ModalFacebookMoto.showModal
            },
            ModalMobiAuto: {
                errorText: pMarketingPublicadorState.ModalMobiAuto.errorText,
                isLoading: pMarketingPublicadorState.ModalMobiAuto.isLoading,
                showModal: pMarketingPublicadorState.ModalMobiAuto.showModal
            },
            ModalCompreBlindados: {
                errorText: pMarketingPublicadorState.ModalCompreBlindados.errorText,
                isLoading: pMarketingPublicadorState.ModalCompreBlindados.isLoading,
                showModal: pMarketingPublicadorState.ModalCompreBlindados.showModal
            },
            ModalUsadosBR: {
                errorText: pMarketingPublicadorState.ModalUsadosBR.errorText,
                isLoading: pMarketingPublicadorState.ModalUsadosBR.isLoading,
                showModal: pMarketingPublicadorState.ModalUsadosBR.showModal
            },
            ModalUsadosBRMoto: {
                errorText: pMarketingPublicadorState.ModalUsadosBRMoto.errorText,
                isLoading: pMarketingPublicadorState.ModalUsadosBRMoto.isLoading,
                showModal: pMarketingPublicadorState.ModalUsadosBRMoto.showModal
            },
            ModalRecomendacaoMercadoLivre: {
                errorText: pMarketingPublicadorState.ModalRecomendacaoMercadoLivre.errorText,
                isLoading: pMarketingPublicadorState.ModalRecomendacaoMercadoLivre.isLoading,
                showModal: pMarketingPublicadorState.ModalRecomendacaoMercadoLivre.showModal
            },
        });
    },
    fnListaVeiculos: () => async (dispatch, getState) => {
        dispatch({ type: 'REQUEST_LISTA_VEICULOS_ACTION' });

        fetch(publicador_service.services.listaVeiculosPublicador(getState().marketing_publicador.ListaVeiculos.filters, getState().auth.authorizationToken))
            .then(response => {
                if (response.status === 200 || response.status === 0) {
                    return response;
                }
                else {
                    return response.json().then(function (object) {
                        return Promise.reject(new Error(object));
                    })
                }
            })
            .then(data => {
                data.json().then(function (object) {
                    var listResult = [];
                    object.forEach(function (entry) {
                        listResult.push({
                            anoFabricacao: parseInt(entry.anoFabricacao),
                            anoModelo: parseInt(entry.anoModelo),
                            chassi: entry.chassi,
                            cor: entry.cor,
                            descCambio: entry.descCambio,
                            descCombustivel: entry.descCombustivel,
                            descMarca: entry.descMarca,
                            descModelo: entry.descModelo,
                            descPortas: entry.descPortas,
                            descVersao: entry.descVersao,
                            id: parseInt(entry.id),
                            km: parseInt(entry.km),
                            observacoes: entry.observacoes,
                            opcionais: entry.opcionais,
                            vendido: entry.vendido,
                            placa: entry.placa,
                            dataCadastro: entry.dataCadastro,
                            portais: [],
                            TotalNumberOfPages: parseInt(entry.TotalNumberOfPages),
                            TotalNumberOfRecords: parseInt(entry.TotalNumberOfRecords),
                            valorWeb: parseFloat(entry.valorWeb),
                            veiculo: entry.veiculo,
                            idCategoria: entry.idCategoria,
                            statusVeiculo: entry.statusVeiculo
                        });
                    });

                    dispatch({
                        type: 'RESPONSE_LISTA_VEICULOS_ACTION',
                        TotalNumberOfRecords: (object.length > 0 ? object[0].TotalNumberOfRecords : 0),
                        TotalNumberOfPages: (object.length > 0 ? object[0].TotalNumberOfPages : 0),
                        results: listResult,
                        token: getState().auth.authorizationToken
                    })
                });
            }).catch(function (err) {
                dispatch({ type: 'FAILURE_LISTA_VEICULOS_ACTION', errorText: err.toString() });
            });
    },
    fnListaVeiculoPortais: (pIdVeiculo) => async (dispatch, getState) => {
        dispatch({ type: 'REQUEST_PORTAIS_VEICULO_ACTION' });

        await fetch(publicador_service.services.listaPortaisVeiculo(pIdVeiculo, getState().marketing_publicador.ListaVeiculos.token))
            .then(response => {
                if (response.status === 200 || response.status === 0) {
                    return response;
                }
                else {
                    return response.json().then(function (object) {
                        return Promise.reject(new Error(object));
                    })
                }
            })
            .then(data => {
                data.json().then(function (object) {
                    dispatch({ type: 'RESPONSE_PORTAIS_VEICULO_ACTION', idVeiculo: pIdVeiculo, portais: object });
                });
            }).catch(function (err) {
                dispatch({ type: 'FAILURE_PORTAIS_VEICULO_ACTION', errorText: err.toString() });
            });
    },
    fnBuscaPortaisAtivos: () => async (dispatch, getState) => {
        dispatch({ type: 'REQUEST_PORTAIS_ATIVOS_ACTION' });

        await fetch(publicador_service.services.listaPortaisAtivos(getState().auth.authorizationToken))
            .then(response => {
                if (response.status === 200 || response.status === 0) {
                    return response;
                }
                else {
                    return response.json().then(function (object) {
                        return Promise.reject(new Error(object));
                    })
                }
            })
            .then(data => {
                data.json().then(function (object) {
                    dispatch({ type: 'RESPONSE_PORTAIS_ATIVOS_ACTION', results: object });
                });
            }).catch(function (err) {
                getState().marketing_publicador.ListaVeiculos.dropdownPortaisAtivos.errorText = err.toString();
                getState().marketing_publicador.ListaVeiculos.dropdownPortaisAtivos.isLoading = false;
                dispatch({ type: 'FAILURE_PORTAIS_ATIVOS_ACTION', errorText: err.toString() })
            });
    },
    fnListaPatios: () => async (dispatch, getState) => {
        dispatch({ type: 'REQUEST_PATIOS_ACTION' });

        await fetch(publicador_service.services.listaPatios(getState().auth.authorizationToken))
            .then(response => {
                if (response.status === 200 || response.status === 0) {
                    return response;
                }
                else {
                    return response.json().then(function (object) {
                        return Promise.reject(new Error(object));
                    })
                }
            })
            .then(data => {
                data.json().then(function (object) {
                    dispatch({ type: 'RESPONSE_PATIOS_ACTION', results: object });
                });
            }).catch(function (err) {
                dispatch({ type: 'FAILURE_PATIOS_ACTION', errorText: err.toString() })
            });
    },
    fnListaAnosModelos: () => async (dispatch, getState) => {
        var pDTO = {
            "IdPublicador": getState().marketing_publicador.PublicarVeiculo.IdPublicador,
            "idVeiculo": getState().marketing_publicador.PublicarVeiculo.idVeiculo,
            "Id": "",
            "Nome": ""
        }

        dispatch({ type: 'REQUEST_ANOSMODELO_PORTAL_ACTION' });

        await fetch(publicador_service.services.listaAnosModelo(pDTO, getState().auth.authorizationToken))
            .then(response => {
                if (response.status === 200 || response.status === 0) {
                    return response;
                }
                else {
                    return response.json().then(function (object) {
                        return Promise.reject(new Error(object));
                    })
                }
            })
            .then(data => {
                data.json().then(function (object) {
                    if (getState().marketing_publicador.PublicarVeiculo.anuncio.idAnuncioBNDV != null
                        && getState().marketing_publicador.PublicarVeiculo.anuncio.idAnuncioBNDV != 0) {
                        dispatch(actionCreators.fnListaVersoes());
                    }

                    dispatch({ type: 'RESPONSE_ANOSMODELO_PORTAL_ACTION', results: object });
                });
            }).catch(function (err) {
                dispatch({ type: 'FAILURE_ANOSMODELO_PORTAL_ACTION', errorText: err.toString() });
            });
    },
    fnListaModelos: () => async (dispatch, getState) => {
        if (getState().marketing_publicador.PublicarVeiculo.anuncio.idAnosModelo === ""
            && getState().marketing_publicador.PublicarVeiculo.IdPublicador === 5 && getState().marketing_publicador.PublicarVeiculo.anuncio.idCategoria === 1) {
            dispatch({ type: 'REQUEST_MODELOS_PORTAL_ACTION' });
        }
        else {
            var pDTO = {
                "idAno": getState().marketing_publicador.PublicarVeiculo.anuncio.idAnosModelo,
                "IdPublicador": getState().marketing_publicador.PublicarVeiculo.IdPublicador,
                "idVeiculo": getState().marketing_publicador.PublicarVeiculo.idVeiculo,
                "idCategoria": getState().marketing_publicador.PublicarVeiculo.anuncio.idCategoria,
                "Id": "",
                "Nome": ""
            }

            dispatch({ type: 'REQUEST_MODELOS_PORTAL_ACTION' });

            await fetch(publicador_service.services.listaModelos(pDTO, getState().auth.authorizationToken))
                .then(response => {
                    if (response.status === 200 || response.status === 0) {
                        return response;
                    }
                    else {
                        return response.json().then(function (object) {
                            return Promise.reject(new Error(object));
                        })
                    }
                })
                .then(data => {
                    data.json().then(function (object) {
                        var dtoListResult = [];
                        object.forEach(function (entry) {
                            dtoListResult.push(
                                {
                                    id: entry.id
                                    , Nome: entry.Nome
                                   
                                }
                            );
                        });

                        dispatch({ type: 'RESPONSE_MODELOS_PORTAL_ACTION', results: dtoListResult });
                    });
                }).catch(function (err) {
                    dispatch({ type: 'FAILURE_MODELOS_PORTAL_ACTION', errorText: err.toString() });
                });
        }
    },
    fnListaVersoes: () => async (dispatch, getState) => {
        if (getState().marketing_publicador.PublicarVeiculo.anuncio.idAnosModelo === ""
            && getState().marketing_publicador.PublicarVeiculo.IdPublicador === 5) {
            dispatch({ type: 'REQUEST_VERSOES_PORTAL_ACTION' });
        }
        else {
            var pDTO = {
                "idAno": getState().marketing_publicador.PublicarVeiculo.anuncio.idAnosModelo,
                "IdPublicador": getState().marketing_publicador.PublicarVeiculo.IdPublicador,
                "idVeiculo": getState().marketing_publicador.PublicarVeiculo.idVeiculo,
                "Id": "",
                "Nome": "",
                "idCategoria": getState().marketing_publicador.PublicarVeiculo.anuncio.idCategoria
            }

            dispatch({ type: 'REQUEST_VERSOES_PORTAL_ACTION' });

            await fetch(publicador_service.services.listaVersoes(pDTO, getState().auth.authorizationToken))
                .then(response => {
                    if (response.status === 200 || response.status === 0) {
                        return response;
                    }
                    else {
                        return response.json().then(function (object) {
                            return Promise.reject(new Error(object));
                        })
                    }
                })
                .then(data => {
                    data.json().then(function (object) {
                        var dtoListResult = [];
                        object.forEach(function (entry) {
                            dtoListResult.push(
                                {
                                    id: entry.id
                                    , Nome: entry.Nome
                                    , anoInicial: entry.anoInicial
                                    , anoFinal: entry.anoFinal
                                }
                            );
                        });

                        dispatch({ type: 'RESPONSE_VERSOES_PORTAL_ACTION', results: dtoListResult });
                    });
                }).catch(function (err) {
                    dispatch({ type: 'FAILURE_VERSOES_PORTAL_ACTION', errorText: err.toString() });
                });
        }
    },
    fnListaCategorias: () => async (dispatch, getState) => {
        var pDTO = {
            "IdPublicador": getState().marketing_publicador.PublicarVeiculo.IdPublicador,
            "idVeiculo": getState().marketing_publicador.PublicarVeiculo.idVeiculo,
            "Id": "",
            "Nome": "",
            "idCategoria": getState().marketing_publicador.PublicarVeiculo.anuncio.idCategoria
        }

        dispatch({ type: 'REQUEST_CATEGORIAS_PORTAL_ACTION' });

        await fetch(publicador_service.services.listaCategorias(pDTO, getState().auth.authorizationToken))
            .then(response => {
                if (response.status === 200 || response.status === 0) {
                    return response;
                }
                else {
                    return response.json().then(function (object) {
                        return Promise.reject(new Error(object));
                    })
                }
            })
            .then(data => {
                data.json().then(function (object) {
                    dispatch({ type: 'RESPONSE_CATEGORIAS_PORTAL_ACTION', results: object });
                });
            }).catch(function (err) {
                dispatch({ type: 'FAILURE_CATEGORIAS_PORTAL_ACTION', errorText: err.toString() });
            });
    },
    fnListaPlanos: () => async (dispatch, getState) => {
        var pDTO = {
            "IdPublicador": getState().marketing_publicador.PublicarVeiculo.IdPublicador,
            "idVeiculo": getState().marketing_publicador.PublicarVeiculo.idVeiculo,
            "idCategoria": getState().marketing_publicador.PublicarVeiculo.anuncio.idCategoria,
            "Id": "",
            "Nome": ""
        };

        dispatch({ type: 'REQUEST_PLANOS_PORTAL_ACTION' });

        await fetch(publicador_service.services.listaPlanos(pDTO, getState().auth.authorizationToken))
            .then(response => {
                if (response.status === 200 || response.status === 0) {
                    return response;
                }
                else {
                    return response.json().then(function (object) {
                        return Promise.reject(new Error(object));
                    })
                }
            })
            .then(data => {
                data.json().then(function (object) {
                    var dtoListResult = [];
                    object.forEach(function (entry) {
                        dtoListResult.push(
                            {
                                id: entry.id
                                , Nome: entry.Nome
                                , qtdeContratada: entry.qtdeContratada
                                , qtdeUtilizada: entry.qtdeUtilizada
                            }
                        );
                    });

                    dispatch({ type: 'RESPONSE_PLANOS_PORTAL_ACTION', results: dtoListResult });
                });
            }).catch(function (err) {
                dispatch({ type: 'ERROR_PLANOS_PORTAL_ACTION', errorText: err.toString() });
            });
    },
    fnListaAdicionais: () => async (dispatch, getState) => {
        var pDTO = {
            "IdPublicador": getState().marketing_publicador.PublicarVeiculo.IdPublicador,
            "idVeiculo": getState().marketing_publicador.PublicarVeiculo.idVeiculo,
            "Id": "",
            "Nome": ""
        }

        dispatch({ type: 'REQUEST_ADICIONAIS_PORTAL_ACTION' });

        await fetch(publicador_service.services.listaAdicionais(pDTO, getState().auth.authorizationToken))
            .then(response => {
                if (response.status === 200 || response.status === 0) {
                    return response;
                }
                else {
                    return response.json().then(function (object) {
                        return Promise.reject(new Error(object));
                    })
                }
            })
            .then(data => {
                data.json().then(function (object) {
                    var dtoListResult = [];
                    object.forEach(function (entry) {
                        dtoListResult.push(
                            {
                                id: entry.id
                                , Nome: entry.Nome
                                , qtdeContratada: entry.qtdeContratada
                                , qtdeUtilizada: entry.qtdeUtilizada
                            }
                        );
                    });

                    dispatch({ type: 'RESPONSE_ADICIONAIS_PORTAL_ACTION', results: dtoListResult });
                });
            }).catch(function (err) {
                dispatch({ type: 'FAILURE_ADICIONAIS_PORTAL_ACTION', erroText: err.toString() });
            });
    },
    fnListaRefrigeracao: () => async (dispatch, getState) => {
    
            var pDTO = {              
                "IdPublicador": getState().marketing_publicador.PublicarVeiculo.IdPublicador,               
                "idCategoria": getState().marketing_publicador.PublicarVeiculo.anuncio.idCategoria               
            }

            dispatch({ type: 'REQUEST_REFRIGERACAO_PORTAL_ACTION' });

            await fetch(publicador_service.services.listaRefrigeracao(pDTO, getState().auth.authorizationToken))
                .then(response => {
                    if (response.status === 200 || response.status === 0) {
                        return response;
                    }
                    else {
                        return response.json().then(function (object) {
                            return Promise.reject(new Error(object));
                        })
                    }
                })
                .then(data => {
                    data.json().then(function (object) {
                        var dtoListResult = [];
                        object.forEach(function (entry) {
                            dtoListResult.push(
                                {
                                    id: entry.id
                                    , Nome: entry.Nome

                                }
                            );
                        });

                        dispatch({ type: 'RESPONSE_REFRIGERACAO_PORTAL_ACTION', results: dtoListResult });
                    });
                }).catch(function (err) {
                    dispatch({ type: 'FAILURE_REFRIGERACAO_PORTAL_ACTION', errorText: err.toString() });
                });
        
    },
    fnListaAlimentacao: () => async (dispatch, getState) => {     

            var pDTO = {
                "IdPublicador": getState().marketing_publicador.PublicarVeiculo.IdPublicador,
                "idCategoria": getState().marketing_publicador.PublicarVeiculo.anuncio.idCategoria
            }

            dispatch({ type: 'REQUEST_ALIMENTACAO_PORTAL_ACTION' });

            await fetch(publicador_service.services.listaAlimentacao(pDTO, getState().auth.authorizationToken))
                .then(response => {
                    if (response.status === 200 || response.status === 0) {
                        return response;
                    }
                    else {
                        return response.json().then(function (object) {
                            return Promise.reject(new Error(object));
                        })
                    }
                })
                .then(data => {
                    data.json().then(function (object) {
                        var dtoListResult = [];
                        object.forEach(function (entry) {
                            dtoListResult.push(
                                {
                                    id: entry.id
                                    , Nome: entry.Nome

                                }
                            );
                        });

                        dispatch({ type: 'RESPONSE_ALIMENTACAO_PORTAL_ACTION', results: dtoListResult });
                    });
                }).catch(function (err) {
                    dispatch({ type: 'FAILURE_ALIMENTACAO_PORTAL_ACTION', errorText: err.toString() });
                });
        
    },
    fnListaMotor: () => async (dispatch, getState) => {

        var pDTO = {
            "IdPublicador": getState().marketing_publicador.PublicarVeiculo.IdPublicador,
            "idCategoria": getState().marketing_publicador.PublicarVeiculo.anuncio.idCategoria
        }

        dispatch({ type: 'REQUEST_MOTOR_PORTAL_ACTION' });

        await fetch(publicador_service.services.listaMotor(pDTO, getState().auth.authorizationToken))
            .then(response => {
                if (response.status === 200 || response.status === 0) {
                    return response;
                }
                else {
                    return response.json().then(function (object) {
                        return Promise.reject(new Error(object));
                    })
                }
            })
            .then(data => {
                data.json().then(function (object) {
                    var dtoListResult = [];
                    object.forEach(function (entry) {
                        dtoListResult.push(
                            {
                                id: entry.id
                                , Nome: entry.Nome

                            }
                        );
                    });

                    dispatch({ type: 'RESPONSE_MOTOR_PORTAL_ACTION', results: dtoListResult });
                });
            }).catch(function (err) {
                dispatch({ type: 'FAILURE_MOTOR_PORTAL_ACTION', errorText: err.toString() });
            });

    },
    fnListaPartida: () => async (dispatch, getState) => {

        var pDTO = {
            "IdPublicador": getState().marketing_publicador.PublicarVeiculo.IdPublicador,
            "idCategoria": getState().marketing_publicador.PublicarVeiculo.anuncio.idCategoria
        }

        dispatch({ type: 'REQUEST_PARTIDA_PORTAL_ACTION' });

        await fetch(publicador_service.services.listaPartida(pDTO, getState().auth.authorizationToken))
            .then(response => {
                if (response.status === 200 || response.status === 0) {
                    return response;
                }
                else {
                    return response.json().then(function (object) {
                        return Promise.reject(new Error(object));
                    })
                }
            })
            .then(data => {
                data.json().then(function (object) {
                    var dtoListResult = [];
                    object.forEach(function (entry) {
                        dtoListResult.push(
                            {
                                id: entry.id
                                , Nome: entry.Nome

                            }
                        );
                    });

                    dispatch({ type: 'RESPONSE_PARTIDA_PORTAL_ACTION', results: dtoListResult });
                });
            }).catch(function (err) {
                dispatch({ type: 'FAILURE_PARTIDA_PORTAL_ACTION', errorText: err.toString() });
            });

    },
    fnListaMarcha: () => async (dispatch, getState) => {

        var pDTO = {
            "IdPublicador": getState().marketing_publicador.PublicarVeiculo.IdPublicador,
            "idCategoria": getState().marketing_publicador.PublicarVeiculo.anuncio.idCategoria
        }

        dispatch({ type: 'REQUEST_MARCHA_PORTAL_ACTION' });

        await fetch(publicador_service.services.listaMarcha(pDTO, getState().auth.authorizationToken))
            .then(response => {
                if (response.status === 200 || response.status === 0) {
                    return response;
                }
                else {
                    return response.json().then(function (object) {
                        return Promise.reject(new Error(object));
                    })
                }
            })
            .then(data => {
                data.json().then(function (object) {
                    var dtoListResult = [];
                    object.forEach(function (entry) {
                        dtoListResult.push(
                            {
                                id: entry.id
                                , Nome: entry.Nome

                            }
                        );
                    });

                    dispatch({ type: 'RESPONSE_MARCHA_PORTAL_ACTION', results: dtoListResult });
                });
            }).catch(function (err) {
                dispatch({ type: 'FAILURE_MARCHA_PORTAL_ACTION', errorText: err.toString() });
            });

    },
    fnListaFreio: () => async (dispatch, getState) => {

        var pDTO = {
            "IdPublicador": getState().marketing_publicador.PublicarVeiculo.IdPublicador,
            "idCategoria": getState().marketing_publicador.PublicarVeiculo.anuncio.idCategoria
        }

        dispatch({ type: 'REQUEST_FREIO_PORTAL_ACTION' });

        await fetch(publicador_service.services.listaFreio(pDTO, getState().auth.authorizationToken))
            .then(response => {
                if (response.status === 200 || response.status === 0) {
                    return response;
                }
                else {
                    return response.json().then(function (object) {
                        return Promise.reject(new Error(object));
                    })
                }
            })
            .then(data => {
                data.json().then(function (object) {
                    var dtoListResult = [];
                    object.forEach(function (entry) {
                        dtoListResult.push(
                            {
                                id: entry.id
                                , Nome: entry.Nome

                            }
                        );
                    });

                    dispatch({ type: 'RESPONSE_FREIO_PORTAL_ACTION', results: dtoListResult });
                });
            }).catch(function (err) {
                dispatch({ type: 'FAILURE_FREIO_PORTAL_ACTION', errorText: err.toString() });
            });

    },
    fnListaCilindradas: () => async (dispatch, getState) => {

        var pDTO = {
            "IdPublicador": getState().marketing_publicador.PublicarVeiculo.IdPublicador,
            "idCategoria": getState().marketing_publicador.PublicarVeiculo.anuncio.idCategoria
        }

        dispatch({ type: 'REQUEST_CILINDRADAS_PORTAL_ACTION' });

        await fetch(publicador_service.services.listaCilindradas(pDTO, getState().auth.authorizationToken))
            .then(response => {
                if (response.status === 200 || response.status === 0) {
                    return response;
                }
                else {
                    return response.json().then(function (object) {
                        return Promise.reject(new Error(object));
                    })
                }
            })
            .then(data => {
                data.json().then(function (object) {
                    var dtoListResult = [];
                    object.forEach(function (entry) {
                        dtoListResult.push(
                            {
                                id: entry.id
                                , Nome: entry.Nome

                            }
                        );
                    });

                    dispatch({ type: 'RESPONSE_CILINDRADAS_PORTAL_ACTION', results: dtoListResult });
                });
            }).catch(function (err) {
                dispatch({ type: 'FAILURE_CILINDRADAS_PORTAL_ACTION', errorText: err.toString() });
            });

    },
    fnListaBlindagem: () => async (dispatch, getState) => {

        var pDTO = {
            "IdPublicador": getState().marketing_publicador.PublicarVeiculo.IdPublicador,
            "idCategoria": getState().marketing_publicador.PublicarVeiculo.anuncio.idCategoria
        }

        dispatch({ type: 'REQUEST_BLINDAGEM_PORTAL_ACTION' });

        await fetch(publicador_service.services.listaBlindagem(pDTO, getState().auth.authorizationToken))
            .then(response => {
                if (response.status === 200 || response.status === 0) {
                    return response;
                }
                else {
                    return response.json().then(function (object) {
                        return Promise.reject(new Error(object));
                    })
                }
            })
            .then(data => {
                data.json().then(function (object) {
                    var dtoListResult = [];
                    object.forEach(function (entry) {
                        dtoListResult.push(
                            {
                                id: entry.id
                                , Nome: entry.Nome

                            }
                        );
                    });

                    dispatch({ type: 'RESPONSE_BLINDAGEM_PORTAL_ACTION', results: dtoListResult });
                });
            }).catch(function (err) {
                dispatch({ type: 'FAILURE_BLINDAGEM_PORTAL_ACTION', errorText: err.toString() });
            });

    },
    fnBuscarAnuncioExtensao: () => async (dispatch, getState) => {

        var pDTO = {
            "idPublicador": getState().marketing_publicador.PublicarVeiculo.IdPublicador,
            "idAnuncio": getState().marketing_publicador.PublicarVeiculo.anuncio.idAnuncioBNDV
        }

        dispatch({ type: 'REQUEST_EXTENSAO_PORTAL_ACTION' });

        await fetch(publicador_service.services.obterAnuncioExtensao(pDTO, getState().auth.authorizationToken))
            .then(response => {
                if (response.status === 200 || response.status === 0) {
                    return response;
                }
                else {
                    return response.json().then(function (object) {
                        return Promise.reject(new Error(object));
                    })
                }
            })
            .then(data => {
                data.json().then(function (object) {
                

                    dispatch({ type: 'RESPONSE_EXTENSAO_PORTAL_ACTION', extensaoJs: object });
                });
            }).catch(function (err) {
                dispatch({ type: 'FAILURE_EXTENSAO_PORTAL_ACTION', errorText: err.toString() });
            });

    },
    fnBuscarAnuncioRecomendacao: () => async (dispatch, getState) => {

        var pDTO = {
            "idPublicador": getState().marketing_publicador.PublicarVeiculo.IdPublicador,
            "idAnuncio": getState().marketing_publicador.PublicarVeiculo.anuncio.idAnuncioBNDV
        }

        dispatch({ type: 'REQUEST_RECOMENDACOES_PORTAL_ACTION' });

        await fetch(publicador_service.services.listaRecomendacoes(pDTO, getState().auth.authorizationToken))
            .then(response => {
                if (response.status === 200 || response.status === 0) {
                    return response;
                }
                else {
                    return response.json().then(function (object) {
                        return Promise.reject(new Error(object));
                    })
                }
            })
            .then(data => {
                data.json().then(function (object) {
                    var dtoListResult = [];
                    object.forEach(function (entry) {
                        dtoListResult.push(
                            {
                                id: entry.id,
                                idAnuncio: entry.IdAnuncio,
                                chaveExternaAnuncio: entry.ChaveExternaAnuncio,
                                idDominio: entry.IdDominio,
                                dh: entry.DH,
                                penalizacao: entry.Penalizacao,
                                visualizadoDH: entry.VisualizadoDH,
                                titulo: entry.Titulo,
                                descricao: entry.Descricao,
                                motivo: entry.Motivo

                            }
                        );
                    });

                    dispatch({ type: 'RESPONSE_RECOMENDACOES_PORTAL_ACTION', results: dtoListResult });
                });
            }).catch(function (err) {
                dispatch({ type: 'FAILURE_RECOMENDACOES_PORTAL_ACTION', errorText: err.toString() });
            });

    },
    fnBuscarVeiculo: () => async (dispatch, getState) => {
        dispatch({ type: 'REQUEST_BUSCAR_VEICULO' });

        try {
            const response = await fetch(publicador_service.services.obterVeiculo(getState().marketing_publicador.PublicarVeiculo.idVeiculo, getState().auth.authorizationToken));
            if (!response.ok) {
                throw new Error(response.statusText);
            }
            else {
                // Preenche o objeto Veículo BNDV
                const json = await response.json();
                dispatch({ type: 'RESPONSE_BUSCAR_VEICULO', response: json });
            }
        } catch (err) {
            dispatch({ type: 'FAILURE_BUSCAR_VEICULO', errorText: err.toString() });
        }
    },
    fnBuscarAnuncio: () => async (dispatch, getState) => {
        dispatch({ type: 'REQUEST_BUSCAR_ANUNCIO' });

        await fetch(publicador_service.services.obterUltimoHistoricoAnuncio(
            getState().marketing_publicador.PublicarVeiculo.anuncio.idAnuncioBNDV,
            getState().auth.authorizationToken)
        )
            .then(response => {
                if (response.status === 200 || response.status === 0) {
                    return response;
                }
                else {
                    return response.json().then(function (object) {
                        return Promise.reject(new Error(object));
                    })
                }
            })
            .then(data => {
                data.json().then(function (object) {
                    // Preenche o objeto do anúncio
                    var retorno = {};
                    retorno = JSON.parse(object.jsonRequest);
                    dispatch({ type: 'RESPONSE_BUSCAR_ANUNCIO', response: retorno });
                });
            }).catch(function (err) {
                dispatch({ type: 'FAILURE_BUSCAR_ANUNCIO', errorText: err.toString() });
            });
    },
    fnPublicar: () => async (dispatch, getState) => {
        var pDTO = {
            "idVeiculo": getState().marketing_publicador.PublicarVeiculo.idVeiculo,
            "idPublicador": getState().marketing_publicador.PublicarVeiculo.IdPublicador,
            "idAnuncio": getState().marketing_publicador.PublicarVeiculo.anuncio.idAnuncio,
            "idAnosModelo": getState().marketing_publicador.PublicarVeiculo.anuncio.idAnosModelo,
            "idVersao": getState().marketing_publicador.PublicarVeiculo.anuncio.idVersao,
            "idCategoria": getState().marketing_publicador.PublicarVeiculo.anuncio.idCategoria,
            "idTipoVeiculo": getState().marketing_publicador.PublicarVeiculo.anuncio.idTipoVeiculo,
            "precoDe": getState().marketing_publicador.PublicarVeiculo.anuncio.precoDe,
            "preco": getState().marketing_publicador.PublicarVeiculo.anuncio.preco,
            "texto": getState().marketing_publicador.PublicarVeiculo.anuncio.texto,
            "idTipoPlano": getState().marketing_publicador.PublicarVeiculo.anuncio.idTipoPlano,
            "idTipoAdicional": getState().marketing_publicador.PublicarVeiculo.anuncio.idTipoAdicional,
            "titulo": getState().marketing_publicador.PublicarVeiculo.anuncio.titulo,
            "anuncioEmDestaque": getState().marketing_publicador.PublicarVeiculo.anuncio.anuncioEmDestaque,
            "video": getState().marketing_publicador.PublicarVeiculo.anuncio.video,
            "idBlindagem": getState().marketing_publicador.PublicarVeiculo.anuncio.idBlindagem
        }

        dispatch({ type: 'POST_PUBLICACAO_ACTION' });

        fetch(publicador_service.services.inserirPublicacao(pDTO, getState().auth.authorizationToken))
            .then(response => {
                if (response.status === 200 || response.status === 0) {
                    return response;
                }
                else {
                    return response.json().then(function (object) {
                        return Promise.reject(new Error(object));
                    })
                }
            })
            .then(data => {
                data.json().then(function (object) {
                    var objReturn = { idAnuncio: object.idAnuncio, urlAnuncio: object.urlAnuncio }
                    dispatch({ type: 'SUCCESS_POST_PUBLICACAO_ACTION', response: objReturn });
                });
            }).catch(function (err) {
                dispatch({ type: 'FAILURE_PUBLICACAO_ACTION', errorText: err.toString() });
            });
    },
    fnAlterarAnuncio: () => async (dispatch, getState) => {
        var pDTO = {
            "idVeiculo": getState().marketing_publicador.PublicarVeiculo.idVeiculo,
            "idPublicador": getState().marketing_publicador.PublicarVeiculo.IdPublicador,
            "idAnuncio": getState().marketing_publicador.PublicarVeiculo.anuncio.idAnuncio,
            "idAnuncioBNDV": getState().marketing_publicador.PublicarVeiculo.anuncio.idAnuncioBNDV,
            "idAnosModelo": getState().marketing_publicador.PublicarVeiculo.anuncio.idAnosModelo,
            "idVersao": getState().marketing_publicador.PublicarVeiculo.anuncio.idVersao,
            "idCategoria": getState().marketing_publicador.PublicarVeiculo.anuncio.idCategoria,
            "idTipoVeiculo": getState().marketing_publicador.PublicarVeiculo.anuncio.idTipoVeiculo,
            "precoDe": getState().marketing_publicador.PublicarVeiculo.anuncio.precoDe,
            "preco": getState().marketing_publicador.PublicarVeiculo.anuncio.preco,
            "texto": getState().marketing_publicador.PublicarVeiculo.anuncio.texto,
            "idTipoPlano": getState().marketing_publicador.PublicarVeiculo.anuncio.idTipoPlano,
            "idTipoAdicional": getState().marketing_publicador.PublicarVeiculo.anuncio.idTipoAdicional,
            "titulo": getState().marketing_publicador.PublicarVeiculo.anuncio.titulo,
            "anuncioEmDestaque": getState().marketing_publicador.PublicarVeiculo.anuncio.anuncioEmDestaque,
            "video": getState().marketing_publicador.PublicarVeiculo.anuncio.video,
            "idBlindagem": getState().marketing_publicador.PublicarVeiculo.anuncio.idBlindagem
        }

        dispatch({ type: 'PUT_PUBLICACAO_ACTION' });

        fetch(publicador_service.services.alterarPublicacao(pDTO, getState().auth.authorizationToken))
            .then(response => {
                if (response.status === 200 || response.status === 0) {
                    return response;
                }
                else {
                    return response.json().then(function (object) {
                        return Promise.reject(new Error(object));
                    })
                }
            })
            .then(data => {
                data.json().then(function (object) {
                    dispatch({ type: 'SUCCESS_PUT_PUBLICACAO_ACTION' });
                });
            }).catch(function (err) {
                dispatch({ type: 'FAILURE_PUBLICACAO_ACTION', errorText: err.toString() });
            });
    },
    fnRemover: () => async (dispatch, getState) => {
        dispatch({ type: 'DELETE_PUBLICACAO_ACTION' });

        var pDTO = {
            "idPublicador": getState().marketing_publicador.PublicarVeiculo.IdPublicador,
            "idAnuncio": getState().marketing_publicador.PublicarVeiculo.anuncio.idAnuncioBNDV,
            "motivo": ""
        }

        await fetch(publicador_service.services.removerPublicacao(pDTO, getState().auth.authorizationToken))
            .then(response => {
                if (response.status === 200 || response.status === 0) {
                    return response;
                }
                else {
                    return response.json().then(function (object) {
                        return Promise.reject(new Error(object));
                    })
                }
            })
            .then(data => {
                data.json().then(function (object) {
                    dispatch({ type: 'SUCCESS_DELETE_PUBLICACAO_ACTION', successText: object });
                });
            }).catch(function (err) {
                dispatch({ type: 'FAILURE_PUBLICACAO_ACTION', errorText: err.toString() });
            });
    },
    fnVisualizarRecomendacao: () => async (dispatch, getState) => {
        dispatch({ type: 'VISUALIZAR_RECOMENDACAO_ACTION' });

        var pDTO = {
            "idPublicador": getState().marketing_publicador.PublicarVeiculo.IdPublicador,
            "idAnuncio": getState().marketing_publicador.PublicarVeiculo.anuncio.idAnuncioBNDV,
           
        }

        await fetch(publicador_service.services.visualizarRecomendacao(pDTO, getState().auth.authorizationToken))
            .then(response => {
                if (response.status === 200 || response.status === 0) {
                    return response;
                }
                else {
                    return response.json().then(function (object) {
                        return Promise.reject(new Error(object));
                    })
                }
            })
            .then(data => {
                data.json().then(function (object) {
                    dispatch({ type: 'SUCCESS_VISUALIZAR_RECOMENDACAO_ACTION', response: object });
                });
            }).catch(function (err) {
                dispatch({ type: 'FAILURE_VISUALIZAR_RECOMENDACAO_ACTION', errorText: err.toString() });
            });
    },
    fnBuscarAnuncioMoto: () => async (dispatch, getState) => {
        dispatch({ type: 'REQUEST_BUSCAR_ANUNCIOMOTO' });

        await fetch(publicador_service.services.obterUltimoHistoricoAnuncio(
            getState().marketing_publicador.PublicarVeiculo.anuncio.idAnuncioBNDV,
            getState().auth.authorizationToken)
        )
            .then(response => {
                if (response.status === 200 || response.status === 0) {
                    return response;
                }
                else {
                    return response.json().then(function (object) {
                        return Promise.reject(new Error(object));
                    })
                }
            })
            .then(data => {
                data.json().then(function (object) {
                    // Preenche o objeto do anúncio
                    var retorno = {};
                    retorno = JSON.parse(object.jsonRequest);
                    dispatch({ type: 'RESPONSE_BUSCAR_ANUNCIOMOTO', response: retorno });
                });
            }).catch(function (err) {
                dispatch({ type: 'FAILURE_BUSCAR_ANUNCIOMOTO', errorText: err.toString() });
            });
    },
    fnPublicarMoto: () => async (dispatch, getState) => {
        var pDTO = {
            "idVeiculo": getState().marketing_publicador.PublicarVeiculo.idVeiculo,
            "idPublicador": getState().marketing_publicador.PublicarVeiculo.IdPublicador,
            "idAnuncio": getState().marketing_publicador.PublicarVeiculo.anuncio.idAnuncio,
            "idAnosModelo": getState().marketing_publicador.PublicarVeiculo.anuncio.idAnosModelo,
            "idVersao": getState().marketing_publicador.PublicarVeiculo.anuncio.idVersao,
            "idCategoria": getState().marketing_publicador.PublicarVeiculo.anuncio.idCategoria,
            "idTipoVeiculo": getState().marketing_publicador.PublicarVeiculo.anuncio.idTipoVeiculo,
            "precoDe": getState().marketing_publicador.PublicarVeiculo.anuncio.precoDe,
            "preco": getState().marketing_publicador.PublicarVeiculo.anuncio.preco,
            "texto": getState().marketing_publicador.PublicarVeiculo.anuncio.texto,
            "idTipoPlano": getState().marketing_publicador.PublicarVeiculo.anuncio.idTipoPlano,
            "idTipoAdicional": getState().marketing_publicador.PublicarVeiculo.anuncio.idTipoAdicional,
            "titulo": getState().marketing_publicador.PublicarVeiculo.anuncio.titulo,
            "anuncioEmDestaque": getState().marketing_publicador.PublicarVeiculo.anuncio.anuncioEmDestaque,
            "video": getState().marketing_publicador.PublicarVeiculo.anuncio.video,
            "cilindradas": getState().marketing_publicador.PublicarVeiculo.anuncio.cilindradas,
            "idCilindradas": getState().marketing_publicador.PublicarVeiculo.anuncio.idCilindradas,            
            "idRefrigeracao": getState().marketing_publicador.PublicarVeiculo.anuncio.idRefrigeracao,
            "idAlimentacao": getState().marketing_publicador.PublicarVeiculo.anuncio.idAlimentacao,
            "idMotor": getState().marketing_publicador.PublicarVeiculo.anuncio.idMotor,
            "idPartida": getState().marketing_publicador.PublicarVeiculo.anuncio.idPartida,
            "idFreio": getState().marketing_publicador.PublicarVeiculo.anuncio.idFreio,
            "idTipoAnuncio": getState().marketing_publicador.PublicarVeiculo.anuncio.idTipoAnuncio,
            "idMarcha": getState().marketing_publicador.PublicarVeiculo.anuncio.idMarcha,
            "marcaMotor": getState().marketing_publicador.PublicarVeiculo.anuncio.marcaMotor
        }

        dispatch({ type: 'POST_PUBLICACAOMOTO_ACTION' });

        fetch(publicador_service.services.inserirPublicacaoMoto(pDTO, getState().auth.authorizationToken))
            .then(response => {
                if (response.status === 200 || response.status === 0) {
                    return response;
                }
                else {
                    return response.json().then(function (object) {
                        return Promise.reject(new Error(object));
                    })
                }
            })
            .then(data => {
                data.json().then(function (object) {
                    var objReturn = { idAnuncio: object.idAnuncio, urlAnuncio: object.urlAnuncio }
                    dispatch({ type: 'SUCCESS_POST_PUBLICACAOMOTO_ACTION', response: objReturn });
                });
            }).catch(function (err) {
                dispatch({ type: 'FAILURE_PUBLICACAO_ACTION', errorText: err.toString() });
            });
    },
    fnAlterarAnuncioMoto: () => async (dispatch, getState) => {
        var pDTO = {
            "idVeiculo": getState().marketing_publicador.PublicarVeiculo.idVeiculo,
            "idPublicador": getState().marketing_publicador.PublicarVeiculo.IdPublicador,
            "idAnuncio": getState().marketing_publicador.PublicarVeiculo.anuncio.idAnuncio,
            "idAnuncioBNDV": getState().marketing_publicador.PublicarVeiculo.anuncio.idAnuncioBNDV,
            "idAnosModelo": getState().marketing_publicador.PublicarVeiculo.anuncio.idAnosModelo,
            "idVersao": getState().marketing_publicador.PublicarVeiculo.anuncio.idVersao,
            "idCategoria": getState().marketing_publicador.PublicarVeiculo.anuncio.idCategoria,
            "idTipoVeiculo": getState().marketing_publicador.PublicarVeiculo.anuncio.idTipoVeiculo,
            "precoDe": getState().marketing_publicador.PublicarVeiculo.anuncio.precoDe,
            "preco": getState().marketing_publicador.PublicarVeiculo.anuncio.preco,
            "texto": getState().marketing_publicador.PublicarVeiculo.anuncio.texto,
            "idTipoPlano": getState().marketing_publicador.PublicarVeiculo.anuncio.idTipoPlano,
            "idTipoAdicional": getState().marketing_publicador.PublicarVeiculo.anuncio.idTipoAdicional,
            "titulo": getState().marketing_publicador.PublicarVeiculo.anuncio.titulo,
            "anuncioEmDestaque": getState().marketing_publicador.PublicarVeiculo.anuncio.anuncioEmDestaque,
            "video": getState().marketing_publicador.PublicarVeiculo.anuncio.video,
            "cilindradas": getState().marketing_publicador.PublicarVeiculo.anuncio.cilindradas,
            "idCilindradas": getState().marketing_publicador.PublicarVeiculo.anuncio.idCilindradas,           
            "idRefrigeracao": getState().marketing_publicador.PublicarVeiculo.anuncio.idRefrigeracao,
            "idAlimentacao": getState().marketing_publicador.PublicarVeiculo.anuncio.idAlimentacao,
            "idMotor": getState().marketing_publicador.PublicarVeiculo.anuncio.idMotor,
            "idPartida": getState().marketing_publicador.PublicarVeiculo.anuncio.idPartida,
            "idFreio": getState().marketing_publicador.PublicarVeiculo.anuncio.idFreio,
            "idTipoAnuncio": getState().marketing_publicador.PublicarVeiculo.anuncio.idTipoAnuncio,
            "idMarcha": getState().marketing_publicador.PublicarVeiculo.anuncio.idMarcha,
            "marcaMotor": getState().marketing_publicador.PublicarVeiculo.anuncio.marcaMotor,
        }

        dispatch({ type: 'PUT_PUBLICACAOMOTO_ACTION' });

        fetch(publicador_service.services.alterarPublicacaoMoto(pDTO, getState().auth.authorizationToken))
            .then(response => {
                if (response.status === 200 || response.status === 0) {
                    return response;
                }
                else {
                    return response.json().then(function (object) {
                        return Promise.reject(new Error(object));
                    })
                }
            })
            .then(data => {
                data.json().then(function (object) {
                    dispatch({ type: 'SUCCESS_PUT_PUBLICACAOMOTO_ACTION' });
                });
            }).catch(function (err) {
                dispatch({ type: 'FAILURE_PUBLICACAOMOTO_ACTION', errorText: err.toString() });
            });
    },
    fnRemoverMoto: () => async (dispatch, getState) => {
        dispatch({ type: 'DELETE_PUBLICACAOMOTO_ACTION' });

        var pDTO = {
            "idPublicador": getState().marketing_publicador.PublicarVeiculo.IdPublicador,
            "idAnuncio": getState().marketing_publicador.PublicarVeiculo.anuncio.idAnuncioBNDV,
            "motivo": ""
        }

        await fetch(publicador_service.services.removerPublicacaoMoto(pDTO, getState().auth.authorizationToken))
            .then(response => {
                if (response.status === 200 || response.status === 0) {
                    return response;
                }
                else {
                    return response.json().then(function (object) {
                        return Promise.reject(new Error(object));
                    })
                }
            })
            .then(data => {
                data.json().then(function (object) {
                    dispatch({ type: 'SUCCESS_DELETE_PUBLICACAOMOTO_ACTION', successText: object });
                });
            }).catch(function (err) {
                dispatch({ type: 'FAILURE_PUBLICACAOMOTO_ACTION', errorText: err.toString() });
            });
    }
}

export const reducer = (state, action) => {
    state = state || initialState;

    if (action.type === UPDATE_STATE_MARKETING_PUBLICADOR_ACTION) {
        return {
            ...state,
            ModalWaitLoading: action.ModalWaitLoading,
            ListaVeiculos: action.ListaVeiculos,
            PublicarVeiculo: action.PublicarVeiculo,
            ModalOLX: action.ModalOLX,
            ModalOLXMoto: action.ModalOLXMoto,
            ModalAutoLine: action.ModalAutoLine,
            ModalAutoLineMoto: action.ModalAutoLineMoto,
            ModalMeuCarroNovo: action.ModalMeuCarroNovo,
            ModalICarros: action.ModalICarros,
            ModalICarrosMoto: action.ModalICarrosMoto,
            ModalWebMotors: action.ModalWebMotors,
            ModalWebMotorsMoto: action.ModalWebMotorsMoto,
            ModalMercadoLivre: action.ModalMercadoLivre,
            ModalMercadoLivreMoto: action.ModalMercadoLivreMoto,
            ModalCarroSaoJose: action.ModalCarroSaoJose,
            ModalCarroSaoJoseMoto: action.ModalCarroSaoJoseMoto,
            ModalTemUsados: action.ModalTemUsados,
            ModalNewMotors: action.ModalNewMotors,
            ModalCompreCar: action.ModalCompreCar,
            ModalCarroBrasil: action.ModalCarroBrasil,
            ModalCarroBrasilMoto: action.ModalCarroBrasilMoto,
            ModalDefaultFeed: action.ModalDefaultFeed,
            ModalWebSiteBNDV: action.ModalWebSiteBNDV,
            ModalWebSiteBNDVMoto: action.ModalWebSiteBNDVMoto,
            ModalFacebook: action.ModalFacebook,
            ModalFacebookMoto: action.ModalFacebookMoto,
            ModalMobiAuto: action.ModalMobiAuto,
            ModalCompreBlindados: action.ModalCompreBlindados,
            ModalUsadosBR: action.ModalUsadosBR,
            ModalUsadosBRMoto: action.ModalUsadosBRMoto,
            ModalRecomendacaoMercadoLivre: action.ModalRecomendacaoMercadoLivre,
        };
    }



    if (action.type === REQUEST_LISTA_VEICULOS_ACTION) {
        return {
            ...state,
            ListaVeiculos: {
                ...state.ListaVeiculos,
                errorText: '',
                isLoading: true,
                results: [],
                requested: true
            }
        };
    }
    if (action.type === RESPONSE_LISTA_VEICULOS_ACTION) {
        return {
            ...state,
            ListaVeiculos: {
                ...state.ListaVeiculos,
                isLoading: false,
                results: action.results,
                token: action.token,
                filters: {
                    ...state.ListaVeiculos.filters,
                    TotalNumberOfRecords: action.TotalNumberOfRecords,
                    TotalNumberOfPages: action.TotalNumberOfPages
                }
            }
        };
    }
    if (action.type === FAILURE_LISTA_VEICULOS_ACTION) {
        return {
            ...state,
            ListaVeiculos: {
                ...state.ListaVeiculos,
                errorText: action.errorText,
                isLoading: false
            }
        };
    }



    if (action.type === REQUEST_PORTAIS_VEICULO_ACTION) {
        return {
            ...state,
            ListaVeiculos: { ...state.ListaVeiculos }
        };
    }
    if (action.type === RESPONSE_PORTAIS_VEICULO_ACTION) {
        var listVeiculos = state.ListaVeiculos.results;
        listVeiculos.map(function (rowData, index) {
            if (rowData.id === action.idVeiculo) {
                listVeiculos[index].portais = action.portais;
            }
        });

        return {
            ...state,
            ListaVeiculos: { ...state.ListaVeiculos }
        };
    }
    if (action.type === FAILURE_PORTAIS_VEICULO_ACTION) {
        return {
            ...state,
            ListaVeiculos: { ...state.ListaVeiculos }
        };
    }



    if (action.type === REQUEST_PORTAIS_ATIVOS_ACTION) {
        return {
            ...state,
            ListaVeiculos: {
                ...state.ListaVeiculos,
                dropdownPortaisAtivos: {
                    errorText: '',
                    isLoading: true,
                    results: []
                }

            }
        };
    }
    if (action.type === RESPONSE_PORTAIS_ATIVOS_ACTION) {
        return {
            ...state,
            ListaVeiculos: {
                ...state.ListaVeiculos,
                dropdownPortaisAtivos: {
                    ...state.ListaVeiculos.dropdownPortaisAtivos,
                    isLoading: false,
                    results: action.results
                }

            }
        };
    }
    if (action.type === FAILURE_PORTAIS_ATIVOS_ACTION) {
        return {
            ...state,
            ListaVeiculos: {
                ...state.ListaVeiculos,
                dropdownPortaisAtivos: {
                    ...state.ListaVeiculos.dropdownPortaisAtivos,
                    isLoading: false,
                    errorText: action.errorText
                }

            }
        };
    }



    if (action.type === REQUEST_PATIOS_ACTION) {
        return {
            ...state,
            ListaVeiculos: {
                ...state.ListaVeiculos,
                dropdownPatios: {
                    errorText: '',
                    isLoading: true,
                    results: []
                }

            }
        };
    }
    if (action.type === RESPONSE_PATIOS_ACTION) {
        return {
            ...state,
            ListaVeiculos: {
                ...state.ListaVeiculos,
                dropdownPatios: {
                    ...state.ListaVeiculos.dropdownPatios,
                    isLoading: false,
                    results: action.results
                }

            }
        };
    }
    if (action.type === FAILURE_PATIOS_ACTION) {
        return {
            ...state,
            ListaVeiculos: {
                ...state.ListaVeiculos,
                dropdownPatios: {
                    ...state.ListaVeiculos.dropdownPatios,
                    isLoading: false,
                    errorText: action.errorText
                }

            }
        };
    }



    if (action.type === REQUEST_BUSCAR_VEICULO) {
        return {
            ...state,
            PublicarVeiculo: {
                ...state.PublicarVeiculo,
                isLoading: true,
                errorText: ''
            }
        };
    }
    if (action.type === RESPONSE_BUSCAR_VEICULO) {
        return {
            ...state,
            PublicarVeiculo: {
                ...state.PublicarVeiculo,
                isLoading: false,
                veiculoBNDV: {
                    ...state.PublicarVeiculo.veiculoBNDV,
                    id: action.response.id,
                    placa: action.response.placa,
                    chassi: action.response.chassi,
                    veiculo: action.response.veiculo,
                    descMarca: action.response.descMarca,
                    descModelo: action.response.descModelo,
                    descVersao: action.response.descVersao,
                    descCombustivel: action.response.descCombustivel,
                    descPortas: action.response.descPortas,
                    descCambio: action.response.descCambio,
                    cor: action.response.cor,
                    observacoes: action.response.observacoes,
                    anoModelo: action.response.anoModelo,
                    anoFabricacao: action.response.anoFabricacao,
                    valorWeb: action.response.valorWeb,
                    km: action.response.km,
                    opcionais: action.response.opcionais
                },
                anuncio: {
                    ...state.PublicarVeiculo.anuncio,
                    titulo: (action.response.descMarca + ' '
                        + action.response.descModelo + ' '
                        + (action.response.descVersao == null ? '' : action.response.descVersao.toString().replace(action.response.descModelo, '') + ' ')
                        + action.response.descCombustivel + ' '
                        + action.response.descCambio).substring(0, 59),
                    preco: action.response.valorWeb,
                    texto: action.response.observacoes                    
                }
            }
        };
    }
    if (action.type === FAILURE_BUSCAR_VEICULO) {
        return {
            ...state,
            PublicarVeiculo: {
                ...state.PublicarVeiculo,
                isLoading: false,
                errorText: action.errorText
            }
        };
    }



    if (action.type === REQUEST_BUSCAR_ANUNCIO) {
        return {
            ...state,
            PublicarVeiculo: {
                ...state.PublicarVeiculo,
                isLoading: true,
                errorText: ''
            }
        };
    }
    if (action.type === RESPONSE_BUSCAR_ANUNCIO) {
        return {
            ...state,
            PublicarVeiculo: {
                ...state.PublicarVeiculo,
                isLoading: false,
                anuncio: {
                    ...state.PublicarVeiculo.anuncio,
                    anuncioEmDestaque: action.response[0].AnuncioEmDestaque,
                    idAnosModelo: action.response[0].IdAnosModelo,
                    idAnuncio: action.response[0].IdAnuncio,
                    idAnuncioCliente: action.response[0].IdAnuncioCliente,
                    idCategoria: action.response[0].IdCategoria,
                    idCidade: action.response[0].IdCidade,
                    idPublicador: action.response[0].IdPublicador,
                    idTipoPlano: action.response[0].IdTipoPlano,
                    idTipoVeiculo: action.response[0].IdTipoVeiculo,
                    idVersao: action.response[0].IdVersao,
                    preco: action.response[0].Preco,
                    precoDe: action.response[0].PrecoDe,
                    texto: action.response[0].Texto,
                    titulo: action.response[0].Titulo,
                    video: action.response[0].Videos
                        ? action.response[0].Videos.length > 0
                            ? action.response[0].Videos[0]
                            : ''
                        : '',
                    idBlindagem: action.response[0].IdBlindagem
                    
                }
            }
        };
    }
    if (action.type === FAILURE_BUSCAR_ANUNCIO) {
        return {
            ...state,
            PublicarVeiculo: {
                ...state.PublicarVeiculo,
                isLoading: false,
                errorText: action.errorText
            }
        };
    }

    if (action.type === REQUEST_BUSCAR_ANUNCIOMOTO) {
        return {
            ...state,
            PublicarVeiculo: {
                ...state.PublicarVeiculo,
                isLoading: true,
                errorText: ''
            }
        };
    }
    if (action.type === RESPONSE_BUSCAR_ANUNCIOMOTO) {
        return {
            ...state,
            PublicarVeiculo: {
                ...state.PublicarVeiculo,
                isLoading: false,
                anuncio: {
                    ...state.PublicarVeiculo.anuncio,
                    anuncioEmDestaque: action.response[0].AnuncioEmDestaque,
                    idAnosModelo: action.response[0].IdAnosModelo,
                    idAnuncio: action.response[0].IdAnuncio,
                    idAnuncioCliente: action.response[0].IdAnuncioCliente,
                    idCategoria: action.response[0].IdCategoria,
                    idCidade: action.response[0].IdCidade,
                    idPublicador: action.response[0].IdPublicador,
                    idTipoPlano: action.response[0].IdTipoPlano,
                    idTipoVeiculo: action.response[0].IdTipoVeiculo,
                    idVersao: action.response[0].IdVersao,
                    preco: action.response[0].Preco,
                    precoDe: action.response[0].PrecoDe,
                    texto: action.response[0].Texto,
                    titulo: action.response[0].Titulo,
                    video: action.response[0].Videos
                        ? action.response[0].Videos.length > 0
                            ? action.response[0].Videos[0]
                            : ''
                        : '',
                    idAlimentacao: action.response[0].IdAlimentacao,
                    idRefrigeracao: action.response[0].IdRefrigeracao,
                    idMotor: action.response[0].IdMotor,
                    idPartida: action.response[0].IdPartida,
                    idFreio: action.response[0].IdFreio,
                    idMarcha: action.response[0].NumMarchas,
                    marcaMotor: action.response[0].MarcaMotor,
                    cilindradas: action.response[0].Cilindradas,
                    idCilindradas: action.response[0].IdCilindradas
                    

                }
            }
        };
    }
    if (action.type === FAILURE_BUSCAR_ANUNCIOMOTO) {
        return {
            ...state,
            PublicarVeiculo: {
                ...state.PublicarVeiculo,
                isLoading: false,
                errorText: action.errorText
            }
        };
    }



    if (action.type === REQUEST_ANOSMODELO_PORTAL_ACTION) {
        return {
            ...state,
            PublicarVeiculo: {
                ...state.PublicarVeiculo,
                dadosPortal: {
                    ...state.PublicarVeiculo.dadosPortal,
                    anosmodelo: {
                        isLoading: true,
                        errorText: '',
                        results: []
                    }
                }
            }
        };
    }
    if (action.type === RESPONSE_ANOSMODELO_PORTAL_ACTION) {
        return {
            ...state,
            PublicarVeiculo: {
                ...state.PublicarVeiculo,
                dadosPortal: {
                    ...state.PublicarVeiculo.dadosPortal,
                    anosmodelo: {
                        ...state.PublicarVeiculo.dadosPortal.anosmodelo,
                        isLoading: false,
                        results: action.results
                    }
                }
            }
        };
    }
    if (action.type === FAILURE_ANOSMODELO_PORTAL_ACTION) {
        return {
            ...state,
            PublicarVeiculo: {
                ...state.PublicarVeiculo,
                dadosPortal: {
                    ...state.PublicarVeiculo.dadosPortal,
                    anosmodelo: {
                        ...state.PublicarVeiculo.dadosPortal.anosmodelo,
                        isLoading: false,
                        errorText: action.erroText
                    }
                }
            }
        };
    }

    if (action.type === REQUEST_MODELOS_PORTAL_ACTION) {
        return {
            ...state,
            PublicarVeiculo: {
                ...state.PublicarVeiculo,
                dadosPortal: {
                    ...state.PublicarVeiculo.dadosPortal,
                    modelos: {
                        isLoading: true,
                        errorText: '',
                        results: []
                    }
                }
            }
        };
    }
    if (action.type === RESPONSE_MODELOS_PORTAL_ACTION) {
        return {
            ...state,
            PublicarVeiculo: {
                ...state.PublicarVeiculo,
                dadosPortal: {
                    ...state.PublicarVeiculo.dadosPortal,
                    modelos: {
                        ...state.PublicarVeiculo.dadosPortal.modelos,
                        isLoading: false,
                        results: action.results
                    }
                }
            }
        };
    }
    if (action.type === FAILURE_MODELOS_PORTAL_ACTION) {
        return {
            ...state,
            PublicarVeiculo: {
                ...state.PublicarVeiculo,
                dadosPortal: {
                    ...state.PublicarVeiculo.dadosPortal,
                    modelos: {
                        ...state.PublicarVeiculo.dadosPortal.modelos,
                        isLoading: false,
                        errorText: action.erroText
                    }
                }
            }
        };
    }

    if (action.type === REQUEST_VERSOES_PORTAL_ACTION) {
        return {
            ...state,
            PublicarVeiculo: {
                ...state.PublicarVeiculo,
                dadosPortal: {
                    ...state.PublicarVeiculo.dadosPortal,
                    versoes: {
                        isLoading: true,
                        errorText: '',
                        results: []
                    }
                }
            }
        };
    }
    if (action.type === RESPONSE_VERSOES_PORTAL_ACTION) {
        return {
            ...state,
            PublicarVeiculo: {
                ...state.PublicarVeiculo,
                dadosPortal: {
                    ...state.PublicarVeiculo.dadosPortal,
                    versoes: {
                        ...state.PublicarVeiculo.dadosPortal.versoes,
                        isLoading: false,
                        results: action.results
                    }
                }
            }
        };
    }
    if (action.type === FAILURE_VERSOES_PORTAL_ACTION) {
        return {
            ...state,
            PublicarVeiculo: {
                ...state.PublicarVeiculo,
                dadosPortal: {
                    ...state.PublicarVeiculo.dadosPortal,
                    versoes: {
                        ...state.PublicarVeiculo.dadosPortal.versoes,
                        isLoading: false,
                        errorText: action.erroText
                    }
                }
            }
        };
    }



    if (action.type === REQUEST_CATEGORIAS_PORTAL_ACTION) {
        return {
            ...state,
            PublicarVeiculo: {
                ...state.PublicarVeiculo,
                dadosPortal: {
                    ...state.PublicarVeiculo.dadosPortal,
                    categorias: {
                        isLoading: true,
                        errorText: '',
                        results: []
                    }
                }
            }
        };
    }
    if (action.type === RESPONSE_CATEGORIAS_PORTAL_ACTION) {
        return {
            ...state,
            PublicarVeiculo: {
                ...state.PublicarVeiculo,
                dadosPortal: {
                    ...state.PublicarVeiculo.dadosPortal,
                    categorias: {
                        ...state.PublicarVeiculo.dadosPortal.categorias,
                        isLoading: false,
                        results: action.results
                    }
                }
            }
        };
    }
    if (action.type === FAILURE_CATEGORIAS_PORTAL_ACTION) {
        return {
            ...state,
            PublicarVeiculo: {
                ...state.PublicarVeiculo,
                dadosPortal: {
                    ...state.PublicarVeiculo.dadosPortal,
                    categorias: {
                        ...state.PublicarVeiculo.dadosPortal.categorias,
                        isLoading: false,
                        errorText: action.errorText
                    }
                }
            }
        };
    }



    if (action.type === REQUEST_PLANOS_PORTAL_ACTION) {
        return {
            ...state,
            PublicarVeiculo: {
                ...state.PublicarVeiculo,
                dadosPortal: {
                    ...state.PublicarVeiculo.dadosPortal,
                    planos: {
                        isLoading: true,
                        errorText: '',
                        results: []
                    }
                }
            }
        };
    }
    if (action.type === RESPONSE_PLANOS_PORTAL_ACTION) {
        return {
            ...state,
            PublicarVeiculo: {
                ...state.PublicarVeiculo,
                dadosPortal: {
                    ...state.PublicarVeiculo.dadosPortal,
                    planos: {
                        ...state.PublicarVeiculo.dadosPortal.planos,
                        isLoading: false,
                        results: action.results
                    }
                }
            }
        };
    }
    if (action.type === FAILURE_PLANOS_PORTAL_ACTION) {
        return {
            ...state,
            PublicarVeiculo: {
                ...state.PublicarVeiculo,
                dadosPortal: {
                    ...state.PublicarVeiculo.dadosPortal,
                    planos: {
                        ...state.PublicarVeiculo.dadosPortal.planos,
                        isLoading: false,
                        errorText: action.errorText
                    }
                }
            }
        };
    }



    if (action.type === REQUEST_ADICIONAIS_PORTAL_ACTION) {
        return {
            ...state,
            PublicarVeiculo: {
                ...state.PublicarVeiculo,
                dadosPortal: {
                    ...state.PublicarVeiculo.dadosPortal,
                    adicionais: {
                        isLoading: true,
                        errorText: '',
                        results: []
                    }
                }
            }
        };
    }
    if (action.type === RESPONSE_ADICIONAIS_PORTAL_ACTION) {
        return {
            ...state,
            PublicarVeiculo: {
                ...state.PublicarVeiculo,
                dadosPortal: {
                    ...state.PublicarVeiculo.dadosPortal,
                    adicionais: {
                        ...state.PublicarVeiculo.dadosPortal.adicionais,
                        isLoading: false,
                        results: action.results
                    }
                }
            }
        };
    }
    if (action.type === FAILURE_ADICIONAIS_PORTAL_ACTION) {
        return {
            ...state,
            PublicarVeiculo: {
                ...state.PublicarVeiculo,
                dadosPortal: {
                    ...state.PublicarVeiculo.dadosPortal,
                    adicionais: {
                        ...state.PublicarVeiculo.dadosPortal.adicionais,
                        isLoading: false,
                        errorText: action.erroText
                    }
                }
            }
        };
    }

    if (action.type === REQUEST_REFRIGERACAO_PORTAL_ACTION) {
        return {
            ...state,
            PublicarVeiculo: {
                ...state.PublicarVeiculo,
                dadosPortal: {
                    ...state.PublicarVeiculo.dadosPortal,
                    refrigeracao: {
                        isLoading: true,
                        errorText: '',
                        results: []
                    }
                }
            }
        };
    }
    if (action.type === RESPONSE_REFRIGERACAO_PORTAL_ACTION) {
        return {
            ...state,
            PublicarVeiculo: {
                ...state.PublicarVeiculo,
                dadosPortal: {
                    ...state.PublicarVeiculo.dadosPortal,
                    refrigeracao: {
                        ...state.PublicarVeiculo.dadosPortal.refrigeracao,
                        isLoading: false,
                        results: action.results
                    }
                }
            }
        };
    }
    if (action.type === FAILURE_REFRIGERACAO_PORTAL_ACTION) {
        return {
            ...state,
            PublicarVeiculo: {
                ...state.PublicarVeiculo,
                dadosPortal: {
                    ...state.PublicarVeiculo.dadosPortal,
                    refrigeracao: {
                        ...state.PublicarVeiculo.dadosPortal.refrigeracao,
                        isLoading: false,
                        errorText: action.erroText
                    }
                }
            }
        };
    }
    if (action.type === REQUEST_ALIMENTACAO_PORTAL_ACTION) {
        return {
            ...state,
            PublicarVeiculo: {
                ...state.PublicarVeiculo,
                dadosPortal: {
                    ...state.PublicarVeiculo.dadosPortal,
                    alimentacao: {
                        isLoading: true,
                        errorText: '',
                        results: []
                    }
                }
            }
        };
    }
    if (action.type === RESPONSE_ALIMENTACAO_PORTAL_ACTION) {
        return {
            ...state,
            PublicarVeiculo: {
                ...state.PublicarVeiculo,
                dadosPortal: {
                    ...state.PublicarVeiculo.dadosPortal,
                    alimentacao: {
                        ...state.PublicarVeiculo.dadosPortal.alimentacao,
                        isLoading: false,
                        results: action.results
                    }
                }
            }
        };
    }
    if (action.type === FAILURE_ALIMENTACAO_PORTAL_ACTION) {
        return {
            ...state,
            PublicarVeiculo: {
                ...state.PublicarVeiculo,
                dadosPortal: {
                    ...state.PublicarVeiculo.dadosPortal,
                    alimentacao: {
                        ...state.PublicarVeiculo.dadosPortal.alimentacao,
                        isLoading: false,
                        errorText: action.erroText
                    }
                }
            }
        };
    }

    if (action.type === REQUEST_MOTOR_PORTAL_ACTION) {
        return {
            ...state,
            PublicarVeiculo: {
                ...state.PublicarVeiculo,
                dadosPortal: {
                    ...state.PublicarVeiculo.dadosPortal,
                    motor: {
                        isLoading: true,
                        errorText: '',
                        results: []
                    }
                }
            }
        };
    }
    if (action.type === RESPONSE_MOTOR_PORTAL_ACTION) {
        return {
            ...state,
            PublicarVeiculo: {
                ...state.PublicarVeiculo,
                dadosPortal: {
                    ...state.PublicarVeiculo.dadosPortal,
                    motor: {
                        ...state.PublicarVeiculo.dadosPortal.motor,
                        isLoading: false,
                        results: action.results
                    }
                }
            }
        };
    }
    if (action.type === FAILURE_MOTOR_PORTAL_ACTION) {
        return {
            ...state,
            PublicarVeiculo: {
                ...state.PublicarVeiculo,
                dadosPortal: {
                    ...state.PublicarVeiculo.dadosPortal,
                    motor: {
                        ...state.PublicarVeiculo.dadosPortal.motor,
                        isLoading: false,
                        errorText: action.erroText
                    }
                }
            }
        };
    }
    if (action.type === REQUEST_PARTIDA_PORTAL_ACTION) {
        return {
            ...state,
            PublicarVeiculo: {
                ...state.PublicarVeiculo,
                dadosPortal: {
                    ...state.PublicarVeiculo.dadosPortal,
                    partida: {
                        isLoading: true,
                        errorText: '',
                        results: []
                    }
                }
            }
        };
    }
    if (action.type === RESPONSE_PARTIDA_PORTAL_ACTION) {
        return {
            ...state,
            PublicarVeiculo: {
                ...state.PublicarVeiculo,
                dadosPortal: {
                    ...state.PublicarVeiculo.dadosPortal,
                    partida: {
                        ...state.PublicarVeiculo.dadosPortal.partida,
                        isLoading: false,
                        results: action.results
                    }
                }
            }
        };
    }
    if (action.type === FAILURE_PARTIDA_PORTAL_ACTION) {
        return {
            ...state,
            PublicarVeiculo: {
                ...state.PublicarVeiculo,
                dadosPortal: {
                    ...state.PublicarVeiculo.dadosPortal,
                    partida: {
                        ...state.PublicarVeiculo.dadosPortal.partida,
                        isLoading: false,
                        errorText: action.erroText
                    }
                }
            }
        };
    }

    if (action.type === REQUEST_MARCHA_PORTAL_ACTION) {
        return {
            ...state,
            PublicarVeiculo: {
                ...state.PublicarVeiculo,
                dadosPortal: {
                    ...state.PublicarVeiculo.dadosPortal,
                    marcha: {
                        isLoading: true,
                        errorText: '',
                        results: []
                    }
                }
            }
        };
    }
    if (action.type === RESPONSE_MARCHA_PORTAL_ACTION) {
        return {
            ...state,
            PublicarVeiculo: {
                ...state.PublicarVeiculo,
                dadosPortal: {
                    ...state.PublicarVeiculo.dadosPortal,
                    marcha: {
                        ...state.PublicarVeiculo.dadosPortal.marcha,
                        isLoading: false,
                        results: action.results
                    }
                }
            }
        };
    }
    if (action.type === FAILURE_MARCHA_PORTAL_ACTION) {
        return {
            ...state,
            PublicarVeiculo: {
                ...state.PublicarVeiculo,
                dadosPortal: {
                    ...state.PublicarVeiculo.dadosPortal,
                    marcha: {
                        ...state.PublicarVeiculo.dadosPortal.marcha,
                        isLoading: false,
                        errorText: action.erroText
                    }
                }
            }
        };
    }

    if (action.type === REQUEST_FREIO_PORTAL_ACTION) {
        return {
            ...state,
            PublicarVeiculo: {
                ...state.PublicarVeiculo,
                dadosPortal: {
                    ...state.PublicarVeiculo.dadosPortal,
                    freio: {
                        isLoading: true,
                        errorText: '',
                        results: []
                    }
                }
            }
        };
    }
    if (action.type === RESPONSE_FREIO_PORTAL_ACTION) {
        return {
            ...state,
            PublicarVeiculo: {
                ...state.PublicarVeiculo,
                dadosPortal: {
                    ...state.PublicarVeiculo.dadosPortal,
                    freio: {
                        ...state.PublicarVeiculo.dadosPortal.freio,
                        isLoading: false,
                        results: action.results
                    }
                }
            }
        };
    }
    if (action.type === FAILURE_FREIO_PORTAL_ACTION) {
        return {
            ...state,
            PublicarVeiculo: {
                ...state.PublicarVeiculo,
                dadosPortal: {
                    ...state.PublicarVeiculo.dadosPortal,
                    freio: {
                        ...state.PublicarVeiculo.dadosPortal.freio,
                        isLoading: false,
                        errorText: action.erroText
                    }
                }
            }
        };
    }

    if (action.type === REQUEST_BLINDAGEM_PORTAL_ACTION) {
        return {
            ...state,
            PublicarVeiculo: {
                ...state.PublicarVeiculo,
                dadosPortal: {
                    ...state.PublicarVeiculo.dadosPortal,
                    blindagem: {
                        isLoading: true,
                        errorText: '',
                        results: []
                    }
                }
            }
        };
    }
    if (action.type === RESPONSE_BLINDAGEM_PORTAL_ACTION) {
        return {
            ...state,
            PublicarVeiculo: {
                ...state.PublicarVeiculo,
                dadosPortal: {
                    ...state.PublicarVeiculo.dadosPortal,
                    blindagem: {
                        ...state.PublicarVeiculo.dadosPortal.blindagem,
                        isLoading: false,
                        results: action.results
                    }
                }
            }
        };
    }
    if (action.type === FAILURE_BLINDAGEM_PORTAL_ACTION) {
        return {
            ...state,
            PublicarVeiculo: {
                ...state.PublicarVeiculo,
                dadosPortal: {
                    ...state.PublicarVeiculo.dadosPortal,
                    blindagem: {
                        ...state.PublicarVeiculo.dadosPortal.blindagem,
                        isLoading: false,
                        errorText: action.erroText
                    }
                }
            }
        };
    }
    if (action.type === REQUEST_RECOMENDACOES_PORTAL_ACTION) {
        return {
            ...state,
            PublicarVeiculo: {
                ...state.PublicarVeiculo,
                dadosPortal: {
                    ...state.PublicarVeiculo.dadosPortal,
                    recomendacoes: {
                        isLoading: true,
                        errorText: '',
                        results: []
                    }
                }
            },
            ModalRecomendacaoMercadoLivre: {
                ...state.ModalRecomendacaoMercadoLivre,
                isLoading: true,
            }

        };
    }
    if (action.type === RESPONSE_RECOMENDACOES_PORTAL_ACTION) {
        return {
            ...state,
            PublicarVeiculo: {
                ...state.PublicarVeiculo,
                dadosPortal: {
                    ...state.PublicarVeiculo.dadosPortal,
                    recomendacoes: {
                        ...state.PublicarVeiculo.dadosPortal.recomendacoes,
                        isLoading: false,
                        results: action.results
                    }
                }
            },
            ModalRecomendacaoMercadoLivre: {
                ...state.ModalRecomendacaoMercadoLivre,
                isLoading: false,
            }
        };
    }
    if (action.type === FAILURE_RECOMENDACOES_PORTAL_ACTION) {
        return {
            ...state,
            PublicarVeiculo: {
                ...state.PublicarVeiculo,
                dadosPortal: {
                    ...state.PublicarVeiculo.dadosPortal,
                    recomendacoes: {
                        ...state.PublicarVeiculo.dadosPortal.recomendacoes,
                        isLoading: false,
                        errorText: action.erroText
                    }
                }
            },            
            ModalRecomendacaoMercadoLivre: {
                ...state.ModalRecomendacaoMercadoLivre,
                isLoading: false,
            }
        };
    }
    if (action.type === REQUEST_EXTENSAO_PORTAL_ACTION) {
        return {
            ...state,          
            ModalRecomendacaoMercadoLivre: {
                ...state.ModalRecomendacaoMercadoLivre,
                isLoading: true,
            }

        };
    }
    if (action.type === RESPONSE_EXTENSAO_PORTAL_ACTION) {
        return {
            ...state,
            PublicarVeiculo: {
                ...state.PublicarVeiculo,
                anuncio: {
                    ...state.PublicarVeiculo.anuncio,
                    anuncioExtensaoJs: action.extensaoJs.PublicadorExtensaoJs                   
                }
            },
            ModalRecomendacaoMercadoLivre: {
                ...state.ModalRecomendacaoMercadoLivre,
                isLoading: false,
            }
        };
    }
    if (action.type === FAILURE_EXTENSAO_PORTAL_ACTION) {
        return {
            ...state,            
            ModalRecomendacaoMercadoLivre: {
                ...state.ModalRecomendacaoMercadoLivre,
                isLoading: false,
            }
        };
    }
    if (action.type === REQUEST_CILINDRADAS_PORTAL_ACTION) {
        return {
            ...state,
            PublicarVeiculo: {
                ...state.PublicarVeiculo,
                dadosPortal: {
                    ...state.PublicarVeiculo.dadosPortal,
                    cilindradas: {
                        isLoading: true,
                        errorText: '',
                        results: []
                    }
                }
            }
        };
    }
    if (action.type === RESPONSE_CILINDRADAS_PORTAL_ACTION) {
        return {
            ...state,
            PublicarVeiculo: {
                ...state.PublicarVeiculo,
                dadosPortal: {
                    ...state.PublicarVeiculo.dadosPortal,
                    cilindradas: {
                        ...state.PublicarVeiculo.dadosPortal.cilindradas,
                        isLoading: false,
                        results: action.results
                    }
                }
            }
        };
    }
    if (action.type === FAILURE_CILINDRADAS_PORTAL_ACTION) {
        return {
            ...state,
            PublicarVeiculo: {
                ...state.PublicarVeiculo,
                dadosPortal: {
                    ...state.PublicarVeiculo.dadosPortal,
                    cilindradas: {
                        ...state.PublicarVeiculo.dadosPortal.cilindradas,
                        isLoading: false,
                        errorText: action.erroText
                    }
                }
            }
        };
    }

    if (action.type === POST_PUBLICACAO_ACTION) {
        return {
            ...state,
            PublicarVeiculo: {
                ...state.PublicarVeiculo,
                isLoading: true,
                erroText: ''
            }
        };
    }
    if (action.type === PUT_PUBLICACAO_ACTION) {
        return {
            ...state,
            PublicarVeiculo: {
                ...state.PublicarVeiculo,
                isLoading: true,
                errorText: ''
            }
        };
    }
    if (action.type === DELETE_PUBLICACAO_ACTION) {
        return {
            ...state,
            PublicarVeiculo: {
                ...state.PublicarVeiculo,
                isLoading: true,
                successText: '',
                errorText: ''
            }
        };
    }
    if (action.type === VISUALIZAR_RECOMENDACAO_ACTION) {
       
        return {
            ...state,
            PublicarVeiculo: {
                ...state.PublicarVeiculo,
                isLoading: true,
                successText: '',
                errorText: ''
            }
        };
    }
    if (action.type === SUCCESS_VISUALIZAR_RECOMENDACAO_ACTION) {
        var listVeiculos = state.ListaVeiculos.results;
        listVeiculos.map(function (veiculo, indexVeiculo) {
            if (veiculo.id === state.PublicarVeiculo.idVeiculo) {
                state.ListaVeiculos.results[indexVeiculo].portais.map(function (portal, indexPortal) {
                    if (portal.idParceiroAnuncio === state.PublicarVeiculo.IdPublicador) {
                        listVeiculos[indexVeiculo].portais[indexPortal].qualidadeAnuncioStatus = action.response.qualidadeAnuncioStatus;
                    }
                });
            }
        });

        return {
            ...state,
            PublicarVeiculo: {
                ...state.PublicarVeiculo,
                isLoading: false,
                successText: action.response.message,
                anuncio: {
                    ...state.PublicarVeiculo.anuncio,
                     qualidadeAnuncioStatus: action.response.qualidadeAnuncioStatus
                }
            }
        };
    }
    if (action.type === FAILURE_VISUALIZAR_RECOMENDACAO_ACTION) {
        return {
            ...state,
            PublicarVeiculo: {
                ...state.PublicarVeiculo,
                isLoading: false,
                errorText: action.errorText
            }
        };
    }
    if (action.type === SUCCESS_POST_PUBLICACAO_ACTION) {
        var listVeiculos = state.ListaVeiculos.results;
        listVeiculos.map(function (veiculo, indexVeiculo) {
            if (veiculo.id === state.PublicarVeiculo.idVeiculo) {
                state.ListaVeiculos.results[indexVeiculo].portais.map(function (portal, indexPortal) {
                    if (portal.idParceiroAnuncio === state.PublicarVeiculo.IdPublicador) {
                        listVeiculos[indexVeiculo].portais[indexPortal].idAnuncio = action.response.idAnuncio;
                        listVeiculos[indexVeiculo].portais[indexPortal].urlAnuncio = action.response.urlAnuncio;
                    }
                });
            }
        });

        return {
            ...state,
            PublicarVeiculo: {
                ...state.PublicarVeiculo,
                isLoading: false,
                successText: 'Veículo anunciado com sucesso',
                anuncio: {
                    ...state.PublicarVeiculo.anuncio,
                    idAnuncioBNDV: action.response.idAnuncio
                }
            },
            ListaVeiculos: {
                ...state.ListaVeiculos,
                results: listVeiculos
            }
        };
    }
    if (action.type === SUCCESS_DELETE_PUBLICACAO_ACTION) {
        var listVeiculos = state.ListaVeiculos.results;
        listVeiculos.map(function (veiculo, indexVeiculo) {
            if (veiculo.id === state.PublicarVeiculo.idVeiculo) {
                state.ListaVeiculos.results[indexVeiculo].portais.map(function (portal, indexPortal) {
                    if (portal.idParceiroAnuncio === state.PublicarVeiculo.IdPublicador) {
                        listVeiculos[indexVeiculo].portais[indexPortal].idAnuncio = null;
                        listVeiculos[indexVeiculo].portais[indexPortal].urlAnuncio = "";
                    }
                });
            }
        });

        return {
            ...state,
            PublicarVeiculo: {
                ...state.PublicarVeiculo,
                isLoading: false,
                successText: action.successText,
                anuncio: {
                    ...state.PublicarVeiculo.anuncio,
                    idAnuncioBNDV: 0
                }
            },
            ListaVeiculos: {
                ...state.ListaVeiculos,
                results: listVeiculos
            }
        };
    }
    if (action.type === SUCCESS_PUT_PUBLICACAO_ACTION) {
        return {
            ...state,
            PublicarVeiculo: {
                ...state.PublicarVeiculo,
                isLoading: false,
                successText: 'Anúncio alterado com sucesso'
            }
        };
    }
    if (action.type === FAILURE_PUBLICACAO_ACTION) {
        return {
            ...state,
            PublicarVeiculo: {
                ...state.PublicarVeiculo,
                isLoading: false,
                errorText: action.errorText
            }
        };
    }
    if (action.type === POST_PUBLICACAOMOTO_ACTION) {
        return {
            ...state,
            PublicarVeiculo: {
                ...state.PublicarVeiculo,
                isLoading: true,
                erroText: ''
            }
        };
    }
    if (action.type === SUCCESS_POST_PUBLICACAOMOTO_ACTION) {
        var listVeiculos = state.ListaVeiculos.results;
        listVeiculos.map(function (veiculo, indexVeiculo) {
            if (veiculo.id === state.PublicarVeiculo.idVeiculo) {
                state.ListaVeiculos.results[indexVeiculo].portais.map(function (portal, indexPortal) {
                    if (portal.idParceiroAnuncio === state.PublicarVeiculo.IdPublicador) {
                        listVeiculos[indexVeiculo].portais[indexPortal].idAnuncio = action.response.idAnuncio;
                        listVeiculos[indexVeiculo].portais[indexPortal].urlAnuncio = action.response.urlAnuncio;
                    }
                });
            }
        });

        return {
            ...state,
            PublicarVeiculo: {
                ...state.PublicarVeiculo,
                isLoading: false,
                successText: 'Moto anunciada com sucesso',
                anuncio: {
                    ...state.PublicarVeiculo.anuncio,
                    idAnuncioBNDV: action.response.idAnuncio
                }
            },
            ListaVeiculos: {
                ...state.ListaVeiculos,
                results: listVeiculos
            }
        };
    }
    if (action.type === FAILURE_PUBLICACAOMOTO_ACTION) {
        return {
            ...state,
            PublicarVeiculo: {
                ...state.PublicarVeiculo,
                isLoading: false,
                errorText: action.errorText
            }
        };
    }
    if (action.type === PUT_PUBLICACAOMOTO_ACTION) {
        return {
            ...state,
            PublicarVeiculo: {
                ...state.PublicarVeiculo,
                isLoading: true,
                errorText: ''
            }
        };
    }
    if (action.type === DELETE_PUBLICACAOMOTO_ACTION) {
        return {
            ...state,
            PublicarVeiculo: {
                ...state.PublicarVeiculo,
                isLoading: true,
                successText: '',
                errorText: ''
            }
        };
    }
    if (action.type === SUCCESS_DELETE_PUBLICACAOMOTO_ACTION) {
        var listVeiculos = state.ListaVeiculos.results;
        listVeiculos.map(function (veiculo, indexVeiculo) {
            if (veiculo.id === state.PublicarVeiculo.idVeiculo) {
                state.ListaVeiculos.results[indexVeiculo].portais.map(function (portal, indexPortal) {
                    if (portal.idParceiroAnuncio === state.PublicarVeiculo.IdPublicador) {
                        listVeiculos[indexVeiculo].portais[indexPortal].idAnuncio = null;
                        listVeiculos[indexVeiculo].portais[indexPortal].urlAnuncio = "";
                    }
                });
            }
        });

        return {
            ...state,
            PublicarVeiculo: {
                ...state.PublicarVeiculo,
                isLoading: false,
                successText: action.successText,
                anuncio: {
                    ...state.PublicarVeiculo.anuncio,
                    idAnuncioBNDV: 0
                }
            },
            ListaVeiculos: {
                ...state.ListaVeiculos,
                results: listVeiculos
            }
        };
    }
    if (action.type === SUCCESS_PUT_PUBLICACAOMOTO_ACTION) {
        return {
            ...state,
            PublicarVeiculo: {
                ...state.PublicarVeiculo,
                isLoading: false,
                successText: 'Anúncio alterado com sucesso'
            }
        };
    }
    return state;
};

