import * as React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Row } from 'react-bootstrap';
import { actionCreators } from '../../../store/dashboard/DashboardAdministrador';
import LeadsEmAbertoPorEtapa from './LeadsEmAbertoPorEtapa';
import LeadsEmAbertoPorUsuario from './LeadsEmAbertoPorUsuario';
import LeadsPorCanalMesAtual from './LeadsPorCanalMesAtual';
import LeadsPorStatusMesAtual from './LeadsPorStatusMesAtual';
import LeadsMotivosPerdaMesAtual from './LeadsMotivosPerdaMesAtual';

class DashboardAdministradorCRMForm extends React.Component {
    render() {
        return (
            this.props.dashboardadministrador.empresaSelected > 0
                ?
                <Row>
                    <div className="col-md-6">
                        <LeadsEmAbertoPorEtapa
                            authorizationToken={this.props.auth.authorizationToken}
                            empresaSelected={this.props.dashboardadministrador.empresaSelected}
                        />
                    </div>
                    <div className="col-md-6">
                        <LeadsPorCanalMesAtual
                            authorizationToken={this.props.auth.authorizationToken}
                            empresaSelected={this.props.dashboardadministrador.empresaSelected}
                        />
                    </div>
                    <div className="col-md-12">
                        <LeadsPorStatusMesAtual
                            authorizationToken={this.props.auth.authorizationToken}
                            empresaSelected={this.props.dashboardadministrador.empresaSelected}
                        />
                    </div>
                    <div className="col-md-12">
                        <LeadsEmAbertoPorUsuario
                            authorizationToken={this.props.auth.authorizationToken}
                            empresaSelected={this.props.dashboardadministrador.empresaSelected}
                        />
                    </div>
                    <div className="col-md-12">
                        <LeadsMotivosPerdaMesAtual
                            authorizationToken={this.props.auth.authorizationToken}
                            empresaSelected={this.props.dashboardadministrador.empresaSelected}
                        />
                    </div>
                </Row>
                : null
        );
    }
}

function mapStateToProps(state) {
    return {
        auth: state.auth,
        dashboardadministrador: state.dashboardadministrador
    }
}

export default connect(
    mapStateToProps,
    dispatch => bindActionCreators(actionCreators, dispatch),
    null,
    { pure: false }
)(DashboardAdministradorCRMForm);