import * as React from "react";
import MaskedInputLib from 'react-maskedinput';

export default class MaskedInput extends React.Component {
    constructor() {
        super();
        this.state = { name: '', value: '' }
    }

    componentWillMount() {
        this.setState({ name: this.props.name, value: this.props.value });
    }

    componentWillReceiveProps(nextProps) {
        this.setState({ name: this.props.name, value: nextProps.value });
    }

    _onChange(e) {
        this.setState({ name: e.target.name, value: e.target.value });
        this.props.onChange(e.target.name, e.target.value);
    }

    _onBlur(e) {
        this.setState({ name: e.target.name, value: e.target.value });
        this.props.onBlur(e.target.name, e.target.value);
    }

    render() {
        return <MaskedInputLib
            mask={this.props.mask}
            placeholder={this.props.placeholder}
            placeholderChar={this.props.placeholderChar}
            size={this.props.size}
            className={this.props.className}
            value={this.state.value}
            onChange={(this.props.onChange == undefined) ? null : this._onChange.bind(this)}
            onBlur={(this.props.onBlur == undefined) ? null : this._onBlur.bind(this)}
            required={this.props.required ? true : false}
            data-parsley-required-message={this.props.requiredErrorText}
            minLength={this.props.minlength}
            data-parsley-minlength-message={this.props.minlenghtErrorText}
        />;
    }
}