import { applyMiddleware, compose, createStore } from 'redux';
import { persistCombineReducers } from 'redux-persist';
import storage from 'localforage';
import thunk from 'redux-thunk';
import { routerReducer, routerMiddleware } from 'react-router-redux';
import * as Login from './login';
import * as NavMenu from './template/navmenu';
import * as DashboardAdministrador from './dashboard/DashboardAdministrador';
import * as HistoricoPagamentos from './analiserisco/HistoricoPagamentos';
import * as SolicitarPagamentos from './analiserisco/SolicitarPagamentos';
import * as BNDVCompleta from './analiserisco/BNDVCompleta';
import * as BNDVCompletaRF from './analiserisco/BNDVCompletaRF';
import * as marketing_publicador from './marketing/publicador/MarketingPublicador';
import * as marketing_publicadorconfig from './marketing/publicador/MarketingPublicadorConfig';
import * as edoc_consulta from './edoc/EdocConsulta';
import * as edoc_configuracao from './edoc/EdocConfiguracao';
import * as daypag_consulta from './documentos/daypag/DaypagConsulta';
import * as daypag_historico from './documentos/daypag/DaypagHistorico';
import * as crm_configuracao from './crm/CrmConfiguracao';
import * as crm_consulta from './crm/CrmConsulta';
import * as crm_webmotors from './crm/CrmWebMotors';

export default function configureStore(history, initialState) {
    const config = {
        key: 'bndv',
        storage,
        blacklist: [
            'routing',
            'historicopagamentos',
            'dashboardadministrador',
            'solicitarpagamentos',
            'bndvcompleta',
            'bndvcompletarf',
            'marketing_publicador',
            'marketing_publicadorconfig',
            'edoc_consulta',
            'edoc_configuracao',
            'daypag_consulta',
            'daypag_historico',
            'crm_configuracao',
            'crm_consulta',
            'crm_webmotors'
        ]
    };

    const reducers = {
        auth: Login.reducer,
        navmenu: NavMenu.reducer,
        dashboardadministrador: DashboardAdministrador.reducer,
        historicopagamentos: HistoricoPagamentos.reducer,
        solicitarpagamentos: SolicitarPagamentos.reducer,
        bndvcompleta: BNDVCompleta.reducer,
        bndvcompletarf: BNDVCompletaRF.reducer,
        marketing_publicador: marketing_publicador.reducer,
        marketing_publicadorconfig: marketing_publicadorconfig.reducer,
        edoc_consulta: edoc_consulta.reducer,
        edoc_configuracao: edoc_configuracao.reducer,
        daypag_consulta: daypag_consulta.reducer,
        daypag_historico: daypag_historico.reducer,
        crm_configuracao: crm_configuracao.reducer,
        crm_consulta: crm_consulta.reducer,
        crm_webmotors: crm_webmotors.reducer
    };

    const middleware = [
        thunk,
        routerMiddleware(history)
    ];

    // In development, use the browser's Redux dev tools extension if installed
    const enhancers = [];
    const isDevelopment = process.env.NODE_ENV === 'development';
    if (isDevelopment && typeof window !== 'undefined' && window.devToolsExtension) {
        enhancers.push(window.devToolsExtension());
    }

    //const rootReducer = combineReducers({
    //    ...reducers,
    //    routing: routerReducer
    //});

    let rootReducer = persistCombineReducers(config, { ...reducers, routing: routerReducer })

    const store =  createStore(
        rootReducer,
        initialState,
        compose(applyMiddleware(...middleware), ...enhancers)
    );

    return store;
}
