import * as React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actionCreators } from '../../../../store/crm/CrmConfiguracao';

import Formsy from 'formsy-react';
import { Row, Modal } from 'react-bootstrap';
import ProgerssBar from '../../../commons/ProgressBar';
import AlertError from '../../../commons/AlertError';
import AlertSuccess from '../../../commons/AlertSuccess';
import InputTextValidated from '../../../commons/InputTextValidated';
import BtnSalvar from '../../../commons/BtnSalvar';
import BtnCancelar from '../../../commons/BtnCancelar';
import OauthPopup from 'react-oauth-popup';

class ModalOLX extends React.Component {
    constructor() {
        super();
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    async componentDidMount() {
        await this.props.fnObterEmpresaCanalLead();

        if (this.props.configuracaoPortais.modalPortais.ativado) {
            this.props.configuracaoPortais.modalPortais.modalOLX.email = this.props.configuracaoPortais.modalPortais.email;
            this.props.configuracaoPortais.modalPortais.modalOLX.token = JSON.parse(this.props.configuracaoPortais.modalPortais.jsonAutenticacao).token;
            await this.props.fnUpdateState(this.props);
        }
        else {
            this.props.configuracaoPortais.modalPortais.modalOLX.email = '';
            this.props.configuracaoPortais.modalPortais.modalOLX.token = '';
            await this.props.fnUpdateState(this.props);
        }
    }

    async handleSubmit(e) {
        var autenticacao = {
            token: this.props.configuracaoPortais.modalPortais.modalOLX.token
        };

        this.props.configuracaoPortais.modalPortais.email = this.props.configuracaoPortais.modalPortais.modalOLX.email;
        this.props.configuracaoPortais.modalPortais.jsonAutenticacao = JSON.stringify(autenticacao);;
        await this.props.fnUpdateState(this.props);

        if (!this.props.configuracaoPortais.modalPortais.ativado) {
            await this.props.fnInsereEmpresaCanalLead();
        }
        else {
            await this.props.fnAtualizaEmpresaCanalLead();
        }
    }

    handleOnHide() {
        this.props.configuracaoPortais.modalPortais.modalOLX.showModal = false;
        this.props.fnUpdateState(this.props);
    }

    render() {
        return <Modal
            show={this.props.configuracaoPortais.modalPortais.modalOLX.showModal}
            onHide={this.handleOnHide.bind(this)}
        >
            <Formsy onValidSubmit={this.handleSubmit}>
                <Modal.Header closeButton>
                    <h4 className="modal-title custom-font">Configurar OLX</h4>
                </Modal.Header>
                <Modal.Body>
                    <AlertSuccess
                        successText={this.props.configuracaoPortais.modalPortais.successText}
                        alertVisible={this.props.configuracaoPortais.modalPortais.successText != '' && this.props.configuracaoPortais.modalPortais.successText != undefined ? true : false}
                        onDismiss={(alertVisible, successText) => {
                            this.props.configuracaoPortais.modalPortais.successText = successText;
                            this.props.fnUpdateState(this.props);
                        }}
                    />
                    <AlertError
                        errorText={this.props.configuracaoPortais.modalPortais.errorText}
                        alertVisible={this.props.configuracaoPortais.modalPortais.errorText != '' && this.props.configuracaoPortais.modalPortais.errorText != undefined ? true : false}
                        onDismiss={(alertVisible, errorText) => {
                            this.props.configuracaoPortais.modalPortais.errorText = errorText;
                            this.props.fnUpdateState(this.props);
                        }}
                    />
                    {
                        this.props.configuracaoPortais.modalPortais.isLoading
                            ? <ProgerssBar />
                            : <Row>
                                <InputTextValidated
                                    name="txtEmail"
                                    type="text"
                                    className="col-md-12"
                                    placeholder="Informe o email"
                                    onChange={(name, value) => {
                                        this.props.configuracaoPortais.modalPortais.modalOLX.email = value;
                                        this.props.fnUpdateState(this.props);
                                    }}
                                    value={this.props.configuracaoPortais.modalPortais.modalOLX.email}
                                    title="Email:"
                                    validations="isEmail"
                                    validationError="Informe um email válido"
                                    required
                                />
                                {/*<InputTextValidated
                                    name="txtToken"
                                    type="text"
                                    className="col-md-12"
                                    placeholder="Informe o access token"
                                    onChange={(name, value) => {
                                        this.props.configuracaoPortais.modalPortais.modalOLX.token = value;
                                        this.props.fnUpdateState(this.props);
                                    }}
                                    value={this.props.configuracaoPortais.modalPortais.modalOLX.token}
                                    title="Token:"
                                    required
                                />*/}
                                {//this.props.configuracaoPortais.modalPortais.ativado == false
                                    1==2
                                    ? <div className="form-group col-md-12">
                                        <OauthPopup
                                            url="https://auth.olx.com.br/oauth/authorize?client_id=f288d122f396f1d7cae24ecba519fec3bc2d54d5&redirect_uri=http%3A%2F%2Fsistema.bndv.com.br%2FretornoBN.aspx&response_type=code&scope=basic_user_info+autoupload&state=bla"
                                            onCode={(access_token) => { console.log(access_token); }}
                                        >
                                            <button
                                                type="button"
                                                className="btn btn-primary btn-lg btn-block">Autorizar Aplicativo do BNDV no OLX</button>
                                        </OauthPopup>
                                    </div>
                                    : null}
                            </Row>
                    }
                </Modal.Body>
                <Modal.Footer>
                    {this.props.configuracaoPortais.modalPortais.ativado == true
                        ? <BtnCancelar
                            type="button"
                            text=" Remover Portal"
                            textLoading=" Aguarde..."
                            isLoading={this.props.configuracaoPortais.modalPortais.isLoading}
                            onClick={async () => {
                                await this.props.fnDeleteEmpresaCanalLead();
                                this.props.configuracaoPortais.modalPortais.modalOLX.showModal = false;
                                this.props.fnUpdateState(this.props);
                            }}
                        />
                        : null}
                    <BtnSalvar
                        type="submit"
                        text={this.props.configuracaoPortais.modalPortais.ativado == false ? " Salvar Configuração" : " Alterar Configuração"}
                        textLoading=" Aguarde..."
                        isLoading={this.props.configuracaoPortais.modalPortais.isLoading}
                    />
                </Modal.Footer>
            </Formsy>
        </Modal>;
    }
}

export default connect(
    state => state.crm_configuracao,
    dispatch => bindActionCreators(actionCreators, dispatch),
    null, {}
)(ModalOLX);