import * as React from 'react';
import { fetch, addTask } from 'domain-task';
import { Chart } from 'react-google-charts';
import * as dashboard_service from '../../../services/dashboard/dashboard_service';
import Section from '../../commons/Section';
import SectionHeader from '../../commons/SectionHeader';
import QtdeVeiculosCompradosPorCompradorTrimestre from './QtdeVeiculosCompradosPorCompradorTrimestre';

export default class LeadsEmAbertoPorUsuario extends React.Component {
    constructor() {
        super();
        this.state = { data: [] };
    }

    componentDidMount() {
        this.loadData(this.props.authorizationToken, this.props.empresaSelected);
    }

    componentWillReceiveProps(nextProps) {
        this.loadData(nextProps.authorizationToken, nextProps.empresaSelected);
    }

    loadData(authorizationToken, empresaSelected) {
        let fetchTask = fetch(dashboard_service.services.Administrador.LeadsEmAbertoPorUsuario(authorizationToken, empresaSelected))
            .then(response => {
                if (response.status === 200 || response.status === 0) {
                    return response.json();
                }
                else {
                    return response.json().then(function (object) {
                        return Promise.reject(new Error(object));
                    })
                }
            })
            .then(data => {
                var arrData = [];
                arrData.push(["Vendedor", "Oportunidade", "Em Contato", "Negociação", "Venda"]);

                var qtdeEmOportunidade = 0;
                var qtdeEmContato = 0;
                var qtdeEmNegociacao = 0;
                var qtdeEmVenda = 0;
                var vendedorAtual = data[0].nomeUsuario;
                var countArray = 0;
                data.forEach(function (entry) {
                    countArray++;
                    if (entry.nomeFunilVendas == "Oportunidade") {
                        qtdeEmOportunidade = entry.qtde;
                    }
                    if (entry.nomeFunilVendas == "Venda") {
                        qtdeEmVenda = entry.qtde;
                    }
                    if (entry.nomeFunilVendas == "Em contato") {
                        qtdeEmContato = entry.qtde;
                    }
                    if (entry.nomeFunilVendas == "Negociação") {
                        qtdeEmNegociacao = entry.qtde;
                    }

                    if (vendedorAtual != entry.nomeUsuario) {
                        arrData.push([vendedorAtual, qtdeEmOportunidade, qtdeEmContato, qtdeEmNegociacao, qtdeEmVenda]);
                        qtdeEmOportunidade = 0;
                        qtdeEmContato = 0;
                        qtdeEmNegociacao = 0;
                        qtdeEmVenda = 0;

                        if (entry.nomeFunilVendas == "Oportunidade") {
                            qtdeEmOportunidade = entry.qtde;
                        }
                        if (entry.nomeFunilVendas == "Venda") {
                            qtdeEmVenda = entry.qtde;
                        }
                        if (entry.nomeFunilVendas == "Em contato") {
                            qtdeEmContato = entry.qtde;
                        }
                        if (entry.nomeFunilVendas == "Negociação") {
                            qtdeEmNegociacao = entry.qtde;
                        }

                        vendedorAtual = entry.nomeUsuario;
                    }

                    if (countArray == data.length) {
                        arrData.push([entry.nomeUsuario, qtdeEmOportunidade, qtdeEmContato, qtdeEmNegociacao, qtdeEmVenda]);
                    }
                });

                this.setState({ data: arrData });
            }).catch(function (err) {
                console.log(err);
            });

        addTask(fetchTask);
    }

    render() {
        return (
            <Section>
                <SectionHeader title="Leads em Aberto por Usuário" />
                <div className="tile-body">
                    <Chart
                        chartType="ColumnChart"
                        data={this.state.data}
                        options={
                            {

                                "bar": {
                                    "groupWidth": "65%"
                                },
                                "legend": {
                                    "position": "top"
                                },
                                "seriesType": "bars",
                                "chartArea": {
                                    "left": 30,
                                    "top": 20,
                                    "width": "100%",
                                    "height": "70%",
                                    "backgroundColor": {
                                        "stroke": "#cccccc",
                                        "strokeWidth": 1
                                    }
                                },
                                "fontName": "Verdana",
                                "hAxis": {
                                    "slantedText": true,
                                    "slantedTextAngle": 60,
                                    "gridlines": {
                                        "count": -1
                                    }
                                },
                                "vAxis": {
                                    "format": "####"
                                },
                            }
                        }
                        width="100%"
                        legend_toggle
                    />
                </div>
            </Section>
        );
    }
}