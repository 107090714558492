import * as React from 'react';
import HeaderPage from '../../components/commons/HeaderPage';
import ConfiguracaoForm from '../../components/crm/configuracao/ConfiguracaoForm';

export default class Crm_Configuracao extends React.Component {
    render() {
        return <div className='page'>
            <HeaderPage
                path=' CRM'
                name='Configurações' />
            <ConfiguracaoForm />
        </div>;
    }
}