import * as React from "react";

export default class ListPager extends React.Component {
    render() {
        var li = [];
        var pageCount = this.props.Size;
        var PageMin = 1;
        var PageMax = 5;

        // Verify left button
        if (pageCount > 0) {
            if (this.props.CurrentPage <= 1)
                li.push(<li key={-1} className="paginate_button previous disabled"><a data-dt-idx="0" tabIndex={0} style={{ cursor: 'pointer' }}>Anterior</a></li>);
            else
                li.push(<li key={-1} className="paginate_button previous"><a data-dt-idx="0" tabIndex={0} style={{ cursor: 'pointer' }} onClick={this.props.OnPageChanged.bind(null, this.props.CurrentPage - 1)}>Anterior</a></li>);
        }

        // Create intermediate pags if necessary
        if (this.props.CurrentPage > 3) {
            if (pageCount - this.props.CurrentPage === 1) {
                PageMax = this.props.CurrentPage + 1;
                PageMin = this.props.CurrentPage - 3;
            }
            else if (pageCount - this.props.CurrentPage === 0) {
                PageMax = this.props.CurrentPage;
                if (this.props.CurrentPage === 4)
                    PageMin = this.props.CurrentPage - 3;
                else
                    PageMin = this.props.CurrentPage - 4;
            }
            else {
                PageMax = this.props.CurrentPage + 2;
                PageMin = this.props.CurrentPage - 2;
            }
        } else {
            if (pageCount < 5)
                PageMax = pageCount;
        }

        for (var i = PageMin; i <= PageMax; i++) {
            if (this.props.CurrentPage === i) {
                li.push(<li key={i} className="paginate_button active"><a data-dt-idx="1" tabIndex={0} style={{ cursor: 'pointer' }}>{i}</a></li>);
            }
            else {
                li.push(<li key={i} className="paginate_button"><a data-dt-idx="1" tabIndex={0} style={{ cursor: 'pointer' }} onClick={this.props.OnPageChanged.bind(null, i)}>{i}</a></li>);
            }
        }

        // Verify right button
        if (pageCount > 0) {
            if (this.props.CurrentPage === pageCount)
                li.push(<li key={pageCount + 1} className="paginate_button next disabled"><a data-dt-idx="2" tabIndex={0} style={{ cursor: 'pointer' }}>Próximo</a></li>);
            else
                li.push(<li key={pageCount + 1} className="paginate_button next"><a data-dt-idx="2" tabIndex={0} style={{ cursor: 'pointer' }} onClick={this.props.OnPageChanged.bind(null, this.props.CurrentPage + 1)}>Próximo</a></li>);
        }

        return (<ul className="pagination">{li}</ul>);
    }
}