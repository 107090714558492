import * as React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actionCreators } from '../../../store/analiserisco/BNDVCompleta';

import { Modal, Row } from 'react-bootstrap';
import AlertDismissable from '../../commons/AlertDismissable';
import BtnSalvar from '../../commons/BtnSalvar';
import BtnCancelar from '../../commons/BtnCancelar';
import InputSelect from '../../commons/InputSelect';
import Section from '../../commons/Section';
import SectionHeader from '../../commons/SectionHeader';
import MaskedInput from '../../commons/MaskedInput';

class BNDVCompletaSolicitacaoForm extends React.Component {
    constructor() { super(); }

    solicitarPesquisaClick() {
        if (this.props.SolicitarPesquisa.Chassi === '' || this.props.SolicitarPesquisa.Placa === '') {
            this.props.SolicitarPesquisa.showModalSolicitacao = true;
            this.props.fnUpdateState(this.props);
        }
        else {
            this.props.fnSolicitarPesquisa();
        }
    }

    render() {
        return <Row>
            <div className="col-md-12">
                <Section>
                    <AlertDismissable
                        title="Ops algo deu errado!"
                        text={this.props.ErrorText}
                        show={this.props.ErrorText != '' && this.props.ErrorText != undefined ? true : false}
                        bsStyle="danger"
                    />
                    <AlertDismissable
                        title="Atenção!"
                        text={<p>A pesquisa <strong>BNDV Completa</strong> retorna os dados do veículo, débitos, restrições, gravame, comunicação de vendas, registros de ofertas em leilão e um ítem exclusivo que informa o risco de comercialização do veículo. Tudo isso por apenas <strong>R$ 7,90</strong>. Aproveite e deixe suas negociações mais seguras.</p>}
                        show={true}
                        bsStyle="warning"
                    />
                    <SectionHeader title="Informe os dados para solicitar a pesquisa" />
                    <div className="tile-body">
                        <Row>
                            <div className="form-group col-md-4">
                                <label>Placa</label>
                                <MaskedInput
                                    mask="#######"
                                    placeholder="XXXXXXX"
                                    placeholderChar=" "
                                    className="form-control"
                                    onChange={(name, value) => { this.props.SolicitarPesquisa.Placa = value; this.props.fnUpdateState(this.props); }}
                                    value={this.props.SolicitarPesquisa.Placa}
                                />
                            </div>
                            <div className="form-group col-md-4">
                                <label>Chassi</label>
                                <MaskedInput
                                    mask="#################"
                                    placeholder="9BD781CD87TGD9876"
                                    placeholderChar=" "
                                    className="form-control"
                                    onChange={(name, value) => { this.props.SolicitarPesquisa.Chassi = value; this.props.fnUpdateState(this.props); }}
                                    value={this.props.SolicitarPesquisa.Chassi}
                                />
                            </div>
                            <div className="form-group col-md-4">
                                <label>UF</label>
                                <InputSelect class="form-control mt-4"
                                    data={
                                        [
                                            { value: '', text: '' }
                                            , { value: 'AC', text: 'AC' }
                                            , { value: 'AL', text: 'AL' }
                                            , { value: 'AP', text: 'AP' }
                                            , { value: 'AM', text: 'AM' }
                                            , { value: 'BA', text: 'BA' }
                                            , { value: 'CE', text: 'CE' }
                                            , { value: 'DF', text: 'DF' }
                                            , { value: 'ES', text: 'ES' }
                                            , { value: 'GO', text: 'GO' }
                                            , { value: 'MA', text: 'MA' }
                                            , { value: 'MT', text: 'MT' }
                                            , { value: 'MS', text: 'MS' }
                                            , { value: 'MG', text: 'MG' }
                                            , { value: 'PA', text: 'PA' }
                                            , { value: 'PB', text: 'PB' }
                                            , { value: 'PR', text: 'PR' }
                                            , { value: 'PE', text: 'PE' }
                                            , { value: 'PI', text: 'PI' }
                                            , { value: 'RJ', text: 'RJ' }
                                            , { value: 'RN', text: 'RN' }
                                            , { value: 'RS', text: 'RS' }
                                            , { value: 'RO', text: 'RO' }
                                            , { value: 'RR', text: 'RR' }
                                            , { value: 'SC', text: 'SC' }
                                            , { value: 'SP', text: 'SP' }
                                            , { value: 'SE', text: 'SE' }
                                            , { value: 'TO', text: 'TO' }
                                        ]
                                    }
                                    onChange={(name, value) => { this.props.SolicitarPesquisa.UF = value; this.props.fnUpdateState(this.props); }}
                                    selectedValue={(this.props.SolicitarPesquisa.UF == null) ? null : this.props.SolicitarPesquisa.UF}
                                />
                            </div>
                            <div className="form-group col-md-12 text-right">
                                <BtnSalvar
                                    type="button"
                                    text=" Solicitar Pesquisa"
                                    textLoading=" Aguarde solicitando pesquisa..."
                                    IsLoading={this.props.SolicitarPesquisa.IsLoading}
                                    onClick={this.solicitarPesquisaClick.bind(this)}
                                />
                            </div>
                        </Row>
                    </div>
                </Section>
            </div>
            <Modal show={this.props.SolicitarPesquisa.showModalSolicitacao}>
                <Modal.Header>
                    <h3 className="modal-title custom-font">Confirmação</h3>
                </Modal.Header>
                <Modal.Body>Prezado cliente, informamos que a pesquisa terá maior confiabilidade caso sejam informados os dois campos (PLACA e CHASSI), deseja continuar mesmo sem informar os dois campos?</Modal.Body>
                <Modal.Footer>
                    <BtnSalvar
                        type="button"
                        text=" Confirmar Solicitação"
                        textLoading=" Aguarde..."
                        isLoading={this.props.SolicitarPesquisa.IsLoading}
                        onClick={() => { this.props.fnSolicitarPesquisa(); }}
                    />
                    <BtnCancelar
                        type="button"
                        text=" Cancelar"
                        textLoading=" Aguarde..."
                        onClick={() => { this.props.SolicitarPesquisa.showModalSolicitacao = false; this.props.fnUpdateState(this.props); }}
                        isLoading={this.props.SolicitarPesquisa.IsLoading}
                    />
                </Modal.Footer>
            </Modal>
        </Row>;
    }
}

export default connect(
    state => state.bndvcompleta,
    dispatch => bindActionCreators(actionCreators, dispatch),
    null,
    { pure: false }
)(BNDVCompletaSolicitacaoForm);