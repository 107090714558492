import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actionCreators } from '../store/login';

class Login extends Component {
    async componentDidMount() {
        var cnpj = this.getParameterByName('cnpjEmpresa', null);
        var login = this.getParameterByName('loginAgente', null);
        var senha = this.getParameterByName('senhaAgente', null);

        await this.props.requestLogin(login, senha, cnpj);

        if (this.props.IsAuthenticated) {
            let { history } = this.props;
            history.push({ pathname: '/', search: '' });
        }
    }

    getParameterByName(name, url) {
        if (!url) url = window.location.href;
        name = name.replace(/[\[\]]/g, "\\$&");
        var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
            results = regex.exec(url);
        if (!results) return null;
        if (!results[2]) return '';
        return decodeURIComponent(results[2].replace(/\+/g, " "));
    }

    render() {
        return (
            <div className="page page-core page-404">
                <div className="container w-420 p-15 bg-white mt-40 text-center">
                    <h2 className="text-light text-blue"><strong>BNDV</strong></h2>
                    <h4 className="mb-0 mt-40">Bem vindo ao BNDV 2.0</h4>
                    {
                        this.props.ErrorText === ''
                            ? <p className="text-muted mt-10">Aguarde estamos efetuando o login no sistema...</p>
                            : <div className="alert alert-big alert-lightred alert-dismissable fade in mt-10">
                                <h4><strong>Ops!</strong> <em>Temos um problema</em></h4>
                                <p>{this.props.ErrorText}</p>
                            </div>
                    }
                </div>
            </div>
        );
    }
}

export default connect(
    state => state.auth,
    dispatch => bindActionCreators(actionCreators, dispatch)
)(Login);
