import * as React from 'react';

export default class ProgressBar extends React.Component {
    render() {
        return <div className="progress progress-striped active">
            <div
                className="progress-bar progress-bar-blue"
                role="progressbar"
                aria-valuenow="100"
                aria-valuemin="0"
                aria-valuemax="100"
                style={{ width: "100%" }}>
                <span className="sr-only">100% Complete</span>
            </div>
        </div>;
    }
}