import * as React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actionCreators } from '../../../store/crm/CrmConsulta';

import Moment from 'react-moment';
import { Modal, Row } from 'react-bootstrap';
import AlertError from '../../commons/AlertError';
import AlertSuccess from '../../commons/AlertSuccess';
import ProgerssBar from '../../commons/ProgressBar';

class ModalDetalhes extends React.Component {
    constructor(props) {
        super(props);
    }

    async componentDidMount() {
        await this.props.fnObterOportunidade();
        this.props.fnListaAtividadesOpoetunidade();
    }

    handleOnHide() {
        this.props.modalDetalhes.showModal = false;
        this.props.fnUpdateState(this.props);
    }

    render() {
        return (<Modal
            show={this.props.modalDetalhes.showModal}
            onHide={this.handleOnHide.bind(this)}
            bsSize="large">
            <form
                id="frm"
                name="frm"
                role="form"
                onSubmit={this.handleSubmit}
                data-parsley-validate>
                <Modal.Header closeButton>
                    <h4 className="modal-title custom-font">Detalhes da Oportunidade</h4>
                </Modal.Header>
                <Modal.Body>
                    <AlertSuccess
                        successText={this.props.modalDetalhes.successText}
                        alertVisible={this.props.modalDetalhes.successText != '' && this.props.modalDetalhes.successText != undefined ? true : false}
                        onDismiss={(alertVisible, successText) => {
                            this.props.modalDetalhes.successText = successText;
                            this.props.fnUpdateState(this.props);
                        }}
                    />
                    <AlertError
                        errorText={this.props.modalDetalhes.errorText}
                        alertVisible={this.props.modalDetalhes.errorText != '' && this.props.modalDetalhes.errorText != undefined ? true : false}
                        onDismiss={(alertVisible, errorText) => {
                            this.props.modalDetalhes.errorText = errorText;
                            this.props.fnUpdateState(this.props);
                        }}
                    />
                    {
                        this.props.modalDetalhes.isLoading
                            ? <ProgerssBar />
                            : <div>
                                <Row>
                                    <div className="col-md-2 text-right"><strong>Cliente: </strong></div>
                                    <div className="col-md-4">{this.props.modalDetalhes.oportunidade.nomeCliente}</div>
                                    <div className="col-md-2 text-right"><strong>Data Cadastro: </strong></div>
                                    <div className="col-md-4"><Moment format="DD/MM/YYYY hh:mm">{this.props.modalDetalhes.oportunidade.dataCadastro}</Moment></div>
                                </Row>
                                <Row>
                                    <div className="col-md-2 text-right"><strong>Tipo Pessoa: </strong></div>
                                    <div className="col-md-4">{this.props.modalDetalhes.oportunidade.idTipoPessoa == 1 ? 'Física' : 'Jurídica'}</div>
                                    <div className="col-md-2 text-right"><strong>Empresa: </strong></div>
                                    <div className="col-md-4">{this.props.modalDetalhes.oportunidade.empresaCliente}</div>
                                </Row>
                                <Row>
                                    <div className="col-md-2 text-right"><strong>Email: </strong></div>
                                    <div className="col-md-4">{this.props.modalDetalhes.oportunidade.emailCliente}</div>
                                    <div className="col-md-2 text-right"><strong>Telefone: </strong></div>
                                    <div className="col-md-4">{this.props.modalDetalhes.oportunidade.telefoneCliente}</div>
                                </Row>
                                <Row>
                                    <div className="col-md-2 text-right"><strong>Canal: </strong></div>
                                    <div className="col-md-4">{this.props.modalDetalhes.oportunidade.nomeCanalLead}</div>
                                    <div className="col-md-2 text-right"><strong>Etapa: </strong></div>
                                    <div className="col-md-4">{this.props.modalDetalhes.oportunidade.nomeEtapaFunilVendas}</div>
                                </Row>
                                <Row>
                                    <div className="col-md-2 text-right"><strong>Status: </strong></div>
                                    <div className="col-md-4">{this.props.modalDetalhes.oportunidade.nomeOportunidadeStatus}</div>
                                    <div className="col-md-2 text-right"><strong>Potencial: </strong></div>
                                    <div className="col-md-4">{this.props.modalDetalhes.oportunidade.potencial}</div>
                                </Row>
                                <Row>
                                    <div className="col-md-2 text-right"><strong>Oferta: </strong></div>
                                    <div className="col-md-10">{this.props.modalDetalhes.oportunidade.oferta}</div>
                                </Row>
                                <h5 class="custom-font filled bg-blue">Atividades</h5>
                                <Row>
                                    <div className="col-sm-12">
                                        <table className="table table-custom dataTable no-footer">
                                            <thead>
                                                <tr role="row">
                                                    <th>Canal</th>
                                                    <th>Data</th>
                                                    <th>Duração</th>
                                                    <th>Status</th>
                                                    <th>Anotações</th>
                                                    <th>Observações</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    (this.props.modalDetalhes.atividades.isLoading)
                                                        ? <tr key={0} className="odd">
                                                            <td colSpan={6} className="dataTables_empty">
                                                                <div className="progress progress-striped active">
                                                                    <div className="progress-bar progress-bar-blue" role="progressbar" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100" style={{ width: "100%" }}>
                                                                        <span className="sr-only">100% Complete</span>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                        : (this.props.modalDetalhes.atividades.results.length == 0)
                                                            ? <tr key={0} className="odd">
                                                                <td colSpan={6} className="dataTables_empty">
                                                                    <p className="text-warning">Não foram encontrados registros...</p>
                                                                </td>
                                                            </tr>
                                                            : this.props.modalDetalhes.atividades.results.map(function (rowData, index) {
                                                                return (
                                                                    <tr key={index}>
                                                                        <td>{rowData.nomeCanalAtividade}</td>
                                                                        <td><Moment format="DD/MM/YYYY hh:mm">{rowData.data}</Moment></td>
                                                                        <td>{rowData.duracao}</td>
                                                                        <td>{rowData.status == 1 ? "Em aberto" : "Encerrada"}</td>
                                                                        <td>{rowData.anotacoes}</td>
                                                                        <td>{rowData.observacoes}</td>
                                                                    </tr>
                                                                )
                                                            })
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </Row>
                            </div>
                    }
                </Modal.Body>
            </form>
        </Modal>);
    }
}

export default connect(
    state => state.crm_consulta,
    dispatch => bindActionCreators(actionCreators, dispatch),
    null, {}
)(ModalDetalhes);