import * as React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actionCreators } from '../../../../store/marketing/publicador/MarketingPublicador';
import Moment from 'react-moment';

import Formsy from 'formsy-react';
import { Row, Panel } from 'react-bootstrap';
import BtnPesquisar from '../../../commons/BtnPesquisar';
import Section from '../../../commons/Section';
import ListPager from '../../../commons/ListPager';
import InputText from '../../../commons/InputText';
import InputSelect from '../../../commons/InputSelect';
import SwitchOnOff from '../../../commons/SwitchOnOff';
import { FormattedNumber } from 'react-intl';
import ListaPublicadorListRowFoto from './ListaPubicadorListRowFoto';
import ListaPublicadorPortais from './ListaPublicadorPortais';
import ListaPublicadorPortaisMoto from './ListaPublicadorPortaisMoto';

// Import dos modais para cada publicador
import ModalOLX from './modalportais/ModalOLX';
import ModalOLXMoto from './modalportais/ModalOLXMoto';
import ModalAutoLine from './modalportais/ModalAutoLine';
import ModalAutoLineMoto from './modalportais/ModalAutoLineMoto';
import ModalMeuCarroNovo from './modalportais/ModalMeuCarroNovo';
import ModalICarros from './modalportais/ModalICarros';
import ModalICarrosMoto from './modalportais/ModalIcarrosMoto';
import ModalWebMotors from './modalportais/ModalWebMotors';
import ModalWebMotorsMoto from './modalportais/ModalWebMotorsMoto';
import ModalMercadoLivre from './modalportais/ModalMercadoLivre';
import ModalMercadoLivreMoto from './modalportais/ModalMercadoLivreMoto';
import ModalCarroSaoJose from './modalportais/ModalCarroSaoJose';
import ModalCarroSaoJoseMoto from './modalportais/ModalCarroSaoJoseMoto';
import ModalTemUsados from './modalportais/ModalTemUsados';
import ModalNewMotors from './modalportais/ModalNewMotors';
import ModalCompreCar from './modalportais/ModalCompreCar';
import ModalCarroBrasil from './modalportais/ModalCarroBrasil';
import ModalCarroBrasilMoto from './modalportais/ModalCarroBrasilMoto';
import ModalDefaultFeed from './modalportais/ModalDefaultFeed';
import ModalWebSiteBNDV from './modalportais/ModalWebSiteBNDV';
import ModalFacebook from './modalportais/ModalFacebook';
import ModalFacebookMoto from './modalportais/ModalFacebookMoto';
import ModalMobiAuto from './modalportais/ModalMobiAuto';
import ModalCompreBlindados from './modalportais/ModalCompreBlindados';
import ModalUsadosBR from './modalportais/ModalUsadosBR';
import ModalUsadosBRMoto from './modalportais/ModalUsadosBRMoto';
import ModalWebSiteBNDVMoto from './modalportais/ModalWebSiteBNDVMoto';
import ModalRecomendacaoMercadoLivre from './modalrecomendacao/ModalRecomendacaoMercadoLivre';
class ListaPublicadorForm extends React.Component {
    constructor() {
        super();
        this.handleSubmit = this.handleSubmit.bind(this);
        this.sortChanged = this.sortChanged.bind(this);
    }

    componentDidMount() {
        this.props.fnBuscaPortaisAtivos();
        this.props.fnListaPatios();
    }

    handleSubmit(e) {
        this.props.ListaVeiculos.filters.PageNumber = 1;
        this.props.fnListaVeiculos();
    }

    sortChanged(OrderBy, Ascending, e) {
        e.preventDefault();

        this.props.ListaVeiculos.filters.OrderBy = OrderBy;
        this.props.ListaVeiculos.filters.PageNumber = 1;
        this.props.ListaVeiculos.filters.Ascending = ((Ascending == true) ? false : true);
        this.props.fnUpdateState(this.props);
    }

    render() {
        var objProps = this.props;

        var dropdownPatios = [];
        dropdownPatios.push({ value: '', text: '' });
        objProps.ListaVeiculos.dropdownPatios.results.forEach(function (entry) {
            dropdownPatios.push({ value: entry.id.toString(), text: entry.nome });
        });

        var dropdownPortais = [];
        dropdownPortais.push({ value: '', text: '' });
        objProps.ListaVeiculos.dropdownPortaisAtivos.results.forEach(function (entry) {
            dropdownPortais.push({ value: entry.idParceiroAnuncio.toString(), text: entry.nomeParceiroAnuncio });
        });

        dropdownPortais.sort(function (a, b) {
            if (a.text > b.text) {
                return 1;
            }
            if (a.text < b.text) {
                return -1;
            }
            return 0;
        });

        return <div>
            <Row>
                <div className="col-md-12">
                    <Section>
                        <Panel id="panelFiltro" bsStyle="primary" defaultExpanded>
                            <Panel.Heading>
                                <Panel.Title componentClass="h3" toggle><strong>Filtro</strong> de busca</Panel.Title>
                            </Panel.Heading>
                            <Panel.Collapse>
                                <Panel.Body>
                                    <div className="tile-body nopadding">
                                        <Formsy onValidSubmit={this.handleSubmit}>
                                            <Row>
                                                <InputText
                                                    name="txtFiltroGeral"
                                                    type="text"
                                                    className="col-md-12"
                                                    placeholder=""
                                                    onChange={(name, value) => {
                                                        this.props.ListaVeiculos.filters.FiltroGeral = value;
                                                        this.props.fnUpdateState(this.props);
                                                    }}
                                                    value={this.props.ListaVeiculos.filters.FiltroGeral}
                                                    title="Filtro geral:"
                                                    info="Para filtrar mais de um veículo informe os argumentos separados por espaço. Ex.: (Palio Gol BMW AAA0000) - Este campo faz filtro por marca, modelo, placa e chassi"
                                                />
                                                <InputText
                                                    name="txtAnoFab"
                                                    type="number"
                                                    className="col-md-3"
                                                    placeholder="2017"
                                                    onChange={(name, value) => {
                                                        this.props.ListaVeiculos.filters.AnoFabricacao = value;
                                                        this.props.fnUpdateState(this.props);
                                                    }}
                                                    value={(this.props.ListaVeiculos.filters.AnoFabricacao == null ? '' : this.props.ListaVeiculos.filters.AnoFabricacao.toString())}
                                                    title="Ano fabricação:"
                                                />
                                                <InputText
                                                    name="txtAnoMod"
                                                    type="number"
                                                    className="col-md-3"
                                                    placeholder="2017"
                                                    onChange={(name, value) => {
                                                        this.props.ListaVeiculos.filters.AnoModelo = value;
                                                        this.props.fnUpdateState(this.props);
                                                    }}
                                                    value={(this.props.ListaVeiculos.filters.AnoModelo == null ? '' : this.props.ListaVeiculos.filters.AnoModelo.toString())}
                                                    title="Ano modelo:"
                                                />
                                                <InputSelect
                                                    name="ddlPatio"
                                                    className="col-md-3"
                                                    data={dropdownPatios}
                                                    onChange={
                                                        (name, value) => {
                                                            this.props.ListaVeiculos.filters.idPatio = value;
                                                            this.props.fnUpdateState(this.props);
                                                        }
                                                    }
                                                    selectedValue={
                                                        (this.props.ListaVeiculos.filters.idPatio == null)
                                                            ? ""
                                                            : this.props.ListaVeiculos.filters.idPatio.toString()
                                                    }
                                                    title="Pátio:"
                                                />
                                                <InputSelect
                                                    name="ddlPortal"
                                                    className="col-md-3"
                                                    data={dropdownPortais}
                                                    onChange={
                                                        (name, value) => {
                                                            this.props.ListaVeiculos.filters.idPortal = value;
                                                            this.props.fnUpdateState(this.props);
                                                        }
                                                    }
                                                    selectedValue={
                                                        (this.props.ListaVeiculos.filters.idPortal == null)
                                                            ? ""
                                                            : this.props.ListaVeiculos.filters.idPortal.toString()
                                                    }
                                                    title="Portal:"
                                                />
                                                <InputSelect
                                                    name="ddlFoto"
                                                    className="col-md-3"
                                                    data={[{ value: '0', text: 'Todos os Veículos' }, { value: '1', text: 'Veículos COM foto' }, { value: '2', text: 'Veículos SEM foto' }]}
                                                    onChange={(name, value) => {
                                                        this.props.ListaVeiculos.filters.Fotos = value; this.props.fnUpdateState(this.props);
                                                    }}
                                                    selectedValue={this.props.ListaVeiculos.filters.Fotos.toString()}
                                                    title="Foto:"
                                                />
                                                <div className="form-group col-md-3">
                                                    <label>Todos status</label><br />
                                                    <SwitchOnOff
                                                        class="onoffswitch-checkbox"
                                                        id="chkEstoqueVendido"
                                                        onChange={(name, value) => {
                                                            this.props.ListaVeiculos.filters.Todos = (value == 'on' ? false : true);
                                                            this.props.fnUpdateState(this.props);
                                                        }}
                                                        value={this.props.ListaVeiculos.filters.Todos == true ? 'on' : 'off'}
                                                    />
                                                </div>
                                                <div className="form-group col-md-3">
                                                    <label>Não publicados em nenhum portal</label><br />
                                                    <SwitchOnOff
                                                        class="onoffswitch-checkbox"
                                                        id="chkNaoPublicados"
                                                        onChange={(name, value) => {
                                                            this.props.ListaVeiculos.filters.NaoPublicados = (value == 'on' ? false : true);
                                                            this.props.fnUpdateState(this.props);
                                                        }}
                                                        value={this.props.ListaVeiculos.filters.NaoPublicados == true ? 'on' : 'off'}
                                                    />
                                                </div>
                                                <div className="form-group col-md-3">
                                                    <label>Somente blindados</label><br />
                                                    <SwitchOnOff
                                                        class="onoffswitch-checkbox"
                                                        id="chkBlindados"
                                                        onChange={(name, value) => {
                                                            this.props.ListaVeiculos.filters.Blindados = (value == 'on' ? false : true);
                                                            this.props.fnUpdateState(this.props);
                                                        }}
                                                        value={this.props.ListaVeiculos.filters.Blindados == true ? 'on' : 'off'}
                                                    />
                                                </div>
                                                <InputSelect
                                                    name="ddlOrder"
                                                    className="col-md-3"
                                                    data={
                                                        [
                                                            { value: '1', text: 'Alfabética Crescente' },
                                                            { value: '2', text: 'Alfabética Decrescente' },
                                                            { value: '3', text: 'Data de Cadastro Crescente' },
                                                            { value: '4', text: 'Data de Cadastro Decrescente' },
                                                        ]
                                                    }
                                                    onChange={(name, value) => {
                                                        if (value === '1' || value === '3') {
                                                            this.props.ListaVeiculos.filters.OrderBy = value;
                                                            this.props.ListaVeiculos.filters.Ascending = true;
                                                            this.props.fnUpdateState(this.props);
                                                        }
                                                        if (value === '2' || value === '4') {
                                                            this.props.ListaVeiculos.filters.OrderBy = value;
                                                            this.props.ListaVeiculos.filters.Ascending = false;
                                                            this.props.fnUpdateState(this.props);
                                                        }
                                                    }}
                                                    selectedValue={this.props.ListaVeiculos.filters.OrderBy.toString()}
                                                    title="Ordenação:"
                                                />
                                                <InputSelect
                                                    name="ddlCategoria"
                                                    className="col-md-3"
                                                    data={
                                                        [
                                                            { value: '0', text: 'Todos' },
                                                            { value: '1', text: 'Carros' },
                                                            { value: '2', text: 'Motos' }                                                           
                                                        ]
                                                    }
                                                    onChange={(name, value) => {
                                                      
                                                            this.props.ListaVeiculos.filters.idCategoria = value;                                                           
                                                            this.props.fnUpdateState(this.props);                                                        
                                                        
                                                    }}
                                                    selectedValue={this.props.ListaVeiculos.filters.idCategoria.toString()}
                                                    title="Categoria:"
                                                />
                                                <div className="form-group col-md-6 text-right">
                                                    <BtnPesquisar
                                                        type="submit"
                                                        isLoading={this.props.ListaVeiculos.isLoading}
                                                        text=" Pesquisar"
                                                        textLoading=" Aguarde..."
                                                    />
                                                </div>
                                            </Row>
                                        </Formsy>
                                    </div>
                                </Panel.Body>
                            </Panel.Collapse>
                        </Panel>
                    </Section>
                </div>
            </Row>
            {
                this.props.ListaVeiculos.requested
                    ? <Row>
                        <div className="col-md-12">
                            <div className="tile">
                                <div className="tile-header dvd dvd-btm">
                                    <h3>Listagem de veículos</h3>
                                </div>
                                {
                                    (this.props.ListaVeiculos.isLoading)
                                        ? <div className="progress progress-striped active">
                                            <div className="progress-bar progress-bar-blue" role="progressbar" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100" style={{ width: "100%" }}>
                                                <span className="sr-only">100% Complete</span>
                                            </div>
                                        </div>
                                        : (this.props.ListaVeiculos.results.length == 0)
                                            ? <p className="text-warning">Não foram encontrados registros para a busca...</p>
                                            : this.props.ListaVeiculos.results.map(function (rowData, index) {
                                                return (
                                                    <div className="tile-body dvd dvd-btm" key={rowData.id.toString()}>
                                                        <div className="row b-b">
                                                            <div className="col-md-12">
                                                                <section className="tile time-simple">
                                                                    <div className="row mb-10">
                                                                        <div className="col-md-2" data-lightbox="gallery">
                                                                            <ListaPublicadorListRowFoto
                                                                                class="img-responsive"
                                                                                alt="Alternate Text"
                                                                                idVeiculo={rowData.id}
                                                                                token={objProps.ListaVeiculos.token}
                                                                            />
                                                                        </div>
                                                                        <div className="col-md-10">
                                                                            <h5 style={{ fontSize: '16px', fontWeight: 600, marginTop: '0px' }}>{rowData.veiculo}</h5>
                                                                            <div className="row">
                                                                                <div className="col-md-9 b-r">
                                                                                    {
                                                                                        rowData.opcionais == ""
                                                                                            ? <div className="alert alert-warning" role="alert">Percebemos que não há nenhum opcional cadastrado para este veículo, se possível cadastre para dar mais visibilidade aos seus anúncios.&nbsp;&nbsp;<i className="fa fa-smile-o"></i></div>
                                                                                            : rowData.opcionais
                                                                                    }
                                                                                </div>
                                                                                <div className="col-md-3 b-r">
                                                                                    <dl className="dl-horizontal">
                                                                                        <dt style={{ width: '80px' }}>Placa:</dt>
                                                                                        <dd style={{ marginLeft: '85px' }}>{rowData.placa}</dd>
                                                                                        <dt style={{ width: '80px' }}>Ano:</dt>
                                                                                        <dd style={{ marginLeft: '85px' }}>{rowData.anoFabricacao + '/' + rowData.anoModelo}</dd>
                                                                                        <dt style={{ width: '80px' }}>Valor Web:</dt>
                                                                                        <dd style={{ marginLeft: '85px' }}>
                                                                                            <FormattedNumber value={rowData.valorWeb} style="decimal" minimumFractionDigits={2} maximumFractionDigits={2} />
                                                                                        </dd>
                                                                                        <dt style={{ width: '80px' }}>KM:</dt>
                                                                                        <dd style={{ marginLeft: '85px' }}>{rowData.km}</dd>
                                                                                        <dt style={{ width: '80px' }}>Status:</dt>
                                                                                        <dd style={{ marginLeft: '85px' }}>
                                                                                            {
                                                                                                rowData.statusVeiculo === 1
                                                                                                    ? <span className="badge badge-success">Em estoque</span>
                                                                                                    : 
                                                                                                    rowData.statusVeiculo === 2
                                                                                                        ? <span className="badge badge-danger">Vendido</span>
                                                                                                        :
                                                                                                        rowData.statusVeiculo === 3
                                                                                                            ? <span className="badge badge-primary">Em compra</span>
                                                                                                            :
                                                                                                            rowData.statusVeiculo === 6
                                                                                                                ? <span className="badge badge-warning">Venda iniciada</span>
                                                                                                                :
                                                                                                                 rowData.statusVeiculo === 4
                                                                                                                    ? <span className="badge badge-danger">Vendido</span>
                                                                                                                    :
                                                                                                                    rowData.statusVeiculo === 5
                                                                                                                        ? <span className="badge badge-info">Entrada veiculo de troca</span>
                                                                                                        : null
                                                                                            }
                                                                                        </dd>
                                                                                        <dt style={{ width: '80px' }}>Data Cad.:</dt>
                                                                                        <dd style={{ marginLeft: '85px' }}><Moment format="DD/MM/YYYY">{rowData.dataCadastro}</Moment></dd>
                                                                                    </dl>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    {
                                                                        rowData.idCategoria == 1 
                                                                            ? <ListaPublicadorPortais children={rowData.id} />
                                                                            : <ListaPublicadorPortaisMoto children={rowData.id} />
                                                                    }
                                                                    
                                                                </section>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            })}
                            </div>
                        </div>
                        <div className="col-md-5">
                            <div className="dataTables_info"> Mostrando&nbsp; {
                                (this.props.ListaVeiculos.filters.PageSize > this.props.ListaVeiculos.filters.TotalNumberOfRecords)
                                    ? this.props.ListaVeiculos.filters.TotalNumberOfRecords :
                                    (this.props.ListaVeiculos.filters.PageSize * this.props.ListaVeiculos.filters.PageNumber > this.props.ListaVeiculos.filters.TotalNumberOfRecords
                                        ? this.props.ListaVeiculos.filters.TotalNumberOfRecords : (this.props.ListaVeiculos.filters.PageSize * this.props.ListaVeiculos.filters.PageNumber))
                            } de {this.props.ListaVeiculos.filters.TotalNumberOfRecords}
                            </div>
                        </div>
                        <div className="col-md-7">
                            <div className="dataTables_paginate paging_simple_numbers">
                                <ListPager
                                    CurrentPage={this.props.ListaVeiculos.filters.PageNumber}
                                    Size={this.props.ListaVeiculos.filters.TotalNumberOfPages}
                                    OnPageChanged={
                                        (page) => {
                                            this.props.ListaVeiculos.filters.PageNumber = page;
                                            this.props.fnListaVeiculos();
                                        }
                                    }
                                />
                            </div>
                        </div>
                    </Row>
                    : null
            }
            {this.props.ModalOLX.showModal ? <ModalOLX /> : null}
            {this.props.ModalOLXMoto.showModal ? <ModalOLXMoto /> : null}
            {this.props.ModalAutoLine.showModal ? <ModalAutoLine /> : null}
            {this.props.ModalAutoLineMoto.showModal ? <ModalAutoLineMoto /> : null}
            {this.props.ModalMeuCarroNovo.showModal ? <ModalMeuCarroNovo /> : null}
            {this.props.ModalICarros.showModal ? <ModalICarros /> : null}
            {this.props.ModalICarrosMoto.showModal ? <ModalICarrosMoto /> : null}
            {this.props.ModalWebMotors.showModal ? <ModalWebMotors /> : null}
            {this.props.ModalMercadoLivre.showModal ? <ModalMercadoLivre /> : null}
            {this.props.ModalMercadoLivreMoto.showModal ? <ModalMercadoLivreMoto /> : null}
            {this.props.ModalCarroSaoJose.showModal ? <ModalCarroSaoJose /> : null}
            {this.props.ModalCarroSaoJoseMoto.showModal ? <ModalCarroSaoJoseMoto /> : null}
            {this.props.ModalTemUsados.showModal ? <ModalTemUsados /> : null}
            {this.props.ModalNewMotors.showModal ? <ModalNewMotors /> : null}
            {this.props.ModalCompreCar.showModal ? <ModalCompreCar /> : null}
            {this.props.ModalCarroBrasil.showModal ? <ModalCarroBrasil /> : null}
            {this.props.ModalCarroBrasilMoto.showModal ? <ModalCarroBrasilMoto /> : null}
            {this.props.ModalDefaultFeed.showModal ? <ModalDefaultFeed /> : null}
            {this.props.ModalWebSiteBNDV.showModal ? <ModalWebSiteBNDV /> : null}
            {this.props.ModalFacebook.showModal ? <ModalFacebook /> : null}
            {this.props.ModalFacebookMoto.showModal ? <ModalFacebookMoto /> : null}
            {this.props.ModalMobiAuto.showModal ? <ModalMobiAuto /> : null}
            {this.props.ModalWebMotorsMoto.showModal ? <ModalWebMotorsMoto /> : null}
            {this.props.ModalCompreBlindados.showModal ? <ModalCompreBlindados /> : null}
            {this.props.ModalUsadosBR.showModal ? <ModalUsadosBR /> : null}
            {this.props.ModalUsadosBRMoto.showModal ? <ModalUsadosBRMoto /> : null}
            {this.props.ModalWebSiteBNDVMoto.showModal ? <ModalWebSiteBNDVMoto /> : null}
            {this.props.ModalRecomendacaoMercadoLivre.showModal ? <ModalRecomendacaoMercadoLivre /> : null}
            
        </div>;
    }
}

export default connect(
    state => state.marketing_publicador,
    dispatch => bindActionCreators(actionCreators, dispatch),
    null, {}
)(ListaPublicadorForm);