import * as funcs from '../../../utils/funcs';

//const _URL = 'http://localhost:60402/api/v1/publicador/';
//const _URL = 'https://apisistema.bndv.com.br/Marketing/api/v1/publicador/';
const _URL = 'https://bndv20-marketing-api.azurewebsites.net/api/v1/publicador/';

export const services = {
    buscaTokenAnunciante: (authorizationToken) => {
        //var query = funcs.encodeURI(pDTO);
        return new Request(_URL + 'buscaPublicadorConfig',
            {
                method: 'GET',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
                    'Authorization': 'Bearer ' + authorizationToken
                }
            }); 
    },
    inserirPublicadorConfig: (authorizationToken) => {
        return new Request(_URL + 'inserirPublicadorConfig',
            {
                method: 'POST',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
                    'Authorization': 'Bearer ' + authorizationToken
                }
            });
    },
    listaPortaisCredenciados: (authorizationToken) => {
        return new Request(_URL + 'obterPortaisCredenciados',
            {
                method: 'GET',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
                    'Authorization': 'Bearer ' + authorizationToken
                }
            });
    },
    obterEmpresaParceiroAnuncio: (idPublicador, authorizationToken) => {
        return new Request(_URL + 'obterEmpresaParceiroAnuncio?idPublicador=' + idPublicador.toString(),
            {
                method: 'GET',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
                    'Authorization': 'Bearer ' + authorizationToken
                }
            });
    },
    inserirEmpresaParceiroAnuncio: (pDTO, authorizationToken) => {
        var query = funcs.encodeURI(pDTO);
        return new Request(_URL + 'inserirEmpresaParceiroAnuncio',
            {
                method: 'POST',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
                    'Authorization': 'Bearer ' + authorizationToken
                },
                body: query
            });
    },
    alterarEmpresaParceiroAnuncio: (pDTO, authorizationToken) => {
        var query = funcs.encodeURI(pDTO);
        return new Request(_URL + 'alterarEmpresaParceiroAnuncio',
            {
                method: 'PUT',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
                    'Authorization': 'Bearer ' + authorizationToken
                },
                body: query
            });
    },
    removerEmpresaParceiroAnuncio: (pDTO, authorizationToken) => {
        var query = funcs.encodeURI(pDTO);
        return new Request(_URL + 'removerEmpresaParceiroAnuncio',
            {
                method: 'DELETE',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
                    'Authorization': 'Bearer ' + authorizationToken
                },
                body: query
            });
    },
    listaUFsParceiroAnuncio: (pDTO, authorizationToken) => {
        var query = funcs.encodeURI(pDTO);
        return new Request(_URL + 'obterUFs',
            {
                method: 'POST',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
                    'Authorization': 'Bearer ' + authorizationToken
                },
                body: query
            });
    },
    listaCidadesParceiroAnuncio: (pDTO, authorizationToken) => {
        var query = funcs.encodeURI(pDTO);
        return new Request(_URL + 'obterCidades',
            {
                method: 'POST',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
                    'Authorization': 'Bearer ' + authorizationToken
                },
                body: query
            });
    },
    listaBairrosParceiroAnuncio: (pDTO, authorizationToken) => {
        var query = funcs.encodeURI(pDTO);
        return new Request(_URL + 'obterBairros',
            {
                method: 'POST',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
                    'Authorization': 'Bearer ' + authorizationToken
                },
                body: query
            });
    },
    obterConfiguracoesAdicionaisPublicador: (authorizationToken) => {
        return new Request(_URL + 'configuracoesAdicionais',
            {
                method: 'GET',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
                    'Authorization': 'Bearer ' + authorizationToken
                }
            });
    },
    inserirConfiguracoesAdicionaisPublicador: (pDTO, authorizationToken) => {
        var query = funcs.encodeURI(pDTO);
        return new Request(_URL + 'configuracoesAdicionais',
            {
                method: 'POST',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
                    'Authorization': 'Bearer ' + authorizationToken
                },
                body: query
            });
    },
    alterarConfiguracoesAdicionaisPublicador: (pDTO, authorizationToken) => {
        var query = funcs.encodeURI(pDTO);
        return new Request(_URL + 'configuracoesAdicionais',
            {
                method: 'PUT',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
                    'Authorization': 'Bearer ' + authorizationToken
                },
                body: query
            });
    },
}