import * as React from 'react'

import BNDVCompletaHistoricoForm from './BNDVCompletaHistoricoForm';
import BNDVCompletaResultado from './BNDVCompletaResultado';
import BNDVCompletaSolicitacaoForm from './BNDVCompletaSolicitacaoForm';

import { Row } from 'react-bootstrap';

export default class BNDVCompletaTab extends React.Component {
    constructor() {
        super();

        this.state = ({
            tabSolicitarPesquisa: "active",
            paneSolicitarPesquisa: "tab-pane active",
            tabHistorico: "",
            paneHistorico: "tab-pane"
        });
    }

    changeTab(tab, e) {
        if (tab == 1) {
            this.setState({
                tabSolicitarPesquisa: "active",
                paneSolicitarPesquisa: "tab-pane active",
                tabHistorico: "",
                paneHistorico: "tab-pane"
            });
        }
        else if (tab == 2) {
            this.setState({
                tabSolicitarPesquisa: "",
                paneSolicitarPesquisa: "tab-pane",
                tabHistorico: "active",
                paneHistorico: "tab-pane active"
            });
        }
    }


    render() {
        return <Row>
            <div className="col-md-12">
                <section className="tile time-simple">
                    <div className="tile-body">
                        <div role="tabpanel">
                            <ul className="nav nav-tabs">
                                <li role="presentation" className={this.state.tabSolicitarPesquisa}><a onClick={this.changeTab.bind(this, 1)}>Solicitar Pesquisa</a></li>
                                <li role="presentation" className={this.state.tabHistorico}><a onClick={this.changeTab.bind(this, 2)}>Histórico</a></li>
                            </ul>
                            <div className="tab-content">
                                <div role="tabpanel" className={this.state.paneSolicitarPesquisa}>
                                    <BNDVCompletaSolicitacaoForm />
                                </div>
                                <div role="tabpanel" className={this.state.paneHistorico}>
                                    <BNDVCompletaHistoricoForm />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
            <BNDVCompletaResultado />
        </Row>;
    }
}