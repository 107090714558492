import * as React from 'react';
import HeaderPage from '../../components/commons/HeaderPage';
import ConsultaForm from '../../components/crm/mercado_livre_pre_aprovado/ConsultaForm';

export default class Crm_Pre_Aprovado_Mercado_Livre extends React.Component {
    render() {
        return <div className='page'>
            <HeaderPage
                path=' CRM'
                name='Crédito Pré Aprovado - (Mercado Livre)' />
            <ConsultaForm />
        </div>;
    }
}