import * as funcs from '../../utils/funcs';

// Urls API
//const URLPagamentos = 'http://localhost:57909/api/v1/';
//const URLConsultasVeiculares = 'http://localhost:56829/api/v1/';
const URLPagamentos = 'https://apisistema.bndv.com.br/AnaliseRisco/Pagamentos/api/v1/';
const URLConsultasVeiculares = 'https://apisistema.bndv.com.br/AnaliseRisco/PesquisasVeiculares/api/v1/';

export const services = {
    Pagamentos: {
        ListaPagamentos: (pFilters, authorizationToken) => {
            var query = funcs.encodeURI(pFilters);
            return new Request(URLPagamentos + 'buscarTodosCreditos?' + query,
                {
                    method: 'GET',
                    mode: 'cors',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + authorizationToken
                    }
                });
        }
        , CancelaSolicitacaoCredito: (pId, authorizationToken) => {
            return new Request(URLPagamentos + 'cancelarSolicitacao',
                {
                    method: 'POST',
                    mode: 'cors',
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
                        'Authorization': 'Bearer ' + authorizationToken
                    },
                    body: 'ID=' + pId.toString()
                });
        }
        , BuscaSaldo: (authorizationToken) => {
            return new Request(URLPagamentos + 'obterSaldo',
                {
                    method: 'GET',
                    mode: 'cors',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + authorizationToken
                    }
                });
        }
        , SolicitarCredito: (pValor, authorizationToken) => {
            return new Request(URLPagamentos + 'inserirPagamento',
                {
                    method: 'POST',
                    mode: 'cors',
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
                        'Authorization': 'Bearer ' + authorizationToken
                    },
                    body: "Valor_EmpresaSolicitacaoCreditoConsulta=" + pValor.toString()
                });
        }
    }
    , ConsultasVeiculares: {
        ListaConsultasVeicularesHistorico: (pFilters, authorizationToken) => {
            var query = funcs.encodeURI(pFilters);
            return new Request(URLConsultasVeiculares + 'listaPesquisasVeiculares?' + query,
                {
                    method: 'GET',
                    mode: 'cors',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + authorizationToken
                    }
                });
        }
        , BuscaResultadoPesquisaCompleta: (pId, authorizationToken) => {
            return new Request(URLConsultasVeiculares + 'obterPesquisaVeicular?id=' + pId.toString(),
                {
                    method: 'GET',
                    mode: 'cors',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + authorizationToken
                    }
                });
        }
        , SolicitaPesquisaCompleta: (pPlaca, pChassi, pUf, authorizationToken) => {
            var params = {
                Chassi: pChassi,
                Placa: pPlaca,
                UF: pUf
            }

            var query = funcs.encodeURI(params);
            return new Request(URLConsultasVeiculares + 'solicitarPesquisaVeicular',
                {
                    method: 'POST',
                    mode: 'cors',
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
                        'Authorization': 'Bearer ' + authorizationToken
                    },
                    body: query
                });
        }
        , ListaPesquisasBNDVCompletaRF: (pFilters, authorizationToken) => {
            var query = funcs.encodeURI(pFilters);
            return new Request(URLConsultasVeiculares + 'listaPesquisasBNDVCompletaRF?' + query,
                {
                    method: 'GET',
                    mode: 'cors',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + authorizationToken
                    }
                });
        }
        , BuscaResultadoPesquisaBNDVCompletaRF: (pId, authorizationToken) => {
            return new Request(URLConsultasVeiculares + 'obterPesquisaBNDVCompletaRF?id=' + pId.toString(),
                {
                    method: 'GET',
                    mode: 'cors',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + authorizationToken
                    }
                });
        }
        , SolicitaPesquisaBNDVCompletaRF: (pPlaca, pChassi, pUf, authorizationToken) => {
            var params = {
                Chassi: pChassi,
                Placa: pPlaca,
                UF: pUf
            }

            var query = funcs.encodeURI(params);
            return new Request(URLConsultasVeiculares + 'solicitarPesquisaBNDVCompletaRF',
                {
                    method: 'POST',
                    mode: 'cors',
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
                        'Authorization': 'Bearer ' + authorizationToken
                    },
                    body: query
                });
        }
    }
}