import { fetch, addTask } from 'domain-task';
import * as analiserisco_service from '../../services/analiserisco/analiserisco_service';

const REQUEST_VALORCREDITO_ACTION = 'REQUEST_VALORCREDITO_ACTION';
const RECEIVE_VALORCREDITO_ACTION = 'RECEIVE_VALORCREDITO_ACTION';
const FAILURE_VALORCREDITO_ACTION = 'FAILURE_VALORCREDITO_ACTION';
const POST_SOLICITARCREDITO_ACTION = 'POST_SOLICITARCREDITO_ACTION';
const SUCCESS_SOLICITARCREDITO_ACTION = 'SUCCESS_SOLICITARCREDITO_ACTION';
const FAILURE_SOLICITARCREDITO_ACTION = 'FAILURE_SOLICITARCREDITO_ACTION';
const OPENMODAL_ACTION = 'OPENMODAL_ACTION';
const CLOSEMODAL_ACTION = 'CLOSEMODAL_ACTION';
const UPDATE_STATE_SOLICITAR_PAGAMENTO_ACTION = 'UPDATE_STATE_SOLICITAR_PAGAMENTO_ACTION';
const initialState = {
    Saldo: 0,
    ValorCredito: 0,
    ShowModal: false,
    IsLoading: false,
    ErrorText: ''
};

export const actionCreators = {
    fnBuscaSaldo: () => async (dispatch, getState) => {
        let fetchTask = fetch(analiserisco_service.services.Pagamentos.BuscaSaldo(getState().auth.authorizationToken))
            .then(response => response.json())
            .then(data => {
                dispatch({ type: 'RECEIVE_VALORCREDITO_ACTION', Saldo: data.Valor });
            }).catch(function (err) {
                dispatch({ type: 'FAILURE_VALORCREDITO_ACTION',  ErrorText: err.message });
            });

        addTask(fetchTask);
        dispatch({ type: 'REQUEST_VALORCREDITO_ACTION' });
    }
    , fnOpenModal: () => async (dispatch) => {
        dispatch({ type: 'OPENMODAL_ACTION' });
    }
    , fnCloseModal: () => async (dispatch) => {
        dispatch({ type: 'CLOSEMODAL_ACTION' });
    }
    , fnSolicitarCredito: () => async (dispatch, getState) => {

        let fetchTask = fetch(analiserisco_service.services.Pagamentos.SolicitarCredito(getState().solicitarpagamentos.ValorCredito, getState().auth.authorizationToken))
            .then(response => {
                if (response.status === 200 || response.status === 0) {
                    return response;
                }
                else {
                    return response.json().then(function (object) {
                        return Promise.reject(new Error(object));
                    })
                }
            })
            .then(data => {
                dispatch({ type: 'SUCCESS_SOLICITARCREDITO_ACTION' });
            }).catch(function (err) {
                dispatch({ type: 'FAILURE_SOLICITARCREDITO_ACTION', ErrorText: err.message });
            });

        addTask(fetchTask);
        dispatch({ type: 'POST_SOLICITARCREDITO_ACTION' });

    }
    , fnUpdateState: (pSolicitarPagamentosState) => async (dispatch) => {
        dispatch({
            type: 'UPDATE_STATE_SOLICITAR_PAGAMENTO_ACTION',
            Saldo: pSolicitarPagamentosState.Saldo,
            ValorCredito: pSolicitarPagamentosState.ValorCredito,
            ShowModal: pSolicitarPagamentosState.ShowModal,
            IsLoading: pSolicitarPagamentosState.IsLoading,
            ErrorText: pSolicitarPagamentosState.ErrorText
        })
    }
    , fnAtualizaValorStore: (pValor) => async (dispatch, getState) => {
        dispatch({
            type: 'UPDATE_STATE_SOLICITAR_PAGAMENTO_ACTION',
            Saldo: getState().solicitarpagamentos.Saldo,
            ValorCredito: pValor,
            ShowModal: getState().solicitarpagamentos.ShowModal,
            IsLoading: getState().solicitarpagamentos.IsLoading,
            ErrorText: getState().solicitarpagamentos.ErrorText
        })
    }
};

export const reducer = (state, action) => {
    state = state || initialState;

    if (action.type === REQUEST_VALORCREDITO_ACTION) {
        return {
            ...state,
            IsLoading: true,
            ErrorText: ""
        };
    }

    if (action.type === RECEIVE_VALORCREDITO_ACTION) {
        return {
            ...state,
            Saldo: action.Saldo,
            IsLoading: false
        };
    }

    if (action.type === FAILURE_VALORCREDITO_ACTION) {
        return {
            ...state,
            IsLoading: false,
            ErrorText: action.ErrorText
        };
    }

    if (action.type === POST_SOLICITARCREDITO_ACTION) {
        return {
            ...state,
            IsLoading: true,
            ErrorText: ""
        };
    }

    if (action.type === SUCCESS_SOLICITARCREDITO_ACTION) {
        return {
            ...state,
            IsLoading: false,
            ShowModal: false
        };
    }

    if (action.type === FAILURE_SOLICITARCREDITO_ACTION) {
        return {
            ...state,
            IsLoading: false,
            ShowModal: false,
            ErrorText: action.ErrorText
        };
    }

    if (action.type === OPENMODAL_ACTION) {
        return {
            ...state,
            ShowModal: true
        };
    }

    if (action.type === CLOSEMODAL_ACTION) {
        return {
            ...state,
            ShowModal: false
        };
    }

    if (action.type === UPDATE_STATE_SOLICITAR_PAGAMENTO_ACTION) {
        return {
            ...state,
            Saldo: action.Saldo,
            ValorCredito: action.ValorCredito,
            ShowModal: action.ShowModal,
            IsLoading: action.IsLoading,
            ErrorText: action.ErrorText
        };
    }

    return state;
};

