import * as funcs from '../../utils/funcs';

//const _URL = 'http://localhost:58416/api/v1/edoc/';
const _URL = 'https://apisistema.bndv.com.br/Edoc/api/v1/edoc/';

export const services = {
    listaTiposDocumento: (authorizationToken) => {
        return new Request(_URL + 'listaTiposDocumento',
            {
                method: 'GET',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
                    'Authorization': 'Bearer ' + authorizationToken
                }
            });
    },
    adicionarTipoDocumento: (authorizationToken, pDTO) => {
        var query = funcs.encodeURI(pDTO);

        return new Request(_URL + 'inserirTipoDocumento',
            {
                method: 'POST',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
                    'Authorization': 'Bearer ' + authorizationToken
                },
                body: query
            });
    },
    removerTipoDocumento: (authorizationToken, id) => {
        return new Request(_URL + 'removerTipoDocumento?id=' + id.toString(),
            {
                method: 'DELETE',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
                    'Authorization': 'Bearer ' + authorizationToken
                }
            });
    }
}