import { fetch, addTask } from 'domain-task';
import * as auth_service from '../services/auth_service';
import * as navmenuState from '../store/template/navmenu';

const requestLoginType = 'REQUEST_LOGIN';
const successLoginType = 'SUCCESS_LOGIN';
const failureLoginType = 'FAILURE_LOGIN';
const initialState = {
    IsLoading: false,
    IsAuthenticated: false,
    NomeUsuario: '',
    NomeEmpresa: '',
    TipoUsuario: 0,
    authorizationToken: '',
    ErrorText: ''
};

export const actionCreators = {
    requestLogin: (username, password, cnpjEmpresa) => async (dispatch, getState) => {
        let fetchTask = await fetch(auth_service.services.Auth.RequestLogin(username, password, cnpjEmpresa))
            .then(response => response.json())
            .then(data => {
                if (data.error !== undefined) {
                    dispatch({ type: 'FAILURE_LOGIN', IsLoading: false, ErrorText: "Usuáio/Senha inválidos" });
                }
                else {
                    dispatch(navmenuState.actionCreators.fnCarregaModulos(data.Permissoes));

                    dispatch({
                        type: 'SUCCESS_LOGIN',
                        NomeUsuario: data.NomeUsuario,
                        NomeEmpresa: data.NomeEmpresa,
                        TipoUsuario: data.TipoUsuario,
                        authorizationToken: data.access_token
                    });
                }
            })
            .catch(err => {
                dispatch({ type: 'FAILURE_LOGIN', ErrorText: err.message });
            });

        addTask(fetchTask);
        dispatch({ type: 'REQUEST_LOGIN', ErrorText: '' });
    }
};

export const reducer = (state, action) => {
    state = state || initialState;

    if (action.type === requestLoginType) {
        return {
            ...state,
            IsLoading: true,
            ErrorText: action.ErrorText
        };
    }

    if (action.type === successLoginType) {
        return {
            ...state,
            IsLoading: false,
            IsAuthenticated: true,
            NomeUsuario: action.NomeUsuario,
            NomeEmpresa: action.NomeEmpresa,
            TipoUsuario: action.TipoUsuario,
            authorizationToken: action.authorizationToken,
            ErrorText: ''
        };
    }

    if (action.type === failureLoginType) {
        return {
            ...state,
            IsLoading: false,
            ErrorText: action.ErrorText
        }
    }

    return state;
};
