import * as React from "react";

export default class SwitchOnOff extends React.Component {
    constructor() {
        super();
        this.state = { value: 'ini' };
    }

    componentDidMount() {
        this.setState({ value: this.props.value });
    }

    componentWillReceiveProps(nextProps) {
        this.setState({ value: nextProps.value });
    }

    fnChange(e) {
        if (e.target.value === 'on') {
            this.setState({ value: 'off' });
        }
        else {
            this.setState({ value: 'on' });
        }

        const fieldName = e.target.name;
        const fieldValue = e.target.value;
        this.props.onChange(fieldName, fieldValue);
    }

    render() {
        return (
            this.state.value == 'ini'
                ? null
                : <div className="onoffswitch labeled blue inline-block">
                    <input
                        type="checkbox"
                        name="onoffswitch"
                        className={this.props.class}
                        id={this.props.id}
                        value={this.state.value}
                        //onClick={this.fnChange.bind(this)}
                        checked={this.state.value == 'on' ? true : false}
                        onChange={this.fnChange.bind(this)}
                    />
                    <label className="onoffswitch-label" htmlFor={this.props.id}>
                        <span className="onoffswitch-inner"></span>
                        <span className="onoffswitch-switch"></span>
                    </label>
                </div>
        );
    }
}