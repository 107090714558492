import * as React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actionCreators } from '../../../../store/marketing/publicador/MarketingPublicador';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';

class ListaPublicadorPortaisMoto extends React.Component {


    async componentDidMount() {
        await this.props.fnListaVeiculoPortais(parseInt(this.props.children.toString()));
    }

    render() {
        var objProps = this.props;
        return <div className="row pt-10 b-t">
            <div className="col-md-9 b-r">
                <small style={{ fontWeight: 'bold' }}>Portais parceiros</small>
                <div className="table-responsive">
                    <table className="table table-custom cabra-table" id="basic-usage">
                        <tbody>
                            <tr>
                                {
                                    this.props.ListaVeiculos.results.map(function (entry, index) {
                                        return (
                                            entry.id === parseInt(objProps.children.toString())
                                                ? objProps.ListaVeiculos.results[index].portais.length === 0
                                                    ? <div key={0} className="alert alert-warning" role="alert">Você não possui nenhum publicador configurado, se precisar de ajuda contate nosso suporte.</div>
                                                    : objProps.ListaVeiculos.results[index].portais.map(function (item, index2) {
                                                        return (
                                                            (
                                                                item.idParceiroAnuncio === 1
                                                                    // Icarros
                                                                ? null
                                                                    //? <td
                                                                    //    style={{ textAlign: 'center' }}
                                                                    //    key={item.idParceiroAnuncio.toString() + index2 + entry.id}>
                                                                    //    <OverlayTrigger
                                                                    //        placement="top"
                                                                    //        overlay={<Tooltip id="tooltip"><strong>ICarros</strong></Tooltip>}>
                                                                    //        <a onClick={() => {
                                                                    //            objProps.PublicarVeiculo.idVeiculo = entry.id;
                                                                    //            objProps.PublicarVeiculo.IdPublicador = item.idParceiroAnuncio;
                                                                    //            objProps.PublicarVeiculo.anuncio.idAnuncioBNDV = (item.idAnuncio === null ? 0 : item.idAnuncio);
                                                                    //            objProps.ModalICarrosMoto.showModal = true;
                                                                    //            objProps.PublicarVeiculo.errorText = "";
                                                                    //            objProps.PublicarVeiculo.successText = "";
                                                                    //            objProps.fnUpdateState(objProps);
                                                                    //        }}>
                                                                    //            <img
                                                                    //                src={
                                                                    //                    item.idAnuncio === null
                                                                    //                        ? require('../../../../assets/images/icones_portais/icarros-alpha.png').toString()
                                                                    //                        : require('../../../../assets/images/icones_portais/icarros.png').toString()
                                                                    //                }
                                                                    //            />
                                                                    //        </a>
                                                                    //    </OverlayTrigger>
                                                                    //    {
                                                                    //        item.idAnuncio !== null && item.urlAnuncio !== ""
                                                                    //            ? <p><a href={item.urlAnuncio} target="_blank"><i className="fa fa-external-link" aria-hidden="true"></i></a></p>
                                                                    //            : null
                                                                    //    }
                                                                    //</td>
                                                                    : item.idParceiroAnuncio === 3
                                                                        //WebMotors
                                                                        ? <td
                                                                            style={{ textAlign: 'center' }}
                                                                            key={item.idParceiroAnuncio.toString() + index2 + entry.id}>
                                                                            <OverlayTrigger
                                                                                placement="top"
                                                                                overlay={<Tooltip id="tooltip"><strong>WebMotors</strong></Tooltip>}>
                                                                                <a onClick={() => {
                                                                                    objProps.PublicarVeiculo.idVeiculo = entry.id;
                                                                                    objProps.PublicarVeiculo.IdPublicador = item.idParceiroAnuncio;
                                                                                    objProps.PublicarVeiculo.anuncio.idAnuncioBNDV = (item.idAnuncio === null ? 0 : item.idAnuncio);
                                                                                    objProps.ModalWebMotorsMoto.showModal = true;
                                                                                    objProps.fnUpdateState(objProps);
                                                                                }}>
                                                                                    <img
                                                                                        src={
                                                                                            item.idAnuncio === null
                                                                                                ? require('../../../../assets/images/icones_portais/webmotors-alpha.png').toString()
                                                                                                : require('../../../../assets/images/icones_portais/webmotors.png').toString()
                                                                                        }
                                                                                    />
                                                                                </a>
                                                                            </OverlayTrigger>
                                                                            {
                                                                                item.idAnuncio !== null && item.urlAnuncio !== ""
                                                                                    ? <p><a href={item.urlAnuncio} target="_blank"><i className="fa fa-external-link" aria-hidden="true"></i></a></p>
                                                                                    : null
                                                                            }
                                                                        </td>
                                                                        : item.idParceiroAnuncio === 5
                                                                            // Auto Line
                                                                            ? <td
                                                                                style={{ textAlign: 'center' }}
                                                                                key={item.idParceiroAnuncio.toString() + index2 + entry.id}>
                                                                                <OverlayTrigger
                                                                                    placement="top"
                                                                                    overlay={<Tooltip id="tooltip"><strong>Auto Line</strong></Tooltip>}>
                                                                                    <a onClick={() => {
                                                                                        objProps.PublicarVeiculo.idVeiculo = entry.id;
                                                                                        objProps.PublicarVeiculo.IdPublicador = item.idParceiroAnuncio;
                                                                                        objProps.PublicarVeiculo.anuncio.idAnuncioBNDV = (item.idAnuncio === null ? 0 : item.idAnuncio);
                                                                                        objProps.ModalAutoLineMoto.showModal = true;
                                                                                        objProps.fnUpdateState(objProps);
                                                                                    }}>
                                                                                        <img
                                                                                            src={
                                                                                                item.idAnuncio === null
                                                                                                    ? require('../../../../assets/images/icones_portais/autoline-alpha.png').toString()
                                                                                                    : require('../../../../assets/images/icones_portais/autoline.png').toString()
                                                                                            }
                                                                                        />
                                                                                    </a>
                                                                                </OverlayTrigger>
                                                                                {
                                                                                    item.idAnuncio !== null && item.urlAnuncio !== ""
                                                                                        ? <p><a href={item.urlAnuncio} target="_blank"><i className="fa fa-external-link" aria-hidden="true"></i></a></p>
                                                                                        : null
                                                                                }

                                                                            </td>
                                                                            : item.idParceiroAnuncio === 4
                                                                                // OLX
                                                                                ? <td
                                                                                    style={{ textAlign: 'center' }}
                                                                                    key={item.idParceiroAnuncio.toString() + index2 + entry.id}>
                                                                                    <OverlayTrigger
                                                                                        placement="top"
                                                                                        overlay={<Tooltip id="tooltip"><strong>OLX</strong></Tooltip>}>
                                                                                        <a onClick={() => {
                                                                                            if (item.statusAnuncio !== 1) {
                                                                                                objProps.PublicarVeiculo.idVeiculo = entry.id;
                                                                                                objProps.PublicarVeiculo.IdPublicador = item.idParceiroAnuncio;
                                                                                                if (item.statusAnuncio === 3)
                                                                                                    objProps.PublicarVeiculo.anuncio.idAnuncioBNDV = 0;
                                                                                                else
                                                                                                    objProps.PublicarVeiculo.anuncio.idAnuncioBNDV = (item.idAnuncio === null ? 0 : item.idAnuncio);
                                                                                                objProps.ModalOLXMoto.showModal = true;
                                                                                                objProps.fnUpdateState(objProps);
                                                                                            }
                                                                                        }}>
                                                                                            <img
                                                                                                src={
                                                                                                    item.idAnuncio === null || item.statusAnuncio === 3
                                                                                                        ? require('../../../../assets/images/icones_portais/olx-alpha.png').toString()
                                                                                                        : require('../../../../assets/images/icones_portais/olx.png').toString()
                                                                                                }
                                                                                            />
                                                                                        </a>
                                                                                    </OverlayTrigger>
                                                                                    {
                                                                                        item.idAnuncio !== null
                                                                                            && item.urlAnuncio !== ""
                                                                                            && item.statusAnuncio == 2
                                                                                            ? <p><a href={item.urlAnuncio} target="_blank"><i className="fa fa-external-link" aria-hidden="true"></i></a></p>
                                                                                            : null
                                                                                    }
                                                                                    {
                                                                                        item.statusAnuncio === 1
                                                                                            ? <p><span className={"label label-warning"}>{"aguardando OLX"}</span></p>
                                                                                            : null
                                                                                    }
                                                                                    {
                                                                                        item.statusAnuncio === 3
                                                                                            ? <OverlayTrigger
                                                                                                placement="top"
                                                                                                overlay={<Tooltip id="tooltip"><strong>{item.erroAnuncio}</strong></Tooltip>}>
                                                                                                <p><span className={"label label-danger"}>{"ver erro"}</span></p>
                                                                                            </OverlayTrigger>
                                                                                            : null
                                                                                    }
                                                                                </td>
                                                                                : item.idParceiroAnuncio === 2
                                                                                    // MeuCarroNovo
                                                                                    ? null
                                                                                    : item.idParceiroAnuncio === 6
                                                                                        // Mercado Livre
                                                                                        ? <td
                                                                                            style={{ textAlign: 'center' }}
                                                                                            key={item.idParceiroAnuncio.toString() + index2 + entry.id}>
                                                                                            <OverlayTrigger
                                                                                                placement="top"
                                                                                                overlay={<Tooltip id="tooltip"><strong>Mercado Livre</strong></Tooltip>}>
                                                                                                <a onClick={() => {
                                                                                                    objProps.PublicarVeiculo.idVeiculo = entry.id;
                                                                                                    objProps.PublicarVeiculo.IdPublicador = item.idParceiroAnuncio;
                                                                                                    objProps.PublicarVeiculo.anuncio.idAnuncioBNDV = (item.idAnuncio === null ? 0 : item.idAnuncio);
                                                                                                    objProps.ModalMercadoLivreMoto.showModal = true;
                                                                                                    objProps.fnUpdateState(objProps);
                                                                                                }}>
                                                                                                    <img
                                                                                                        src={
                                                                                                            item.idAnuncio === null
                                                                                                                ? require('../../../../assets/images/icones_portais/mercadolivre-alpha.png').toString()
                                                                                                                : require('../../../../assets/images/icones_portais/mercadolivre.png').toString()
                                                                                                        }
                                                                                                    />
                                                                                                </a>
                                                                                            </OverlayTrigger>
                                                                                            {
                                                                                                item.idAnuncio !== null && item.urlAnuncio !== ""
                                                                                                    ? <p><a href={item.urlAnuncio} target="_blank"><i className="fa fa-external-link" aria-hidden="true"></i></a></p>
                                                                                                    : null
                                                                                            }  
                                                                                                </td>
                                                                                            //{item.qualidadeAnuncioStatus === 1 && item.idAnuncio !== null ?
                                                                                            //    <OverlayTrigger
                                                                                            //        placement="top"
                                                                                            //        overlay={<Tooltip id="tooltip"><strong>Aguardando Verificação</strong></Tooltip>}>
                                                                                            //        <p><span style={{ color: 'Black' }}><i className="fa fa-info-circle fa-lg" aria-hidden="true"></i></span></p>
                                                                                            //    </OverlayTrigger>
                                                                                            //    : null
                                                                                            //}
                                                                                            //{item.qualidadeAnuncioStatus === 2 && item.idAnuncio !== null ?
                                                                                            //    <OverlayTrigger
                                                                                            //        placement="top"
                                                                                            //        overlay={<Tooltip id="tooltip"><strong>Anúncio com Recomendações</strong></Tooltip>}>
                                                                                            //        <p><a onClick={() => {
                                                                                            //            objProps.PublicarVeiculo.idVeiculo = entry.id;
                                                                                            //            objProps.PublicarVeiculo.IdPublicador = item.idParceiroAnuncio;
                                                                                            //            objProps.PublicarVeiculo.anuncio.idAnuncioBNDV = (item.idAnuncio === null ? 0 : item.idAnuncio);
                                                                                            //            objProps.PublicarVeiculo.anuncio.qualidadeAnuncioStatus = item.qualidadeAnuncioStatus;
                                                                                            //            objProps.ModalRecomendacaoMercadoLivre.showModal = true;
                                                                                            //            objProps.fnUpdateState(objProps);
                                                                                            //        }}>
                                                                                            //            <span style={{ color: 'Orange' }}><i className="fa fa-info-circle fa-lg" aria-hidden="true"></i></span></a></p>
                                                                                            //    </OverlayTrigger>
                                                                                            //    : null
                                                                                            //}
                                                                                            //{item.qualidadeAnuncioStatus === 3 && item.idAnuncio !== null ?
                                                                                            //    <OverlayTrigger
                                                                                            //        placement="top"
                                                                                            //        overlay={<Tooltip id="tooltip"><strong>Anúncio com Penalizações</strong></Tooltip>}>
                                                                                            //        <p><a onClick={() => {
                                                                                            //            objProps.PublicarVeiculo.idVeiculo = entry.id;
                                                                                            //            objProps.PublicarVeiculo.IdPublicador = item.idParceiroAnuncio;
                                                                                            //            objProps.PublicarVeiculo.anuncio.idAnuncioBNDV = (item.idAnuncio === null ? 0 : item.idAnuncio);
                                                                                            //            objProps.ModalRecomendacaoMercadoLivre.showModal = true;
                                                                                            //            objProps.PublicarVeiculo.anuncio.qualidadeAnuncioStatus = item.qualidadeAnuncioStatus;
                                                                                            //            objProps.fnUpdateState(objProps);
                                                                                            //        }}>
                                                                                            //            <span style={{ color: 'Red' }}><i className="fa fa-info-circle fa-lg" aria-hidden="true"></i></span></a></p>
                                                                                            //    </OverlayTrigger>
                                                                                            //    : null
                                                                                            //}
                                                                                            //{item.qualidadeAnuncioStatus === 4 && item.idAnuncio !== null ?
                                                                                            //    <OverlayTrigger
                                                                                            //        placement="top"
                                                                                            //        overlay={<Tooltip id="tooltip"><strong>Cliente Ciente</strong></Tooltip>}>
                                                                                            //        <p><a onClick={() => {
                                                                                            //            objProps.PublicarVeiculo.idVeiculo = entry.id;
                                                                                            //            objProps.PublicarVeiculo.IdPublicador = item.idParceiroAnuncio;
                                                                                            //            objProps.PublicarVeiculo.anuncio.idAnuncioBNDV = (item.idAnuncio === null ? 0 : item.idAnuncio);
                                                                                            //            objProps.ModalRecomendacaoMercadoLivre.showModal = true;
                                                                                            //            objProps.PublicarVeiculo.anuncio.qualidadeAnuncioStatus = item.qualidadeAnuncioStatus;
                                                                                            //            objProps.fnUpdateState(objProps);
                                                                                            //        }}>
                                                                                            //            <span style={{ color: 'Blue' }}><i className="fa fa-info-circle fa-lg" aria-hidden="true"></i></span></a></p>
                                                                                            //    </OverlayTrigger>
                                                                                            //    : null
                                                                                            //}

                                                                                            //{item.qualidadeAnuncioStatus === 5 && item.idAnuncio !== null ?
                                                                                            //    <OverlayTrigger
                                                                                            //        placement="top"
                                                                                            //        overlay={<Tooltip id="tooltip"><strong>Tudo Ok!</strong></Tooltip>}>
                                                                                            //        <p><span style={{ color: 'Green' }}><i className="fa fa-info-circle fa-lg" aria-hidden="true"></i></span></p>
                                                                                            //    </OverlayTrigger>
                                                                                            //    : null
                                                                                            //}
                                                                                      
                                                                                        : item.idParceiroAnuncio === 8
                                                                                            // Carro São José
                                                                                            ? <td
                                                                                                style={{ textAlign: 'center' }}
                                                                                                key={item.idParceiroAnuncio.toString() + index2 + entry.id}>
                                                                                                <OverlayTrigger
                                                                                                    placement="top"
                                                                                                    overlay={<Tooltip id="tooltip"><strong>Carro São José</strong></Tooltip>}>
                                                                                                    <a onClick={() => {
                                                                                                        objProps.PublicarVeiculo.idVeiculo = entry.id;
                                                                                                        objProps.PublicarVeiculo.IdPublicador = item.idParceiroAnuncio;
                                                                                                        objProps.PublicarVeiculo.anuncio.idAnuncioBNDV = (item.idAnuncio === null ? 0 : item.idAnuncio);
                                                                                                        objProps.ModalCarroSaoJoseMoto.showModal = true;
                                                                                                        objProps.fnUpdateState(objProps);
                                                                                                    }}>
                                                                                                        <img
                                                                                                            src={
                                                                                                                item.idAnuncio === null
                                                                                                                    ? require('../../../../assets/images/icones_portais/carrosaojose-alpha.png').toString()
                                                                                                                    : require('../../../../assets/images/icones_portais/carrossaojose.png').toString()
                                                                                                            }
                                                                                                        />
                                                                                                    </a>
                                                                                                </OverlayTrigger>
                                                                                                {
                                                                                                    item.idAnuncio !== null && item.urlAnuncio !== ""
                                                                                                        ? <p><a href={item.urlAnuncio} target="_blank"><i className="fa fa-external-link" aria-hidden="true"></i></a></p>
                                                                                                        : null
                                                                                                }
                                                                                            </td>
                                                                                            : item.idParceiroAnuncio === 16
                                                                                                // New Motors
                                                                                                ? null
                                                                                                : item.idParceiroAnuncio === 14
                                                                                                    // Auto Shopping Global
                                                                                                    ? null
                                                                                                    : item.idParceiroAnuncio === 19
                                                                                                        // Auto Shopping Pontal
                                                                                                        ? null
                                                                                                        : item.idParceiroAnuncio === 18
                                                                                                            // CompreCar
                                                                                                            ? null
                                                                                                            : item.idParceiroAnuncio === 12
                                                                                                                // Carro Brasil
                                                                                                                ? <td
                                                                                                                    style={{ textAlign: 'center' }}
                                                                                                                    key={item.idParceiroAnuncio.toString() + index2 + entry.id}>
                                                                                                                    <OverlayTrigger
                                                                                                                        placement="top"
                                                                                                                        overlay={<Tooltip id="tooltip"><strong>Carro Brasil</strong></Tooltip>}>
                                                                                                                        <a onClick={() => {
                                                                                                                            objProps.PublicarVeiculo.idVeiculo = entry.id;
                                                                                                                            objProps.PublicarVeiculo.IdPublicador = item.idParceiroAnuncio;
                                                                                                                            objProps.PublicarVeiculo.anuncio.idAnuncioBNDV = (item.idAnuncio === null ? 0 : item.idAnuncio);
                                                                                                                            objProps.ModalCarroBrasilMoto.showModal = true;
                                                                                                                            objProps.fnUpdateState(objProps);
                                                                                                                        }}>
                                                                                                                            <img
                                                                                                                                src={
                                                                                                                                    item.idAnuncio === null
                                                                                                                                        ? require('../../../../assets/images/icones_portais/carrobrasil-alpha.png').toString()
                                                                                                                                        : require('../../../../assets/images/icones_portais/carrobrasil.png').toString()
                                                                                                                                }
                                                                                                                            />
                                                                                                                        </a>
                                                                                                                    </OverlayTrigger>
                                                                                                                    {
                                                                                                                        item.idAnuncio !== null && item.urlAnuncio !== ""
                                                                                                                            ? <p><a href={item.urlAnuncio} target="_blank"><i className="fa fa-external-link" aria-hidden="true"></i></a></p>
                                                                                                                            : null
                                                                                                                    }
                                                                                                                </td>                                                                                                                
                                                                                                                : item.idParceiroAnuncio === 15
                                                                                                                    // Vale Veículos
                                                                                                                    ? null
                                                                                                                    : item.idParceiroAnuncio === 10
                                                                                                                        // InfoCarro
                                                                                                                        ? null
                                                                                                                        : item.idParceiroAnuncio === 13
                                                                                                                            // Tem Usados
                                                                                                                            ? null
                                                                                                                            : item.idParceiroAnuncio === 21
                                                                                                                                // Auto Shopping Imigrantes
                                                                                                                                ? null
                                                                                                                                : item.idParceiroAnuncio === 22
                                                                                                                                    // MobiAuto
                                                                                                                                    ? null
                                                                                                                                    : item.idParceiroAnuncio === 24
                                                                                                                                        // UsadosBR
                                                                                                                                        ? <td
                                                                                                                                            style={{ textAlign: 'center' }}
                                                                                                                                            key={item.idParceiroAnuncio.toString() + index2 + entry.id}>
                                                                                                                                            <OverlayTrigger
                                                                                                                                                placement="top"
                                                                                                                                                overlay={<Tooltip id="tooltip"><strong>Usadosbr</strong></Tooltip>}>
                                                                                                                                                <a onClick={() => {
                                                                                                                                                    objProps.PublicarVeiculo.idVeiculo = entry.id;
                                                                                                                                                    objProps.PublicarVeiculo.IdPublicador = item.idParceiroAnuncio;
                                                                                                                                                    objProps.PublicarVeiculo.anuncio.idAnuncioBNDV = (item.idAnuncio === null ? 0 : item.idAnuncio);
                                                                                                                                                    objProps.ModalUsadosBRMoto.showModal = true;
                                                                                                                                                    objProps.fnUpdateState(objProps);
                                                                                                                                                }}>
                                                                                                                                                    <img
                                                                                                                                                        src={
                                                                                                                                                            item.idAnuncio === null
                                                                                                                                                                ? require('../../../../assets/images/icones_portais/usadosbr-alpha.png').toString()
                                                                                                                                                                : require('../../../../assets/images/icones_portais/usadosbr.png').toString()
                                                                                                                                                        }
                                                                                                                                                    />
                                                                                                                                                </a>
                                                                                                                                            </OverlayTrigger>
                                                                                                                                            {
                                                                                                                                                item.idAnuncio !== null && item.urlAnuncio !== ""
                                                                                                                                                    ? <p><a href={item.urlAnuncio} target="_blank"><i className="fa fa-external-link" aria-hidden="true"></i></a></p>
                                                                                                                                                    : null
                                                                                                                                            }
                                                                                                                                        </td>
                                                                                                                                    : null
                                                            )
                                                        )
                                                    })
                                                : null
                                        )
                                    })
                                }
                            </tr>
                        
                        </tbody>
                    </table>
                </div>
            </div>
            <div className="col-md-3 b-r">
                <small style={{ fontWeight: 600 }}>Mídia Social</small>
                <div className="table-responsive">
                    <table className="table table-custom cabra-table cabra-table-link" id="basic-usage">
                        <tbody>
                            <tr>
                                {
                                    this.props.ListaVeiculos.results.map(function (entry, index) {
                                        return (
                                            entry.id === parseInt(objProps.children.toString())
                                                ? objProps.ListaVeiculos.results[index].portais.length === 0
                                                    ? <div key={0} className="alert alert-warning" role="alert">Você não possui nenhuma mídia social configurada.</div>
                                                    : objProps.ListaVeiculos.results[index].portais.map(function (item, index2) {
                                                        return (
                                                            (
                                                                item.idParceiroAnuncio === 20
                                                                    // Site do Cliente
                                                                    ?
                                                                    <td
                                                                        style={{ textAlign: 'center' }}
                                                                        key={item.idParceiroAnuncio.toString() + index2 + entry.id}>
                                                                        <OverlayTrigger
                                                                            placement="top"
                                                                            overlay={<Tooltip id="tooltip"><strong>Web Site Loja</strong></Tooltip>}>
                                                                            <a onClick={() => {
                                                                                objProps.PublicarVeiculo.idVeiculo = entry.id;
                                                                                objProps.PublicarVeiculo.IdPublicador = item.idParceiroAnuncio;
                                                                                objProps.PublicarVeiculo.anuncio.idAnuncioBNDV = (item.idAnuncio === null ? 0 : item.idAnuncio);
                                                                                objProps.ModalWebSiteBNDVMoto.showModal = true;
                                                                                objProps.fnUpdateState(objProps);
                                                                            }}>
                                                                                <img
                                                                                    src={
                                                                                        item.idAnuncio === null
                                                                                            ? require('../../../../assets/images/icones_portais/bndvSites-alpha.png').toString()
                                                                                            : require('../../../../assets/images/icones_portais/bndvSites.png').toString()
                                                                                    }
                                                                                />
                                                                            </a>
                                                                        </OverlayTrigger>
                                                                        {
                                                                            item.idAnuncio !== null && item.urlAnuncio !== ""
                                                                                ? <p><a href={item.urlAnuncio} target="_blank"><i className="fa fa-external-link" aria-hidden="true"></i></a></p>
                                                                                : null
                                                                        }
                                                                    </td>
                                                                    : item.idParceiroAnuncio === 17
                                                                        // Facebook
                                                                        ? <td
                                                                            style={{ textAlign: 'center' }}
                                                                            key={item.idParceiroAnuncio.toString() + index2 + entry.id}>
                                                                            <OverlayTrigger
                                                                                placement="top"
                                                                                overlay={<Tooltip id="tooltip"><strong>Facebok</strong></Tooltip>}>
                                                                                <a onClick={() => {
                                                                                    objProps.PublicarVeiculo.idVeiculo = entry.id;
                                                                                    objProps.PublicarVeiculo.IdPublicador = item.idParceiroAnuncio;
                                                                                    objProps.PublicarVeiculo.anuncio.idAnuncioBNDV = (item.idAnuncio === null ? 0 : item.idAnuncio);
                                                                                    objProps.ModalFacebookMoto.showModal = true;
                                                                                    objProps.fnUpdateState(objProps);
                                                                                }}>
                                                                                    <img
                                                                                        src={
                                                                                            item.idAnuncio === null
                                                                                                ? require('../../../../assets/images/icones_portais/facebook-alpha.png').toString()
                                                                                                : require('../../../../assets/images/icones_portais/facebook.png').toString()
                                                                                        }
                                                                                    />
                                                                                </a>
                                                                            </OverlayTrigger>
                                                                            {
                                                                                item.idAnuncio !== null && item.urlAnuncio !== ""
                                                                                    ? <p><a href={item.urlAnuncio} target="_blank"><i className="fa fa-external-link" aria-hidden="true"></i></a></p>
                                                                                    : null
                                                                            }
                                                                        </td>
                                                                        : null
                                                            )
                                                        )
                                                    })
                                                : null
                                        )
                                    })
                                }
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    }
}


export default connect(
    state => state.marketing_publicador,
    dispatch => bindActionCreators(actionCreators, dispatch),
    null, {}
)(ListaPublicadorPortaisMoto);