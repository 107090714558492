import * as React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actionCreators } from '../../../../store/crm/CrmConfiguracao';

import Formsy from 'formsy-react';
import { Row, Modal } from 'react-bootstrap';
import ProgerssBar from '../../../commons/ProgressBar';
import AlertError from '../../../commons/AlertError';
import AlertSuccess from '../../../commons/AlertSuccess';
import InputTextValidated from '../../../commons/InputTextValidated';
import BtnSalvar from '../../../commons/BtnSalvar';
import BtnCancelar from '../../../commons/BtnCancelar';

class ModalSiteCliente extends React.Component {
    constructor() {
        super();
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    async componentDidMount() {
        await this.props.fnObterEmpresaCanalLead();

        if (this.props.configuracaoPortais.modalPortais.ativado) {
            this.props.configuracaoPortais.modalPortais.modalSiteCliente.email = this.props.configuracaoPortais.modalPortais.email;
            await this.props.fnUpdateState(this.props);
        }
        else {
            this.props.configuracaoPortais.modalPortais.modalSiteCliente.email = '';
            await this.props.fnUpdateState(this.props);
        }
    }

    async handleSubmit(e) {
        this.props.configuracaoPortais.modalPortais.email = this.props.configuracaoPortais.modalPortais.modalSiteCliente.email;
        this.props.configuracaoPortais.modalPortais.jsonAutenticacao = '';
        await this.props.fnUpdateState(this.props);

        if (!this.props.configuracaoPortais.modalPortais.ativado) {
            await this.props.fnInsereEmpresaCanalLead();
        }
        else {
            await this.props.fnAtualizaEmpresaCanalLead();
        }
    }

    handleOnHide() {
        this.props.configuracaoPortais.modalPortais.modalSiteCliente.showModal = false;
        this.props.fnUpdateState(this.props);
    }

    render() {
        return <Modal
            show={this.props.configuracaoPortais.modalPortais.modalSiteCliente.showModal}
            onHide={this.handleOnHide.bind(this)}
        >
            <Formsy onValidSubmit={this.handleSubmit}>
                <Modal.Header closeButton>
                    <h4 className="modal-title custom-font">Configurar Site Cliente</h4>
                </Modal.Header>
                <Modal.Body>
                    <AlertSuccess
                        successText={this.props.configuracaoPortais.modalPortais.successText}
                        alertVisible={this.props.configuracaoPortais.modalPortais.successText != '' && this.props.configuracaoPortais.modalPortais.successText != undefined ? true : false}
                        onDismiss={(alertVisible, successText) => {
                            this.props.configuracaoPortais.modalPortais.successText = successText;
                            this.props.fnUpdateState(this.props);
                        }}
                    />
                    <AlertError
                        errorText={this.props.configuracaoPortais.modalPortais.errorText}
                        alertVisible={this.props.configuracaoPortais.modalPortais.errorText != '' && this.props.configuracaoPortais.modalPortais.errorText != undefined ? true : false}
                        onDismiss={(alertVisible, errorText) => {
                            this.props.configuracaoPortais.modalPortais.errorText = errorText;
                            this.props.fnUpdateState(this.props);
                        }}
                    />
                    {
                        this.props.configuracaoPortais.modalPortais.isLoading
                            ? <ProgerssBar />
                            : <Row>
                                <InputTextValidated
                                    name="txtEmail"
                                    type="text"
                                    className="col-md-12"
                                    placeholder="Informe o email"
                                    onChange={(name, value) => {
                                        this.props.configuracaoPortais.modalPortais.modalSiteCliente.email = value;
                                        this.props.fnUpdateState(this.props);
                                    }}
                                    value={this.props.configuracaoPortais.modalPortais.modalSiteCliente.email}
                                    title="Email:"
                                    validations="isEmail"
                                    validationError="Informe um email válido"
                                    required
                                />
                            </Row>
                    }
                </Modal.Body>
                <Modal.Footer>
                    {this.props.configuracaoPortais.modalPortais.ativado == true
                        ? <BtnCancelar
                            type="button"
                            text=" Remover Portal"
                            textLoading=" Aguarde..."
                            isLoading={this.props.configuracaoPortais.modalPortais.isLoading}
                            onClick={async () => {
                                await this.props.fnDeleteEmpresaCanalLead();
                                this.props.configuracaoPortais.modalPortais.modalSiteCliente.showModal = false;
                                this.props.fnUpdateState(this.props);
                            }}
                        />
                        : null}
                    <BtnSalvar
                        type="submit"
                        text={this.props.configuracaoPortais.modalPortais.ativado == false ? " Salvar Configuração" : " Alterar Configuração"}
                        textLoading=" Aguarde..."
                        isLoading={this.props.configuracaoPortais.modalPortais.isLoading}
                    />
                </Modal.Footer>
            </Formsy>
        </Modal>;
    }
}

export default connect(
    state => state.crm_configuracao,
    dispatch => bindActionCreators(actionCreators, dispatch),
    null, {}
)(ModalSiteCliente);