import * as React from 'react';
import HeaderPage from '../components/commons/HeaderPage';

export default class Home extends React.Component {
    render() {
        return (
            <div className="page">
                <HeaderPage path=' BNDV' name='Home' />
                <div className="row">
                    <div className="col-lg-5 col-md-5 col-sm-12 col-xs-12">
                        <div className="panel panel-default">
                            <div className="panel-heading bg-white">
                                Publicador 2.0 <span className="label ml-5 label-primary">Em construção</span>
                            </div>
                            <div className="panel-body">
                                <p>Estamos construindo no BNDV 2.0, uma nova maneira de publicar seus veículos nos portais parceiros. Com as novas tecnologias implantadas no sistema, você perceberá que todos os processos serão feitos de forma mais rápida e prática. </p>
                                <p>Redesenhamos o layout pensando em você. Agora, acessar o BNDV de seu celular ou tablet fica muito mais confortável e isso lhe trará mais produtividade na hora de cadastrar e publicar seus veículos.</p>
                                <br /><br />
                                <p>Aproveite as novidades do BNDV 2.0.</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12">
                        <ul className="list-unstyled">
                            <li style={{ padding: '1em .5em', margin: '.5em 0', background: '#fff', color: '#969696', borderLeft: '3px solid #ff0010' }}><img src={require('../assets/images/icones_portais/webmotors.png').toString()} alt="Webmotors" width="30" /> WebMotors</li>
                            <li style={{ padding: '1em .5em', margin: '.5em 0', background: '#fff', color: '#969696', borderLeft: '3px solid #717273' }}><img src={require('../assets/images/icones_portais/icarros.png').toString()} alt="ICarros" width="30" /> ICarros</li>
                            <li style={{ padding: '1em .5em', margin: '.5em 0', background: '#fff', color: '#969696', borderLeft: '3px solid #60207d' }}><img src={require('../assets/images/icones_portais/olx.png').toString()} alt="OLX" width="30" /> OLX</li>
                            <li style={{ padding: '1em .5em', margin: '.5em 0', background: '#fff', color: '#969696', borderLeft: '3px solid #fce402' }}><img src={require('../assets/images/icones_portais/mercadolivre.png').toString()} alt="Mercado Livre" width="30" /> Mercado Livre</li>
                            <li style={{ padding: '1em .5em', margin: '.5em 0', background: '#fff', color: '#969696', borderLeft: '3px solid #cc1332' }}><img src={require('../assets/images/icones_portais/autoline.png').toString()} alt="AutoLine" width="30" /> AutoLine</li>
                        </ul>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-6 col-xs-12">
                        <div className="tile text-center" style={{
                            color: '#ffffff',
                            background: '#2585cc',
                            paddingBottom: '2em',
                            padding: '1.5em 0 2em 0'
                        }}>
                            <div className="row">
                                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                    <div style={{ padding: '1.5em 0 2em 0' }}>
                                        <span style={{ fontSize: 'font-size: 80px' }}>18</span>
                                        <p><small>Portais Integrados</small></p>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12" style={{ borderRight: '1px solid #ffffff' }}>
                                    <span style={{ fontSize: '40px' }}>1</span>
                                    <p><small>Em construção</small></p>
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12 ">
                                    <span style={{ fontSize: '40px' }}>2</span>
                                    <p><small>Em análise</small></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row pt-3">
                    <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12">
                        <div style={{ textAlign: 'center', padding: '2em', color: '#ffffff', margin: '.5em 0', background: 'linear-gradient(to bottom, #2757b3 0%,#93adec 100%)' }}>
                            <p>Facebook</p>
                            <i className="fa fa-facebook" style={{ fontSize: '20px' }}></i>
                            <hr />
                            <p><a href="https://www.facebook.com/bndvbrasil/" target="_blank" rel="noopener noreferrer" style={{ color: '#ffffff' }}>Curta nossa página</a></p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12">
                        <div style={{ textAlign: 'center', padding: '2em', color: '#ffffff', margin: '.5em 0', background: 'linear-gradient(to bottom, #de2b2b 0%,#e29297 100%)' }}>
                            <p>Youtube</p>
                            <i className="fa fa-youtube-play" style={{ fontSize: '20px' }}></i>
                            <hr />
                            <p><a href="https://www.youtube.com/channel/UCfRPE500w3okVjTa3-xcQSQ" rel="noopener noreferrer" target="_blank" style={{ color: '#ffffff' }}>Se inscreva no canal</a></p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12">
                        <div style={{ textAlign: 'center', padding: '2em', color: '#ffffff', margin: '.5em 0', background: 'linear-gradient(to bottom, #258dcc 0%,#aad7f3 100%)' }}>
                            <p>BNDV Blog</p>
                            <i className="fa fa-globe" style={{ fontSize: '20px' }}></i>
                            <hr />
                            <p><a href="http://blog.bndv.com.br" target="_blank" rel="noopener noreferrer" style={{ color: '#ffffff' }}>Acesse as novidades</a></p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12">
                        <div style={{ textAlign: 'center', padding: '2em', color: '#ffffff', margin: '.5em 0', background: 'linear-gradient(to bottom, #f56711 0%,#f3be9f 100%)' }}>
                            <p>BNDV Sites</p>
                            <i className="fa fa-code" style={{ fontSize: '20px' }}></i>
                            <hr />
                            <p><a href="http://dev.bndv.com.br" target="_blank" rel="noopener noreferrer" style={{ color: '#ffffff' }}>Escolha um template</a></p>
                        </div>
                    </div>
                </div>
            </div>
        );
    };
}