import * as React from 'react';

export class BNDVCompletaResultadoOutrosEstados extends React.Component {
    render() {
        var jsonObjectPesquisa = JSON.parse(this.props.data);
        var leiloes;

        if (JSON.stringify(jsonObjectPesquisa.leilao.leiloes) != null) {
            jsonObjectPesquisa.leilao = (jsonObjectPesquisa.leilao.leiloes == null ? [] : jsonObjectPesquisa.leilao.leiloes);
        }

        if (jsonObjectPesquisa.leilao.length == 0) {
            leiloes = <div className="row">
                <div className="col-md-2">&nbsp; </div>
                <div className="col-md-10">Não foram encontrados registros de oferta em leilão</div>
            </div>;
        }
        else {
            leiloes = jsonObjectPesquisa.leilao.map(function (rowData, index) {
                return <div key={index}>
                    <h4>Ocorrência {index + 1}</h4>
                    <div className="row">
                        <div className="col-md-2">Placa: </div>
                        <div className="col-md-4">{rowData.placa}</div>
                        <div className="col-md-2">Chassi: </div>
                        <div className="col-md-4">{rowData.chassi}</div>
                    </div>
                    <div className="row">
                        <div className="col-md-2">Marca: </div>
                        <div className="col-md-4">{rowData.marca}</div>
                        <div className="col-md-2">Modelo: </div>
                        <div className="col-md-4">{rowData.modelo}</div>
                    </div>
                    <div className="row">
                        <div className="col-md-2">Renavam: </div>
                        <div className="col-md-4">{rowData.renavam}</div>
                        <div className="col-md-2">Ano Modelo: </div>
                        <div className="col-md-4">{rowData.anoModelo}</div>
                    </div>
                    <div className="row">
                        <div className="col-md-2">Cor: </div>
                        <div className="col-md-4">{rowData.cor}</div>
                        <div className="col-md-2">Combustível: </div>
                        <div className="col-md-4">{rowData.combustivel}</div>
                    </div>
                    <div className="row">
                        <div className="col-md-2">Condição Veic: </div>
                        <div className="col-md-4">{rowData.condicaoVeiculo}</div>
                        <div className="col-md-2">Data Leilão: </div>
                        <div className="col-md-4">{rowData.dataLeilao}</div>
                    </div>
                    <div className="row">
                        <div className="col-md-2">Comitente: </div>
                        <div className="col-md-4">{rowData.comitente}</div>
                        <div className="col-md-2">Lote: </div>
                        <div className="col-md-4">{rowData.lote}</div>
                    </div>
                </div>
            });
        }

        return <div>
            <div id="retorno-pesquisa">
                <h5 className="custom-font filled bg-blue">Dados do Veículo</h5>
                <div className="row">
                    <div className="col-md-2">Placa: </div>
                    <div className="col-md-4">{jsonObjectPesquisa.baseEstadual.dadosVeiculo.placa}</div>
                    <div className="col-md-2">Chassi: </div>
                    <div className="col-md-4">{jsonObjectPesquisa.baseEstadual.dadosVeiculo.chassi}</div>
                </div>
                <div className="row">
                    <div className="col-md-2">Município: </div>
                    <div className="col-md-4">{jsonObjectPesquisa.baseEstadual.dadosVeiculo.municipioEmplacamento}</div>
                    <div className="col-md-2">UF: </div>
                    <div className="col-md-4">{jsonObjectPesquisa.baseEstadual.dadosVeiculo.uf}</div>
                </div>
                <div className="row">
                    <div className="col-md-2">Procedência: </div>
                    <div className="col-md-4">{jsonObjectPesquisa.baseEstadual.dadosVeiculo.procedencia}</div>
                    <div className="col-md-2">Situação: </div>
                    <div className="col-md-4">{jsonObjectPesquisa.baseEstadual.dadosVeiculo.situacao}</div>
                </div>
                <div className="row">
                    <div className="col-md-2">Última Atuali.: </div>
                    <div className="col-md-4">{jsonObjectPesquisa.baseEstadual.dadosVeiculo.ultimaAtualizacao}</div>
                    <div className="col-md-2">Cor: </div>
                    <div className="col-md-4">{jsonObjectPesquisa.baseEstadual.dadosVeiculo.cor}</div>
                </div>
                <div className="row">
                    <div className="col-md-2">Marca: </div>
                    <div className="col-md-4">{jsonObjectPesquisa.baseEstadual.dadosVeiculo.marca}</div>
                    <div className="col-md-2">Combustível: </div>
                    <div className="col-md-4">{jsonObjectPesquisa.baseEstadual.dadosVeiculo.combustivel}</div>
                </div>
                <div className="row">
                    <div className="col-md-2">Ano Fab.: </div>
                    <div className="col-md-4">{jsonObjectPesquisa.baseEstadual.dadosVeiculo.anoFabricacao}</div>
                    <div className="col-md-2">Ano Mod.: </div>
                    <div className="col-md-4">{jsonObjectPesquisa.baseEstadual.dadosVeiculo.anoModelo}</div>
                </div>
                <div className="row">
                    <div className="col-md-2">Tipo: </div>
                    <div className="col-md-4">{jsonObjectPesquisa.baseEstadual.dadosVeiculo.tipo}</div>
                    <div className="col-md-2">Categoria: </div>
                    <div className="col-md-4">{jsonObjectPesquisa.baseEstadual.dadosVeiculo.categoria}</div>
                </div>
                <div className="row">
                    <div className="col-md-2">Espécie: </div>
                    <div className="col-md-4">{jsonObjectPesquisa.baseEstadual.dadosVeiculo.especie}</div>
                    <div className="col-md-2">Num. Motor: </div>
                    <div className="col-md-4">{(jsonObjectPesquisa.baseNacional != undefined) ? jsonObjectPesquisa.baseNacional.identificacaoVeiculoBIN.numeroMotor : "N/I"}</div>
                </div>
                <h5 className="custom-font filled bg-blue">Dados do Proprietário</h5>
                <div className="row">
                    <div className="col-md-2">Nome: </div>
                    <div className="col-md-10">{jsonObjectPesquisa.baseEstadual.dadosProprietario.nomeProprietario}</div>
                </div>
                <h5 className="custom-font filled bg-blue">Informações de Gravame</h5>
                <div className="row">
                    <div className="col-md-2">Restr.Financeira: </div>
                    <div className="col-md-4">{jsonObjectPesquisa.baseEstadual.gravames.tipoRestricaoFinanceira}</div>
                    <div className="col-md-2">Financeira: </div>
                    <div className="col-md-4">{jsonObjectPesquisa.baseEstadual.gravames.nomeAgenteFinanceiro}</div>
                </div>
                <div className="row">
                    <div className="col-md-2">Financiado: </div>
                    <div className="col-md-4">{jsonObjectPesquisa.baseEstadual.gravames.nomeFinanciado}</div>
                    <div className="col-md-2">CPF/CNPJ: </div>
                    <div className="col-md-4">{jsonObjectPesquisa.baseEstadual.gravames.cnpjcpfFinanc}</div>
                </div>
                <div className="row">
                    <div className="col-md-2">Data Inclusão: </div>
                    <div className="col-md-10">{jsonObjectPesquisa.baseEstadual.gravames.dataInclusaoIntensaoTrocaFinanc}</div>
                </div>
                <h5 className="custom-font filled bg-blue">Débitos / Multas</h5>
                <div className="row">
                    <div className="col-md-2">Multas: </div>
                    <div className="col-md-4">{jsonObjectPesquisa.baseEstadual.debitosMultas.valorTotalDebitoMultas}</div>
                    <div className="col-md-2">Licenciamento: </div>
                    <div className="col-md-4">{jsonObjectPesquisa.baseEstadual.debitosMultas.valorTotalDebitoLicenciamento}</div>
                </div>
                <div className="row">
                    <div className="col-md-2">IPVA: </div>
                    <div className="col-md-4">{jsonObjectPesquisa.baseEstadual.debitosMultas.valorTotalDebitoIpva}</div>
                    <div className="col-md-2">DPVAT: </div>
                    <div className="col-md-4">{jsonObjectPesquisa.baseEstadual.debitosMultas.valorTotalDebitoDpvat}</div>
                </div>
                <h5 className="custom-font filled bg-blue">Restrições</h5>
                <div className="row">
                    <div className="col-md-2">Restrição 1: </div>
                    <div className="col-md-4">{jsonObjectPesquisa.baseEstadual.restricoes.restricao1}</div>
                    <div className="col-md-2">Restrição 2: </div>
                    <div className="col-md-4">{jsonObjectPesquisa.baseEstadual.restricoes.restricao2}</div>
                </div>
                <div className="row">
                    <div className="col-md-2">Restrição 3: </div>
                    <div className="col-md-4">{jsonObjectPesquisa.baseEstadual.restricoes.restricao3}</div>
                    <div className="col-md-2">Restrição 4: </div>
                    <div className="col-md-4">{jsonObjectPesquisa.baseEstadual.restricoes.restricao4}</div>
                </div>
                <h5 className="custom-font filled bg-blue">Histórico de Oferta em Leilão</h5>
                {leiloes}
                <h5 className="custom-font filled bg-blue">Risco de Comercialização</h5>
                <div className="row">
                    <div className="col-md-2">Parecer: </div>
                    <div className="col-md-10">
                        {
                            (jsonObjectPesquisa.parecerSinistro != null)
                                ? (jsonObjectPesquisa.parecerSinistro.parecer.indiceParecer == 1)
                                    ? 'Não foram encontrados registros para avaliar o veículo'
                                    : (jsonObjectPesquisa.parecerSinistro.parecer.indiceParecer == 2)
                                        ? <p className="text-warning">{jsonObjectPesquisa.parecerSinistro.parecer.parecerHistorico}</p>
                                        : <p className="text-lightred">{jsonObjectPesquisa.parecerSinistro.parecer.parecerHistorico}</p>
                                : (jsonObjectPesquisa.leilao.length > 0)
                                    ? (jsonObjectPesquisa.leilao[0].nomeFoto1 == "01.jpg")
                                        ? "Risco baixo de comercialização"
                                        : (jsonObjectPesquisa.leilao[0].nomeFoto1 == "02.jpg")
                                            ? <p className="text-warning">Risco médio de comercialização</p>
                                            : (jsonObjectPesquisa.leilao[0].nomeFoto1 == "03.jpg")
                                                ? <p className="text-lightred">Risco alto de comercialização</p>
                                                : "Não foram encontrados registros para avaliar o veículo"
                                    : "Não foram encontrados registros para avaliar o veículo"
                        }
                    </div>
                </div>
            </div>
        </div>;
    }
}