import $ from 'jquery';
import * as React from 'react';

// Faz o encode da URI para fazer o call na API
export function encodeURI(params) {
    var esc = encodeURIComponent;
    var query = Object.keys(params)
        .map(k => esc(k) + '=' + esc(params[k]))
        .join('&');

    return query;
}

export function json_to_uri(params) {
    return $.param(params);
}

export function formatDate(date) {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [year, month, day].join('-');
}

export function formatErrors(errors) {
    let message = "";
    for (let i = 0; i < errors.length; i++) {
        message += <p>errors[i].Value</p>;
    }
    return message;
}
export function formatArray(itens, value, text) {
    var arrData = [];
    if (itens.length > 0)
        arrData.push({ value: "0", text: "-- Selecione --" });
    itens.map((rowData, index) => {
        arrData.push({ value: rowData[value], text: rowData[text] });
    });
    return arrData;
}
