import * as React from 'react';

import HeaderPage from '../../components/commons/HeaderPage';
import SolicitarPagamentosForm from '../../components/analiserisco/pagamentos/SolicitarPagamentosForm';
import BNDVCompletaRFTab from '../../components/analiserisco/bndvcompletarf/BNDVCompletaRFTab';

export default class BndvCompletaRF extends React.Component {
    render() {
        return <div className='page'>
            <HeaderPage path=' Análise de Risco' name='Pesquisas Veiculares' subName='BNDV Completa + RF' />
            <SolicitarPagamentosForm />
            <BNDVCompletaRFTab />
        </div>;
    }
}