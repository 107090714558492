import * as React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actionCreators } from '../../../store/analiserisco/BNDVCompleta';

import Moment from 'react-moment';
import { FormattedNumber } from 'react-intl';
import { Row, Modal } from 'react-bootstrap';
import AlertDismissable from '../../commons/AlertDismissable';
import DateTimePicker from '../../commons/DateTimePicker';
import BtnPesquisar from '../../commons/BtnPesquisar';
import InputSelect from '../../commons/InputSelect';
import Section from '../../commons/Section';
import SectionHeader from '../../commons/SectionHeader';
import ListPager from '../../commons/ListPager';
import MaskedInput from '../../commons/MaskedInput';

class BNDVCompletaHistoricoForm extends React.Component {
    constructor() {
        super();
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {
        this.props.fnListaPesquisasHistorico();
    }

    handleSubmit(e) {
        e.preventDefault();
        this.props.Filters.PageNumber = 1;
        this.props.fnListaPesquisasHistorico();
    }

    render() {
        var _Props = this.props;
        return <Row>
            <div className="col-md-12">
                <Section>
                    <AlertDismissable
                        title="Ops algo deu errado!"
                        text={this.props.ErrorText}
                        show={this.props.ErrorText != '' && this.props.ErrorText != undefined ? true : false}
                        bsStyle="danger"
                    />
                    <SectionHeader title="Histórico de Solicitação de Pesquisas" />
                    <div className="tile-body">
                        <form name="frmPesquisas" role="form" id="frmPesquisas" onSubmit={this.handleSubmit}>
                            <Row>
                                <div className="form-group col-md-3">
                                    <label>Data inicial</label>
                                    <DateTimePicker
                                        onChange={(value) => {
                                            this.props.Filters.DataDe = value;
                                            this.props.fnUpdateState(this.props);
                                        }}
                                        value={this.props.Filters.DataDe}
                                    />
                                </div>
                                <div className="form-group col-md-3">
                                    <label>Data final</label>
                                    <DateTimePicker
                                        onChange={(value) => {
                                            this.props.Filters.DataAte = value;
                                            this.props.fnUpdateState(this.props);
                                        }}
                                        value={this.props.Filters.DataAte}
                                    />
                                </div>
                                <div className="form-group col-md-3">
                                    <label>Placa</label>
                                    <MaskedInput
                                        mask="#######"
                                        placeholder="XXXXXXX"
                                        placeholderChar=" "
                                        className="form-control"
                                        onChange={(name, value) => { this.props.Filters.Placa = value; this.props.fnUpdateState(this.props); }}
                                        value={this.props.Filters.Placa}
                                    />
                                </div>
                                <div className="form-group col-md-3">
                                    <label>Chassi</label>
                                    <MaskedInput
                                        mask="#################"
                                        placeholder="9BD781CD87TGD9876"
                                        placeholderChar=" "
                                        className="form-control"
                                        onChange={(name, value) => { this.props.Filters.Chassi = value; this.props.fnUpdateState(this.props); }}
                                        value={this.props.Filters.Chassi}
                                    />
                                </div>
                                <div className="form-group text-right">
                                    <div className='col-md-12'>
                                        <BtnPesquisar type="submit" isLoading={this.props.IsLoading} text=" Pesquisar" textLoading=" Aguarde..." />
                                    </div>
                                </div>
                            </Row>
                        </form>
                        <br />
                        <div className="table-responsive">
                            <div className="dataTables_wrapper form-inline dt-bootstrap no-footer">
                                <div className="dataTables_length" id="basic-usage_length">
                                    <InputSelect
                                        class="form-control input-sm"
                                        data={[{ value: '10', text: '10' }, { value: '25', text: '25' }, { value: '50', text: '50' }]}
                                        onChange={(name, value) => { this.props.Filters.PageSize = value; this.props.Filters.PageNumber = 1; this.props.fnUpdateState(this.props); this.props.fnListaPesquisasHistorico(); }}
                                        selectedValue={(this.props.Filters.PageSize == null) ? null : this.props.Filters.PageSize.toString()}
                                    /> registros por página
                </div>
                                <Row>
                                    <div className="col-sm-12">
                                        <table className="table table-custom dataTable no-footer">
                                            <thead>
                                                <tr role="row">
                                                    <th></th>
                                                    <th>Placa</th>
                                                    <th>Chassi</th>
                                                    <th>UF</th>
                                                    <th>Solicitado Por.</th>
                                                    <th>Data/Hora Solic.</th>
                                                    <th>Valor R$</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    (this.props.IsLoading)
                                                        ? <tr key={0} className="odd">
                                                            <td colSpan={7} className="dataTables_empty">
                                                                <div className="progress progress-striped active">
                                                                    <div className="progress-bar progress-bar-blue" role="progressbar" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100" style={{ width: "100%" }}>
                                                                        <span className="sr-only">100% Complete</span>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                        : (this.props.Results.length == 0)
                                                            ? <tr key={0} className="odd">
                                                                <td colSpan={8} className="dataTables_empty">
                                                                    <p className="text-warning">Não foram encontrados registros para a busca...</p>
                                                                </td>
                                                            </tr>
                                                            : this.props.Results.map(function (rowData, index) {
                                                                return (
                                                                    <tr key={index}>
                                                                        <td><a onClick={() => { _Props.fnMostraPesquisa(rowData.ID); }}><i className="fa fa-file"></i></a></td>
                                                                        <td>{rowData.Placa}</td>
                                                                        <td>{rowData.Chassi}</td>
                                                                        <td>{rowData.UF}</td>
                                                                        <td>{rowData.NomeResponsavelSolicitacao}</td>
                                                                        <td><Moment format="DD/MM/YYYY HH:mm">{rowData.DataHoraSolicitacao}</Moment></td>
                                                                        <td><FormattedNumber value={rowData.Valor} style="decimal" minimumFractionDigits={2} maximumFractionDigits={2} /></td>
                                                                    </tr>
                                                                )
                                                            })
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </Row>
                                <Row>
                                    <div className="col-sm-5">
                                        <div className="dataTables_info"> Mostrando&nbsp; {
                                            (this.props.Filters.PageSize > this.props.Filters.TotalNumberOfRecords)
                                                ? this.props.Filters.TotalNumberOfRecords : this.props.Filters.PageSize
                                        } de {this.props.Filters.TotalNumberOfRecords}
                                        </div>
                                    </div>
                                    <div className="col-sm-7">
                                        <div className="dataTables_paginate paging_simple_numbers">
                                            <ListPager CurrentPage={this.props.Filters.PageNumber} Size={this.props.Filters.TotalNumberOfPages} OnPageChanged={(page) => { this.props.Filters.PageNumber = page; this.props.fnListaPesquisasHistorico(); }} />
                                        </div>
                                    </div>
                                </Row>
                            </div>
                        </div>
                    </div>
                </Section>
            </div>
        </Row>;
    }
}

export default connect(
    state => state.bndvcompleta,
    dispatch => bindActionCreators(actionCreators, dispatch),
    null,
    { pure: false }
)(BNDVCompletaHistoricoForm);