import * as React from "react";

export default class BtnImprimir extends React.Component {
    onButtonClick() { this.props.onClick(); }

    render() {
        return <button
            type={this.props.type}
            className={(this.props.IsLoading) ? "btn btn-dark" : "btn btn-warning"}
            disabled={this.props.IsLoading ? true : false}
            onClick={this.onButtonClick.bind(this)}
        >
            <i className="fa fa-print"></i>
            {(this.props.IsLoading) ? this.props.textLoading : this.props.text}
        </button>;
    }
}