import * as funcs from '../../utils/funcs';

//const _URL = 'http://localhost:58416/api/v1/edoc/';
const _URL = 'https://apisistema.bndv.com.br/Edoc/api/v1/edoc/';

export const services = {
    listaCategorias: (authorizationToken) => {
        return new Request(_URL + 'listaCategorias',
            {
                method: 'GET',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
                    'Authorization': 'Bearer ' + authorizationToken
                }
            });
    },
    listaTiposDocumento: (authorizationToken) => {
        return new Request(_URL + 'listaTiposDocumento',
            {
                method: 'GET',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
                    'Authorization': 'Bearer ' + authorizationToken
                }
            });
    },
    listaMarcas: (authorizationToken, idCategoria) => {
        return new Request(_URL + 'listaMarcas?idCategoria=' + idCategoria.toString() ,
            {
                method: 'GET',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
                    'Authorization': 'Bearer ' + authorizationToken
                }
            });
    },
    listaModelos: (authorizationToken, idCategoria, idMarca) => {
        return new Request(_URL + 'listaModelos?idCategoria=' + idCategoria.toString() + '&idMarca=' + idMarca.toString(),
            {
                method: 'GET',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
                    'Authorization': 'Bearer ' + authorizationToken
                }
            });
    },
    listaStatus: (authorizationToken) => {
        return new Request(_URL + 'listaStatus',
            {
                method: 'GET',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
                    'Authorization': 'Bearer ' + authorizationToken
                }
            });
    },
    listaVeiculos: (authorizationToken, pDTO) => {
        var query = funcs.encodeURI(pDTO);

        return new Request(_URL + 'listaVeiculos',
            {
                method: 'POST',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
                    'Authorization': 'Bearer ' + authorizationToken
                },
                body: query
            });
    },
    listaDocumentosVeiculo: (authorizationToken, idVeiculo) => {
        return new Request(_URL + 'listaDocumentosVeiculo?idVeiculo=' + idVeiculo.toString(),
            {
                method: 'GET',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
                    'Authorization': 'Bearer ' + authorizationToken
                }
            });
    },
    inserirDocumento: (authorizationToken, pDTO) => {
        var query = funcs.encodeURI(pDTO);
        return new Request(_URL + 'inserirDocumentoBase64',
            {
                method: 'POST',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
                    'Authorization': 'Bearer ' + authorizationToken
                },
                body: query
            });
    },
    removerDocumento: (authorizationToken, idDocumento) => {
        return new Request(_URL + 'removerDocumento?id=' + idDocumento.toString(),
            {
                method: 'DELETE',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
                    'Authorization': 'Bearer ' + authorizationToken
                }
            });
    },
};