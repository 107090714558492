import React, { Component } from 'react';

class LayoutEmpty extends Component {
    componentDidMount() { }

    render() {
        return (
            <div id="wrap">
                <div id="container">
                    {this.props.children}
                </div>
            </div>
        );
    }
}

export default LayoutEmpty;