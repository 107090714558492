import * as React from "react";

export default class SectionHeader extends React.Component {
    render() {
        return (
            <div className="tile-header dvd dvd-btm">
                <h1 className="custom-font">{this.props.title}</h1>
                {this.props.children}
            </div>
        );
    }
}