import * as funcs from '../../utils/funcs';

//const _URL = 'http://localhost:61655/api/v1/crm/';
const _URL = 'https://apicrm.bndv.com.br/api/v1/crm/';

export const services = {
    listaOportunidades: (authorizationToken, pDTO) => {
        var query = funcs.encodeURI(pDTO);

        return new Request(_URL + 'listaOportunidades',
            {
                method: 'POST',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
                    'Authorization': 'Bearer ' + authorizationToken
                },
                body: query
            });
    },
    listaOportunidadesExport: (authorizationToken, pDTO) => {
        var query = funcs.encodeURI(pDTO);

        return new Request(_URL + 'listaOportunidadesExport',
            {
                method: 'POST',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
                    'Authorization': 'Bearer ' + authorizationToken
                },
                body: query
            });
    },
    listaStatus: (authorizationToken) => {
        return new Request(_URL + 'listaOportunidadeStatus',
            {
                method: 'GET',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
                    'Authorization': 'Bearer ' + authorizationToken
                }
            });
    },
    listaEtapasFunilVendas: (authorizationToken) => {
        return new Request(_URL + 'listaEtapasFunilVendas',
            {
                method: 'GET',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
                    'Authorization': 'Bearer ' + authorizationToken
                }
            });
    },
    obterOportunidade: (authorizationToken, id) => {
        return new Request(_URL + 'obterOportunidade?id=' + id.toString(),
            {
                method: 'GET',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
                    'Authorization': 'Bearer ' + authorizationToken
                }
            });
    },
    listaResponsavel: (authorizationToken) => {
        return new Request(_URL + 'listaUsuariosAdicionados',
            {
                method: 'GET',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
                    'Authorization': 'Bearer ' + authorizationToken
                }
            });
    },
    atualizaResponsavelOportunidade: (authorizationToken, pDTO) => {
        var query = funcs.encodeURI(pDTO);

        return new Request(_URL + 'atualizaResponsavelOportunidade',
            {
                method: 'PUT',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
                    'Authorization': 'Bearer ' + authorizationToken
                },
                body: query
            });
    },
    atualizaTemperaturaOportunidade: (authorizationToken, pDTO) => {
        var query = funcs.encodeURI(pDTO);

        return new Request(_URL + 'atualizaTemperaturaOportunidade',
            {
                method: 'PUT',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
                    'Authorization': 'Bearer ' + authorizationToken
                },
                body: query
            });
    },
    listaAtividadesOportunidade: (authorizationToken, idOportunidade) => {
        return new Request(_URL + 'listaAtividadesOportunidade?idOportunidade=' + idOportunidade.toString(),
            {
                method: 'GET',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
                    'Authorization': 'Bearer ' + authorizationToken
                }
            });
    },
    listaTimeLineOportunidade: (authorizationToken, idOportunidade) => {
        return new Request(_URL + 'listaOportunidadeEvento?idOportunidade=' + idOportunidade.toString(),
            {
                method: 'GET',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
                    'Authorization': 'Bearer ' + authorizationToken
                }
            });
    },
    inserirOportunidadeEvento: (authorizationToken, pDTO) => {
        var query = funcs.encodeURI(pDTO);

        return new Request(_URL + 'inserirOportunidadeEvento',
            {
                method: 'POST',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
                    'Authorization': 'Bearer ' + authorizationToken
                },
                body: query
            });
    },
    inserirAtividade: (authorizationToken, pDTO) => {
        var query = funcs.encodeURI(pDTO);

        return new Request(_URL + 'inserirAtividade',
            {
                method: 'POST',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
                    'Authorization': 'Bearer ' + authorizationToken
                },
                body: query
            });
    },
    listaCanaisAtividade: (authorizationToken) => {
        return new Request(_URL + 'listaCanaisAtividade',
            {
                method: 'GET',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
                    'Authorization': 'Bearer ' + authorizationToken
                }
            });
    },
    //inserirAtividade: (authorizationToken, pDTO) => {
    //    var query = funcs.encodeURI(pDTO);

    //    return new Request(_URL + 'inserirAtividade',
    //        {
    //            method: 'POST',
    //            mode: 'cors',
    //            headers: {
    //                'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    //                'Authorization': 'Bearer ' + authorizationToken
    //            },
    //            body: query
    //        });
    //},
    novaOportunidade: (authorizationToken, pDTO) => {
        var query = funcs.encodeURI(pDTO);

        return new Request(_URL + 'inserirOportunidade',
            {
                method: 'POST',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
                    'Authorization': 'Bearer ' + authorizationToken
                },
                body: query
            });
    },
    reabrirOportunidade: (authorizationToken, idOportunidade) => {
        return new Request(_URL + 'reabrirOportunidade?idOportunidade=' + idOportunidade.toString(),
            {
                method: 'PUT',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
                    'Authorization': 'Bearer ' + authorizationToken
                }
            });
    },
    listaCanaisLead: (authorizationToken) => {
        return new Request(_URL + 'listaCanaisLead',
            {
                method: 'GET',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
                    'Authorization': 'Bearer ' + authorizationToken
                }
            });
    },
    listaMotivosPerdido: (authorizationToken) => {
        return new Request(_URL + 'listaMotivosPerdido',
            {
                method: 'GET',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
                    'Authorization': 'Bearer ' + authorizationToken
                }
            });
    },
    listaMotivosVendido: (authorizationToken) => {
        return new Request(_URL + 'listaMotivosVendido',
            {
                method: 'GET',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
                    'Authorization': 'Bearer ' + authorizationToken
                }
            });
    },
    vendidoOportunidade: (authorizationToken, pDTO) => {
        var query = funcs.encodeURI(pDTO);
        return new Request(_URL + 'vendidoOportunidade',
            {
                method: 'PUT',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
                    'Authorization': 'Bearer ' + authorizationToken
                },
                body: query
            });
    },
    perdidoOportunidade: (authorizationToken, pDTO) => {
        var query = funcs.encodeURI(pDTO);
        return new Request(_URL + 'perdidoOportunidade',
            {
                method: 'PUT',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
                    'Authorization': 'Bearer ' + authorizationToken
                },
                body: query
            });
    },
    listaTemperatura: (authorizationToken) => {
        return new Request(_URL + 'listaTemperatura',
            {
                method: 'GET',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
                    'Authorization': 'Bearer ' + authorizationToken
                }
            });
    }
};