import * as React from 'react';
import { fetch, addTask } from 'domain-task';
import { Chart } from 'react-google-charts';
import * as dashboard_service from '../../../services/dashboard/dashboard_service';
import Section from '../../commons/Section';
import SectionHeader from '../../commons/SectionHeader';

export default class LucratividadeXMetaSemestre extends React.Component {
    constructor() {
        super();
        this.state = { data: [] };
    }

    componentDidMount() {
        this.loadData(this.props.authorizationToken, this.props.empresaSelected);
    }

    componentWillReceiveProps(nextProps) {
        this.loadData(nextProps.authorizationToken, nextProps.empresaSelected);
    }

    loadData(authorizationToken, empresaSelected) {
        let fetchTask = fetch(dashboard_service.services.Administrador.LucratividadeXMetaSemestre(authorizationToken, empresaSelected))
            .then(response => {
                if (response.status === 200 || response.status === 0) {
                    return response.json();
                }
                else {
                    return response.json().then(function (object) {
                        return Promise.reject(new Error(object));
                    })
                }
            })
            .then(data => {
                var arrData = [];
                arrData.push(["Mes", "Valor", "Meta"]);

                data.forEach(function (entry) {
                    arrData.push([entry.Mes + '/' + entry.Ano, entry.Total, entry.Meta]);
                });

                this.setState({ data: arrData });
            }).catch(function (err) {
                console.log(err);
            });

        addTask(fetchTask);
    }

    render() {
        return (
            <Section>
                <SectionHeader title="Lucratividade de Vendas X Meta (Últimos 6 meses)" />
                <div className="tile-body">
                    <Chart
                        chartType="ComboChart"
                        data={this.state.data}
                        options={
                            {
                                "bar": {
                                    "groupWidth": "65%"
                                },
                                "legend": {
                                    "position": "top"
                                },
                                "seriesType": "bars",
                                "series": {
                                    "1": {
                                        "type": "line"
                                    }
                                },
                                "chartArea": {
                                    "left": 30,
                                    "top": 20,
                                    "width": "100%",
                                    "height": "85%",
                                    "backgroundColor": {
                                        "stroke": "#cccccc",
                                        "strokeWidth": 1
                                    }
                                },
                                "fontName": "Verdana",
                                "hAxis": {
                                    "slantedText": false,
                                    "slantedTextAngle": 30,
                                    "gridlines": {
                                        "count": -1
                                    }
                                },
                                "vAxis": {
                                    "format": "####"
                                },
                                "isStacked": true,
                                "colors": ['#25afd8', '#b0615c', '#2da57f']
                            }
                        }
                        width="100%"
                        legend_toggle
                    />
                </div>
            </Section>
        );
    }
}