import * as React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actionCreators } from '../../../../../store/marketing/publicador/MarketingPublicador';

import Formsy from 'formsy-react';
import { Row, Modal, Table } from 'react-bootstrap';
import ProgerssBar from '../../../../commons/ProgressBar';
import BtnEntendi from '../../../../commons/BtnEntendi';


class ModalRecomendacaoMercadoLivre extends React.Component {
    constructor() {
        super();
       
    }

    async componentDidMount() {
        this.props.PublicarVeiculo.dadosPortal.recomendacoes.results = [];       
        this.props.PublicarVeiculo.errorText = "";
        this.props.PublicarVeiculo.successText = "";    
        this.props.PublicarVeiculo.anuncio.idAnuncio = "";
        this.props.PublicarVeiculo.anuncio.idAnuncioCliente = "";            
        this.props.PublicarVeiculo.anuncio.idPublicador = 0;       
        this.props.PublicarVeiculo.anuncio.idVeiculo = 0;
        this.props.PublicarVeiculo.anuncio.anuncioExtensaoJs = null;

        await this.props.fnUpdateState(this.props);
      
        if (this.props.ModalRecomendacaoMercadoLivre.showModal) {
           // await this.props.fnBuscarAnuncio();
            await this.props.fnBuscarAnuncioRecomendacao();
            await this.props.fnBuscarAnuncioExtensao();
        }       

    }
  

    handleOnHide() {
        this.props.ModalRecomendacaoMercadoLivre.showModal = false;       
        this.props.fnUpdateState(this.props);
    }

    render() {
        var objProps = this.props;

        var recomendacoesList = [];
        recomendacoesList = objProps.PublicarVeiculo.dadosPortal.recomendacoes.results.filter(rec => rec.penalizacao === false);

        var penalizacoesList = [];
        penalizacoesList = objProps.PublicarVeiculo.dadosPortal.recomendacoes.results.filter(rec => rec.penalizacao === true);

        const renderRecommendation = (recomendacao , index) => {

            return (
                <tr key={index}>
                    <td style={{ textAlign: "center" }}><span style={{ color: 'Orange'}}><i className="fa fa-info-circle fa-lg" aria-hidden="true"></i></span></td>
                    <td>{recomendacao.titulo}</td>
                    <td>{recomendacao.descricao}</td>
                </tr>                
                )
        }

        const renderPenalization = (penalizacao, index) => {

            return (
                <tr key={index}>
                    <td style={{ textAlign: "center" }}><span style={{ color: 'Red' }}><i className="fa fa-info-circle fa-lg" aria-hidden="true"></i></span></td>
                    <td>{penalizacao.titulo}</td>
                    <td style={{ whiteSpace: "unset" }}>{penalizacao.motivo}</td>
                    
                </tr>
            )
        }

        const renderExtention = (extensaoJs) => {
            var extensao = JSON.parse(extensaoJs);
            return (
                <tr>                   
                    <td style={{ textAlign: "center" }}>{extensao.health}</td>
                    <td style={{ textAlign: "center" }}>{extensao.level}</td>
                </tr>
            )
        }
      

        return <Modal
            show={this.props.ModalRecomendacaoMercadoLivre.showModal}
            onHide={this.handleOnHide.bind(this)}
            bsSize="lg"
        >
          
                <Modal.Header closeButton>
                    <h4 className="modal-title custom-font">Recomendações do Mercado Livre</h4>
                </Modal.Header>
            <Modal.Body>
                <div style={{ overflowX: "auto" }}>
                {this.props.PublicarVeiculo.anuncio.anuncioExtensaoJs !== null ?
                    <Table striped bordered hover>
                        <thead>
                            <tr>
                                <th colSpan="2" style={{ textAlign: "center" }}>QUALIDADE DO ANÚNCIO</th>
                            </tr>
                            <tr>
                                <th style={{ textAlign: "center" }}>Saúde</th>
                                <th style={{ textAlign: "center" }}>Nível</th>
                            </tr>
                        </thead>
                        <tbody>
                            {renderExtention(this.props.PublicarVeiculo.anuncio.anuncioExtensaoJs)}
                        </tbody>
                    </Table>
                    : null}

                    <br />

                    {(
                        recomendacoesList.length > 0 ? 
                    <Table striped bordered hover>
                        <thead>
                            <tr>
                                <th colSpan="3" style={{ textAlign: "center" }}>RECOMENDAÇÕES</th>
                            </tr>
                        </thead>
                        <tbody>
                            {recomendacoesList.map(renderRecommendation) }
                        </tbody>
                            </Table>
                            : null
                    )}

                      <br />
                {this.props.ModalRecomendacaoMercadoLivre.isLoading
                    ? <ProgerssBar />
                    : null
                }
                    <br />
                    {(
                        penalizacoesList.length > 0? 
                            <Table striped bordered hover>
                                <thead>
                                    <tr>
                                    <th colSpan="3" style={{ textAlign: "center" }}>PENALIZAÇÕES </th>
                                    </tr>
                                </thead>
                            <tbody>
                                    {penalizacoesList.map(renderPenalization)}
                                </tbody>
                            </Table>                    
                    : null

                    )}
                    
              </div>
                </Modal.Body>
            <Modal.Footer>
                { this.props.ModalRecomendacaoMercadoLivre.isLoading
                                ? null
                :
                (this.props.PublicarVeiculo.anuncio.qualidadeAnuncioStatus === 2 || this.props.PublicarVeiculo.anuncio.qualidadeAnuncioStatus === 3
                        ? <BtnEntendi
                            type="button"
                            text=" Entendi!"
                            textLoading=" Aguarde..."
                            isLoading={this.props.PublicarVeiculo.isLoading}
                            onClick={() => { this.props.fnVisualizarRecomendacao(); }}
                        />
                    : null)

                }
                </Modal.Footer>            
        </Modal>;
    }
}

export default connect(
    state => state.marketing_publicador,
    dispatch => bindActionCreators(actionCreators, dispatch),
    null, {}
)(ModalRecomendacaoMercadoLivre);