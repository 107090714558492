import * as React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actionCreators } from '../../../../../store/marketing/publicador/MarketingPublicador';

import Formsy from 'formsy-react';
import { Row, Modal } from 'react-bootstrap';
import ProgerssBar from '../../../../commons/ProgressBar';
import AlertError from '../../../../commons/AlertError';
import AlertSuccess from '../../../../commons/AlertSuccess';
import TextAreaValidated from '../../../../commons/TextAreaValidated';
import CurrencyInput from 'react-currency-input';
import BtnSalvar from '../../../../commons/BtnSalvar';
import BtnCancelar from '../../../../commons/BtnCancelar';

class ModalCompreCar extends React.Component {
    constructor() {
        super();
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    async componentDidMount() {
        this.props.PublicarVeiculo.anuncio.idCategoria = 1;

        if (this.props.ModalCompreCar.showModal) {
            if (this.props.PublicarVeiculo.anuncio.idAnuncioBNDV != null && this.props.PublicarVeiculo.anuncio.idAnuncioBNDV != 0) {
                this.props.PublicarVeiculo.dadosPortal.versoes.results = [];
                this.props.PublicarVeiculo.errorText = "";
                this.props.PublicarVeiculo.successText = "";
                this.props.fnUpdateState(this.props);

                await this.props.fnBuscarVeiculo();
                await this.props.fnBuscarAnuncio();
            }
            else {
                this.props.fnBuscarVeiculo();
                this.props.fnListaPlanos();

                this.props.PublicarVeiculo.dadosPortal.versoes.results = [];
                this.props.PublicarVeiculo.errorText = "";
                this.props.PublicarVeiculo.successText = "";
                this.props.fnUpdateState(this.props);
            }
        }
    }

    handleSubmit(e) {
        if (this.props.PublicarVeiculo.anuncio.idAnuncioBNDV == 0)
            this.props.fnPublicar();
        else
            this.props.fnAlterarAnuncio();
    }

    handleOnHide() {
        this.props.ModalCompreCar.showModal = false;
        this.props.fnUpdateState(this.props);
    }

    render() {
        var objProps = this.props;

        var dropdownVersoes = [];
        dropdownVersoes.push({ value: '', text: 'Selecione a Versão' });
        objProps.PublicarVeiculo.dadosPortal.versoes.results.forEach(function (entry) {
            dropdownVersoes.push({ value: entry.id, text: entry.Nome });
        });

        return <Modal
            show={this.props.ModalCompreCar.showModal}
            onHide={this.handleOnHide.bind(this)}
            bsSize="large"
        >
            <Formsy onValidSubmit={this.handleSubmit}>
                <Modal.Header closeButton>
                    <h4 className="modal-title custom-font">Publicar Veículo Compre Car</h4>
                </Modal.Header>
                <Modal.Body>
                    <AlertSuccess
                        successText={this.props.PublicarVeiculo.successText}
                        alertVisible={this.props.PublicarVeiculo.successText != '' && this.props.PublicarVeiculo.successText != undefined ? true : false}
                        onDismiss={(alertVisible, successText) => {
                            this.props.PublicarVeiculo.successText = successText;
                            this.props.fnUpdateState(this.props);
                        }}
                    />
                    <AlertError
                        errorText={this.props.PublicarVeiculo.errorText}
                        alertVisible={this.props.PublicarVeiculo.errorText != '' && this.props.PublicarVeiculo.errorText != undefined ? true : false}
                        onDismiss={(alertVisible, errorText) => {
                            this.props.PublicarVeiculo.errorText = errorText;
                            this.props.fnUpdateState(this.props);
                        }}
                    />
                    {
                        this.props.ModalCompreCar.isLoading
                            ? <ProgerssBar />
                            : <Row>
                                <div className="form-group col-md-5">
                                    <div className="form-group col-md-12 legend">
                                        <h4><strong>Dados Veículo</strong></h4>
                                    </div>
                                    <dl className="dl-horizontal">
                                        <dt>Placa:</dt>
                                        <dd>{this.props.PublicarVeiculo.veiculoBNDV.placa}</dd>
                                        <dt>Chassi:</dt>
                                        <dd>{this.props.PublicarVeiculo.veiculoBNDV.chassi}</dd>
                                        <dt>Marca:</dt>
                                        <dd>{this.props.PublicarVeiculo.veiculoBNDV.descMarca}</dd>
                                        <dt>Modelo:</dt>
                                        <dd>{this.props.PublicarVeiculo.veiculoBNDV.descModelo}</dd>
                                        <dt>Versão:</dt>
                                        <dd>{this.props.PublicarVeiculo.veiculoBNDV.descVersao}</dd>
                                        <dt>Câmbio:</dt>
                                        <dd>{this.props.PublicarVeiculo.veiculoBNDV.descCambio}</dd>
                                        <dt>Combustível:</dt>
                                        <dd>{this.props.PublicarVeiculo.veiculoBNDV.descCombustivel}</dd>
                                        <dt>Portas:</dt>
                                        <dd>{this.props.PublicarVeiculo.veiculoBNDV.descPortas}</dd>
                                        <dt>Cor:</dt>
                                        <dd>{this.props.PublicarVeiculo.veiculoBNDV.cor}</dd>
                                        <dt>Ano Modelo:</dt>
                                        <dd>{this.props.PublicarVeiculo.veiculoBNDV.anoModelo}</dd>
                                        <dt>Ano Fabricação:</dt>
                                        <dd>{this.props.PublicarVeiculo.veiculoBNDV.anoFabricacao}</dd>
                                        <dt>KM:</dt>
                                        <dd>{this.props.PublicarVeiculo.veiculoBNDV.km}</dd>
                                    </dl>
                                    <div className="form-group col-md-12 legend">
                                        <h4><strong>Opcionais</strong></h4>
                                    </div>
                                    <dl className="form-group col-md-12">
                                        <p>{this.props.PublicarVeiculo.veiculoBNDV.opcionais}</p>
                                    </dl>
                                </div>
                                <div className="form-group col-md-7">
                                    <div className="tile-body">
                                        <Row>
                                            <div className="alert alert-warning" role="alert">Prezado cliente o portal CompreCar permite alteração apenas das informações: valor, observações e imagens do anúncio, caso precise alterar outro campo faça a remoção do anúncio e anuncie novamente.<br />
                                                Tanto para publicação quanto alteração do anúncio o Portal CompreCar faz uma conferência das informações e o anúncio é ativado em até 1hora, dentro do horário comercial - (seg - sex, das 8 às 18 horas - sábadado das 8 às 12 horas).
                                            </div>
                                            <div className="form-group col-md-12">
                                                <p>
                                                    <strong>
                                                        {
                                                            (this.props.PublicarVeiculo.dadosPortal.planos.results.length > 0
                                                                ? this.props.PublicarVeiculo.dadosPortal.planos.results[0].Nome
                                                                + ' - Anúncios Disponíveis: '
                                                                + (this.props.PublicarVeiculo.dadosPortal.planos.results[0].qtdeContratada - this.props.PublicarVeiculo.dadosPortal.planos.results[0].qtdeUtilizada).toString()
                                                                : "Carregando...")
                                                        }
                                                    </strong>
                                                </p>
                                            </div>
                                            <div className="form-group col-md-6">
                                                <label>Valor para anúncio</label>
                                                <CurrencyInput
                                                    decimalSeparator="."
                                                    thousandSeparator=""
                                                    className="form-control"
                                                    id="txtValorAnuncio"
                                                    onChange={(x) => {
                                                        this.props.PublicarVeiculo.anuncio.preco = parseFloat(x);
                                                        this.props.fnUpdateState(this.props);
                                                    }}
                                                    inputType="text"
                                                    value={this.props.PublicarVeiculo.anuncio.preco}
                                                />
                                            </div>
                                            <TextAreaValidated
                                                name="txtDescricao"
                                                className="col-md-12"
                                                placeholder="Informe a descrição do anúncio"
                                                rows={6}
                                                onChange={(name, value) => {
                                                    this.props.PublicarVeiculo.anuncio.texto = value;
                                                    this.props.fnUpdateState(this.props);
                                                }}
                                                title="Descrição do anúncio:"
                                                value={this.props.PublicarVeiculo.anuncio.texto}
                                                required
                                            />
                                        </Row>
                                    </div>
                                </div>
                            </Row>
                    }
                </Modal.Body>
                <Modal.Footer>
                    {this.props.PublicarVeiculo.anuncio.idAnuncioBNDV != null
                        && this.props.PublicarVeiculo.anuncio.idAnuncioBNDV != 0
                        ? <BtnCancelar
                            type="button"
                            text=" Remover anúncio"
                            textLoading=" Aguarde..."
                            isLoading={this.props.PublicarVeiculo.isLoading}
                            onClick={() => { this.props.fnRemover(); }}
                        />
                        : null}
                    <BtnSalvar
                        type="submit"
                        text={this.props.PublicarVeiculo.anuncio.idAnuncioBNDV != 0 ? " Alterar Anúncio" : "Publicar Veículo"}
                        textLoading=" Aguarde..."
                        isLoading={this.props.PublicarVeiculo.isLoading}
                    />
                </Modal.Footer>
            </Formsy>
        </Modal>;
    }
}

export default connect(
    state => state.marketing_publicador,
    dispatch => bindActionCreators(actionCreators, dispatch),
    null, {}
)(ModalCompreCar);