import * as funcs from '../../../utils/funcs';

//const _URL = 'http://localhost:51880/api/v1/';
//const _URL = 'https://apidaypag-homologa.bndv.com.br/api/v1/';
const _URL = 'https://apidaypag.bndv.com.br/api/v1/'

export const services = {
    listaTipoServico: (authorizationToken) => {
        return new Request(_URL + 'listaTipoServico',
            {
                method: 'GET',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
                    'Authorization': 'Bearer ' + authorizationToken
                }
            });
    },
    listaTipoSubServico: (idTipoServico, authorizationToken) => {
        return new Request(_URL + 'listaTipoSubServico?idTipoServico=' + idTipoServico,
            {
                method: 'GET',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
                    'Authorization': 'Bearer ' + authorizationToken
                }
            });
    },
    consultaDetalhada: (pDTO, authorizationToken) => {
        var query = funcs.json_to_uri(pDTO);

        return new Request(_URL + 'consultaDetalhadaVeiculo',
            {
                method: 'POST',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
                    'Authorization': 'Bearer ' + authorizationToken
                },
                body: query
            });
    },
    efetuarPagamento: (pDTO, authorizationToken) => {
        var query = funcs.json_to_uri(pDTO);

        return new Request(_URL + 'pagarDebitosVeiculo',
            {
                method: 'POST',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
                    'Authorization': 'Bearer ' + authorizationToken
                },
                body: query
            });
    },
    obterSaldo: (authorizationToken) => {
        return new Request(_URL + 'obterSaldo',
            {
                method: 'POST',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
                    'Authorization': 'Bearer ' + authorizationToken
                }
            });
    },
};