import { fetch } from 'domain-task';
import * as publicadorconfig_service from '../../../services/marketing/publicador/publicadorconfig_service';

const UPDATE_STATE_MARKETING_PUBLICADOR_CONFIG_ACTION = 'UPDATE_STATE_MARKETING_PUBLICADOR_CONFIG_ACTION';

const REQUEST_DADOS_ANUNCIANTE_ACTION = 'REQUEST_DADOS_ANUNCIANTE_ACTION';
const RESPONSE_DADOS_ANUNCIANTE_ACTION = 'RESPONSE_DADOS_ANUNCIANTE_ACTION';
const FAILURE_DADOS_ANUNCIANTE_ACTION = 'FAILURE_DADOS_ANUNCIANTE_ACTION';
const POST_DADOS_ANUNCIANTE_ACTION = 'POST_DADOS_ANUNCIANTE_ACTION';

const REQUEST_PORTAIS_CREDENCIADOS_ACTION = 'REQUEST_PORTAIS_CREDENCIADOS_ACTION';
const RESPONSE_PORTAIS_CREDENCIADOS_ACTION = 'RESPONSE_PORTAIS_CREDENCIADOS_ACTION';
const FAILURE_PORTAIS_CREDENCIADOS_ACTION = 'FAILURE_PORTAIS_CREDENCIADOS_ACTION';

const REQUEST_DADOS_EMPRESA_PARCEIRO_ANUNCIO_ACTION = 'REQUEST_DADOS_EMPRESA_PARCEIRO_ANUNCIO_ACTION';
const RESPONSE_DADOS_EMPRESA_PARCEIRO_ANUNCIO_ACTION = 'RESPONSE_DADOS_EMPRESA_PARCEIRO_ANUNCIO_ACTION';
const FAILURE_DADOS_EMPRESA_PARCEIRO_ANUNCIO_ACTION = 'FAILURE_DADOS_EMPRESA_PARCEIRO_ANUNCIO_ACTION';
const POST_DADOS_EMPRESA_PARCEIRO_ANUNCIO_ACTION = 'POST_DADOS_EMPRESA_PARCEIRO_ANUNCIO_ACTION';
const SUCCESS_DADOS_EMPRESA_PARCEIRO_ANUNCIO_ACTION = 'SUCCESS_DADOS_EMPRESA_PARCEIRO_ANUNCIO_ACTION';
const PUT_DADOS_EMPRESA_PARCEIRO_ANUNCIO_ACTION = 'PUT_DADOS_EMPRESA_PARCEIRO_ANUNCIO_ACTION';
const DELETE_DADOS_EMPRESA_PARCEIRO_ANUNCIO_ACTION = 'DELETE_DADOS_EMPRESA_PARCEIRO_ANUNCIO_ACTION';
const UPDATE_LIST_PORTAIS_CREDENCIADOS = 'UPDATE_LIST_PORTAIS_CREDENCIADOS';

const REQUEST_UFS_EMPRESA_PARCEIRO_ANUNCIO_ACTION = 'REQUEST_UFS_EMPRESA_PARCEIRO_ANUNCIO_ACTION';
const RESPONSE_UFS_EMPRESA_PARCEIRO_ANUNCIO_ACTION = 'RESPONSE_UFS_EMPRESA_PARCEIRO_ANUNCIO_ACTION';
const FAILURE_UFS_EMPRESA_PARCEIRO_ANUNCIO_ACTION = 'FAILURE_UFS_EMPRESA_PARCEIRO_ANUNCIO_ACTION';

const REQUEST_CIDADES_EMPRESA_PARCEIRO_ANUNCIO_ACTION = 'REQUEST_CIDADES_EMPRESA_PARCEIRO_ANUNCIO_ACTION';
const RESPONSE_CIDADES_EMPRESA_PARCEIRO_ANUNCIO_ACTION = 'RESPONSE_CIDADES_EMPRESA_PARCEIRO_ANUNCIO_ACTION';
const FAILURE_CIDADES_EMPRESA_PARCEIRO_ANUNCIO_ACTION = 'FAILURE_CIDADES_EMPRESA_PARCEIRO_ANUNCIO_ACTION';

const REQUEST_BAIRROS_EMPRESA_PARCEIRO_ANUNCIO_ACTION = 'REQUEST_BAIRROS_EMPRESA_PARCEIRO_ANUNCIO_ACTION';
const RESPONSE_BAIRROS_EMPRESA_PARCEIRO_ANUNCIO_ACTION = 'RESPONSE_BAIRROS_EMPRESA_PARCEIRO_ANUNCIO_ACTION';
const FAILURE_BAIRROS_EMPRESA_PARCEIRO_ANUNCIO_ACTION = 'FAILURE_BAIRROS_EMPRESA_PARCEIRO_ANUNCIO_ACTION';

const REQUEST_PUBLICADOR_CONFIG_ADICIONAIS_ACTION = 'REQUEST_PUBLICADOR_CONFIG_ADICIONAIS_ACTION';
const RESPONSE_PUBLICADOR_CONFIG_ADICIONAIS_ACTION = 'RESPONSE_PUBLICADOR_CONFIG_ADICIONAIS_ACTION';
const FAILURE_PUBLICADOR_CONFIG_ADICIONAIS_ACTION = 'FAILURE_PUBLICADOR_CONFIG_ADICIONAIS_ACTION';
const POST_PUBLICADOR_CONFIG_ADICIONAIS_ACTION = 'POST_PUBLICADOR_CONFIG_ADICIONAIS_ACTION';
const PUT_PUBLICADOR_CONFIG_ADICIONAIS_ACTION = 'PUT_PUBLICADOR_CONFIG_ADICIONAIS_ACTION';
const SUCCESS_PUBLICADOR_CONFIG_ADICIONAIS_ACTION = 'SUCCESS_PUBLICADOR_CONFIG_ADICIONAIS_ACTION';

const initialState = {
    dadosAnunciante: {
        errorText: '',
        isLoading: false,
        token: ''
    },
    configuracaoPortais: {
        errorText: '',
        isLoading: false,
        portaisCredenciados: [],
        modalPortais: {
            errorText: '',
            isLoading: false,
            successText: '',
            idPublicador: 0,
            jsAutenticacao: '',
            jsDadosEspeciais: '',
            ativado: false,
            comboCidade: { errorText: '', isLoading: false, results: [] },
            comboUF: { errorText: '', isLoading: false, results: [] },
            comboBairro: { errorText: '', isLoading: false, results: [] },
            modalICarros: { showModal: false, username: '', password: '', access_token: '' },
            modalMeuCarroNovo: { showModal: false, userName: '', password: '' },
            modalWebMotors: { showModal: false, cnpj: '', email: '', senha: '' },
            modalOLX: { showModal: false, access_token: '' },
            modalAutoLine: { showModal: false, email: '', senha: '', idUf: 0, idCidade: 0 },
            modalMercadoLivre: { showModal: false, token: '', idUf: '', idCidade: '', idBairro: '' },
            modalCarroSaoJose: { showModal: false },
            modalCarroBrasil: { showModal: false, login: '', senha: '', token: '' },
            modalNewMotors: { showModal: false, email: '', password: '' },
            modalTemUsados: { showModal: false, id_anunciante: 0, login: '', senha: '' },
            modalCompreCar: { showModal: false, id_cliente: 0, token: '' },
            modalAutoShoppingGlobal: { showModal: false },
            modalValeVeiculos: { showModal: false },
            modalAutoShoppingPontal: { showModal: false },
            modalSiteCliente: { showModal: false },
            modalFacebook: { showModal: false, token: '', id_pagina: '' },
            modalAutoShoppingImigrantes: { showModal: false },
            modalMobiAuto: { showModal: false, username: '', password: '', access_token: '' },
            modalCompreBlindados: { showModal: false, id_anunciante: 0, login: '', senha: '' },
            modalUsadosBR: { showModal: false, userName: '', password: '' },
        }
    },
    configuracoesAdicionais: {
        isLoading: false,
        errorText: '',
        id: 0,
        remocaoAutomatica: true,
        textoPadrao: '',
        valorPromocional: false
    }
};

export const actionCreators = {
    fnUpdateState: (pState) => async (dispatch) => {
        dispatch({
            type: 'UPDATE_STATE_MARKETING_PUBLICADOR_CONFIG_ACTION',
            dadosAnunciante: {
                errorText: pState.dadosAnunciante.errorText,
                isLoading: pState.dadosAnunciante.isLoading,
                token: pState.dadosAnunciante.token
            },
            configuracaoPortais: {
                errorText: pState.configuracaoPortais.errorText,
                isLoading: pState.configuracaoPortais.isLoading,
                portaisCredenciados: pState.configuracaoPortais.portaisCredenciados,
                modalPortais: {
                    errorText: pState.configuracaoPortais.modalPortais.errorText,
                    isLoading: pState.configuracaoPortais.modalPortais.isLoading,
                    successText: pState.configuracaoPortais.modalPortais.successText,
                    idPublicador: pState.configuracaoPortais.modalPortais.idPublicador,
                    jsAutenticacao: pState.configuracaoPortais.modalPortais.jsAutenticacao,
                    jsDadosEspeciais: pState.configuracaoPortais.modalPortais.jsDadosEspeciais,
                    ativado: pState.configuracaoPortais.modalPortais.ativado,
                    comboCidade: {
                        errorText: pState.configuracaoPortais.modalPortais.comboCidade.errorText,
                        isLoading: pState.configuracaoPortais.modalPortais.comboCidade.isLoading,
                        results: pState.configuracaoPortais.modalPortais.comboCidade.results
                    },
                    comboUF: {
                        errorText: pState.configuracaoPortais.modalPortais.comboUF.errorText,
                        isLoading: pState.configuracaoPortais.modalPortais.comboUF.isLoading,
                        results: pState.configuracaoPortais.modalPortais.comboUF.results
                    },
                    comboBairro: {
                        errorText: pState.configuracaoPortais.modalPortais.comboBairro.errorText,
                        isLoading: pState.configuracaoPortais.modalPortais.comboBairro.isLoading,
                        results: pState.configuracaoPortais.modalPortais.comboBairro.results
                    },
                    modalICarros: {
                        showModal: pState.configuracaoPortais.modalPortais.modalICarros.showModal,
                        username: pState.configuracaoPortais.modalPortais.modalICarros.username,
                        password: pState.configuracaoPortais.modalPortais.modalICarros.password,
                        access_token: pState.configuracaoPortais.modalPortais.modalICarros.access_token
                    },
                    modalMeuCarroNovo: {
                        showModal: pState.configuracaoPortais.modalPortais.modalMeuCarroNovo.showModal,
                        userName: pState.configuracaoPortais.modalPortais.modalMeuCarroNovo.userName,
                        password: pState.configuracaoPortais.modalPortais.modalMeuCarroNovo.password
                    },
                    modalWebMotors: {
                        showModal: pState.configuracaoPortais.modalPortais.modalWebMotors.showModal,
                        cnpj: pState.configuracaoPortais.modalPortais.modalWebMotors.cnpj,
                        email: pState.configuracaoPortais.modalPortais.modalWebMotors.email,
                        senha: pState.configuracaoPortais.modalPortais.modalWebMotors.senha
                    },
                    modalOLX: {
                        showModal: pState.configuracaoPortais.modalPortais.modalOLX.showModal,
                        access_token: pState.configuracaoPortais.modalPortais.modalOLX.access_token
                    },
                    modalAutoLine: {
                        showModal: pState.configuracaoPortais.modalPortais.modalAutoLine.showModal,
                        email: pState.configuracaoPortais.modalPortais.modalAutoLine.email,
                        senha: pState.configuracaoPortais.modalPortais.modalAutoLine.senha,
                        idUf: pState.configuracaoPortais.modalPortais.modalAutoLine.idUf,
                        idCidade: pState.configuracaoPortais.modalPortais.modalAutoLine.idCidade
                    },
                    modalMercadoLivre: {
                        showModal: pState.configuracaoPortais.modalPortais.modalMercadoLivre.showModal,
                        token: pState.configuracaoPortais.modalPortais.modalMercadoLivre.token,
                        idUf: pState.configuracaoPortais.modalPortais.modalMercadoLivre.idUf,
                        idCidade: pState.configuracaoPortais.modalPortais.modalMercadoLivre.idCidade,
                        idBairro: pState.configuracaoPortais.modalPortais.modalMercadoLivre.idBairro,
                    },
                    modalCarroSaoJose: {
                        showModal: pState.configuracaoPortais.modalPortais.modalCarroSaoJose.showModal
                    },
                    modalCarroBrasil: {
                        showModal: pState.configuracaoPortais.modalPortais.modalCarroBrasil.showModal,
                        login: pState.configuracaoPortais.modalPortais.modalCarroBrasil.login,
                        senha: pState.configuracaoPortais.modalPortais.modalCarroBrasil.senha,
                        token: pState.configuracaoPortais.modalPortais.modalCarroBrasil.token
                    },
                    modalNewMotors: {
                        showModal: pState.configuracaoPortais.modalPortais.modalNewMotors.showModal,
                        email: pState.configuracaoPortais.modalPortais.modalNewMotors.email,
                        password: pState.configuracaoPortais.modalPortais.modalNewMotors.password
                    },
                    modalTemUsados: {
                        showModal: pState.configuracaoPortais.modalPortais.modalTemUsados.showModal,
                        id_anunciante: pState.configuracaoPortais.modalPortais.modalTemUsados.id_anunciante,
                        login: pState.configuracaoPortais.modalPortais.modalTemUsados.login,
                        senha: pState.configuracaoPortais.modalPortais.modalTemUsados.senha
                    },
                    modalCompreCar: {
                        showModal: pState.configuracaoPortais.modalPortais.modalCompreCar.showModal,
                        id_cliente: pState.configuracaoPortais.modalPortais.modalCompreCar.id_cliente,
                        token: pState.configuracaoPortais.modalPortais.modalCompreCar.token
                    },
                    modalAutoShoppingGlobal: {
                        showModal: pState.configuracaoPortais.modalPortais.modalAutoShoppingGlobal.showModal
                    },
                    modalValeVeiculos: {
                        showModal: pState.configuracaoPortais.modalPortais.modalValeVeiculos.showModal
                    },
                    modalAutoShoppingPontal: {
                        showModal: pState.configuracaoPortais.modalPortais.modalAutoShoppingPontal.showModal
                    },
                    modalSiteCliente: {
                        showModal: pState.configuracaoPortais.modalPortais.modalSiteCliente.showModal
                    },
                    modalFacebook: {
                        showModal: pState.configuracaoPortais.modalPortais.modalFacebook.showModal,
                        token: pState.configuracaoPortais.modalPortais.modalFacebook.token,
                        id_pagina: pState.configuracaoPortais.modalPortais.modalFacebook.id_pagina
                    },
                    modalAutoShoppingImigrantes: {
                        showModal: pState.configuracaoPortais.modalPortais.modalAutoShoppingImigrantes.showModal
                    },
                    modalMobiAuto: {
                        showModal: pState.configuracaoPortais.modalPortais.modalMobiAuto.showModal,
                        username: pState.configuracaoPortais.modalPortais.modalMobiAuto.username,
                        password: pState.configuracaoPortais.modalPortais.modalMobiAuto.password,
                        access_token: pState.configuracaoPortais.modalPortais.modalMobiAuto.access_token
                    },
                    modalCompreBlindados: {
                        showModal: pState.configuracaoPortais.modalPortais.modalCompreBlindados.showModal,
                        id_anunciante: pState.configuracaoPortais.modalPortais.modalCompreBlindados.id_anunciante,
                        login: pState.configuracaoPortais.modalPortais.modalCompreBlindados.login,
                        senha: pState.configuracaoPortais.modalPortais.modalCompreBlindados.senha
                    },
                    modalUsadosBR: {
                        showModal: pState.configuracaoPortais.modalPortais.modalUsadosBR.showModal,
                        userName: pState.configuracaoPortais.modalPortais.modalUsadosBR.userName,
                        password: pState.configuracaoPortais.modalPortais.modalUsadosBR.password
                    },
                }
            },
            configuracoesAdicionais: {
                isLoading: pState.configuracoesAdicionais.isLoading,
                errorText: pState.configuracoesAdicionais.errorText,
                id: pState.configuracoesAdicionais.id,
                remocaoAutomatica: pState.configuracoesAdicionais.remocaoAutomatica,
                textoPadrao: pState.configuracoesAdicionais.textoPadrao,
                valorPromocional: pState.configuracoesAdicionais.valorPromocional
            }
        });
    },
    fnBuscaTokenAnunciante: () => async (dispatch, getState) => {
        dispatch({ type: 'REQUEST_DADOS_ANUNCIANTE_ACTION' });

        await fetch(publicadorconfig_service.services.buscaTokenAnunciante(getState().auth.authorizationToken))
            .then(response => {
                if (response.status === 200 || response.status === 0) {
                    return response;
                }
                else {
                    return response.json().then(function (object) {
                        return Promise.reject(new Error(object));
                    })
                }
            })
            .then(data => {
                data.json().then(function (object) {
                    var objRetorno = { token: object.token };
                    dispatch({
                        type: 'RESPONSE_DADOS_ANUNCIANTE_ACTION', token: objRetorno.token
                    });
                });
            }).catch(function (err) {
                dispatch({
                    type: 'FAILURE_DADOS_ANUNCIANTE_ACTION', errorText: err.toString()
                });
            });
    },
    fnGerarTokenAnunciante: () => async (dispatch, getState) => {
        dispatch({ type: 'POST_DADOS_ANUNCIANTE_ACTION' });

        await fetch(publicadorconfig_service.services.inserirPublicadorConfig(getState().auth.authorizationToken))
            .then(response => {
                if (response.status === 200 || response.status === 0) {
                    return response;
                }
                else {
                    return response.json().then(function (object) {
                        return Promise.reject(new Error(object));
                    })
                }
            })
            .then(data => {
                data.json().then(function (object) {
                    dispatch({
                        type: 'RESPONSE_DADOS_ANUNCIANTE_ACTION', token: object
                    });
                });
            }).catch(function (err) {
                dispatch({ type: 'FAILURE_DADOS_ANUNCIANTE_ACTION', errorText: err.toString() });
            });
    },
    fnListaPortaisCredenciados: () => async (dispatch, getState) => {
        dispatch({ type: 'REQUEST_PORTAIS_CREDENCIADOS_ACTION' });

        await fetch(publicadorconfig_service.services.listaPortaisCredenciados(getState().auth.authorizationToken))
            .then(response => {
                if (response.status === 200 || response.status === 0) {
                    return response;
                }
                else {
                    return response.json().then(function (object) {
                        return Promise.reject(new Error(object));
                    })
                }
            })
            .then(data => {
                data.json().then(function (object) {
                    var dtoListResult = [];
                    object.forEach(function (entry) {
                        dtoListResult.push(
                            {
                                id: entry.id
                                , nome: entry.nome
                                , ordem: entry.ordem
                                , idAtivado: entry.idAtivado
                            }
                        );
                    });

                    dispatch({ type: 'RESPONSE_PORTAIS_CREDENCIADOS_ACTION', results: dtoListResult });
                });
            }).catch(function (err) {
                dispatch({ type: 'FAILURE_PORTAIS_CREDENCIADOS_ACTION', errorText: err.toString() });
            });
    },
    fnListaUF: () => async (dispatch, getState) => {
        dispatch({ type: 'REQUEST_UFS_EMPRESA_PARCEIRO_ANUNCIO_ACTION' });

        var pDTO = {
            "IdPublicador": getState().marketing_publicadorconfig.configuracaoPortais.modalPortais.idPublicador,
            "idVeiculo": 0,
            "Id": "",
            "Nome": ""
        };

        await fetch(publicadorconfig_service.services.listaUFsParceiroAnuncio(pDTO, getState().auth.authorizationToken))
            .then(response => {
                if (response.status === 200 || response.status === 0) {
                    return response;
                }
                else {
                    return response.json().then(function (object) {
                        return Promise.reject(new Error(object));
                    })
                }
            })
            .then(data => {
                data.json().then(function (object) {
                    var dtoListResult = [];
                    object.forEach(function (entry) { dtoListResult.push({ id: entry.id, nome: entry.Nome }); });
                    dispatch({ type: 'RESPONSE_UFS_EMPRESA_PARCEIRO_ANUNCIO_ACTION', results: dtoListResult });
                });
            }).catch(function (err) {
                dispatch({ type: 'FAILURE_UFS_EMPRESA_PARCEIRO_ANUNCIO_ACTION', errorText: err.toString() });
            });
    },
    fnListaCidades: (idUf) => async (dispatch, getState) => {
        dispatch({ type: 'REQUEST_CIDADES_EMPRESA_PARCEIRO_ANUNCIO_ACTION' });

        var pDTO = {
            "IdPublicador": getState().marketing_publicadorconfig.configuracaoPortais.modalPortais.idPublicador,
            "idVeiculo": 0,
            "Id": "",
            "Nome": "",
            "idUF": idUf
        };

        await fetch(publicadorconfig_service.services.listaCidadesParceiroAnuncio(pDTO, getState().auth.authorizationToken))
            .then(response => {
                if (response.status === 200 || response.status === 0) {
                    return response;
                }
                else {
                    return response.json().then(function (object) {
                        return Promise.reject(new Error(object));
                    })
                }
            })
            .then(data => {
                data.json().then(function (object) {
                    var dtoListResult = [];
                    object.forEach(function (entry) {
                        dtoListResult.push({ id: entry.id, nome: entry.Nome });
                    });

                    dispatch({ type: 'RESPONSE_CIDADES_EMPRESA_PARCEIRO_ANUNCIO_ACTION', results: dtoListResult });
                });
            }).catch(function (err) {
                dispatch({ type: 'FAILURE_CIDADES_EMPRESA_PARCEIRO_ANUNCIO_ACTION', errorText: err.toString() });
            });
    },
    fnListaBairros: (idCidade) => async (dispatch, getState) => {
        dispatch({ type: 'REQUEST_BAIRROS_EMPRESA_PARCEIRO_ANUNCIO_ACTION' });

        var pDTO = {
            "IdPublicador": getState().marketing_publicadorconfig.configuracaoPortais.modalPortais.idPublicador,
            "idVeiculo": 0,
            "Id": "",
            "Nome": "",
            "idUF": "",
            "idCidade": idCidade,
        };

        await fetch(publicadorconfig_service.services.listaBairrosParceiroAnuncio(pDTO, getState().auth.authorizationToken))
            .then(response => {
                if (response.status === 200 || response.status === 0) {
                    return response;
                }
                else {
                    return response.json().then(function (object) {
                        return Promise.reject(new Error(object));
                    })
                }
            })
            .then(data => {
                data.json().then(function (object) {
                    var dtoListResult = [];
                    object.forEach(function (entry) {
                        dtoListResult.push({ id: entry.id, nome: entry.Nome });
                    });

                    dispatch({ type: 'RESPONSE_BAIRROS_EMPRESA_PARCEIRO_ANUNCIO_ACTION', results: dtoListResult });
                });
            }).catch(function (err) {
                dispatch({ type: 'FAILURE_CIDADES_BAIRROS_PARCEIRO_ANUNCIO_ACTION', errorText: err.toString() });
            });
    },
    fnBuscaConfiguracaoPortal: () => async (dispatch, getState) => {
        dispatch({ type: 'REQUEST_DADOS_EMPRESA_PARCEIRO_ANUNCIO_ACTION' });

        try {
            const response = await await fetch(publicadorconfig_service.services.obterEmpresaParceiroAnuncio(getState().marketing_publicadorconfig.configuracaoPortais.modalPortais.idPublicador, getState().auth.authorizationToken));
            if (!response.ok) {
                throw new Error(response.statusText);
            }
            else {
                const json = await response.json();
                var objRetorno = {
                    jsDadosEspeciais: json.jsDadosEspeciais,
                    jsAutenticacao: json.jsAutenticacao
                };

                dispatch({
                    type: 'RESPONSE_DADOS_EMPRESA_PARCEIRO_ANUNCIO_ACTION',
                    jsAutenticacao: objRetorno.jsAutenticacao,
                    jsDadosEspeciais: objRetorno.jsDadosEspeciais
                });
            }
        } catch (err) {
            dispatch({ type: 'FAILURE_DADOS_EMPRESA_PARCEIRO_ANUNCIO_ACTION', errorText: err.toString() });
        }
    },
    fnInsereConfiguracaoPortal: (autenticacao, dadosespeciais) => async (dispatch, getState) => {
        var pDTO = {
            idPublicador: getState().marketing_publicadorconfig.configuracaoPortais.modalPortais.idPublicador,
            jsDadosEspeciais: dadosespeciais,
            jsAutenticacao: autenticacao
        };

        dispatch({ type: 'POST_DADOS_EMPRESA_PARCEIRO_ANUNCIO_ACTION' });

        await fetch(publicadorconfig_service.services.inserirEmpresaParceiroAnuncio(pDTO, getState().auth.authorizationToken))
            .then(response => {
                if (response.status === 200 || response.status === 0) {
                    return response;
                }
                else {
                    return response.json().then(function (object) {
                        return Promise.reject(new Error(object));
                    })
                }
            })
            .then(data => {
                data.json().then(function (object) {
                    dispatch({ type: 'SUCCESS_DADOS_EMPRESA_PARCEIRO_ANUNCIO_ACTION' });
                    dispatch({ type: 'UPDATE_LIST_PORTAIS_CREDENCIADOS', idAtivado: object });
                });
            }).catch(function (err) {
                dispatch({ type: 'FAILURE_DADOS_EMPRESA_PARCEIRO_ANUNCIO_ACTION', errorText: err.toString() });
            });
    },
    fnAtualizaConfiguracaoPortal: (autenticacao, dadosespeciais) => async (dispatch, getState) => {
        var pDTO = {
            idPublicador: getState().marketing_publicadorconfig.configuracaoPortais.modalPortais.idPublicador,
            jsDadosEspeciais: dadosespeciais,
            jsAutenticacao: autenticacao
        };

        dispatch({ type: 'PUT_DADOS_EMPRESA_PARCEIRO_ANUNCIO_ACTION' });

        await fetch(publicadorconfig_service.services.alterarEmpresaParceiroAnuncio(pDTO, getState().auth.authorizationToken))
            .then(response => {
                if (response.status === 200 || response.status === 0) {
                    return response;
                }
                else {
                    return response.json().then(function (object) {
                        return Promise.reject(new Error(object));
                    })
                }
            })
            .then(data => {
                data.json().then(function (object) {
                    dispatch({ type: 'SUCCESS_DADOS_EMPRESA_PARCEIRO_ANUNCIO_ACTION' });
                });
            }).catch(function (err) {
                dispatch({ type: 'FAILURE_DADOS_EMPRESA_PARCEIRO_ANUNCIO_ACTION', errorText: err.toString() });
            });
    },
    fnDeleteConfiguracaoPortal: () => async (dispatch, getState) => {
        var pDTO = { idPublicador: getState().marketing_publicadorconfig.configuracaoPortais.modalPortais.idPublicador };
        dispatch({ type: 'DELETE_DADOS_EMPRESA_PARCEIRO_ANUNCIO_ACTION' });

        await fetch(publicadorconfig_service.services.removerEmpresaParceiroAnuncio(pDTO, getState().auth.authorizationToken))
            .then(response => {
                if (response.status === 200 || response.status === 0) {
                    return response;
                }
                else {
                    return response.json().then(function (object) {
                        return Promise.reject(new Error(object));
                    })
                }
            })
            .then(data => {
                dispatch({ type: 'SUCCESS_DADOS_EMPRESA_PARCEIRO_ANUNCIO_ACTION' });
                dispatch({ type: 'UPDATE_LIST_PORTAIS_CREDENCIADOS', idAtivado: null })
            }).catch(function (err) {
                dispatch({ type: 'FAILURE_DADOS_EMPRESA_PARCEIRO_ANUNCIO_ACTION', errorText: err.toString() });
            });
    },
    fnBuscaConfiguracoesAdicionaisPublicador: () => async (dispatch, getState) => {
        dispatch({ type: 'REQUEST_PUBLICADOR_CONFIG_ADICIONAIS_ACTION' });

        await fetch(publicadorconfig_service.services.obterConfiguracoesAdicionaisPublicador(getState().auth.authorizationToken))
            .then(response => {
                if (response.status === 200 || response.status === 0) {
                    return response;
                }
                else {
                    return response.json().then(function (object) {
                        return Promise.reject(new Error(object));
                    })
                }
            })
            .then(data => {
                data.json().then(function (object) {
                    var objRetorno = {
                        "id": object.id,
                        "valorPromocional": object.valorPromocional,
                        "remocaoAutomatica": object.remocaoAutomatica,
                        "textoPadrao": object.textoPadrao
                    };

                    dispatch({
                        type: 'RESPONSE_PUBLICADOR_CONFIG_ADICIONAIS_ACTION',
                        id: objRetorno.id,
                        valorPromocional: objRetorno.valorPromocional,
                        remocaoAutomatica: objRetorno.remocaoAutomatica,
                        textoPadrao: objRetorno.textoPadrao
                    });
                });
            }).catch(function (err) {
                dispatch({ type: 'FAILURE_PUBLICADOR_CONFIG_ADICIONAIS_ACTION', errorText: err.toString() });
            });
    },
    fnInsereConfiguracoesAdicionaisPublicador: () => async (dispatch, getState) => {
        dispatch({ type: 'POST_PUBLICADOR_CONFIG_ADICIONAIS_ACTION' });

        var pDTO = {
            "valorPromocional": getState().marketing_publicadorconfig.configuracoesAdicionais.valorPromocional,
            "remocaoAutomatica": getState().marketing_publicadorconfig.configuracoesAdicionais.remocaoAutomatica,
            "textoPadrao": getState().marketing_publicadorconfig.configuracoesAdicionais.textoPadrao
        };

        await fetch(publicadorconfig_service.services.inserirConfiguracoesAdicionaisPublicador(pDTO, getState().auth.authorizationToken))
            .then(response => {
                if (response.status === 200 || response.status === 0) {
                    return response;
                }
                else {
                    return response.json().then(function (object) {
                        return Promise.reject(new Error(object));
                    })
                }
            })
            .then(data => {
                dispatch({ type: 'SUCCESS_PUBLICADOR_CONFIG_ADICIONAIS_ACTION' });
            }).catch(function (err) {
                dispatch({ type: 'FAILURE_PUBLICADOR_CONFIG_ADICIONAIS_ACTION', errorText: err.toString() });
            });
    },
    fnAtualizaConfiguracoesAdicionaisPublicador: () => async (dispatch, getState) => {
        dispatch({ type: 'PUT_PUBLICADOR_CONFIG_ADICIONAIS_ACTION' });

        var pDTO = {
            "id": getState().marketing_publicadorconfig.configuracoesAdicionais.id,
            "valorPromocional": getState().marketing_publicadorconfig.configuracoesAdicionais.valorPromocional,
            "remocaoAutomatica": getState().marketing_publicadorconfig.configuracoesAdicionais.remocaoAutomatica,
            "textoPadrao": getState().marketing_publicadorconfig.configuracoesAdicionais.textoPadrao
        };

        await fetch(publicadorconfig_service.services.alterarConfiguracoesAdicionaisPublicador(pDTO, getState().auth.authorizationToken))
            .then(response => {
                if (response.status === 200 || response.status === 0) {
                    return response;
                }
                else {
                    return response.json().then(function (object) {
                        return Promise.reject(new Error(object));
                    })
                }
            })
            .then(data => {
                dispatch({ type: 'SUCCESS_PUBLICADOR_CONFIG_ADICIONAIS_ACTION' });
            }).catch(function (err) {
                dispatch({ type: 'FAILURE_PUBLICADOR_CONFIG_ADICIONAIS_ACTION', errorText: err.toString() });
            });
    }
}

export const reducer = (state, action) => {
    state = state || initialState;

    // Atualiza o estado geral
    if (action.type === UPDATE_STATE_MARKETING_PUBLICADOR_CONFIG_ACTION) {
        return {
            ...state,
            dadosAnunciante: action.dadosAnunciante,
            configuracaoPortais: action.configuracaoPortais,
            configuracoesAdicionais: action.configuracoesAdicionais
        };
    }


    // Dados Anunciante
    if (action.type === REQUEST_DADOS_ANUNCIANTE_ACTION) {
        return {
            ...state,
            dadosAnunciante: {
                errorText: '',
                isLoading: true,
                token: ''
            }
        };
    }

    if (action.type === RESPONSE_DADOS_ANUNCIANTE_ACTION) {
        return {
            ...state,
            dadosAnunciante: {
                errorText: '',
                isLoading: false,
                token: action.token
            }
        };
    }

    if (action.type === FAILURE_DADOS_ANUNCIANTE_ACTION) {
        return {
            ...state,
            dadosAnunciante: {
                ...state.dadosAnunciante,
                errorText: action.errorText,
                isLoading: false
            }
        };
    }

    if (action.type === POST_DADOS_ANUNCIANTE_ACTION) {
        return {
            ...state,
            dadosAnunciante: {
                errorText: '',
                isLoading: true,
                token: ''
            }
        };
    }


    // Dados Portais
    if (action.type === REQUEST_PORTAIS_CREDENCIADOS_ACTION) {
        return {
            ...state,
            configuracaoPortais: {
                ...state.configuracaoPortais,
                errorText: '',
                isLoading: true,
                portaisCredenciados: []
            }
        };
    }

    if (action.type === RESPONSE_PORTAIS_CREDENCIADOS_ACTION) {
        return {
            ...state,
            configuracaoPortais: {
                ...state.configuracaoPortais,
                isLoading: false,
                portaisCredenciados: action.results
            }
        };
    }

    if (action.type === FAILURE_PORTAIS_CREDENCIADOS_ACTION) {
        return {
            ...state,
            configuracaoPortais: {
                ...state.configuracaoPortais,
                errorText: action.errorText,
                isLoading: false
            }
        };
    }

    if (action.type === REQUEST_DADOS_EMPRESA_PARCEIRO_ANUNCIO_ACTION) {
        return {
            ...state,
            configuracaoPortais: {
                ...state.configuracaoPortais,
                modalPortais: {
                    ...state.configuracaoPortais.modalPortais,
                    errorText: '',
                    isLoading: true
                }
            }
        };
    }

    if (action.type === RESPONSE_DADOS_EMPRESA_PARCEIRO_ANUNCIO_ACTION) {
        return {
            ...state,
            configuracaoPortais: {
                ...state.configuracaoPortais,
                modalPortais: {
                    ...state.configuracaoPortais.modalPortais,
                    isLoading: false,
                    jsAutenticacao: action.jsAutenticacao,
                    jsDadosEspeciais: action.jsDadosEspeciais
                }
            }
        };
    }

    if (action.type === POST_DADOS_EMPRESA_PARCEIRO_ANUNCIO_ACTION) {
        return {
            ...state,
            configuracaoPortais: {
                ...state.configuracaoPortais,
                modalPortais: {
                    ...state.configuracaoPortais.modalPortais,
                    errorText: '',
                    isLoading: true
                }
            }
        };
    }

    if (action.type === PUT_DADOS_EMPRESA_PARCEIRO_ANUNCIO_ACTION) {
        return {
            ...state,
            configuracaoPortais: {
                ...state.configuracaoPortais,
                modalPortais: {
                    ...state.configuracaoPortais.modalPortais,
                    errorText: '',
                    isLoading: true
                }
            }
        };
    }

    if (action.type === DELETE_DADOS_EMPRESA_PARCEIRO_ANUNCIO_ACTION) {
        return {
            ...state,
            configuracaoPortais: {
                ...state.configuracaoPortais,
                modalPortais: {
                    ...state.configuracaoPortais.modalPortais,
                    errorText: '',
                    isLoading: true
                }
            }
        };
    }

    if (action.type === SUCCESS_DADOS_EMPRESA_PARCEIRO_ANUNCIO_ACTION) {
        return {
            ...state,
            configuracaoPortais: {
                ...state.configuracaoPortais,
                modalPortais: {
                    ...state.configuracaoPortais.modalPortais,
                    isLoading: false,
                    ativado: true
                }
            }
        };
    }

    if (action.type === FAILURE_DADOS_EMPRESA_PARCEIRO_ANUNCIO_ACTION) {
        return {
            ...state,
            configuracaoPortais: {
                ...state.configuracaoPortais,
                modalPortais: {
                    ...state.configuracaoPortais.modalPortais,
                    errorText: action.errorText,
                    isLoading: false
                }
            }
        };
    }

    if (action.type === REQUEST_UFS_EMPRESA_PARCEIRO_ANUNCIO_ACTION) {
        return {
            ...state,
            configuracaoPortais: {
                ...state.configuracaoPortais,
                modalPortais: {
                    ...state.configuracaoPortais.modalPortais,
                    comboUF: {
                        isLoading: true,
                        errorText: '',
                        results: []
                    }
                }
            }
        };
    }

    if (action.type === RESPONSE_UFS_EMPRESA_PARCEIRO_ANUNCIO_ACTION) {
        return {
            ...state,
            configuracaoPortais: {
                ...state.configuracaoPortais,
                modalPortais: {
                    ...state.configuracaoPortais.modalPortais,
                    comboUF: {
                        isLoading: false,
                        errorText: '',
                        results: action.results
                    }
                }
            }
        };
    }

    if (action.type === FAILURE_UFS_EMPRESA_PARCEIRO_ANUNCIO_ACTION) {
        return {
            ...state,
            configuracaoPortais: {
                ...state.configuracaoPortais,
                modalPortais: {
                    ...state.configuracaoPortais.modalPortais,
                    comboUF: {
                        ...state.configuracaoPortais.modalPortais.comboUF,
                        isLoading: false,
                        errorText: action.errorText
                    }
                }
            }
        };
    }

    if (action.type === REQUEST_CIDADES_EMPRESA_PARCEIRO_ANUNCIO_ACTION) {
        return {
            ...state,
            configuracaoPortais: {
                ...state.configuracaoPortais,
                modalPortais: {
                    ...state.configuracaoPortais.modalPortais,
                    comboCidade: {
                        isLoading: true,
                        errorText: '',
                        results: []
                    }
                }
            }
        };
    }

    if (action.type === RESPONSE_CIDADES_EMPRESA_PARCEIRO_ANUNCIO_ACTION) {
        return {
            ...state,
            configuracaoPortais: {
                ...state.configuracaoPortais,
                modalPortais: {
                    ...state.configuracaoPortais.modalPortais,
                    comboCidade: {
                        isLoading: false,
                        errorText: '',
                        results: action.results
                    }
                }
            }
        };
    }

    if (action.type === FAILURE_CIDADES_EMPRESA_PARCEIRO_ANUNCIO_ACTION) {
        return {
            ...state,
            configuracaoPortais: {
                ...state.configuracaoPortais,
                modalPortais: {
                    ...state.configuracaoPortais.modalPortais,
                    comboCidade: {
                        ...state.configuracaoPortais.modalPortais.comboCidade,
                        isLoading: false,
                        errorText: action.errorText
                    }
                }
            }
        };
    }

    if (action.type === REQUEST_BAIRROS_EMPRESA_PARCEIRO_ANUNCIO_ACTION) {
        return {
            ...state,
            configuracaoPortais: {
                ...state.configuracaoPortais,
                modalPortais: {
                    ...state.configuracaoPortais.modalPortais,
                    comboBairro: {
                        isLoading: true,
                        errorText: '',
                        results: []
                    }
                }
            }
        };
    }

    if (action.type === RESPONSE_BAIRROS_EMPRESA_PARCEIRO_ANUNCIO_ACTION) {
        return {
            ...state,
            configuracaoPortais: {
                ...state.configuracaoPortais,
                modalPortais: {
                    ...state.configuracaoPortais.modalPortais,
                    comboBairro: {
                        isLoading: false,
                        errorText: '',
                        results: action.results
                    }
                }
            }
        };
    }

    if (action.type === FAILURE_BAIRROS_EMPRESA_PARCEIRO_ANUNCIO_ACTION) {
        return {
            ...state,
            configuracaoPortais: {
                ...state.configuracaoPortais,
                modalPortais: {
                    ...state.configuracaoPortais.modalPortais,
                    comboBairro: {
                        ...state.configuracaoPortais.modalPortais.comboBairro,
                        isLoading: false,
                        errorText: action.errorText
                    }
                }
            }
        };
    }


    if (action.type === UPDATE_LIST_PORTAIS_CREDENCIADOS) {
        var listPortaisCredenciados = [];
        state.configuracaoPortais.portaisCredenciados.forEach(function (entry) {
            if (entry.id === state.configuracaoPortais.modalPortais.idPublicador)
                entry.idAtivado = action.idAtivado;
            listPortaisCredenciados.push(entry);
        });

        return {
            ...state,
            configuracaoPortais: {
                ...state.configuracaoPortais,
                portaisCredenciados: listPortaisCredenciados
            }
        };
    }


    // Configurações Adicionais
    if (action.type === REQUEST_PUBLICADOR_CONFIG_ADICIONAIS_ACTION) {
        return {
            ...state,
            configuracoesAdicionais: {
                ...state.configuracoesAdicionais,
                isLoading: true,
                errorText: ''
            }
        };
    }

    if (action.type === RESPONSE_PUBLICADOR_CONFIG_ADICIONAIS_ACTION) {
        return {
            ...state,
            configuracoesAdicionais: {
                ...state.configuracoesAdicionais,
                id: action.id,
                valorPromocional: action.valorPromocional,
                remocaoAutomatica: action.remocaoAutomatica,
                textoPadrao: action.textoPadrao,
                isLoading: false
            }
        };
    }

    if (action.type === FAILURE_PUBLICADOR_CONFIG_ADICIONAIS_ACTION) {
        return {
            ...state,
            configuracoesAdicionais: {
                ...state.configuracoesAdicionais,
                isLoading: false,
                errorText: action.errorText
            }
        };
    }

    if (action.type === POST_PUBLICADOR_CONFIG_ADICIONAIS_ACTION) {
        return {
            ...state,
            configuracoesAdicionais: {
                ...state.configuracoesAdicionais,
                isLoading: true,
                errorText: ''
            }
        };
    }

    if (action.type === PUT_PUBLICADOR_CONFIG_ADICIONAIS_ACTION) {
        return {
            ...state,
            configuracoesAdicionais: {
                ...state.configuracoesAdicionais,
                isLoading: true,
                errorText: ''
            }
        };
    }

    if (action.type === SUCCESS_PUBLICADOR_CONFIG_ADICIONAIS_ACTION) {
        return {
            ...state,
            configuracoesAdicionais: {
                ...state.configuracoesAdicionais,
                isLoading: false
            }
        };
    }

    return state;
};
