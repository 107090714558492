import * as React from 'react';
import HeaderPage from '../../components/commons/HeaderPage';
import ConsultaForm from '../../components/crm/webmotors/ConsultaForm';

export default class Crm_WebMotors extends React.Component {
    render() {
        return <div className='page'>
            <HeaderPage
                path=' CRM'
                name='WebMotors' />
            <ConsultaForm />
        </div>;
    }
}