import * as React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actionCreators } from '../../../../store/documentos/daypag/DaypagHistorico';

import { Modal } from 'react-bootstrap';
import AlertError from '../../../commons/AlertError';
import ProgerssBar from '../../../commons/ProgressBar';

class ModalRecibo extends React.Component {
    constructor() {
        super();
    }

    async componentDidMount() {
        await this.props.fnBuscaRecibo();
    }

    handleOnHide() {
        this.props.modalRecibo.showModal = false;
        this.props.fnUpdateState(this.props);
    }

    render() {
        return <Modal
            show={this.props.modalRecibo.showModal}
            onHide={this.handleOnHide.bind(this)}
            bsSize="large"
        >
            <Modal.Body>
                <AlertError
                    errorText={this.props.modalRecibo.errorText}
                    alertVisible={this.props.modalRecibo.errorText != '' && this.props.modalRecibo.errorText != undefined ? true : false}
                    onDismiss={(alertVisible, errorText) => {
                        this.props.modalRecibo.errorText = errorText;
                        this.props.fnUpdateState(this.props);
                    }}
                />
                {
                    this.props.modalRecibo.isLoading
                        ? <ProgerssBar />
                        : <div>
                            <div id="showpdf" name="showpdf">
                                <object
                                    width="100%"
                                    height="500px"
                                    data={"data:application/pdf;base64," + this.props.modalRecibo.pdf}
                                    type="application/pdf"
                                    className="internal">
                                    <embed
                                        src={"data:application/pdf;base64," + this.props.modalRecibo.pdf}
                                        type="application/pdf" />
                                </object>
                            </div>
                        </div>
                }
            </Modal.Body>
        </Modal>;
    }
}

export default connect(
    state => state.daypag_historico,
    dispatch => bindActionCreators(actionCreators, dispatch),
    null, {}
)(ModalRecibo);