import React from 'react';
import { propTypes, withFormsy } from 'formsy-react';

class InputTextValidated extends React.Component {
    constructor(props) {
        super(props);
        this.changeValue = this.changeValue.bind(this);
    }

    changeValue(event) {
        // setValue() will set the value of the component, which in
        // turn will validate it and the rest of the form
        this.props.setValue(event.currentTarget[this.props.type === 'checkbox' ? 'checked' : 'value']);
        this.props.onChange(event.target.name, event.target.value);
    }

    render() {
        // Set a specific className based on the validation
        // state of this component. showRequired() is true
        // when the value is empty and the required prop is
        // passed to the input. showError() is true when the
        // value typed is invalid
        const className = `form-group ${this.props.className} ${this.props.showRequired() ? ' has-error has-feedback' : ''} ${this.props.showError() ? ' has-error has-feedback' : ''}`;

        // An error message is returned ONLY if the component is invalid
        // or the server has returned an error message
        const errorMessage = this.props.getErrorMessage();

        return (
            <div className={className}>
                <label class="control-label" data-required="true" htmlFor={this.props.name}>{this.props.title}</label>
                <input
                    onChange={this.changeValue}
                    name={this.props.name}
                    type={this.props.type || 'text'}
                    value={this.props.getValue() || ''}
                    className="form-control"
                    disabled={this.props.disabled}
                    maxLength={this.props.maxLength}
                />
                {
                    (errorMessage !== "" && errorMessage != undefined)
                        ? <span class="glyphicon glyphicon-remove form-control-feedback" aria-hidden="true"></span>
                        : null
                }
                {
                    (errorMessage !== "" && errorMessage != undefined)
                        ? <span className='help-block validation-message'>{errorMessage}</span>
                        : null
                }
            </div>
        );
    }
}

InputTextValidated.propTypes = {
    ...propTypes,
};

export default withFormsy(InputTextValidated);