import React from 'react';

class InputText extends React.Component {
    constructor(props) {
        super(props);
        this.changeValue = this.changeValue.bind(this);
    }

    changeValue(event) {
        this.props.onChange(event.target.name, event.target.value);
    }

    render() {
        const className = `form-group ${this.props.className}`;

        return (
            <div className={className}>
                <label class="control-label" data-required="true" htmlFor={this.props.name}>{this.props.title}</label>
                <input
                    onChange={this.changeValue}
                    name={this.props.name}
                    type={this.props.type || 'text'}
                    value={this.props.value || ''}
                    className="form-control"
                    disabled={this.props.disabled}
                />
                {
                    this.props.info !== undefined && this.props.info !== ""
                        ? <span className="help-block mb-0 text-cyan">{this.props.info}</span>
                        : null
                }
                
            </div>
        );
    }
}

export default InputText;