import * as React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actionCreators } from '../../../../store/documentos/daypag/DaypagConsulta';

import { Row } from 'react-bootstrap';
import Formsy from 'formsy-react';
import Moment from 'react-moment';
import AlertError from '../../../commons/AlertError';
import AlertInfo from '../../../commons/AlertInfo';
import Section from '../../../commons/Section';
import BtnSalvar from '../../../commons/BtnSalvar';
import InputTextValidated from '../../../commons/InputTextValidated';
import BtnPesquisar from '../../../commons/BtnPesquisar';
import InputSelectValidated from '../../../commons/InputSelectValidated';

class ConsultaForm extends React.Component {
    constructor() {
        super();
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {
        this.props.fnListaTipoServico();
    }

    handleSubmit(e) {
        this.props.fnConsultaDetalhada();
    }

    render() {
        var objProps = this.props;

        var dropdownTipoServico = [];
        dropdownTipoServico.push({ value: '', text: 'Selecione o Tipo de Serviço' });
        objProps.consultaDebitos.dropdownTipoServico.results.forEach(function (entry) {
            dropdownTipoServico.push({ value: entry.id, text: entry.nome });
        });

        var dropdownTipoSubServico = [];
        dropdownTipoSubServico.push({ value: '', text: 'Selecione o Sub-Serviço' });
        objProps.consultaDebitos.dropdownTipoSubServico.results.forEach(function (entry) {
            dropdownTipoSubServico.push({ value: entry.id, text: entry.descricao });
        });

        return <div>
            <Row>
                <div className="col-md-12">
                    <Section>
                        <div className="tile-header bg-blue dvd dvd-btm">
                            <h3 className="custom-font"><strong>Daypag - Consultas e Pagamentos</strong></h3>
                        </div>
                        <div className="tile-body nopadding">
                            <AlertError
                                errorText={this.props.consultaDebitos.errorText}
                                alertVisible={this.props.consultaDebitos.errorText != '' && this.props.consultaDebitos.errorText != undefined ? true : false}
                                onDismiss={(alertVisible, errorText) => {
                                    this.props.consultaDebitos.errorText = errorText;
                                    this.props.fnUpdateState(this.props);
                                }}
                            />
                            <AlertError
                                errorText={this.props.pagamento.errorText}
                                alertVisible={this.props.pagamento.errorText != '' && this.props.pagamento.errorText != undefined ? true : false}
                                onDismiss={(alertVisible, errorText) => {
                                    this.props.pagamento.errorText = errorText;
                                    this.props.fnUpdateState(this.props);
                                }}
                            />
                            <AlertInfo
                                alertVisible={true}
                                text={"Caro cliente o Banco Daycoval é parceiro do BNDV para recolhimento de taxas e débitos de veículos. Com este serviço você pode ter maior controle sobre os" +
                                    " gastos de sua loja com documentação dos seus veículos em estoque, venda e compra. Abra já sua conta no banco Daycoval e utilize este serviço exclusivo."}
                                textHeader="Banco Daycoval"
                            />
                            <Row>
                                <div className="form-group col-md-12">
                                    <Formsy onValidSubmit={this.handleSubmit}>
                                        <Row>
                                            <InputTextValidated
                                                name="txtRenavam"
                                                type="number"
                                                className="col-md-2"
                                                placeholder=""
                                                onChange={(name, value) => {
                                                    this.props.consultaDebitos.renavam = value;
                                                    this.props.fnUpdateState(this.props);
                                                }}
                                                value={this.props.consultaDebitos.renavam}
                                                title="Renavam:"
                                                required
                                            />
                                            <InputSelectValidated
                                                name="ddlTipoServico"
                                                className="col-md-6"
                                                data={(this.props.consultaDebitos.dropdownTipoServico.isLoading
                                                    ? [{ value: '', text: 'Aguarde carregando...' }]
                                                    : dropdownTipoServico)}
                                                onChange={
                                                    (name, value) => {
                                                        this.props.consultaDebitos.tipoServico = value;
                                                        if (value != '') {
                                                            this.props.fnListaTipoSubServico(value);
                                                            this.props.consultaDebitos.tipoSubServico = '';
                                                        }
                                                        else {
                                                            this.props.consultaDebitos.dropdownTipoSubServico.isLoading = false;
                                                            this.props.consultaDebitos.dropdownTipoSubServico.errorText = '';
                                                            this.props.consultaDebitos.dropdownTipoSubServico.results = [];
                                                            this.props.consultaDebitos.tipoSubServico = '';
                                                        };
                                                        this.props.fnUpdateState(this.props);
                                                    }
                                                }
                                                selectedValue={
                                                    (this.props.consultaDebitos.tipoServico == null)
                                                        ? null
                                                        : this.props.consultaDebitos.tipoServico.toString()
                                                }
                                                title="Tipo de serviço:"
                                                disabled={this.props.consultaDebitos.dropdownTipoServico.isLoading ? true : false}
                                                required
                                            />
                                            <InputSelectValidated
                                                name="ddlTipoSubServico"
                                                className="col-md-4"
                                                data={(this.props.consultaDebitos.dropdownTipoSubServico.isLoading
                                                    ? [{ value: '', text: 'Aguarde carregando...' }]
                                                    : dropdownTipoSubServico)}
                                                onChange={
                                                    (name, value) => {
                                                        this.props.consultaDebitos.tipoSubServico = value;
                                                        this.props.fnUpdateState(this.props);
                                                    }
                                                }
                                                selectedValue={
                                                    (this.props.consultaDebitos.tipoSubServico == null)
                                                        ? null
                                                        : this.props.consultaDebitos.tipoSubServico.toString()
                                                }
                                                title="Tipo de sub-serviço:"
                                                disabled={this.props.consultaDebitos.dropdownTipoSubServico.isLoading ? true : false}
                                            />
                                            <div className="form-group col-md-12">
                                                <label>&nbsp;</label>
                                                <BtnPesquisar
                                                    type="submit"
                                                    isLoading={this.props.consultaDebitos.isLoading}
                                                    text=" Consultar Veículo"
                                                    textLoading=" Aguarde..." />
                                            </div>
                                        </Row>
                                    </Formsy>
                                </div>
                            </Row>
                        </div>
                    </Section>
                </div>
                {this.props.consultaDebitos.result.length > 0
                    ? <div className="col-md-12">
                        <Section>
                            <div className="tile-header bg-blue dvd dvd-btm">
                                <h3 className="custom-font"><strong>Informações principais</strong></h3>
                            </div>
                            <div className="tile-body nopadding">
                                <dl className="row">
                                    <dt className="col-sm-2">Renavam</dt>
                                    <dd className="col-sm-4">{this.props.consultaDebitos.result[0].Renavam}</dd>
                                    <dt className="col-sm-2">Placa</dt>
                                    <dd className="col-sm-4">{this.props.consultaDebitos.result[0].Placa}</dd>

                                    <dt className="col-sm-2">Documento</dt>
                                    <dd className="col-sm-4">{this.props.consultaDebitos.result[0].Documento}</dd>
                                    <dt className="col-sm-2">Exercício</dt>
                                    <dd className="col-sm-4">{this.props.consultaDebitos.result[0].Exercicio}</dd>

                                    <dt className="col-sm-2">Nome</dt>
                                    <dd className="col-sm-4">{this.props.consultaDebitos.result[0].Nome}</dd>
                                    <dt className="col-sm-2">Município</dt>
                                    <dd className="col-sm-4">{this.props.consultaDebitos.result[0].Municipio.Nome}</dd>

                                    <dt className="col-sm-2">Data/Hora Consulta</dt>
                                    <dd className="col-sm-10"><Moment format="DD/MM/YYYY HH:mm">{this.props.consultaDebitos.result[0].DataHoraConsulta}</Moment></dd>
                                </dl>
                            </div>
                            <div className="tile-header bg-blue dvd dvd-btm">
                                <h3 className="custom-font"><strong>Totais por tipo de débito</strong></h3>
                            </div>
                            <div className="tile-body nopadding">
                                <dl className="row">
                                    <dt className="col-sm-2">Total Taxas</dt>
                                    <dd className="col-sm-4">{this.props.consultaDebitos.result[0].ValorTaxas == null ? "0,00" : this.props.consultaDebitos.result[0].ValorTaxas}</dd>
                                    <dt className="col-sm-2">Total Ipva</dt>
                                    <dd className="col-sm-4">{this.props.consultaDebitos.result[0].ValorIpvas == null ? "0,00" : this.props.consultaDebitos.result[0].ValorIpvas}</dd>

                                    <dt className="col-sm-2">Total Dpvats</dt>
                                    <dd className="col-sm-4">{this.props.consultaDebitos.result[0].ValorDpvats == null ? "0,00" : this.props.consultaDebitos.result[0].ValorDpvats}</dd>
                                    <dt className="col-sm-2">Total Multas</dt>
                                    <dd className="col-sm-4">{this.props.consultaDebitos.result[0].ValorMultas == null ? "0,00" : this.props.consultaDebitos.result[0].ValorMultas}</dd>

                                    <dt className="col-sm-2">Total Multas Renainf</dt>
                                    <dd className="col-sm-4">{this.props.consultaDebitos.result[0].ValorMultasRenainf == null ? "0,00" : this.props.consultaDebitos.result[0].ValorMultasRenainf}</dd>
                                    <dt className="col-sm-2">Total Geral</dt>
                                    <dd className="col-sm-4">{this.props.consultaDebitos.result[0].ValorTotal == null ? "0,00" : this.props.consultaDebitos.result[0].ValorTotal}</dd>
                                </dl>
                            </div>
                            {this.props.consultaDebitos.result[0].Taxas != null
                                ? <div>
                                    <div className="tile-header bg-blue dvd dvd-btm">
                                        <h3 className="custom-font"><strong>Listagem de Taxas do Detran</strong></h3>
                                    </div>
                                    <div className="tile-body nopadding">
                                        <table className="table">
                                            <thead>
                                                <tr>
                                                    <th scope="col">Descrição</th>
                                                    <th scope="col">Ano</th>
                                                    <th scope="col">Valor</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    this.props.consultaDebitos.result[0].Taxas.map(function (rowData, index) {
                                                        return (
                                                            <tr key={index}>
                                                                <td>{rowData.Descricao}</td>
                                                                <td>{rowData.Ano}</td>
                                                                <td>{rowData.Valor}</td>
                                                            </tr>
                                                        )
                                                    })
                                                }
                                                <tr>
                                                    <td colSpan={3} className="dataTables_empty text-right"><strong>Valor Total: </strong>{
                                                        this.props.consultaDebitos.result[0].ValorTaxas == null
                                                            ? "0,00"
                                                            : this.props.consultaDebitos.result[0].ValorTaxas
                                                    }</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                : null}
                            {this.props.consultaDebitos.result[0].Ipvas != null
                                ? <div>
                                    <div className="tile-header bg-blue dvd dvd-btm">
                                        <h3 className="custom-font"><strong>Listagem de débitos de Ipva</strong></h3>
                                    </div>
                                    <div className="tile-body nopadding">
                                        <table className="table">
                                            <thead>
                                                <tr>
                                                    <th scope="col">Exercício</th>
                                                    <th scope="col">Cota</th>
                                                    <th scope="col">Valor</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    this.props.consultaDebitos.result[0].Ipvas.map(function (rowData, index) {
                                                        return (
                                                            <tr key={index}>
                                                                <td>{rowData.Exercicio}</td>
                                                                <td>{rowData.CotaIpva}</td>
                                                                <td>{rowData.Valor}</td>
                                                            </tr>
                                                        )
                                                    })
                                                }
                                                <tr>
                                                    <td colSpan={3} className="dataTables_empty text-right"><strong>Valor Total: </strong>{
                                                        this.props.consultaDebitos.result[0].ValorIpvas == null
                                                            ? "0,00"
                                                            : this.props.consultaDebitos.result[0].ValorIpvas
                                                    }</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                : null}
                            {this.props.consultaDebitos.result[0].Dpvats != null
                                ? <div>
                                    <div className="tile-header bg-blue dvd dvd-btm">
                                        <h3 className="custom-font"><strong>Listagem de débitos de Dpvat</strong></h3>
                                    </div>
                                    <div className="tile-body nopadding">
                                        <table className="table">
                                            <thead>
                                                <tr>
                                                    <th scope="col">Exercício</th>
                                                    <th scope="col">Cota</th>
                                                    <th scope="col">Valor</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    this.props.consultaDebitos.result[0].Dpvats.map(function (rowData, index) {
                                                        return (
                                                            <tr key={index}>
                                                                <td>{rowData.Exercicio}</td>
                                                                <td>{rowData.CotaDpvat}</td>
                                                                <td>{rowData.Valor}</td>
                                                            </tr>
                                                        )
                                                    })
                                                }
                                                <tr>
                                                    <td colSpan={3} className="dataTables_empty text-right"><strong>Valor Total: </strong>{
                                                        this.props.consultaDebitos.result[0].ValorDpvats == null
                                                            ? "0,00"
                                                            : this.props.consultaDebitos.result[0].ValorDpvats
                                                    }</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                : null}
                            {this.props.consultaDebitos.result[0].Multas != null
                                ? <div>
                                    <div className="tile-header bg-blue dvd dvd-btm">
                                        <h3 className="custom-font"><strong>Listagem de débitos de Multas</strong></h3>
                                    </div>
                                    <div className="tile-body nopadding">
                                        <table className="table">
                                            <thead>
                                                <tr>
                                                    <th scope="col">Aiip</th>
                                                    <th scope="col">Guia</th>
                                                    <th scope="col">Data Infração</th>
                                                    <th scope="col">Município</th>
                                                    <th scope="col">Enquadramento</th>
                                                    <th scope="col">Data Vencimento</th>
                                                    <th scope="col">Órgão Autuador</th>
                                                    <th scope="col">Valor</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    this.props.consultaDebitos.result[0].Multas.map(function (rowData, index) {
                                                        return (
                                                            <tr key={index}>
                                                                <td>{rowData.Aiip}</td>
                                                                <td>{rowData.Guia}</td>
                                                                <td><Moment format="DD/MM/YYYY">{rowData.DataInfracao}</Moment></td>
                                                                <td>{rowData.Municipio.Nome}</td>
                                                                <td>{rowData.Enquadramento.Descricao}</td>
                                                                <td><Moment format="DD/MM/YYYY">{rowData.DataVencimento}</Moment></td>
                                                                <td>{rowData.OrgaoAutuador.Nome}</td>
                                                                <td>{rowData.Valor}</td>
                                                            </tr>
                                                        )
                                                    })
                                                }
                                                <tr>
                                                    <td colSpan={8} className="dataTables_empty text-right"><strong>Valor Total: </strong>{
                                                        this.props.consultaDebitos.result[0].ValorMultas == null
                                                            ? "0,00"
                                                            : this.props.consultaDebitos.result[0].ValorMultas
                                                    }</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                : null}
                            {this.props.consultaDebitos.result[0].MultasRenainf != null
                                ? <div>
                                    <div className="tile-header bg-blue dvd dvd-btm">
                                        <h3 className="custom-font"><strong>Listagem de débitos de Multas Renainf</strong></h3>
                                    </div>
                                    <div className="tile-body nopadding">
                                        <table className="table">
                                            <thead>
                                                <tr>
                                                    <th scope="col">Aiip</th>
                                                    <th scope="col">Guia</th>
                                                    <th scope="col">Data Infração</th>
                                                    <th scope="col">UF</th>
                                                    <th scope="col">Município</th>
                                                    <th scope="col">Enquadramento</th>
                                                    <th scope="col">Data Vencimento</th>
                                                    <th scope="col">Órgão Autuador</th>
                                                    <th scope="col">Valor</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    this.props.consultaDebitos.result[0].MultasRenainf.map(function (rowData, index) {
                                                        return (
                                                            <tr key={index}>
                                                                <td>{rowData.Aiip}</td>
                                                                <td>{rowData.Guia}</td>
                                                                <td><Moment format="DD/MM/YYYY HH:mm">{rowData.DataInfracao}</Moment></td>
                                                                <td>{rowData.Uf}</td>
                                                                <td>{rowData.Municipio.Nome}</td>
                                                                <td>{rowData.Enquadramento.Descricao}</td>
                                                                <td><Moment format="DD/MM/YYYY HH:mm">{rowData.DataVencimento}</Moment></td>
                                                                <td>{rowData.OrgaoAutuador.Nome}</td>
                                                                <td>{rowData.Valor}</td>
                                                            </tr>
                                                        )
                                                    })
                                                }
                                                <tr>
                                                    <td colSpan={8} className="dataTables_empty text-right"><strong>Valor Total: </strong>{
                                                        this.props.consultaDebitos.result[0].ValorMultasRenainf == null
                                                            ? "0,00"
                                                            : this.props.consultaDebitos.result[0].ValorMultasRenainf
                                                    }</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                : null}
                            <div className="tile-body nopadding" style={{ height: '80px' }}>
                                <div className="col-md-12 text-right">
                                    <BtnSalvar
                                        onClick={() => { this.props.fnPagamento(); }}
                                        isLoading={this.props.pagamento.isLoading}
                                        text={" Efetuar Pagamento"}
                                        textLoading={" Aguarde..."}
                                        type="button"
                                    />
                                </div>
                            </div>
                        </Section>
                    </div>
                    : null}
            </Row>
        </div>;
    }
}

export default connect(
    state => state.daypag_consulta,
    dispatch => bindActionCreators(actionCreators, dispatch),
    null, {}
)(ConsultaForm);