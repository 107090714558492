import * as React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { fetch, addTask } from 'domain-task';
import { Row } from 'react-bootstrap';
import * as dashboard_service from '../../../services/dashboard/dashboard_service';
import { actionCreators } from '../../../store/dashboard/DashboardAdministrador';
import DashboardAdministradorForm from './DashboardAdministradorForm';
import DashboardAdministradorComprasForm from './DashboardAdministradorComprasForm';
import DashboardAdministradorCRMForm from './DashboardAdministradorCRMForm';
import InputSelect from '../../commons/InputSelect';

class DashboardAdministradorTab extends React.Component {
    constructor(props) {
        super(props);

        this.state = ({
            tabVendas: "active",
            paneVendas: "tab-pane active",
            tabCompras: "",
            paneCompras: "tab-pane",
            tabCrm: "",
            paneCrm: "tab-pane",
            empresasData: []
        });
    }

    componentDidMount() {
        let fetchTask = fetch(dashboard_service.services.Administrador.ListaEmpresasVinculadasUsuario(this.props.auth.authorizationToken))
            .then(response => {
                if (response.status === 200 || response.status === 0) {
                    return response.json();
                }
                else {
                    return response.json().then(function (object) {
                        return Promise.reject(new Error(object));
                    })
                }
            })
            .then(data => {
                var arrData = [];
                data.forEach(function (entry) {
                    arrData.push({ value: entry.idEmpresa, text: entry.NomeFantasia });
                });

                this.setState({
                    tabVendas: "active",
                    paneVendas: "tab-pane active",
                    tabCompras: "",
                    paneCompras: "tab-pane",
                    tabCrm: "",
                    paneCrm: "tab-pane",
                    empresasData: arrData
                });

                this.props.fnChangeEmpresa(arrData[0].value);

            }).catch(function (err) {
                console.log(err);
            });

        addTask(fetchTask);
    }

    changeTab(tab, e) {
        if (tab === 1) {
            this.setState({
                tabVendas: "active",
                paneVendas: "tab-pane active",
                tabCompras: "",
                paneCompras: "tab-pane",
                tabCrm: "",
                paneCrm: "tab-pane",
                empresasData: this.state.empresasData
            });
        }
        else if (tab === 2) {
            this.setState({
                tabVendas: "",
                paneVendas: "tab-pane",
                tabCompras: "active",
                paneCompras: "tab-pane active",
                tabCrm: "",
                paneCrm: "tab-pane",
                empresasData: this.state.empresasData
            });
        }
        else if (tab === 3) {
            this.setState({
                tabVendas: "",
                paneVendas: "tab-pane",
                tabCompras: "",
                paneCompras: "tab-pane",
                tabCrm: "active",
                paneCrm: "tab-pane active",
                empresasData: this.state.empresasData
            });
        }
    }

    render() {
        return (
            <Row>
                <div className="col-md-12">
                    <section className="tile time-simple">
                        <div className="tile-body">
                            <div role="tabpanel">
                                <ul className="nav nav-tabs">
                                    <li role="presentation" className={this.state.tabVendas}><a onClick={this.changeTab.bind(this, 1)}>Vendas</a></li>
                                    <li role="presentation" className={this.state.tabCompras}><a onClick={this.changeTab.bind(this, 2)}>Compras</a></li>
                                    <li role="presentation" className={this.state.tabCrm}><a onClick={this.changeTab.bind(this, 3)}>CRM</a></li>
                                    <li role="presentation" style={{ float: 'right' }}>
                                        <InputSelect
                                            class="form-control input-sm"
                                            data={this.state.empresasData}
                                            onChange={(name, value) => { this.props.fnChangeEmpresa(value); }}
                                            selectedValue={this.props.dashboardadministrador.empresaSelected}
                                        />
                                    </li>
                                </ul>
                                <div className="tab-content">
                                    <div role="tabpanel" className={this.state.paneVendas}>
                                        {
                                            this.state.tabVendas === "active"
                                                ? <DashboardAdministradorForm />
                                                : null
                                        }
                                    </div>
                                    <div role="tabpanel" className={this.state.paneCompras}>
                                        {
                                            this.state.tabCompras === "active"
                                                ? <DashboardAdministradorComprasForm />
                                                : null
                                        }
                                    </div>
                                    <div role="tabpanel" className={this.state.paneCrm}>
                                        {
                                            this.state.tabCrm === "active"
                                                ? <DashboardAdministradorCRMForm />
                                                : null
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </Row>
        )
    }
}

function mapStateToProps(state) {
    return {
        auth: state.auth,
        dashboardadministrador: state.dashboardadministrador
    }
}

export default connect(
    mapStateToProps,
    dispatch => bindActionCreators(actionCreators, dispatch),
    null,
    { pure: false }
)(DashboardAdministradorTab);