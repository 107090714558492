import * as React from 'react';

import HeaderPage from '../../components/commons/HeaderPage';
import HistoricoPagamentosForm from '../../components/documentos/daypag/extrato/HistoricoPagamentosForm';

export default class Daypag_HistoricoPagamentos extends React.Component {
    render() {
        return <div className='page'>
            <HeaderPage
                path=' Documentação Veículos'
                name='Daypag'
                subName='Histórico de Pagamentos' />
            <HistoricoPagamentosForm />
        </div>;
    }
}