import * as React from 'react';

export default class HeaderPage extends React.Component {
    render() {
        return <div className="pageheader">
            <div className="page-bar">
                <ul className="page-breadcrumb">
                    <li>
                        <a>{this.props.path}</a>
                    </li>
                    <li>
                        <a>{this.props.name}</a>
                    </li>
                    {
                        this.props.subName !== undefined
                            && this.props.subName !== ''
                            ? <li>
                                <a>{this.props.subName}</a>
                            </li>
                            : null
                    }
                    {
                        this.props.subName !== undefined
                            && this.props.subName !== ''
                            && this.props.description !== undefined
                            && this.props.description !== ''
                            ? <li>
                                <a>{this.props.description}</a>
                            </li>
                            : null
                    }
                </ul>
            </div>
        </div>;
    }
}