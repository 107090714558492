import * as React from 'react';
import { Alert, Button } from 'react-bootstrap';

export default class AlertDismissable extends React.Component {
    constructor(props, context) {
        super(props, context);

        this.handleDismiss = this.handleDismiss.bind(this);
        this.handleShow = this.handleShow.bind(this);

        this.state = { show: this.props.show };
    }

    componentWillReceiveProps(nextProps) {
        this.setState({ show: nextProps.show });
    }

    handleDismiss() {
        this.setState({ show: false });
    }

    handleShow() {
        this.setState({ show: true });
    }

    render() {
        return (
            this.state.show
                ? <Alert
                    bsStyle={this.props.bsStyle || 'danger'}
                    onDismiss={this.handleDismiss}
                >
                    <h4>{this.props.title}</h4>
                    <p>{this.props.text}</p>
                </Alert>
                : null
        );
    }
}