import * as React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actionCreators } from '../../../store/edoc/EdocConfiguracao';

import Formsy from 'formsy-react';
import { Modal, Row } from 'react-bootstrap';
import AlertError from '../../commons/AlertError';
import Section from '../../commons/Section';
import InputTextValidated from '../../commons/InputTextValidated';
import BtnSalvar from '../../commons/BtnSalvar';
import BtnCancelar from '../../commons/BtnCancelar';

class ConfiguracaoForm extends React.Component {
    constructor() {
        super();
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    async componentDidMount() {
        await this.props.fnListaTiposDocumento();
    }

    handleSubmit(model) {
        this.props.fnAdicionarTipoDocumento();
    }

    render() {
        var objProps = this.props;
        return <div>
            <Row>
                <div className="col-md-12">
                    <Section>
                        <div className="tile-header bg-blue dvd dvd-btm">
                            <h3 className="custom-font"><strong>Configuração dos Tipos de Documentos</strong></h3>
                        </div>
                        <div className="tile-body nopadding">
                            <AlertError
                                errorText={this.props.tipoDocumentoConfig.errorText}
                                alertVisible={this.props.tipoDocumentoConfig.errorText != '' && this.props.tipoDocumentoConfig.errorText != undefined ? true : false}
                                onDismiss={(alertVisible, errorText) => {
                                    this.props.tipoDocumentoConfig.errorText = errorText;
                                    this.props.fnUpdateState(this.props);
                                }}
                            />
                            <Row>
                                <div className="form-group col-md-12">
                                    <Formsy onValidSubmit={this.handleSubmit}>
                                        <Row>
                                            <InputTextValidated
                                                name="txtTipoDocumento"
                                                type="text"
                                                className="col-md-6"
                                                placeholder="Ex. Comprovante de Endereço"
                                                onChange={(name, value) => {
                                                    this.props.tipoDocumentoConfig.tipoDocumento = value;
                                                    this.props.fnUpdateState(this.props);
                                                }}
                                                value={this.props.tipoDocumentoConfig.tipoDocumento}
                                                required
                                                title="Tipo de documento:"
                                            />
                                            <div className="form-group col-md-2" style={{ marginTop: '23px' }}>
                                                <BtnSalvar
                                                    type="submit"
                                                    isLoading={this.props.tipoDocumentoConfig.isLoading}
                                                    text=" Adicionar"
                                                    textLoading=" Aguarde..."
                                                />
                                            </div>
                                        </Row>
                                    </Formsy>
                                    <br />
                                    <div className="table-responsive">
                                        <div className="dataTables_wrapper form-inline dt-bootstrap no-footer">
                                            <Row>
                                                <div className="col-sm-12">
                                                    <table className="table table-custom dataTable no-footer">
                                                        <thead>
                                                            <tr role="row">
                                                                <th></th>
                                                                <th>Tipo de Documento</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {
                                                                (this.props.tipoDocumentoConfig.isLoading)
                                                                    ? <tr key={0} className="odd">
                                                                        <td colSpan={7} className="dataTables_empty">
                                                                            <div className="progress progress-striped active">
                                                                                <div className="progress-bar progress-bar-blue" role="progressbar" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100" style={{ width: "100%" }}>
                                                                                    <span className="sr-only">100% Complete</span>
                                                                                </div>
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                    : (this.props.tipoDocumentoConfig.results.length == 0)
                                                                        ? <tr key={0} className="odd">
                                                                            <td colSpan={8} className="dataTables_empty">
                                                                                <p className="text-warning">Não foram encontrados tipos de documento cadastrados...</p>
                                                                            </td>
                                                                        </tr>
                                                                        : this.props.tipoDocumentoConfig.results.map(function (rowData, index) {
                                                                            return (
                                                                                <tr key={index}>
                                                                                    <td>
                                                                                        <a
                                                                                            role="button"
                                                                                            tabIndex={0}
                                                                                            className="delete text-danger text-uppercase text-strong text-sm mr-10"
                                                                                            onClick={() => {
                                                                                                objProps.tipoDocumentoConfig.idTipoDocumentoRemocao = rowData.id;
                                                                                                objProps.tipoDocumentoConfig.showModalConfirmaRemocao = true;
                                                                                                objProps.fnUpdateState(objProps);
                                                                                            }}>Remover</a>
                                                                                    </td>
                                                                                    <td>{rowData.nome}</td>
                                                                                </tr>
                                                                            )
                                                                        })
                                                            }
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </Row>
                                        </div>
                                    </div>
                                </div>
                            </Row>
                        </div>
                    </Section>
                </div>
            </Row>
            <Modal show={this.props.tipoDocumentoConfig.showModalConfirmaRemocao}>
                <Modal.Header>
                    <h3 className="modal-title custom-font">Confirmação</h3>
                </Modal.Header>
                <Modal.Body>Confirma a exclusão do tipo de documento? Após removido este tipo de documento não poderá mais ser utilizado para fazer upload de arquivos no aplicativo do BNDV, fique tranquilo você não perderá o histórico dos arquivos que utilizaram este tipo de documento.</Modal.Body>
                <Modal.Footer>
                    <BtnSalvar
                        type="button"
                        text=" Confirmar Remoção"
                        textLoading=" Aguarde..."
                        isLoading={this.props.tipoDocumentoConfig.isLoading}
                        onClick={() => { this.props.fnRemoverTipoDocumento(this.props.tipoDocumentoConfig.idTipoDocumentoRemocao); }}
                    />
                    <BtnCancelar
                        type="button"
                        text=" Cancelar"
                        textLoading=" Aguarde..."
                        onClick={() => {
                            this.props.tipoDocumentoConfig.showModalConfirmaRemocao = false;
                            this.props.fnUpdateState(this.props);
                        }}
                        isLoading={this.props.tipoDocumentoConfig.isLoading}
                    />
                </Modal.Footer>
            </Modal>
        </div>;
    }
}

export default connect(
    state => state.edoc_configuracao,
    dispatch => bindActionCreators(actionCreators, dispatch),
    null, { pure: true }
)(ConfiguracaoForm);