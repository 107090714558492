import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { actionCreators } from '../../store/login';

class NavHeader extends Component {
    render() {
        return (
            <section id="header">
                <header className="clearfix">
                    <div className="branding">
                        <Link to={'/'} className="brand">
                            <span><strong>BN</strong>DV</span>
                        </Link>
                        <a role="button" tabIndex={0} className="offcanvas-toggle visible-xs-inline"><i className="fa fa-bars"></i></a>
                    </div>
                    <ul className="nav-left pull-left list-unstyled list-inline">
                        <li className="sidebar-collapse divided-right">
                            <a role="button" tabIndex={0} className="collapse-sidebar">
                                <i className="fa fa-outdent"></i>
                            </a>
                        </li>
                    </ul>
                    <ul className="nav-right pull-right list-inline">
                        <li className="">
                            <a
                                href="https://app.contako.com.br/Atendimento?cadastro=DC5ECABBB7" target="_blank">
                                <img
                                    alt="Chat BNDV"
                                    src={require('../../assets/images/Base-ArteChat.gif').toString()}
                                    style={{ height: '30px' }}
                                />
                            </a>
                        </li>
                        <li className="dropdown nav-profile">
                            <a className="dropdown-toggle" data-toggle="dropdown">
                                <span>{this.props.NomeEmpresa + ' - ' + this.props.NomeUsuario}</span>
                            </a>
                        </li>
                    </ul>
                </header>
            </section>
        );
    }
}

export default connect(
    state => state.auth,
    dispatch => bindActionCreators(actionCreators, dispatch),
    null,
    { pure: false }
)(NavHeader);