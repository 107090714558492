import * as React from "react";
import { DatePickerInput } from 'rc-datepicker';
import 'rc-datepicker/lib/style.css';
import 'moment/locale/pt-br.js'

export default class DateTimePicker extends React.Component {
    constructor(props) {
        super(props);
        this.state = { value: '', required: false };
    }

    componentDidMount() {
        if (this.props.value !== "") {
            this.setState({ value: this.props.value, required: this.props.required });
        }
    }

    onChangeDate = (jsDate, dateString) => {
        if (dateString === "Invalid date") {
            this.setState({ value: "" });
            this.props.onChange("");
        }
        else {
            this.setState({ value: dateString });
            this.props.onChange(dateString);
        }
    }

    render() {
        return (
            <div>
                <DatePickerInput
                    onChange={this.onChangeDate.bind(this)}
                    value={this.state.value}
                    className='my-custom-datepicker-component'
                    locale='pt-br'
                    displayFormat='DD/MM/YYYY'
                    returnFormat='YYYY-MM-DD'
                    required={this.state.required}
                />
            </div>
        );
    }
}