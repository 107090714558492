import * as React from 'react';
import { Alert, Button } from 'react-bootstrap';

export default class AlertInfo extends React.Component {
    constructor() {
        super();
        this.state = { alertVisible: false, errorText: '' };
    }

    componentWillMount() {
        this.setState({
            alertVisible: this.props.alertVisible,
            text: this.props.text,
            textHeader: this.props.textHeader
        });
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            alertVisible: nextProps.alertVisible,
            text: nextProps.text,
            textHeader: nextProps.textHeader
        });
    }

    handleAlertDismiss() {
        this.setState({
            alertVisible: false,
            text: '',
            textHeader: ''
        });
        this.props.onDismiss(false, '');
    }

    render() {
        return (this.state.alertVisible ?
            <Alert bsStyle="alert alert-warning">
                <h4><strong>{this.state.textHeader}</strong></h4><br />
                {this.state.text}
            </Alert>
            : null);
    }
}