import * as React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actionCreators } from '../../../store/crm/CrmConsulta';
import { FormattedNumber } from 'react-intl';
import Moment from 'react-moment';
import { Modal, Row } from 'react-bootstrap';
import AlertError from '../../commons/AlertError';
import ProgerssBar from '../../commons/ProgressBar';
import * as crmmercadolivre_service from '../../../services/crm/crmmercadolivre_service';

class ModalDetalhes extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isError: false,
            errorText: '',
            isLoading: true,
            data: null
        };
    }

    async componentDidMount() {
        if (this.state.isLoading) {
            var self = this;
            fetch(crmmercadolivre_service.services.obterMercadoLivreCreditoPreAprovado(this.props.auth.authorizationToken, this.props.id))
                .then(response => {
                    if (response.status === 200 || response.status === 0) { return response; }
                    else { return response.json().then(function (object) { return Promise.reject(new Error(object)); }) }
                })
                .then(data => {
                    data.json().then(function (object) {
                        self.setState({
                            ...self.state,
                            isLoading: false,
                            data: object
                        });
                    });
                }).catch(function (err) {
                    self.setState({
                        ...self.state,
                        isError: true,
                        errorText: err.toString(),
                        isLoading: false
                    });
                });
        }
    }

    handleOnHide() {
        this.props.closeModal();
    }

    render() {
        return (<Modal
            show={true}
            onHide={this.handleOnHide.bind(this)}
            bsSize="large">
            <form
                id="frm"
                name="frm"
                role="form"
                onSubmit={this.handleSubmit}
                data-parsley-validate>
                <Modal.Header closeButton>
                    <h4 className="modal-title custom-font">Detalhes do Crédito Pré-Aprovado</h4>
                </Modal.Header>
                <Modal.Body>
                    <AlertError
                        errorText={this.state.errorText}
                        alertVisible={this.state.isError}
                        onDismiss={() => {
                            this.setState({
                                ...this.state,
                                isError: false,
                                errorText: ''
                            });
                        }}
                    />
                    {
                        this.state.isLoading
                            ? <ProgerssBar />
                            : this.state.isError
                                ? null
                                : <div>
                                    <Row>
                                        <div className="col-md-2 text-right"><strong>Banco: </strong></div>
                                        <div className="col-md-4">{this.state.data.financial_entity_id}</div>
                                        <div className="col-md-2 text-right"><strong>Data do Crédito: </strong></div>
                                        <div className="col-md-4"><Moment format="DD/MM/YYYY hh:mm">{this.state.data.date_created}</Moment></div>
                                    </Row>
                                    <Row>
                                        <div className="col-md-2 text-right"><strong>Entrada: </strong></div>
                                        <div className="col-md-4"><FormattedNumber value={this.state.data.down_payment_amount} style="decimal" minimumFractionDigits={2} maximumFractionDigits={2} /></div>
                                        <div className="col-md-2 text-right"><strong>Qtde de Parcelas: </strong></div>
                                        <div className="col-md-4">{this.state.data.installments_number}</div>
                                    </Row>
                                    <Row>
                                        <div className="col-md-2 text-right"><strong>Valor da Parcela: </strong></div>
                                        <div className="col-md-10"><FormattedNumber value={this.state.data.installments_amount} style="decimal" minimumFractionDigits={2} maximumFractionDigits={2} /></div>
                                    </Row>
                                    <Row>
                                        <div className="col-md-2 text-right"><strong>Cliente: </strong></div>
                                        <div className="col-md-10">{this.state.data.buyer.full_name}</div>
                                    </Row>
                                    <Row>
                                        <div className="col-md-2 text-right"><strong>Telefone: </strong></div>
                                        <div className="col-md-4">{this.state.data.buyer.phone}</div>
                                        <div className="col-md-2 text-right"><strong>Email: </strong></div>
                                        <div className="col-md-4">{this.state.data.buyer.email}</div>
                                    </Row>
                                    <Row>
                                        <div className="col-md-2 text-right"><strong>Veículo: </strong></div>
                                        <div className="col-md-10">{this.state.data.vehicle.title}</div>
                                    </Row>
                                    <Row>
                                        <div className="col-md-2 text-right"><strong>Link do Anúncio: </strong></div>
                                        <div className="col-md-10"><a href={this.state.data.vehicle.permalink} target="_blank">{this.state.data.vehicle.permalink}</a></div>
                                    </Row>
                                </div>
                    }
                </Modal.Body>
            </form>
        </Modal>);
    }
}

function mapStateToProps(state) {
    return { auth: state.auth }
}

export default connect(
    mapStateToProps,
    dispatch => bindActionCreators(actionCreators, dispatch),
    null, {}
)(ModalDetalhes);
