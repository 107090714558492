import * as funcs from '../../utils/funcs';

//const _URL = 'http://localhost:61655/api/v1/crm/';
const _URL = 'https://apicrm.bndv.com.br/api/v1/crm/';

export const services = {
    listaLeads: (authorizationToken, pDTO) => {
        var query = funcs.encodeURI(pDTO);

        return new Request(_URL + 'listaWebMotorsLeadWeb',
            {
                method: 'POST',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
                    'Authorization': 'Bearer ' + authorizationToken
                },
                body: query
            });
    },
    aceitarLead: (authorizationToken, pId) => {
        return new Request(_URL + 'aceitarWebMotorsLead?id=' + pId.toString(),
            {
                method: 'POST',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
                    'Authorization': 'Bearer ' + authorizationToken
                }
            });
    },
    recusarLead: (authorizationToken, pId) => {
        return new Request(_URL + 'recusarWebMotorsLead?id=' + pId.toString(),
            {
                method: 'POST',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
                    'Authorization': 'Bearer ' + authorizationToken
                }
            });
    },
};