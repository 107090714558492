import * as React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actionCreators } from '../../../../../store/marketing/publicador/MarketingPublicadorConfig';

import Formsy from 'formsy-react';
import { Row, Modal } from 'react-bootstrap';
import ProgerssBar from '../../../../commons/ProgressBar';
import AlertError from '../../../../commons/AlertError';
import AlertSuccess from '../../../../commons/AlertSuccess';
import BtnSalvar from '../../../../commons/BtnSalvar';
import BtnCancelar from '../../../../commons/BtnCancelar';
import InputTextValidated from '../../../../commons/InputTextValidated';
import InputText from '../../../../commons/InputText';
import OauthPopup from 'react-oauth-popup';

class ModalFacebook extends React.Component {
    constructor() {
        super();
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    async componentDidMount() {
        if (this.props.configuracaoPortais.modalPortais.modalFacebook.showModal) {
            if (this.props.configuracaoPortais.modalPortais.ativado) {
                await this.props.fnBuscaConfiguracaoPortal();
                this.props.configuracaoPortais.modalPortais.modalFacebook.token = JSON.parse(this.props.configuracaoPortais.modalPortais.jsAutenticacao).token;
                if (this.props.configuracaoPortais.modalPortais.jsDadosEspeciais !== "")
                    this.props.configuracaoPortais.modalPortais.modalFacebook.id_pagina = JSON.parse(this.props.configuracaoPortais.modalPortais.jsDadosEspeciais).id_pagina;

                this.props.fnUpdateState(this.props);
            }
            else {
                this.props.configuracaoPortais.modalPortais.modalFacebook.token = '';
                this.props.configuracaoPortais.modalPortais.modalFacebook.id_pagina = '';
                this.props.fnUpdateState(this.props);
            }
        }
    }

    async handleSubmit(e) {
        var autenticacao = { token: this.props.configuracaoPortais.modalPortais.modalFacebook.token };
        var dadosEspeciais = { id_pagina: this.props.configuracaoPortais.modalPortais.modalFacebook.id_pagina };

        if (this.props.configuracaoPortais.modalPortais.ativado) {
            await this.props.fnAtualizaConfiguracaoPortal(JSON.stringify(autenticacao), JSON.stringify(dadosEspeciais));
            this.props.configuracaoPortais.modalPortais.modalAutoShoppingPontal.showModal = false;
        }
        else {
            await this.props.fnInsereConfiguracaoPortal(JSON.stringify(autenticacao), '');
            this.props.configuracaoPortais.modalPortais.modalAutoShoppingPontal.showModal = false;
        }
    }

    async gravarTokenAuth(token) {
        var autenticacao = { token: token };
        await this.props.fnInsereConfiguracaoPortal(JSON.stringify(autenticacao), '');
        this.props.configuracaoPortais.modalPortais.modalFacebook.showModal = false;
        this.props.fnUpdateState(this.props);
    }

    handleOnHide() {
        this.props.configuracaoPortais.modalPortais.modalFacebook.showModal = false;
        this.props.fnUpdateState(this.props);
    }

    render() {
        return <Modal
            show={this.props.configuracaoPortais.modalPortais.modalFacebook.showModal}
            onHide={this.handleOnHide.bind(this)}
        >
            <Formsy onValidSubmit={this.handleSubmit}>
                <Modal.Header closeButton>
                    <h4 className="modal-title custom-font">Configurar Facebook</h4>
                </Modal.Header>
                <Modal.Body>
                    <AlertSuccess
                        successText={this.props.configuracaoPortais.modalPortais.successText}
                        alertVisible={this.props.configuracaoPortais.modalPortais.successText != '' && this.props.configuracaoPortais.modalPortais.successText != undefined ? true : false}
                        onDismiss={(alertVisible, successText) => {
                            this.props.configuracaoPortais.modalPortais.successText = successText;
                            this.props.fnUpdateState(this.props);
                        }}
                    />
                    <AlertError
                        errorText={this.props.configuracaoPortais.modalPortais.errorText}
                        alertVisible={this.props.configuracaoPortais.modalPortais.errorText != '' && this.props.configuracaoPortais.modalPortais.errorText != undefined ? true : false}
                        onDismiss={(alertVisible, errorText) => {
                            this.props.configuracaoPortais.modalPortais.errorText = errorText;
                            this.props.fnUpdateState(this.props);
                        }}
                    />
                    {
                        this.props.configuracaoPortais.modalPortais.isLoading
                            ? <ProgerssBar />
                            : <Row>
                                <div>
                                    {this.props.configuracaoPortais.modalPortais.ativado
                                        ? <div className="form-group col-md-12">
                                            <InputTextValidated
                                                name="txtIdFacebook"
                                                type="text"
                                                className="col-md-12"
                                                placeholder="Informe o id da sua página no Facebook"
                                                onChange={(name, value) => {
                                                    this.props.configuracaoPortais.modalPortais.modalFacebook.id_pagina = value;
                                                    this.props.fnUpdateState(this.props);
                                                }}
                                                value={this.props.configuracaoPortais.modalPortais.modalFacebook.id_pagina}
                                                title="ID Página:"
                                                required
                                            />
                                            <InputText
                                                name="txtToken"
                                                type="text"
                                                className="col-md-12"
                                                placeholder="Informe o token"
                                                value={this.props.configuracaoPortais.modalPortais.modalFacebook.token}
                                                disabled={true}
                                                title="Token"
                                            />
                                        </div>
                                        : <div className="form-group col-md-12">
                                            <OauthPopup
                                                url="https://www.facebook.com/v3.0/dialog/oauth?client_id=669219643427953&redirect_uri=https://sistema2.bndv.com.br/&scope=pages_read_engagement,pages_show_list,pages_manage_posts,business_management"
                                                onCode={(code) => { this.gravarTokenAuth(code); }}
                                            >
                                                <button
                                                    type="button"
                                                    className="btn btn-primary btn-lg btn-block">
                                                    <i class="fa fa-facebook"></i>&nbsp;&nbsp;Autenticar no Facebook</button>
                                            </OauthPopup>
                                        </div>
                                    }
                                </div>
                            </Row>
                    }
                </Modal.Body>
                <Modal.Footer>
                    {this.props.configuracaoPortais.modalPortais.ativado == true
                        ? <div className="form-group col-md-12">
                            <BtnCancelar
                                type="button"
                                text=" Remover Portal"
                                textLoading=" Aguarde..."
                                isLoading={this.props.configuracaoPortais.modalPortais.isLoading}
                                onClick={async () => {
                                    await this.props.fnDeleteConfiguracaoPortal();
                                    this.props.configuracaoPortais.modalPortais.modalFacebook.showModal = false;
                                    this.props.fnUpdateState(this.props);
                                }}
                            />
                            <BtnSalvar
                                type="submit"
                                text={this.props.configuracaoPortais.modalPortais.ativado == false ? " Salvar Configuração" : " Alterar Configuração"}
                                textLoading=" Aguarde..."
                                isLoading={this.props.configuracaoPortais.modalPortais.isLoading}
                            />
                        </div>
                        : null}
                </Modal.Footer>
            </Formsy>
        </Modal>;
    }
}

export default connect(
    state => state.marketing_publicadorconfig,
    dispatch => bindActionCreators(actionCreators, dispatch),
    null, {}
)(ModalFacebook);