import * as React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actionCreators } from '../../../../../store/marketing/publicador/MarketingPublicadorConfig';

import Formsy from 'formsy-react';
import { Row, Modal } from 'react-bootstrap';
import ProgerssBar from '../../../../commons/ProgressBar';
import AlertError from '../../../../commons/AlertError';
import AlertSuccess from '../../../../commons/AlertSuccess';
import InputText from '../../../../commons/InputText';
import BtnSalvar from '../../../../commons/BtnSalvar';
import BtnCancelar from '../../../../commons/BtnCancelar';
import InputSelect from '../../../../commons/InputSelect';
import OauthPopup from 'react-oauth-popup';

class ModalMercadoLivre extends React.Component {
    constructor() {
        super();
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    async componentDidMount() {
        if (this.props.configuracaoPortais.modalPortais.modalMercadoLivre.showModal) {
            if (this.props.configuracaoPortais.modalPortais.ativado) {
                await this.props.fnBuscaConfiguracaoPortal();
                await this.props.fnListaUF();
                this.props.configuracaoPortais.modalPortais.modalMercadoLivre.token = JSON.parse(this.props.configuracaoPortais.modalPortais.jsAutenticacao).token;
                this.props.configuracaoPortais.modalPortais.modalMercadoLivre.idUf = JSON.parse(this.props.configuracaoPortais.modalPortais.jsDadosEspeciais).state;
                if (this.props.configuracaoPortais.modalPortais.modalMercadoLivre.idUf !== '')
                    await this.props.fnListaCidades(this.props.configuracaoPortais.modalPortais.modalMercadoLivre.idUf);
                this.props.configuracaoPortais.modalPortais.modalMercadoLivre.idCidade = JSON.parse(this.props.configuracaoPortais.modalPortais.jsDadosEspeciais).city;
                if (this.props.configuracaoPortais.modalPortais.modalMercadoLivre.idCidade !== '')
                    await this.props.fnListaBairros(this.props.configuracaoPortais.modalPortais.modalMercadoLivre.idCidade);
                this.props.configuracaoPortais.modalPortais.modalMercadoLivre.idBairro = JSON.parse(this.props.configuracaoPortais.modalPortais.jsDadosEspeciais).neighborhood;
                this.props.fnUpdateState(this.props);
            }
            else {
                await this.props.fnListaUF();
                this.props.configuracaoPortais.modalPortais.modalMercadoLivre.token = '';
                this.props.configuracaoPortais.modalPortais.modalMercadoLivre.idUf = '';
                this.props.configuracaoPortais.modalPortais.modalMercadoLivre.idCidade = '';
                this.props.configuracaoPortais.modalPortais.modalMercadoLivre.idBairro = '';
                this.props.fnUpdateState(this.props);
            }
        }
    }

    async handleSubmit(e) {
        if (this.props.configuracaoPortais.modalPortais.modalMercadoLivre.token === "") {
            this.props.configuracaoPortais.modalPortais.errorText = "Autorize o aplicativo para receber um token";
            this.props.fnUpdateState(this.props);
            return;
        }
        if (this.props.configuracaoPortais.modalPortais.modalMercadoLivre.idUf === "") {
            this.props.configuracaoPortais.modalPortais.errorText = "Informe o estado";
            this.props.fnUpdateState(this.props);
            return;
        }
        if (this.props.configuracaoPortais.modalPortais.modalMercadoLivre.idCidade === "") {
            this.props.configuracaoPortais.modalPortais.errorText = "Informe a cidade";
            this.props.fnUpdateState(this.props);
            return;
        }
        //if (this.props.configuracaoPortais.modalPortais.modalMercadoLivre.idBairro === "") {
        //    this.props.configuracaoPortais.modalPortais.errorText = "Informe o bairro";
        //    this.props.fnUpdateState(this.props);
        //    return;
        //}


        var autenticacao = { token: this.props.configuracaoPortais.modalPortais.modalMercadoLivre.token };
        var dadosespeciais = {
            idUf: this.props.configuracaoPortais.modalPortais.modalMercadoLivre.idUf,
            idCidade: this.props.configuracaoPortais.modalPortais.modalMercadoLivre.idCidade,
            idBairro: this.props.configuracaoPortais.modalPortais.modalMercadoLivre.idBairro,
        };

        await this.props.fnInsereConfiguracaoPortal(JSON.stringify(autenticacao), JSON.stringify(dadosespeciais));
        this.props.configuracaoPortais.modalPortais.modalMercadoLivre.showModal = false;
        this.props.fnUpdateState(this.props);
    }

    handleOnHide() {
        this.props.configuracaoPortais.modalPortais.modalMercadoLivre.showModal = false;
        this.props.fnUpdateState(this.props);
    }

    render() {
        var objProps = this.props;

        var dropdownUFs = [];
        dropdownUFs.push({ value: '', text: 'Selecione a UF' });
        objProps.configuracaoPortais.modalPortais.comboUF.results.forEach(function (entry) {
            dropdownUFs.push({ value: entry.id, text: entry.nome });
        });

        var dropdownCidades = [];
        dropdownCidades.push({ value: '', text: 'Selecione a Cidade' });
        objProps.configuracaoPortais.modalPortais.comboCidade.results.forEach(function (entry) {
            dropdownCidades.push({ value: entry.id, text: entry.nome });
        });

        var dropdownBairros = [];
        dropdownBairros.push({ value: '', text: 'Selecione o Bairro' });
        objProps.configuracaoPortais.modalPortais.comboBairro.results.forEach(function (entry) {
            dropdownBairros.push({ value: entry.id, text: entry.nome });
        });

        return <Modal
            show={this.props.configuracaoPortais.modalPortais.modalMercadoLivre.showModal}
            onHide={this.handleOnHide.bind(this)}
        >
            <Formsy onValidSubmit={this.handleSubmit}>
                <Modal.Header closeButton>
                    <h4 className="modal-title custom-font">Configurar Mercado Livre</h4>
                </Modal.Header>
                <Modal.Body>
                    <AlertSuccess
                        successText={this.props.configuracaoPortais.modalPortais.successText}
                        alertVisible={this.props.configuracaoPortais.modalPortais.successText != '' && this.props.configuracaoPortais.modalPortais.successText != undefined ? true : false}
                        onDismiss={(alertVisible, successText) => {
                            this.props.configuracaoPortais.modalPortais.successText = successText;
                            this.props.fnUpdateState(this.props);
                        }}
                    />
                    <AlertError
                        errorText={this.props.configuracaoPortais.modalPortais.errorText}
                        alertVisible={this.props.configuracaoPortais.modalPortais.errorText != '' && this.props.configuracaoPortais.modalPortais.errorText != undefined ? true : false}
                        onDismiss={(alertVisible, errorText) => {
                            this.props.configuracaoPortais.modalPortais.errorText = errorText;
                            this.props.fnUpdateState(this.props);
                        }}
                    />
                    {
                        this.props.configuracaoPortais.modalPortais.isLoading
                            ? <ProgerssBar />
                            : <Row>
                                <div className="form-group col-md-12">
                                    <label>Estado:</label>
                                    <InputSelect
                                        class="form-control mt-4"
                                        data={(this.props.configuracaoPortais.modalPortais.comboUF.isLoading
                                            ? [{ value: '', text: 'Aguarde carregando...' }]
                                            : dropdownUFs)}
                                        onChange={
                                            (name, value) => {
                                                this.props.configuracaoPortais.modalPortais.modalMercadoLivre.idUf = value;
                                                this.props.fnUpdateState(this.props);
                                                this.props.fnListaCidades(value);
                                            }
                                        }
                                        selectedValue={
                                            (!this.props.configuracaoPortais.modalPortais.modalMercadoLivre.idUf)
                                                ? null
                                                : this.props.configuracaoPortais.modalPortais.modalMercadoLivre.idUf.toString()
                                        }
                                        disabled={(this.props.configuracaoPortais.modalPortais.comboUF.isLoading ? true : false)}
                                    />
                                </div>
                                <div className="form-group col-md-12">
                                    <label>Cidade:</label>
                                    <InputSelect
                                        class="form-control mt-4"
                                        data={(this.props.configuracaoPortais.modalPortais.comboCidade.isLoading
                                            ? [{ value: '', text: 'Aguarde carregando...' }]
                                            : dropdownCidades)}
                                        onChange={
                                            (name, value) => {
                                                this.props.configuracaoPortais.modalPortais.modalMercadoLivre.idCidade = value;
                                                this.props.fnUpdateState(this.props);
                                                this.props.fnListaBairros(value);
                                            }
                                        }
                                        selectedValue={
                                            (this.props.configuracaoPortais.modalPortais.modalMercadoLivre.idCidade == null)
                                                ? null
                                                : this.props.configuracaoPortais.modalPortais.modalMercadoLivre.idCidade.toString()
                                        }
                                        disabled={(this.props.configuracaoPortais.modalPortais.comboCidade.isLoading ? true : false)}
                                    />
                                </div>
                                <div className="form-group col-md-12">
                                    <label>Bairro:</label>
                                    <InputSelect
                                        class="form-control mt-4"
                                        data={(this.props.configuracaoPortais.modalPortais.comboBairro.isLoading
                                            ? [{ value: '', text: 'Aguarde carregando...' }]
                                            : dropdownBairros)}
                                        onChange={
                                            (name, value) => {
                                                this.props.configuracaoPortais.modalPortais.modalMercadoLivre.idBairro = value;
                                                this.props.fnUpdateState(this.props);
                                            }
                                        }
                                        selectedValue={
                                            (this.props.configuracaoPortais.modalPortais.modalMercadoLivre.idBairro == null)
                                                ? null
                                                : this.props.configuracaoPortais.modalPortais.modalMercadoLivre.idBairro.toString()
                                        }
                                        disabled={(this.props.configuracaoPortais.modalPortais.comboBairro.isLoading ? true : false)}
                                    />
                                </div>
                                <InputText
                                    name="txtToken"
                                    type="text"
                                    className="col-md-12"
                                    placeholder="Informe o token"
                                    value={this.props.configuracaoPortais.modalPortais.modalMercadoLivre.token}
                                    onChange={(name, value) => {
                                        this.props.configuracaoPortais.modalPortais.modalMercadoLivre.token = value;
                                        this.props.fnUpdateState(this.props);
                                    }}
                                    title="Token"
                                />
                                {this.props.configuracaoPortais.modalPortais.ativado
                                    ? null
                                    : <div className="form-group col-md-12">
                                        <OauthPopup
                                            url="https://auth.mercadolivre.com.br/authorization?response_type=code&client_id=15960718270120"
                                            onCode={(code) => {
                                                this.props.configuracaoPortais.modalPortais.modalMercadoLivre.token = code;
                                                this.props.fnUpdateState(this.props);
                                            }}
                                        >
                                            <button
                                                type="button"
                                                className="btn btn-primary btn-lg btn-block">Autorizar Aplicativo do BNDV para publicar no Mercado Livre</button>
                                        </OauthPopup>
                                    </div>
                                }
                            </Row>
                    }
                </Modal.Body>
                <Modal.Footer>
                    {this.props.configuracaoPortais.modalPortais.ativado == true
                        ? <BtnCancelar
                            type="button"
                            text=" Remover Portal"
                            textLoading=" Aguarde..."
                            isLoading={this.props.configuracaoPortais.modalPortais.isLoading}
                            onClick={async () => {
                                await this.props.fnDeleteConfiguracaoPortal();
                                this.props.configuracaoPortais.modalPortais.modalMercadoLivre.showModal = false;
                                this.props.fnUpdateState(this.props);
                            }}
                        />
                        : <BtnSalvar
                            type="submit"
                            text={this.props.configuracaoPortais.modalPortais.ativado == false ? " Salvar Configuração" : " Alterar Configuração"}
                            textLoading=" Aguarde..."
                            isLoading={this.props.configuracaoPortais.modalPortais.isLoading}
                        />
                    }
                </Modal.Footer>
            </Formsy>
        </Modal>;
    }
}

export default connect(
    state => state.marketing_publicadorconfig,
    dispatch => bindActionCreators(actionCreators, dispatch),
    null, {}
)(ModalMercadoLivre);