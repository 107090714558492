import * as React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actionCreators } from '../../../store/crm/CrmConfiguracao';

import Formsy from 'formsy-react';
import { Row, Tooltip, OverlayTrigger } from 'react-bootstrap';
import Section from '../../commons/Section';
import ProgerssBar from '../../commons/ProgressBar';
import BtnSalvar from '../../commons/BtnSalvar';
import InputSelectValidated from '../../commons/InputSelectValidated';
import AlertError from '../../commons/AlertError';
import SwitchOnOff from '../../commons/SwitchOnOff';
import ModalWebMotors from './modalportais/ModalWebMotors';
import ModalICarros from './modalportais/ModalICarros';
import ModalAutoLine from './modalportais/ModalAutoLine';
import ModalMeuCarroNovo from './modalportais/ModalMeuCarroNovo';
import ModalSiteCliente from './modalportais/ModalSiteCliente';
import ModalOLX from './modalportais/ModalOLX';
import ModalMercadoLivre from './modalportais/ModalMercadoLivre';

class ConfiguracaoForm extends React.Component {
    constructor() {
        super();
        this.handleSubmitTipoDistribuicao = this.handleSubmitTipoDistribuicao.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.initialize = this.initialize.bind(this);
    }

    async componentDidMount() {
        await this.props.fnBuscaEmpresa();
        this.initialize();
    }

    async initialize() {
        if (this.props.empresa.id !== 0) {
            this.props.fnListaUsuariosNaoAdicionados();
            this.props.fnListaUsuariosAdicionados();
            this.props.fnObterTipoDistribuicao();
            this.props.fnListaPortaisAtivos();
        }
    }

    handleSubmitTipoDistribuicao() {
        this.props.fnAlterarTipoDistribuicao();
    }

    handleSubmit() {
        this.props.fnAdicionaUsuarioDistribuicao();
    }

    verificaPortalAtivo(idPortal) {
        var result = false;
        this.props.configuracaoPortais.portaisAtivos.results.forEach(function (entry) {
            if (idPortal === entry.id)
                result = true;
        });
        return result;
        return result;
    }

    render() {
        var objProps = this.props;

        var dropdownUsuariosNaoAdicionados = [];
        dropdownUsuariosNaoAdicionados.push({ value: '', text: 'Selecione...' });
        objProps.tipoDistribuicaoUsuario.listaUsuariosNaoAdicionados.results.forEach(function (entry) {
            dropdownUsuariosNaoAdicionados.push({ value: entry.id, text: entry.nome });
        });

        return <div>
            {
                this.props.empresa.isLoading
                    ? <ProgerssBar />
                    : this.props.empresa.id === 0
                        ? <Row>
                            <AlertError
                                errorText={this.props.empresa.errorText}
                                alertVisible={this.props.empresa.isError}
                                onDismiss={(alertVisible, errorText) => {
                                    this.props.empresa.errorText = '';
                                    this.props.empresa.isError = false;
                                    this.props.fnUpdateState(this.props);
                                }}
                            />
                            <div className="form-group col-md-12">
                                <h4>Percebemos que você ainda não configurou sua empresa para utilizar o CRM, clique no botão abaixo para iniciar a configuração</h4>
                                <BtnSalvar
                                    type="button"
                                    text={" Iniciar Configuração"}
                                    textLoading=" Aguarde inserindo dados da empresa"
                                    isLoading={this.props.empresa.isLoading}
                                    onClick={async () => {
                                        await this.props.fnInserirEmpresa();
                                        this.initialize();
                                    }}
                                />
                            </div>
                        </Row>
                        : <div>
                            <Row>
                                <div className="col-md-12">
                                    <Section>
                                        <div className="tile-header bg-blue dvd dvd-btm">
                                            <h3 className="custom-font"><strong>Configuração dos Usuários que Receberão as Oportunidades</strong></h3>
                                        </div>
                                        <div className="tile-body nopadding">
                                            <AlertError
                                                errorText={this.props.tipoDistribuicaoUsuario.errorText}
                                                alertVisible={this.props.tipoDistribuicaoUsuario.errorText != '' && this.props.tipoDistribuicaoUsuario.errorText != undefined ? true : false}
                                                onDismiss={(alertVisible, errorText) => {
                                                    this.props.tipoDistribuicaoUsuario.errorText = errorText;
                                                    this.props.fnUpdateState(this.props);
                                                }}
                                            />
                                            <Row>
                                                <div className="form-group col-md-12">
                                                    <Formsy onValidSubmit={this.handleSubmitTipoDistribuicao}>
                                                        <Row>
                                                            <InputSelectValidated
                                                                name="ddlTipoDistribuicao"
                                                                className="col-md-5"
                                                                data={[{ value: '1', text: 'Sequencial' }, { value: '2', text: 'Manual' }]}
                                                                onChange={(name, value) => {
                                                                    this.props.tipoDistribuicaoUsuario.idTipoDistribuicao = value;
                                                                    this.props.fnUpdateState(this.props);
                                                                }}
                                                                selectedValue={this.props.tipoDistribuicaoUsuario.idTipoDistribuicao.toString()}
                                                                title="Tipo de distribuição:"
                                                                disabled={false}
                                                                required={false}
                                                            />
                                                            <div className="form-group col-md-7" style={{ marginTop: '23px' }}>
                                                                <BtnSalvar
                                                                    type="submit"
                                                                    isLoading={this.props.tipoDistribuicaoUsuario.isLoading}
                                                                    text=""
                                                                    textLoading=" ..."
                                                                />
                                                            </div>
                                                        </Row>
                                                    </Formsy>
                                                </div>

                                                <div className="form-group col-md-6">
                                                    <Formsy onValidSubmit={this.handleSubmit}>
                                                        <Row>
                                                            <InputSelectValidated
                                                                name="ddlUsuarioNaoAdicionado"
                                                                className="col-md-10"
                                                                data={(this.props.tipoDistribuicaoUsuario.listaUsuariosNaoAdicionados.isLoading
                                                                    ? [{ value: '', text: 'Aguarde carregando...' }]
                                                                    : dropdownUsuariosNaoAdicionados)}
                                                                onChange={
                                                                    (name, value) => {
                                                                        this.props.tipoDistribuicaoUsuario.listaUsuariosNaoAdicionados.idUsuario = value;
                                                                        this.props.fnUpdateState(this.props);
                                                                    }
                                                                }
                                                                selectedValue={this.props.tipoDistribuicaoUsuario.listaUsuariosNaoAdicionados.idUsuario.toString()}
                                                                title="Usuário:"
                                                                disabled={this.props.tipoDistribuicaoUsuario.listaUsuariosNaoAdicionados.isLoading ? true : false}
                                                                required
                                                            />
                                                            <div className="form-group col-md-2" style={{ marginTop: '23px' }}>
                                                                <BtnSalvar
                                                                    type="submit"
                                                                    isLoading={this.props.tipoDistribuicaoUsuario.isLoading}
                                                                    text=""
                                                                    textLoading=" ..."
                                                                />
                                                            </div>
                                                        </Row>
                                                    </Formsy>
                                                </div>
                                                <div className="form-group col-md-6">
                                                    <div className="table-responsive">
                                                        <div className="dataTables_wrapper form-inline dt-bootstrap no-footer">
                                                            <Row>
                                                                <div className="col-sm-12">
                                                                    <table className="table table-custom dataTable no-footer">
                                                                        <thead>
                                                                            <tr role="row">
                                                                                <th></th>
                                                                                <th>Usuários adicionados</th>
                                                                                <th>Recebe Lead Web?</th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            {
                                                                                (this.props.tipoDistribuicaoUsuario.listaUsuariosAdicionados.isLoading)
                                                                                    ? <tr key={0} className="odd">
                                                                                        <td colSpan={7} className="dataTables_empty">
                                                                                            <div className="progress progress-striped active">
                                                                                                <div className="progress-bar progress-bar-blue" role="progressbar" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100" style={{ width: "100%" }}>
                                                                                                    <span className="sr-only">100% Complete</span>
                                                                                                </div>
                                                                                            </div>
                                                                                        </td>
                                                                                    </tr>
                                                                                    : (this.props.tipoDistribuicaoUsuario.listaUsuariosAdicionados.results.length == 0)
                                                                                        ? <tr key={0} className="odd">
                                                                                            <td colSpan={8} className="dataTables_empty">
                                                                                                <p className="text-warning">Nenhum usuário adicionado...</p>
                                                                                            </td>
                                                                                        </tr>
                                                                                        : this.props.tipoDistribuicaoUsuario.listaUsuariosAdicionados.results.map(function (rowData, index) {
                                                                                            return (
                                                                                                <tr key={index}>
                                                                                                    <td>
                                                                                                        <a
                                                                                                            role="button"
                                                                                                            tabIndex={0}
                                                                                                            className="delete text-danger text-uppercase text-strong text-sm mr-10"
                                                                                                            onClick={() => { objProps.fnRemoverUsuarioDistribuicao(rowData.id); }}><i className="fa fa-trash"></i></a>
                                                                                                    </td>
                                                                                                    <td>{rowData.nome}</td>
                                                                                                    <td>
                                                                                                        <SwitchOnOff
                                                                                                            class="onoffswitch-checkbox"
                                                                                                            id={"chkRecebeLeadWeb" + rowData.id}
                                                                                                            onChange={(name, value) => {
                                                                                                                objProps.fnAlterarRecebeLeadWebUsuario(rowData.id, (value == 'on' ? false : true));
                                                                                                            }}
                                                                                                            value={rowData.recebeLeadWeb == true ? 'on' : 'off'}
                                                                                                        />
                                                                                                    </td>
                                                                                                </tr>
                                                                                            )
                                                                                        })
                                                                            }
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </Row>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Row>
                                        </div>
                                    </Section>
                                </div>
                            </Row>
                            {
                                this.props.configuracaoPortais.portaisAtivos.isLoading
                                    ? <ProgerssBar />
                                    : <Row>
                                        <div className="col-md-12">
                                            <Section>
                                                <div className="tile-header bg-blue dvd dvd-btm">
                                                    <h3 className="custom-font"><strong>Configuração dos portais para o CRM</strong></h3>
                                                </div>
                                                <div className="tile-body nopadding">
                                                    <AlertError
                                                        errorText={this.props.configuracaoPortais.portaisAtivos.errorText}
                                                        alertVisible={this.props.configuracaoPortais.portaisAtivos.errorText != '' && this.props.configuracaoPortais.portaisAtivos.errorText != undefined ? true : false}
                                                        onDismiss={(alertVisible, errorText) => {
                                                            this.props.configuracaoPortais.portaisAtivos.errorText = errorText;
                                                            this.props.fnUpdateState(this.props);
                                                        }}
                                                    />
                                                    <Row>
                                                        <div className="form-group col-md-12">
                                                            <div className="table-responsive">
                                                                <table className="table table-custom cabra-table" id="basic-usage">
                                                                    <tbody>
                                                                        <tr>
                                                                            <td
                                                                                style={{ textAlign: 'center' }}
                                                                                key={5}>
                                                                                <OverlayTrigger
                                                                                    placement="top"
                                                                                    overlay={<Tooltip id="tooltip"><strong>{"WebMotors"}</strong></Tooltip>}>
                                                                                    <a onClick={() => {
                                                                                        this.props.configuracaoPortais.modalPortais.modalWebMotors.showModal = true;
                                                                                        this.props.configuracaoPortais.modalPortais.idCanalLead = 5;
                                                                                        this.props.fnUpdateState(this.props);
                                                                                    }}>
                                                                                        <img
                                                                                            src={
                                                                                                this.verificaPortalAtivo(5) === false
                                                                                                    ? require('../../../assets/images/icones_portais/webmotors-alpha.png').toString()
                                                                                                    : require('../../../assets/images/icones_portais/webmotors.png').toString()
                                                                                            }
                                                                                        />
                                                                                    </a>
                                                                                </OverlayTrigger>
                                                                            </td>
                                                                            <td
                                                                                style={{ textAlign: 'center' }}
                                                                                key={6}>
                                                                                <OverlayTrigger
                                                                                    placement="top"
                                                                                    overlay={<Tooltip id="tooltip"><strong>{"ICarros"}</strong></Tooltip>}>
                                                                                    <a onClick={() => {
                                                                                        this.props.configuracaoPortais.modalPortais.modalICarros.showModal = true;
                                                                                        this.props.configuracaoPortais.modalPortais.idCanalLead = 6;
                                                                                        this.props.fnUpdateState(this.props);
                                                                                    }}>
                                                                                        <img
                                                                                            src={
                                                                                                this.verificaPortalAtivo(6) === false
                                                                                                    ? require('../../../assets/images/icones_portais/icarros-alpha.png').toString()
                                                                                                    : require('../../../assets/images/icones_portais/icarros.png').toString()
                                                                                            }
                                                                                        />
                                                                                    </a>
                                                                                </OverlayTrigger>
                                                                            </td>
                                                                            <td
                                                                                style={{ textAlign: 'center' }}
                                                                                key={7}>
                                                                                <OverlayTrigger
                                                                                    placement="top"
                                                                                    overlay={<Tooltip id="tooltip"><strong>{"OLX"}</strong></Tooltip>}>
                                                                                    <a onClick={() => {
                                                                                        this.props.configuracaoPortais.modalPortais.modalOLX.showModal = true;
                                                                                        this.props.configuracaoPortais.modalPortais.idCanalLead = 7;
                                                                                        this.props.fnUpdateState(this.props);
                                                                                    }}>
                                                                                        <img
                                                                                            src={
                                                                                                this.verificaPortalAtivo(7) === false
                                                                                                    ? require('../../../assets/images/icones_portais/olx-alpha.png').toString()
                                                                                                    : require('../../../assets/images/icones_portais/olx.png').toString()
                                                                                            }
                                                                                        />
                                                                                    </a>
                                                                                </OverlayTrigger>
                                                                            </td>
                                                                            <td
                                                                                style={{ textAlign: 'center' }}
                                                                                key={8}>
                                                                                <OverlayTrigger
                                                                                    placement="top"
                                                                                    overlay={<Tooltip id="tooltip"><strong>{"Mercado Livre"}</strong></Tooltip>}>
                                                                                    <a onClick={() => {
                                                                                        this.props.configuracaoPortais.modalPortais.modalMercadoLivre.showModal = true;
                                                                                        this.props.configuracaoPortais.modalPortais.idCanalLead = 8;
                                                                                        this.props.fnUpdateState(this.props);
                                                                                    }}>
                                                                                        <img
                                                                                            src={
                                                                                                this.verificaPortalAtivo(8) === false
                                                                                                    ? require('../../../assets/images/icones_portais/mercadolivre-alpha.png').toString()
                                                                                                    : require('../../../assets/images/icones_portais/mercadolivre.png').toString()
                                                                                            }
                                                                                        />
                                                                                    </a>
                                                                                </OverlayTrigger>
                                                                            </td>
                                                                            <td
                                                                                style={{ textAlign: 'center' }}
                                                                                key={9}>
                                                                                <OverlayTrigger
                                                                                    placement="top"
                                                                                    overlay={<Tooltip id="tooltip"><strong>{"Auto Line"}</strong></Tooltip>}>
                                                                                    <a onClick={() => {
                                                                                        this.props.configuracaoPortais.modalPortais.modalAutoLine.showModal = true;
                                                                                        this.props.configuracaoPortais.modalPortais.idCanalLead = 9;
                                                                                        this.props.fnUpdateState(this.props);
                                                                                    }}>
                                                                                        <img
                                                                                            src={
                                                                                                this.verificaPortalAtivo(9) === false
                                                                                                    ? require('../../../assets/images/icones_portais/autoline-alpha.png').toString()
                                                                                                    : require('../../../assets/images/icones_portais/autoline.png').toString()
                                                                                            }
                                                                                        />
                                                                                    </a>
                                                                                </OverlayTrigger>
                                                                            </td>
                                                                            <td
                                                                                style={{ textAlign: 'center' }}
                                                                                key={11}>
                                                                                <OverlayTrigger
                                                                                    placement="top"
                                                                                    overlay={<Tooltip id="tooltip"><strong>{"Na Pista"}</strong></Tooltip>}>
                                                                                    <a onClick={() => {
                                                                                        this.props.configuracaoPortais.modalPortais.modalMeuCarroNovo.showModal = true;
                                                                                        this.props.configuracaoPortais.modalPortais.idCanalLead = 11;
                                                                                        this.props.fnUpdateState(this.props);
                                                                                    }}>
                                                                                        <img
                                                                                            src={
                                                                                                this.verificaPortalAtivo(11) === false
                                                                                                    ? require('../../../assets/images/icones_portais/napista-alpha.png').toString()
                                                                                                    : require('../../../assets/images/icones_portais/napista.png').toString()
                                                                                            }
                                                                                        />
                                                                                    </a>
                                                                                </OverlayTrigger>
                                                                            </td>
                                                                            <td
                                                                                style={{ textAlign: 'center' }}
                                                                                key={10}>
                                                                                <OverlayTrigger
                                                                                    placement="top"
                                                                                    overlay={<Tooltip id="tooltip"><strong>{"Web Site Loja"}</strong></Tooltip>}>
                                                                                    <a onClick={() => {
                                                                                        this.props.configuracaoPortais.modalPortais.modalSiteCliente.showModal = true;
                                                                                        this.props.configuracaoPortais.modalPortais.idCanalLead = 10;
                                                                                        this.props.fnUpdateState(this.props);
                                                                                    }}>
                                                                                        <img
                                                                                            src={
                                                                                                this.verificaPortalAtivo(10) === false
                                                                                                    ? require('../../../assets/images/icones_portais/bndvSites-alpha.png').toString()
                                                                                                    : require('../../../assets/images/icones_portais/bndvSites.png').toString()
                                                                                            }
                                                                                        />
                                                                                    </a>
                                                                                </OverlayTrigger>
                                                                            </td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </Row>
                                                </div>
                                            </Section>
                                        </div>
                                    </Row>
                            }
                        </div>
            }
            {this.props.configuracaoPortais.modalPortais.modalWebMotors.showModal ? <ModalWebMotors /> : null}
            {this.props.configuracaoPortais.modalPortais.modalICarros.showModal ? <ModalICarros /> : null}
            {this.props.configuracaoPortais.modalPortais.modalAutoLine.showModal ? <ModalAutoLine /> : null}
            {this.props.configuracaoPortais.modalPortais.modalMeuCarroNovo.showModal ? <ModalMeuCarroNovo /> : null}
            {this.props.configuracaoPortais.modalPortais.modalSiteCliente.showModal ? <ModalSiteCliente /> : null}
            {this.props.configuracaoPortais.modalPortais.modalOLX.showModal ? <ModalOLX /> : null}
            {this.props.configuracaoPortais.modalPortais.modalMercadoLivre.showModal ? <ModalMercadoLivre /> : null}
        </div>;
    }
}

export default connect(
    state => state.crm_configuracao,
    dispatch => bindActionCreators(actionCreators, dispatch),
    null, { pure: true }
)(ConfiguracaoForm);