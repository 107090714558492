import * as React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actionCreators } from '../../store/marketing/publicador/MarketingPublicadorConfig';

import HeaderPage from '../../components/commons/HeaderPage';
import AnuncianteForm from '../../components/marketing/publicador/configuracoes/AnuncianteForm';
import ConfiguracaoPortaisForm from '../../components/marketing/publicador/configuracoes/ConfiguracaoPortaisForm';
import ConfiguracaoAdicionaisForm from '../../components/marketing/publicador/configuracoes/ConfiguracoesAdicionaisForm';

class PublicadorConfig_Container extends React.Component {
    render() {
        return <div className='page'>
            <HeaderPage
                path=' Marketing'
                name='Publicador'
                subName='Configurações' />
            <AnuncianteForm />
            {this.props.dadosAnunciante.token != "" && this.props.dadosAnunciante.token != null
                ? <ConfiguracaoPortaisForm />
                : null
            }
            <ConfiguracaoAdicionaisForm />
        </div>;
    }
}

export default connect(
    state => state.marketing_publicadorconfig,
    dispatch => bindActionCreators(actionCreators, dispatch),
    null,
    { pure: false }
)(PublicadorConfig_Container);
