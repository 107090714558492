import * as React from 'react';

import HeaderPage from '../../components/commons/HeaderPage';
import ConfiguracaoForm from '../../components/edoc/configuracao/ConfiguracaoForm';

export default class Edoc_Configuracao extends React.Component {
    render() {
        return <div className='page'>
            <HeaderPage
                path=' E-Doc'
                name='Configurações' />
            <ConfiguracaoForm />
        </div>;
    }
}