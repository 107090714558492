import * as React from 'react';
import { fetch, addTask } from 'domain-task';
import * as publicador_service from '../../../../services/marketing/publicador/publicador_service';

import ModalFotosVeiculo from './ModalFotosVeiculo';

export default class ListaPublicadorListRowFoto extends React.Component {
    constructor() {
        super();
        this.state = {
            image: '',
            error: '',
            showModalFotos: false
        };
    }

    componentDidMount() {
        let fetchTask = fetch(publicador_service.services.obterFotoVeiculo(this.props.idVeiculo, 1, this.props.token))
            .then(response => {
                if (response.status === 200 || response.status === 0) {
                    return response;
                }
                else {
                    return response.json().then(function (object) {
                        return Promise.reject(new Error(object));
                    })
                }
            })
            .then(data => {
                var state = this;
                data.json().then(function (object) {
                    state.setState({
                        image: object,
                        error: '',
                        showModalFotos: false
                    });
                });
            }).catch(function (err) {
                this.setState({
                    image: '',
                    error: err.toString(),
                    showModalFotos: false
                });
            });

        addTask(fetchTask);
    }

    _onClick() {
        this.setState({
            image: this.state.image,
            error: this.state.error,
            showModalFotos: true
        });
    }

    render() {
        return (
            <div>
                {
                    this.state.image != null
                        ? <img
                            className={this.props.class}
                            alt={this.props.alt}
                            src={
                                this.state.image == null
                                    ? ""
                                    : this.state.image
                            }
                            onClick={this._onClick.bind(this)}
                            style={{ cursor: 'pointer' }}
                        />
                        : <div style={{
                            textAlign: 'center',
                            width: '100%',
                            height: '100%'
                        }}
                            className="alert alert-primary img-responsive">
                            <i className="fa fa-car fa-3x"></i>
                            <h5>Veículo sem foto</h5>
                        </div>
                }
                {
                    this.state.showModalFotos
                        ? <ModalFotosVeiculo
                            showModal={this.state.showModalFotos}
                            idVeiculo={this.props.idVeiculo}
                            token={this.props.token}
                            onHide={(value) => {
                                this.setState({
                                    image: this.state.image,
                                    error: this.state.error,
                                    showModalFotos: value
                                });
                            }}
                        />
                        : null
                }
            </div>
        );
    }
}