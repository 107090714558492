import * as React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actionCreators } from '../../../../../store/marketing/publicador/MarketingPublicador';

import Formsy from 'formsy-react';
import { Row, Modal } from 'react-bootstrap';
import ProgerssBar from '../../../../commons/ProgressBar';
import AlertError from '../../../../commons/AlertError';
import AlertSuccess from '../../../../commons/AlertSuccess';
import InputSelectValidated from '../../../../commons/InputSelectValidated';
import CurrencyInput from 'react-currency-input';
import BtnSalvar from '../../../../commons/BtnSalvar';
import BtnCancelar from '../../../../commons/BtnCancelar';
import TextAreaValidated from '../../../../commons/TextAreaValidated';

class ModalAutoLine extends React.Component {
    constructor() {
        super();
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    async componentDidMount() {
        this.props.PublicarVeiculo.dadosPortal.versoes.results = [];
        this.props.PublicarVeiculo.dadosPortal.planos.results = [];
        this.props.PublicarVeiculo.errorText = "";
        this.props.PublicarVeiculo.successText = "";
        this.props.PublicarVeiculo.anuncio.anuncioEmDestaque = false;
        this.props.PublicarVeiculo.anuncio.idAnosModelo = "";
        this.props.PublicarVeiculo.anuncio.idAnuncio = "";
        this.props.PublicarVeiculo.anuncio.idAnuncioCliente = "";
        //this.props.PublicarVeiculo.anuncio.idAnuncioBNDV = 0;
        this.props.PublicarVeiculo.anuncio.idCategoria = 1;
        this.props.PublicarVeiculo.anuncio.idCidade = "";
        this.props.PublicarVeiculo.anuncio.idPublicador = 0;
        this.props.PublicarVeiculo.anuncio.idTipoPlano = "";
        this.props.PublicarVeiculo.anuncio.idTipoAdicional = "";
        this.props.PublicarVeiculo.anuncio.idTipoVeiculo = "";
        this.props.PublicarVeiculo.anuncio.idVeiculo = 0;
        this.props.PublicarVeiculo.anuncio.idVersao = "";
        this.props.PublicarVeiculo.anuncio.preco = 0;
        this.props.PublicarVeiculo.anuncio.precoDe = 0;
        this.props.PublicarVeiculo.anuncio.texto = "";
        this.props.PublicarVeiculo.anuncio.titulo = "";
        await this.props.fnUpdateState(this.props);
        if (this.props.ModalAutoLine.showModal) {
            if (this.props.PublicarVeiculo.anuncio.idAnuncioBNDV != null && this.props.PublicarVeiculo.anuncio.idAnuncioBNDV != 0) {
                await this.props.fnBuscarVeiculo();
                await this.props.fnBuscarAnuncio();
                this.props.fnListaAnosModelos();
                this.props.fnListaCategorias();
                this.props.fnListaPlanos();
            }
            else {
                await this.props.fnBuscarVeiculo();
                this.props.fnListaCategorias();
                this.props.fnListaPlanos();
                this.props.fnListaAnosModelos();
            }
        }
    }

    handleSubmit(e) {
        if (this.props.PublicarVeiculo.anuncio.idAnuncioBNDV == 0)
            this.props.fnPublicar();
        else
            this.props.fnAlterarAnuncio();
    }

    handleOnHide() {
        this.props.ModalAutoLine.showModal = false;
        this.props.fnUpdateState(this.props);
    }

    render() {
        var objProps = this.props;

        var dropdownAnosModelo = [];
        dropdownAnosModelo.push({ value: '', text: 'Selecione o Ano' });
        objProps.PublicarVeiculo.dadosPortal.anosmodelo.results.forEach(function (entry) {
            dropdownAnosModelo.push({ value: entry.id, text: entry.Nome });
        });

        var dropdownVersoes = [];
        dropdownVersoes.push({ value: '', text: 'Selecione a Versão' });
        objProps.PublicarVeiculo.dadosPortal.versoes.results.forEach(function (entry) {
            dropdownVersoes.push({ value: entry.id, text: entry.Nome });
        });

        var dropdownCategorias = [];
        dropdownCategorias.push({ value: '', text: 'Selecione a Categoria' });
        objProps.PublicarVeiculo.dadosPortal.categorias.results.forEach(function (entry) {
            dropdownCategorias.push({ value: entry.id, text: entry.Nome });
        });

        var dropdownPlanos = [];
        dropdownPlanos.push({ value: '', text: 'Selecione o Tipo de Plano' });
        objProps.PublicarVeiculo.dadosPortal.planos.results.forEach(function (entry) {
            dropdownPlanos.push({ value: entry.id, text: entry.Nome });
        });

        return <Modal
            show={this.props.ModalAutoLine.showModal}
            onHide={this.handleOnHide.bind(this)}
            bsSize="large"
        >
            <Formsy onValidSubmit={this.handleSubmit}>
                <Modal.Header closeButton>
                    <h4 className="modal-title custom-font">Publicar Veículo AutoLine</h4>
                </Modal.Header>
                <Modal.Body>
                    <AlertSuccess
                        successText={this.props.PublicarVeiculo.successText}
                        alertVisible={this.props.PublicarVeiculo.successText != '' && this.props.PublicarVeiculo.successText != undefined ? true : false}
                        onDismiss={(alertVisible, successText) => {
                            this.props.PublicarVeiculo.successText = successText;
                            this.props.fnUpdateState(this.props);
                        }}
                    />
                    <AlertError
                        errorText={this.props.PublicarVeiculo.errorText}
                        alertVisible={this.props.PublicarVeiculo.errorText != '' && this.props.PublicarVeiculo.errorText != undefined ? true : false}
                        onDismiss={(alertVisible, errorText) => {
                            this.props.PublicarVeiculo.errorText = errorText;
                            this.props.fnUpdateState(this.props);
                        }}
                    />
                    {
                        this.props.ModalAutoLine.isLoading
                            ? <ProgerssBar />
                            : <Row>
                                <div className="form-group col-md-5">
                                    <div className="form-group col-md-12 legend">
                                        <h4><strong>Dados Veículo</strong></h4>
                                    </div>
                                    <dl className="dl-horizontal">
                                        <dt>Placa:</dt>
                                        <dd>{this.props.PublicarVeiculo.veiculoBNDV.placa}</dd>
                                        <dt>Chassi:</dt>
                                        <dd>{this.props.PublicarVeiculo.veiculoBNDV.chassi}</dd>
                                        <dt>Marca:</dt>
                                        <dd>{this.props.PublicarVeiculo.veiculoBNDV.descMarca}</dd>
                                        <dt>Modelo:</dt>
                                        <dd>{this.props.PublicarVeiculo.veiculoBNDV.descModelo}</dd>
                                        <dt>Versão:</dt>
                                        <dd>{this.props.PublicarVeiculo.veiculoBNDV.descVersao}</dd>
                                        <dt>Câmbio:</dt>
                                        <dd>{this.props.PublicarVeiculo.veiculoBNDV.descCambio}</dd>
                                        <dt>Combustível:</dt>
                                        <dd>{this.props.PublicarVeiculo.veiculoBNDV.descCombustivel}</dd>
                                        <dt>Portas:</dt>
                                        <dd>{this.props.PublicarVeiculo.veiculoBNDV.descPortas}</dd>
                                        <dt>Cor:</dt>
                                        <dd>{this.props.PublicarVeiculo.veiculoBNDV.cor}</dd>
                                        <dt>Ano Modelo:</dt>
                                        <dd>{this.props.PublicarVeiculo.veiculoBNDV.anoModelo}</dd>
                                        <dt>Ano Fabricação:</dt>
                                        <dd>{this.props.PublicarVeiculo.veiculoBNDV.anoFabricacao}</dd>
                                        <dt>KM:</dt>
                                        <dd>{this.props.PublicarVeiculo.veiculoBNDV.km}</dd>
                                    </dl>
                                    <div className="form-group col-md-12 legend">
                                        <h4><strong>Opcionais</strong></h4>
                                    </div>
                                    <dl className="form-group col-md-12">
                                        <p>{this.props.PublicarVeiculo.veiculoBNDV.opcionais}</p>
                                    </dl>
                                </div>
                                <div className="form-group col-md-7">
                                    <div className="tile-body">
                                        <InputSelectValidated
                                            name="ddlAnoModelo"
                                            className="col-md-6"
                                            data={(this.props.PublicarVeiculo.dadosPortal.anosmodelo.isLoading
                                                ? [{ value: '', text: 'Aguarde carregando...' }]
                                                : dropdownAnosModelo)}
                                            onChange={
                                                (name, value) => {
                                                    this.props.PublicarVeiculo.anuncio.idAnosModelo = value;
                                                    this.props.fnUpdateState(this.props);
                                                    this.props.fnListaVersoes();
                                                }
                                            }
                                            selectedValue={
                                                (this.props.PublicarVeiculo.anuncio.idAnosModelo == "")
                                                    ? ""
                                                    : this.props.PublicarVeiculo.anuncio.idAnosModelo.toString()
                                            }
                                            value={
                                                (this.props.PublicarVeiculo.anuncio.idAnosModelo == "")
                                                    ? ""
                                                    : this.props.PublicarVeiculo.anuncio.idAnosModelo.toString()
                                            }
                                            title="Ano modelo:"
                                            disabled={this.props.PublicarVeiculo.dadosPortal.anosmodelo.isLoading ? true : false}
                                            required
                                        />
                                        <InputSelectValidated
                                            name="ddlCategoria"
                                            className="col-md-6"
                                            data={(this.props.PublicarVeiculo.dadosPortal.categorias.isLoading
                                                ? [{ value: '', text: 'Aguarde carregando...' }]
                                                : dropdownCategorias)}
                                            onChange={
                                                (name, value) => {
                                                    this.props.PublicarVeiculo.anuncio.idTipoVeiculo = value;
                                                    this.props.fnUpdateState(this.props);
                                                }
                                            }
                                            selectedValue={
                                                (this.props.PublicarVeiculo.anuncio.idTipoVeiculo == "")
                                                    ? ""
                                                    : this.props.PublicarVeiculo.anuncio.idTipoVeiculo.toString()
                                            }
                                            value={
                                                (this.props.PublicarVeiculo.anuncio.idTipoVeiculo == "")
                                                    ? ""
                                                    : this.props.PublicarVeiculo.anuncio.idTipoVeiculo.toString()
                                            }
                                            title="Categoria:"
                                            disabled={this.props.PublicarVeiculo.dadosPortal.categorias.isLoading ? true : false}
                                            required
                                        />
                                        <InputSelectValidated
                                            name="ddlVersao"
                                            className="col-md-12"
                                            data={(this.props.PublicarVeiculo.dadosPortal.versoes.isLoading
                                                ? [{ value: '', text: 'Aguarde carregando...' }]
                                                : dropdownVersoes)}
                                            onChange={
                                                (name, value) => {
                                                    this.props.PublicarVeiculo.anuncio.idVersao = value;
                                                    this.props.fnUpdateState(this.props);
                                                }
                                            }
                                            selectedValue={
                                                (this.props.PublicarVeiculo.anuncio.idVersao == "")
                                                    ? ""
                                                    : this.props.PublicarVeiculo.anuncio.idVersao.toString()
                                            }
                                            value={
                                                (this.props.PublicarVeiculo.anuncio.idVersao == "")
                                                    ? ""
                                                    : this.props.PublicarVeiculo.anuncio.idVersao.toString()
                                            }
                                            title="Versão:"
                                            disabled={this.props.PublicarVeiculo.dadosPortal.versoes.isLoading ? true : false}
                                            required
                                        />
                                        <InputSelectValidated
                                            name="ddlTipoPlano"
                                            className="col-md-6"
                                            data={(this.props.PublicarVeiculo.dadosPortal.planos.isLoading
                                                ? [{ value: '', text: 'Aguarde carregando...' }]
                                                : dropdownPlanos)}
                                            onChange={
                                                (name, value) => {
                                                    this.props.PublicarVeiculo.anuncio.idTipoPlano = value;
                                                    this.props.fnUpdateState(this.props);
                                                }
                                            }
                                            selectedValue={
                                                (this.props.PublicarVeiculo.anuncio.idTipoPlano == "")
                                                    ? ""
                                                    : this.props.PublicarVeiculo.anuncio.idTipoPlano.toString()
                                            }
                                            value={
                                                (this.props.PublicarVeiculo.anuncio.idTipoPlano == "")
                                                    ? ""
                                                    : this.props.PublicarVeiculo.anuncio.idTipoPlano.toString()
                                            }
                                            title="Tipo de plano:"
                                            disabled={this.props.PublicarVeiculo.dadosPortal.planos.isLoading ? true : false}
                                            required
                                        />
                                        <div className="form-group col-md-6">
                                            <label>Valor para anúncio:</label>
                                            <CurrencyInput
                                                decimalSeparator="."
                                                thousandSeparator=""
                                                className="form-control"
                                                id="txtValorAnuncio"
                                                onChange={(x) => {
                                                    this.props.PublicarVeiculo.anuncio.preco = parseFloat(x);
                                                    this.props.fnUpdateState(this.props);
                                                }}
                                                inputType="text"
                                                value={this.props.PublicarVeiculo.anuncio.preco}
                                            />
                                        </div>
                                        <TextAreaValidated
                                            name="txtDescricao"
                                            placeholder="Informe a descrição do anúncio"
                                            rows={6}
                                            className="col-md-12"
                                            onChange={(name, value) => {
                                                this.props.PublicarVeiculo.anuncio.texto = value;
                                                this.props.fnUpdateState(this.props);
                                            }}
                                            value={this.props.PublicarVeiculo.anuncio.texto}
                                            title="Descrição do anúncio:"
                                            required
                                        />
                                    </div>
                                </div>
                            </Row>
                    }
                </Modal.Body>
                <Modal.Footer>
                    {this.props.PublicarVeiculo.anuncio.idAnuncioBNDV != null
                        && this.props.PublicarVeiculo.anuncio.idAnuncioBNDV != 0
                        ? <BtnCancelar
                            type="button"
                            text=" Remover anúncio"
                            textLoading=" Aguarde..."
                            isLoading={this.props.PublicarVeiculo.isLoading}
                            onClick={() => { this.props.fnRemover(); }}
                        />
                        : null}
                    <BtnSalvar
                        type="submit"
                        text={this.props.PublicarVeiculo.anuncio.idAnuncioBNDV != 0 ? " Alterar Anúncio" : "Publicar Veículo"}
                        textLoading=" Aguarde..."
                        isLoading={this.props.PublicarVeiculo.isLoading}
                    />
                </Modal.Footer>
            </Formsy>
        </Modal>;
    }
}

export default connect(
    state => state.marketing_publicador,
    dispatch => bindActionCreators(actionCreators, dispatch),
    null, {}
)(ModalAutoLine);