import React from 'react';
import { Route, Switch } from 'react-router';

// Layouts padrão
import Layout from './components/template/Layout';
import LayoutEmpty from './components/template/LayoutEmpty';

// Componentes principais
import Login from './containers/Login';
import Home from './containers/Home';

// Dashboard
import Dashboard from './containers/dashboard/Dashboard';

// Análise de risco
import AnaliseRiscoPagamentos from './containers/analiserisco/Pagamentos';
import AnaliseRiscoBndvCompleta from './containers/analiserisco/BndvCompleta';
import AnaliseRiscoBndvCompletaRF from './containers/analiserisco/BndvCompletaRF';

// Marketing
import Publicador from './containers/marketing/Publicador';
import PublicadorConfig from './containers/marketing/PublicadorConfig';

// E-doc
import EdocConsulta from './containers/edoc/EdocConsulta';
import EdocConfiguracao from './containers/edoc/EdocConfiguracao';

// Documentos
import DaypagConsulta from './containers/documentos/Daypag_Consulta';
import DaypagHistoricoPagamentos from './containers/documentos/Daypag_HistoricoPagamentos';

// CRM
import CrmConsulta from './containers/crm/CrmConsulta';
import CrmDetalhes from './containers/crm/CrmDetalhes';
import CrmConfiguracao from './containers/crm/CrmConfiguracao';
import CrmWebMotors from './containers/crm/CrmWebMotors';
import CrmMercadoLivrePreAprovado from './containers/crm/CrmMercadoLivrePreAprovado';

export default () => (
    <Switch>
        <Route path="/(login|signup)" render={({ location }) => (
            <LayoutEmpty>
                <Switch>
                    <Route path="/login" component={Login} />
                </Switch>
            </LayoutEmpty>
        )} />
        <Route path="/(dashboard|analiserisco/pagamentos|analiserisco/bndvcompleta|analiserisco/bndvcompletarf)" render={({ location }) => (
            <Layout>
                <Switch>
                    <Route path="/dashboard" component={Dashboard} />
                    <Route path="/analiserisco/pagamentos" component={AnaliseRiscoPagamentos} />
                    <Route path="/analiserisco/bndvcompleta" component={AnaliseRiscoBndvCompleta} />
                    <Route path="/analiserisco/bndvcompletarf" component={AnaliseRiscoBndvCompletaRF} />
                </Switch>
            </Layout>
        )} />
        <Route path="/(marketing/publicador/meusanuncios|marketing/publicador/configuracao)" render={({ location }) => (
            <Layout>
                <Switch>
                    <Route path='/marketing/publicador/meusanuncios' component={Publicador} />
                    <Route path='/marketing/publicador/configuracao' component={PublicadorConfig} />
                </Switch>
            </Layout>
        )} />
        <Route path="/(edoc/configuracao|edoc/consulta)" render={({ location }) => (
            <Layout>
                <Switch>
                    <Route path='/edoc/consulta' component={EdocConsulta} />
                    <Route path='/edoc/configuracao' component={EdocConfiguracao} />
                </Switch>
            </Layout>
        )} />
        <Route path="/(documentos/daypag/consulta|documentos/daypag/historicopagamentos)" render={({ location }) => (
            <Layout>
                <Switch>
                    <Route path='/documentos/daypag/consulta' component={DaypagConsulta} />
                    <Route path='/documentos/daypag/historicopagamentos' component={DaypagHistoricoPagamentos} />
                </Switch>
            </Layout>
        )} />
        <Route path="/(crm/configuracao|crm/consulta|crm/webmotors|crm/detalhes|crm/pre_aprovado_mercado_livre/*)" render={({ location }) => (
            <Layout>
                <Switch>
                    <Route path='/crm/consulta' component={CrmConsulta} />
                    <Route path='/crm/detalhes/:id' component={CrmDetalhes} />
                    <Route path='/crm/configuracao' component={CrmConfiguracao} />
                    <Route path='/crm/webmotors' component={CrmWebMotors} />
                    <Route path='/crm/pre_aprovado_mercado_livre' component={CrmMercadoLivrePreAprovado} />
                </Switch>
            </Layout>
        )} />
        <Layout>
            <Route path="/" component={Home} />
        </Layout>
    </Switch>
);
