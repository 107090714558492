import * as React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actionCreators } from '../../../../store/documentos/daypag/DaypagHistorico';

import Moment from 'react-moment';
import { Row } from 'react-bootstrap';
import AlertError from '../../../commons/AlertError';
import Section from '../../../commons/Section';
import DateTimePicker from '../../../commons/DateTimePicker';
import InputSelect from '../../../commons/InputSelect';
import BtnPesquisar from '../../../commons/BtnPesquisar';
import ModalRecibo from './ModalRecibo';

class HistoricoPagamentosForm extends React.Component {
    constructor() {
        super();
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() { }

    handleSubmit(e) {
        e.preventDefault();
        this.props.fnListaHistoricoPagamentos();
    }

    render() {
        var objProps = this.props;

        return <div>
            <Row>
                <div className="col-md-12">
                    <Section>
                        <div className="tile-header bg-blue dvd dvd-btm">
                            <h3 className="custom-font"><strong>Daypag - Consultas Histórico de Pagamentos</strong></h3>
                        </div>
                        <div className="tile-body nopadding">
                            <AlertError
                                errorText={this.props.historicoPagamentos.errorText}
                                alertVisible={this.props.historicoPagamentos.errorText != '' && this.props.historicoPagamentos.errorText != undefined ? true : false}
                                onDismiss={(alertVisible, errorText) => {
                                    this.props.historicoPagamentos.errorText = errorText;
                                    this.props.fnUpdateState(this.props);
                                }}
                            />
                            <Row>
                                <div className="form-group col-md-12">
                                    <form id="frm" onSubmit={this.handleSubmit}>
                                        <Row>
                                            <div className="form-group col-md-3">
                                                <label>Data inicial:</label>
                                                <DateTimePicker
                                                    onChange={(value) => {
                                                        this.props.historicoPagamentos.dataDe = value; this.props.fnUpdateState(this.props);
                                                    }}
                                                    value={this.props.historicoPagamentos.dataDe}
                                                />
                                            </div>
                                            <div className="form-group col-md-3">
                                                <label>Data final:</label>
                                                <DateTimePicker
                                                    onChange={(value) => {
                                                        this.props.historicoPagamentos.dataAte = value; this.props.fnUpdateState(this.props);
                                                    }}
                                                    value={this.props.historicoPagamentos.dataAte}
                                                />
                                            </div>
                                            <div className="form-group col-md-6 text-left">
                                                <BtnPesquisar
                                                    type="submit"
                                                    isLoading={this.props.historicoPagamentos.isLoading}
                                                    text=" Consultar Histórico"
                                                    textLoading=" Aguarde..." />
                                            </div>
                                        </Row>
                                    </form>
                                    <br />
                                    <div className="table-responsive">
                                        <div className="dataTables_wrapper form-inline dt-bootstrap no-footer">
                                            <div className="dataTables_length" id="basic-usage_length">
                                                <InputSelect
                                                    class="form-control input-sm"
                                                    data={[{ value: '10', text: '10' }, { value: '25', text: '25' }, { value: '50', text: '50' }]}
                                                    onChange={(name, value) => {
                                                        //this.props.consultaVeiculos.pageSize = value;
                                                        //this.props.consultaVeiculos.pageNumber = 1;
                                                        //this.props.fnUpdateState(this.props);
                                                        //this.props.listaVeiculos();
                                                    }}
                                                    selectedValue={'10'}
                                                /> registros por página
                                            </div>
                                            <Row>
                                                <div className="col-sm-12">
                                                    <table className="table table-custom dataTable no-footer">
                                                        <thead>
                                                            <tr role="row">
                                                                <th></th>
                                                                <th>Placa</th>
                                                                <th>Renavam</th>
                                                                <th>Pago Por</th>
                                                                <th>Data Pagto</th>
                                                                <th>Valor Total</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {
                                                                (this.props.historicoPagamentos.isLoading)
                                                                    ? <tr key={0} className="odd">
                                                                        <td colSpan={6} className="dataTables_empty">
                                                                            <div className="progress progress-striped active">
                                                                                <div className="progress-bar progress-bar-blue" role="progressbar" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100" style={{ width: "100%" }}>
                                                                                    <span className="sr-only">100% Complete</span>
                                                                                </div>
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                    : (this.props.historicoPagamentos.results.length == 0)
                                                                        ? <tr key={0} className="odd">
                                                                            <td colSpan={6} className="dataTables_empty">
                                                                                <p className="text-warning">Não foram encontrados registros para a busca...</p>
                                                                            </td>
                                                                        </tr>
                                                                        : this.props.historicoPagamentos.results.map(function (rowData, index) {
                                                                            return (
                                                                                <tr key={index}>
                                                                                    <td><a onClick={() => {
                                                                                        objProps.modalRecibo.codRecibo = rowData.jsonResposta.serializedObject.ListaPagamentos[0].CodRecibo;
                                                                                        objProps.modalRecibo.showModal = true;
                                                                                        objProps.fnUpdateState(objProps);
                                                                                    }}><i className="fa fa-file"></i></a></td>
                                                                                    <td>{rowData.jsonResposta.serializedObject.ListaPagamentos[0].Placa}</td>
                                                                                    <td>{rowData.jsonResposta.serializedObject.ListaPagamentos[0].Renavam}</td>
                                                                                    <td>{rowData.nomeUsuario}</td>
                                                                                    <td><Moment format="DD/MM/YYYY">{rowData.jsonResposta.serializedObject.ListaPagamentos[0].DataHoraPagamento}</Moment></td>
                                                                                    <td>{rowData.jsonResposta.serializedObject.ListaPagamentos[0].ValorTotal}</td>
                                                                                </tr>
                                                                            )
                                                                        })
                                                            }
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </Row>
                                        </div>
                                    </div>
                                </div>
                            </Row>
                        </div>
                    </Section>
                </div>
            </Row>
            {this.props.modalRecibo.showModal ? <ModalRecibo /> : null}
        </div>;
    }
}

export default connect(
    state => state.daypag_historico,
    dispatch => bindActionCreators(actionCreators, dispatch),
    null, {}
)(HistoricoPagamentosForm);