import * as React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actionCreators } from '../../../../../store/marketing/publicador/MarketingPublicador';

import Formsy from 'formsy-react';
import { Row, Modal } from 'react-bootstrap';
import AlertError from '../../../../commons/AlertError';
import AlertSuccess from '../../../../commons/AlertSuccess';
import AlertInfo from '../../../../commons/AlertInfo';
import TextAreaValidated from '../../../../commons/TextAreaValidated';
import BtnSalvar from '../../../../commons/BtnSalvar';
import BtnCancelar from '../../../../commons/BtnCancelar';

class ModalFacebookMoto extends React.Component {
    constructor() {
        super();
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    async componentDidMount() {
        if (this.props.ModalFacebookMoto.showModal) {
            if (this.props.PublicarVeiculo.anuncio.idAnuncioBNDV != null && this.props.PublicarVeiculo.anuncio.idAnuncioBNDV != 0) {
                this.props.PublicarVeiculo.errorText = "";
                this.props.PublicarVeiculo.successText = "";
                await this.props.fnBuscarVeiculo();
                await this.props.fnBuscarAnuncio();
                
            }
            else {
                await this.props.fnBuscarVeiculo();

                this.props.PublicarVeiculo.errorText = "";
                this.props.PublicarVeiculo.successText = "";
               
                this.props.PublicarVeiculo.anuncio.texto =
                    this.props.PublicarVeiculo.veiculoBNDV.descMarca +                   
                    ' ' + this.props.PublicarVeiculo.veiculoBNDV.descVersao +
                    ' - ' + this.props.PublicarVeiculo.veiculoBNDV.anoModelo +
                    ' - ' + this.props.PublicarVeiculo.veiculoBNDV.descCambio +
                    '\n\n' + this.props.PublicarVeiculo.anuncio.texto;

                this.props.fnUpdateState(this.props);
            }

        }
    }

    handleSubmit() {
        if (this.props.PublicarVeiculo.anuncio.idAnuncioBNDV == 0)
            this.props.fnPublicarMoto();
        else
            this.props.fnAlterarAnuncioMoto();
    }

    handleOnHide() {
        this.props.ModalFacebookMoto.showModal = false;
        this.props.fnUpdateState(this.props);
    }

    render() {
        return <Modal
            show={this.props.ModalFacebookMoto.showModal}
            onHide={this.handleOnHide.bind(this)}
            bsSize="large"
        >
            <Formsy onValidSubmit={this.handleSubmit}>
                <Modal.Header closeButton>
                    <h4 className="modal-title custom-font">Post no Facebook</h4>
                </Modal.Header>
                <Modal.Body>
                    <AlertSuccess
                        successText={this.props.PublicarVeiculo.successText}
                        alertVisible={this.props.PublicarVeiculo.successText != '' && this.props.PublicarVeiculo.successText != undefined ? true : false}
                        onDismiss={(alertVisible, successText) => {
                            this.props.PublicarVeiculo.successText = successText;
                            this.props.fnUpdateState(this.props);
                        }}
                    />
                    <AlertError
                        errorText={this.props.PublicarVeiculo.errorText}
                        alertVisible={this.props.PublicarVeiculo.errorText != '' && this.props.PublicarVeiculo.errorText != undefined ? true : false}
                        onDismiss={(alertVisible, errorText) => {
                            this.props.PublicarVeiculo.errorText = errorText;
                            this.props.fnUpdateState(this.props);
                        }}
                    />
                    <AlertInfo
                        textHeader="Aviso sobre uso Facebook!"
                        text={<p>Prezado usuário o Facebook para integração de Post apenas permite que sejam enviados posts que contenham fotos, certifique-se de que o veículo anunciado já está com pelo menos uma foto cadastrada. Nós utilizaremos a primeira foto cadastrada para fazer o Post.</p>}
                        alertVisible={true}
                        onDismiss={() => { }}
                    />
                    <Row>
                        <div className="form-group col-md-5">
                            <div className="form-group col-md-12 legend">
                                <h4><strong>Dados Veículo</strong></h4>
                            </div>
                            <dl className="dl-horizontal">
                                <dt>Placa:</dt>
                                <dd>{this.props.PublicarVeiculo.veiculoBNDV.placa}</dd>
                                <dt>Chassi:</dt>
                                <dd>{this.props.PublicarVeiculo.veiculoBNDV.chassi}</dd>
                                <dt>Marca:</dt>
                                <dd>{this.props.PublicarVeiculo.veiculoBNDV.descMarca}</dd>
                                <dt>Modelo:</dt>
                                <dd>{this.props.PublicarVeiculo.veiculoBNDV.descModelo}</dd>
                                <dt>Versão:</dt>
                                <dd>{this.props.PublicarVeiculo.veiculoBNDV.descVersao}</dd>
                                <dt>Câmbio:</dt>
                                <dd>{this.props.PublicarVeiculo.veiculoBNDV.descCambio}</dd>
                                <dt>Combustível:</dt>
                                <dd>{this.props.PublicarVeiculo.veiculoBNDV.descCombustivel}</dd>                               
                                <dt>Cor:</dt>
                                <dd>{this.props.PublicarVeiculo.veiculoBNDV.cor}</dd>
                                <dt>Ano Modelo:</dt>
                                <dd>{this.props.PublicarVeiculo.veiculoBNDV.anoModelo}</dd>
                                <dt>Ano Fabricação:</dt>
                                <dd>{this.props.PublicarVeiculo.veiculoBNDV.anoFabricacao}</dd>
                                <dt>KM:</dt>
                                <dd>{this.props.PublicarVeiculo.veiculoBNDV.km}</dd>
                            </dl>
                            <div className="form-group col-md-12 legend">
                                <h4><strong>Opcionais</strong></h4>
                            </div>
                            <dl className="form-group col-md-12">
                                <p>{this.props.PublicarVeiculo.veiculoBNDV.opcionais}</p>
                            </dl>
                        </div>
                        <div className="form-group col-md-7">
                            <div className="tile-body">
                                <Row>
                                    <TextAreaValidated
                                        name="txtDescricao"
                                        className="col-md-12"
                                        placeholder="Informe a descrição do post"
                                        rows={12}
                                        onChange={(name, value) => {
                                            this.props.PublicarVeiculo.anuncio.texto = value;
                                            this.props.fnUpdateState(this.props);
                                        }}
                                        value={this.props.PublicarVeiculo.anuncio.texto}
                                        title="Descrição do post:"
                                        required
                                    />
                                </Row>
                            </div>
                        </div>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    {this.props.PublicarVeiculo.anuncio.idAnuncioBNDV != null
                        && this.props.PublicarVeiculo.anuncio.idAnuncioBNDV != 0
                        ? <BtnCancelar
                            type="button"
                            text=" Remover Post"
                            textLoading=" Aguarde..."
                            isLoading={this.props.PublicarVeiculo.isLoading}
                            onClick={() => { this.props.fnRemoverMoto(); }}
                        />
                        : null}
                    <BtnSalvar
                        type="submit"
                        text={this.props.PublicarVeiculo.anuncio.idAnuncioBNDV != 0 ? " Alterar Post" : "Postar no Facebook"}
                        textLoading=" Aguarde..."
                        isLoading={this.props.PublicarVeiculo.isLoading}
                    />
                </Modal.Footer>
            </Formsy>
        </Modal>;
    }
}

export default connect(
    state => state.marketing_publicador,
    dispatch => bindActionCreators(actionCreators, dispatch),
    null, {}
)(ModalFacebookMoto);