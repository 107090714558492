import * as React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actionCreators } from '../../../../store/marketing/publicador/MarketingPublicadorConfig';

import { Row } from 'react-bootstrap';
import ProgressBar from '../../../commons/ProgressBar';
import AlertError from '../../../commons/AlertError';
import Section from '../../../commons/Section';
import BtnSalvar from '../../../commons/BtnSalvar';

class AnuncianteForm extends React.Component {
    constructor(props) {
        super(props);
    }

    async componentDidMount() {
       await this.props.fnBuscaTokenAnunciante();
    }

    render() {
        return <div>
            <Row>
                <div className="col-md-12">
                    <Section>
                        <div className="tile-header bg-blue dvd dvd-btm">
                            <h3 className="custom-font"><strong>Token autenticação anunciante no BNDV</strong></h3>
                        </div>
                        <div className="tile-body nopadding">
                            <AlertError
                                errorText={this.props.dadosAnunciante.errorText}
                                alertVisible={this.props.dadosAnunciante.errorText != '' && this.props.dadosAnunciante.errorText != undefined ? true : false}
                                onDismiss={(alertVisible, errorText) => {
                                    this.props.dadosAnunciante.errorText = errorText;
                                    this.props.fnUpdateState(this.props);
                                }}
                            />
                            <Row>
                                <div className="form-group col-md-12">
                                    {
                                        this.props.dadosAnunciante.isLoading
                                            ? <ProgressBar />
                                            : this.props.dadosAnunciante.token == ""
                                                ? <div className="form-group col-md-12">
                                                    <h4>Percebemos que você ainda não possui um token de autenticação clique no botão abaixo para gerar seu token!</h4>
                                                    <BtnSalvar
                                                        type="button"
                                                        text={" Gerar meu Token"}
                                                        textLoading=" Aguarde Gerando Novo Token..."
                                                        isLoading={this.props.dadosAnunciante.isLoading}
                                                        onClick={() => {
                                                            this.props.fnGerarTokenAnunciante();
                                                        }}
                                                    />
                                                </div>
                                                : <h4> <strong>Token de Autenticação: </strong>{this.props.dadosAnunciante.token}</h4>
                                    }
                                </div>
                            </Row>
                        </div>
                    </Section>
                </div>
            </Row>
        </div>;
    }
}

export default connect(
    state => state.marketing_publicadorconfig,
    dispatch => bindActionCreators(actionCreators, dispatch),
    null, {}
)(AnuncianteForm);