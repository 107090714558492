import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actionCreators } from '../../store/login';
import NavHeader from './NavHeader';
import NavMenu from './NavMenu';
import * as Minovate from '../../assets/js/main_react';
import { addLocaleData, IntlProvider } from 'react-intl';
import esLocaleData from 'react-intl/locale-data/pt';

addLocaleData(esLocaleData);

class Layout extends Component {
    componentDidMount() {
        Minovate.documentOnReady_init();
    }

    render() {
        return (
            <IntlProvider locale="pt-BR">
                <div id="wrap">
                    <NavHeader />
                    <NavMenu />
                    <section id="content">
                        <div id="container">
                            {this.props.children}
                        </div>
                    </section>
                </div>
            </IntlProvider>
        );
    }
}

export default connect(
    state => state.auth,
    dispatch => bindActionCreators(actionCreators, dispatch),
    null,
    { pure: false }
)(Layout);
