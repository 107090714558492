import * as React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actionCreators } from '../../../store/crm/CrmConsulta';

import { Modal, Row } from 'react-bootstrap';
import AlertError from '../../commons/AlertError';
import AlertSuccess from '../../commons/AlertSuccess';
import ProgerssBar from '../../commons/ProgressBar';
import InputSelect from '../../commons/InputSelect';
import BtnSalvar from '../../commons/BtnSalvar';
import BtnCancelar from '../../commons/BtnCancelar';

class ModalReabrir extends React.Component {
    constructor(props) {
        super(props);
    }

    async componentDidMount() { }

    handleOnHide() {
        this.props.modalReabrir.showModal = false;
        this.props.fnUpdateState(this.props);
    }

    async fnReabrirOportunidade() {
        await this.props.fnReabrirOportunidade();
    }

    render() {
        return <Modal
            show={this.props.modalReabrir.showModal}
            onHide={this.handleOnHide.bind(this)}
            bsSize="small"
        >
            <Modal.Header closeButton>
                <h4 className="modal-title custom-font">Reabrir Oportunidade</h4>
            </Modal.Header>
            <Modal.Body>
                <AlertSuccess
                    successText={this.props.modalReabrir.successText}
                    alertVisible={this.props.modalReabrir.successText != '' && this.props.modalReabrir.successText != undefined ? true : false}
                    onDismiss={(alertVisible, successText) => {
                        this.props.modalReabrir.successText = successText;
                        this.props.fnUpdateState(this.props);
                    }}
                />
                <AlertError
                    errorText={this.props.modalReabrir.errorText}
                    alertVisible={this.props.modalReabrir.errorText != '' && this.props.modalReabrir.errorText != undefined ? true : false}
                    onDismiss={(alertVisible, errorText) => {
                        this.props.modalReabrir.errorText = errorText;
                        this.props.fnUpdateState(this.props);
                    }}
                />
                {
                    this.props.modalReabrir.isLoading
                        ? <ProgerssBar />
                        : <div>
                            <Row>
                                <div className="col-md-6 text-right">
                                    <BtnCancelar
                                        isLoading={this.props.modalReabrir.isLoading}
                                        text={this.props.modalReabrir.isLoading ? " Aguarde..." : " Cancelar"}
                                        disabled={this.props.modalReabrir.isLoading}
                                        onClick={() => {
                                            this.props.modalReabrir.showModal = false;
                                            this.props.fnUpdateState(this.props);
                                        }}
                                    />
                                </div>
                                <div className="col-md-6 text-right">
                                    <BtnSalvar
                                        isLoading={this.props.modalReabrir.isLoading}
                                        text={this.props.modalReabrir.isLoading ? " Aguarde..." : " Confirmar"}
                                        disabled={this.props.modalReabrir.isLoading}
                                        onClick={() => { this.fnReabrirOportunidade(); }}
                                    />
                                </div>
                            </Row>
                        </div>
                }
            </Modal.Body>
        </Modal>;
    }
}

export default connect(
    state => state.crm_consulta,
    dispatch => bindActionCreators(actionCreators, dispatch),
    null, {}
)(ModalReabrir);