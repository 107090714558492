import * as React from 'react'
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actionCreators } from '../../../store/edoc/EdocConsulta';

import Moment from 'react-moment';
import { Modal, Row } from 'react-bootstrap';
import AlertError from '../../commons/AlertError';
import AlertSuccess from '../../commons/AlertSuccess';
import ProgerssBar from '../../commons/ProgressBar';
import InputSelect from '../../commons/InputSelect';
import BtnSalvar from '../../commons/BtnSalvar';
import DropzoneComponent from 'react-dropzone-component';
import 'react-dropzone-component/styles/filepicker.css';
import 'dropzone/dist/min/dropzone.min.css';

class ModalUpload extends React.Component {
    constructor(props) {
        super(props);

        this.djsConfig = {
            addRemoveLinks: true,
            acceptedFiles: "image/jpeg,image/png,image/gif,application/pdf",
            autoProcessQueue: false,
            maxFiles: 1,
            dictDefaultMessage: 'Clique aqui para selecionar o arquivo',
            thumbnailMethod: 'contain'
        };

        this.componentConfig = {
            iconFiletypes: ['.jpg', '.png', '.gif', '.pdf'],
            showFiletypeIcon: true,
            postUrl: 'no-url'
        };
    }

    componentDidMount() {
        this.props.listaTiposDocumento();
    }

    handleOnHide() {
        this.props.modalUpload.showModal = false;
        this.props.fnUpdateState(this.props);
    }

    handleFileAdded(file) {
        var objProps = this.props;
        var reader = new FileReader();
        reader.onload = function (event) {
            var base64String = event.target.result;
            objProps.modalUpload.file = base64String;
            objProps.fnUpdateState(objProps);
        };
        reader.readAsDataURL(file);
    }

    handleFileRemoved() {
        this.props.modalUpload.file = '';
        this.props.fnUpdateState(this.props);
    }

    handleSubmit() {
        if (this.props.modalUpload.tipo == 0) {
            this.props.modalUpload.errorText = 'Informe se o documento é entrada ou saída';
            this.props.fnUpdateState(this.props);
        }
        else if (this.props.modalUpload.idTipoDocumento == 0) {
            this.props.modalUpload.errorText = 'Informe o tipo do documento';
            this.props.fnUpdateState(this.props);
        }
        else if (this.props.modalUpload.file == '') {
            this.props.modalUpload.errorText = 'Escolha o arquivo que deseja fazer upload';
            this.props.fnUpdateState(this.props);
        }
        else {
            this.props.inserirDocumento();
        }
    }

    render() {
        var objProps = this.props;

        var dropdownTipoDocumento = [];
        dropdownTipoDocumento.push({ value: 0, text: 'Selecione...' });
        objProps.modalUpload.dropdownTipoDocumento.results.forEach(function (entry) {
            dropdownTipoDocumento.push({ value: entry.id.toString(), text: entry.nome });
        });


        const config = this.componentConfig;
        const djsConfig = this.djsConfig;
        const eventHandlers = {
            addedfile: this.handleFileAdded.bind(this),
            removedfile: this.handleFileRemoved.bind(this),
        }

        return <Modal
            show={this.props.modalUpload.showModal}
            onHide={this.handleOnHide.bind(this)}
            bsSize="large"
        >
            <form
                id="frm"
                name="frm"
                role="form"
                onSubmit={this.handleSubmit}
                data-parsley-validate>
                <Modal.Header closeButton>
                    <h4 className="modal-title custom-font">Upload de Documento</h4>
                </Modal.Header>
                <Modal.Body>
                    <AlertSuccess
                        successText={this.props.modalUpload.successText}
                        alertVisible={this.props.modalUpload.successText != '' && this.props.modalUpload.successText != undefined ? true : false}
                        onDismiss={(alertVisible, successText) => {
                            this.props.modalUpload.successText = successText;
                            this.props.fnUpdateState(this.props);
                        }}
                    />
                    <AlertError
                        errorText={this.props.modalUpload.errorText}
                        alertVisible={this.props.modalUpload.errorText != '' && this.props.modalUpload.errorText != undefined ? true : false}
                        onDismiss={(alertVisible, errorText) => {
                            this.props.modalUpload.errorText = errorText;
                            this.props.fnUpdateState(this.props);
                        }}
                    />
                    {
                        this.props.modalUpload.isLoading
                            ? <ProgerssBar />
                            : <div>
                                <Row>
                                    <div className="col-md-2 text-right">Placa: </div>
                                    <div className="col-md-4">{this.props.modalUpload.placa}</div>
                                    <div className="col-md-2 text-right">Chassi: </div>
                                    <div className="col-md-4">{this.props.modalUpload.chassi}</div>
                                </Row>
                                <Row>
                                    <div className="col-md-2 text-right">Ano Fab/Mod: </div>
                                    <div className="col-md-4">{this.props.modalUpload.anoFabricacao.toString() + "/" + this.props.modalImagens.anoModelo.toString()}</div>
                                    <div className="col-md-2 text-right">Data Cadastro: </div>
                                    <div className="col-md-4"><Moment format="DD/MM/YYYY">{this.props.modalUpload.dataCadastro}</Moment></div>
                                </Row>
                                <Row>
                                    <div className="col-md-2 text-right">Marca: </div>
                                    <div className="col-md-4">{this.props.modalUpload.nomeMarca}</div>
                                    <div className="col-md-2 text-right">Modelo: </div>
                                    <div className="col-md-4">{this.props.modalUpload.nomeVersao}</div>
                                </Row>
                                <Row>
                                    <div className="col-md-2 text-right">Num. Envelope: </div>
                                    <div className="col-md-4">{this.props.modalUpload.numEnvelope}</div>
                                    <div className="col-md-2 text-right">Status: </div>
                                    <div className="col-md-4">
                                        <span className={this.props.modalUpload.status == "Vendido" ? "label label-success" : this.props.modalUpload.status == "Cancelado" ? "label label-danger" : "label label-primary"}>{this.props.modalUpload.status}</span>
                                    </div>
                                </Row>
                                <br />
                                <Row>
                                    <InputSelect
                                        name="ddlEntradaSaida"
                                        className="col-md-12"
                                        data={[
                                            { value: 0, text: 'Selecione...' },
                                            { value: 1, text: 'Entrada' },
                                            { value: 2, text: 'Saída' },
                                        ]}
                                        onChange={(name, value) => {
                                            this.props.modalUpload.tipo = value;
                                            this.props.fnUpdateState(this.props);
                                        }}
                                        title="Entrada/Saída:"
                                        selectedValue={this.props.modalUpload.tipo}
                                    />
                                    <InputSelect
                                        name="ddlTipoDocumento"
                                        className="col-md-12"
                                        data={this.props.modalUpload.dropdownTipoDocumento.isLoading
                                            ? [{ value: null, text: 'Carregando...' }]
                                            : this.props.modalUpload.dropdownTipoDocumento.errorText != ''
                                                ? [{ value: null, text: this.props.modalUpload.dropdownTipoDocumento.errorText }]
                                                : dropdownTipoDocumento}
                                        onChange={(name, value) => {
                                            this.props.modalUpload.idTipoDocumento = value;
                                            this.props.fnUpdateState(this.props);
                                        }}
                                        title="Tipo de Documento:"
                                        disabled={this.props.modalUpload.dropdownTipoDocumento.isLoading ? true : false}
                                        selectedValue={this.props.modalUpload.idTipoDocumento}
                                    />
                                </Row>
                                <Row>
                                    <div className="col-md-12">
                                        <DropzoneComponent config={config} eventHandlers={eventHandlers} djsConfig={djsConfig} />
                                    </div>
                                </Row>
                                <Row>
                                    <div className="col-md-12 text-center">
                                        <BtnSalvar
                                            type="button"
                                            text=" Efetuar Upload"
                                            textLoading=" Aguarde efetuando upload..."
                                            disabled={this.props.modalUpload.isLoading}
                                            onClick={this.handleSubmit.bind(this)}
                                        />
                                    </div>
                                </Row>
                            </div>
                    }
                </Modal.Body>
            </form>
        </Modal>;
    }
}

export default connect(
    state => state.edoc_consulta,
    dispatch => bindActionCreators(actionCreators, dispatch),
    null, {}
)(ModalUpload);