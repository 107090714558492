import * as React from 'react';

import HeaderPage from '../../components/commons/HeaderPage';
import ConsultaForm from '../../components/documentos/daypag/consulta/ConsultaForm';

export default class Daypag_Consulta extends React.Component {
    render() {
        return <div className='page'>
            <HeaderPage
                path=' Documentação Veículos'
                name='Daypag'
                subName='Consulta/Pagamento' />
            <ConsultaForm />
        </div>;
    }
}