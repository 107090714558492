import { fetch, addTask } from 'domain-task';
import * as analiserisco_service from '../../services/analiserisco/analiserisco_service';

const REQUEST_PAGAMENTOS_ACTION = 'REQUEST_PAGAMENTOS_ACTION';
const RESPONSE_PAGAMENTOS_ACTION = 'RESPONSE_PAGAMENTOS_ACTION';
const FAILURE_PAGAMENTOS_ACTION = 'FAILURE_PAGAMENTOS_ACTION';
const POST_CONFIRMAR_CANCELAMENTO_ACTION = 'POST_CONFIRMAR_CANCELAMENTO_ACTION';
const SUCCESS_CONFIRMAR_CANCELAMENTO_ACTION = 'SUCCESS_CONFIRMAR_CANCELAMENTO_ACTION';
const FAILURE_CONFIRMAR_CANCELAMENTO_ACTION = 'FAILURE_CONFIRMAR_CANCELAMENTO_ACTION';
const OPEN_MODAL_CANCELAR_SOLICITACAO_CREDITO_ACTION = 'OPEN_MODAL_CANCELAR_SOLICITACAO_CREDITO_ACTION';
const CLOSE_MODAL_CANCELAR_SOLICITACAO_CREDITO_ACTION = 'CLOSE_MODAL_CANCELAR_SOLICITACAO_CREDITO_ACTION';
const UPDATE_STATE_HISTORICO_PAGAMENTOS_ACTION = 'UPDATE_STATE_HISTORICO_PAGAMENTOS_ACTION';
const initialState = {
    Filters: {
        PageNumber: 1,
        PageSize: 10,
        OrderBy: '',
        Ascending: true,
        TotalNumberOfPages: 0,
        TotalNumberOfRecords: 0,
        DataDe: '',
        DataAte: '',
        IdStatus: null
    },
    Results: [],
    ModalCancelar: {
        IdCancelar: 0,
        ShowModalCancelar: false
    },
    IsLoading: false,
    ErrorText: ''
};

export const actionCreators = {
    requestListPagamentos: () => async (dispatch, getState) => {
        let fetchTask = fetch(analiserisco_service.services.Pagamentos.ListaPagamentos(getState().historicopagamentos.Filters, getState().auth.authorizationToken))
            .then(response => {
                if (response.status === 200 || response.status === 0) {
                    return response.json();
                }
                else {
                    return response.json().then(function (object) {
                        return Promise.reject(new Error(object));
                    })
                }
            })
            .then(data => {
                var _Filters = getState().historicopagamentos.Filters;
                _Filters.Ascending = getState().historicopagamentos.Filters.Ascending;
                _Filters.DataAte = getState().historicopagamentos.Filters.DataAte;
                _Filters.DataDe = getState().historicopagamentos.Filters.DataDe;
                _Filters.IdStatus = getState().historicopagamentos.Filters.IdStatus;
                _Filters.OrderBy = getState().historicopagamentos.Filters.OrderBy;
                _Filters.PageNumber = getState().historicopagamentos.Filters.PageNumber;
                _Filters.PageSize = getState().historicopagamentos.Filters.PageSize;
                _Filters.TotalNumberOfPages = data.TotalNumberOfPages;
                _Filters.TotalNumberOfRecords = data.TotalNumberOfRecords;

                dispatch({
                    type: 'RESPONSE_PAGAMENTOS_ACTION',
                    Results: data.Results
                });
            }).catch(function (err) {
                dispatch({
                    type: 'FAILURE_PAGAMENTOS_ACTION',
                    ErrorText: err.message
                });
            });

        addTask(fetchTask);
        dispatch({
            type: 'REQUEST_PAGAMENTOS_ACTION',
            Filters: getState().historicopagamentos.Filters
        });

    }
    , fnConfirmarCancelamento: () => async (dispatch, getState) => {
        var params = {
            ID: getState().historicopagamentos.ModalCancelar.IdCancelar
        }
        let fetchTask = fetch(analiserisco_service.services.Pagamentos.CancelaSolicitacaoCredito(getState().historicopagamentos.ModalCancelar.IdCancelar, getState().auth.authorizationToken))
            .then(response => {
                if (response.status === 200 || response.status === 0) {
                    return response;
                }
                else {
                    return response.json().then(function (object) {
                        return Promise.reject(new Error(object));
                    })
                }
            })
            .then(data => {
                dispatch({
                    type: 'SUCCESS_CONFIRMAR_CANCELAMENTO_ACTION',
                    IsLoading: false,
                    ModalCancelar: { IdCancelar: getState().historicopagamentos.ModalCancelar.IdCancelar, ShowModalCancelar: false }
                });

                dispatch(actionCreators.requestListPagamentos());
            }).catch(function (err) {
                dispatch({
                    type: 'FAILURE_CONFIRMAR_CANCELAMENTO_ACTION',
                    IsLoading: false,
                    ModalCancelar: { IdCancelar: getState().historicopagamentos.ModalCancelar.IdCancelar, ShowModalCancelar: false },
                    ErrorText: err.message
                });
            });

        addTask(fetchTask);
        dispatch({
            type: 'POST_CONFIRMAR_CANCELAMENTO_ACTION',
            IsLoading: true,
            ErrorText: ''
        });
    }
    , fnOpenModal: () => async (dispatch, getState) => {
        dispatch({
            type: 'OPEN_MODAL_CANCELAR_SOLICITACAO_CREDITO_ACTION',
            ModalCancelar: {
                IdCancelar: getState().historicopagamentos.ModalCancelar.IdCancelar,
                ShowModalCancelar: true
            }
        });
    }
    , fnCloseModal: () => async (dispatch, getState) => {
        dispatch({
            type: 'CLOSE_MODAL_CANCELAR_SOLICITACAO_CREDITO_ACTION',
            ModalCancelar: {
                IdCancelar: getState().historicopagamentos.ModalCancelar.IdCancelar,
                ShowModalCancelar: false
            }
        });
    }
    , fnUpdateState: (pHistoricoPagamentosState) => async (dispatch, getState) => {
        dispatch({
            type: 'UPDATE_STATE_HISTORICO_PAGAMENTOS_ACTION',
            Filters: pHistoricoPagamentosState.Filters,
            Results: pHistoricoPagamentosState.Results,
            ModalCancelar: pHistoricoPagamentosState.ModalCancelar,
            IsLoading: pHistoricoPagamentosState.IsLoading,
            ErrorText: pHistoricoPagamentosState.ErrorText
        });
    }
};

export const reducer = (state, action) => {
    state = state || initialState;

    if (action.type === REQUEST_PAGAMENTOS_ACTION) {
        return {
            ...state,
            Filters: action.Filters,
            Results: [],
            IsLoading: true
        };
    }

    if (action.type === RESPONSE_PAGAMENTOS_ACTION) {
        return {
            ...state,
            Results: action.Results,
            IsLoading: false
        };
    }

    if (action.type === FAILURE_PAGAMENTOS_ACTION) {
        return {
            ...state,
            IsLoading: false,
            ErrorText: action.ErrorText
        };
    }

    if (action.type === UPDATE_STATE_HISTORICO_PAGAMENTOS_ACTION) {
        return {
            ...state,
            Filters: action.Filters,
            Results: action.Results,
            ModalCancelar: action.ModalCancelar,
            IsLoading: action.IsLoading,
            ErrorText: action.ErrorText
        };
    }

    if (action.type === POST_CONFIRMAR_CANCELAMENTO_ACTION) {
        return {
            ...state,
            IsLoading: action.IsLoading,
            ErrorText: action.ErrorText
        };
    }

    if (action.type === SUCCESS_CONFIRMAR_CANCELAMENTO_ACTION) {
        return {
            ...state,
            IsLoading: action.IsLoading,
            ModalCancelar: action.ModalCancelar
        };
    }

    if (action.type === FAILURE_CONFIRMAR_CANCELAMENTO_ACTION) {
        return {
            ...state,
            IsLoading: action.IsLoading,
            ModalCancelar: action.ModalCancelar,
            ErrorText: action.ErrorText
        };
    }

    if (action.type === OPEN_MODAL_CANCELAR_SOLICITACAO_CREDITO_ACTION) {
        return {
            ...state,
            ModalCancelar: action.ModalCancelar
        };
    }

    if (action.type === CLOSE_MODAL_CANCELAR_SOLICITACAO_CREDITO_ACTION) {
        return {
            ...state,
            ModalCancelar: action.ModalCancelar
        };
    }

    return state;
};

