import * as React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actionCreators } from '../../../store/crm/CrmWebMotors';

import Formsy from 'formsy-react';
import { Row, Panel } from 'react-bootstrap';
import Moment from 'react-moment';
import { FormattedNumber } from 'react-intl';
import AlertError from '../../commons/AlertError';
import Section from '../../commons/Section';
import BtnPesquisar from '../../commons/BtnPesquisar';
import InputSelect from '../../commons/InputSelect';
import ListPager from '../../commons/ListPager';
import DateTimePicker from '../../commons/DateTimePicker';
import ModalDetalhes from './ModalDetalhes';

class ConsultaForm extends React.Component {
    constructor() {
        super();
        this.handleSubmit = this.handleSubmit.bind(this);
        this.parseJsonReturn = this.parseJsonReturn.bind(this);
    }

    handleSubmit(e) {
        this.props.consultaLeads.pageNumber = 1;
        this.props.consultaLeads.pageSize = 10;
        this.props.fnListaLeads();
    }

    parseJsonReturn(json) {
        json = JSON.parse(json);
        if (json.Sucesso !== undefined) {
            var jsonReturn = {
                id: json.Retorno.Lead.IdLead,
                status: '',
                midia: json.Retorno.Lead.NomeCanal,
                negociacao: json.Retorno.StatusNegociacao,
                marca: json.Retorno.Veiculo.Marca,
                modelo: json.Retorno.Veiculo.Modelo,
                versao: json.Retorno.Veiculo.Versao,
                anoModelo: json.Retorno.Veiculo.AnoModelo,
                anoFabricacao: json.Retorno.Veiculo.AnoFabricacao,
                placa: json.Retorno.Veiculo.Placa,
                valor: json.Retorno.Veiculo.PrecoClassificado,
                nome: json.Retorno.Cliente.Nome,
                email: json.Retorno.Cliente.Email,
                telefone: json.Retorno.Cliente.TelefonesContato.length > 0 ? json.Retorno.Cliente.TelefonesContato[0].NumeroTelefone : '',
                mensagem: json.Retorno.Lead.Mensagem,
            }
            return jsonReturn;
        }
        else {
            var jsonReturn = {
                id: json.id,
                status: json.status,
                midia: json.midia,
                negociacao: json.idStatusNegociacao,
                marca: json.anuncio.marca,
                modelo: json.anuncio.modelo,
                versao: json.anuncio.versao,
                anoModelo: json.anuncio.anoModelo,
                anoFabricacao: json.anuncio.anoFabricacao,
                placa: json.anuncio.placa,
                valor: json.anuncio.valor,
                nome: json.mensagem.nome,
                email: json.mensagem.email,
                telefone: json.mensagem.telefone,
                mensagem: json.mensagem.mensagem,
            }
            return jsonReturn;
        }

    }

    render() {
        var objProps = this.props;
        var objGlobal = this;
        return <Row>
            <div className="col-md-12">
                <Section>
                    <Panel id="panelFiltro" bsStyle="primary" defaultExpanded>
                        <Panel.Heading>
                            <Panel.Title componentClass="h3" toggle><strong>Consulta</strong> leads WebMotors</Panel.Title>
                        </Panel.Heading>
                        <Panel.Collapse>
                            <Panel.Body>
                                <div className="tile-body nopadding">
                                    <AlertError
                                        errorText={this.props.consultaLeads.errorText}
                                        alertVisible={this.props.consultaLeads.errorText != '' && this.props.consultaLeads.errorText != undefined ? true : false}
                                        onDismiss={(alertVisible, errorText) => {
                                            this.props.consultaLeads.errorText = errorText;
                                            this.props.fnUpdateState(this.props);
                                        }}
                                    />
                                    <Formsy onValidSubmit={this.handleSubmit}>
                                        <Row>
                                            <InputSelect
                                                name="ddlStatus"
                                                className="col-md-3"
                                                data={
                                                    [
                                                        { value: 1, text: 'Em aberto' },
                                                        { value: 2, text: 'Lead aceito' },
                                                        { value: 3, text: 'Lead recusado' },
                                                    ]
                                                }
                                                onChange={(name, value) => {
                                                    this.props.consultaLeads.status = value;
                                                    this.props.fnUpdateState(this.props);
                                                }}
                                                title="Status:"
                                                selectedValue={
                                                    (this.props.consultaLeads.status == null)
                                                        ? null
                                                        : this.props.consultaLeads.status.toString()
                                                }
                                            />
                                            <div className="form-group col-md-3">
                                                <label>Data inicial:</label>
                                                <DateTimePicker
                                                    onChange={(value) => {
                                                        this.props.consultaLeads.dataDe = value;
                                                        this.props.fnUpdateState(this.props);
                                                    }}
                                                    value={this.props.consultaLeads.dataDe}
                                                />
                                            </div>
                                            <div className="form-group col-md-3">
                                                <label>Data final:</label>
                                                <DateTimePicker
                                                    onChange={(value) => {
                                                        this.props.consultaLeads.dataAte = value;
                                                        this.props.fnUpdateState(this.props);
                                                    }}
                                                    value={this.props.consultaLeads.dataAte}
                                                />
                                            </div>
                                            <div className="form-group col-md-3" style={
                                                {
                                                    textAlign: 'right',
                                                    marginTop: '26px',
                                                }
                                            }>
                                                <BtnPesquisar
                                                    type="submit"
                                                    isLoading={this.props.consultaLeads.isLoading}
                                                    text=" Pesquisar"
                                                    textLoading=" Aguarde..."
                                                />
                                            </div>
                                        </Row>
                                    </Formsy>
                                </div>
                            </Panel.Body>
                        </Panel.Collapse>
                    </Panel>
                </Section>
                <Section>
                    {this.props.consultaLeads.requested
                        ? <div className="tile-body">
                            <div className="table-responsive">
                                <div className="dataTables_wrapper form-inline dt-bootstrap no-footer">
                                    <div className="dataTables_length" id="basic-usage_length">
                                        <InputSelect
                                            className=""
                                            data={[{ value: '10', text: '10' }, { value: '25', text: '25' }, { value: '50', text: '50' }]}
                                            onChange={(name, value) => {
                                                this.props.consultaLeads.pageSize = value;
                                                this.props.consultaLeads.pageNumber = 1;
                                                this.props.fnUpdateState(this.props);
                                                this.props.fnListaLeads();
                                            }}
                                            selectedValue={(this.props.consultaLeads.pageSize == null) ? null : this.props.consultaLeads.pageSize.toString()}
                                        /> registros por página
                            </div>
                                    <Row>
                                        <div className="col-sm-12">
                                            <table className="table table-custom dataTable no-footer">
                                                <thead>
                                                    <tr role="row">
                                                        <th></th>
                                                        <th>Canal</th>
                                                        <th>Veículo</th>
                                                        <th>Placa</th>
                                                        <th>Valor</th>
                                                        <th>Data Lead</th>
                                                        <th>Status</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        (this.props.consultaLeads.isLoading)
                                                            ? <tr key={0} className="odd">
                                                                <td colSpan={8} className="dataTables_empty">
                                                                    <div className="progress progress-striped active">
                                                                        <div className="progress-bar progress-bar-blue" role="progressbar" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100" style={{ width: "100%" }}>
                                                                            <span className="sr-only">100% Complete</span>
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                            : (this.props.consultaLeads.results.length == 0)
                                                                ? <tr key={0} className="odd">
                                                                    <td colSpan={8} className="dataTables_empty">
                                                                        <p className="text-warning">Não foram encontrados registros para a busca...</p>
                                                                    </td>
                                                                </tr>
                                                                : this.props.consultaLeads.results.map(function (rowData, index) {
                                                                    var jsonWebMotors = rowData.jsonProcessamento == '' || rowData.jsonProcessamento == null
                                                                        ? objGlobal.parseJsonReturn(rowData.jsonLead)
                                                                        : objGlobal.parseJsonReturn(rowData.jsonProcessamento)
                                                                    return (
                                                                        <tr key={index}>
                                                                            <td>
                                                                                <a onClick={() => {
                                                                                    objProps.modalDetalhes.showModal = true;
                                                                                    objProps.modalDetalhes.id = rowData.id;
                                                                                    objProps.modalDetalhes.idStatus = rowData.status;
                                                                                    objProps.modalDetalhes.lead = jsonWebMotors;
                                                                                    objProps.fnUpdateState(objProps);
                                                                                }}
                                                                                    style={{ cursor: 'pointer' }}>
                                                                                    <i className="fa fa-file"></i>
                                                                                </a>
                                                                            </td>
                                                                            <td>{jsonWebMotors.midia}</td>
                                                                            <td>{
                                                                                jsonWebMotors.marca
                                                                                + ' ' + jsonWebMotors.modelo
                                                                                + ' ' + jsonWebMotors.versao
                                                                            }</td>
                                                                            <td>{jsonWebMotors.placa}</td>
                                                                            <td><FormattedNumber value={jsonWebMotors.valor} style="decimal" minimumFractionDigits={2} maximumFractionDigits={2} /></td>
                                                                            <td><Moment format="DD/MM/YYYY HH:mm">{rowData.datahoraCadastro}</Moment></td>
                                                                            <td>{
                                                                                rowData.status == 1
                                                                                    ? <span className="label label-warning">em aberto</span>
                                                                                    : rowData.status == 2
                                                                                        ? rowData.jsonProcessamento == null
                                                                                            ? <span className="label label-primary">em processamento</span>
                                                                                            : <span className="label label-success">lead aceito</span>
                                                                                        : rowData.jsonProcessamento == null
                                                                                            ? <span className="label label-primary">em processamento</span>
                                                                                            : <span className="label label-danger">lead recusado</span>
                                                                            }</td>
                                                                        </tr>
                                                                    )
                                                                })
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                    </Row>
                                    <br />
                                    <Row>
                                        <div className="col-md-3">
                                            <div className="dataTables_info"> Mostrando&nbsp; {
                                                (this.props.consultaLeads.pageSize > this.props.consultaLeads.totalNumberOfRecords)
                                                    ? this.props.consultaLeads.totalNumberOfRecords : this.props.consultaLeads.pageSize
                                            } de {this.props.consultaLeads.totalNumberOfRecords}
                                            </div>
                                        </div>
                                        <div className="col-md-9">
                                            <div className="dataTables_paginate paging_simple_numbers">
                                                <ListPager
                                                    CurrentPage={this.props.consultaLeads.pageNumber}
                                                    Size={this.props.consultaLeads.totalNumberOfPages}
                                                    OnPageChanged={
                                                        (page) => {
                                                            this.props.consultaLeads.pageNumber = page;
                                                            this.props.fnListaLeads();
                                                        }
                                                    }
                                                />
                                            </div>
                                        </div>
                                    </Row>
                                </div>
                            </div>
                        </div>
                        : null
                    }
                </Section>
            </div>
            {this.props.modalDetalhes.showModal ? <ModalDetalhes /> : null}
        </Row>;
    }
}

export default connect(
    state => state.crm_webmotors,
    dispatch => bindActionCreators(actionCreators, dispatch),
    null, {}
)(ConsultaForm);