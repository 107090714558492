import * as React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actionCreators } from '../../../../../store/marketing/publicador/MarketingPublicadorConfig';

import { Row, Modal } from 'react-bootstrap';
import ProgerssBar from '../../../../commons/ProgressBar';
import AlertError from '../../../../commons/AlertError';
import AlertSuccess from '../../../../commons/AlertSuccess';
import BtnSalvar from '../../../../commons/BtnSalvar';
import BtnCancelar from '../../../../commons/BtnCancelar';

class ModalAutoShoppingPontal extends React.Component {
    constructor() {
        super();
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    async componentDidMount() { }

    async handleSubmit(e) {
        e.preventDefault();
        var autenticacao = {};

        if (this.props.configuracaoPortais.modalPortais.ativado) {
            await this.props.fnAtualizaConfiguracaoPortal(JSON.stringify(autenticacao), '');
            this.props.configuracaoPortais.modalPortais.modalAutoShoppingPontal.showModal = false;
        }
        else {
            await this.props.fnInsereConfiguracaoPortal(JSON.stringify(autenticacao), '');
            this.props.configuracaoPortais.modalPortais.modalAutoShoppingPontal.showModal = false;
        }
    }

    handleOnHide() {
        this.props.configuracaoPortais.modalPortais.modalAutoShoppingPontal.showModal = false;
        this.props.fnUpdateState(this.props);
    }

    render() {
        return <Modal
            show={this.props.configuracaoPortais.modalPortais.modalAutoShoppingPontal.showModal}
            onHide={this.handleOnHide.bind(this)}
        >
            <form
                id="frmModalPublicar"
                name="frmModalPublicar"
                role="form"
                onSubmit={this.handleSubmit}
                data-parsley-validate>
                <Modal.Header closeButton>
                    <h4 className="modal-title custom-font">Configurar Auto Shopping Pontal</h4>
                </Modal.Header>
                <Modal.Body>
                    <AlertSuccess
                        successText={this.props.configuracaoPortais.modalPortais.successText}
                        alertVisible={this.props.configuracaoPortais.modalPortais.successText != '' && this.props.configuracaoPortais.modalPortais.successText != undefined ? true : false}
                        onDismiss={(alertVisible, successText) => {
                            this.props.configuracaoPortais.modalPortais.successText = successText;
                            this.props.fnUpdateState(this.props);
                        }}
                    />
                    <AlertError
                        errorText={this.props.configuracaoPortais.modalPortais.errorText}
                        alertVisible={this.props.configuracaoPortais.modalPortais.errorText != '' && this.props.configuracaoPortais.modalPortais.errorText != undefined ? true : false}
                        onDismiss={(alertVisible, errorText) => {
                            this.props.configuracaoPortais.modalPortais.errorText = errorText;
                            this.props.fnUpdateState(this.props);
                        }}
                    />
                    {
                        this.props.configuracaoPortais.modalPortais.isLoading
                            ? <ProgerssBar />
                            : <Row>
                                <div className="col-md-12">
                                    <div className="alert alert-warning" role="alert">Prezado cliente a integração com o portal Auto Shopping Pontal é feita através de leitura de feed, por isso tennha certeza de que já possui uma conta criada neste portal para que seus anúncios possam ser lidos pelo serviço de leitura de feed do portal Auto Shopping Pontal. Qualquer dúvida entre em contato com nosso suporte para maiores esclarecimentos.</div>
                                </div>
                            </Row>
                    }
                </Modal.Body>
                <Modal.Footer>
                    {this.props.configuracaoPortais.modalPortais.ativado == true
                        ? <BtnCancelar
                            type="button"
                            text=" Remover Portal"
                            textLoading=" Aguarde..."
                            isLoading={this.props.configuracaoPortais.modalPortais.isLoading}
                            onClick={async () => {
                                await this.props.fnDeleteConfiguracaoPortal();
                                this.props.configuracaoPortais.modalPortais.modalAutoShoppingPontal.showModal = false;
                                this.props.fnUpdateState(this.props);
                            }}
                        />
                        : null}
                    {this.props.configuracaoPortais.modalPortais.ativado != true
                        ? <BtnSalvar
                            type="submit"
                            text={" Salvar Configuração"}
                            textLoading=" Aguarde..."
                            isLoading={this.props.configuracaoPortais.modalPortais.isLoading}
                        />
                        : null}
                </Modal.Footer>
            </form>
        </Modal>;
    }
}

export default connect(
    state => state.marketing_publicadorconfig,
    dispatch => bindActionCreators(actionCreators, dispatch),
    null, {}
)(ModalAutoShoppingPontal);