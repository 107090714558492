import * as React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actionCreators } from '../../../../store/marketing/publicador/MarketingPublicadorConfig';

import { Row } from 'react-bootstrap';
import ProgressBar from '../../../commons/ProgressBar';
import AlertError from '../../../commons/AlertError';
import Section from '../../../commons/Section';
import BtnSalvar from '../../../commons/BtnSalvar';
import SwitchOnOff from '../../../commons/SwitchOnOff';
import TextArea from '../../../commons/TextArea';

class ConfiguracaoAdicionaisForm extends React.Component {
    constructor() {
        super();
    }

    async componentDidMount() {
        this.props.fnBuscaConfiguracoesAdicionaisPublicador();
    }

    async salvarDados() {
        if (this.props.configuracoesAdicionais.id == 0 || this.props.configuracoesAdicionais.id == null)
            await this.props.fnInsereConfiguracoesAdicionaisPublicador();
        else
            await this.props.fnAtualizaConfiguracoesAdicionaisPublicador();

        this.props.fnBuscaConfiguracoesAdicionaisPublicador();
    }

    render() {
        return <div>
            {this.props.dadosAnunciante.token == ""
                ? null
                : <Row>
                    <div className="col-md-12">
                        <Section>
                            <div className="tile-header bg-blue dvd dvd-btm">
                                <h3 className="custom-font"><strong>Configurações Adicionais</strong></h3>
                            </div>
                            {
                                this.props.configuracoesAdicionais.isLoading
                                    ? <div className="tile-body nopadding">
                                        <Row>
                                            <div className="form-group col-md-12">
                                                <ProgressBar />
                                            </div>
                                        </Row>
                                    </div>
                                    : <div className="tile-body nopadding">
                                        <AlertError
                                            errorText={this.props.configuracoesAdicionais.errorText}
                                            alertVisible={this.props.configuracoesAdicionais.errorText != '' && this.props.configuracoesAdicionais.errorText != undefined ? true : false}
                                            onDismiss={(alertVisible, errorText) => {
                                                this.props.configuracoesAdicionais.errorText = errorText;
                                                this.props.fnUpdateState(this.props);
                                            }}
                                        />
                                        <Row>
                                            <div className="form-group col-md-4">
                                                <label>Ativar valor promocional (site da loja)?</label><br />
                                                <SwitchOnOff
                                                    class="onoffswitch-checkbox"
                                                    id="chkValorPromocional"
                                                    onChange={(name, value) => {
                                                        this.props.configuracoesAdicionais.valorPromocional = (value == 'on' ? false : true);
                                                        this.props.fnUpdateState(this.props);
                                                    }}
                                                    value={this.props.configuracoesAdicionais.valorPromocional == true ? 'on' : 'off'}
                                                />
                                            </div>
                                            <div className="form-group col-md-4">
                                                <label>Remover veículo automaticamente após a venda?</label><br />
                                                <SwitchOnOff
                                                    class="onoffswitch-checkbox"
                                                    id="chkRemocaoAutomatica"
                                                    onChange={(name, value) => {
                                                        this.props.configuracoesAdicionais.remocaoAutomatica = (value == 'on' ? false : true);
                                                        this.props.fnUpdateState(this.props);
                                                    }}
                                                    value={this.props.configuracoesAdicionais.remocaoAutomatica == true ? 'on' : 'off'}
                                                />
                                            </div>
                                            <div className="form-group col-md-12">
                                                <label>Descrição do anúncio (Padrão)</label>
                                                <TextArea
                                                    class="form-control"
                                                    placeholder="Informe a descrição do anúncio"
                                                    rows={10}
                                                    onChange={(name, value) => {
                                                        this.props.configuracoesAdicionais.textoPadrao = value;
                                                        this.props.fnUpdateState(this.props);
                                                    }}
                                                    value={this.props.configuracoesAdicionais.textoPadrao}
                                                />
                                            </div>
                                        </Row>
                                        <Row>
                                            <div className="form-group col-md-12 text-right">
                                                <BtnSalvar
                                                    type="button"
                                                    text={this.props.configuracoesAdicionais.id == 0 || this.props.configuracoesAdicionais.id == null
                                                        ? " Salvar Configurações Adicionais"
                                                        : " Alterar Configurações Adicionais"}
                                                    textLoading=" Aguarde..."
                                                    isLoading={false}
                                                    onClick={() => { this.salvarDados(); }}
                                                />
                                            </div>
                                        </Row>
                                    </div>
                            }
                        </Section>
                    </div>
                </Row>
            }
        </div>;
    }
}

export default connect(
    state => state.marketing_publicadorconfig,
    dispatch => bindActionCreators(actionCreators, dispatch),
    null,
    { pure: false }
)(ConfiguracaoAdicionaisForm);