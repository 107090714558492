import * as React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actionCreators } from '../../../store/edoc/EdocConsulta';

import Formsy from 'formsy-react';
import { Row, Panel } from 'react-bootstrap';
import Moment from 'react-moment';
import AlertError from '../../commons/AlertError';
import Section from '../../commons/Section';
import InputText from '../../commons/InputText';
import BtnPesquisar from '../../commons/BtnPesquisar';
import InputSelect from '../../commons/InputSelect';
import MaskedInput from '../../commons/MaskedInput';
import ListPager from '../../commons/ListPager';
import ModalImagens from './ModalImagens';
import ModalUpload from './ModalUpload';

class ConsultaForm extends React.Component {
    constructor(props) {
        super(props);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.verificaPermissao = this.verificaPermissao.bind(this);      
    }

    componentDidMount() {
        this.props.listaCategorias();
        this.props.listaStatus();
    }

    handleSubmit(e) {
        this.props.edoc_consulta.consultaVeiculos.pageNumber = 1;
        this.props.edoc_consulta.consultaVeiculos.pageSize = 10;
        this.props.listaVeiculos();
    }

    verificaPermissao(modulo) {
        var boolReturn = false;
        JSON.parse(this.props.navmenu.modulos).forEach(function (entry) {
            if (entry.toString() === modulo.toString()) {
                boolReturn = true;
            }
        });

        return boolReturn;
    }

    



    render() {
        var objProps = this.props;       

        var dropdownCategorias = [];
        dropdownCategorias.push({ value: 0, text: 'Selecione a Categoria' });
        objProps.edoc_consulta.consultaVeiculos.dropdownCategorias.results.forEach(function (entry) {
            dropdownCategorias.push({ value: entry.id.toString(), text: entry.nome });
        });

        var dropdownMarcas = [];
        dropdownMarcas.push({ value: 0, text: 'Selecione a Marca' });
        objProps.edoc_consulta.consultaVeiculos.dropdownMarcas.results.forEach(function (entry) {
            dropdownMarcas.push({ value: entry.id.toString(), text: entry.nome });
        });

        var dropdownModelos = [];
        dropdownModelos.push({ value: 0, text: 'Selecione o Modelo' });
        objProps.edoc_consulta.consultaVeiculos.dropdownModelos.results.forEach(function (entry) {
            dropdownModelos.push({ value: entry.id.toString(), text: entry.nome });
        });

        var dropdownStatus = [];
        objProps.edoc_consulta.consultaVeiculos.dropdownStatus.results.forEach(function (entry) {
            dropdownStatus.push({ value: entry.id.toString(), text: entry.nome });
        });

        const renderRows = (rowData, index) => {

            return (
                <tr key={index}>
                    <td>
                        {
                            this.verificaPermissao(169) ?
                                <a onClick={() => {
                                    this.props.edoc_consulta.modalUpload.showModal = true;
                                    this.props.edoc_consulta.modalUpload.anoFabricacao = rowData.anoFabricacao;
                                    this.props.edoc_consulta.modalUpload.anoModelo = rowData.anoModelo;
                                    this.props.edoc_consulta.modalUpload.chassi = rowData.chassi;
                                    this.props.edoc_consulta.modalUpload.dataCadastro = rowData.dataCadastro;
                                    this.props.edoc_consulta.modalUpload.idVeiculo = rowData.idVeiculo;
                                    this.props.edoc_consulta.modalUpload.nomeMarca = rowData.nomeMarca;
                                    this.props.edoc_consulta.modalUpload.nomeModelo = rowData.nomeModelo;
                                    this.props.edoc_consulta.modalUpload.nomeVersao = rowData.nomeVersao;
                                    this.props.edoc_consulta.modalUpload.numEnvelope = rowData.numEnvelope;
                                    this.props.edoc_consulta.modalUpload.placa = rowData.placa;
                                    this.props.edoc_consulta.modalUpload.status = rowData.status;
                                    this.props.edoc_consulta.modalUpload.errorText = '';
                                    this.props.edoc_consulta.modalUpload.successText = '';
                                    this.props.fnUpdateState(this.props.edoc_consulta);
                                }}
                                    style={{ cursor: 'pointer' }}>
                                    <i className="fa fa-upload"></i>
                                </a>
                                : null
                        }
                    </td>
                    <td>
                        <a onClick={() => {
                            this.props.edoc_consulta.modalImagens.showModal = true;
                            this.props.edoc_consulta.modalImagens.anoFabricacao = rowData.anoFabricacao;
                            this.props.edoc_consulta.modalImagens.anoModelo = rowData.anoModelo;
                            this.props.edoc_consulta.modalImagens.chassi = rowData.chassi;
                            this.props.edoc_consulta.modalImagens.dataCadastro = rowData.dataCadastro;
                            this.props.edoc_consulta.modalImagens.idVeiculo = rowData.idVeiculo;
                            this.props.edoc_consulta.modalImagens.nomeMarca = rowData.nomeMarca;
                            this.props.edoc_consulta.modalImagens.nomeModelo = rowData.nomeModelo;
                            this.props.edoc_consulta.modalImagens.nomeVersao = rowData.nomeVersao;
                            this.props.edoc_consulta.modalImagens.numEnvelope = rowData.numEnvelope;
                            this.props.edoc_consulta.modalImagens.placa = rowData.placa;
                            this.props.edoc_consulta.modalImagens.status = rowData.status;
                            this.props.fnUpdateState(this.props.edoc_consulta);
                        }}
                            style={{ cursor: 'pointer' }}>
                            <i className="fa fa-envelope"></i>
                        </a>
                    </td>
                    <td>{rowData.placa}</td>
                    <td>{rowData.chassi}</td>
                    <td>{rowData.nomeMarca + ' ' + rowData.nomeVersao + " (" + rowData.anoFabricacao.toString() + '/' + rowData.anoModelo.toString() + ")"}</td>
                    <td><Moment format="DD/MM/YYYY">{rowData.dataCadastro}</Moment></td>
                    <td>{rowData.numEnvelope}</td>
                    <td>
                        <span className={rowData.status == "Vendido" ? "label label-success" : rowData.status == "Cancelado" ? "label label-danger" : "label label-primary"}>{rowData.status}</span>
                    </td>
                </tr>
            )

        }


        return <div>
            <Section>
                <Panel id="panelFiltro" bsStyle="primary" defaultExpanded>
                    <Panel.Heading>
                        <Panel.Title componentClass="h3" toggle><strong>Consulta</strong> de documentos armazenados</Panel.Title>
                    </Panel.Heading>
                    <Panel.Collapse>
                        <Panel.Body>
                            <div className="tile-body nopadding">
                                <AlertError
                                    errorText={this.props.edoc_consulta.consultaVeiculos.errorText}
                                    alertVisible={this.props.edoc_consulta.consultaVeiculos.errorText != '' && this.props.edoc_consulta.consultaVeiculos.errorText != undefined ? true : false}
                                    onDismiss={(alertVisible, errorText) => {
                                        this.props.edoc_consulta.consultaVeiculos.errorText = errorText;
                                        this.props.fnUpdateState(this.props.edoc_consulta);
                                    }}
                                />
                                <Formsy onValidSubmit={this.handleSubmit}>
                                    <Row>
                                        <div className="form-group col-md-3">
                                            <label>Placa:</label>
                                            <MaskedInput
                                                mask="#######"
                                                placeholder="Ex. XXXXXXX"
                                                placeholderChar=" "
                                                className="form-control"
                                                onChange={(name, value) => {
                                                    this.props.edoc_consulta.consultaVeiculos.placa = value;
                                                    this.props.fnUpdateState(this.props.edoc_consulta);
                                                }}
                                                value={this.props.edoc_consulta.consultaVeiculos.placa}
                                            />
                                        </div>
                                        <div className="form-group col-md-3">
                                            <label>Chassi:</label>
                                            <MaskedInput
                                                mask="#################"
                                                placeholder="Ex. 9BD781CD87TGD9876"
                                                placeholderChar=" "
                                                className="form-control"
                                                onChange={(name, value) => {
                                                    this.props.edoc_consulta.consultaVeiculos.chassi = value;
                                                    this.props.fnUpdateState(this.props.edoc_consulta);
                                                }}
                                                value={this.props.edoc_consulta.consultaVeiculos.chassi}
                                            />
                                        </div>
                                        <InputText
                                            name="txtNumeroEnvelope"
                                            type="text"
                                            className="col-md-3"
                                            placeholder="Ex. 10500"
                                            onChange={(name, value) => {
                                                this.props.edoc_consulta.consultaVeiculos.numEnvelope = value;
                                                this.props.fnUpdateState(this.props.edoc_consulta);
                                            }}
                                            value={this.props.edoc_consulta.consultaVeiculos.numEnvelope}
                                            title="Número envelope:"
                                        />
                                        <InputSelect
                                            name="ddlCategoria"
                                            className="col-md-3"
                                            data={
                                                this.props.edoc_consulta.consultaVeiculos.dropdownCategorias.isLoading
                                                    ? [{ value: 0, text: 'Carregando...' }]
                                                    : this.props.edoc_consulta.consultaVeiculos.dropdownCategorias.errorText != ''
                                                        ? [{ value: 0, text: this.props.edoc_consulta.consultaVeiculos.dropdownCategorias.errorText }]
                                                        : dropdownCategorias
                                            }
                                            onChange={(name, value) => {
                                                this.props.edoc_consulta.consultaVeiculos.categoria = value;
                                                if (value != 0) {
                                                    this.props.listaMarcas(value);
                                                    this.props.edoc_consulta.consultaVeiculos.dropdownModelos.isLoading = false;
                                                    this.props.edoc_consulta.consultaVeiculos.dropdownModelos.errorText = '';
                                                    this.props.edoc_consulta.consultaVeiculos.dropdownModelos.results = [];
                                                    this.props.edoc_consulta.consultaVeiculos.marca = 0;
                                                    this.props.edoc_consulta.consultaVeiculos.modelo = 0;
                                                }
                                                else {
                                                    this.props.edoc_consulta.consultaVeiculos.dropdownMarcas.isLoading = false;
                                                    this.props.edoc_consulta.consultaVeiculos.dropdownMarcas.errorText = '';
                                                    this.props.edoc_consulta.consultaVeiculos.dropdownMarcas.results = [];
                                                    this.props.edoc_consulta.consultaVeiculos.marca = 0;
                                                    this.props.edoc_consulta.consultaVeiculos.dropdownModelos.isLoading = false;
                                                    this.props.edoc_consulta.consultaVeiculos.dropdownModelos.errorText = '';
                                                    this.props.edoc_consulta.consultaVeiculos.dropdownModelos.results = [];
                                                    this.props.edoc_consulta.consultaVeiculos.modelo = 0;
                                                };
                                                this.props.fnUpdateState(this.props.edoc_consulta);
                                            }}
                                            title="Categoria:"
                                            disabled={this.props.edoc_consulta.consultaVeiculos.dropdownCategorias.isLoading ? true : false}
                                            selectedValue={(this.props.edoc_consulta.consultaVeiculos.categoria === null) ? null : this.props.edoc_consulta.consultaVeiculos.categoria.toString()}
                                        />
                                        <InputSelect
                                            name="ddlMarca"
                                            className="col-md-3"
                                            data={
                                                this.props.edoc_consulta.consultaVeiculos.dropdownMarcas.isLoading
                                                    ? [{ value: 0, text: 'Carregando...' }]
                                                    : this.props.edoc_consulta.consultaVeiculos.dropdownMarcas.errorText != ''
                                                        ? [{ value: 0, text: this.props.edoc_consulta.consultaVeiculos.dropdownMarcas.errorText }]
                                                        : dropdownMarcas
                                            }
                                            onChange={(name, value) => {
                                                this.props.edoc_consulta.consultaVeiculos.marca = value;
                                                if (value != 0) {
                                                    this.props.listaModelos(this.props.edoc_consulta.consultaVeiculos.categoria, value);
                                                }
                                                else {
                                                    this.props.edoc_consulta.consultaVeiculos.dropdownModelos.isLoading = false;
                                                    this.props.edoc_consulta.consultaVeiculos.dropdownModelos.errorText = '';
                                                    this.props.edoc_consulta.consultaVeiculos.dropdownModelos.results = [];
                                                    this.props.edoc_consulta.consultaVeiculos.modelo = 0;
                                                };
                                                this.props.fnUpdateState(this.props.edoc_consulta);
                                            }}
                                            title="Marca:"
                                            disabled={
                                                this.props.edoc_consulta.consultaVeiculos.categoria === 0
                                                    ? true
                                                    : this.props.edoc_consulta.consultaVeiculos.dropdownMarcas.isLoading
                                                        ? true
                                                        : false
                                            }
                                            selectedValue={(this.props.edoc_consulta.consultaVeiculos.marca == null) ? null : this.props.edoc_consulta.consultaVeiculos.marca.toString()}
                                        />
                                        <InputSelect
                                            name="ddlModelo"
                                            className="col-md-3"
                                            data={
                                                this.props.edoc_consulta.consultaVeiculos.dropdownModelos.isLoading
                                                    ? [{ value: 0, text: 'Carregando...' }]
                                                    : this.props.edoc_consulta.consultaVeiculos.dropdownModelos.errorText != ''
                                                        ? [{ value: 0, text: this.props.edoc_consulta.consultaVeiculos.dropdownModelos.errorText }]
                                                        : dropdownModelos
                                            }
                                            onChange={(name, value) => {
                                                this.props.edoc_consulta.consultaVeiculos.modelo = value;
                                                this.props.fnUpdateState(this.props.edoc_consulta);
                                            }}
                                            title="Modelo:"
                                            disabled={
                                                this.props.edoc_consulta.consultaVeiculos.marca == 0
                                                    ? true
                                                    : this.props.edoc_consulta.consultaVeiculos.dropdownModelos.isLoading
                                                        ? true
                                                        : false
                                            }
                                            selectedValue={(this.props.edoc_consulta.consultaVeiculos.modelo == null) ? null : this.props.edoc_consulta.consultaVeiculos.modelo.toString()}
                                        />
                                        <InputSelect
                                            name="ddlStatus"
                                            className="col-md-3"
                                            data={
                                                this.props.edoc_consulta.consultaVeiculos.dropdownStatus.isLoading
                                                    ? [{ value: 0, text: 'Carregando...' }]
                                                    : this.props.edoc_consulta.consultaVeiculos.dropdownStatus.errorText != ''
                                                        ? [{ value: 0, text: this.props.edoc_consulta.consultaVeiculos.dropdownStatus.errorText }]
                                                        : dropdownStatus
                                            }
                                            onChange={(name, value) => {
                                                this.props.edoc_consulta.consultaVeiculos.status = value;
                                                this.props.fnUpdateState(this.props.edoc_consulta);
                                            }}
                                            title="Status:"
                                            disabled={this.props.edoc_consulta.consultaVeiculos.dropdownStatus.isLoading ? true : false}
                                            selectedValue={(this.props.edoc_consulta.consultaVeiculos.status == null) ? null : this.props.edoc_consulta.consultaVeiculos.status.toString()}
                                        />
                                        <div className="form-group col-md-3" style={{ textAlign: 'right', marginTop: '23px' }}>
                                            <BtnPesquisar
                                                type="submit"
                                                isLoading={this.props.edoc_consulta.consultaVeiculos.isLoading}
                                                text=" Consultar Veículos"
                                                textLoading=" Aguarde..."
                                            />
                                        </div>
                                    </Row>
                                </Formsy>
                            </div>
                        </Panel.Body>
                    </Panel.Collapse>
                </Panel>
            </Section>
            <Section>
                {this.props.edoc_consulta.consultaVeiculos.requested
                    ? <div className="tile-body">
                        <div className="table-responsive">
                            <div className="dataTables_wrapper form-inline dt-bootstrap no-footer">
                                <div className="dataTables_length" id="basic-usage_length">
                                    <InputSelect
                                        className=""
                                        data={[{ value: '10', text: '10' }, { value: '25', text: '25' }, { value: '50', text: '50' }]}
                                        onChange={(name, value) => {
                                            this.props.edoc_consulta.consultaVeiculos.pageSize = value;
                                            this.props.edoc_consulta.consultaVeiculos.pageNumber = 1;
                                            this.props.fnUpdateState(this.props.edoc_consulta);
                                            this.props.listaVeiculos();
                                        }}
                                        selectedValue={(this.props.edoc_consulta.consultaVeiculos.pageSize == null) ? null : this.props.edoc_consulta.consultaVeiculos.pageSize.toString()}
                                    /> registros por página
                            </div>
                                <Row>
                                    <div className="col-sm-12">
                                        <table className="table table-custom dataTable no-footer">
                                            <thead>
                                                <tr role="row">
                                                    <th></th>
                                                    <th></th>
                                                    <th>Placa</th>
                                                    <th>Chassi</th>
                                                    <th>Veículo</th>
                                                    <th>Data Cadastro</th>
                                                    <th>Num. Envelope</th>
                                                    <th>Status</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    (this.props.edoc_consulta.consultaVeiculos.isLoading)
                                                        ? <tr key={0} className="odd">
                                                            <td colSpan={8} className="dataTables_empty">
                                                                <div className="progress progress-striped active">
                                                                    <div className="progress-bar progress-bar-blue" role="progressbar" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100" style={{ width: "100%" }}>
                                                                        <span className="sr-only">100% Complete</span>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                        : (this.props.edoc_consulta.consultaVeiculos.results.length == 0)
                                                            ? <tr key={0} className="odd">
                                                                <td colSpan={8} className="dataTables_empty">
                                                                    <p className="text-warning">Não foram encontrados registros para a busca...</p>
                                                                </td>
                                                            </tr>
                                                            : this.props.edoc_consulta.consultaVeiculos.results.map(renderRows)
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </Row>
                                <br />
                                <Row>
                                    <div className="col-md-3">
                                        <div className="dataTables_info"> Mostrando&nbsp; {
                                            (this.props.edoc_consulta.consultaVeiculos.pageSize > this.props.edoc_consulta.consultaVeiculos.totalNumberOfRecords)
                                                ? this.props.edoc_consulta.consultaVeiculos.totalNumberOfRecords : this.props.edoc_consulta.consultaVeiculos.pageSize
                                        } de {this.props.edoc_consulta.consultaVeiculos.totalNumberOfRecords}
                                        </div>
                                    </div>
                                    <div className="col-md-9">
                                        <div className="dataTables_paginate paging_simple_numbers">
                                            <ListPager
                                                CurrentPage={this.props.edoc_consulta.consultaVeiculos.pageNumber}
                                                Size={this.props.edoc_consulta.consultaVeiculos.totalNumberOfPages}
                                                OnPageChanged={
                                                    (page) => {
                                                        this.props.edoc_consulta.consultaVeiculos.pageNumber = page;
                                                        this.props.listaVeiculos();
                                                    }
                                                }
                                            />
                                        </div>
                                    </div>
                                </Row>
                            </div>
                        </div>
                    </div>
                    : null
                }
            </Section>
            {this.props.edoc_consulta.modalImagens.showModal ? <ModalImagens /> : null}
            {this.props.edoc_consulta.modalUpload.showModal ? <ModalUpload /> : null}
        </div>;
    }
}

function mapStateToProps(state) {
    return {
        ...state,
        navmenu: state.navmenu,
        edoc_consulta: state.edoc_consulta
    }
}
export default connect(
    mapStateToProps,
    dispatch => bindActionCreators(actionCreators, dispatch),
    null, { pure: false }
)(ConsultaForm);