import * as React from "react";

export default class Section extends React.Component {
    render() {
        return (
            <section className={(this.props.class === undefined) ? 'tile time-simple' : this.props.class}>
                {this.props.children}
            </section>
        );
    }
}