import { fetch } from 'domain-task';
import * as daypaghistorico_service from '../../../services/documentos/daypag/daypaghistorico_service';

const UPDATE_STATE_DAYPAG_HISTORICO_ACTION = 'UPDATE_STATE_DAYPAG_HISTORICO_ACTION';
const REQUEST_HISTORICO_PAGAMENTOS_ACTION = 'REQUEST_HISTORICO_PAGAMENTOS_ACTION';
const RESPONSE_HISTORICO_PAGAMENTOS_ACTION = 'RESPONSE_HISTORICO_PAGAMENTOS_ACTION';
const ERROR_HISTORICO_PAGAMENTOS_ACTION = 'ERROR_HISTORICO_PAGAMENTOS_ACTION';
const REQUEST_RECIBO_ACTION = 'REQUEST_RECIBO_ACTION';
const RESPONSE_RECIBO_ACTION = 'RESPONSE_RECIBO_ACTION';
const ERROR_RECIBO_ACTION = 'ERROR_RECIBO_ACTION';

const initialState = {
    historicoPagamentos: {
        isLoading: false,
        errorText: '',
        dataDe: '',
        dataAte: '',
        results: []
    },
    modalRecibo: {
        errorText: '',
        isLoading: false,
        showModal: false,
        codRecibo: '',
        pdf: ''
    }
};

export const actionCreators = {
    fnUpdateState: (pDaypagHistoricoState) => async (dispatch, getState) => {
        dispatch({
            type: 'UPDATE_STATE_DAYPAG_HISTORICO_ACTION',
            historicoPagamentos: {
                isLoading: pDaypagHistoricoState.historicoPagamentos.isLoading,
                errorText: pDaypagHistoricoState.historicoPagamentos.errorText,
                dataDe: pDaypagHistoricoState.historicoPagamentos.dataDe,
                dataAte: pDaypagHistoricoState.historicoPagamentos.dataAte,
                results: pDaypagHistoricoState.historicoPagamentos.results
            },
            modalRecibo: {
                errorText: pDaypagHistoricoState.modalRecibo.errorText,
                isLoading: pDaypagHistoricoState.modalRecibo.isLoading,
                showModal: pDaypagHistoricoState.modalRecibo.showModal,
                codRecibo: pDaypagHistoricoState.modalRecibo.codRecibo,
                pdf: pDaypagHistoricoState.modalRecibo.pdf
            }
        });
    },
    fnListaHistoricoPagamentos: () => async (dispatch, getState) => {
        dispatch({ type: 'REQUEST_HISTORICO_PAGAMENTOS_ACTION' });

        await fetch(daypaghistorico_service.services.listaHistoricoPagamentos(getState().auth.authorizationToken))
            .then(response => {
                if (response.status === 200 || response.status === 0) {
                    return response;
                }
                else {
                    return response.json().then(function (object) {
                        return Promise.reject(new Error(object));
                    })
                }
            })
            .then(data => {
                data.json().then(function (object) {
                    console.log(object);
                    dispatch({ type: 'RESPONSE_HISTORICO_PAGAMENTOS_ACTION', results: object });
                });
            }).catch(function (err) {
                dispatch({ type: 'ERROR_HISTORICO_PAGAMENTOS_ACTION', errorText: err.toString() });
            });
    },
    fnBuscaRecibo: () => async (dispatch, getState) => {
        var dto = {
            idUsuario: "1",
            tokenCliente: "pItRnemCfitpFgjHGhGESA==",
            CodRecibo: getState().daypag_historico.modalRecibo.codRecibo
        };

        dispatch({ type: 'REQUEST_RECIBO_ACTION' });

        await fetch(daypaghistorico_service.services.obterRecibo(dto, getState().auth.authorizationToken))
            .then(response => {
                if (response.status === 200 || response.status === 0) { return response; }
                else { return response.json().then(function (object) { return Promise.reject(new Error(object)); }) }
            })
            .then(data => {
                data.json().then(function (object) {
                    dispatch({ type: 'RESPONSE_RECIBO_ACTION', pdf: object.Pdf });
                });
            }).catch(function (err) {
                dispatch({ type: 'ERROR_RECIBO_ACTION', errorText: err.toString() });
            });
    }
};

export const reducer = (state, action) => {
    state = state || initialState;



    if (action.type === UPDATE_STATE_DAYPAG_HISTORICO_ACTION) {
        return {
            ...state,
            historicoPagamentos: action.historicoPagamentos,
            modalRecibo: action.modalRecibo
        };
    }



    if (action.type === REQUEST_HISTORICO_PAGAMENTOS_ACTION) {
        return {
            ...state,
            historicoPagamentos: {
                ...state.historicoPagamentos,
                errorText: '',
                isLoading: true,
                results: []
            }
        };
    }
    if (action.type === RESPONSE_HISTORICO_PAGAMENTOS_ACTION) {
        return {
            ...state,
            historicoPagamentos: {
                ...state.historicoPagamentos,
                isLoading: false,
                results: action.results
            }
        };
    }
    if (action.type === ERROR_HISTORICO_PAGAMENTOS_ACTION) {
        return {
            ...state,
            historicoPagamentos: {
                ...state.historicoPagamentos,
                errorText: action.errorText,
                isLoading: false
            }
        };
    }



    if (action.type === REQUEST_RECIBO_ACTION) {
        return {
            ...state,
            modalRecibo: {
                ...state.modalRecibo,
                errorText: '',
                isLoading: true
            }
        };
    }
    if (action.type === RESPONSE_RECIBO_ACTION) {
        return {
            ...state,
            modalRecibo: {
                ...state.modalRecibo,
                isLoading: false,
                pdf: action.pdf
            }
        };
    }
    if (action.type === ERROR_RECIBO_ACTION) {
        return {
            ...state,
            modalRecibo: {
                ...state.modalRecibo,
                isLoading: false,
                errorText: action.errorText
            }
        };
    }



    return state;
};

