import * as React from 'react';
import { Alert } from 'react-bootstrap';

export default class AlertError extends React.Component {
    constructor() {
        super();
        this.state = {
            alertVisible: false,
            errorText: ''
        };
    }

    componentWillMount() {
        this.setState({ alertVisible: this.props.alertVisible, errorText: this.props.errorText });
    }

    componentWillReceiveProps(nextProps) {
        this.setState({ alertVisible: nextProps.alertVisible, errorText: nextProps.errorText });
    }

    handleAlertDismiss() {
        this.setState({ alertVisible: false, errorText: '' });
        this.props.onDismiss(false, '');
    }

    render() {
        return (this.state.alertVisible ?
            <Alert bsStyle="danger" onDismiss={this.handleAlertDismiss.bind(this)}>
                <h4>Ops algo deu errado!</h4>
                <p>{this.state.errorText}</p>
            </Alert>
            : null);
    }
}