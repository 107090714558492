import * as React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actionCreators } from '../../../../store/marketing/publicador/MarketingPublicadorConfig';

import { Row, Tooltip, OverlayTrigger } from 'react-bootstrap';
import AlertError from '../../../commons/AlertError';
import Section from '../../../commons/Section';

// Importa os modais
import ModalICarros from './modalportais/ModalICarros';
import ModalMeuCarroNovo from './modalportais/ModalMeuCarroNovo';
import ModalWebMotors from './modalportais/ModalWebMotors';
import ModalOLX from './modalportais/ModalOLX';
import ModalAutoLine from './modalportais/ModalAutoLine';
import ModalMercadoLivre from './modalportais/ModalMercadoLivre';
import ModalCarroSaoJose from './modalportais/ModalCarroSaoJose';
import ModalCarroBrasil from './modalportais/ModalCarroBrasil';
import ModalNewMotors from './modalportais/ModalNewMotors';
import ModalTemUsados from './modalportais/ModalTemUsados';
import ModalCompreCar from './modalportais/ModalCompreCar';
import ModalAutoShoppingGlobal from './modalportais/ModalAutoShoppingGlobal';
import ModalValeVeiculos from './modalportais/ModalValeVeiculos';
import ModalAutoShoppingPontal from './modalportais/ModalAutoShoppingPontal';
import ModalSiteCliente from './modalportais/ModalSiteCliente';
import ModalFacebook from './modalportais/ModalFacebook';
import ModalAutoShoppingImigrantes from './modalportais/ModalAutoShoppingImigrantes';
import ModalMobiAuto from './modalportais/ModalMobiAuto';
import ModalCompreBlindados from './modalportais/ModalCompreBlindados';
import ModalUsadosBR from './modalportais/ModalUsadosBR';

class ConfiguracaoPortaisForm extends React.Component {
    constructor(props) {
        super(props);
    }

    async componentDidMount() {
        await this.props.fnListaPortaisCredenciados();
    }

    render() {
        var objProps = this.props;
        return <div>
            {this.props.dadosAnunciante.token == ""
                ? null
                : <Row>
                    <div className="col-md-12">
                        <Section>
                            <div className="tile-header bg-blue dvd dvd-btm">
                                <h3 className="custom-font"><strong>Configuração dos portais para o Integrador</strong></h3>
                            </div>
                            <div className="tile-body nopadding">
                                <AlertError
                                    errorText={this.props.dadosAnunciante.errorText}
                                    alertVisible={this.props.dadosAnunciante.errorText != '' && this.props.dadosAnunciante.errorText != undefined ? true : false}
                                    onDismiss={(alertVisible, errorText) => {
                                        this.props.dadosAnunciante.errorText = errorText;
                                        this.props.fnUpdateState(this.props);
                                    }}
                                />
                                <Row>
                                    <div className="form-group col-md-12">
                                        <div className="table-responsive">
                                            <table className="table table-custom cabra-table" id="basic-usage">
                                                <tbody>
                                                    <tr>
                                                        {
                                                            this.props.configuracaoPortais.portaisCredenciados.length > 0
                                                                ? this.props.configuracaoPortais.portaisCredenciados.map(function (entry,index) {
                                                                    return (
                                                                        entry.id == 1
                                                                            // ICarros
                                                                            ? <td
                                                                                style={{ textAlign: 'center' }}
                                                                                key={entry.id}>
                                                                                <OverlayTrigger
                                                                                    placement="top"
                                                                                    overlay={<Tooltip id="tooltip"><strong>{entry.nome}</strong></Tooltip>}>
                                                                                    <a onClick={() => {
                                                                                        objProps.configuracaoPortais.modalPortais.idPublicador = entry.id;
                                                                                        objProps.configuracaoPortais.modalPortais.modalICarros.showModal = true;
                                                                                        if (entry.idAtivado != null)
                                                                                            objProps.configuracaoPortais.modalPortais.ativado = true;
                                                                                        else
                                                                                            objProps.configuracaoPortais.modalPortais.ativado = false;
                                                                                        objProps.fnUpdateState(objProps);
                                                                                    }}>
                                                                                        <img
                                                                                            src={
                                                                                                entry.idAtivado == null
                                                                                                    ? require('../../../../assets/images/icones_portais/icarros-alpha.png').toString()
                                                                                                    : require('../../../../assets/images/icones_portais/icarros.png').toString()
                                                                                            }
                                                                                        />
                                                                                    </a>
                                                                                </OverlayTrigger>
                                                                            </td>
                                                                            : entry.id == 2
                                                                                // MeuCarroNovo
                                                                                ? <td
                                                                                    style={{ textAlign: 'center' }}
                                                                                    key={entry.id}>
                                                                                    <OverlayTrigger
                                                                                        placement="top"
                                                                                        overlay={<Tooltip id="tooltip"><strong>Na Pista</strong></Tooltip>}>
                                                                                        <a onClick={() => {
                                                                                            objProps.configuracaoPortais.modalPortais.idPublicador = entry.id;
                                                                                            objProps.configuracaoPortais.modalPortais.modalMeuCarroNovo.showModal = true;
                                                                                            if (entry.idAtivado != null)
                                                                                                objProps.configuracaoPortais.modalPortais.ativado = true;
                                                                                            else
                                                                                                objProps.configuracaoPortais.modalPortais.ativado = false;
                                                                                            objProps.fnUpdateState(objProps);
                                                                                        }}>
                                                                                            <img
                                                                                                src={
                                                                                                    entry.idAtivado == null
                                                                                                        ? require('../../../../assets/images/icones_portais/napista-alpha.png').toString()
                                                                                                        : require('../../../../assets/images/icones_portais/napista.png').toString()
                                                                                                }
                                                                                            />
                                                                                        </a>
                                                                                    </OverlayTrigger>
                                                                                </td>
                                                                                : entry.id == 3
                                                                                    // WebMotors
                                                                                    ? <td
                                                                                        style={{ textAlign: 'center' }}
                                                                                        key={entry.id}>
                                                                                        <OverlayTrigger
                                                                                            placement="top"
                                                                                            overlay={<Tooltip id="tooltip"><strong>{entry.nome}</strong></Tooltip>}>
                                                                                            <a onClick={() => {
                                                                                                objProps.configuracaoPortais.modalPortais.idPublicador = entry.id;
                                                                                                objProps.configuracaoPortais.modalPortais.modalWebMotors.showModal = true;
                                                                                                if (entry.idAtivado != null)
                                                                                                    objProps.configuracaoPortais.modalPortais.ativado = true;
                                                                                                else
                                                                                                    objProps.configuracaoPortais.modalPortais.ativado = false;
                                                                                                objProps.fnUpdateState(objProps);
                                                                                            }}>
                                                                                                <img
                                                                                                    src={
                                                                                                        entry.idAtivado == null
                                                                                                            ? require('../../../../assets/images/icones_portais/webmotors-alpha.png').toString()
                                                                                                            : require('../../../../assets/images/icones_portais/webmotors.png').toString()
                                                                                                    }
                                                                                                />
                                                                                            </a>
                                                                                        </OverlayTrigger>
                                                                                    </td>
                                                                                    : entry.id == 4
                                                                                        // OLX
                                                                                        ? <td
                                                                                            style={{ textAlign: 'center' }}
                                                                                            key={entry.id}>
                                                                                            <OverlayTrigger
                                                                                                placement="top"
                                                                                                overlay={<Tooltip id="tooltip"><strong>{entry.nome}</strong></Tooltip>}>
                                                                                                <a onClick={() => {
                                                                                                    objProps.configuracaoPortais.modalPortais.idPublicador = entry.id;
                                                                                                    objProps.configuracaoPortais.modalPortais.modalOLX.showModal = true;
                                                                                                    if (entry.idAtivado != null)
                                                                                                        objProps.configuracaoPortais.modalPortais.ativado = true;
                                                                                                    else
                                                                                                        objProps.configuracaoPortais.modalPortais.ativado = false;
                                                                                                    objProps.fnUpdateState(objProps);
                                                                                                }}>
                                                                                                    <img
                                                                                                        src={
                                                                                                            entry.idAtivado == null
                                                                                                                ? require('../../../../assets/images/icones_portais/olx-alpha.png').toString()
                                                                                                                : require('../../../../assets/images/icones_portais/olx.png').toString()
                                                                                                        }
                                                                                                    />
                                                                                                </a>
                                                                                            </OverlayTrigger>
                                                                                        </td>
                                                                                        : entry.id == 5
                                                                                            // AutoLine
                                                                                            ? <td
                                                                                                style={{ textAlign: 'center' }}
                                                                                                key={entry.id}>
                                                                                                <OverlayTrigger
                                                                                                    placement="top"
                                                                                                    overlay={<Tooltip id="tooltip"><strong>{entry.nome}</strong></Tooltip>}>
                                                                                                    <a onClick={() => {
                                                                                                        objProps.configuracaoPortais.modalPortais.idPublicador = entry.id;
                                                                                                        objProps.configuracaoPortais.modalPortais.modalAutoLine.showModal = true;
                                                                                                        if (entry.idAtivado != null)
                                                                                                            objProps.configuracaoPortais.modalPortais.ativado = true;
                                                                                                        else
                                                                                                            objProps.configuracaoPortais.modalPortais.ativado = false;
                                                                                                        objProps.fnUpdateState(objProps);
                                                                                                    }}>
                                                                                                        <img
                                                                                                            src={
                                                                                                                entry.idAtivado == null
                                                                                                                    ? require('../../../../assets/images/icones_portais/autoline-alpha.png').toString()
                                                                                                                    : require('../../../../assets/images/icones_portais/autoline.png').toString()
                                                                                                            }
                                                                                                        />
                                                                                                    </a>
                                                                                                </OverlayTrigger>
                                                                                            </td>
                                                                                            : entry.id == 6
                                                                                                // Mercado Livre
                                                                                                ? <td
                                                                                                    style={{ textAlign: 'center' }}
                                                                                                    key={entry.id}>
                                                                                                    <OverlayTrigger
                                                                                                        placement="top"
                                                                                                        overlay={<Tooltip id="tooltip"><strong>{entry.nome}</strong></Tooltip>}>
                                                                                                        <a onClick={() => {
                                                                                                            objProps.configuracaoPortais.modalPortais.idPublicador = entry.id;
                                                                                                            objProps.configuracaoPortais.modalPortais.modalMercadoLivre.showModal = true;
                                                                                                            if (entry.idAtivado != null)
                                                                                                                objProps.configuracaoPortais.modalPortais.ativado = true;
                                                                                                            else
                                                                                                                objProps.configuracaoPortais.modalPortais.ativado = false;
                                                                                                            objProps.fnUpdateState(objProps);
                                                                                                        }}>
                                                                                                            <img
                                                                                                                src={
                                                                                                                    entry.idAtivado == null
                                                                                                                        ? require('../../../../assets/images/icones_portais/mercadolivre-alpha.png').toString()
                                                                                                                        : require('../../../../assets/images/icones_portais/mercadolivre.png').toString()
                                                                                                                }
                                                                                                            />
                                                                                                        </a>
                                                                                                    </OverlayTrigger>
                                                                                                </td>
                                                                                                : entry.id == 8
                                                                                                    // Carro São José
                                                                                                    ? <td
                                                                                                        style={{ textAlign: 'center' }}
                                                                                                        key={entry.id}>
                                                                                                        <OverlayTrigger
                                                                                                            placement="top"
                                                                                                            overlay={<Tooltip id="tooltip"><strong>{entry.nome}</strong></Tooltip>}>
                                                                                                            <a onClick={() => {
                                                                                                                objProps.configuracaoPortais.modalPortais.idPublicador = entry.id;
                                                                                                                objProps.configuracaoPortais.modalPortais.modalCarroSaoJose.showModal = true;
                                                                                                                if (entry.idAtivado != null)
                                                                                                                    objProps.configuracaoPortais.modalPortais.ativado = true;
                                                                                                                else
                                                                                                                    objProps.configuracaoPortais.modalPortais.ativado = false;
                                                                                                                objProps.fnUpdateState(objProps);
                                                                                                            }}>
                                                                                                                <img
                                                                                                                    src={
                                                                                                                        entry.idAtivado == null
                                                                                                                            ? require('../../../../assets/images/icones_portais/carrosaojose-alpha.png').toString()
                                                                                                                            : require('../../../../assets/images/icones_portais/carrossaojose.png').toString()
                                                                                                                    }
                                                                                                                />
                                                                                                            </a>
                                                                                                        </OverlayTrigger>
                                                                                                    </td>
                                                                                                    : entry.id == 10
                                                                                                        // InfoCarro
                                                                                                        ? <td
                                                                                                            style={{ textAlign: 'center' }}
                                                                                                            key={entry.id}>
                                                                                                            <OverlayTrigger
                                                                                                                placement="top"
                                                                                                                overlay={<Tooltip id="tooltip"><strong>{entry.nome}</strong></Tooltip>}>
                                                                                                                <a onClick={() => {
                                                                                                                    alert('aki');
                                                                                                                }}>
                                                                                                                    <img
                                                                                                                        src={
                                                                                                                            entry.idAtivado == null
                                                                                                                                ? require('../../../../assets/images/icones_portais/infocarro-alpha.png').toString()
                                                                                                                                : require('../../../../assets/images/icones_portais/infocarro.png').toString()
                                                                                                                        }
                                                                                                                    />
                                                                                                                </a>
                                                                                                            </OverlayTrigger>
                                                                                                        </td>
                                                                                                        : entry.id == 12
                                                                                                            // CarroBrasil
                                                                                                            ? <td
                                                                                                                style={{ textAlign: 'center' }}
                                                                                                                key={entry.id}>
                                                                                                                <OverlayTrigger
                                                                                                                    placement="top"
                                                                                                                    overlay={<Tooltip id="tooltip"><strong>{entry.nome}</strong></Tooltip>}>
                                                                                                                    <a onClick={() => {
                                                                                                                        objProps.configuracaoPortais.modalPortais.idPublicador = entry.id;
                                                                                                                        objProps.configuracaoPortais.modalPortais.modalCarroBrasil.showModal = true;
                                                                                                                        if (entry.idAtivado != null)
                                                                                                                            objProps.configuracaoPortais.modalPortais.ativado = true;
                                                                                                                        else
                                                                                                                            objProps.configuracaoPortais.modalPortais.ativado = false;
                                                                                                                        objProps.fnUpdateState(objProps);
                                                                                                                    }}>
                                                                                                                        <img
                                                                                                                            src={
                                                                                                                                entry.idAtivado == null
                                                                                                                                    ? require('../../../../assets/images/icones_portais/carrobrasil-alpha.png').toString()
                                                                                                                                    : require('../../../../assets/images/icones_portais/carrobrasil.png').toString()
                                                                                                                            }
                                                                                                                        />
                                                                                                                    </a>
                                                                                                                </OverlayTrigger>
                                                                                                            </td>
                                                                                                            : entry.id == 13
                                                                                                                // TemUsados
                                                                                                                ? <td
                                                                                                                    style={{ textAlign: 'center' }}
                                                                                                                    key={entry.id}>
                                                                                                                    <OverlayTrigger
                                                                                                                        placement="top"
                                                                                                                        overlay={<Tooltip id="tooltip"><strong>{entry.nome}</strong></Tooltip>}>
                                                                                                                        <a onClick={() => {
                                                                                                                            objProps.configuracaoPortais.modalPortais.idPublicador = entry.id;
                                                                                                                            objProps.configuracaoPortais.modalPortais.modalTemUsados.showModal = true;
                                                                                                                            if (entry.idAtivado != null)
                                                                                                                                objProps.configuracaoPortais.modalPortais.ativado = true;
                                                                                                                            else
                                                                                                                                objProps.configuracaoPortais.modalPortais.ativado = false;
                                                                                                                            objProps.fnUpdateState(objProps);
                                                                                                                        }}>
                                                                                                                            <img
                                                                                                                                src={
                                                                                                                                    entry.idAtivado == null
                                                                                                                                        ? require('../../../../assets/images/icones_portais/temusados-alpha.png').toString()
                                                                                                                                        : require('../../../../assets/images/icones_portais/temusados.png').toString()
                                                                                                                                }
                                                                                                                            />
                                                                                                                        </a>
                                                                                                                    </OverlayTrigger>
                                                                                                                </td>
                                                                                                                : entry.id == 14
                                                                                                                    // AutoShoppingGlobal
                                                                                                                    ? <td
                                                                                                                        style={{ textAlign: 'center' }}
                                                                                                                        key={entry.id}>
                                                                                                                        <OverlayTrigger
                                                                                                                            placement="top"
                                                                                                                            overlay={<Tooltip id="tooltip"><strong>{entry.nome}</strong></Tooltip>}>
                                                                                                                            <a onClick={() => {
                                                                                                                                objProps.configuracaoPortais.modalPortais.idPublicador = entry.id;
                                                                                                                                objProps.configuracaoPortais.modalPortais.modalAutoShoppingGlobal.showModal = true;
                                                                                                                                if (entry.idAtivado != null)
                                                                                                                                    objProps.configuracaoPortais.modalPortais.ativado = true;
                                                                                                                                else
                                                                                                                                    objProps.configuracaoPortais.modalPortais.ativado = false;
                                                                                                                                objProps.fnUpdateState(objProps);
                                                                                                                            }}>
                                                                                                                                <img
                                                                                                                                    src={
                                                                                                                                        entry.idAtivado == null
                                                                                                                                            ? require('../../../../assets/images/icones_portais/asglobal-alpha.png').toString()
                                                                                                                                            : require('../../../../assets/images/icones_portais/asglobal.png').toString()
                                                                                                                                    }
                                                                                                                                />
                                                                                                                            </a>
                                                                                                                        </OverlayTrigger>
                                                                                                                    </td>
                                                                                                                    : entry.id == 15
                                                                                                                        // Vale Veiculos
                                                                                                                        ? <td
                                                                                                                            style={{ textAlign: 'center' }}
                                                                                                                            key={entry.id}>
                                                                                                                            <OverlayTrigger
                                                                                                                                placement="top"
                                                                                                                                overlay={<Tooltip id="tooltip"><strong>{entry.nome}</strong></Tooltip>}>
                                                                                                                                <a onClick={() => {
                                                                                                                                    objProps.configuracaoPortais.modalPortais.idPublicador = entry.id;
                                                                                                                                    objProps.configuracaoPortais.modalPortais.modalValeVeiculos.showModal = true;
                                                                                                                                    if (entry.idAtivado != null)
                                                                                                                                        objProps.configuracaoPortais.modalPortais.ativado = true;
                                                                                                                                    else
                                                                                                                                        objProps.configuracaoPortais.modalPortais.ativado = false;
                                                                                                                                    objProps.fnUpdateState(objProps);
                                                                                                                                }}>
                                                                                                                                    <img
                                                                                                                                        src={
                                                                                                                                            entry.idAtivado == null
                                                                                                                                                ? require('../../../../assets/images/icones_portais/ValeVeiculos-alpha.png').toString()
                                                                                                                                                : require('../../../../assets/images/icones_portais/ValeVeiculos.png').toString()
                                                                                                                                        }
                                                                                                                                    />
                                                                                                                                </a>
                                                                                                                            </OverlayTrigger>
                                                                                                                        </td>
                                                                                                                        : entry.id == 16
                                                                                                                            // New Motors
                                                                                                                            ? <td
                                                                                                                                style={{ textAlign: 'center' }}
                                                                                                                                key={entry.id}>
                                                                                                                                <OverlayTrigger
                                                                                                                                    placement="top"
                                                                                                                                    overlay={<Tooltip id="tooltip"><strong>{entry.nome}</strong></Tooltip>}>
                                                                                                                                    <a onClick={() => {
                                                                                                                                        objProps.configuracaoPortais.modalPortais.idPublicador = entry.id;
                                                                                                                                        objProps.configuracaoPortais.modalPortais.modalNewMotors.showModal = true;
                                                                                                                                        if (entry.idAtivado != null)
                                                                                                                                            objProps.configuracaoPortais.modalPortais.ativado = true;
                                                                                                                                        else
                                                                                                                                            objProps.configuracaoPortais.modalPortais.ativado = false;
                                                                                                                                        objProps.fnUpdateState(objProps);
                                                                                                                                    }}>
                                                                                                                                        <img
                                                                                                                                            src={
                                                                                                                                                entry.idAtivado == null
                                                                                                                                                    ? require('../../../../assets/images/icones_portais/newmotors-alpha.png').toString()
                                                                                                                                                    : require('../../../../assets/images/icones_portais/newmotors.png').toString()
                                                                                                                                            }
                                                                                                                                        />
                                                                                                                                    </a>
                                                                                                                                </OverlayTrigger>
                                                                                                                            </td>
                                                                                                                            : entry.id == 17
                                                                                                                                // Facebook
                                                                                                                                ? <td
                                                                                                                                    style={{ textAlign: 'center' }}
                                                                                                                                    key={entry.id}>
                                                                                                                                    <OverlayTrigger
                                                                                                                                        placement="top"
                                                                                                                                        overlay={<Tooltip id="tooltip"><strong>{"Facebook"}</strong></Tooltip>}>
                                                                                                                                        <a onClick={() => {
                                                                                                                                            objProps.configuracaoPortais.modalPortais.idPublicador = entry.id;
                                                                                                                                            objProps.configuracaoPortais.modalPortais.modalFacebook.showModal = true;
                                                                                                                                            if (entry.idAtivado != null)
                                                                                                                                                objProps.configuracaoPortais.modalPortais.ativado = true;
                                                                                                                                            else
                                                                                                                                                objProps.configuracaoPortais.modalPortais.ativado = false;
                                                                                                                                            objProps.fnUpdateState(objProps);
                                                                                                                                        }}>
                                                                                                                                            <img
                                                                                                                                                src={
                                                                                                                                                    entry.idAtivado == null
                                                                                                                                                        ? require('../../../../assets/images/icones_portais/facebook-alpha.png').toString()
                                                                                                                                                        : require('../../../../assets/images/icones_portais/facebook.png').toString()
                                                                                                                                                }
                                                                                                                                            />
                                                                                                                                        </a>
                                                                                                                                    </OverlayTrigger>
                                                                                                                                </td>
                                                                                                                                : entry.id == 18
                                                                                                                                    // CompreCar
                                                                                                                                    ? <td
                                                                                                                                        style={{ textAlign: 'center' }}
                                                                                                                                        key={entry.id}>
                                                                                                                                        <OverlayTrigger
                                                                                                                                            placement="top"
                                                                                                                                            overlay={<Tooltip id="tooltip"><strong>{entry.nome}</strong></Tooltip>}>
                                                                                                                                            <a onClick={() => {
                                                                                                                                                objProps.configuracaoPortais.modalPortais.idPublicador = entry.id;
                                                                                                                                                objProps.configuracaoPortais.modalPortais.modalCompreCar.showModal = true;
                                                                                                                                                if (entry.idAtivado != null)
                                                                                                                                                    objProps.configuracaoPortais.modalPortais.ativado = true;
                                                                                                                                                else
                                                                                                                                                    objProps.configuracaoPortais.modalPortais.ativado = false;
                                                                                                                                                objProps.fnUpdateState(objProps);
                                                                                                                                            }}>
                                                                                                                                                <img
                                                                                                                                                    src={
                                                                                                                                                        entry.idAtivado == null
                                                                                                                                                            ? require('../../../../assets/images/icones_portais/comprecar-alpha.png').toString()
                                                                                                                                                            : require('../../../../assets/images/icones_portais/comprecar.png').toString()
                                                                                                                                                    }
                                                                                                                                                />
                                                                                                                                            </a>
                                                                                                                                        </OverlayTrigger>
                                                                                                                                    </td>
                                                                                                                                    : entry.id == 19
                                                                                                                                        // AutoShoppingPontal
                                                                                                                                        ? <td
                                                                                                                                            style={{ textAlign: 'center' }}
                                                                                                                                            key={entry.id}>
                                                                                                                                            <OverlayTrigger
                                                                                                                                                placement="top"
                                                                                                                                                overlay={<Tooltip id="tooltip"><strong>{entry.nome}</strong></Tooltip>}>
                                                                                                                                                <a onClick={() => {
                                                                                                                                                    objProps.configuracaoPortais.modalPortais.idPublicador = entry.id;
                                                                                                                                                    objProps.configuracaoPortais.modalPortais.modalAutoShoppingPontal.showModal = true;
                                                                                                                                                    if (entry.idAtivado != null)
                                                                                                                                                        objProps.configuracaoPortais.modalPortais.ativado = true;
                                                                                                                                                    else
                                                                                                                                                        objProps.configuracaoPortais.modalPortais.ativado = false;
                                                                                                                                                    objProps.fnUpdateState(objProps);
                                                                                                                                                }}>
                                                                                                                                                    <img
                                                                                                                                                        src={
                                                                                                                                                            entry.idAtivado == null
                                                                                                                                                                ? require('../../../../assets/images/icones_portais/aspontal-alpha.png').toString()
                                                                                                                                                                : require('../../../../assets/images/icones_portais/aspontal.png').toString()
                                                                                                                                                        }
                                                                                                                                                    />
                                                                                                                                                </a>
                                                                                                                                            </OverlayTrigger>
                                                                                                                                        </td>
                                                                                                                                        : entry.id == 20
                                                                                                                                            // Site da Loja
                                                                                                                                            ? <td
                                                                                                                                                style={{ textAlign: 'center' }}
                                                                                                                                                key={entry.id}>
                                                                                                                                                <OverlayTrigger
                                                                                                                                                    placement="top"
                                                                                                                                                    overlay={<Tooltip id="tooltip"><strong>{"Site da Loja"}</strong></Tooltip>}>
                                                                                                                                                    <a onClick={() => {
                                                                                                                                                        objProps.configuracaoPortais.modalPortais.idPublicador = entry.id;
                                                                                                                                                        objProps.configuracaoPortais.modalPortais.modalSiteCliente.showModal = true;
                                                                                                                                                        if (entry.idAtivado != null)
                                                                                                                                                            objProps.configuracaoPortais.modalPortais.ativado = true;
                                                                                                                                                        else
                                                                                                                                                            objProps.configuracaoPortais.modalPortais.ativado = false;
                                                                                                                                                        objProps.fnUpdateState(objProps);
                                                                                                                                                    }}>
                                                                                                                                                        <img
                                                                                                                                                            src={
                                                                                                                                                                entry.idAtivado == null
                                                                                                                                                                    ? require('../../../../assets/images/icones_portais/bndvSites-alpha.png').toString()
                                                                                                                                                                    : require('../../../../assets/images/icones_portais/bndvSites.png').toString()
                                                                                                                                                            }
                                                                                                                                                        />
                                                                                                                                                    </a>
                                                                                                                                                </OverlayTrigger>
                                                                                                                                            </td>
                                                                                                                                            : entry.id == 21
                                                                                                                                                // Auto Shopping Imigrantes
                                                                                                                                                ? <td
                                                                                                                                                    style={{ textAlign: 'center' }}
                                                                                                                                                    key={entry.id}>
                                                                                                                                                    <OverlayTrigger
                                                                                                                                                        placement="top"
                                                                                                                                                        overlay={<Tooltip id="tooltip"><strong>{"Auto Shopping Imigrantes"}</strong></Tooltip>}>
                                                                                                                                                        <a onClick={() => {
                                                                                                                                                            objProps.configuracaoPortais.modalPortais.idPublicador = entry.id;
                                                                                                                                                            objProps.configuracaoPortais.modalPortais.modalAutoShoppingImigrantes.showModal = true;
                                                                                                                                                            if (entry.idAtivado != null)
                                                                                                                                                                objProps.configuracaoPortais.modalPortais.ativado = true;
                                                                                                                                                            else
                                                                                                                                                                objProps.configuracaoPortais.modalPortais.ativado = false;
                                                                                                                                                            objProps.fnUpdateState(objProps);
                                                                                                                                                        }}>
                                                                                                                                                            <img
                                                                                                                                                                src={
                                                                                                                                                                    entry.idAtivado == null
                                                                                                                                                                        ? require('../../../../assets/images/icones_portais/asimigrantes-alpha.png').toString()
                                                                                                                                                                        : require('../../../../assets/images/icones_portais/asimigrantes.png').toString()
                                                                                                                                                                }
                                                                                                                                                            />
                                                                                                                                                        </a>
                                                                                                                                                    </OverlayTrigger>
                                                                                                                                                </td>
                                                                                                                                                :
                                                                                                                                                entry.id == 22
                                                                                                                                                    // MobiAuto
                                                                                                                                                    ? <td
                                                                                                                                                        style={{ textAlign: 'center' }}
                                                                                                                                                        key={entry.id}>
                                                                                                                                                        <OverlayTrigger
                                                                                                                                                            placement="top"
                                                                                                                                                            overlay={<Tooltip id="tooltip"><strong>{entry.nome}</strong></Tooltip>}>
                                                                                                                                                            <a onClick={() => {
                                                                                                                                                                objProps.configuracaoPortais.modalPortais.idPublicador = entry.id;
                                                                                                                                                                objProps.configuracaoPortais.modalPortais.modalMobiAuto.showModal = true;
                                                                                                                                                                if (entry.idAtivado != null)
                                                                                                                                                                    objProps.configuracaoPortais.modalPortais.ativado = true;
                                                                                                                                                                else
                                                                                                                                                                    objProps.configuracaoPortais.modalPortais.ativado = false;
                                                                                                                                                                objProps.fnUpdateState(objProps);
                                                                                                                                                            }}>
                                                                                                                                                                <img
                                                                                                                                                                    src={
                                                                                                                                                                        entry.idAtivado == null
                                                                                                                                                                            ? require('../../../../assets/images/icones_portais/mobiauto-alpha.png').toString()
                                                                                                                                                                            : require('../../../../assets/images/icones_portais/mobiauto.png').toString()
                                                                                                                                                                    }
                                                                                                                                                                />
                                                                                                                                                            </a>
                                                                                                                                                        </OverlayTrigger>
                                                                                                                                                    </td>
                                                                                                                                                    : entry.id == 23
                                                                                                                                                      // Compre Blindados
                                                                                                                                                      ? <td
                                                                                                                                                          style={{ textAlign: 'center' }}
                                                                                                                                                          key={entry.id}>
                                                                                                                                                          <OverlayTrigger
                                                                                                                                                              placement="top"
                                                                                                                                                              overlay={<Tooltip id="tooltip"><strong>{entry.nome}</strong></Tooltip>}>
                                                                                                                                                              <a onClick={() => {
                                                                                                                                                                  objProps.configuracaoPortais.modalPortais.idPublicador = entry.id;
                                                                                                                                                                  objProps.configuracaoPortais.modalPortais.modalCompreBlindados.showModal = true;
                                                                                                                                                                  if (entry.idAtivado != null)
                                                                                                                                                                      objProps.configuracaoPortais.modalPortais.ativado = true;
                                                                                                                                                                  else
                                                                                                                                                                      objProps.configuracaoPortais.modalPortais.ativado = false;
                                                                                                                                                                  objProps.fnUpdateState(objProps);
                                                                                                                                                              }}>
                                                                                                                                                                  <img
                                                                                                                                                                      src={
                                                                                                                                                                          entry.idAtivado == null
                                                                                                                                                                              ? require('../../../../assets/images/icones_portais/compreblindados-alpha.png').toString()
                                                                                                                                                                                : require('../../../../assets/images/icones_portais/compreblindados.png').toString()
                                                                                                                                                                      }
                                                                                                                                                                  />
                                                                                                                                                              </a>
                                                                                                                                                          </OverlayTrigger>
                                                                                                                                                        </td> 
                                                                                                                                                        : entry.id == 24
                                                                                                                                                          // Usadosbr
                                                                                                                                                          ? <td
                                                                                                                                                              style={{ textAlign: 'center' }}
                                                                                                                                                              key={entry.id}>
                                                                                                                                                              <OverlayTrigger
                                                                                                                                                                  placement="top"
                                                                                                                                                                  overlay={<Tooltip id="tooltip"><strong>{entry.nome}</strong></Tooltip>}>
                                                                                                                                                                  <a onClick={() => {
                                                                                                                                                                      objProps.configuracaoPortais.modalPortais.idPublicador = entry.id;
                                                                                                                                                                      objProps.configuracaoPortais.modalPortais.modalUsadosBR.showModal = true;
                                                                                                                                                                      if (entry.idAtivado != null)
                                                                                                                                                                          objProps.configuracaoPortais.modalPortais.ativado = true;
                                                                                                                                                                      else
                                                                                                                                                                          objProps.configuracaoPortais.modalPortais.ativado = false;
                                                                                                                                                                      objProps.fnUpdateState(objProps);
                                                                                                                                                                  }}>
                                                                                                                                                                      <img
                                                                                                                                                                          src={
                                                                                                                                                                              entry.idAtivado == null
                                                                                                                                                                                  ? require('../../../../assets/images/icones_portais/usadosbr-alpha.png').toString()
                                                                                                                                                                                    : require('../../../../assets/images/icones_portais/usadosbr.png').toString()
                                                                                                                                                                          }
                                                                                                                                                                      />
                                                                                                                                                                  </a>
                                                                                                                                                              </OverlayTrigger>
                                                                                                                                                          </td> :null
                                                                                                                                                          )
                                                                })
                                                                : null
                                                        }
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </Row>
                            </div>
                        </Section>
                    </div>
                </Row>
            }
            {this.props.configuracaoPortais.modalPortais.modalICarros.showModal ? <ModalICarros /> : null}
            {this.props.configuracaoPortais.modalPortais.modalMeuCarroNovo.showModal ? <ModalMeuCarroNovo /> : null}
            {this.props.configuracaoPortais.modalPortais.modalWebMotors.showModal ? <ModalWebMotors /> : null}
            {this.props.configuracaoPortais.modalPortais.modalOLX.showModal ? <ModalOLX /> : null}
            {this.props.configuracaoPortais.modalPortais.modalAutoLine.showModal ? <ModalAutoLine /> : null}
            {this.props.configuracaoPortais.modalPortais.modalMercadoLivre.showModal ? <ModalMercadoLivre /> : null}
            {this.props.configuracaoPortais.modalPortais.modalCarroSaoJose.showModal ? <ModalCarroSaoJose /> : null}
            {this.props.configuracaoPortais.modalPortais.modalCarroBrasil.showModal ? <ModalCarroBrasil /> : null}
            {this.props.configuracaoPortais.modalPortais.modalNewMotors.showModal ? <ModalNewMotors /> : null}
            {this.props.configuracaoPortais.modalPortais.modalTemUsados.showModal ? <ModalTemUsados /> : null}
            {this.props.configuracaoPortais.modalPortais.modalCompreCar.showModal ? <ModalCompreCar /> : null}
            {this.props.configuracaoPortais.modalPortais.modalAutoShoppingGlobal.showModal ? <ModalAutoShoppingGlobal /> : null}
            {this.props.configuracaoPortais.modalPortais.modalValeVeiculos.showModal ? <ModalValeVeiculos /> : null}
            {this.props.configuracaoPortais.modalPortais.modalAutoShoppingPontal.showModal ? <ModalAutoShoppingPontal /> : null}
            {this.props.configuracaoPortais.modalPortais.modalSiteCliente.showModal ? <ModalSiteCliente /> : null}
            {this.props.configuracaoPortais.modalPortais.modalFacebook.showModal ? <ModalFacebook /> : null}
            {this.props.configuracaoPortais.modalPortais.modalAutoShoppingImigrantes.showModal ? <ModalAutoShoppingImigrantes /> : null}
            {this.props.configuracaoPortais.modalPortais.modalMobiAuto.showModal ? <ModalMobiAuto /> : null}
            {this.props.configuracaoPortais.modalPortais.modalCompreBlindados.showModal ? <ModalCompreBlindados /> : null}
            {this.props.configuracaoPortais.modalPortais.modalUsadosBR.showModal ? <ModalUsadosBR /> : null}
        </div>;
    }
}

export default connect(
    state => state.marketing_publicadorconfig,
    dispatch => bindActionCreators(actionCreators, dispatch),
    null, {}
)(ConfiguracaoPortaisForm);