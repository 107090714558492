const URLAdministrador = 'https://apisistema.bndv.com.br/Dashboard/api/v1/administrador/';
const URLAdministradroCRM = 'https://apicrm.bndv.com.br/api/v1/dashboard/';
//const URLAdministrador = 'https://bndvapis-sistema-lojistas.azurewebsites.net/dashboard/api/v1/administrador/';
//const URLAdministrador = 'http://localhost:61505/api/v1/administrador/';

export const services = {
    Administrador: {
        VeiculosVendidosXMetaSemestre: (authorizationToken: string, idEmpresa: number) => {
            return new Request(URLAdministrador + 'obterVeiculosVendidosXMetaSemestre?idEmpresa=' + idEmpresa.toString(),
                {
                    method: 'GET',
                    mode: 'cors',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + authorizationToken
                    }
                });
        }
        , VeiculosVendidosPorVendedorMesAtual: (authorizationToken: string, idEmpresa: number) => {
            return new Request(URLAdministrador + 'obterVeiculosVendidosPorVendedorMesAtual?idEmpresa=' + idEmpresa.toString(),
                {
                    method: 'GET',
                    mode: 'cors',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + authorizationToken
                    }
                });
        }
        , LucratividadeXMetaSemestre: (authorizationToken: string, idEmpresa: number) => {
            return new Request(URLAdministrador + 'obterLucratividadeXMetaSemestre?idEmpresa=' + idEmpresa.toString(),
                {
                    method: 'GET',
                    mode: 'cors',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + authorizationToken
                    }
                });
        }
        , LucratividadePorVendedorMesAtual: (authorizationToken: string, idEmpresa: number) => {
            return new Request(URLAdministrador + 'obterLucratividadePorVendedorMesAtual?idEmpresa=' + idEmpresa.toString(),
                {
                    method: 'GET',
                    mode: 'cors',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + authorizationToken
                    }
                });
        }
        , FinanciamentosXMetaSemestre: (authorizationToken: string, idEmpresa: number) => {
            return new Request(URLAdministrador + 'obterFinanciamentosXMetaSemestre?idEmpresa=' + idEmpresa.toString(),
                {
                    method: 'GET',
                    mode: 'cors',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + authorizationToken
                    }
                });
        }
        , FinanciamentosPorFinanceiraMesAtual: (authorizationToken: string, idEmpresa: number) => {
            return new Request(URLAdministrador + 'obterFinanciamentosPorFinanceiraMesAtual?idEmpresa=' + idEmpresa.toString(),
                {
                    method: 'GET',
                    mode: 'cors',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + authorizationToken
                    }
                });
        }
        , DezVeiculosMaisVendidosTrimestre: (authorizationToken: string, idEmpresa: number) => {
            return new Request(URLAdministrador + 'obterDezVeiculosMaisVendidosTrimestre?idEmpresa=' + idEmpresa.toString(),
                {
                    method: 'GET',
                    mode: 'cors',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + authorizationToken
                    }
                });
        }
        , DezModelosMaiorTempoEstoqueMedia: (authorizationToken: string, idEmpresa: number) => {
            return new Request(URLAdministrador + 'obterDezModelosMaiorTempoEstoqueMedia?idEmpresa=' + idEmpresa.toString(),
                {
                    method: 'GET',
                    mode: 'cors',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + authorizationToken
                    }
                });
        }
        , QtdeVeiculosCompradosSemestre: (authorizationToken: string, idEmpresa: number) => {
            return new Request(URLAdministrador + 'obterQtdeVeiculosCompradosSemestre?idEmpresa=' + idEmpresa.toString(),
                {
                    method: 'GET',
                    mode: 'cors',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + authorizationToken
                    }
                });
        }
        , QtdeVeiculosCompradosPorCompradorTrimestre: (authorizationToken: string, idEmpresa: number) => {
            return new Request(URLAdministrador + 'obterQtdeVeiculosCompradosPorCompradorTrimestre?idEmpresa=' + idEmpresa.toString(),
                {
                    method: 'GET',
                    mode: 'cors',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + authorizationToken
                    }
                });
        }
        , DezModelosMaisCompradosTrimestre: (authorizationToken: string, idEmpresa: number) => {
            return new Request(URLAdministrador + 'obterDezModelosMaisCompradosTrimestre?idEmpresa=' + idEmpresa.toString(),
                {
                    method: 'GET',
                    mode: 'cors',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + authorizationToken
                    }
                });
        }
        , MediaDiasEstoqueDezModelosMaisComprados: (authorizationToken: string, idEmpresa: number) => {
            return new Request(URLAdministrador + 'obterMediaDiasEstoqueDezModelosMaisComprados?idEmpresa=' + idEmpresa.toString(),
                {
                    method: 'GET',
                    mode: 'cors',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + authorizationToken
                    }
                });
        }
        , ListaEmpresasVinculadasUsuario: (authorizationToken: string) => {
            return new Request(URLAdministrador + 'obterEmpresasVinculadasUsuario',
                {
                    method: 'GET',
                    mode: 'cors',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + authorizationToken
                    }
                });
        }
        , LeadsEmAbertoPorEtapa: (authorizationToken, idEmpresa) => {
            return new Request(URLAdministradroCRM + 'listaLeadsEmAbertoPorEtapa?idEmpresa=' + idEmpresa.toString(),
                {
                    method: 'GET',
                    mode: 'cors',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + authorizationToken
                    }
                });
        }
        , LeadsEmAbertoPorUsuario: (authorizationToken, idEmpresa) => {
            return new Request(URLAdministradroCRM + 'listaLeadsEmAbertoPorUsuario?idEmpresa=' + idEmpresa.toString(),
                {
                    method: 'GET',
                    mode: 'cors',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + authorizationToken
                    }
                });
        }
        , LeadsPorCanalMesAtual: (authorizationToken, idEmpresa) => {
            return new Request(URLAdministradroCRM + 'listaLeadsPorCanalMesAtual?idEmpresa=' + idEmpresa.toString(),
                {
                    method: 'GET',
                    mode: 'cors',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + authorizationToken
                    }
                });
        }
        , LeadsPorStatusMesAtual: (authorizationToken, idEmpresa) => {
            return new Request(URLAdministradroCRM + 'listaLeadsPorStatusMesAtual?idEmpresa=' + idEmpresa.toString(),
                {
                    method: 'GET',
                    mode: 'cors',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + authorizationToken
                    }
                });
        }
        , LeadsMotivosPerdaMesAtual: (authorizationToken, idEmpresa) => {
            return new Request(URLAdministradroCRM + 'listaMotivosPerdaMesAtual?idEmpresa=' + idEmpresa.toString(),
                {
                    method: 'GET',
                    mode: 'cors',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + authorizationToken
                    }
                });
        }
    }
}