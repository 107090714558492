import * as React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actionCreators } from '../../../store/crm/CrmWebMotors';

import Moment from 'react-moment';
import { Modal, Row } from 'react-bootstrap';
import AlertError from '../../commons/AlertError';
import AlertSuccess from '../../commons/AlertSuccess';
import ProgerssBar from '../../commons/ProgressBar';
import BtnSalvar from '../../commons/BtnSalvar';
import BtnCancelar from '../../commons/BtnCancelar';

class ModalDetalhes extends React.Component {
    constructor(props) {
        super(props);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    async componentDidMount() { }

    handleOnHide() {
        this.props.modalDetalhes.showModal = false;
        this.props.fnUpdateState(this.props);
    }

    async handleSubmit(e) {
        e.preventDefault();
        await this.props.fnAceitarLead();
    }

    render() {
        return <Modal
            show={this.props.modalDetalhes.showModal}
            onHide={this.handleOnHide.bind(this)}
            bsSize="large"
        >
            <form
                id="frm"
                name="frm"
                role="form"
                onSubmit={this.handleSubmit}
                data-parsley-validate>
                <Modal.Header closeButton>
                    <h4 className="modal-title custom-font">Detalhes do Lead</h4>
                </Modal.Header>
                <Modal.Body>
                    <AlertSuccess
                        successText={this.props.modalDetalhes.successText}
                        alertVisible={this.props.modalDetalhes.successText != '' && this.props.modalDetalhes.successText != undefined ? true : false}
                        onDismiss={(alertVisible, successText) => {
                            this.props.modalDetalhes.successText = successText;
                            this.props.fnUpdateState(this.props);
                        }}
                    />
                    <AlertError
                        errorText={this.props.modalDetalhes.errorText}
                        alertVisible={this.props.modalDetalhes.errorText != '' && this.props.modalDetalhes.errorText != undefined ? true : false}
                        onDismiss={(alertVisible, errorText) => {
                            this.props.modalDetalhes.errorText = errorText;
                            this.props.fnUpdateState(this.props);
                        }}
                    />
                    {
                        this.props.modalDetalhes.isLoading
                            ? <ProgerssBar />
                            : <div>
                                <Row>
                                    <div className="col-md-3 text-right"><strong>Número Lead: </strong></div>
                                    <div className="col-md-3">{this.props.modalDetalhes.lead.id}</div>
                                    <div className="col-md-3 text-right"><strong>Status: </strong></div>
                                    <div className="col-md-3">{this.props.modalDetalhes.lead.status}</div>
                                </Row>
                                <Row>
                                    <div className="col-md-3 text-right"><strong>Canal: </strong></div>
                                    <div className="col-md-3">{this.props.modalDetalhes.lead.midia}</div>
                                    <div className="col-md-3 text-right"><strong>Negociação: </strong></div>
                                    <div className="col-md-3">{this.props.modalDetalhes.lead.negociacao}</div>
                                </Row>
                                <Row>
                                    <div className="col-md-3 text-right"><strong>Marca: </strong></div>
                                    <div className="col-md-3">{this.props.modalDetalhes.lead.marca}</div>
                                    <div className="col-md-3 text-right"><strong>Modelo: </strong></div>
                                    <div className="col-md-3">{this.props.modalDetalhes.lead.modelo}</div>
                                </Row>
                                <Row>
                                    <div className="col-md-3 text-right"><strong>Versão: </strong></div>
                                    <div className="col-md-9">{this.props.modalDetalhes.lead.versao}</div>
                                </Row>
                                <Row>
                                    <div className="col-md-3 text-right"><strong>Ano Mod.: </strong></div>
                                    <div className="col-md-3">{this.props.modalDetalhes.lead.anoModelo}</div>
                                    <div className="col-md-3 text-right"><strong>Ano Fab: </strong></div>
                                    <div className="col-md-3">{this.props.modalDetalhes.lead.anoFabricacao}</div>
                                </Row>
                                <Row>
                                    <div className="col-md-3 text-right"><strong>Placa: </strong></div>
                                    <div className="col-md-3">{this.props.modalDetalhes.lead.placa}</div>
                                    <div className="col-md-3 text-right"><strong>Valor: </strong></div>
                                    <div className="col-md-3">{this.props.modalDetalhes.lead.valor}</div>
                                </Row>
                                <Row>
                                    <div className="col-md-3 text-right"><strong>Nome: </strong></div>
                                    <div className="col-md-3">{this.props.modalDetalhes.lead.nome}</div>
                                    <div className="col-md-3 text-right"><strong>Email: </strong></div>
                                    <div className="col-md-3">{this.props.modalDetalhes.lead.email}</div>
                                </Row>
                                <Row>
                                    <div className="col-md-3 text-right"><strong>Telefone: </strong></div>
                                    <div className="col-md-9">{this.props.modalDetalhes.lead.telefone}</div>
                                </Row>
                                <Row>
                                    <div className="col-md-3 text-right"><strong>Mensagem: </strong></div>
                                    <div className="col-md-9">{this.props.modalDetalhes.lead.mensagem}</div>
                                </Row>
                            </div>
                    }
                </Modal.Body>
                {this.props.modalDetalhes.idStatus == 1
                    ? <Modal.Footer>
                        <BtnCancelar
                            type="button"
                            text=" Recusar Lead"
                            textLoading=" Aguarde..."
                            isLoading={this.props.modalDetalhes.isLoading}
                            onClick={() => { this.props.fnRecusarLead(); }}
                        />
                        <BtnSalvar
                            type="submit"
                            text=" Aceitar Lead"
                            textLoading=" Aguarde..."
                            isLoading={this.props.modalDetalhes.isLoading}
                        />
                    </Modal.Footer>
                    : null}
            </form>
        </Modal>;
    }
}

export default connect(
    state => state.crm_webmotors,
    dispatch => bindActionCreators(actionCreators, dispatch),
    null, {}
)(ModalDetalhes);