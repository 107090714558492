import React from 'react';
import { withFormsy } from 'formsy-react';

class InputSelectValidated extends React.Component {
    changeValue = (event) => {
        this.props.setValue(event.currentTarget.value);
        this.props.onChange(event.target.name, event.target.value, event.target.options[event.target.selectedIndex].text);
    }

    render() {
        const className = 'form-group ' + (this.props.className || ' ') +
            (this.props.showRequired() ? ' has-error has-feedback' : this.props.showError() ? ' has-error has-feedback' : '');
        const errorMessage = this.props.getErrorMessage();

        const options = this.props.data.map((option, i) => (
            <option key={option.text + option.value} value={option.value}>
                {option.text}
            </option>
        ));

        return (
            <div className={className}>
                <label className="control-label" data-required="true" htmlFor={this.props.name}>{this.props.title}</label>
                <select
                    name={this.props.name}
                    onChange={this.changeValue}
                    value={this.props.selectedValue}                    
                    className="form-control"
                    disabled={this.props.disabled}
                >
                    {options}
                </select>
                {
                    (errorMessage !== "" && errorMessage != undefined)
                        ? <span className='help-block validation-message'>{errorMessage}</span>
                        : null
                }
            </div>
        );
    }
}

export default withFormsy(InputSelectValidated);