import React from 'react';

class InputSelect extends React.Component {
    changeValue = (event) => {
        this.props.onChange(event.target.name, event.target.value);
    }

    render() {
        const className = 'form-group ' + (this.props.className || ' ');

        const options = this.props.data.map((option, i) => (
            <option key={option.text + option.value} value={option.value}>
                {option.text}
            </option>
        ));

        return (
            <div className={className}>
                {this.props.name != null && this.props.name != '' && this.props.name != undefined
                    ? <label className="control-label" data-required="true" htmlFor={this.props.name}>{this.props.title}</label>
                    : null
                }
                
                <select
                    name={this.props.name}
                    onChange={this.changeValue}
                    value={this.props.value}
                    className="form-control"
                    disabled={this.props.disabled}
                    value={this.props.selectedValue||''}
                >
                    {options}
                </select>
            </div>
        );
    }
}

export default InputSelect;