import * as React from "react";

export default class BtnSalvar extends React.Component {
    onClickSalvar() {
        this.props.onClick();
    }

    render() {
        return <button
            type={this.props.type}
            className={(this.props.isLoading) ? "btn btn-dark" : "btn btn-success"}
            disabled={this.props.isLoading ? true : false}
            onClick={this.props.onClick != null ? this.onClickSalvar.bind(this) : null}
        >
            <i className="fa fa-save"></i>
            {(this.props.isLoading) ? this.props.textLoading : this.props.text}
        </button>;
    }
}