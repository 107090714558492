import { fetch, addTask } from 'domain-task';
import * as analiserisco_service from '../../services/analiserisco/analiserisco_service';
import * as SolicitarPagamentosStore from './SolicitarPagamentos';

const REQUEST_CONSULTAS_BNDVCOMPLETARF_HISTORICO_ACTION = 'REQUEST_CONSULTAS_BNDVCOMPLETARF_HISTORICO_ACTION';
const RECEIVE_CONSULTAS_BNDVCOMPLETARF_HISTORICO_ACTION = 'RECEIVE_CONSULTAS_BNDVCOMPLETARF_HISTORICO_ACTION';
const FAILURE_CONSULTAS_BNDVCOMPLETARF_HISTORICO_ACTION = 'FAILURE_CONSULTAS_BNDVCOMPLETARF_HISTORICO_ACTION';
const REQUEST_RESULTADO_PESQUISA_BNDVCOMPLETARF_ACTION = 'REQUEST_RESULTADO_PESQUISA_BNDVCOMPLETARF_ACTION';
const RECEIVE_RESULTADO_PESQUISA_BNDVCOMPLETARF_ACTION = 'RECEIVE_RESULTADO_PESQUISA_BNDVCOMPLETARF_ACTION';
const FAILURE_RESULTADO_PESQUISA_BNDVCOMPLETARF_ACTION = 'FAILURE_RESULTADO_PESQUISA_BNDVCOMPLETARF_ACTION';
const POST_SOLICITAR_PESQUISA_BNDVCOMPLETARF_ACTION = 'POST_SOLICITAR_PESQUISA_BNDVCOMPLETARF_ACTION';
const SUCCESS_SOLICITAR_PESQUISA_BNDVCOMPLETARF_ACTION = 'SUCCESS_SOLICITAR_PESQUISA_BNDVCOMPLETARF_ACTION';
const FAILURE_SOLICITAR_PESQUISA_BNDVCOMPLETARF_ACTION = 'FAILURE_SOLICITAR_PESQUISA_BNDVCOMPLETARF_ACTION';
const UPDATE_STATE_BNDVCOMPLETARF_ACTION = 'UPDATE_STATE_BNDVCOMPLETARF_ACTION';
const CLOSE_MODAL_RESULTADO_BNDVCOMPLETARF_ACTION = 'CLOSE_MODAL_RESULTADO_BNDVCOMPLETARF_ACTION';

const initialState = {
    Filters: {
        PageNumber: 1,
        PageSize: 10,
        OrderBy: '',
        Ascending: true,
        TotalNumberOfPages: 0,
        TotalNumberOfRecords: 0,
        DataDe: '',
        DataAte: '',
        Chassi: '',
        Placa: ''
    }
    , Results: []
    , ModalResultadoPesquisa: {
        id: 0
        , showModal: false
        , data: {
            Chassi: ''
            , DataHoraSolicitacao: ''
            , ID: 0
            , JsonRetorno: ''
            , NomeResponsavelSolicitacao: ''
            , Placa: ''
            , UF: ''
            , Valor: 0
        }
    }
    , SolicitarPesquisa: {
        ID: 0
        , Placa: ''
        , Chassi: ''
        , UF: ''
        , IsLoading: false
        , showModalSolicitacao: false
    }
    , IsLoading: false
    , ErrorText: ''
};

export const actionCreators = {
    fnListaPesquisasHistorico: () => async (dispatch, getState) => {
        let fetchTask = fetch(analiserisco_service.services.ConsultasVeiculares.ListaPesquisasBNDVCompletaRF(getState().bndvcompletarf.Filters, getState().auth.authorizationToken))
            .then(response => {
                if (response.status === 200 || response.status === 0) {
                    return response.json();
                }
                else {
                    return response.json().then(function (object) {
                        return Promise.reject(new Error(object));
                    })
                }
            })
            .then(data => {
                var _Filters = getState().bndvcompletarf.Filters;
                _Filters.Ascending = getState().bndvcompletarf.Filters.Ascending;
                _Filters.DataAte = getState().bndvcompletarf.Filters.DataAte;
                _Filters.DataDe = getState().bndvcompletarf.Filters.DataDe;
                _Filters.Chassi = getState().bndvcompletarf.Filters.Chassi;
                _Filters.Placa = getState().bndvcompletarf.Filters.Placa;
                _Filters.OrderBy = getState().bndvcompletarf.Filters.OrderBy;
                _Filters.PageNumber = getState().bndvcompletarf.Filters.PageNumber;
                _Filters.PageSize = getState().bndvcompletarf.Filters.PageSize;
                _Filters.TotalNumberOfPages = data.TotalNumberOfPages;
                _Filters.TotalNumberOfRecords = data.TotalNumberOfRecords;

                dispatch({ type: 'RECEIVE_CONSULTAS_BNDVCOMPLETARF_HISTORICO_ACTION', Results: data.Results });
            }).catch(function (err) {
                dispatch({ type: 'FAILURE_CONSULTAS_BNDVCOMPLETARF_HISTORICO_ACTION',  ErrorText: err.message });
            });

        addTask(fetchTask);
        dispatch({ type: 'REQUEST_CONSULTAS_BNDVCOMPLETARF_HISTORICO_ACTION', Filters: getState().bndvcompletarf.Filters, Results: [] });

    }
    , fnUpdateState: (pBNDVCompletaRFState) => async (dispatch, getState) => {
        dispatch({
            type: 'UPDATE_STATE_BNDVCOMPLETARF_ACTION',
            Filters: pBNDVCompletaRFState.Filters,
            Results: pBNDVCompletaRFState.Results,
            ModalResultadoPesquisa: pBNDVCompletaRFState.ModalResultadoPesquisa,
            SolicitarPesquisa: pBNDVCompletaRFState.SolicitarPesquisa,
            IsLoading: pBNDVCompletaRFState.IsLoading,
            ErrorText: pBNDVCompletaRFState.ErrorText
        });
    }
    , fnMostraPesquisa: (pId) => async (dispatch, getState) => {

        getState().bndvcompletarf.ModalResultadoPesquisa.id = pId;
        getState().bndvcompletarf.ModalResultadoPesquisa.showModal = true;

        let fetchTask = fetch(analiserisco_service.services.ConsultasVeiculares.BuscaResultadoPesquisaBNDVCompletaRF(getState().bndvcompletarf.ModalResultadoPesquisa.id, getState().auth.authorizationToken))
            .then(response => {
                if (response.status === 200 || response.status === 0) {
                    return response.json();
                }
                else {
                    return response.json().then(function (object) {
                        return Promise.reject(new Error(object));
                    })
                }
            })
            .then(data => {
                getState().bndvcompletarf.ModalResultadoPesquisa.data = data;
                dispatch({ type: 'RECEIVE_RESULTADO_PESQUISA_BNDVCOMPLETARF_ACTION', ModalResultadoPesquisa: getState().bndvcompletarf.ModalResultadoPesquisa });
            }).catch(function (err) {
                dispatch({ type: 'FAILURE_RESULTADO_PESQUISA_BNDVCOMPLETARF_ACTION', ErrorText: err.message });
            });

        addTask(fetchTask);
        dispatch({ type: 'REQUEST_RESULTADO_PESQUISA_BNDVCOMPLETARF_ACTION', ModalResultadoPesquisa: getState().bndvcompletarf.ModalResultadoPesquisa });

    }
    , fnCloseModalResultadoPesquisa: () => async (dispatch, getState) => {

        getState().bndvcompletarf.ModalResultadoPesquisa.id = 0;
        getState().bndvcompletarf.ModalResultadoPesquisa.data = { Chassi: '', DataHoraSolicitacao: '', ID: 0, JsonRetorno: '', NomeResponsavelSolicitacao: '', Placa: '', UF: '', Valor: 0 };
        getState().bndvcompletarf.ModalResultadoPesquisa.showModal = false;

        dispatch({ type: 'CLOSE_MODAL_RESULTADO_BNDVCOMPLETARF_ACTION', ModalResultadoPesquisa: getState().bndvcompletarf.ModalResultadoPesquisa });
    }
    , fnSolicitarPesquisa: () => async (dispatch, getState) => {

        getState().bndvcompletarf.SolicitarPesquisa.IsLoading = true;

        let fetchTask = fetch(analiserisco_service.services.ConsultasVeiculares.SolicitaPesquisaBNDVCompletaRF(
            getState().bndvcompletarf.SolicitarPesquisa.Placa
            , getState().bndvcompletarf.SolicitarPesquisa.Chassi
            , getState().bndvcompletarf.SolicitarPesquisa.UF
            , getState().auth.authorizationToken)
        ).then(response => {
            if (response.status === 200 || response.status === 0) {
                return response.json();
            }
            else {
                return response.json().then(function (object) {
                    return Promise.reject(new Error(object));
                })
            }
        }).then(data => {
            //getState().consultaveicular.SolicitarPesquisa.ID = data.ID;
            getState().bndvcompletarf.SolicitarPesquisa.Placa = '';
            getState().bndvcompletarf.SolicitarPesquisa.Chassi = '';
            getState().bndvcompletarf.SolicitarPesquisa.UF = '';
            getState().bndvcompletarf.SolicitarPesquisa.IsLoading = false;
            getState().bndvcompletarf.SolicitarPesquisa.showModalSolicitacao = false;

            dispatch({ type: 'SUCCESS_SOLICITAR_PESQUISA_BNDVCOMPLETARF_ACTION', SolicitarPesquisa: getState().bndvcompletarf.SolicitarPesquisa });

            // Mostra a pesquisa
            dispatch(actionCreators.fnMostraPesquisa(data.ID));
            // Atualiza o valor de crédito do cliente
            dispatch(SolicitarPagamentosStore.actionCreators.fnBuscaSaldo());
        }).catch(function (err) {
            getState().bndvcompletarf.SolicitarPesquisa.IsLoading = false;

            dispatch({ type: 'FAILURE_SOLICITAR_PESQUISA_BNDVCOMPLETARF_ACTION', SolicitarPesquisa: getState().bndvcompletarf.SolicitarPesquisa, ErrorText: err.message });
        });

        addTask(fetchTask);
        dispatch({ type: 'POST_SOLICITAR_PESQUISA_BNDVCOMPLETARF_ACTION', SolicitarPesquisa: getState().bndvcompletarf.SolicitarPesquisa });

    }
};

export const reducer = (state, action) => {
    state = state || initialState;

    if (action.type === REQUEST_CONSULTAS_BNDVCOMPLETARF_HISTORICO_ACTION) {
        return {
            ...state,
            Filters: action.Filters,
            Results: action.Results,
            IsLoading: true
        };
    }

    if (action.type === RECEIVE_CONSULTAS_BNDVCOMPLETARF_HISTORICO_ACTION) {
        return {
            ...state,
            Results: action.Results,
            IsLoading: false
        };
    }

    if (action.type === FAILURE_CONSULTAS_BNDVCOMPLETARF_HISTORICO_ACTION) {
        return {
            ...state,
            IsLoading: false,
            ErrorText: action.ErrorText
        };
    }

    if (action.type === REQUEST_RESULTADO_PESQUISA_BNDVCOMPLETARF_ACTION) {
        return {
            ...state,
            ModalResultadoPesquisa: action.ModalResultadoPesquisa,
            IsLoading: true
        };
    }

    if (action.type === RECEIVE_RESULTADO_PESQUISA_BNDVCOMPLETARF_ACTION) {
        return {
            ...state,
            ModalResultadoPesquisa: action.ModalResultadoPesquisa,
            IsLoading: false
        };
    }

    if (action.type === FAILURE_RESULTADO_PESQUISA_BNDVCOMPLETARF_ACTION) {
        return {
            ...state,
            IsLoading: false,
            ErrorText: action.ErrorText
        };
    }

    if (action.type === UPDATE_STATE_BNDVCOMPLETARF_ACTION) {
        return {
            ...state,
            Filters: action.Filters,
            Results: action.Results,
            ModalResultadoPesquisa: action.ModalResultadoPesquisa,
            SolicitarPesquisa: action.SolicitarPesquisa,
            IsLoading: action.IsLoading,
            ErrorText: action.ErrorText
        };
    }

    if (action.type === CLOSE_MODAL_RESULTADO_BNDVCOMPLETARF_ACTION) {
        return {
            ...state,
            ModalResultadoPesquisa: action.ModalResultadoPesquisa
        };
    }

    if (action.type === POST_SOLICITAR_PESQUISA_BNDVCOMPLETARF_ACTION) {
        return {
            ...state,
            SolicitarPesquisa: action.SolicitarPesquisa
        };
    }

    if (action.type === SUCCESS_SOLICITAR_PESQUISA_BNDVCOMPLETARF_ACTION) {
        return {
            ...state,
            SolicitarPesquisa: action.SolicitarPesquisa
        };
    }

    if (action.type === FAILURE_SOLICITAR_PESQUISA_BNDVCOMPLETARF_ACTION) {
        return {
            ...state,
            SolicitarPesquisa: action.SolicitarPesquisa,
            ErrorText: action.ErrorText
        };
    }

    return state;
};

