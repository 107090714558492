import * as React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actionCreators } from '../../../../../store/marketing/publicador/MarketingPublicadorConfig';

import Formsy from 'formsy-react';
import { Row, Modal } from 'react-bootstrap';
import ProgerssBar from '../../../../commons/ProgressBar';
import AlertError from '../../../../commons/AlertError';
import AlertSuccess from '../../../../commons/AlertSuccess';
import InputTextValidated from '../../../../commons/InputTextValidated';
import BtnSalvar from '../../../../commons/BtnSalvar';
import BtnCancelar from '../../../../commons/BtnCancelar';

class ModalCompreCar extends React.Component {
    constructor() {
        super();
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    async componentDidMount() {
        if (this.props.configuracaoPortais.modalPortais.modalCompreCar.showModal) {
            if (this.props.configuracaoPortais.modalPortais.ativado) {
                await this.props.fnBuscaConfiguracaoPortal();
                this.props.configuracaoPortais.modalPortais.modalCompreCar.id_cliente = JSON.parse(this.props.configuracaoPortais.modalPortais.jsAutenticacao).id_cliente;
                this.props.configuracaoPortais.modalPortais.modalCompreCar.token = JSON.parse(this.props.configuracaoPortais.modalPortais.jsAutenticacao).token;
                this.props.fnUpdateState(this.props);
            }
            else {
                this.props.configuracaoPortais.modalPortais.modalCompreCar.id_cliente = 0;
                this.props.configuracaoPortais.modalPortais.modalCompreCar.token = '';
                this.props.fnUpdateState(this.props);
            }
        }
    }

    async handleSubmit(e) {
        var autenticacao = {
            id_cliente: this.props.configuracaoPortais.modalPortais.modalCompreCar.id_cliente,
            token: this.props.configuracaoPortais.modalPortais.modalCompreCar.token
        };

        if (this.props.configuracaoPortais.modalPortais.ativado) {
            await this.props.fnAtualizaConfiguracaoPortal(JSON.stringify(autenticacao), '');
            this.props.configuracaoPortais.modalPortais.modalCompreCar.showModal = false;
        }
        else {
            await this.props.fnInsereConfiguracaoPortal(JSON.stringify(autenticacao), '');
            this.props.configuracaoPortais.modalPortais.modalCompreCar.showModal = false;
        }
    }

    handleOnHide() {
        this.props.configuracaoPortais.modalPortais.modalCompreCar.showModal = false;
        this.props.fnUpdateState(this.props);
    }

    render() {
        return <Modal
            show={this.props.configuracaoPortais.modalPortais.modalCompreCar.showModal}
            onHide={this.handleOnHide.bind(this)}
        >
            <Formsy onValidSubmit={this.handleSubmit}>
                <Modal.Header closeButton>
                    <h4 className="modal-title custom-font">Configurar CompreCar</h4>
                </Modal.Header>
                <Modal.Body>
                    <AlertSuccess
                        successText={this.props.configuracaoPortais.modalPortais.successText}
                        alertVisible={this.props.configuracaoPortais.modalPortais.successText != '' && this.props.configuracaoPortais.modalPortais.successText != undefined ? true : false}
                        onDismiss={(alertVisible, successText) => {
                            this.props.configuracaoPortais.modalPortais.successText = successText;
                            this.props.fnUpdateState(this.props);
                        }}
                    />
                    <AlertError
                        errorText={this.props.configuracaoPortais.modalPortais.errorText}
                        alertVisible={this.props.configuracaoPortais.modalPortais.errorText != '' && this.props.configuracaoPortais.modalPortais.errorText != undefined ? true : false}
                        onDismiss={(alertVisible, errorText) => {
                            this.props.configuracaoPortais.modalPortais.errorText = errorText;
                            this.props.fnUpdateState(this.props);
                        }}
                    />
                    {
                        this.props.configuracaoPortais.modalPortais.isLoading
                            ? <ProgerssBar />
                            : <Row>
                                <InputTextValidated
                                    name="txtIdCliente"
                                    type="number"
                                    className="col-md-12"
                                    placeholder="Informe o id do cliente"
                                    onChange={(name, value) => {
                                        this.props.configuracaoPortais.modalPortais.modalCompreCar.id_cliente = parseInt(value);
                                        this.props.fnUpdateState(this.props);
                                    }}
                                    value={this.props.configuracaoPortais.modalPortais.modalCompreCar.id_cliente.toString()}
                                    title="ID do cliente:"
                                    required
                                />
                                <InputTextValidated
                                    name="txtToken"
                                    type="text"
                                    className="col-md-12"
                                    placeholder="Informe o token"
                                    onChange={(name, value) => {
                                        this.props.configuracaoPortais.modalPortais.modalCompreCar.token = value;
                                        this.props.fnUpdateState(this.props);
                                    }}
                                    value={this.props.configuracaoPortais.modalPortais.modalCompreCar.token}
                                    title="Token:"
                                    required
                                />
                            </Row>
                    }
                </Modal.Body>
                <Modal.Footer>
                    {this.props.configuracaoPortais.modalPortais.ativado == true
                        ? <BtnCancelar
                            type="button"
                            text=" Remover Portal"
                            textLoading=" Aguarde..."
                            isLoading={this.props.configuracaoPortais.modalPortais.isLoading}
                            onClick={async () => {
                                await this.props.fnDeleteConfiguracaoPortal();
                                this.props.configuracaoPortais.modalPortais.modalCompreCar.showModal = false;
                                this.props.fnUpdateState(this.props);
                            }}
                        />
                        : null}
                    <BtnSalvar
                        type="submit"
                        text={this.props.configuracaoPortais.modalPortais.ativado == false ? " Salvar Configuração" : " Alterar Configuração"}
                        textLoading=" Aguarde..."
                        isLoading={this.props.configuracaoPortais.modalPortais.isLoading}
                    />
                </Modal.Footer>
            </Formsy>
        </Modal>;
    }
}

export default connect(
    state => state.marketing_publicadorconfig,
    dispatch => bindActionCreators(actionCreators, dispatch),
    null, {}
)(ModalCompreCar);