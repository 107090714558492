const changeEmpresaType = 'CHANGE_EMPRESA_ACTION';
const initialState = { empresaSelected: 0 };

export const actionCreators = {
    fnChangeEmpresa: (idEmpresa) => async (dispatch) => {
        dispatch({ type: 'CHANGE_EMPRESA_ACTION', empresaSelected: idEmpresa });
    }
};

export const reducer = (state, action) => {
    state = state || initialState;

    if (action.type === changeEmpresaType) {
        return {
            ...state,
            empresaSelected: action.empresaSelected
        };
    }

    return state;
};

