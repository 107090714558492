import * as React from 'react';
import { Alert } from 'react-bootstrap';

export default class AlertSuccess extends React.Component {
    constructor() {
        super();
        this.state = {
            alertVisible: false,
            successText: ''
        };
    }

    componentWillMount() {
        this.setState({ alertVisible: this.props.alertVisible, successText: this.props.successText });
    }

    componentWillReceiveProps(nextProps) {
        this.setState({ alertVisible: nextProps.alertVisible, successText: nextProps.successText });
    }

    handleAlertDismiss() {
        this.setState({ alertVisible: false, successText: '' });
        this.props.onDismiss(false, '');
    }

    render() {
        return (
            this.state.alertVisible
                ? <Alert bsStyle="success" onDismiss={this.handleAlertDismiss.bind(this)}>
                    <h4>OK tudo certo!</h4>
                    <p>{this.state.successText}</p>
                </Alert>
                : null
        );
    }
}