import * as React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actionCreators } from '../../../store/crm/CrmConsulta';
import Formsy from 'formsy-react';
import { Row, Panel } from 'react-bootstrap';
import Moment from 'react-moment';
import AlertError from '../../commons/AlertError';
import Section from '../../commons/Section';
import BtnPesquisar from '../../commons/BtnPesquisar';
import InputSelect from '../../commons/InputSelect';
import ListPager from '../../commons/ListPager';
import DateTimePicker from '../../commons/DateTimePicker';
import ModalDetalhes from './ModalDetalhes';
import * as crmmercadolivre_service from '../../../services/crm/crmmercadolivre_service';
import { FormattedNumber } from 'react-intl';

class ConsultaForm extends React.Component {
    constructor() {
        super();
        this.state = {
            list: {
                isError: false,
                errorText: '',
                isLoading: false,
                requested: false,
                filters: {
                    pageNumber: 0,
                    pageSize: 0,
                    totalNumberOfRecords: 0,
                    dataDe: null,
                    dataAte: null,
                    dataDeOriginal: null,
                    dataAteOriginal: null,
                    financialEntity: '',
                    status: ''
                },
                results: []
            },
            modalDetalhes: {
                show: false,
                id: ''
            }
        };
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.state.list.isLoading) {
            var self = this;
            var pDTO = {
                pageNumber: this.state.list.filters.pageNumber,
                pageSize: this.state.list.filters.pageSize,
                dataDe: this.state.list.filters.dataDe,
                dataAte: this.state.list.filters.dataAte,
                financialEntity: this.state.list.filters.financialEntity,
                status: this.state.list.filters.status
            };

            fetch(crmmercadolivre_service.services.listaMercadoLivreCreditosPreAprovados(this.props.auth.authorizationToken, pDTO))
                .then(response => {
                    if (response.status === 200 || response.status === 0) { return response; }
                    else { return response.json().then(function (object) { return Promise.reject(new Error(object)); }) }
                })
                .then(data => {
                    data.json().then(function (object) {
                        var dtoListResult = [];
                        object.results.forEach(function (entry) {
                            dtoListResult.push({
                                buyer_id: entry.buyer_id,
                                date_created: entry.date_created,
                                down_payment_amount: entry.down_payment_amount,
                                expired: entry.expired,
                                financial_entity_id: entry.financial_entity_id,
                                id: entry.id,
                                installments_amount: entry.installments_amount,
                                installments_number: entry.installments_number,
                                item_id: entry.item_id,
                                seller_id: entry.seller_id,
                                status: entry.status
                            });
                        });
                        self.setState({
                            ...self.state,
                            list: {
                                ...self.state.list,
                                isLoading: false,
                                requested: true,
                                results: dtoListResult,
                                filters: {
                                    ...self.state.list.filters,
                                    totalNumberOfRecords: object.paging.total
                                }
                            }
                        });
                    });
                }).catch(function (err) {
                    self.setState({
                        ...self.state,
                        list: {
                            ...self.state.list,
                            isError: true,
                            errorText: err.toString(),
                            isLoading: false
                        }
                    });
                });
        }
    }

    handleSubmit(e) {
        this.setState({
            ...this.state,
            list: {
                ...this.state.list,
                isError: false,
                errorText: '',
                isLoading: true,
                filters: {
                    ...this.state.list.filters,
                    pageNumber: 0,
                    pageSize: 10,
                }
            }
        });
    }

    render() {
        var self = this;
        return (<Row>
            <div className="col-md-12">
                <Section>
                    <Panel id="panelFiltro" bsStyle="primary" defaultExpanded>
                        <Panel.Heading>
                            <Panel.Title componentClass="h3" toggle><strong>Consulta</strong> créditos pré-aprovados</Panel.Title>
                        </Panel.Heading>
                        <Panel.Collapse>
                            <Panel.Body>
                                <div className="tile-body nopadding">
                                    <AlertError
                                        errorText={this.state.list.errorText}
                                        alertVisible={this.state.list.isError}
                                        onDismiss={() => {
                                            this.setState({
                                                ...this.state,
                                                list: {
                                                    ...this.state.list,
                                                    isError: false,
                                                    errorText: ''
                                                }
                                            });
                                        }}
                                    />
                                    <Formsy onValidSubmit={this.handleSubmit}>
                                        <Row>
                                            <div className="form-group col-md-3">
                                                <label>Data inicial</label>
                                                <DateTimePicker
                                                    onChange={(value) => {
                                                        this.setState({
                                                            ...this.state,
                                                            list: {
                                                                ...this.state.list,
                                                                filters: {
                                                                    ...this.state.list.filters,
                                                                    dataDe: `${value}`,
                                                                    dataDeOriginal: value
                                                                }
                                                            }
                                                        });
                                                    }}
                                                    required
                                                    value={this.state.list.filters.dataDeOriginal}
                                                />
                                            </div>
                                            <div className="form-group col-md-3">
                                                <label>Data final</label>
                                                <DateTimePicker
                                                    onChange={(value) => {
                                                        this.setState({
                                                            ...this.state,
                                                            list: {
                                                                ...this.state.list,
                                                                filters: {
                                                                    ...this.state.list.filters,
                                                                    dataAte: `${value}`,
                                                                    dataAteOriginal: value
                                                                }
                                                            }
                                                        });
                                                    }}
                                                    required
                                                    value={this.state.list.filters.dataAteOriginal}
                                                />
                                            </div>
                                            <InputSelect
                                                name="ddlFinancialEntity"
                                                className="col-md-3"
                                                data={[
                                                    { value: null, text: '' },
                                                    { value: 'VOTORANTIM', text: 'VOTORANTIM' },
                                                    { value: 'ITAU', text: 'ITAU' },
                                                    { value: 'SANTANDER', text: 'SANTANDER' }
                                                ]}
                                                onChange={(name, value) => {
                                                    this.setState({
                                                        ...this.state,
                                                        list: {
                                                            ...this.state.list,
                                                            filters: {
                                                                ...this.state.list.filters,
                                                                financialEntity: value
                                                            }
                                                        }
                                                    })
                                                }}
                                                title="Financeira:"
                                                selectedValue={this.state.list.filters.financialEntity === '' ? null : this.state.list.filters.financialEntity}
                                            />
                                            <InputSelect
                                                name="ddlStatus"
                                                className="col-md-3"
                                                data={[
                                                    { value: 'all', text: '' },
                                                    { value: 'approved', text: 'Aprovado' },
                                                    { value: 'rejected', text: 'Não aprovado' },
                                                    { value: 'in_analysis', text: 'Em análise' }
                                                ]}
                                                onChange={(name, value) => {
                                                    this.setState({
                                                        ...this.state,
                                                        list: {
                                                            ...this.state.list,
                                                            filters: {
                                                                ...this.state.list.filters,
                                                                status: value
                                                            }
                                                        }
                                                    })
                                                }}
                                                title="Status:"
                                                selectedValue={this.state.list.filters.status === '' ? null : this.state.list.filters.status}
                                            />
                                            <div className="form-group col-md-12" style={{ textAlign: 'right', marginTop: '23px' }}>
                                                <BtnPesquisar
                                                    type="submit"
                                                    isLoading={this.state.list.isLoading}
                                                    text=" Pesquisar"
                                                    textLoading=" Aguarde..."
                                                />
                                            </div>
                                        </Row>
                                    </Formsy>
                                </div>
                            </Panel.Body>
                        </Panel.Collapse>
                    </Panel>
                </Section>
                <Section>
                    {this.state.list.requested
                        ? <div className="tile-body">
                            <div className="table-responsive">
                                <div className="dataTables_wrapper form-inline dt-bootstrap no-footer">
                                    <div style={{ alignContent: 'space-between' }}>
                                        <div className="col-md-6">
                                            <InputSelect
                                                className=""
                                                data={[{ value: '10', text: '10' }, { value: '25', text: '25' }, { value: '50', text: '50' }]}
                                                onChange={(name, value) => {
                                                    this.setState({
                                                        ...this.state,
                                                        list: {
                                                            ...this.state.list,
                                                            isLoading: true,
                                                            filters: {
                                                                ...this.state.list.filters,
                                                                pageSize: value,
                                                                pageNumber: 0
                                                            }
                                                        }
                                                    });
                                                }}
                                                selectedValue={(this.state.list.filters.pageSize == null) ? null : this.state.list.filters.pageSize.toString()}
                                            /> registros por página
                                        </div>
                                    </div>
                                    <Row>
                                        <div className="col-sm-12">
                                            <table className="table table-custom dataTable no-footer">
                                                <thead>
                                                    <tr role="row">
                                                        <th></th>
                                                        <th>Id do Anúncio</th>
                                                        <th>Data</th>
                                                        <th>Banco</th>
                                                        <th>Entrada</th>
                                                        <th>Qtde Parcelas</th>
                                                        <th>Valor Parcela</th>
                                                        <th>Status</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        (this.state.list.isLoading)
                                                            ? <tr key={0} className="odd">
                                                                <td colSpan={8} className="dataTables_empty">
                                                                    <div className="progress progress-striped active">
                                                                        <div className="progress-bar progress-bar-blue" role="progressbar" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100" style={{ width: "100%" }}>
                                                                            <span className="sr-only">100% Complete</span>
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                            : (this.state.list.results.length == 0)
                                                                ? <tr key={0} className="odd">
                                                                    <td colSpan={8} className="dataTables_empty">
                                                                        <p className="text-warning">Não foram encontrados registros para a busca...</p>
                                                                    </td>
                                                                </tr>
                                                                : this.state.list.results.map(function (rowData, index) {
                                                                    return (
                                                                        <tr key={index}>
                                                                            <td>
                                                                                {
                                                                                    !rowData.expired
                                                                                        ? <a onClick={() => {
                                                                                            self.setState({
                                                                                                ...self.state,
                                                                                                modalDetalhes: {
                                                                                                    ...self.state.modalDetalhes,
                                                                                                    show: true,
                                                                                                    id: rowData.id
                                                                                                }
                                                                                            });
                                                                                        }}
                                                                                            style={{ cursor: 'pointer' }}>
                                                                                            <i className="fa fa-file"></i>
                                                                                        </a>
                                                                                        : null
                                                                                }
                                                                            </td>
                                                                            <td>{rowData.item_id}</td>
                                                                            <td><Moment format="DD/MM/YYYY HH:mm">{rowData.date_created}</Moment></td>
                                                                            <td>{rowData.financial_entity_id}</td>
                                                                            <td><FormattedNumber value={rowData.down_payment_amount} style="decimal" minimumFractionDigits={2} maximumFractionDigits={2} /></td>
                                                                            <td>{rowData.installments_number}</td>
                                                                            <td><FormattedNumber value={rowData.installments_amount} style="decimal" minimumFractionDigits={2} maximumFractionDigits={2} /></td>
                                                                            <td>{
                                                                                rowData.status === 'approved' ? <span className="label label-success">Aprovado</span> 
                                                                                    : rowData.status === 'rejected' ? <span className="label label-danger">Não aprovado</span>                                                                                    
                                                                                        : rowData.status === 'in_analysis' ? <span className="label label-warning">Em análise</span>  
                                                                                        : null
                                                                            }</td>
                                                                        </tr>
                                                                    )
                                                                })
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                    </Row>
                                    <br />
                                    <Row>
                                        <div className="col-md-3">
                                            <div className="dataTables_info"> Mostrando&nbsp; {
                                                (this.state.list.filters.pageSize > this.state.list.filters.totalNumberOfRecords)
                                                    ? this.state.list.filters.totalNumberOfRecords : this.state.list.filters.pageSize
                                            } de {this.state.list.filters.totalNumberOfRecords}
                                            </div>
                                        </div>
                                        <div className="col-md-9">
                                            <div className="dataTables_paginate paging_simple_numbers">
                                                <ListPager
                                                    CurrentPage={this.state.list.filters.pageNumber + 1}
                                                    Size={
                                                        (this.state.list.filters.totalNumberOfRecords / this.state.list.filters.pageSize) === 0
                                                            ? (this.state.list.filters.totalNumberOfRecords / this.state.list.filters.pageSize)
                                                            : (this.state.list.filters.totalNumberOfRecords / this.state.list.filters.pageSize) + 1
                                                    }
                                                    OnPageChanged={
                                                        (page) => {
                                                            this.setState({
                                                                ...this.state,
                                                                list: {
                                                                    ...this.state.list,
                                                                    isLoading: true,
                                                                    filters: {
                                                                        ...this.state.list.filters,
                                                                        pageNumber: (page - 1)
                                                                    }
                                                                }
                                                            });
                                                        }
                                                    }
                                                />
                                            </div>
                                        </div>
                                    </Row>
                                </div>
                            </div>
                        </div>
                        : null
                    }
                </Section>
            </div>
            {
                this.state.modalDetalhes.show
                    ? <ModalDetalhes
                        id={this.state.modalDetalhes.id}
                        closeModal={() => {
                            this.setState({
                                ...this.state,
                                modalDetalhes: {
                                    ...this.state.modalDetalhes,
                                    show: false,
                                    id: ''
                                }
                            });
                        }} />
                    : null
            }
        </Row>);
    }
}

function mapStateToProps(state) {
    return { auth: state.auth }
}

export default connect(
    mapStateToProps,
    dispatch => bindActionCreators(actionCreators, dispatch),
    null, {}
)(ConsultaForm);
