import * as funcs from '../../../utils/funcs';

//const _URL = 'http://localhost:60402/api/v1/publicador/';
//const _URL = 'https://apisistema.bndv.com.br/Marketing/api/v1/publicador/';
const _URL = 'https://bndv20-marketing-api.azurewebsites.net/api/v1/publicador/';

export const services = {
    listaVeiculosPublicador: (pDTO, authorizationToken) => {
        var query = funcs.encodeURI(pDTO);
        return new Request(_URL + 'listaVeiculosPublicador',
            {
                method: 'POST',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
                    'Authorization': 'Bearer ' + authorizationToken
                },
                body: query
            });
    },
    obterVeiculo: (pidVeiculo, authorizationToken) => {
        return new Request(_URL + 'obterVeiculo?idVeiculo=' + pidVeiculo.toString(),
            {
                method: 'GET',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
                    'Authorization': 'Bearer ' + authorizationToken
                }
            });
    },
    obterUltimoHistoricoAnuncio: (pidAnuncio, authorizationToken) => {
        return new Request(_URL + 'obterUltimoHistoricoAnuncio?idAnuncio=' + pidAnuncio.toString(),
            {
                method: 'GET',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
                    'Authorization': 'Bearer ' + authorizationToken
                }
            });
    },
    obterFotoVeiculo: (pidVeiculo, pidFoto, authorizationToken) => {
        return new Request(_URL + 'obterFotoVeiculo?idVeiculo=' + pidVeiculo.toString() + '&idVeiculoFoto=' + pidFoto.toString(),
            {
                method: 'GET',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
                    'Authorization': 'Bearer ' + authorizationToken
                }
            });
    },
    obterFotosVeiculo: (pidVeiculo, authorizationToken) => {
        return new Request(_URL + 'obterFotosVeiculo?idVeiculo=' + pidVeiculo.toString(),
            {
                method: 'GET',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
                    'Authorization': 'Bearer ' + authorizationToken
                }
            });
    },
    listaPortaisAtivos: (authorizationToken) => {
        return new Request(_URL + 'obterPortaisAtivos',
            {
                method: 'GET',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
                    'Authorization': 'Bearer ' + authorizationToken
                }
            });
    },
    listaPortaisVeiculo: (pidVeiculo, authorizationToken) => {
        return new Request(_URL + 'obterPortaisVeiculo?idVeiculo=' + pidVeiculo.toString(),
            {
                method: 'GET',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
                    'Authorization': 'Bearer ' + authorizationToken
                }
            });
    },
    listaPatios: (authorizationToken) => {
        return new Request(_URL + 'obterPatios',
            {
                method: 'GET',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
                    'Authorization': 'Bearer ' + authorizationToken
                }
            });
    },
    listaMarcas: (pDTO, authorizationToken) => {
        var query = funcs.encodeURI(pDTO);
        return new Request(_URL + 'obterMarcas',
            {
                method: 'POST',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
                    'Authorization': 'Bearer ' + authorizationToken
                },
                body: query
            });
    },
    listaModelos: (pDTO, authorizationToken) => {
        var query = funcs.encodeURI(pDTO);
        return new Request(_URL + 'obterModelos',
            {
                method: 'POST',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
                    'Authorization': 'Bearer ' + authorizationToken
                },
                body: query
            });
    },
    listaAnosModelo: (pDTO, authorizationToken) => {
        var query = funcs.encodeURI(pDTO);
        return new Request(_URL + 'obterTiposAnosModelo',
            {
                method: 'POST',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
                    'Authorization': 'Bearer ' + authorizationToken
                },
                body: query
            });
    }, 
    listaVersoes: (pDTO, authorizationToken) => {
        var query = funcs.encodeURI(pDTO);
        return new Request(_URL + 'obterVersoes',
            {
                method: 'POST',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
                    'Authorization': 'Bearer ' + authorizationToken
                },
                body: query
            });
    },
    listaCategorias: (pDTO, authorizationToken) => {
        var query = funcs.encodeURI(pDTO);
        return new Request(_URL + 'obterTiposCategoria',
            {
                method: 'POST',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
                    'Authorization': 'Bearer ' + authorizationToken
                },
                body: query
            });
    },
    listaPlanos: (pDTO, authorizationToken) => {
        var query = funcs.encodeURI(pDTO);
        return new Request(_URL + 'obterTiposModalidade',
            {
                method: 'POST',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
                    'Authorization': 'Bearer ' + authorizationToken
                },
                body: query
            });
    },
    listaAdicionais: (pDTO, authorizationToken) => {
        var query = funcs.encodeURI(pDTO);
        return new Request(_URL + 'obterTiposAdicional',
            {
                method: 'POST',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
                    'Authorization': 'Bearer ' + authorizationToken
                },
                body: query
            });
    },
    listaRefrigeracao: (pDTO, authorizationToken) => {
        var query = funcs.encodeURI(pDTO);
        return new Request(_URL + 'obterRefrigeracao',
            {
                method: 'POST',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
                    'Authorization': 'Bearer ' + authorizationToken
                },
                body: query
            });
    },
    listaAlimentacao: (pDTO, authorizationToken) => {
        var query = funcs.encodeURI(pDTO);
        return new Request(_URL + 'obterAlimentacao',
            {
                method: 'POST',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
                    'Authorization': 'Bearer ' + authorizationToken
                },
                body: query
            });
    },
    listaMotor: (pDTO, authorizationToken) => {
        var query = funcs.encodeURI(pDTO);
        return new Request(_URL + 'obterMotor',
            {
                method: 'POST',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
                    'Authorization': 'Bearer ' + authorizationToken
                },
                body: query
            });
    },
    listaPartida: (pDTO, authorizationToken) => {
        var query = funcs.encodeURI(pDTO);
        return new Request(_URL + 'obterPartida',
            {
                method: 'POST',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
                    'Authorization': 'Bearer ' + authorizationToken
                },
                body: query
            });
    },
    listaMarcha: (pDTO, authorizationToken) => {
        var query = funcs.encodeURI(pDTO);
        return new Request(_URL + 'obterMarcha',
            {
                method: 'POST',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
                    'Authorization': 'Bearer ' + authorizationToken
                },
                body: query
            });
    },
    listaFreio: (pDTO, authorizationToken) => {
        var query = funcs.encodeURI(pDTO);
        return new Request(_URL + 'obterFreio',
            {
                method: 'POST',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
                    'Authorization': 'Bearer ' + authorizationToken
                },
                body: query
            });
    },
    listaBlindagem: (pDTO, authorizationToken) => {
        var query = funcs.encodeURI(pDTO);
        return new Request(_URL + 'obterBlindagem',
            {
                method: 'POST',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
                    'Authorization': 'Bearer ' + authorizationToken
                },
                body: query
            });
    },
    listaCilindradas: (pDTO, authorizationToken) => {
        var query = funcs.encodeURI(pDTO);
        return new Request(_URL + 'obterCilindradas',
            {
                method: 'POST',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
                    'Authorization': 'Bearer ' + authorizationToken
                },
                body: query
            });
    },
    obterAnuncioExtensao: (pDTO, authorizationToken) => {
        var query = funcs.encodeURI(pDTO);
        return new Request(_URL + 'obterAnuncioExtensao',
            {
                method: 'POST',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
                    'Authorization': 'Bearer ' + authorizationToken
                },
                body: query
            });
    },
    listaRecomendacoes: (pDTO, authorizationToken) => {
        var query = funcs.encodeURI(pDTO);
        return new Request(_URL + 'obterRecomendacoes',
            {
                method: 'POST',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
                    'Authorization': 'Bearer ' + authorizationToken
                },
                body: query
            });
    },
    visualizarRecomendacao: (pDTO, authorizationToken) => {
        var query = funcs.encodeURI(pDTO);
        return new Request(_URL + 'visualizarRecomendacao',
            {
                method: 'PUT',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
                    'Authorization': 'Bearer ' + authorizationToken
                },
                body: query
            });
    },
    inserirPublicacao: (pDTO, authorizationToken) => {
        var query = funcs.encodeURI(pDTO);
        return new Request(_URL + 'inserirPublicacao',
            {
                method: 'POST',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
                    'Authorization': 'Bearer ' + authorizationToken
                },
                body: query
            });
    },
    alterarPublicacao: (pDTO, authorizationToken) => {
        var query = funcs.encodeURI(pDTO);
        return new Request(_URL + 'alterarPublicacao',
            {
                method: 'PUT',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
                    'Authorization': 'Bearer ' + authorizationToken
                },
                body: query
            });
    },
    removerPublicacao: (pDTO, authorizationToken) => {
        var query = funcs.encodeURI(pDTO);
        return new Request(_URL + 'removerPublicacao',
            {
                method: 'DELETE',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
                    'Authorization': 'Bearer ' + authorizationToken
                },
                body: query
            });
    },
    inserirPublicacaoMoto: (pDTO, authorizationToken) => {
        var query = funcs.encodeURI(pDTO);
        return new Request(_URL + 'inserirPublicacaoMoto',
            {
                method: 'POST',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
                    'Authorization': 'Bearer ' + authorizationToken
                },
                body: query
            });
    },
    alterarPublicacaoMoto: (pDTO, authorizationToken) => {
        var query = funcs.encodeURI(pDTO);
        return new Request(_URL + 'alterarPublicacaoMoto',
            {
                method: 'PUT',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
                    'Authorization': 'Bearer ' + authorizationToken
                },
                body: query
            });
    },
    removerPublicacaoMoto: (pDTO, authorizationToken) => {
        var query = funcs.encodeURI(pDTO);
        return new Request(_URL + 'removerPublicacaoMoto',
            {
                method: 'DELETE',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
                    'Authorization': 'Bearer ' + authorizationToken
                },
                body: query
            });
    }
}