import * as React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { actionCreators } from '../../../store/crm/CrmConsulta';
import Formsy from 'formsy-react';
import { Row, Panel } from 'react-bootstrap';
import Moment from 'react-moment';
import AlertError from '../../commons/AlertError';
import Section from '../../commons/Section';
import BtnPesquisar from '../../commons/BtnPesquisar';
import InputSelect from '../../commons/InputSelect';
import ListPager from '../../commons/ListPager';
import DateTimePicker from '../../commons/DateTimePicker';
import ModalDetalhes from './ModalDetalhes';
import ModalNovoLead from './ModalNovoLead';
import SwitchOnOff from '../../commons/SwitchOnOff';
import exportFromJSON from 'export-from-json'
import BtnImprimir from '../../commons/BtnImprimir';

class ConsultaForm extends React.Component {
    constructor() {
        super();
        this.handleSubmit = this.handleSubmit.bind(this);
        this.exportData = this.exportData.bind(this);
    }


    componentDidMount() {
        this.props.fnListaStatus();
        this.props.fnListaEtapaFunil();
        this.props.fnListaResponsavel();
        this.props.fnListaCanaisOportunidade();
        this.props.fnListaTemperaturaConsulta();
    }

    handleSubmit(e) {
        this.props.crm_consulta.consultaOportunidades.pageNumber = 1;
        this.props.crm_consulta.consultaOportunidades.pageSize = 10;
        this.props.fnListaOportunidades();
    }

    async exportData() {
        await this.props.fnListaOportunidadesExport();
        if (this.props.crm_consulta.consultaOportunidadesExport.results.length > 0) {
            const data = this.props.crm_consulta.consultaOportunidadesExport.results;
            const fileName = 'download'
            const exportType = 'xls'
            exportFromJSON({ data, fileName, exportType })
        }
    }

    render() {
        var objProps = this.props;
       
        //obtendo e formatando a dh atual
        //var current_datetime = new Date();
        //var month = (current_datetime.getMonth() + 1).toString();
        //var dateString = current_datetime.getFullYear() + "-" + ("00".substr(0, 2 - (month.length)) + (month)) + "-" + current_datetime.getDate();


        var dropdownStatus = [];
        dropdownStatus.push({ value: null, text: '' });
        objProps.crm_consulta.consultaOportunidades.dropdownStatus.results.forEach(function (entry) {
            dropdownStatus.push({ value: entry.id.toString(), text: entry.nome });
        });

        var dropdownEtapa = [];
        dropdownEtapa.push({ value: null, text: '' });
        objProps.crm_consulta.consultaOportunidades.dropdownEtapa.results.forEach(function (entry) {
            dropdownEtapa.push({ value: entry.id.toString(), text: entry.nome });
        });

        var dropdownCanalOportunidade = [];
        dropdownCanalOportunidade.push({ value: null, text: '' });
        objProps.crm_consulta.consultaOportunidades.dropdownCanalOportunidade.results.forEach(function (entry) {
            dropdownCanalOportunidade.push({ value: entry.id.toString(), text: entry.nome });
        });

        var dropdownResponsavel = [];
        dropdownResponsavel.push({ value: null, text: 'Todos...' });
        objProps.crm_consulta.consultaOportunidades.dropdownResponsavel.results.forEach(function (entry) {
            dropdownResponsavel.push({ value: entry.id.toString(), text: entry.nome });
        });

        var dropdownTemperatura = [];
        dropdownTemperatura.push({ value: null, text: '' });
        objProps.crm_consulta.consultaOportunidades.dropdownTemperatura.results.forEach(function (entry) {
            dropdownTemperatura.push({ value: entry.id.toString(), text: entry.nome });
        });

        return (<Row>
            <div className="col-md-12">
                <Section>
                    <Panel id="panelFiltro" bsStyle="primary" defaultExpanded>
                        <Panel.Heading>
                            <Panel.Title componentClass="h3" toggle><strong>Consulta</strong> oportunidades</Panel.Title>
                        </Panel.Heading>
                        <Panel.Collapse>
                            <Panel.Body>
                                <div className="tile-body nopadding">
                                    <AlertError
                                        errorText={this.props.crm_consulta.consultaOportunidades.errorText}
                                        alertVisible={this.props.crm_consulta.consultaOportunidades.errorText != '' && this.props.crm_consulta.consultaOportunidades.errorText != undefined ? true : false}
                                        onDismiss={(alertVisible, errorText) => {
                                            this.props.crm_consulta.consultaOportunidades.errorText = errorText;
                                            this.props.crm_consulta.fnUpdateState(this.props.crm_consulta);
                                        }}
                                    />
                                    <Formsy onValidSubmit={this.handleSubmit}>
                                        <Row>
                                            <InputSelect
                                                name="ddlEtapa"
                                                className="col-md-3"
                                                data={
                                                    this.props.crm_consulta.consultaOportunidades.dropdownEtapa.isLoading
                                                        ? [{ value: null, text: 'Carregando...' }]
                                                        : this.props.crm_consulta.consultaOportunidades.dropdownEtapa.errorText != ''
                                                            ? [{ value: null, text: this.props.crm_consulta.consultaOportunidades.dropdownEtapa.errorText }]
                                                            : dropdownEtapa
                                                }
                                                onChange={(name, value) => {
                                                    this.props.crm_consulta.consultaOportunidades.idEtapaFunilVendas = value;
                                                    this.props.fnUpdateState(this.props.crm_consulta);
                                                }}
                                                title="Etapa:"
                                                disabled={this.props.crm_consulta.consultaOportunidades.dropdownEtapa.isLoading ? true : false}
                                                selectedValue={(this.props.crm_consulta.consultaOportunidades.idEtapaFunilVendas == null) ? null : this.props.crm_consulta.consultaOportunidades.idEtapaFunilVendas.toString()}
                                            />
                                            <InputSelect
                                                name="ddlStatus"
                                                className="col-md-3"
                                                data={
                                                    this.props.crm_consulta.consultaOportunidades.dropdownStatus.isLoading
                                                        ? [{ value: null, text: 'Carregando...' }]
                                                        : this.props.crm_consulta.consultaOportunidades.dropdownStatus.errorText != ''
                                                            ? [{ value: null, text: this.props.crm_consulta.consultaOportunidades.dropdownStatus.errorText }]
                                                            : dropdownStatus
                                                }
                                                onChange={(name, value) => {
                                                    this.props.crm_consulta.consultaOportunidades.idOportunidadeStatus = value;
                                                    this.props.fnUpdateState(this.props.crm_consulta);
                                                }}
                                                title="Status:"
                                                disabled={this.props.crm_consulta.consultaOportunidades.dropdownStatus.isLoading ? true : false}
                                                selectedValue={(this.props.crm_consulta.consultaOportunidades.idOportunidadeStatus == null) ? null : this.props.crm_consulta.consultaOportunidades.idOportunidadeStatus.toString()}
                                            />
                                            <div className="form-group col-md-3">
                                                <label>Data inicial</label>
                                                <DateTimePicker
                                                    onChange={(value) => {
                                                        this.props.crm_consulta.consultaOportunidades.dataDe = (value === "" ? null : `${value} 00:00`);
                                                        this.props.crm_consulta.consultaOportunidades.dataDeOriginal = value;
                                                        this.props.fnUpdateState(this.props.crm_consulta);                                                      
                                                    }}                                                    
                                                    value={this.props.crm_consulta.consultaOportunidades.dataDeOriginal}
                                                    valid={true}
                                                />
                                            </div>
                                            <div className="form-group col-md-3">
                                                <label>Data final</label>  
                                                <DateTimePicker
                                                    onChange={(value) => {
                                                        this.props.crm_consulta.consultaOportunidades.dataAte = (value === "" ? null : `${value} 23:59`);
                                                        this.props.crm_consulta.consultaOportunidades.dataAteOriginal = value;
                                                        this.props.fnUpdateState(this.props.crm_consulta);                                                        
                                                    }}                                                    
                                                    value={this.props.crm_consulta.consultaOportunidades.dataAteOriginal}
                                               />
                                            </div>
                                            <InputSelect
                                                name="ddlCanalOportunidade"
                                                className="col-md-3"
                                                data={
                                                    this.props.crm_consulta.consultaOportunidades.dropdownCanalOportunidade.isLoading
                                                        ? [{ value: null, text: 'Carregando...' }]
                                                        : this.props.crm_consulta.consultaOportunidades.dropdownCanalOportunidade.errorText != ''
                                                            ? [{ value: null, text: this.props.crm_consulta.consultaOportunidades.dropdownCanalOportunidade.errorText }]
                                                            : dropdownCanalOportunidade
                                                }
                                                onChange={(name, value) => {
                                                    this.props.crm_consulta.consultaOportunidades.idCanalLead = value;
                                                    this.props.fnUpdateState(this.props.crm_consulta);
                                                }}
                                                title="Canal:"
                                                disabled={this.props.crm_consulta.consultaOportunidades.dropdownCanalOportunidade.isLoading ? true : false}
                                                selectedValue={(this.props.crm_consulta.consultaOportunidades.idCanalLead == null) ? null : this.props.crm_consulta.consultaOportunidades.idCanalLead.toString()}
                                            />
                                            <InputSelect
                                                name="ddlReponsavel"
                                                className="col-md-6"
                                                data={
                                                    this.props.crm_consulta.consultaOportunidades.dropdownResponsavel.isLoading
                                                        ? [{ value: null, text: 'Carregando...' }]
                                                        : this.props.crm_consulta.consultaOportunidades.dropdownResponsavel.errorText !== ''
                                                            ? [{ value: null, text: this.props.crm_consulta.consultaOportunidades.dropdownResponsavel.errorText }]
                                                            : dropdownResponsavel
                                                }
                                                onChange={(name, value) => {
                                                    this.props.crm_consulta.consultaOportunidades.idResponsavel = value;
                                                    this.props.fnUpdateState(this.props.crm_consulta);

                                                    console.log(this.props.crm_consulta.consultaOportunidades.idResponsavel);
                                                }}
                                                title="Responsável:"
                                                disabled={this.props.crm_consulta.consultaOportunidades.dropdownResponsavel.isLoading ? true : false}
                                                selectedValue={
                                                                this.props.crm_consulta.consultaOportunidades.idResponsavel == null
                                                                ? null
                                                                : this.props.crm_consulta.consultaOportunidades.idResponsavel.toString()}
                                            />
                                            <div className="form-group col-md-3">
                                                <label>Somente não atribuídos</label><br />
                                                <SwitchOnOff
                                                    class="onoffswitch-checkbox"
                                                    id="chkNaoAtribuidos"
                                                    onChange={(name, value) => {
                                                        this.props.crm_consulta.consultaOportunidades.naoAtribuido = (value == 'on' ? false : true);
                                                        this.props.fnUpdateState(this.props.crm_consulta);
                                                    }}
                                                    value={this.props.crm_consulta.consultaOportunidades.naoAtribuido == true ? 'on' : 'off'}
                                                />
                                            </div>
                                            <InputSelect
                                                name="ddlTemperaturaOportunidade"
                                                className="col-md-3"
                                                data={
                                                    this.props.crm_consulta.consultaOportunidades.dropdownTemperatura.isLoading
                                                        ? [{ value: null, text: 'Carregando...' }]
                                                        : this.props.crm_consulta.consultaOportunidades.dropdownTemperatura.errorText != ''
                                                            ? [{ value: null, text: this.props.crm_consulta.consultaOportunidades.dropdownTemperatura.errorText }]
                                                            : dropdownTemperatura
                                                }
                                                onChange={(name, value) => {
                                                    this.props.crm_consulta.consultaOportunidades.idTemperatura = value;
                                                    this.props.fnUpdateState(this.props.crm_consulta);
                                                }}
                                                title="Temperatura:"
                                                disabled={this.props.crm_consulta.consultaOportunidades.dropdownTemperatura.isLoading ? true : false}
                                                selectedValue={(this.props.crm_consulta.consultaOportunidades.idTemperatura == null) ? null : this.props.crm_consulta.consultaOportunidades.idTemperatura.toString()}
                                            />
                                            <div className="form-group col-md-3">
                                                <label>Data de Encerramento de:</label>
                                                <DateTimePicker
                                                    onChange={(value) => {
                                                        this.props.crm_consulta.consultaOportunidades.dataEncerramentoDe = (value === "" ? null : `${value} 00:00`);
                                                        this.props.crm_consulta.consultaOportunidades.dataEncerramentoDeOriginal = value;
                                                        this.props.fnUpdateState(this.props.crm_consulta);
                                                    }}                                                    
                                                    value={this.props.crm_consulta.consultaOportunidades.dataEncerramentoDeOriginal}
                                                />
                                            </div>
                                            <div className="form-group col-md-3">
                                                <label>Data de Encerramento até:</label>
                                                <DateTimePicker
                                                    onChange={(value) => {
                                                        this.props.crm_consulta.consultaOportunidades.dataEncerramentoAte = (value === "" ? null : `${value} 23:59`);
                                                        this.props.crm_consulta.consultaOportunidades.dataEncerramentoAteOriginal = value;
                                                        this.props.fnUpdateState(this.props.crm_consulta);
                                                    }}                                                    
                                                    value={this.props.crm_consulta.consultaOportunidades.dataEncerramentoAteOriginal}
                                                />
                                            </div>

                                            <div className="form-group col-md-12" style={{ textAlign: 'right' }}>
                                                <a className="btn btn-success" onClick={() => {
                                                    this.props.crm_consulta.modalNovoLead.showModal = true;
                                                    this.props.fnUpdateState(this.props.crm_consulta);
                                                }}
                                                    style={{ marginRight: 10 }}
                                                >
                                                    Novo Lead
                                                </a>
                                                <BtnPesquisar
                                                    type="submit"
                                                    isLoading={this.props.crm_consulta.consultaOportunidades.isLoading}
                                                    text=" Pesquisar"
                                                    textLoading=" Aguarde..."
                                                />
                                            </div>
                                        </Row>
                                    </Formsy>
                                </div>
                            </Panel.Body>
                        </Panel.Collapse>
                    </Panel>
                </Section>
                <Section>
                    {this.props.crm_consulta.consultaOportunidades.requested
                        ? <div className="tile-body">
                            <div className="table-responsive">
                                <div className="dataTables_wrapper form-inline dt-bootstrap no-footer">
                                    <div style={{ alignContent: 'space-between' }}>
                                        <div className="col-md-6">
                                            <InputSelect
                                                className=""
                                                data={[{ value: '10', text: '10' }, { value: '25', text: '25' }, { value: '50', text: '50' }]}
                                                onChange={(name, value) => {
                                                    this.props.crm_consulta.consultaOportunidades.pageSize = value;
                                                    this.props.crm_consulta.consultaOportunidades.pageNumber = 1;
                                                    this.props.fnUpdateState(this.props.crm_consulta);
                                                    this.props.fnListaOportunidades();
                                                }}
                                                selectedValue={(this.props.crm_consulta.consultaOportunidades.pageSize == null) ? null : this.props.crm_consulta.consultaOportunidades.pageSize.toString()}
                                            /> registros por página
                                        </div>
                                        <div className="col-md-6" style={{ textAlign: 'right' }}>
                                            {
                                                !this.props.crm_consulta.consultaOportunidadesExport.isLoading
                                                    ? <i style={{ fontSize: '20px', color: 'green' }} className="fa fa-file-excel-o" onClick={() => { this.exportData() }}></i>
                                                    : null
                                            }
                                        </div>
                                    </div>
                                    <Row>
                                        <div className="col-sm-12">
                                            <table className="table table-custom dataTable no-footer">
                                                <thead>
                                                    <tr role="row">
                                                        <th></th>
                                                        <th>Canal</th>
                                                        <th>Cliente</th>
                                                        <th>Contato</th>
                                                        <th>Data</th>
                                                        <th>Etapa</th>
                                                        <th>Status</th>
                                                        <th>Responsável</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        (this.props.crm_consulta.consultaOportunidades.isLoading)
                                                            ? <tr key={0} className="odd">
                                                                <td colSpan={8} className="dataTables_empty">
                                                                    <div className="progress progress-striped active">
                                                                        <div className="progress-bar progress-bar-blue" role="progressbar" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100" style={{ width: "100%" }}>
                                                                            <span className="sr-only">100% Complete</span>
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                            : (this.props.crm_consulta.consultaOportunidades.results.length == 0)
                                                                ? <tr key={0} className="odd">
                                                                    <td colSpan={8} className="dataTables_empty">
                                                                        <p className="text-warning">Não foram encontrados registros para a busca...</p>
                                                                    </td>
                                                                </tr>
                                                                : this.props.crm_consulta.consultaOportunidades.results.map(function (rowData, index) {
                                                                    return (
                                                                        <tr key={index}>
                                                                            <td>
                                                                                <Link to={"/crm/detalhes/" + rowData.id}><i className="fa fa-file"></i></Link>
                                                                            </td>
                                                                            <td>{rowData.nomeCanalLead}</td>
                                                                            <td>{rowData.nomeCliente}</td>
                                                                            <td>{rowData.emailCliente}<br />{rowData.telefoneCliente}</td>
                                                                            <td><Moment format="DD/MM/YYYY HH:mm">{rowData.dataCadastro}</Moment></td>
                                                                            <td>{
                                                                                rowData.idFunilVendas == 1 ?
                                                                                    <span className="label label-info">{rowData.nomeEtapaFunilVendas}</span>
                                                                                    : rowData.idFunilVendas == 2 ?
                                                                                        <span className="label label-warning">{rowData.nomeEtapaFunilVendas}</span>
                                                                                        : rowData.idFunilVendas == 3 ?
                                                                                            <span className="label label-primary">{rowData.nomeEtapaFunilVendas}</span>
                                                                                            : <span className="label label-success">{rowData.nomeEtapaFunilVendas}</span>
                                                                            }</td>
                                                                            <td>{
                                                                                rowData.idOportunidadeStatus == 1 ?
                                                                                    <span className="label label-warning">{rowData.nomeOportunidadeStatus}</span>
                                                                                    : rowData.idOportunidadeStatus == 2 ?
                                                                                        <span className="label label-danger">{rowData.nomeOportunidadeStatus}</span>
                                                                                        : <span className="label label-success">{rowData.nomeOportunidadeStatus}</span>
                                                                            }</td>
                                                                            <td>{rowData.nomeUsuario}</td>
                                                                        </tr>
                                                                    )
                                                                })
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                    </Row>
                                    <br />
                                    <Row>
                                        <div className="col-md-3">
                                            <div className="dataTables_info"> Mostrando&nbsp; {
                                                (this.props.crm_consulta.consultaOportunidades.pageSize > this.props.crm_consulta.consultaOportunidades.totalNumberOfRecords)
                                                    ? this.props.crm_consulta.consultaOportunidades.totalNumberOfRecords : this.props.crm_consulta.consultaOportunidades.pageSize
                                            } de {this.props.crm_consulta.consultaOportunidades.totalNumberOfRecords}
                                            </div>
                                        </div>
                                        <div className="col-md-9">
                                            <div className="dataTables_paginate paging_simple_numbers">
                                                <ListPager
                                                    CurrentPage={this.props.crm_consulta.consultaOportunidades.pageNumber}
                                                    Size={this.props.crm_consulta.consultaOportunidades.totalNumberOfPages}
                                                    OnPageChanged={
                                                        (page) => {
                                                            this.props.crm_consulta.consultaOportunidades.pageNumber = page;
                                                            this.props.fnListaOportunidades();
                                                        }
                                                    }
                                                />
                                            </div>
                                        </div>
                                    </Row>
                                </div>
                            </div>
                        </div>
                        : null
                    }
                </Section>
                {this.props.crm_consulta.modalDetalhes.showModal ? <ModalDetalhes /> : null}
                {this.props.crm_consulta.modalNovoLead.showModal ? <ModalNovoLead /> : null}

            </div>
        </Row>);
    }
}

function mapStateToProps(state) {
    return {
        ...state,
        auth: state.auth,
        crm_consulta: state.crm_consulta
    }
}

export default connect(
    mapStateToProps,
    dispatch => bindActionCreators(actionCreators, dispatch),
    null, {}
)(ConsultaForm);
