import * as React from "react";

export default class BtnEntendi extends React.Component {
    onClickEntendi() {
        this.props.onClick();
    }

    render() {
        return <button
            type={this.props.type}
            className={(this.props.isLoading) ? "btn btn-dark" : "btn btn-cyan"}
            disabled={this.props.isLoading ? true : false}
            onClick={this.onClickEntendi.bind(this)}
        >
            <i className="fa fa-thumbs-up"></i>
            {(this.props.isLoading) ? this.props.textLoading : this.props.text}
        </button>;
    }
}