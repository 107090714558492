const changeMenuType = 'CHANGE_MENU';
const carregaModulosType = 'CARREGA_MODULOS';
const initialState = {
    menuClass: null,
    subMenuClass: null,
    modulos: []
};

export const actionCreators = {
    fnChangeMenu: (menuClass, subMenuClass) => async (dispatch) => {
        dispatch({ type: 'CHANGE_MENU', menuClass: menuClass, subMenuClass: subMenuClass });
    }
    , fnCarregaModulos: (paramModulos) => async (dispatch) => {
        dispatch({ type: 'CARREGA_MODULOS', modulos: paramModulos });
    } 
};

export const reducer = (state, action) => {
    state = state || initialState;

    if (action.type === changeMenuType) {
        return {
            ...state,
            menuClass: action.menuClass,
            subMenuClass: action.subMenuClass
        };
    }

    if (action.type === carregaModulosType) {
        return {
            ...state,
            modulos: action.modulos
        };
    }

    return state;
};

