import $ from 'jquery';
import jRespond from 'jrespond';
import screenfull from 'screenfull';
import * as moment from 'moment';

var MINOVATE = MINOVATE || {};

// Global functions
function global_deviceSize() {
    var jRes = jRespond([
        {
            label: 'smallest',
            enter: 0,
            exit: 479
        }, {
            label: 'handheld',
            enter: 480,
            exit: 767
        }, {
            label: 'tablet',
            enter: 768,
            exit: 991
        }, {
            label: 'laptop',
            enter: 992,
            exit: 1199
        }, {
            label: 'desktop',
            enter: 1200,
            exit: 10000
        }
    ]);
    jRes.addFunc([
        {
            breakpoint: 'desktop',
            enter: function () { $body.addClass('device-lg'); },
            exit: function () { $body.removeClass('device-lg'); }
        }, {
            breakpoint: 'laptop',
            enter: function () { $body.addClass('device-md'); },
            exit: function () { $body.removeClass('device-md'); }
        }, {
            breakpoint: 'tablet',
            enter: function () { $body.addClass('device-sm'); },
            exit: function () { $body.removeClass('device-sm'); }
        }, {
            breakpoint: 'handheld',
            enter: function () { $body.addClass('device-xs'); },
            exit: function () { $body.removeClass('device-xs'); }
        }, {
            breakpoint: 'smallest',
            enter: function () { $body.addClass('device-xxs'); },
            exit: function () { $body.removeClass('device-xxs'); }
        }
    ]);
}
function global_layout() {
    var defaultHeaderScheme = 'scheme-blue',
        defaultNavbarScheme = 'blue-default',
        defaultBrandingScheme = 'blue-default',
        defaultColorScheme = 'blue-scheme-color',
        //defaultHeaderPosition = 'header-fixed',
        defaultNavbarPosition = 'aside-fixed',
        //defaultRightbarVisibility = 'rightbar-hidden',
        defaultAppClasses = 'scheme-blue blue-scheme-color header-fixed aside-fixed rightbar-hidden';

    $body.addClass(defaultAppClasses);
    $header.addClass(defaultHeaderScheme);
    $branding.addClass(defaultBrandingScheme);
    $sidebar.addClass(defaultNavbarScheme).addClass(defaultNavbarPosition);

    $headerSchemeEl.on('click', function ($event) {
        var scheme = $(this).data('scheme');

        $body.removeClass(defaultHeaderScheme).addClass(scheme);
        $header.removeClass(defaultHeaderScheme).addClass(scheme);
        defaultHeaderScheme = scheme;
        $event.stopPropagation();
    });

    $brandingSchemeEl.on('click', function ($event) {
        var scheme = $(this).data('scheme');

        $branding.removeClass(defaultBrandingScheme).addClass(scheme);
        defaultBrandingScheme = scheme;
        $event.stopPropagation();
    });

    $sidebarSchemeEl.on('click', function ($event) {
        var scheme = $(this).data('scheme');

        $body.removeClass(defaultNavbarScheme).addClass(scheme);
        $sidebar.removeClass(defaultNavbarScheme).addClass(scheme);
        defaultNavbarScheme = scheme;
        $event.stopPropagation();
    });

    $colorSchemeEl.on('click', function ($event) {
        var scheme = $(this).data('scheme');

        $body.removeClass(defaultColorScheme).addClass(scheme);
        defaultColorScheme = scheme;
        $event.stopPropagation();
    });

    $fixedHeaderEl.change(function () {
        if ($body.hasClass('header-fixed')) {
            $body.removeClass('header-fixed').addClass('header-static');
        } else {
            $body.removeClass('header-static').addClass('header-fixed');
        }
    });
    $fixedHeaderEl.parent().on('click', function ($event) {
        $event.stopPropagation();
    });

    $fixedAsideEl.change(function () {
        if ($body.hasClass('aside-fixed')) {
            $body.removeClass('aside-fixed').addClass('aside-static');
            $sidebar.removeClass('aside-fixed').addClass('aside-static');
        } else {
            $body.removeClass('aside-static').addClass('aside-fixed');
            $sidebar.removeClass('aside-static').addClass('aside-fixed');
        }
    });
    $fixedAsideEl.parent().on('click', function ($event) {
        $event.stopPropagation();
    });

    $toggleRightbarEl.on('click', function () {
        if ($body.hasClass('rightbar-hidden')) {
            $body.removeClass('rightbar-hidden').addClass('rightbar-show');
        } else {
            $body.removeClass('rightbar-show').addClass('rightbar-hidden');
        }
    });

    if ($app.hasClass('boxed-layout')) {
        $app.parent().addClass('boxed-layout');
    }

    if ($app.hasClass('sidebar-offcanvas')) {
        $app.parent().addClass('sidebar-offcanvas');
    }

    if ($app.hasClass('hz-menu')) {
        $app.parent().addClass('hz-menu');
    }

    if ($app.hasClass('rtl')) {
        $app.parent().addClass('rtl');
    }
}
function global_animsition() {
    $wrap.animsition({
        inClass: 'fade-in',
        outClass: 'fade-out',
        inDuration: 1500,
        outDuration: 800,
        linkElement: '.animsition-link',
        // e.g. linkElement   :   'a:not([target="_blank"]):not([href^=#])'
        loading: true,
        loadingParentElement: 'body', //animsition wrapper element
        loadingClass: 'animsition-loading',
        unSupportCss: ['animation-duration',
            '-webkit-animation-duration',
            '-o-animation-duration'
        ],
        //"unSupportCss" option allows you to disable the "animsition" in case the css property in the array is not supported by your browser.
        //The default setting is to disable the "animsition" in a browser that does not support "animation-duration".
        overlay: false,
        overlayClass: 'animsition-overlay-slide',
        overlayParentElement: 'body'
    });
}
export function global_init() {
    updateElementsJQuery();
    global_deviceSize();
    global_layout();
    //global_animsition();
}
function global_initjquery() {
    updateElementsJQuery();
    global_deviceSize();
    global_layout();
    global_animsition();
}

// Navbar functions
function navbar_menu() {
    if ($dropdowns.length > 0) {

        $dropdowns.addClass('dropdown');

        var $submenus = $dropdowns.find('ul >.dropdown');
        $submenus.addClass('submenu');

        $a.append('<i class="fa fa-plus"></i>');

        $a.on('click', function (event) {
            if ($app.hasClass('sidebar-sm') || $app.hasClass('sidebar-xs') || $app.hasClass('hz-menu')) {
                return false;
            }

            var $this = $(this),
                $parent = $this.parent('li'),
                $openSubmenu = $('.submenu.open');

            if (!$parent.hasClass('submenu')) {
                $dropdowns.not($parent).removeClass('open').find('ul').slideUp();
            }

            $openSubmenu.not($this.parents('.submenu')).removeClass('open').find('ul').slideUp();
            $parent.toggleClass('open').find('>ul').stop().slideToggle();
            event.preventDefault();
        });

        $dropdowns.on('mouseenter', function () {
            $sidebar.addClass('dropdown-open');
            $controls.addClass('dropdown-open');
        });

        $dropdowns.on('mouseleave', function () {
            $sidebar.removeClass('dropdown-open');
            $controls.removeClass('dropdown-open');
        });

        $notDropdownsLinks.on('click', function () {
            $dropdowns.removeClass('open').find('ul').slideUp();
        });

        var $activeDropdown = $('.dropdown>ul>.active').parent();

        $activeDropdown.css('display', 'block');
    }
}
function navbar_ripple() {
    var parent, ink, d, x, y;

    $navigation.find('>li>a').click(function (e) {
        parent = $(this).parent();

        if (parent.find('.ink').length === 0) {
            parent.prepend('<span class="ink"></span>');
        }

        ink = parent.find('.ink');
        //incase of quick double clicks stop the previous animation
        ink.removeClass('animate');

        //set size of .ink
        if (!ink.height() && !ink.width()) {
            //use parent's width or height whichever is larger for the diameter to make a circle which can cover the entire element.
            d = Math.max(parent.outerWidth(), parent.outerHeight());
            ink.css({ height: d, width: d });
        }

        //get click coordinates
        //logic = click coordinates relative to page - parent's position relative to page - half of self height/width to make it controllable from the center;
        x = e.pageX - parent.offset().left - ink.width() / 2;
        y = e.pageY - parent.offset().top - ink.height() / 2;

        //set the position and add class .animate
        ink.css({ top: y + 'px', left: x + 'px' }).addClass('animate');

        setTimeout(function () {
            $('.ink').remove();
        }, 600);
    });
}
function navbar_removeRipple() {
    $sidebar.find('.ink').remove();
}
function navbar_collapse() {
    $collapseSidebarEl.on('click', function (e) {
        if ($app.hasClass('sidebar-sm')) {
            $app.removeClass('sidebar-sm').addClass('sidebar-xs');
        }
        else if ($app.hasClass('sidebar-xs')) {
            $app.removeClass('sidebar-xs');
        }
        else {
            $app.addClass('sidebar-sm');
        }

        $app.removeClass('sidebar-sm-forced sidebar-xs-forced');
        $app.parent().removeClass('sidebar-sm sidebar-xs');
        navbar_removeRipple();
        //$window.trigger('resize');
        e.preventDefault();
    });
}
function navbar_offcanvas() {
    $offcanvasToggleEl.on('click', function (e) {
        if ($app.hasClass('offcanvas-opened')) {
            $app.removeClass('offcanvas-opened');
        } else {
            $app.addClass('offcanvas-opened');
        }
        e.preventDefault();
    });
}
export function navbar_init() {
    updateElementsJQuery();
    navbar_menu();
    navbar_ripple();
    navbar_removeRipple();
    navbar_collapse();
    navbar_offcanvas();
}

// Tiles functions
function tiles_toggle() {
    $tileToggleEl.on('click', function () {
        var element = $(this);
        var tile = element.parents('.tile');

        tile.toggleClass('collapsed');
        tile.children().not('.tile-header').slideToggle(150);
    });
}
function tiles_refresh() {
    $tileRefreshEl.on('click', function () {
        var element = $(this);
        var tile = element.parents('.tile');
        var dropdown = element.parents('.dropdown');

        tile.addClass('refreshing');
        dropdown.trigger('click');

        setTimeout(function () {
            tile.removeClass('refreshing');
        }, 3000);
    });
}
function tiles_fullscreen() {
    $tileFullscreenEl.on('click', function () {
        var element = $(this);
        var tile = element.parents('.tile');
        var dropdown = element.parents('.dropdown');

        screenfull.toggle(tile[0]);
        dropdown.trigger('click');
    });

    if ($tileFullscreenEl.length > 0) {
        $(document).on(screenfull.raw.fullscreenchange, function () {
            var element = $(screenfull.element);
            if (screenfull.isFullscreen) {
                element.addClass('isInFullScreen');
            } else {
                $('.tile.isInFullScreen').removeClass('isInFullScreen');
            }
        });
    }
}
function tiles_close() {
    $tileCloseEl.on('click', function () {
        var element = $(this);
        var tile = element.parents('.tile');

        tile.addClass('closed').fadeOut();
    });
}
export function tiles_init() {
    updateElementsJQuery();
    tiles_toggle();
    tiles_refresh();
    tiles_fullscreen();
    tiles_close();
}

// Extra functions
export function extra_sparklineChart() {
    if ($sparklineEl.length > 0) {
        $sparklineEl.each(function () {
            var element = $(this);
            element.sparkline('html', { enableTagOptions: true });
        });
    }
}
export function extra_slimScroll() {
    if ($slimScrollEl.length > 0) {
        $slimScrollEl.each(function () {
            //var element = $(this);
            //element.slimScroll({ height: '100%' });
        });
    }
}
export function extra_daterangePicker() {
    if ($pickDateEl.length > 0) {
        $pickDateEl.each(function () {
            var element = $(this);

            element.find('span').html(moment().subtract(29, 'days').format('MMMM D, YYYY') + ' - ' + moment().format('MMMM D, YYYY'));

            element.daterangepicker({
                format: 'MM/DD/YYYY',
                startDate: moment().subtract(29, 'days'),
                endDate: moment(),
                minDate: '01/01/2012',
                maxDate: '12/31/2015',
                dateLimit: { days: 60 },
                showDropdowns: true,
                showWeekNumbers: true,
                timePicker: false,
                timePickerIncrement: 1,
                timePicker12Hour: true,
                ranges: {
                    'Today': [moment(), moment()],
                    'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
                    'Last 7 Days': [moment().subtract(6, 'days'), moment()],
                    'Last 30 Days': [moment().subtract(29, 'days'), moment()],
                    'This Month': [moment().startOf('month'), moment().endOf('month')],
                    'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
                },
                opens: 'left',
                drops: 'down',
                buttonClasses: ['btn', 'btn-sm'],
                applyClass: 'btn-success',
                cancelClass: 'btn-default',
                separator: ' to ',
                locale: {
                    applyLabel: 'Submit',
                    cancelLabel: 'Cancel',
                    fromLabel: 'From',
                    toLabel: 'To',
                    customRangeLabel: 'Custom',
                    daysOfWeek: ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'],
                    monthNames: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
                    firstDay: 1
                }
            }, function (start, end, label) {
                console.log(start.toISOString(), end.toISOString(), label);
                element.find('span').html(start.format('MMMM D, YYYY') + ' - ' + end.format('MMMM D, YYYY'));
            });

        });
    }
}
export function extra_easypiechart() {
    if ($easypiechartEl.length > 0) {
        $easypiechartEl.each(function () {
            var element = $(this);
            element.easyPieChart({
                onStart: function (value) {
                    if (element.hasClass('animate')) {
                        $(this.el).find('span').countTo({ to: value });
                    }
                }
            });
        });
    }
}
export function extra_chosen() {
    if ($chosenEl.length > 0) {
        $chosenEl.each(function () {
            var element = $(this);
            element.on('chosen:ready', function (e, chosen) {
                var width = element.css("width");
                element.next().find('.chosen-choices').addClass('form-control');
                element.next().css("width", width);
                element.next().find('.search-field input').css("width", "125px");
            }).chosen();
        });
    }
}
export function extra_toggleClass() {
    $toggleClassEl.on('click', function () {
        var element = $(this),
            className = element.data('toggle'),
            type = element.data('type');

        if (type === 'radio') {
            element.parent().find('.' + className).removeClass(className);
        }

        if (element.hasClass(className)) {
            element.removeClass(className);
        } else {
            element.addClass(className);
        }
    });
}
export function extra_colorpicker() {
    if ($colorPickerEl.length > 0) {
        $colorPickerEl.each(function () {
            var element = $(this);
            element.colorpicker();
        });
    }
}
export function extra_touchspin() {
    if ($touchspinEl.length > 0) {
        $touchspinEl.each(function () {
            var element = $(this);
            element.TouchSpin();
        });
    }
}
export function extra_datepicker() {
    //if ($datepickerEl.length > 0) {
    //    $datepickerEl.each(function () {
    //        var element = $(this);
    //        var format = element.data('format')
    //        element.datetimepicker({
    //            format: format
    //        });
    //    });
    //}
}
export function extra_animateProgress() {
    if ($animateProgressEl.length > 0) {
        $animateProgressEl.each(function () {
            var element = $(this);
            var progress = element.data('percentage');

            element.css('width', progress);
        });
    }
}
export function extra_counter() {
    if ($counterEl.length > 0) {
        $counterEl.each(function () {
            var element = $(this);

            element.countTo();
        });
    }
}
export function extra_popover() {
    var $popoverEl = $('[data-toggle="popover"]');
    if ($popoverEl.length > 0) {
        $popoverEl.each(function () {
            var element = $(this);

            element.popover();
        });
    }
}
export function extra_tooltip() {
    var $tooltipEl = $('[data-toggle="tooltip"]');
    if ($tooltipEl.length > 0) {
        $tooltipEl.each(function () {
            var element = $(this);

            element.tooltip();
        });
    }
}
export function extra_splash() {
    var options = "";
    $splashEl.on('show.bs.modal', function (e) {
        options = e.relatedTarget.dataset.options;
        $body.addClass(options).addClass('splash');
    });
    $splashEl.on('hidden.bs.modal', function () {
        $body.removeClass(options).removeClass('splash');
    });
}
export function extra_lightbox() {
    var $lightboxImageEl = $('[data-lightbox="image"]'),
        $lightboxIframeEl = $('[data-lightbox="iframe"]'),
        $lightboxGalleryEl = $('[data-lightbox="gallery"]');

    if ($lightboxImageEl.length > 0) {
        $lightboxImageEl.magnificPopup({
            type: 'image',
            closeOnContentClick: true,
            closeBtnInside: false,
            fixedContentPos: true,
            image: {
                verticalFit: true
            }
        });
    }

    if ($lightboxIframeEl.length > 0) {
        $lightboxIframeEl.magnificPopup({
            disableOn: 600,
            type: 'iframe',
            removalDelay: 160,
            preloader: false,
            fixedContentPos: false
        });
    }

    if ($lightboxGalleryEl.length > 0) {
        $lightboxGalleryEl.each(function () {
            var element = $(this);

            if (element.find('a[data-lightbox="gallery-item"]').parent('.clone').hasClass('clone')) {
                element.find('a[data-lightbox="gallery-item"]').parent('.clone').find('a[data-lightbox="gallery-item"]').attr('data-lightbox', '');
            }

            element.magnificPopup({
                delegate: 'a[data-lightbox="gallery-item"]',
                type: 'image',
                closeOnContentClick: true,
                closeBtnInside: false,
                fixedContentPos: true,
                image: {
                    verticalFit: true
                },
                gallery: {
                    enabled: true,
                    navigateByImgClick: true,
                    preload: [0, 1] // Will preload 0 - before current, and 1 after the current image
                }
            });
        });
    }
}
export function extra_init() {
    //extra_sparklineChart();
    //extra_slimScroll();
    //extra_daterangePicker();
    //extra_easypiechart();
    //extra_chosen();
    //extra_toggleClass();
    //extra_colorpicker();
    //extra_touchspin();
    //extra_datepicker();
    //extra_animateProgress();
    //extra_counter();
    //extra_popover();
    //extra_tooltip();
    //extra_splash();
    //extra_lightbox();
}

// Check mobile device
MINOVATE.isMobile = {
    Android: function () {
        return navigator.userAgent.match(/Android/i);
    },
    BlackBerry: function () {
        return navigator.userAgent.match(/BlackBerry/i);
    },
    iOS: function () {
        return navigator.userAgent.match(/iPhone|iPad|iPod/i);
    },
    Opera: function () {
        return navigator.userAgent.match(/Opera Mini/i);
    },
    Windows: function () {
        return navigator.userAgent.match(/IEMobile/i);
    },
    any: function () {
        return (MINOVATE.isMobile.Android() || MINOVATE.isMobile.BlackBerry() || MINOVATE.isMobile.iOS() || MINOVATE.isMobile.Opera() || MINOVATE.isMobile.Windows());
    }
};

// Initialize after resize
export function documentOnResize_init() {
    setTimeout(function () {
        documentOnReady_setSidebar();
        navbar_removeRipple();
    }, 500);
}

// Initialize when document ready
export function documentOnReady_init() {
    global_init();
    //MINOVATE.header.init();
    navbar_init();
    documentOnReady_windowscroll();
    tiles_init();
    extra_init();
    documentOnReady_setSidebar();
}
export function documentOnReady_initJQuery() {
    global_initjquery();
    //MINOVATE.header.init();
    navbar_init();
    documentOnReady_windowscroll();
    tiles_init();
    extra_init();
    documentOnReady_setSidebar();
}
function documentOnReady_windowscroll() {
    $window.on('scroll', function () { });
}
function documentOnReady_setSidebar() {
    var width = $window.width();

    if (width < 992) {
        $app.addClass('sidebar-sm');
    } else {
        $app.removeClass('sidebar-sm sidebar-xs');
    }

    if (width < 768) {
        $app.removeClass('sidebar-sm').addClass('sidebar-xs');
    } else if (width > 992) {
        $app.removeClass('sidebar-sm sidebar-xs');
    } else {
        $app.removeClass('sidebar-xs').addClass('sidebar-sm');
    }

    if ($app.hasClass('sidebar-sm-forced')) {
        $app.addClass('sidebar-sm');
    }

    if ($app.hasClass('sidebar-xs-forced')) {
        $app.addClass('sidebar-xs');
    }
}

// Initialize when document load
export function documentOnLoad_init() { }

// Atualiza elementos do JQuery
function updateElementsJQuery() {
    if (typeof (window) !== 'undefined') {
        $window = $(window);
        $body = $('body');
        $header = $('#header');
        $branding = $('#header .branding');
        $sidebar = $('#sidebar');
        $controls = $('#controls');
        $app = $('.appWrapper');
        $navigation = $('#navigation');
        $sparklineEl = $('.sparklineChart');
        $slimScrollEl = $('.slim-scroll');
        $collapseSidebarEl = $('.collapse-sidebar');
        $wrap = $('#wrap');
        $offcanvasToggleEl = $('.offcanvas-toggle');

        //navigation elements
        $dropdowns = $navigation.find('ul').parent('li');
        $a = $dropdowns.children('a');
        $notDropdowns = $navigation.children('li').not($dropdowns);
        $notDropdownsLinks = $notDropdowns.children('a');
        // end of navuigation elements

        $headerSchemeEl = $('.color-schemes .header-scheme');
        $brandingSchemeEl = $('.color-schemes .branding-scheme');
        $sidebarSchemeEl = $('.color-schemes .sidebar-scheme');
        $colorSchemeEl = $('.color-schemes .color-scheme');
        $fixedHeaderEl = $('#fixed-header');
        $fixedAsideEl = $('#fixed-aside');
        $toggleRightbarEl = $('.toggle-right-sidebar');
        $pickDateEl = $('.pickDate');

        //$tileEl = $('.tile');
        $tileToggleEl = $('.tile .tile-toggle');
        $tileRefreshEl = $('.tile .tile-refresh');
        $tileFullscreenEl = $('.tile .tile-fullscreen');
        $tileCloseEl = $('.tile .tile-close');

        $easypiechartEl = $('.easypiechart');
        $chosenEl = $('.chosen-select');
        $toggleClassEl = $('.toggle-class');
        $colorPickerEl = $('.colorpicker');
        $touchspinEl = $('.touchspin');
        $datepickerEl = $('.datepicker');
        $animateProgressEl = $('.animate-progress-bar');
        $counterEl = $('.counter');
        $splashEl = $('.splash');
    }
}

// Global variables
if (typeof (window) !== 'undefined') {
    var $window = $(window),
        $body = $('body'),
        $header = $('#header'),
        $branding = $('#header .branding'),
        $sidebar = $('#sidebar'),
        $controls = $('#controls'),
        $app = $('.appWrapper'),
        $navigation = $('#navigation'),
        $sparklineEl = $('.sparklineChart'),
        $slimScrollEl = $('.slim-scroll'),
        $collapseSidebarEl = $('.collapse-sidebar'),
        $wrap = $('#wrap'),
        $offcanvasToggleEl = $('.offcanvas-toggle'),

        //navigation elements
        $dropdowns = $navigation.find('ul').parent('li'),
        $a = $dropdowns.children('a'),
        $notDropdowns = $navigation.children('li').not($dropdowns),
        $notDropdownsLinks = $notDropdowns.children('a'),
        // end of navuigation elements

        $headerSchemeEl = $('.color-schemes .header-scheme'),
        $brandingSchemeEl = $('.color-schemes .branding-scheme'),
        $sidebarSchemeEl = $('.color-schemes .sidebar-scheme'),
        $colorSchemeEl = $('.color-schemes .color-scheme'),
        $fixedHeaderEl = $('#fixed-header'),
        $fixedAsideEl = $('#fixed-aside'),
        $toggleRightbarEl = $('.toggle-right-sidebar'),
        $pickDateEl = $('.pickDate'),

        //$tileEl = $('.tile'),
        $tileToggleEl = $('.tile .tile-toggle'),
        $tileRefreshEl = $('.tile .tile-refresh'),
        $tileFullscreenEl = $('.tile .tile-fullscreen'),
        $tileCloseEl = $('.tile .tile-close'),

        $easypiechartEl = $('.easypiechart'),
        $chosenEl = $('.chosen-select'),
        $toggleClassEl = $('.toggle-class'),
        $colorPickerEl = $('.colorpicker'),
        $touchspinEl = $('.touchspin'),
        $datepickerEl = $('.datepicker'),
        $animateProgressEl = $('.animate-progress-bar'),
        $counterEl = $('.counter'),
        $splashEl = $('.splash');

    //$(document).ready(documentOnReady_init);
    $(window).on('load', documentOnLoad_init);
    $window.on('resize', documentOnResize_init);
}