import * as React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actionCreators } from '../../../store/crm/CrmConsulta';

import { Row, Panel, Modal } from 'react-bootstrap';
import AlertError from '../../commons/AlertError';
import AlertSuccess from '../../commons/AlertSuccess';
import ProgerssBar from '../../commons/ProgressBar';
import InputSelect from '../../commons/InputSelect';
import BtnSalvar from '../../commons/BtnSalvar';
import BtnCancelar from '../../commons/BtnCancelar';
import DateTimePicker from '../../commons/DateTimePicker';
import TextAreaValidated from '../../commons/TextAreaValidated';

import InputText from '../../commons/InputText';
import Section from '../../commons/Section';




class ModalNovoLead extends React.Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        this.props.fnListaResponsavel();
        this.props.fnListaEtapaFunil();
        this.props.fnListaTemperaturaNovoLead();
    }

    async fnNovaOportunidade() {
        const { idEtapaFunilVendas, nomeCliente, idTipoPessoa,emailCliente,telefoneCliente,idCanal,oferta,idTemperatura} = this.props.modalNovoLead;

        if (idEtapaFunilVendas == '' || idEtapaFunilVendas == 0
            ||
            idTipoPessoa == '' || idTipoPessoa == 0
            ||
            idCanal == '' || idCanal == 0
            ||
            nomeCliente == ''
            || idTemperatura == 0 || idTemperatura == ''
        ) {
            this.props.modalNovoLead.errorText = 'Etapa, Canal, Cliente, Tipo Pessoa e Temperatura são obrigatórios';
            this.props.fnUpdateState(this.props);
        } else if (emailCliente == '' && telefoneCliente == '') {
            this.props.modalNovoLead.errorText = 'E-mail e Telefone : Ao menos um deverá ser informado';
            this.props.fnUpdateState(this.props);
        }
        else {
            await this.props.fnNovaOportunidade();
        }

    }

    handleOnHide() {
        this.props.modalNovoLead.showModal = false;
        this.props.fnUpdateState(this.props);
    }

    render() {
        var dropdownEtapaFunilVendas = [];
        dropdownEtapaFunilVendas.push({ value: null, text: 'Selecione...' });
        this.props.modalNovoLead.dropdownEtapaFunilVendas.results.forEach(function (entry) {
            dropdownEtapaFunilVendas.push({ value: entry.id.toString(), text: entry.nome });
        });

        var dropdownTipoPessoa = [];
        dropdownTipoPessoa.push({ value: null, text: 'Selecione' });
        dropdownTipoPessoa.push({ value: 1, text: 'Física' });
        dropdownTipoPessoa.push({ value: 2, text: 'Jurídica' });

        var dropdownCanalOportunidade = [];
        dropdownCanalOportunidade.push({ value: null, text: 'Selecione' });
        this.props.modalNovoLead.dropdownCanalOportunidade.results.forEach(function (entry) {
            dropdownCanalOportunidade.push({ value: entry.id.toString(), text: entry.nome });
        });

        var dropdownTemperaturas = [];
        dropdownTemperaturas.push({ value: null, text: 'Selecione' });
        this.props.modalNovoLead.dropdownTemperaturas.results.forEach(function (entry) {
            dropdownTemperaturas.push({ value: entry.id.toString(), text: entry.nome });
        });


        return (<Modal
            show={this.props.modalNovoLead.showModal}
            onHide={this.handleOnHide.bind(this)}
            bsSize="large"
                >
            <Modal.Header closeButton bsStyle="primary">
                <h3 className="modal-title custom-font">Novo Lead</h3>
            </Modal.Header>
            <Modal.Body>
                <AlertSuccess
                    successText={this.props.modalNovoLead.successText}
                    alertVisible={this.props.modalNovoLead.successText != '' && this.props.modalNovoLead.successText != undefined ? true : false}
                    onDismiss={(alertVisible, successText) => {
                        this.props.modalNovoLead.successText = successText;
                        this.props.fnUpdateState(this.props);
                    }}
                />
                <AlertError
                    errorText={this.props.modalNovoLead.errorText}
                    alertVisible={this.props.modalNovoLead.errorText != '' && this.props.modalNovoLead.errorText != undefined ? true : false}
                    onDismiss={(alertVisible, errorText) => {
                        this.props.modalNovoLead.errorText = errorText;
                        this.props.fnUpdateState(this.props);
                    }}
                />
                {
                    this.props.modalNovoLead.isLoading
                        ? <ProgerssBar />
                        : <div>
                            <Row>
                                <InputSelect
                                    required
                                    name="ddlFunilVendas"
                                    className="col-md-6"
                                    title="Etapa:"
                                    data={
                                        this.props.modalNovoLead.dropdownEtapaFunilVendas.isLoading
                                            ? [{ value: null, text: 'Carregando...' }]
                                            : this.props.modalNovoLead.dropdownEtapaFunilVendas.errorText != ''
                                                ? [{ value: null, text: this.props.modalNovoLead.dropdownEtapaFunilVendas.errorText }]
                                                : dropdownEtapaFunilVendas
                                    }
                                    onChange={(name, value) => {
                                        this.props.modalNovoLead.idEtapaFunilVendas = value;
                                        this.props.fnUpdateState(this.props);
                                    }}
                                    disabled={
                                        this.props.modalNovoLead.dropdownEtapaFunilVendas.isLoading ? true : false
                                    }
                                    selectedValue={
                                        this.props.modalNovoLead.idEtapaFunilVendas == null
                                            ? null
                                            : this.props.modalNovoLead.idEtapaFunilVendas.toString()
                                    }
                                />
                                <InputText
                                    name="txtCliente"
                                    placeholder=""
                                    className="col-md-6"
                                    onChange={(name, value) => {
                                        this.props.modalNovoLead.nomeCliente = value;
                                        this.props.fnUpdateState(this.props);
                                    }}
                                    value={this.props.modalNovoLead.nomeCliente}
                                    title="Cliente:"
                                />

                                <InputSelect
                                    required
                                    name="ddTipoPessoa"
                                    className="col-md-6"
                                    data={dropdownTipoPessoa}
                                    title="Tipo Pessoa:"
                                    onChange={(name, value) => {
                                        this.props.modalNovoLead.idTipoPessoa = value;
                                        this.props.fnUpdateState(this.props);
                                    }}
                                    selectedValue={
                                        this.props.modalNovoLead.idTipoPessoa == null
                                            ? null
                                            : this.props.modalNovoLead.idTipoPessoa.toString()
                                    }
                                />

                                <InputText
                                    name="txtEmail"
                                    placeholder=""
                                    className="col-md-6"
                                    onChange={(name, value) => {
                                        this.props.modalNovoLead.emailCliente = value;
                                        this.props.fnUpdateState(this.props);
                                    }}
                                    value={this.props.modalNovoLead.emailCliente}
                                    title="Email:"
                                />

                                <InputText
                                    name="txtTelefone"
                                    placeholder=""
                                    className="col-md-6"
                                    onChange={(name, value) => {
                                        this.props.modalNovoLead.telefoneCliente = value;
                                        this.props.fnUpdateState(this.props);
                                    }}
                                    value={this.props.modalNovoLead.telefoneCliente}
                                    title="Telefone:"
                                />

                                <InputSelect
                                    required
                                    name="ddCanal"
                                    className="col-md-6"
                                    title="Canal:"
                                    data={
                                        this.props.modalNovoLead.dropdownCanalOportunidade.isLoading
                                            ? [{ value: null, text: 'Carregando...' }]
                                            : this.props.modalNovoLead.dropdownCanalOportunidade.errorText != ''
                                                ? [{ value: null, text: this.props.modalNovoLead.dropdownCanalOportunidade.errorText }]
                                                : dropdownCanalOportunidade
                                    }
                                    onChange={(name, value) => {
                                        this.props.modalNovoLead.idCanal = value;
                                        this.props.fnUpdateState(this.props);
                                    }}
                                    disabled={
                                        this.props.modalNovoLead.dropdownCanalOportunidade.isLoading ? true : false
                                    }
                                    selectedValue={
                                        this.props.modalNovoLead.idCanal == null
                                            ? null
                                            : this.props.modalNovoLead.idCanal.toString()
                                    }
                                />

                                <InputText
                                    name="txtAnuncio"
                                    placeholder=""
                                    className="col-md-6"
                                    onChange={(name, value) => {
                                        this.props.modalNovoLead.anuncio = value;
                                        this.props.fnUpdateState(this.props);
                                    }}
                                    value={this.props.modalNovoLead.anuncio}
                                    title="Anúncio:"
                                />

                                <InputSelect
                                    required
                                    name="ddTemperatura"
                                    className="col-md-6"
                                    title="Temperatura:"
                                    data={
                                        this.props.modalNovoLead.dropdownTemperaturas.isLoading
                                            ? [{ value: null, text: 'Carregando...' }]
                                            : this.props.modalNovoLead.dropdownTemperaturas.errorText != ''
                                                ? [{ value: null, text: this.props.modalNovoLead.dropdownTemperaturas.errorText }]
                                                : dropdownTemperaturas
                                    }
                                    onChange={(name, value) => {
                                        this.props.modalNovoLead.idTemperatura = value;
                                        this.props.fnUpdateState(this.props);
                                    }}
                                    disabled={
                                        this.props.modalNovoLead.dropdownTemperaturas.isLoading ? true : false
                                    }
                                    selectedValue={
                                        this.props.modalNovoLead.idTemperatura == null
                                            ? null
                                            : this.props.modalNovoLead.idTemperatura.toString()
                                    }
                                />

                                <InputText
                                    name="txtOferta"
                                    placeholder=""
                                    className="col-md-12"
                                    onChange={(name, value) => {
                                        this.props.modalNovoLead.oferta = value;
                                        this.props.fnUpdateState(this.props);
                                    }}
                                    value={this.props.modalNovoLead.oferta}
                                    title="Oferta:"
                                />


                                <div className="col-md-12 text-right pull-right">
                                    <BtnSalvar
                                        isLoading={this.props.modalNovoLead.isLoading}
                                        text={this.props.modalNovoLead.isLoading ? " Aguarde..." : " Confirmar"}
                                        disabled={this.props.modalNovoLead.isLoading}
                                        onClick={() => { this.fnNovaOportunidade(); }}
                                    />
                                </div>
                            </Row>
                        </div>
                }
            </Modal.Body>
        </Modal>);
    }
}


export default connect(
    state => state.crm_consulta,
    dispatch => bindActionCreators(actionCreators, dispatch),
    null, {}
)(ModalNovoLead);