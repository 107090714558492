import * as React from 'react';

import HeaderPage from '../../components/commons/HeaderPage';
import ConsultaForm from '../../components/edoc/consulta/ConsultaForm';

export default class Edoc_Consulta extends React.Component {
    render() {
        return <div className='page'>
            <HeaderPage
                path=' E-Doc'
                name='Consulta de Documentos' />
            <ConsultaForm />
        </div>;
    }
}