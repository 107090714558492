import * as React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actionCreators } from '../../../store/crm/CrmConsulta';

import Formsy from 'formsy-react';
import Moment from 'react-moment';
import { FormattedNumber } from 'react-intl';
import { Row, Panel, Modal } from 'react-bootstrap';
import Section from '../../commons/Section';
import AlertError from '../../commons/AlertError';
import AlertSuccess from '../../commons/AlertSuccess';
import ProgerssBar from '../../commons/ProgressBar';
import { Timeline, TimelineEvent } from 'react-event-timeline'
import ModalUsuarios from './ModalUsuarios';
import ModalReabrir from './ModalReabrir';
import ModalFinalizar from './ModalFinalizar';
import InputSelectValidated from '../../commons/InputSelectValidated';
import InputSelectWithoutClass from '../../commons/InputSelectWithoutClass';
import DateTimePicker from '../../commons/DateTimePicker';
import TextAreaValidated from '../../commons/TextAreaValidated';
import BtnSalvar from '../../commons/BtnSalvar';
import InputTextValidated from '../../commons/InputTextValidated';
import BtnCancelar from '../../commons/BtnCancelar';

const styles = {
    link: {
        color: "white",
        ":hover": {
            textDecoration: "none",
            color: "white",
        },
        cursor: "pointer",
    },
    i: {
        fontSize: '26px'
    },
    thumbsBtn: {
        width: 36,
        padding: 4,
        marginLeft: 4,
        borderRadius: 10,
        textAlign: 'center',
        float: 'right',
        cursor: 'pointer'
    },

    temperaturaddl: {
        width: 200,
        height:20
    }

};



class DetalhesForm extends React.Component {
    constructor(props) {
        super(props);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleSalvarDetalhes = this.handleSalvarDetalhes.bind(this);
    }

    async componentDidMount() {
        await this.props.fnObterOportunidade();
        //this.props.fnListaAtividadesOpoetunidade();
        this.props.fnListaTimeLineOportunidade();
        this.props.fnListaCanaisAtividade();
        this.props.fnListaTemperaturaDetalhes();
    }

    handleSubmit(e) {
        if (this.props.detalhes.atividade.idCanalAtividade == '' || this.props.detalhes.atividade.idCanalAtividade == 0) {
            this.props.detalhes.atividade.errorText = 'Informe o tipo da atividade';
            this.props.fnUpdateState(this.props);
        }
        else if (this.props.detalhes.atividade.data == '') {
            this.props.detalhes.atividade.errorText = 'Informe a data';
            this.props.fnUpdateState(this.props);
        }
        else if (this.props.detalhes.atividade.data == 'Invalid date') {
            this.props.detalhes.atividade.errorText = 'Data inválida';
            this.props.fnUpdateState(this.props);
        }
        else if (this.props.detalhes.atividade.hora == '') {
            this.props.detalhes.atividade.errorText = 'Informe a hora';
            this.props.fnUpdateState(this.props);
        }
        else if (this.props.detalhes.atividade.hora > 23) {
            this.props.detalhes.atividade.errorText = 'Hora inválida';
            this.props.fnUpdateState(this.props);
        }
        else if (this.props.detalhes.atividade.minuto == '') {
            this.props.detalhes.atividade.errorText = 'Informe o minuto';
            this.props.fnUpdateState(this.props);
        }
        else if (this.props.detalhes.atividade.minuto > 59) {
            this.props.detalhes.atividade.errorText = 'Minuto inválido';
            this.props.fnUpdateState(this.props);
        }
        else {
            this.props.fnInserirAtividade();
        }
    }

    handleSalvarDetalhes(e) {

        if (this.props.detalhes.idTemperatura == null) {
            this.props.detalhes.idTemperatura = 1;
        }

        this.props.fnAtualizaTemperaturaOportunidade();

    }

    render() {
        var objProps = this.props;

        var dropdownAtividades = [];
        dropdownAtividades.push({ value: null, text: '' });
        objProps.detalhes.atividade.dropdownAtividades.results.forEach(function (entry) {
            dropdownAtividades.push({ value: entry.id.toString(), text: entry.nome });
        });

        var dropdownTemperatura = [];
        objProps.detalhes.dropdownTemperatura.results.forEach(function (entry) {
            dropdownTemperatura.push({ value: entry.id.toString(), text: entry.nome });
        });


        return (<Row>
            <div className="col-md-12">
                <Section>
                    <Panel bsStyle="primary">
                        <Panel.Heading style={{ height:48 }}>
                            <strong>Detalhes</strong> da oportunidade

                                {this.props.detalhes.oportunidade.idOportunidadeStatus === 1 ?
                                <a onClick={() => {
                                    this.props.modalFinalizar.idOportunidade = this.props.detalhes.idOportunidade;
                                    this.props.modalFinalizar.showModal = true;
                                    this.props.modalFinalizar.ehVendido = true;
                                    this.props.fnUpdateState(this.props);
                                }} style={styles.link}
                                >

                                        <div style={{ backgroundColor: '#4caf50', ...styles.thumbsBtn }}>
                                            <i className="fa fa-thumbs-up" style={styles.i} />
                                        </div>
                                </a>
                                : null}

                            {this.props.detalhes.oportunidade.idOportunidadeStatus === 1 ?
                                <a onClick={() => {
                                    this.props.modalFinalizar.idOportunidade = this.props.detalhes.idOportunidade;
                                    this.props.modalFinalizar.showModal = true;
                                    this.props.modalFinalizar.ehVendido = false;
                                    this.props.fnUpdateState(this.props);
                                }} style={styles.link}
                                >

                                    <div style={{ backgroundColor: '#f44336', ...styles.thumbsBtn }}>
                                        <i className="fa fa-thumbs-down" style={styles.i} />
                                    </div>
                                </a>
                                : null}


                            <div style={{ ...styles.thumbsBtn }}>
                                {this.props.detalhes.oportunidade.idOportunidadeStatus == 1 ?
                                    <a onClick={() => {
                                        this.props.modalUsuarios.idOportunidade = this.props.detalhes.idOportunidade;
                                        this.props.modalUsuarios.showModal = true;
                                        this.props.fnUpdateState(this.props);
                                    }} style={styles.link}><i className="fa fa-share-square pull-right" style={styles.i} /></a>
                                    : null}
                                {this.props.detalhes.oportunidade.idOportunidadeStatus != 1
                                    ? <a onClick={() => {
                                        this.props.modalReabrir.idOportunidade = this.props.detalhes.idOportunidade;
                                        this.props.modalReabrir.showModal = true;
                                        this.props.fnUpdateState(this.props);
                                    }} style={styles.link}><i className="fa fa-lock pull-right" style={styles.i} /></a>
                                    : null}
                            </div>
                        </Panel.Heading>
                        <Panel.Body>
                            <AlertSuccess
                                successText={this.props.detalhes.successText}
                                alertVisible={this.props.detalhes.successText != '' && this.props.detalhes.successText != undefined ? true : false}
                                onDismiss={(alertVisible, successText) => {
                                    this.props.detalhes.successText = successText;
                                    this.props.fnUpdateState(this.props);
                                }}
                            />
                            <AlertError
                                errorText={this.props.detalhes.errorText}
                                alertVisible={this.props.detalhes.errorText != '' && this.props.detalhes.errorText != undefined ? true : false}
                                onDismiss={(alertVisible, errorText) => {
                                    this.props.detalhes.errorText = errorText;
                                    this.props.fnUpdateState(this.props);
                                }}
                            />
                            {
                                this.props.detalhes.isLoading
                                    ? <ProgerssBar />
                                    : <div>
                                        <Formsy onValidSubmit={this.handleSubmit}>
                                        <Row>
                                            <div className="col-md-2 text-right"><strong>Responsável: </strong></div>
                                            <div className="col-md-10">{this.props.detalhes.oportunidade.nomeUsuario}</div>
                                        </Row>
                                        <Row>
                                            <div className="col-md-2 text-right"><strong>Cliente: </strong></div>
                                            <div className="col-md-4">{this.props.detalhes.oportunidade.nomeCliente}</div>
                                            <div className="col-md-2 text-right"><strong>Data Cadastro: </strong></div>
                                            <div className="col-md-4"><Moment format="DD/MM/YYYY hh:mm">{this.props.detalhes.oportunidade.dataCadastro}</Moment></div>
                                        </Row>
                                        <Row>
                                            <div className="col-md-2 text-right"><strong>Tipo Pessoa: </strong></div>
                                            <div className="col-md-4">{this.props.detalhes.oportunidade.idTipoPessoa == 1 ? 'Física' : 'Jurídica'}</div>
                                            <div className="col-md-2 text-right"><strong>Telefone: </strong></div>
                                            <div className="col-md-4"><a href={"https://api.whatsapp.com/send?phone=55" + this.props.detalhes.oportunidade.telefoneCliente} target="_blank">{this.props.detalhes.oportunidade.telefoneCliente}</a></div>
                                        </Row>
                                        <Row>
                                            <div className="col-md-2 text-right"><strong>Email: </strong></div>
                                            <div className="col-md-4"><a href={"mailto:" + this.props.detalhes.oportunidade.emailCliente}>{this.props.detalhes.oportunidade.emailCliente}</a></div>
                                            <div className="col-md-2 text-right"><strong>Etapa: </strong></div>
                                            <div className="col-md-4">{this.props.detalhes.oportunidade.nomeEtapaFunilVendas}</div>
                                        </Row>
                                        <Row>
                                            <div className="col-md-2 text-right"><strong>Canal: </strong></div>
                                            <div className="col-md-4">{this.props.detalhes.oportunidade.nomeCanalLead}</div>
                                            <div className="col-md-2 text-right"><strong>Potencial: </strong></div>
                                            <div className="col-md-4"><FormattedNumber value={this.props.detalhes.oportunidade.potencial} style="decimal" minimumFractionDigits={2} maximumFractionDigits={2} /></div>
                                        </Row>
                                        <Row>
                                            <div className="col-md-2 text-right"><strong>Status: </strong></div>
                                            <div className="col-md-4">{this.props.detalhes.oportunidade.nomeOportunidadeStatus}</div>
                                            <div className="col-md-2 text-right"><strong>Temperatura: </strong></div>
                                                <div className="col-md-4" style={{ ...styles.temperaturaddl }}>
                                            <InputSelectWithoutClass
                                                name="ddlTemperatura"  
                                                menuPlacement="auto"
                                                menuPosition="fixed"                                                        
                                                style={{ ...styles.temperaturaddl }}
                                                data={
                                                    this.props.detalhes.dropdownTemperatura.isLoading
                                                        ? [{ value: null, text: 'Carregando...' }]
                                                        : this.props.detalhes.dropdownTemperatura.errorText != ''
                                                            ? [{ value: null, text: this.props.detalhes.dropdownTemperatura.errorText }]
                                                            : dropdownTemperatura
                                                }
                                                onChange={(name, value) => {                                             
                                                    this.props.detalhes.oportunidade.idTemperatura = value;
                                                    this.props.fnUpdateState(this.props);

                                                }}                                                
                                                disabled={this.props.detalhes.dropdownTemperatura.isLoading ? true : false}
                                                selectedValue={(this.props.detalhes.oportunidade.idTemperatura == null) ? null : this.props.detalhes.oportunidade.idTemperatura.toString()}
                                                required={true} />
                                                </div>
                                        </Row>
                                        <Row>
                                            <div className="col-md-2 text-right"><strong>Oferta: </strong></div>
                                            <div className="col-md-4">{this.props.detalhes.oportunidade.oferta}</div>

                                                <div className="col-md-2 text-right"><strong>{(this.props.detalhes.oportunidade.idOportunidadeStatus === 2 || this.props.detalhes.oportunidade.idOportunidadeStatus === 3) ?
                                                    "Data de Encerramento:" : null} </strong></div>
                                                <div className="col-md-4">{ (this.props.detalhes.oportunidade.idOportunidadeStatus === 2 || this.props.detalhes.oportunidade.idOportunidadeStatus === 3) ?
                                                    <Moment format="DD/MM/YYYY HH:mm">{this.props.detalhes.oportunidade.datahoraEncerrado}</Moment> : null
                                                }
                                                </div>

                                        </Row>
                                        {this.props.detalhes.oportunidade.linkAnuncio != "" && this.props.detalhes.oportunidade.linkAnuncio != null
                                            ? <Row>
                                                <div className="col-md-2 text-right"><strong>Link Anúncio: </strong></div>
                                                <div className="col-md-10"><a href={this.props.detalhes.oportunidade.linkAnuncio} target="_blank">clique aqui para ver o anúncio</a></div>
                                                </Row>                                              
                                                : null}
                                            <div className="col-md-12 text-right">
                                                <BtnSalvar
                                                    isLoading={this.props.detalhes.oportunidade.isLoading}
                                                    text={this.props.detalhes.oportunidade.isLoading ? " Aguarde..." : " Salvar Detalhes"}
                                                    disabled={this.props.detalhes.oportunidade.isLoading}
                                                    type="button"
                                                    onClick={(e) => { this.handleSalvarDetalhes();}}
                                                />
                                            </div>
                                           </Formsy>
                                        <br />
                                        <div className="col-md-7">
                                            <h5 className="custom-font filled bg-blue">Time-Line</h5>
                                            <Row>
                                                <Timeline>
                                                    {
                                                        (this.props.detalhes.timeline.isLoading)
                                                            ? <div className="progress progress-striped active">
                                                                <div className="progress-bar progress-bar-blue" role="progressbar" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100" style={{ width: "100%" }}>
                                                                    <span className="sr-only">100% Complete</span>
                                                                </div>
                                                            </div>
                                                            : (this.props.detalhes.timeline.results.length == 0)
                                                                ? <p className="text-warning">Não foram encontrados registros...</p>
                                                                : this.props.detalhes.timeline.results.map(function (rowData, index) {
                                                                    return (
                                                                        <TimelineEvent
                                                                            title={rowData.usuario.toString().toUpperCase()}
                                                                            createdAt={<Moment format="DD/MM/YYYY HH:mm">{rowData.datahora}</Moment>}
                                                                            icon={<i className="fa fa-calendar"></i>}
                                                                            style={{ fontWeight: 'bold' }}
                                                                        >
                                                                            <p>{rowData.texto}</p> 
                                                                            {((rowData.idOportunidadeTipoEvento == 6 || rowData.idOportunidadeTipoEvento == 7) ? 
                                                                                <p> Data de Encerramento: {(rowData.datahoraEncerramento == null ? <Moment format="DD/MM/YYYY HH:mm">{rowData.datahora}</Moment>
                                                                                    : <Moment format="DD/MM/YYYY HH:mm">{rowData.datahoraEncerramento}</Moment>)}
                                                                                   
                                                                                </p>
                                                                            : null)}
                                                                        </TimelineEvent>
                                                                    )
                                                                })
                                                    }
                                                </Timeline>
                                            </Row>
                                        </div>
                                        <div className="col-md-5">
                                            <h5 className="custom-font filled bg-blue">Nova Atividade</h5>
                                            <AlertError
                                                errorText={this.props.detalhes.atividade.errorText}
                                                alertVisible={this.props.detalhes.atividade.errorText != '' && this.props.detalhes.atividade.errorText != undefined ? true : false}
                                                onDismiss={(alertVisible, errorText) => {
                                                    this.props.detalhes.atividade.errorText = errorText;
                                                    this.props.fnUpdateState(this.props);
                                                }}
                                            />
                                            <Formsy onValidSubmit={this.handleSubmit}>
                                                <Row>
                                                    <InputSelectValidated
                                                        name="ddlEtapa"
                                                        className="col-md-12"
                                                        data={
                                                            this.props.detalhes.atividade.dropdownAtividades.isLoading
                                                                ? [{ value: null, text: 'Carregando...' }]
                                                                : this.props.detalhes.atividade.dropdownAtividades.errorText != ''
                                                                    ? [{ value: null, text: this.props.detalhes.atividade.dropdownAtividades.errorText }]
                                                                    : dropdownAtividades
                                                        }
                                                        onChange={(name, value) => {
                                                            if (value == 5) {
                                                                this.props.detalhes.atividade.showDateTime = false;
                                                                var dt = new Date();
                                                                this.props.detalhes.atividade.data = dt.getFullYear() + '-' + dt.getMonth() + '-' + dt.getDay();
                                                                this.props.detalhes.atividade.hora = dt.getHours();
                                                                this.props.detalhes.atividade.minuto = dt.getMinutes();
                                                            }
                                                            else {
                                                                this.props.detalhes.atividade.showDateTime = true;
                                                                this.props.detalhes.atividade.data = '';
                                                                this.props.detalhes.atividade.hora = '';
                                                                this.props.detalhes.atividade.minuto = '';
                                                            }
                                                            this.props.detalhes.atividade.idCanalAtividade = value;
                                                            this.props.fnUpdateState(this.props);

                                                        }}
                                                        title="Tipo da Atividade:"
                                                        disabled={this.props.detalhes.atividade.dropdownAtividades.isLoading ? true : false}
                                                        selectedValue={(this.props.detalhes.atividade.idCanalAtividade == null) ? null : this.props.detalhes.atividade.idCanalAtividade.toString()}
                                                        required={false}
                                                    />
                                                    {this.props.detalhes.atividade.showDateTime
                                                        ? <div>
                                                            <div className="form-group col-md-6">
                                                                <label>Data agendamento:</label>
                                                                <DateTimePicker
                                                                    name="txtData"
                                                                    onChange={(value) => {
                                                                        this.props.detalhes.atividade.data = value;
                                                                        this.props.fnUpdateState(this.props);
                                                                    }}
                                                                    value={this.props.detalhes.atividade.data}
                                                                />
                                                            </div>
                                                            <InputTextValidated
                                                                name="txtHora"
                                                                type="number"
                                                                className="col-md-3"
                                                                placeholder=""
                                                                onChange={(name, value) => {
                                                                    this.props.detalhes.atividade.hora = value;
                                                                    this.props.fnUpdateState(this.props);
                                                                }}
                                                                value={this.props.detalhes.atividade.hora}
                                                                title="Hora:"
                                                                required={false}
                                                            />
                                                            <InputTextValidated
                                                                name="txtMinuto"
                                                                type="number"
                                                                className="col-md-3"
                                                                placeholder=""
                                                                onChange={(name, value) => {
                                                                    this.props.detalhes.atividade.minuto = value;
                                                                    this.props.fnUpdateState(this.props);
                                                                }}
                                                                value={this.props.detalhes.atividade.minuto}
                                                                title="Minuto:"
                                                                required={false}
                                                            />
                                                        </div>
                                                        : null}
                                                    <TextAreaValidated
                                                        name="txtAnotacoes"
                                                        placeholder=""
                                                        rows={6}
                                                        className="col-md-12"
                                                        onChange={(name, value) => {
                                                            this.props.detalhes.atividade.anotacoes = value;
                                                            this.props.fnUpdateState(this.props);
                                                        }}
                                                        value={this.props.detalhes.atividade.anotacoes}
                                                        title="Anotações da Atividade:"
                                                    />
                                                    {this.props.detalhes.oportunidade.idOportunidadeStatus == 1
                                                        ? <div className="col-md-12 text-right">
                                                            <BtnSalvar
                                                                isLoading={this.props.detalhes.atividade.isLoading}
                                                                text={this.props.detalhes.atividade.isLoading ? " Aguarde..." : " Adicionar Atividade"}
                                                                disabled={this.props.detalhes.atividade.isLoading}
                                                            />
                                                        </div>
                                                        : null}
                                                </Row>
                                            </Formsy>
                                        </div>
                                    </div>
                            }
                        </Panel.Body>
                    </Panel>
                </Section>
                {this.props.modalUsuarios.showModal ? <ModalUsuarios /> : null}
                {this.props.modalReabrir.showModal ? <ModalReabrir /> : null}
                {this.props.modalFinalizar.showModal ? <ModalFinalizar /> : null}
            </div>
        </Row>);
    }
}

export default connect(
    state => state.crm_consulta,
    dispatch => bindActionCreators(actionCreators, dispatch),
    null, {}
)(DetalhesForm);