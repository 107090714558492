import { fetch, addTask } from 'domain-task';
import * as crmconfiguracao_service from '../../services/crm/crmconfiguracao_service';
import { getStoredState } from 'redux-persist';

const UPDATE_STATE_CRM_CONFIGURACAO_ACTION = 'UPDATE_STATE_CRM_CONFIGURACAO_ACTION';

const REQUEST_CRM_LISTA_USUARIOS_NAO_ADICIONADOS_ACTION = 'REQUEST_CRM_LISTA_USUARIOS_NAO_ADICIONADOS_ACTION';
const RESPONSE_CRM_LISTA_USUARIOS_NAO_ADICIONADOS_ACTION = 'RESPONSE_CRM_LISTA_USUARIOS_NAO_ADICIONADOS_ACTION';
const ERROR_CRM_LISTA_USUARIOS_NAO_ADICIONADOS_ACTION = 'ERROR_CRM_LISTA_USUARIOS_NAO_ADICIONADOS_ACTION';

const REQUEST_CRM_LISTA_USUARIOS_ADICIONADOS_ACTION = 'REQUEST_CRM_LISTA_USUARIOS_ADICIONADOS_ACTION';
const RESPONSE_CRM_LISTA_USUARIOS_ADICIONADOS_ACTION = 'RESPONSE_CRM_LISTA_USUARIOS_ADICIONADOS_ACTION';
const ERROR_CRM_LISTA_USUARIOS_ADICIONADOS_ACTION = 'ERROR_CRM_LISTA_USUARIOS_ADICIONADOS_ACTION';

const POST_CRM_USUARIO_DISTRIBUICAO_ACTION = 'POST_CRM_USUARIO_DISTRIBUICAO_ACTION';
const DELETE_CRM_USUARIO_DISTRIBUICAO_ACTION = 'DELETE_CRM_USUARIO_DISTRIBUICAO_ACTION';
const RESPONSE_CRM_USUARIO_DISTRIBUICAO_ACTION = 'RESPONSE_CRM_USUARIO_DISTRIBUICAO_ACTION';
const ERROR_CRM_USUARIO_DISTRIBUICAO_ACTION = 'ERROR_CRM_USUARIO_DISTRIBUICAO_ACTION';

const REQUEST_CRM_TIPO_DISTRIBUICAO = 'REQUEST_CRM_TIPO_DISTRIBUICAO';
const PUT_CRM_TIPO_DISTRIBUICAO = 'PUT_CRM_TIPO_DISTRIBUICAO';
const RESPONSE_CRM_TIPO_DISTRIBUICAO = 'RESPONSE_CRM_TIPO_DISTRIBUICAO';
const ERROR_CRM_TIPO_DISTRIBUICAO = 'ERROR_CRM_TIPO_DISTRIBUICAO';

const REQUEST_CRM_LISTA_PORTAIS_ATIVOS = 'REQUEST_CRM_LISTA_PORTAIS_ATIVOS';
const RESPONSE_CRM_LISTA_PORTAIS_ATIVOS = 'RESPONSE_CRM_LISTA_PORTAIS_ATIVOS';
const ERROR_CRM_LISTA_PORTAIS_ATIVOS = 'ERROR_CRM_LISTA_PORTAIS_ATIVOS';

const POST_CRM_EMPRESA_CANAL_LEAD = 'POST_CRM_EMPRESA_CANAL_LEAD';
const PUT_CRM_EMPRESA_CANAL_LEAD = 'PUT_CRM_EMPRESA_CANAL_LEAD';
const DELETE_CRM_EMPRESA_CANAL_LEAD = 'DELETE_CRM_EMPRESA_CANAL_LEAD';
const SUCCESS_CRM_EMPRESA_CANAL_LEAD = 'SUCCESS_CRM_EMPRESA_CANAL_LEAD';
const ERROR_CRM_EMPRESA_CANAL_LEAD = 'ERROR_CRM_EMPRESA_CANAL_LEAD';
const REQUEST_CRM_EMPRESA_CANAL_LEAD = 'REQUEST_CRM_EMPRESA_CANAL_LEAD';
const RESPONSE_CRM_EMPRESA_CANAL_LEAD = 'RESPONSE_CRM_EMPRESA_CANAL_LEAD';

const REQUEST_CRM_EMPRESA = 'REQUEST_CRM_EMPRESA';
const RESPONSE_CRM_EMPRESA = 'RESPONSE_CRM_EMPRESA';
const ERROR_CRM_EMPRESA = 'ERROR_CRM_EMPRESA';
const POST_CRM_EMPRESA = 'POST_CRM_EMPRESA';

const initialState = {
    empresa: {
        isLoading: false,
        isError: false,
        errorText: '',
        id: 0,
        idEmpresaGrupo: 0,
        cnpj: '',
        razaosocial: '',
        nomefantasia: '',
    },
    tipoDistribuicaoUsuario: {
        errorText: '',
        isLoading: false,
        idTipoDistribuicao: 1,
        listaUsuariosNaoAdicionados: {
            errorText: '',
            isLoading: false,
            idUsuario: '',
            results: []
        },
        listaUsuariosAdicionados: {
            errorText: '',
            isLoading: false,
            idUsuario: 0,
            results: []
        }
    },
    configuracaoPortais: {
        portaisAtivos: {
            isLoading: false,
            errorText: '',
            results: [],
        },
        modalPortais: {
            isLoading: false,
            errorText: '',
            successText: '',
            ativado: false,
            idCanalLead: 0,
            email: '',
            jsonAutenticacao: '',
            modalWebMotors: { showModal: false, email: '', senha: '' },
            modalICarros: { showModal: false, email: '' },
            modalAutoLine: { showModal: false, email: '' },
            modalMeuCarroNovo: { showModal: false, email: '' },
            modalSiteCliente: { showModal: false, email: '' },
            modalOLX: { showModal: false, email: '', token: '' },
            modalMercadoLivre: { showModal: false, email: '', token: '' },
        }
    }
};

export const actionCreators = {
    fnUpdateState: (pCrmConfiguracaoState) => async (dispatch) => {
        dispatch({
            type: 'UPDATE_STATE_CRM_CONFIGURACAO_ACTION',
            empresa: {
                isLoading: pCrmConfiguracaoState.empresa.isLoading,
                isError: pCrmConfiguracaoState.empresa.isError,
                errorText: pCrmConfiguracaoState.empresa.errorText,
                id: pCrmConfiguracaoState.empresa.id,
                idEmpresaGrupo: pCrmConfiguracaoState.empresa.idEmpresaGrupo,
                cnpj: pCrmConfiguracaoState.empresa.cnpj,
                razaosocial: pCrmConfiguracaoState.empresa.razaosocial,
                nomefantasia: pCrmConfiguracaoState.empresa.nomefantasia,
            },
            tipoDistribuicaoUsuario: {
                errorText: '',
                isLoading: false,
                idTipoDistribuicao: pCrmConfiguracaoState.tipoDistribuicaoUsuario.idTipoDistribuicao,
                listaUsuariosNaoAdicionados: {
                    errorText: pCrmConfiguracaoState.tipoDistribuicaoUsuario.listaUsuariosNaoAdicionados.errorText,
                    isLoading: pCrmConfiguracaoState.tipoDistribuicaoUsuario.listaUsuariosNaoAdicionados.isLoading,
                    idUsuario: pCrmConfiguracaoState.tipoDistribuicaoUsuario.listaUsuariosNaoAdicionados.idUsuario,
                    results: pCrmConfiguracaoState.tipoDistribuicaoUsuario.listaUsuariosNaoAdicionados.results
                },
                listaUsuariosAdicionados: {
                    errorText: pCrmConfiguracaoState.tipoDistribuicaoUsuario.listaUsuariosAdicionados.errorText,
                    isLoading: pCrmConfiguracaoState.tipoDistribuicaoUsuario.listaUsuariosAdicionados.isLoading,
                    idUsuario: pCrmConfiguracaoState.tipoDistribuicaoUsuario.listaUsuariosAdicionados.idUsuario,
                    results: pCrmConfiguracaoState.tipoDistribuicaoUsuario.listaUsuariosAdicionados.results
                }
            },
            configuracaoPortais: {
                portaisAtivos: {
                    isLoading: pCrmConfiguracaoState.configuracaoPortais.portaisAtivos.isLoading,
                    errorText: pCrmConfiguracaoState.configuracaoPortais.portaisAtivos.errorText,
                    results: pCrmConfiguracaoState.configuracaoPortais.portaisAtivos.results,
                },
                modalPortais: {
                    isLoading: pCrmConfiguracaoState.configuracaoPortais.modalPortais.isLoading,
                    errorText: pCrmConfiguracaoState.configuracaoPortais.modalPortais.errorText,
                    successText: pCrmConfiguracaoState.configuracaoPortais.modalPortais.successText,
                    ativado: pCrmConfiguracaoState.configuracaoPortais.modalPortais.ativado,
                    idCanalLead: pCrmConfiguracaoState.configuracaoPortais.modalPortais.idCanalLead,
                    email: pCrmConfiguracaoState.configuracaoPortais.modalPortais.email,
                    jsonAutenticacao: pCrmConfiguracaoState.configuracaoPortais.modalPortais.jsonAutenticacao,
                    modalWebMotors: {
                        showModal: pCrmConfiguracaoState.configuracaoPortais.modalPortais.modalWebMotors.showModal,
                        email: pCrmConfiguracaoState.configuracaoPortais.modalPortais.modalWebMotors.email,
                        senha: pCrmConfiguracaoState.configuracaoPortais.modalPortais.modalWebMotors.senha,
                    },
                    modalICarros: {
                        showModal: pCrmConfiguracaoState.configuracaoPortais.modalPortais.modalICarros.showModal,
                        email: pCrmConfiguracaoState.configuracaoPortais.modalPortais.modalICarros.email,
                    },
                    modalAutoLine: {
                        showModal: pCrmConfiguracaoState.configuracaoPortais.modalPortais.modalAutoLine.showModal,
                        email: pCrmConfiguracaoState.configuracaoPortais.modalPortais.modalAutoLine.email,
                    },
                    modalMeuCarroNovo: {
                        showModal: pCrmConfiguracaoState.configuracaoPortais.modalPortais.modalMeuCarroNovo.showModal,
                        email: pCrmConfiguracaoState.configuracaoPortais.modalPortais.modalMeuCarroNovo.email,
                    },
                    modalSiteCliente: {
                        showModal: pCrmConfiguracaoState.configuracaoPortais.modalPortais.modalSiteCliente.showModal,
                        email: pCrmConfiguracaoState.configuracaoPortais.modalPortais.modalSiteCliente.email,
                    },
                    modalOLX: {
                        showModal: pCrmConfiguracaoState.configuracaoPortais.modalPortais.modalOLX.showModal,
                        email: pCrmConfiguracaoState.configuracaoPortais.modalPortais.modalOLX.email,
                        token: pCrmConfiguracaoState.configuracaoPortais.modalPortais.modalOLX.token,
                    },
                    modalMercadoLivre: {
                        showModal: pCrmConfiguracaoState.configuracaoPortais.modalPortais.modalMercadoLivre.showModal,
                        email: pCrmConfiguracaoState.configuracaoPortais.modalPortais.modalMercadoLivre.email,
                        token: pCrmConfiguracaoState.configuracaoPortais.modalPortais.modalMercadoLivre.token,
                    },
                }
            }
        });
    }
    , fnListaUsuariosNaoAdicionados: () => async (dispatch, getState) => {
        dispatch({ type: 'REQUEST_CRM_LISTA_USUARIOS_NAO_ADICIONADOS_ACTION' });

        await fetch(crmconfiguracao_service.services.listaUsuariosNaoAdicionados(getState().auth.authorizationToken))
            .then(response => {
                if (response.status === 200 || response.status === 0) {
                    return response;
                }
                else {
                    return response.json().then(function (object) {
                        return Promise.reject(new Error(object));
                    })
                }
            })
            .then(data => {
                data.json().then(function (object) {
                    dispatch({ type: 'RESPONSE_CRM_LISTA_USUARIOS_NAO_ADICIONADOS_ACTION', results: object });
                });
            }).catch(function (err) {
                dispatch({ type: 'ERROR_CRM_LISTA_USUARIOS_NAO_ADICIONADOS_ACTION', errorText: err.toString() });
            });
    }
    , fnListaUsuariosAdicionados: () => async (dispatch, getState) => {
        dispatch({ type: 'REQUEST_CRM_LISTA_USUARIOS_ADICIONADOS_ACTION' });

        await fetch(crmconfiguracao_service.services.listaUsuariosAdicionados(getState().auth.authorizationToken))
            .then(response => {
                if (response.status === 200 || response.status === 0) {
                    return response;
                }
                else {
                    return response.json().then(function (object) {
                        return Promise.reject(new Error(object));
                    })
                }
            })
            .then(data => {
                data.json().then(function (object) {
                    dispatch({ type: 'RESPONSE_CRM_LISTA_USUARIOS_ADICIONADOS_ACTION', results: object });
                });
            }).catch(function (err) {
                dispatch({ type: 'ERROR_CRM_LISTA_USUARIOS_ADICIONADOS_ACTION', errorText: err.toString() });
            });
    }
    , fnAdicionaUsuarioDistribuicao: () => async (dispatch, getState) => {
        var pDTO = { idUsuario: getState().crm_configuracao.tipoDistribuicaoUsuario.listaUsuariosNaoAdicionados.idUsuario };

        dispatch({ type: 'POST_CRM_USUARIO_DISTRIBUICAO_ACTION' });

        await fetch(crmconfiguracao_service.services.adicionarUsuarioDistribuicao(pDTO, getState().auth.authorizationToken))
            .then(response => {
                if (response.status === 200 || response.status === 0) {
                    return response;
                }
                else {
                    return response.json().then(function (object) {
                        return Promise.reject(new Error(object));
                    })
                }
            })
            .then(data => {
                data.json().then(function (object) {
                    dispatch({ type: 'RESPONSE_CRM_USUARIO_DISTRIBUICAO_ACTION' });
                    dispatch(actionCreators.fnListaUsuariosNaoAdicionados());
                    dispatch(actionCreators.fnListaUsuariosAdicionados());
                });
            }).catch(function (err) {
                dispatch({ type: 'ERROR_CRM_USUARIO_DISTRIBUICAO_ACTION', errorText: err.toString() });
            });
    }
    , fnRemoverUsuarioDistribuicao: (pidUsuario) => async (dispatch, getState) => {
        var pDTO = { idUsuario: pidUsuario };

        dispatch({ type: 'DELETE_CRM_USUARIO_DISTRIBUICAO_ACTION' });

        await fetch(crmconfiguracao_service.services.removerUsuarioDistribuicao(pDTO, getState().auth.authorizationToken))
            .then(response => {
                if (response.status === 200 || response.status === 0) {
                    return response;
                }
                else {
                    return response.json().then(function (object) {
                        return Promise.reject(new Error(object));
                    })
                }
            })
            .then(data => {
                data.json().then(function (object) {
                    dispatch({ type: 'RESPONSE_CRM_USUARIO_DISTRIBUICAO_ACTION' });
                    dispatch(actionCreators.fnListaUsuariosNaoAdicionados());
                    dispatch(actionCreators.fnListaUsuariosAdicionados());
                });
            }).catch(function (err) {
                dispatch({ type: 'ERROR_CRM_USUARIO_DISTRIBUICAO_ACTION', errorText: err.toString() });
            });
    }
    , fnObterTipoDistribuicao: () => async (dispatch, getState) => {
        dispatch({ type: 'REQUEST_CRM_TIPO_DISTRIBUICAO' });

        await fetch(crmconfiguracao_service.services.obterTipoDistribuicao(getState().auth.authorizationToken))
            .then(response => {
                if (response.status === 200 || response.status === 0) {
                    return response;
                }
                else {
                    return response.json().then(function (object) {
                        return Promise.reject(new Error(object));
                    })
                }
            })
            .then(data => {
                data.json().then(function (object) {
                    dispatch({ type: 'RESPONSE_CRM_TIPO_DISTRIBUICAO', result: object.tipo });
                });
            }).catch(function (err) {
                dispatch({ type: 'ERROR_CRM_TIPO_DISTRIBUICAO', errorText: err.toString() });
            });
    }
    , fnAlterarTipoDistribuicao: () => async (dispatch, getState) => {
        var pDTO = { tipo: getState().crm_configuracao.tipoDistribuicaoUsuario.idTipoDistribuicao };

        dispatch({ type: 'PUT_CRM_TIPO_DISTRIBUICAO' });

        await fetch(crmconfiguracao_service.services.atualizarTipoDistribuicao(pDTO, getState().auth.authorizationToken))
            .then(response => {
                if (response.status === 200 || response.status === 0) {
                    return response;
                }
                else {
                    return response.json().then(function (object) {
                        return Promise.reject(new Error(object));
                    })
                }
            })
            .then(data => {
                data.json().then(function (object) {
                    dispatch({ type: 'RESPONSE_CRM_TIPO_DISTRIBUICAO', result: getState().crm_configuracao.tipoDistribuicaoUsuario.idTipoDistribuicao });
                });
            }).catch(function (err) {
                dispatch({ type: 'ERROR_CRM_TIPO_DISTRIBUICAO', errorText: err.toString() });
            });
    }
    , fnAlterarRecebeLeadWebUsuario: (idUsuario, recebeLeadWeb) => async (dispatch, getState) => {
        await fetch(crmconfiguracao_service.services.atualizarRecebeLeadWebUsuario(idUsuario, recebeLeadWeb, getState().auth.authorizationToken))
            .then(response => {
                if (response.status === 200 || response.status === 0) {
                    return response;
                }
                else {
                    return response.json().then(function (object) {
                        return Promise.reject(new Error(object));
                    })
                }
            })
            .then(data => {
                data.json().then(function (object) {
                    //dispatch({ type: 'RESPONSE_CRM_TIPO_DISTRIBUICAO', result: getState().crm_configuracao.tipoDistribuicaoUsuario.idTipoDistribuicao });
                });
            }).catch(function (err) {
                //dispatch({ type: 'ERROR_CRM_TIPO_DISTRIBUICAO', errorText: err.toString() });
            });
    }
    , fnListaPortaisAtivos: () => async (dispatch, getState) => {
        dispatch({ type: 'REQUEST_CRM_LISTA_PORTAIS_ATIVOS' });

        await fetch(crmconfiguracao_service.services.listaPortaisAtivos(getState().auth.authorizationToken))
            .then(response => {
                if (response.status === 200 || response.status === 0) {
                    return response;
                }
                else {
                    return response.json().then(function (object) {
                        return Promise.reject(new Error(object));
                    })
                }
            })
            .then(data => {
                data.json().then(function (object) {
                    dispatch({ type: 'RESPONSE_CRM_LISTA_PORTAIS_ATIVOS', results: object });
                });
            }).catch(function (err) {
                dispatch({ type: 'ERROR_CRM_LISTA_PORTAIS_ATIVOS', errorText: err.toString() });
            });
    }
    , fnInsereEmpresaCanalLead: () => async (dispatch, getState) => {
        var pDTO = {
            idCanalLead: getState().crm_configuracao.configuracaoPortais.modalPortais.idCanalLead,
            email: getState().crm_configuracao.configuracaoPortais.modalPortais.email,
            jsonAutenticacao: getState().crm_configuracao.configuracaoPortais.modalPortais.jsonAutenticacao,
        };

        dispatch({ type: 'POST_CRM_EMPRESA_CANAL_LEAD' });

        try {
            const response = await fetch(crmconfiguracao_service.services.inserirEmpresaCanalLead(pDTO, getState().auth.authorizationToken));
            if (!response.ok) {
                throw new Error(response.statusText);
            }
            else {
                dispatch({ type: 'SUCCESS_CRM_EMPRESA_CANAL_LEAD', successText: "Configuração realizada com sucesso!" });
                dispatch(actionCreators.fnListaPortaisAtivos());
            }
        } catch (err) {
            dispatch({ type: 'ERROR_CRM_EMPRESA_CANAL_LEAD', errorText: err.toString() });
        }
    }
    , fnAtualizaEmpresaCanalLead: () => async (dispatch, getState) => {
        var pDTO = {
            idCanalLead: getState().crm_configuracao.configuracaoPortais.modalPortais.idCanalLead,
            email: getState().crm_configuracao.configuracaoPortais.modalPortais.email,
            jsonAutenticacao: getState().crm_configuracao.configuracaoPortais.modalPortais.jsonAutenticacao,
        };

        dispatch({ type: 'PUT_CRM_EMPRESA_CANAL_LEAD' });

        try {
            const response = await fetch(crmconfiguracao_service.services.atualizaEmpresaCanalLead(pDTO, getState().auth.authorizationToken));
            if (!response.ok) {
                throw new Error(response.statusText);
            }
            else {
                dispatch({ type: 'SUCCESS_CRM_EMPRESA_CANAL_LEAD', successText: "Configuração atualizada com sucesso!" });
            }
        } catch (err) {
            dispatch({ type: 'ERROR_CRM_EMPRESA_CANAL_LEAD', errorText: err.toString() });
        }
    }
    , fnObterEmpresaCanalLead: () => async (dispatch, getState) => {
        dispatch({ type: 'REQUEST_CRM_EMPRESA_CANAL_LEAD' });

        try {
            const response = await fetch(crmconfiguracao_service.services.obterEmpresaCanalLead(
                getState().crm_configuracao.configuracaoPortais.modalPortais.idCanalLead,
                getState().auth.authorizationToken));
            if (!response.ok) {
                throw new Error(response.statusText);
            }
            else {
                const json = await response.json();
                dispatch({
                    type: 'RESPONSE_CRM_EMPRESA_CANAL_LEAD',
                    email: json.email,
                    jsonAutenticacao: json.jsonAutenticacao,
                    ativado: (json.id === 0 ? false : true)
                });
            }
        } catch (err) {
            dispatch({ type: 'ERROR_CRM_EMPRESA_CANAL_LEAD', errorText: err.toString() });
        }
    }
    , fnDeleteEmpresaCanalLead: () => async (dispatch, getState) => {
        dispatch({ type: 'DELETE_CRM_EMPRESA_CANAL_LEAD' });

        try {
            const response = await fetch(crmconfiguracao_service.services.removerEmpresaCanalLead(
                getState().crm_configuracao.configuracaoPortais.modalPortais.idCanalLead,
                getState().auth.authorizationToken));
            if (!response.ok) {
                throw new Error(response.statusText);
            }
            else {
                const json = await response.json();
                dispatch({
                    type: 'SUCCESS_CRM_EMPRESA_CANAL_LEAD',
                    email: json.email,
                    jsonAutenticacao: json.jsonAutenticacao,
                    ativado: (json.id === 0 ? false : true)
                });
                dispatch(actionCreators.fnListaPortaisAtivos());
            }
        } catch (err) {
            dispatch({ type: 'ERROR_CRM_EMPRESA_CANAL_LEAD', errorText: err.toString() });
        }
    }
    , fnBuscaEmpresa: () => async (dispatch, getState) => {
        dispatch({ type: 'REQUEST_CRM_EMPRESA' });
        try {
            const response = await fetch(crmconfiguracao_service.services.buscaEmpresa(getState().auth.authorizationToken));
            if (!response.ok) {
                throw new Error(response.statusText);
            }
            else {
                const json = await response.json();
                dispatch({ type: 'RESPONSE_CRM_EMPRESA', result: json });
            }
        } catch (err) {
            dispatch({ type: 'ERROR_CRM_EMPRESA', errorText: err.toString() });
        }
    }
    , fnInserirEmpresa: () => async (dispatch, getState) => {
        dispatch({ type: 'POST_CRM_EMPRESA' });
        try {
            const response = await fetch(crmconfiguracao_service.services.inserirEmpresa(getState().auth.authorizationToken));
            if (!response.ok) {
                throw new Error(response.statusText);
            }
            else {
                const json = await response.json();
                dispatch({ type: 'RESPONSE_CRM_EMPRESA', result: json });
            }
        } catch (err) {
            dispatch({ type: 'ERROR_CRM_EMPRESA', errorText: err.toString() });
        }
    }
};

export const reducer = (state, action) => {
    state = state || initialState;


    if (action.type === UPDATE_STATE_CRM_CONFIGURACAO_ACTION) {
        return {
            ...state,
            tipoDistribuicaoUsuario: action.tipoDistribuicaoUsuario
        };
    }


    if (action.type === REQUEST_CRM_LISTA_USUARIOS_NAO_ADICIONADOS_ACTION) {
        return {
            ...state,
            tipoDistribuicaoUsuario: {
                ...state.tipoDistribuicaoUsuario,
                listaUsuariosNaoAdicionados: {
                    ...state.tipoDistribuicaoUsuario.listaUsuariosNaoAdicionados,
                    errorText: '',
                    isLoading: true
                }
            }
        };
    }
    if (action.type === RESPONSE_CRM_LISTA_USUARIOS_NAO_ADICIONADOS_ACTION) {
        return {
            ...state,
            tipoDistribuicaoUsuario: {
                ...state.tipoDistribuicaoUsuario,
                listaUsuariosNaoAdicionados: {
                    ...state.tipoDistribuicaoUsuario.listaUsuariosNaoAdicionados,
                    isLoading: false,
                    results: action.results
                }
            }
        };
    }
    if (action.type === ERROR_CRM_LISTA_USUARIOS_NAO_ADICIONADOS_ACTION) {
        return {
            ...state,
            tipoDistribuicaoUsuario: {
                ...state.tipoDistribuicaoUsuario,
                listaUsuariosNaoAdicionados: {
                    ...state.tipoDistribuicaoUsuario.listaUsuariosNaoAdicionados,
                    isLoading: false,
                    errorText: action.errorText
                }
            }
        };
    }


    if (action.type === REQUEST_CRM_LISTA_USUARIOS_ADICIONADOS_ACTION) {
        return {
            ...state,
            tipoDistribuicaoUsuario: {
                ...state.tipoDistribuicaoUsuario,
                listaUsuariosAdicionados: {
                    ...state.tipoDistribuicaoUsuario.listaUsuariosAdicionados,
                    errorText: '',
                    isLoading: true
                }
            }
        };
    }
    if (action.type === RESPONSE_CRM_LISTA_USUARIOS_ADICIONADOS_ACTION) {
        return {
            ...state,
            tipoDistribuicaoUsuario: {
                ...state.tipoDistribuicaoUsuario,
                listaUsuariosAdicionados: {
                    ...state.tipoDistribuicaoUsuario.listaUsuariosAdicionados,
                    isLoading: false,
                    results: action.results
                }
            }
        };
    }
    if (action.type === ERROR_CRM_LISTA_USUARIOS_ADICIONADOS_ACTION) {
        return {
            ...state,
            tipoDistribuicaoUsuario: {
                ...state.tipoDistribuicaoUsuario,
                listaUsuariosAdicionados: {
                    ...state.tipoDistribuicaoUsuario.listaUsuariosAdicionados,
                    isLoading: false,
                    errorText: action.errorText
                }
            }
        };
    }


    if (action.type === POST_CRM_USUARIO_DISTRIBUICAO_ACTION) {
        return {
            ...state,
            tipoDistribuicaoUsuario: {
                ...state.tipoDistribuicaoUsuario,
                errorText: '',
                isLoading: true
            }
        };
    }
    if (action.type === DELETE_CRM_USUARIO_DISTRIBUICAO_ACTION) {
        return {
            ...state,
            tipoDistribuicaoUsuario: {
                ...state.tipoDistribuicaoUsuario,
                errorText: '',
                isLoading: true
            }
        };
    }
    if (action.type === RESPONSE_CRM_USUARIO_DISTRIBUICAO_ACTION) {
        return {
            ...state,
            tipoDistribuicaoUsuario: {
                ...state.tipoDistribuicaoUsuario,
                isLoading: false,
                listaUsuariosNaoAdicionados: {
                    ...state.tipoDistribuicaoUsuario.listaUsuariosNaoAdicionados,
                    idUsuario: ''
                }
            }
        };
    }
    if (action.type === ERROR_CRM_USUARIO_DISTRIBUICAO_ACTION) {
        return {
            ...state,
            tipoDistribuicaoUsuario: {
                ...state.tipoDistribuicaoUsuario,
                errorText: action.errorText,
                isLoading: false
            }
        };
    }


    if (action.type === REQUEST_CRM_TIPO_DISTRIBUICAO) {
        return {
            ...state,
            tipoDistribuicaoUsuario: {
                ...state.tipoDistribuicaoUsuario,
                errorText: '',
                isLoading: true
            }
        };
    }
    if (action.type === PUT_CRM_TIPO_DISTRIBUICAO) {
        return {
            ...state,
            tipoDistribuicaoUsuario: {
                ...state.tipoDistribuicaoUsuario,
                errorText: '',
                isLoading: true
            }
        };
    }
    if (action.type === RESPONSE_CRM_TIPO_DISTRIBUICAO) {
        return {
            ...state,
            tipoDistribuicaoUsuario: {
                ...state.tipoDistribuicaoUsuario,
                idTipoDistribuicao: action.result,
                isLoading: false
            }
        };
    }
    if (action.type === ERROR_CRM_TIPO_DISTRIBUICAO) {
        return {
            ...state,
            tipoDistribuicaoUsuario: {
                ...state.tipoDistribuicaoUsuario,
                errorText: action.errorText,
                isLoading: false
            }
        };
    }


    if (action.type === REQUEST_CRM_LISTA_PORTAIS_ATIVOS) {
        return {
            ...state,
            configuracaoPortais: {
                ...state.configuracaoPortais,
                portaisAtivos: {
                    ...state.configuracaoPortais.portaisAtivos,
                    isLoading: true,
                    errorText: '',
                    results: [],
                }
            }
        };
    }
    if (action.type === RESPONSE_CRM_LISTA_PORTAIS_ATIVOS) {
        return {
            ...state,
            configuracaoPortais: {
                ...state.configuracaoPortais,
                portaisAtivos: {
                    ...state.configuracaoPortais.portaisAtivos,
                    isLoading: false,
                    results: action.results,
                }
            }
        };
    }
    if (action.type === ERROR_CRM_LISTA_PORTAIS_ATIVOS) {
        return {
            ...state,
            configuracaoPortais: {
                ...state.configuracaoPortais,
                portaisAtivos: {
                    ...state.configuracaoPortais.portaisAtivos,
                    isLoading: false,
                    errorText: action.errorText,
                }
            }
        };
    }


    if (action.type === POST_CRM_EMPRESA_CANAL_LEAD) {
        return {
            ...state,
            configuracaoPortais: {
                ...state.configuracaoPortais,
                modalPortais: {
                    ...state.configuracaoPortais.modalPortais,
                    isLoading: true,
                    errorText: '',
                    successText: '',
                }
            }
        };
    }
    if (action.type === PUT_CRM_EMPRESA_CANAL_LEAD) {
        return {
            ...state,
            configuracaoPortais: {
                ...state.configuracaoPortais,
                modalPortais: {
                    ...state.configuracaoPortais.modalPortais,
                    isLoading: true,
                    errorText: '',
                    successText: '',
                }
            }
        };
    }
    if (action.type === SUCCESS_CRM_EMPRESA_CANAL_LEAD) {
        return {
            ...state,
            configuracaoPortais: {
                ...state.configuracaoPortais,
                modalPortais: {
                    ...state.configuracaoPortais.modalPortais,
                    isLoading: false,
                    successText: action.successText,
                    ativado: true,
                }
            }
        };
    }
    if (action.type === ERROR_CRM_EMPRESA_CANAL_LEAD) {
        return {
            ...state,
            configuracaoPortais: {
                ...state.configuracaoPortais,
                modalPortais: {
                    ...state.configuracaoPortais.modalPortais,
                    isLoading: false,
                    errorText: action.errorText,
                }
            }
        };
    }
    if (action.type === REQUEST_CRM_EMPRESA_CANAL_LEAD) {
        return {
            ...state,
            configuracaoPortais: {
                ...state.configuracaoPortais,
                modalPortais: {
                    ...state.configuracaoPortais.modalPortais,
                    isLoading: true,
                    errorText: '',
                    successText: '',
                }
            }
        };
    }
    if (action.type === RESPONSE_CRM_EMPRESA_CANAL_LEAD) {
        return {
            ...state,
            configuracaoPortais: {
                ...state.configuracaoPortais,
                modalPortais: {
                    ...state.configuracaoPortais.modalPortais,
                    isLoading: false,
                    email: action.email,
                    jsonAutenticacao: action.jsonAutenticacao,
                    ativado: action.ativado,
                }
            }
        };
    }
    if (action.type === DELETE_CRM_EMPRESA_CANAL_LEAD) {
        return {
            ...state,
            configuracaoPortais: {
                ...state.configuracaoPortais,
                modalPortais: {
                    ...state.configuracaoPortais.modalPortais,
                    isLoading: true,
                    errorText: '',
                    successText: '',
                }
            }
        };
    }

    if (action.type === REQUEST_CRM_EMPRESA) {
        return {
            ...state,
            empresa: {
                ...state.empresa,
                isLoading: true,
                isError: false,
                errorText: '',
            }
        };
    }
    if (action.type === RESPONSE_CRM_EMPRESA) {
        return {
            ...state,
            empresa: {
                ...state.empresa,
                isLoading: false,
                id: action.result.id,
                idEmpresaGrupo: action.result.idEmpresaGrupo,
                cnpj: action.result.cnpj,
                razaosocial: action.result.razaosocial,
                nomefantasia: action.result.nomefantasia
            }
        };
    }
    if (action.type === ERROR_CRM_EMPRESA) {
        return {
            ...state,
            empresa: {
                ...state.empresa,
                isLoading: false,
                isError: true,
                errorText: action.errorText,
            }
        };
    }
    if (action.type === POST_CRM_EMPRESA) {
        return {
            ...state,
            empresa: {
                ...state.empresa,
                isLoading: true,
                isError: false,
                errorText: '',
            }
        };
    }

    return state;
};

