import * as React from "react";

export default class TextArea extends React.Component { 
    constructor() {
        super();
        this.state = {
            name: '',
            value: ''
        }
    }

    componentWillMount() {
        this.setState({ name: this.props.name, value: this.props.value });
    }

    componentWillReceiveProps(nextProps) {
        this.setState({ name: this.props.name, value: nextProps.value });
    }

    _onChange(e) {
        this.setState({ name: e.target.name, value: e.target.value });
        this.props.onChange(e.target.name, e.target.value);
    }

    render() {
        return (
            <textarea
                className={this.props.class}
                placeholder={this.props.placeholder}
                id={this.props.id}
                name={this.state.name}
                value={this.state.value}
                rows={this.props.rows}
                onChange={(this.props.onChange == undefined) ? null : this._onChange.bind(this)}
                required={this.props.required ? true : false}
                data-parsley-required-message={this.props.requiredErrorText}
                minLength={this.props.minlength}
                data-parsley-minlength-message={this.props.minlenghtErrorText}
                data-parsley-type-message={this.props.typeErrorText}
                data-parsley-equalto={this.props.requiredEqualsTo}
                data-parsley-equalto-message={this.props.equalsToErrorText}
                data-parsley-trigger={this.props.triggerRequiredType}
                disabled={this.props.disabled ? true : false}
            />
        );
    }
}