import { fetch, addTask } from 'domain-task';
import * as edocconsulta_service from '../../services/edoc/edocconsulta_service';

const UPDATE_STATE_EDOC_CONSULTA_ACTION = 'UPDATE_STATE_EDOC_CONSULTA_ACTION';
const REQUEST_EDOC_CATEGORIAS_ACTION = 'REQUEST_EDOC_CATEGORIAS_ACTION';
const RESPONSE_EDOC_CATEGORIAS_ACTION = 'RESPONSE_EDOC_CATEGORIAS_ACTION';
const ERROR_EDOC_CATEGORIAS_ACTION = 'ERROR_EDOC_CATEGORIAS_ACTION';
const REQUEST_EDOC_MARCAS_ACTION = 'REQUEST_EDOC_MARCAS_ACTION';
const RESPONSE_EDOC_MARCAS_ACTION = 'RESPONSE_EDOC_MARCAS_ACTION';
const ERROR_EDOC_MARCAS_ACTION = 'ERROR_EDOC_MARCAS_ACTION';
const REQUEST_EDOC_MODELOS_ACTION = 'REQUEST_EDOC_MODELOS_ACTION';
const RESPONSE_EDOC_MODELOS_ACTION = 'RESPONSE_EDOC_MODELOS_ACTION';
const ERROR_EDOC_MODELOS_ACTION = 'ERROR_EDOC_MODELOS_ACTION';
const REQUEST_EDOC_STATUS_ACTION = 'REQUEST_EDOC_STATUS_ACTION';
const RESPONSE_EDOC_STATUS_ACTION = 'RESPONSE_EDOC_STATUS_ACTION';
const ERROR_EDOC_STATUS_ACTION = 'ERROR_EDOC_STATUS_ACTION';
const REQUEST_EDOC_VEICULOS_ACTION = 'REQUEST_EDOC_VEICULOS_ACTION';
const RESPONSE_EDOC_VEICULOS_ACTION = 'RESPONSE_EDOC_VEICULOS_ACTION';
const ERROR_EDOC_VEICULOS_ACTION = 'ERROR_EDOC_VEICULOS_ACTION';
const REQUEST_EDOC_DOCUMENTOS_VEICULO_ACTION = 'REQUEST_EDOC_DOCUMENTOS_VEICULO_ACTION';
const RESPONSE_EDOC_DOCUMENTOS_VEICULO_ACTION = 'RESPONSE_EDOC_DOCUMENTOS_VEICULO_ACTION';
const ERROR_EDOC_DOCUMENTOS_VEICULO_ACTION = 'ERROR_EDOC_CONSULTA_DOCUMENTOS_VEICULO_ACTION';
const REQUEST_EDOC_CONSULTA_TIPOS_DOCUMENTO_ACTION = 'REQUEST_EDOC_CONSULTA_TIPOS_DOCUMENTO_ACTION';
const RESPONSE_EDOC_CONSULTA_TIPOS_DOCUMENTO_ACTION = 'RESPONSE_EDOC_CONSULTA_TIPOS_DOCUMENTO_ACTION';
const ERROR_EDOC_CONSULTA_TIPOS_DOCUMENTO_ACTION = 'ERROR_EDOC_TIPOS_DOCUMENTO_ACTION';
const POST_EDOC_DOCUMENTO_ACTION = 'POST_EDOC_DOCUMENTO_ACTION';
const SUCCESS_EDOC_DOCUMENTO_ACTION = 'SUCCESS_EDOC_DOCUMENTO_ACTION';
const ERROR_EDOC_DOCUMENTO_ACTION = 'ERROR_EDOC_DOCUMENTO_ACTION';
const DELETE_EDOC_DOCUMENTO_ACTION = 'DELETE_EDOC_DOCUMENTO_ACTION';
const SUCCESS_DELETE_EDOC_DOCUMENTO_ACTION = 'SUCCESS_DELETE_EDOC_DOCUMENTO_ACTION';
const ERROR_DELETE_EDOC_DOCUMENTO_ACTION = 'ERROR_DELETE_EDOC_DOCUMENTO_ACTION';

const initialState = {
    consultaVeiculos: {
        categoria: null,
        chassi: '',
        errorText: '',
        isLoading: false,
        requested: false,
        marca: null,
        modelo: null,
        numEnvelope: '',
        placa: '',
        status: 1,
        pageNumber: 0,
        pageSize: 0,
        totalNumberOfPages: 0,
        totalNumberOfRecords: 0,
        dropdownCategorias: {
            isLoading: false,
            errorText: '',
            results: []
        },
        dropdownMarcas: {
            isLoading: false,
            errorText: '',
            results: []
        },
        dropdownModelos: {
            isLoading: false,
            errorText: '',
            results: []
        },
        dropdownStatus: {
            isLoading: false,
            errorText: '',
            results: []
        },
        results: []
    },
    modalUpload: {
        errorText: '',
        successText: '',
        isLoading: '',
        showModal: false,
        idVeiculo: 0,
        tipo: 0,
        idTipoDocumento: 0,
        file: '',
        anoFabricacao: 0,
        anoModelo: 0,
        chassi: '',
        dataCadastro: '',
        nomeMarca: '',
        nomeModelo: '',
        nomeVersao: '',
        numEnvelope: '',
        placa: '',
        status: '',
        dropdownTipoDocumento: {
            errorText: '',
            isLoading: false,
            results: []
        }
    },
    modalImagens: {
        errorText: '',
        successText: '',
        isLoading: false,
        showModal: false,
        showModalRemover: false,
        idModalRemover: 0,
        arquivoModalRemover: '',
        idVeiculo: 0,
        anoFabricacao: 0,
        anoModelo: 0,
        chassi: '',
        dataCadastro: '',
        nomeMarca: '',
        nomeModelo: '',
        nomeVersao: '',
        numEnvelope: '',
        placa: '',
        status: '',
        resultsEntrada: [],
        resultsSaida: []
    }
};

export const actionCreators = {
    fnUpdateState: (pEdocConsultaState) => async (dispatch) => {
        dispatch({
            type: 'UPDATE_STATE_EDOC_CONSULTA_ACTION',
            consultaVeiculos: {
                categoria: pEdocConsultaState.consultaVeiculos.categoria,
                chassi: pEdocConsultaState.consultaVeiculos.chassi,
                errorText: pEdocConsultaState.consultaVeiculos.errorText,
                isLoading: pEdocConsultaState.consultaVeiculos.isLoading,
                requested: pEdocConsultaState.consultaVeiculos.requested,
                marca: pEdocConsultaState.consultaVeiculos.marca,
                modelo: pEdocConsultaState.consultaVeiculos.modelo,
                numEnvelope: pEdocConsultaState.consultaVeiculos.numEnvelope,
                placa: pEdocConsultaState.consultaVeiculos.placa,
                status: pEdocConsultaState.consultaVeiculos.status,
                pageNumber: pEdocConsultaState.consultaVeiculos.pageNumber,
                pageSize: pEdocConsultaState.consultaVeiculos.pageSize,
                totalNumberOfPages: pEdocConsultaState.consultaVeiculos.totalNumberOfPages,
                totalNumberOfRecords: pEdocConsultaState.consultaVeiculos.totalNumberOfRecords,
                dropdownCategorias: {
                    isLoading: pEdocConsultaState.consultaVeiculos.dropdownCategorias.isLoading,
                    errorText: pEdocConsultaState.consultaVeiculos.dropdownCategorias.errorText,
                    results: pEdocConsultaState.consultaVeiculos.dropdownCategorias.results
                },
                dropdownMarcas: {
                    isLoading: pEdocConsultaState.consultaVeiculos.dropdownMarcas.isLoading,
                    errorText: pEdocConsultaState.consultaVeiculos.dropdownMarcas.errorText,
                    results: pEdocConsultaState.consultaVeiculos.dropdownMarcas.results
                },
                dropdownModelos: {
                    isLoading: pEdocConsultaState.consultaVeiculos.dropdownModelos.isLoading,
                    errorText: pEdocConsultaState.consultaVeiculos.dropdownModelos.errorText,
                    results: pEdocConsultaState.consultaVeiculos.dropdownModelos.results
                },
                dropdownStatus: {
                    isLoading: pEdocConsultaState.consultaVeiculos.dropdownStatus.isLoading,
                    errorText: pEdocConsultaState.consultaVeiculos.dropdownStatus.errorText,
                    results: pEdocConsultaState.consultaVeiculos.dropdownStatus.results
                },
                results: pEdocConsultaState.consultaVeiculos.results
            },
            modalUpload: {
                errorText: pEdocConsultaState.modalUpload.errorText,
                successText: pEdocConsultaState.modalUpload.successText,
                isLoading: pEdocConsultaState.modalUpload.isLoading,
                showModal: pEdocConsultaState.modalUpload.showModal,
                idVeiculo: pEdocConsultaState.modalUpload.idVeiculo,
                tipo: pEdocConsultaState.modalUpload.tipo,
                idTipoDocumento: pEdocConsultaState.modalUpload.idTipoDocumento,
                file: pEdocConsultaState.modalUpload.file,
                anoFabricacao: pEdocConsultaState.modalUpload.anoFabricacao,
                anoModelo: pEdocConsultaState.modalUpload.anoModelo,
                chassi: pEdocConsultaState.modalUpload.chassi,
                dataCadastro: pEdocConsultaState.modalUpload.dataCadastro,
                nomeMarca: pEdocConsultaState.modalUpload.nomeMarca,
                nomeModelo: pEdocConsultaState.modalUpload.nomeModelo,
                nomeVersao: pEdocConsultaState.modalUpload.nomeVersao,
                numEnvelope: pEdocConsultaState.modalUpload.numEnvelope,
                placa: pEdocConsultaState.modalUpload.placa,
                status: pEdocConsultaState.modalUpload.status,
                dropdownTipoDocumento: {
                    errorText: pEdocConsultaState.modalUpload.dropdownTipoDocumento.errorText,
                    isLoading: pEdocConsultaState.modalUpload.dropdownTipoDocumento.isLoading,
                    results: pEdocConsultaState.modalUpload.dropdownTipoDocumento.results,
                },
            },
            modalImagens: {
                errorText: pEdocConsultaState.modalImagens.errorText,
                successText: pEdocConsultaState.modalImagens.successText,
                isLoading: pEdocConsultaState.modalImagens.isLoading,
                showModal: pEdocConsultaState.modalImagens.showModal,
                showModalRemover: pEdocConsultaState.modalImagens.showModalRemover,
                idModalRemover: pEdocConsultaState.modalImagens.idModalRemover,
                arquivoModalRemover: pEdocConsultaState.modalImagens.arquivoModalRemover,
                idVeiculo: pEdocConsultaState.modalImagens.idVeiculo,
                anoFabricacao: pEdocConsultaState.modalImagens.anoFabricacao,
                anoModelo: pEdocConsultaState.modalImagens.anoModelo,
                chassi: pEdocConsultaState.modalImagens.chassi,
                dataCadastro: pEdocConsultaState.modalImagens.dataCadastro,
                nomeMarca: pEdocConsultaState.modalImagens.nomeMarca,
                nomeModelo: pEdocConsultaState.modalImagens.nomeModelo,
                nomeVersao: pEdocConsultaState.modalImagens.nomeVersao,
                numEnvelope: pEdocConsultaState.modalImagens.numEnvelope,
                placa: pEdocConsultaState.modalImagens.placa,
                status: pEdocConsultaState.modalImagens.status,
                resultsEntrada: pEdocConsultaState.modalImagens.resultsEntrada,
                resultsSaida: pEdocConsultaState.modalImagens.resultsSaida
            }
        });
    },
    listaCategorias: () => async (dispatch, getState) => {
        dispatch({ type: 'REQUEST_EDOC_CATEGORIAS_ACTION' });

        await fetch(edocconsulta_service.services.listaCategorias(getState().auth.authorizationToken))
            .then(response => {
                if (response.status === 200 || response.status === 0) {
                    return response;
                }
                else {
                    return response.json().then(function (object) {
                        return Promise.reject(new Error(object));
                    })
                }
            })
            .then(data => {
                data.json().then(function (object) {
                    dispatch({ type: 'RESPONSE_EDOC_CATEGORIAS_ACTION', results: object });
                });
            }).catch(function (err) {
                dispatch({ type: 'ERROR_EDOC_CATEGORIAS_ACTION', errorText: err.toString() });
            });

    },
    listaTiposDocumento: () => async (dispatch, getState) => {
        dispatch({ type: 'REQUEST_EDOC_CONSULTA_TIPOS_DOCUMENTO_ACTION' });

        await fetch(edocconsulta_service.services.listaTiposDocumento(getState().auth.authorizationToken))
            .then(response => {
                if (response.status === 200 || response.status === 0) {
                    return response;
                }
                else {
                    return response.json().then(function (object) {
                        return Promise.reject(new Error(object));
                    })
                }
            })
            .then(data => {
                data.json().then(function (object) {
                    dispatch({ type: 'RESPONSE_EDOC_CONSULTA_TIPOS_DOCUMENTO_ACTION', results: object });
                });
            }).catch(function (err) {
                dispatch({ type: 'ERROR_EDOC_CONSULTA_TIPOS_DOCUMENTO_ACTION', errorText: err.toString() });
            });

    },
    listaMarcas: (idCategoria) => async (dispatch, getState) => {
        dispatch({ type: 'REQUEST_EDOC_MARCAS_ACTION' });

        await fetch(edocconsulta_service.services.listaMarcas(getState().auth.authorizationToken, idCategoria))
            .then(response => {
                if (response.status === 200 || response.status === 0) {
                    return response;
                }
                else {
                    return response.json().then(function (object) {
                        return Promise.reject(new Error(object));
                    })
                }
            })
            .then(data => {
                data.json().then(function (object) {
                    var dtoListResult = [];
                    object.forEach(function (entry) { dtoListResult.push({ id: entry.id, nome: entry.nomeMarca }); });

                    dispatch({ type: 'RESPONSE_EDOC_MARCAS_ACTION', results: dtoListResult });
                });
            }).catch(function (err) {
                dispatch({ type: 'ERROR_EDOC_MARCAS_ACTION', errorText: err.toString() });
            });
    },
    listaModelos: (idCategoria, idMarca) => async (dispatch, getState) => {
        dispatch({ type: 'REQUEST_EDOC_MODELOS_ACTION' });

        await fetch(edocconsulta_service.services.listaModelos(getState().auth.authorizationToken, idCategoria, idMarca))
            .then(response => {
                if (response.status === 200 || response.status === 0) {
                    return response;
                }
                else {
                    return response.json().then(function (object) {
                        return Promise.reject(new Error(object));
                    })
                }
            })
            .then(data => {
                data.json().then(function (object) {
                    var dtoListResult = [];
                    object.forEach(function (entry) { dtoListResult.push({ id: entry.id, nome: entry.nomeModelo }); });

                    dispatch({ type: 'RESPONSE_EDOC_MODELOS_ACTION', results: dtoListResult });
                });
            }).catch(function (err) {
                dispatch({ type: 'ERROR_EDOC_MODELOS_ACTION', errorText: err.toString() });
            });
    },
    listaStatus: () => async (dispatch, getState) => {
        dispatch({ type: 'REQUEST_EDOC_STATUS_ACTION' });

        await fetch(edocconsulta_service.services.listaStatus(getState().auth.authorizationToken))
            .then(response => {
                if (response.status === 200 || response.status === 0) {
                    return response;
                }
                else {
                    return response.json().then(function (object) {
                        return Promise.reject(new Error(object));
                    })
                }
            })
            .then(data => {
                data.json().then(function (object) {
                    dispatch({ type: 'RESPONSE_EDOC_STATUS_ACTION', results: object });
                });
            }).catch(function (err) {
                dispatch({ type: 'ERROR_EDOC_STATUS_ACTION', errorText: err.toString() });
            });
    },
    listaVeiculos: () => async (dispatch, getState) => {
        dispatch({ type: 'REQUEST_EDOC_VEICULOS_ACTION' });

        var pDTO = {
            pageNumber: getState().edoc_consulta.consultaVeiculos.pageNumber,
            pageSize: getState().edoc_consulta.consultaVeiculos.pageSize,
            placa: getState().edoc_consulta.consultaVeiculos.placa.replace("-", ""),
            chassi: getState().edoc_consulta.consultaVeiculos.chassi,
            numeroEnvelope: getState().edoc_consulta.consultaVeiculos.numEnvelope,
            idCategoria: (getState().edoc_consulta.consultaVeiculos.categoria == 0 ? null : getState().edoc_consulta.consultaVeiculos.categoria),
            idMarca: (getState().edoc_consulta.consultaVeiculos.marca == 0 ? null : getState().edoc_consulta.consultaVeiculos.marca),
            idModelo: (getState().edoc_consulta.consultaVeiculos.modelo == 0 ? null : getState().edoc_consulta.consultaVeiculos.modelo),
            status: (getState().edoc_consulta.consultaVeiculos.status == 0 ? null : getState().edoc_consulta.consultaVeiculos.status)
        };

        await fetch(edocconsulta_service.services.listaVeiculos(getState().auth.authorizationToken, pDTO))
            .then(response => {
                if (response.status === 200 || response.status === 0) {
                    return response;
                }
                else {
                    return response.json().then(function (object) {
                        return Promise.reject(new Error(object));
                    })
                }
            })
            .then(data => {
                data.json().then(function (object) {
                    var dtoListResult = [];
                    object.forEach(function (entry) {
                        dtoListResult.push({
                            idVeiculo: entry.idVeiculo,
                            anoModelo: entry.anoModelo,
                            anoFabricacao: entry.anoFabricacao,
                            placa: entry.placa,
                            chassi: entry.chassi,
                            nomeMarca: entry.nomeMarca,
                            nomeModelo: entry.nomeModelo,
                            nomeVersao: entry.nomeVersao,
                            dataCadastro: entry.dataCadastro,
                            status: entry.status,
                            numEnvelope: entry.numEnvelope,
                            totalNumberOfPages: entry.totalNumberOfPages,
                            totalNumberOfRecords: entry.totalNumberOfRecords
                        });
                    });

                    dispatch({
                        type: 'RESPONSE_EDOC_VEICULOS_ACTION',
                        results: object,
                        totalNumberOfPages: (dtoListResult.length > 0 ? dtoListResult[0].totalNumberOfPages : 0),
                        totalNumberOfRecords: (dtoListResult.length > 0 ? dtoListResult[0].totalNumberOfRecords : 0)
                    });
                });
            }).catch(function (err) {
                dispatch({ type: 'ERROR_EDOC_VEICULOS_ACTION', errorText: err.toString() });
            });
    },
    listaDocumentosVeiculo: (idVeiculo) => async (dispatch, getState) => {
        dispatch({ type: 'REQUEST_EDOC_DOCUMENTOS_VEICULO_ACTION' });

        await fetch(edocconsulta_service.services.listaDocumentosVeiculo(getState().auth.authorizationToken, idVeiculo))
            .then(response => {
                if (response.status === 200 || response.status === 0) {
                    return response;
                }
                else {
                    return response.json().then(function (object) {
                        return Promise.reject(new Error(object));
                    })
                }
            })
            .then(data => {
                data.json().then(function (object) {
                    var dtoListResultEntrada = [];
                    object.forEach(function (entry) {
                        if (entry.tipo == 1) {
                            dtoListResultEntrada.push({
                                id: entry.id,
                                tipo: entry.tipo,
                                nomeTipoDocumento: entry.nomeTipoDocumento,
                                urlArquivo: entry.urlArquivo
                            });
                        }
                    });

                    var dtoListResultSaida = [];
                    object.forEach(function (entry) {
                        if (entry.tipo == 2) {
                            dtoListResultSaida.push({
                                id: entry.id,
                                tipo: entry.tipo,
                                nomeTipoDocumento: entry.nomeTipoDocumento,
                                urlArquivo: entry.urlArquivo
                            });
                        }
                    });

                    dispatch({ type: 'RESPONSE_EDOC_DOCUMENTOS_VEICULO_ACTION', resultsEntrada: dtoListResultEntrada, resultsSaida: dtoListResultSaida });
                });
            }).catch(function (err) {
                dispatch({ type: 'ERROR_EDOC_DOCUMENTOS_VEICULO_ACTION', errorText: err.toString() });
            });
    },
    inserirDocumento: () => async (dispatch, getState) => {
        dispatch({ type: 'POST_EDOC_DOCUMENTO_ACTION' });

        var pDTO = {
            idVeiculo: getState().edoc_consulta.modalUpload.idVeiculo,
            idTipoDocumento: getState().edoc_consulta.modalUpload.idTipoDocumento,
            tipo: getState().edoc_consulta.modalUpload.tipo,
            base64File: getState().edoc_consulta.modalUpload.file
        }

        await fetch(edocconsulta_service.services.inserirDocumento(getState().auth.authorizationToken, pDTO))
            .then(response => {
                if (response.status === 200 || response.status === 0) {
                    return response;
                }
                else {
                    return response.json().then(function (object) {
                        return Promise.reject(new Error(object));
                    })
                }
            })
            .then(data => {
                dispatch({ type: 'SUCCESS_EDOC_DOCUMENTO_ACTION' });
                //data.json().then(function (object) {
                //    dispatch({ type: 'SUCCESS_EDOC_DOCUMENTO_ACTION' });
                //});
            }).catch(function (err) {
                dispatch({ type: 'ERROR_EDOC_DOCUMENTO_ACTION', errorText: err.toString() });
            });
    },
    removerDocumento: (idDocumento) => async (dispatch, getState) => {
        dispatch({ type: 'DELETE_EDOC_DOCUMENTO_ACTION' });

        await fetch(edocconsulta_service.services.removerDocumento(getState().auth.authorizationToken, idDocumento))
            .then(response => {
                if (response.status === 200 || response.status === 0) {
                    return response;
                }
                else {
                    return response.json().then(function (object) {
                        return Promise.reject(new Error(object));
                    })
                }
            })
            .then(data => {
                dispatch({ type: 'SUCCESS_DELETE_EDOC_DOCUMENTO_ACTION' });
                dispatch(actionCreators.listaDocumentosVeiculo(getState().edoc_consulta.modalImagens.idVeiculo));
            }).catch(function (err) {
                dispatch({ type: 'ERROR_DELETE_EDOC_DOCUMENTO_ACTION', errorText: err.toString() });
            });
    }
};

export const reducer = (state, action) => {
    state = state || initialState;

    if (action.type === UPDATE_STATE_EDOC_CONSULTA_ACTION) {
        return {
            ...state,
            consultaVeiculos: action.consultaVeiculos,
            modalImagens: action.modalImagens
        };
    }



    if (action.type === REQUEST_EDOC_CATEGORIAS_ACTION) {
        return {
            ...state,
            consultaVeiculos: {
                ...state.consultaVeiculos,
                dropdownCategorias: {
                    isLoading: true,
                    errorText: '',
                    results: []
                }
            }
        };
    }

    if (action.type === RESPONSE_EDOC_CATEGORIAS_ACTION) {
        return {
            ...state,
            consultaVeiculos: {
                ...state.consultaVeiculos,
                dropdownCategorias: {
                    ...state.consultaVeiculos.dropdownCategorias,
                    isLoading: false,
                    results: action.results
                }
            }
        };
    }

    if (action.type === ERROR_EDOC_CATEGORIAS_ACTION) {
        return {
            ...state,
            consultaVeiculos: {
                ...state.consultaVeiculos,
                dropdownCategorias: {
                    ...state.consultaVeiculos.dropdownCategorias,
                    isLoading: false,
                    errorText: action.errorText
                }
            }
        };
    }



    if (action.type === REQUEST_EDOC_MARCAS_ACTION) {
        return {
            ...state,
            consultaVeiculos: {
                ...state.consultaVeiculos,
                dropdownMarcas: {
                    isLoading: true,
                    errorText: '',
                    results: []
                }
            }
        };
    }

    if (action.type === RESPONSE_EDOC_MARCAS_ACTION) {
        return {
            ...state,
            consultaVeiculos: {
                ...state.consultaVeiculos,
                dropdownMarcas: {
                    ...state.consultaVeiculos.dropdownCategorias,
                    isLoading: false,
                    results: action.results
                }
            }
        };
    }

    if (action.type === ERROR_EDOC_MARCAS_ACTION) {
        return {
            ...state,
            consultaVeiculos: {
                ...state.consultaVeiculos,
                dropdownMarcas: {
                    ...state.consultaVeiculos.dropdownCategorias,
                    isLoading: false,
                    errorText: action.errorText
                }
            }
        };
    }



    if (action.type === REQUEST_EDOC_MODELOS_ACTION) {
        return {
            ...state,
            consultaVeiculos: {
                ...state.consultaVeiculos,
                dropdownModelos: {
                    isLoading: true,
                    errorText: '',
                    results: []
                }
            }
        };
    }

    if (action.type === RESPONSE_EDOC_MODELOS_ACTION) {
        return {
            ...state,
            consultaVeiculos: {
                ...state.consultaVeiculos,
                dropdownModelos: {
                    ...state.consultaVeiculos.dropdownCategorias,
                    isLoading: false,
                    results: action.results
                }
            }
        };
    }

    if (action.type === ERROR_EDOC_MODELOS_ACTION) {
        return {
            ...state,
            consultaVeiculos: {
                ...state.consultaVeiculos,
                dropdownModelos: {
                    ...state.consultaVeiculos.dropdownCategorias,
                    isLoading: false,
                    errorText: action.errorText
                }
            }
        };
    }



    if (action.type === REQUEST_EDOC_STATUS_ACTION) {
        return {
            ...state,
            consultaVeiculos: {
                ...state.consultaVeiculos,
                dropdownStatus: {
                    isLoading: true,
                    errorText: '',
                    results: []
                }
            }
        };
    }

    if (action.type === RESPONSE_EDOC_STATUS_ACTION) {
        return {
            ...state,
            consultaVeiculos: {
                ...state.consultaVeiculos,
                dropdownStatus: {
                    ...state.consultaVeiculos.dropdownCategorias,
                    isLoading: false,
                    results: action.results
                }
            }
        };
    }

    if (action.type === ERROR_EDOC_STATUS_ACTION) {
        return {
            ...state,
            consultaVeiculos: {
                ...state.consultaVeiculos,
                dropdownStatus: {
                    ...state.consultaVeiculos.dropdownCategorias,
                    isLoading: false,
                    errorText: action.errorText
                }
            }
        };
    }




    if (action.type === REQUEST_EDOC_VEICULOS_ACTION) {
        return {
            ...state,
            consultaVeiculos: {
                ...state.consultaVeiculos,
                errorText: '',
                isLoading: true,
                requested: true,
                results: []
            }
        };
    }

    if (action.type === RESPONSE_EDOC_VEICULOS_ACTION) {
        return {
            ...state,
            consultaVeiculos: {
                ...state.consultaVeiculos,
                isLoading: false,
                results: action.results,
                totalNumberOfPages: action.totalNumberOfPages,
                totalNumberOfRecords: action.totalNumberOfRecords
            }
        };
    }

    if (action.type === ERROR_EDOC_VEICULOS_ACTION) {
        return {
            ...state,
            consultaVeiculos: {
                ...state.consultaVeiculos,
                isLoading: false,
                errorText: action.errorText
            }
        };
    }



    if (action.type === REQUEST_EDOC_DOCUMENTOS_VEICULO_ACTION) {
        return {
            ...state,
            modalImagens: {
                ...state.modalImagens,
                errorText: '',
                isLoading: true,
                resultsEntrada: [],
                resultsSaida: []
            }
        }
    }

    if (action.type === RESPONSE_EDOC_DOCUMENTOS_VEICULO_ACTION) {
        return {
            ...state,
            modalImagens: {
                ...state.modalImagens,
                isLoading: false,
                resultsEntrada: action.resultsEntrada,
                resultsSaida: action.resultsSaida
            }
        }
    }

    if (action.type === ERROR_EDOC_DOCUMENTOS_VEICULO_ACTION) {
        return {
            ...state,
            modalImagens: {
                ...state.modalImagens,
                errorText: action.errorText,
                isLoading: false
            }
        }
    }



    if (action.type === REQUEST_EDOC_CONSULTA_TIPOS_DOCUMENTO_ACTION) {
        return {
            ...state,
            modalUpload: {
                ...state.modalUpload,
                dropdownTipoDocumento: {
                    ...state.modalUpload.dropdownTipoDocumento,
                    errorText: '',
                    isLoading: true,
                    results: []
                }
            }
        }
    }

    if (action.type === RESPONSE_EDOC_CONSULTA_TIPOS_DOCUMENTO_ACTION) {
        return {
            ...state,
            modalUpload: {
                ...state.modalUpload,
                dropdownTipoDocumento: {
                    ...state.modalUpload.dropdownTipoDocumento,
                    isLoading: false,
                    results: action.results
                }
            }
        }
    }

    if (action.type === ERROR_EDOC_CONSULTA_TIPOS_DOCUMENTO_ACTION) {
        return {
            ...state,
            modalUpload: {
                ...state.modalUpload,
                dropdownTipoDocumento: {
                    ...state.modalUpload.dropdownTipoDocumento,
                    errorText: action.errorText,
                    isLoading: false
                }
            }
        }
    }



    if (action.type === POST_EDOC_DOCUMENTO_ACTION) {
        return {
            ...state,
            modalUpload: {
                ...state.modalUpload,
                errorText: '',
                isLoading: true,
                successText: '',
            }
        }
    }

    if (action.type === SUCCESS_EDOC_DOCUMENTO_ACTION) {
        return {
            ...state,
            modalUpload: {
                ...state.modalUpload,
                isLoading: false,
                successText: 'Documento salvo com sucesso, você pode consultá-lo na listagem de documentos.',
                tipo: 0,
                idTipoDocumento: 0,
                file: '',
            }
        }
    }

    if (action.type === ERROR_EDOC_DOCUMENTO_ACTION) {
        return {
            ...state,
            modalUpload: {
                ...state.modalUpload,
                errorText: action.errorText,
                isLoading: false,
            }
        }
    }



    if (action.type === DELETE_EDOC_DOCUMENTO_ACTION) {
        return {
            ...state,
            modalImagens: {
                ...state.modalImagens,
                showModalRemover: false,
                errorText: '',
                isLoading: true,
            }
        }
    }

    if (action.type === SUCCESS_DELETE_EDOC_DOCUMENTO_ACTION) {
        return {
            ...state,
            modalImagens: {
                ...state.modalImagens,
                isLoading: false,
                idModalRemover: 0,
                arquivoModalRemover: '',
            }
        }
    }

    if (action.type === ERROR_DELETE_EDOC_DOCUMENTO_ACTION) {
        return {
            ...state,
            modalImagens: {
                ...state.modalImagens,
                errorText: action.errorText,
                isLoading: false,
            }
        }
    }

    return state;
};

