import * as React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actionCreators } from '../../../../../store/marketing/publicador/MarketingPublicadorConfig';

import Formsy from 'formsy-react';
import { Row, Modal } from 'react-bootstrap';
import ProgerssBar from '../../../../commons/ProgressBar';
import AlertError from '../../../../commons/AlertError';
import AlertSuccess from '../../../../commons/AlertSuccess';
import InputTextValidated from '../../../../commons/InputTextValidated';
import BtnSalvar from '../../../../commons/BtnSalvar';
import BtnCancelar from '../../../../commons/BtnCancelar';
import InputSelect from '../../../../commons/InputSelect';

class ModalAutoLine extends React.Component {
    constructor() {
        super();
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    async componentDidMount() {
        if (this.props.configuracaoPortais.modalPortais.modalAutoLine.showModal) {
            if (this.props.configuracaoPortais.modalPortais.ativado) {
                await this.props.fnBuscaConfiguracaoPortal();
                await this.props.fnListaUF();
                this.props.configuracaoPortais.modalPortais.modalAutoLine.email = JSON.parse(this.props.configuracaoPortais.modalPortais.jsAutenticacao).email;
                this.props.configuracaoPortais.modalPortais.modalAutoLine.senha = JSON.parse(this.props.configuracaoPortais.modalPortais.jsAutenticacao).senha;
                this.props.configuracaoPortais.modalPortais.modalAutoLine.idUf = JSON.parse(this.props.configuracaoPortais.modalPortais.jsDadosEspeciais).idUf;
                if (this.props.configuracaoPortais.modalPortais.modalAutoLine.idUf != 0)
                    await this.props.fnListaCidades(this.props.configuracaoPortais.modalPortais.modalAutoLine.idUf);
                this.props.configuracaoPortais.modalPortais.modalAutoLine.idCidade = JSON.parse(this.props.configuracaoPortais.modalPortais.jsDadosEspeciais).idCidade;
                this.props.fnUpdateState(this.props);
            }
            else {
                this.props.configuracaoPortais.modalPortais.modalAutoLine.email = '';
                this.props.configuracaoPortais.modalPortais.modalAutoLine.senha = '';
                this.props.configuracaoPortais.modalPortais.modalAutoLine.idUf = 0;
                this.props.configuracaoPortais.modalPortais.modalAutoLine.idCidade = 0;
                this.props.fnUpdateState(this.props);
            }
        }
    }

    async handleSubmit(e) {
        var autenticacao = {
            email: this.props.configuracaoPortais.modalPortais.modalAutoLine.email,
            senha: this.props.configuracaoPortais.modalPortais.modalAutoLine.senha
        };

        var dadosespeciais = {
            idUf: this.props.configuracaoPortais.modalPortais.modalAutoLine.idUf,
            idCidade: this.props.configuracaoPortais.modalPortais.modalAutoLine.idCidade
        };

        if (this.props.configuracaoPortais.modalPortais.ativado) {
            await this.props.fnAtualizaConfiguracaoPortal(JSON.stringify(autenticacao), JSON.stringify(dadosespeciais));
            this.props.configuracaoPortais.modalPortais.modalAutoLine.showModal = false;
        }
        else {
            await this.props.fnInsereConfiguracaoPortal(JSON.stringify(autenticacao), JSON.stringify(dadosespeciais));
            this.props.configuracaoPortais.modalPortais.modalAutoLine.showModal = false;
        }
    }

    handleOnHide() {
        this.props.configuracaoPortais.modalPortais.modalAutoLine.showModal = false;
        this.props.fnUpdateState(this.props);
    }

    render() {
        var objProps = this.props;

        var dropdownUFs = [];
        dropdownUFs.push({ value: '', text: 'Selecione a UF' });
        objProps.configuracaoPortais.modalPortais.comboUF.results.forEach(function (entry) {
            dropdownUFs.push({ value: entry.id, text: entry.nome });
        });

        var dropdownCidades = [];
        dropdownCidades.push({ value: '', text: 'Selecione a Cidade' });
        objProps.configuracaoPortais.modalPortais.comboCidade.results.forEach(function (entry) {
            dropdownCidades.push({ value: entry.id, text: entry.nome });
        });

        return <Modal
            show={this.props.configuracaoPortais.modalPortais.modalAutoLine.showModal}
            onHide={this.handleOnHide.bind(this)}
        >
            <Formsy onValidSubmit={this.handleSubmit}>
                <Modal.Header closeButton>
                    <h4 className="modal-title custom-font">Configurar AutoLine</h4>
                </Modal.Header>
                <Modal.Body>
                    <AlertSuccess
                        successText={this.props.configuracaoPortais.modalPortais.successText}
                        alertVisible={this.props.configuracaoPortais.modalPortais.successText != '' && this.props.configuracaoPortais.modalPortais.successText != undefined ? true : false}
                        onDismiss={(alertVisible, successText) => {
                            this.props.configuracaoPortais.modalPortais.successText = successText;
                            this.props.fnUpdateState(this.props);
                        }}
                    />
                    <AlertError
                        errorText={this.props.configuracaoPortais.modalPortais.errorText}
                        alertVisible={this.props.configuracaoPortais.modalPortais.errorText != '' && this.props.configuracaoPortais.modalPortais.errorText != undefined ? true : false}
                        onDismiss={(alertVisible, errorText) => {
                            this.props.configuracaoPortais.modalPortais.errorText = errorText;
                            this.props.fnUpdateState(this.props);
                        }}
                    />
                    {
                        this.props.configuracaoPortais.modalPortais.isLoading
                            ? <ProgerssBar />
                            : <Row>
                                <InputTextValidated
                                    name="txtEmail"
                                    type="text"
                                    className="col-md-12"
                                    placeholder="Informe o email"
                                    onChange={(name, value) => {
                                        this.props.configuracaoPortais.modalPortais.modalAutoLine.email = value;
                                        this.props.fnUpdateState(this.props);
                                    }}
                                    value={this.props.configuracaoPortais.modalPortais.modalAutoLine.email}
                                    title="Email:"
                                    validations="isEmail"
                                    validationError="Informe um email válido"
                                    required
                                />
                                <InputTextValidated
                                    name="txtSenha"
                                    type="text"
                                    className="col-md-12"
                                    placeholder="Informe a senha"
                                    onChange={(name, value) => {
                                        this.props.configuracaoPortais.modalPortais.modalAutoLine.senha = value;
                                        this.props.fnUpdateState(this.props);
                                    }}
                                    value={this.props.configuracaoPortais.modalPortais.modalAutoLine.senha}
                                    required={true}
                                    requiredErrorText="A senha é obrigatória"
                                    title="Senha:"
                                />
                                <div className="form-group col-md-12">
                                    <label>UF:</label>
                                    <InputSelect
                                        class="form-control mt-4"
                                        data={(this.props.configuracaoPortais.modalPortais.comboUF.isLoading
                                            ? [{ value: '', text: 'Aguarde carregando...' }]
                                            : dropdownUFs)}
                                        onChange={
                                            (name, value) => {
                                                this.props.configuracaoPortais.modalPortais.modalAutoLine.idUf = value;
                                                this.props.fnUpdateState(this.props);
                                                this.props.fnListaCidades(value);
                                            }
                                        }
                                        selectedValue={
                                            (this.props.configuracaoPortais.modalPortais.modalAutoLine.idUf == null)
                                                ? null
                                                : this.props.configuracaoPortais.modalPortais.modalAutoLine.idUf.toString()
                                        }
                                        disabled={(this.props.configuracaoPortais.modalPortais.comboUF.isLoading ? true : false)}
                                    />
                                </div>
                                <div className="form-group col-md-12">
                                    <label>Cidade:</label>
                                    <InputSelect
                                        class="form-control mt-4"
                                        data={(this.props.configuracaoPortais.modalPortais.comboCidade.isLoading
                                            ? [{ value: '', text: 'Aguarde carregando...' }]
                                            : dropdownCidades)}
                                        onChange={
                                            (name, value) => {
                                                this.props.configuracaoPortais.modalPortais.modalAutoLine.idCidade = value;
                                                this.props.fnUpdateState(this.props);
                                            }
                                        }
                                        selectedValue={
                                            (this.props.configuracaoPortais.modalPortais.modalAutoLine.idCidade == null)
                                                ? null
                                                : this.props.configuracaoPortais.modalPortais.modalAutoLine.idCidade.toString()
                                        }
                                        disabled={(this.props.configuracaoPortais.modalPortais.comboCidade.isLoading ? true : false)}
                                    />
                                </div>
                            </Row>
                    }
                </Modal.Body>
                <Modal.Footer>
                    {this.props.configuracaoPortais.modalPortais.ativado == true
                        ? <BtnCancelar
                            type="button"
                            text=" Remover Portal"
                            textLoading=" Aguarde..."
                            isLoading={this.props.configuracaoPortais.modalPortais.isLoading}
                            onClick={async () => {
                                await this.props.fnDeleteConfiguracaoPortal();
                                this.props.configuracaoPortais.modalPortais.modalAutoLine.showModal = false;
                                this.props.fnUpdateState(this.props);
                            }}
                        />
                        : null}
                    <BtnSalvar
                        type="submit"
                        text={this.props.configuracaoPortais.modalPortais.ativado == false ? " Salvar Configuração" : " Alterar Configuração"}
                        textLoading=" Aguarde..."
                        isLoading={this.props.configuracaoPortais.modalPortais.isLoading}
                    />
                </Modal.Footer>
            </Formsy>
        </Modal>;
    }
}

export default connect(
    state => state.marketing_publicadorconfig,
    dispatch => bindActionCreators(actionCreators, dispatch),
    null, {}
)(ModalAutoLine);