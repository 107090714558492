import * as React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actionCreators } from '../../../store/analiserisco/BNDVCompleta';

import { Modal } from 'react-bootstrap';
import { BNDVCompletaResultadoSP } from './BNDVCompletaResultadoSP';
import { BNDVCompletaResultadoOutrosEstados } from './BNDVCompletaResultadoOutrosEstados';
import BtnImprimir from '../../commons/BtnImprimir';
import BtnCancelar from '../../commons/BtnCancelar';

class BNDVCompletaResultado extends React.Component {
    constructor() {
        super();
        this.onClickPrint = this.onClickPrint.bind(this);
    }

    onClickPrint() { window.print(); }

    render() {
        return <Modal show={this.props.ModalResultadoPesquisa.showModal} onHide={() => { this.props.fnCloseModalResultadoPesquisa(); }} bsSize="large" id="dados-pesquisa">
            <Modal.Header closeButton>
                <h3 className="modal-title custom-font">Resultado da Pesquisa - BNDV Completa</h3>
            </Modal.Header>
            <Modal.Body>
                {
                    (!this.props.IsLoading)
                        ? (this.props.ModalResultadoPesquisa.data.UF != "")
                            ? (this.props.ModalResultadoPesquisa.data.UF == "SP")
                                ? <BNDVCompletaResultadoSP data={this.props.ModalResultadoPesquisa.data.JsonRetorno} />
                                : <BNDVCompletaResultadoOutrosEstados data={this.props.ModalResultadoPesquisa.data.JsonRetorno} />
                            : null
                        : <div className="progress progress-striped active">
                            <div className="progress-bar progress-bar-blue" role="progressbar" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100" style={{ width: "100%" }}>
                                <span className="sr-only">100% Complete</span>
                            </div>
                        </div>
                }
            </Modal.Body>
            <Modal.Footer>
                <BtnImprimir
                    type="button"
                    IsLoading={this.props.IsLoading}
                    onClick={this.onClickPrint}
                />
                <BtnCancelar
                    type="button"
                    text=" Fechar"
                    onClick={() => { this.props.fnCloseModalResultadoPesquisa(); }}
                />
            </Modal.Footer>
        </Modal>;
    }
}

export default connect(
    state => state.bndvcompleta,
    dispatch => bindActionCreators(actionCreators, dispatch),
    null,
    { pure: false }
)(BNDVCompletaResultado);